import {useState, useRef} from "react"

import Modal from 'components/modal_new/Modal'
import {BtnPrimary} from "components/atom/Button";
import {RepositoryRadio} from "components/atom/RadioContent";
import { VerticalLabelInput} from "components/molecule/InputFieldLabel"
import { PUBLISH_TYPE } from "components/common/EnuSpaceEnum";
import {ModalBottomBox, BgPopup, WarningMsg} from 'components/css/common'
import {CreateEnuCanvas, UpdateEnuCanvasInfo} from 'api/EnuCanvasApi'

import styled from "styled-components";

export default function NewCanvasFormModal({StateData, CreateCanvas, ReloadEvent}){
    const [Msg, setMsg] = useState();
    const CanvasNameRef = useRef(null);

    const [newProjectForm, setNewProjectForm] = StateData;
    const {userId, description, CanvasName, publishType, mode} = newProjectForm;

    const headerCanvas = (e) =>{
        if(CanvasName === ''){
            CanvasNameRef.current.focus();
            setMsg({CanvasName:'Canvas Name을 입력해주세요.'});
            return;
        }
        
        setMsg('');

        // Canvas 생성 로직
        if(mode === 'new'){
            CreateEnuCanvas(userId, publishType, CanvasName, description)
                .then(res => {
                    const {result, msg, data} = res.data;
                    if(result === 'Success'){
                        CreateCanvas(data, CanvasName);
                    }else{
                        setMsg({common:`Canvas 생성에 실패하였습니다. ${msg}`});
                        return;
                    }
                })
                .catch(err => {
                    setMsg({common:`Canvas 생성시 오류: ${err}`});
                    return;
                });
        }else{
            const originData = newProjectForm.originData;
            const changeData = Object.entries(originData).reduce((acc, [key, value]) =>{
                if(newProjectForm[key] !== value){
                    acc[key] = newProjectForm[key];
                }
                return acc;
            }, {});

            const {userId, eunCanvasId} = newProjectForm;
            const {CanvasName, description, publishType} = changeData;

            UpdateEnuCanvasInfo(
                userId,
                eunCanvasId,
                originData.CanvasName,
                CanvasName,
                description,
                publishType
            ).then(res => {
                const {result, msg} = res.data;
                if(result === 'Success'){
                    CloseModal();
                    ReloadEvent && ReloadEvent();
                }else{
                    setMsg({common:msg});
                }
            }).catch(err => {
                setMsg({common:err});
            });
        }
    };

    const CloseModal = () =>{
        setNewProjectForm({ visibility: false, });
    }

    return(
        <>
        <BgPopup onClick={ CloseModal }/>
        <Modal 
            _x={document.getElementById('root').offsetWidth / 2 - 120} 
            _y={document.getElementById('root').offsetHeight/2 - 340} 
            _title={mode === 'new' ? 'New Canvas' : 'Canvas Info Edit'} 
            _enableResizing={false} 
            _onClick={null} 
            _closeModal={CloseModal}
        >
            <FlexContent>
                {/* 탭 */}
                <CustomRepositoryRadio
                    isUseKeyLabel={true}
                    attributeName='PublishType'
                    dataList={PUBLISH_TYPE}
                    selectedValue={publishType}
                    titleList={{PUBLIC:'Public', PRIVATE:'Private'}}
                    changeEvent={(name, value) => setNewProjectForm(perv => ({...perv, publishType: value}))}
                />

                <VerticalLabelInput
                    label='Canvas Name'
                    placeholder='Canvas Name'
                    isRequired={true}
                    inputRef={CanvasNameRef}
                    value={newProjectForm.CanvasName}
                    errorMsg={Msg?.CanvasName || ''}
                    onChangeEvent={(CanvasName) => {
                        setNewProjectForm(prev => ({ ...prev, CanvasName }))
                    }}
                />
                <VerticalLabelInput
                    label='Description'
                    placeholder='Description'
                    value={newProjectForm.description}
                    onChangeEvent={(description) => {
                        setNewProjectForm(prev => ({ ...prev, description }))
                    }}
                />
            </FlexContent>
            <ModalBottomBox marginTop={"0px!important"}>
                <WarningMsg>{Msg?.common}</WarningMsg>
                <BtnPrimary label="Ok" onClick={headerCanvas}/>
            </ModalBottomBox>
        </Modal>
        </>
    );
}

//스타일----------------------------------------------
const FlexContent = styled.div`
    &>div{
        margin-top: 0;
    }
`;

const CustomRepositoryRadio = styled(RepositoryRadio)`
    height: 36px;
    margin-bottom: 24px;
    border-radius: 4px;

    &>div{
        &:first-child >input + label {
            border-radius: 2px 0 0 2px;
        }
    
        &:last-of-type >input + label {
            border-radius: 0 2px 2px 0;
        }
    }
`;