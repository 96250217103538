import { useState, useRef } from "react";
import styled, {css} from "styled-components"

function ResizeDiv({enuSpace, _pos, _width, children}){
    const resizable = useRef();
    const [isReSize,   setIsReSize] = useState(false);
    const [initialPos,   setInitialPos] = useState('');
    const [initialSize, setInitialSize] = useState('');

    const initial = (e) => {
        setInitialPos(e.clientX);
        setInitialSize(resizable.current.offsetWidth);
        setIsReSize(true);
        resizable.current.style.userSelect = 'none';
    }

    const ChangeWidth = (e) =>{
        switch (_pos) {
            case 'left':
                if(parseInt(e.clientX) === 0){
                    resizable.current.style.width = `250px`;
                }else{
                    resizable.current.style.width = `${parseInt(initialSize) - parseInt(e.clientX - initialPos)}px`;
                }
                break;
            case 'right':
                if(parseInt(e.clientX) === 0){
                    resizable.current.style.width = `250px`;
                }else{
                    resizable.current.style.width = `${parseInt(initialSize) + parseInt(e.clientX - initialPos)}px`;
                }
                break;
            default:
                break;
        }
    }
    
    const resize = (e) => {
        if(isReSize){
            ChangeWidth(e);
            enuSpace?.SetResize();
        }
    }

    const onDragEnd = (e) => {
        ChangeWidth(e);
        enuSpace?.SetResize();
        setIsReSize(false);
        resizable.current.style.userSelect = 'auto';
    }

    return(
        <Content ref={resizable} style={{'width': `${_width}px`}} Type={_pos}>
            <ResizeBar draggable='true' Type={_pos} onDragStart={initial} onDrag={resize} onDragEnd={onDragEnd}/>
            {children && children}
        </Content>
    )
}

export default ResizeDiv;

ResizeDiv.defaultProps ={
    _pos:'left',
    _width: 200,
}

// 스타일---------------------------------------------------------
const Content = styled.aside`
    position: relative;
    font-size: 0.9rem;
    min-width: 250px;
    height: 100%;

    ${(props) =>{
        switch(props.Type) {
            case "left":
                return css`
                    border-left: 1px solid ${({theme}) => theme.base.border_color_Gray};    
                `;
            case "right":
                return css`
                    border-right: 1px solid ${({theme}) => theme.base.border_color_Gray};
                `;
            default:
                break;
        }
    }}
`;

const ResizeBar = styled.div`
    position: absolute;
    width: 10px;
    height: 100%;
    z-index: 8;
    cursor: w-resize;
    top: 0;

    ${(props) =>{
        switch(props.Type) {
            case "left":
                return css`
                    left: -5px;
                `;
            case "right":
                return css`
                    right: -5px;
                `;
            default:
                break;
        }
    }}
`;
