import { useState } from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import {SvgDropdown} from "components/atom/ImageDropdown";
import SelectList from "components/atom/SelectList";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import FontFamily from 'components/common/FontFamily';
import {FontSizeIcon, DecoNoneIcon, DecoThroughIcon, DecoUnderlineIcon} from 'components/common/EnuSpaceIcon'
import { GroupName, ToggleRect } from 'components/css/common';

import styled from "styled-components";
import { MdFormatItalic } from "react-icons/md";

export default function TextInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        font_family: NodeFontFamily,
        text: NodeText,
        font_style: NodeFontStyle,
        font_weight: NodeFontWeight,
        font_size: NodeFontSize,
        text_decoration: NodeTextDecoration,
        text_anchor: NodeTextAnchor,
        interface: NodeInterface,
        variable: NodeVariable,
        variable_format: NodeVariableFormat,
    } = _data;

    const [fontClass] = useState(new FontFamily());

    const TextDecorationList = {
        none: <DecoNoneIcon />,
        underline: <DecoUnderlineIcon />,
        line_through: <DecoThroughIcon />,
    };

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };
    
    return(
        <PropertiesContent _Title="Text">
            <div>
                <SelectList
                    attributeName="font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <SelectList
                    attributeName="font-weight"
                    dataList={fontClass.GetFontWeight(NodeFontFamily)}
                    selectedValue={NodeFontWeight}
                    {...commonProps}
                />
                <InputComponent
                    label={<FontSizeIcon />}
                    attributeName="font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeFontSize}
                    {...commonProps}
                />
            </div>
            <div>
                <ColorComponent
                    label="text"
                    attributeName="stroke"
                    isGradient={false}
                    isVisibility={false}
                    {...commonProps}
                />
            </div>
            <div>
                <GroupName width={84}>decoration</GroupName>
                <SvgDropdown 
                    attributeName="text-decoration" 
                    optionMap={TextDecorationList} 
                    value={NodeTextDecoration}
                    {...commonProps}
                />
                <ToggleRect 
                    disabled={_disabled}
                    onClick={(e)=>{
                        if (_disabled) return;
                        const attributeValue = Array.isArray(NodeFontStyle) ? 'italic' : ['normal'].includes(NodeFontStyle) ? 'italic' : 'normal';
                        _ChangeEvent([{attributeName: 'font-style', attributeValue}]);
                    }}
                >
                    <MdFormatItalic className={Array.isArray(NodeFontStyle) ? '' :  ['normal'].includes(NodeFontStyle) ? '' : 'active'}/>
                </ToggleRect>
            </div>
            <div>
                <GroupName width={84}>anchor</GroupName>
                <SelectList
                    attributeName="text-anchor"
                    dataList={["start", "middle", "end"]}
                    selectedValue={NodeTextAnchor}
                    {...commonProps}
                />
            </div>

            <div style={{'justifyContent': 'space-between'}}>
                <GroupName  width={84}>interface</GroupName>
                <BtnToggle 
                    _toggleID="interface" 
                    _isToggleValue={NodeInterface} 
                    _disabled={Array.isArray(NodeInterface) ? true : _disabled}
                    _IsToggle={(attributeName, attributeValue)=> _ChangeEvent([{attributeName,attributeValue }])}
                />
            </div>
            {!Array.isArray(NodeInterface) && (
                NodeInterface === '0' ? (
                    <TextArea>
                        <textarea 
                            name="text" 
                            value={Array.isArray(NodeText) ? 'Mixed' : NodeText || ''}
                            onClick={(e)=>{
                                if(e.target.value === 'Mixed') e.target.select();
                            }}
                            onChange={(e) => { 
                                _ChangeEvent([{attributeName: 'text', attributeValue: e.target.value}])
                            }}
                            onKeyDown={(e)=>{
                                if (e.nativeEvent.isComposing) {
                                    return;		
                                }
                                if( e.key === 'Enter' && e.shiftKey){
                                    return;
                                }else if(e.key === 'Enter'){
                                    e.preventDefault();
                                    e.target.blur();
                                }else if (e.ctrlKey && e.key === 'a'){
                                    e.preventDefault();
                                    e.target.select();
                                }
                            }}
                            disabled={_disabled} />
                    </TextArea>
                ) : (
                    <>
                            <div>
                                <InputComponent
                                    label='variable'
                                    labelWidth='76px'
                                    isAllowEmptyInputReset={false}
                                    value={NodeVariable}
                                    {...commonProps}
                                />
                        </div>
                            <div>
                                <InputComponent
                                    label='format'
                                    attributeName='variable-format'
                                    labelWidth='76px'
                                    isAllowEmptyInputReset={false}
                                    value={NodeVariableFormat}
                                    {...commonProps}
                                />
                            </div>
                    </>
                )
            )}
        </PropertiesContent>
    )
}

//스타일-------------------------
const TextArea = styled.div`
    height: 72px;
    margin-top: 0;

    textarea{
        margin-left:8px;
        width: 100%;
        height: 58px;
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 2px;
        overflow:auto;
        resize: none;
        line-height:20px;

        &:focus, &:hover {
            border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
        }

        &:defined {
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            outline: none;
        }
    }
`;