import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import LabelAndInput from "babylon/components/atom/LabelAndInput";
import ColorPickerContent from "babylon/components/atom/ColorPickerContent";

export default function Edge(){

    return(
        <PropertiesContent 
            _Title="Edge"
            _Toggle={ <BtnToggle _toggleID="use-text" _isToggleValue={true} _IsToggle={false} _disabled={false}/> } 
            _ToggleValue={true}
        >
            <ColorPickerContent
                Id="EdgeColor"
                label="Edge"
                isOpacity={false}
            />

            <LabelAndInput 
                id='edgesWidth'
                label='Width'
                inputType='number'
                value={''}
                labelWidth="30%"
                changeEvent={(e)=>{
                    // const targetValue = e.target.value;
                    // TODO: 변경에 따른 작업 필요
                    console.log("변경에따른 작업 필요")
                }}
            />
        </PropertiesContent>
    )
}