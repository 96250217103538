import PropertiesContent from "components/atom/PropertiesContent";
import Checkbox from "components/atom/Checkbox";
import InputComponent from "components/atom/InputComponent";
import SelectList from "components/atom/SelectList"
import ColorComponent from "components/molecule/ColorComponent";
import { GroupName } from 'components/css/common';

import styled from "styled-components";

export default function TrendXAxisInputList({_data, _ChangeEvent, _disabled}){
    const {
        type: NodeType,
        axis_position: NodeAxisPosition,
        input_type: NodeInputType,
        input_format: NodeInputFormat,
        decimal_point: NodeDecimalPoint,
        m_partition_count: NodeMPartitionCount,
        min: NodeMin,
        max: NodeMax,
        axis_stroke_width: NodeAxisStrokeWidth,
        window_fix: NodeWindowFix,
    } = _data;

    const RealtimeInputFormatList = ["auto", "number", "yyyy-MM-dd", "yyyy-MM-dd hh:mm", "yy-MM-dd", "yy-MM-dd hh:mm", "yyyy/MM/dd", "yy/MM/dd", "MM/dd", "hh:mm", "hh:mm:ss", "mm:ss", "ss.fff"]

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return (
        <PropertiesContent _Title="x-axis">
          <>
            <div>
                <CustomInputComponent label='position' attributeName='axis-position' valueType='number' labelWidth='88px' value={NodeAxisPosition} {...commonProps} />
            </div>
            <div>
                <GroupName width={76}>input-type</GroupName>
                <SelectList attributeName='input-type' dataList={["number", "timeseries"]} selectedValue={NodeInputType} {...commonProps} />
            </div>
            {NodeInputType === 'number' &&
                <div>
                    <CustomInputComponent label='decimal-point' valueType='number' labelWidth='120px' value={NodeDecimalPoint} {...commonProps} />
                </div>
            }
            {NodeType === 'axis-realtime-x' && NodeInputType === 'timeseries' &&
                <div>
                    <GroupName width={46}>format</GroupName>
                    <SelectList attributeName='input-format' dataList={RealtimeInputFormatList} selectedValue={NodeInputFormat} {...commonProps} />
                </div>
            }
            {NodeType === 'axis-histogram-x' &&
                <div>
                    <CustomInputComponent label='partition-count' attributeName='m-partition-count' valueType='number' labelWidth='88px' value={NodeMPartitionCount} {...commonProps} />
                </div>
            }
            {NodeType === 'axis-realtime-x' &&
                <>
                    <div>
                        <CustomInputComponent label='min' labelWidth='64px' value={NodeMin} {...commonProps} />
                    </div>
                    <div>
                        <CustomInputComponent label='max' labelWidth='64px' value={NodeMax} {...commonProps} />
                    </div>
                </>
            }
            <div>
                <ColorComponent label="stroke" attributeName="axis-stroke"  isGradient={false} isVisibility={false} {...commonProps}/>
            </div>
            <div>
                <CustomGroupName >stroke-width</CustomGroupName>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='axis-stroke-width' 
                    valueType='number' 
                    labelWidth='24px' 
                    value={NodeAxisStrokeWidth} 
                    {...commonProps} 
                />
            </div>
            <div>
                <Options>
                    <Checkbox 
                        _id='window-fix' 
                        checked={NodeWindowFix}
                        _disabled={_disabled}
                        onChange={(e)=> _ChangeEvent([{attributeName: 'window-fix', attributeValue: e.target.checked}])}
                    >
                        Window-Fix
                    </Checkbox>
                </Options>
            </div>
          </>
        </PropertiesContent>
    );
}

//스타일-------------------------
const Options = styled.div`
    margin-left: 0px;
    font-size: .9rem;
    color: ${({theme}) => theme.base.font_color_DarkGray};
`;

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;

const CustomGroupName = styled(GroupName)`
    width: 100%;
    max-width: 208px;
    min-width: 120px;
`;