
import PropertiesContent from "components/atom/PropertiesContent";
import Checkbox from "components/atom/Checkbox";
import BtnToggle from "components/atom/BtnToggle";
import { GroupName } from 'components/css/common';

export default function TableInputList({ _data,  _disabled, ...props }) {
    const {
        vertical_scroll_visibility: NodeVerticalScrollVisibility, horizontal_scroll_visibility: NodeHorizontalScrollVisibility,
        view_range_fix: NodeViewRangeFix, 
    } = _data;

    const ChangeVisible = (e) => {
        const attributeName = e.target.name;
        const isVisible = _data[attributeName.replace(/-/g, '_')] === 'true';
        props._ChangeEvent([{ attributeName, attributeValue: isVisible ? 'false' : 'true' }]);
    };

    const ChangeViewRangeFix = (attributeName, isToggle) => {
        const JsonData = [{
            attributeName,
            attributeValue: isToggle
        }]
        props._ChangeEvent(JsonData);
    }

    return (
        <PropertiesContent _Title={"Table"}>
            <div style={{ flexWrap: 'wrap' }}>
                <Checkbox _id='vertical-scroll-visibility' checked={NodeVerticalScrollVisibility === 'true'} onChange={ChangeVisible} _disabled={_disabled}>vertical-scroll</Checkbox>
                <Checkbox _id='horizontal-scroll-visibility' checked={NodeHorizontalScrollVisibility === 'true'} onChange={ChangeVisible} _disabled={_disabled}>horizontal-scroll</Checkbox>
            </div>
            <div style={{ 'justifyContent': 'space-between' }}>
                <GroupName width={130}>view-range-fix</GroupName>
                <BtnToggle _toggleID="view-range-fix" _isToggleValue={NodeViewRangeFix} _IsToggle={ChangeViewRangeFix} _disabled={_disabled} />
            </div>
        </PropertiesContent>

    )
}