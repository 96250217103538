import { useState, useEffect } from 'react';

import LuaSyntax from 'components/atom/LuaSyntax';

import CodeMirror from '@uiw/react-codemirror';
import {EditorState} from "@codemirror/state"
import { StreamLanguage } from '@codemirror/language';
import { lua } from '@codemirror/legacy-modes/mode/lua';
// import { javascript } from '@codemirror/lang-javascript';
import {vscodeDark} from '@uiw/codemirror-theme-vscode';
import {tooltips} from "@codemirror/view"

//completeFromList, snippetCompletion
import {autocompletion} from "@codemirror/autocomplete";

function LuaSyntax2(context) {
    const code = context.state.doc.toString(); // 현재 코드 가져오기
    const regex = /(\b(?:local|function)\s+([a-zA-Z_$][0-9a-zA-Z_$]+))/g;
    const globalRegex = /\b([a-zA-Z_$][0-9a-zA-Z_$]+)\s*=/g;
    const matches = code.match(regex);
    if (!matches) return null;

    const options = matches.map(match => {
        const parts = match.split(/\s+/);
        const name = parts[1];
        const type = parts[0] === 'function' ? 'function' : 'variable';
        return { label: name, type };
    });

    const globalMatches = code.match(globalRegex);
    if (globalMatches) {
        globalMatches.forEach(match => {
            const name = match.replace(/\s*=/, '');
            options.push({ label: name, type: 'variable' });
        });
    }

    const word = context.matchBefore(/\w*/);
    if (word.from === word.to && !context.explicit) {
        return null;
    }

    return {
        from: word.from,
        options: options
    };
}

function CodeEditor({_CodeData, _tempScript, _heigh, _isReadOnly}){
    const [codeData, setCodeData] = useState('');
    useEffect(()=>{
        setCodeData(_CodeData);
    },[_CodeData]);

    const CodeChange =(e) =>{
        setCodeData(e);
        _tempScript && _tempScript(e);
    }
    
    const StopEventBubbling = (e) =>{
        e.stopPropagation();
    }

    return(
        <CodeMirror 
            value={codeData} 
            height={_heigh !== undefined ? `${_heigh}px` : '100%'} 
            theme={vscodeDark}
            extensions={[
                StreamLanguage.define(lua),
                EditorState.tabSize.of(2),
                tooltips({
                    position:'absolute',
                    parent: document.body,
                }),
                autocompletion({
                    override: [LuaSyntax, LuaSyntax2],
                }),
            ]} 
            readOnly={_isReadOnly} 
            onChange={CodeChange} 
            onMouseDown={StopEventBubbling}
            basicSetup={{ 
                lineNumbers: !_isReadOnly, 
                foldGutter: !_isReadOnly,
                highlightActiveLine: !_isReadOnly,
                autocompletion: false
            }}
        />
    )
}

export default CodeEditor;

CodeEditor.defaultProps = {
    _isReadOnly : false,
}