import { useState, useEffect, useCallback } from "react";
import { useDispatch } from 'react-redux';

import SearchBar from "components/atom/SearchBar";
import {RepositoryRadio } from "components/atom/RadioContent";
import {BtnPrimary, BtnSecondary} from "components/atom/Button"
import GlobalResourceTemplate from "components/modal_new/GlobalResourceTemplate";
import { ModalBottomBox } from "components/css/common";
import {RESOURCE_TYPE} from 'components/common/EnuSpaceEnum';
import {GetSearchData, GetTemplateSubResource} from 'api/TemplateApi';
import useModalPopup from "hooks/useModalPopup"
import useProjectData from 'hooks/useProjectData';
import {PaginationBar} from 'components/css/common';
import { insertCanvasView} from 'reducers/projectDataReducer';

import styled from "styled-components"
import { MdOutlineFirstPage, MdOutlineLastPage, MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";

const PAGE_LIMIT = 100;
const SHOW_PAGING_NUMBER = 5;
const PAGE_OFFSET = 1;

export default function GlobalResource({ enuSpace, _Modal }) {
    const dispatch = useDispatch();
    const {DeleteModal} = useModalPopup();
    const {SetTreeData} = useProjectData();
    const [CurrentTab, setCurrentTab] = useState(RESOURCE_TYPE.SYMBOL);
    const [SelectedResource, setSelectedResource] = useState(''); //선택된 리소스 [Symbol, Image, Picture, Template
    const [SelectedTemplate, setSelectedTemplate] = useState('');

    const [ContentsInfo, setContentsInfo] = useState({});
    const [BoardList, setBoardList] = useState();
    const [CurrentPage, setCurrentPage] = useState(0);
    const [PageNumber, setPageNumber] = useState([0]);

    const GetSearchResource = useCallback((keyword, offset, isTemplate=true, isSymbol=true, isPicture=true, isImage=true) => {
        const Keyword = keyword === '' ? '*' : keyword;
        GetSearchData(Keyword, PAGE_LIMIT, offset, isTemplate, isSymbol, isPicture, isImage, false, [])
            .then((res) => {
                const {result, msg} = res.data;
                if(result === 'Success'){
                    if(msg.length === 0){
                        setContentsInfo({});
                        setBoardList();
                    }else{
                        const {MAX_PAGE, MAX_NUM, SEARCH_LIST } = msg[0];
                        setContentsInfo({ MAX_NUM, MAX_PAGE });
                        setBoardList(SEARCH_LIST);
                    }
                }
            })
    }, []);

    const CheckTabState = (CurrentTab) =>{
        const isTemplate = CurrentTab === RESOURCE_TYPE.TEMPLATE;
        const isSymbol = CurrentTab === RESOURCE_TYPE.SYMBOL;
        const isPicture = CurrentTab === RESOURCE_TYPE.PICTURE;
        const isImage = CurrentTab === RESOURCE_TYPE.IMAGE;

        return {isTemplate, isSymbol, isPicture, isImage};
    }

    useEffect(()=>{
        const {isTemplate, isSymbol, isPicture, isImage} = CheckTabState(CurrentTab);
        GetSearchResource('', CurrentPage, isTemplate, isSymbol, isPicture, isImage)
    }, [GetSearchResource, CurrentTab, CurrentPage]);

    const handleItemClick = (sys_id) => {
        setSelectedResource((prev) => (prev === sys_id ? '' : sys_id));
    };

    window.Re_CreateTemplate = (msg) =>{
        const {Path: URL, id} = JSON.parse(msg);
        SetTreeData(JSON.parse(enuSpace.GetProjectTree()));
        dispatch(insertCanvasView(URL))
        enuSpace.EventHandler(URL,"GETNODEDATA_BY_ID", JSON.stringify({id}), "TreeClick");
    }

    const BtnActionHandler = (e) => {
        const Target = BoardList.filter(item => item.SYS_ID === SelectedResource);
        const { SYS_ID, TEMPLATE_SYSTEMID, VERSION, TITLE, TYPE } = Target[0];

        switch (TYPE) {
            case RESOURCE_TYPE.SYMBOL.toLowerCase():
                enuSpace.CreateTemplateSymbol("Canvas", TEMPLATE_SYSTEMID, VERSION.toString(), SYS_ID, TITLE);
                break;
            case RESOURCE_TYPE.IMAGE.toLowerCase():
                enuSpace.CreateTemplateImage("Canvas", TEMPLATE_SYSTEMID, VERSION.toString(), SYS_ID, TITLE);
                break;
            case RESOURCE_TYPE.PICTURE.toLowerCase():
                enuSpace.CreateTemplatePicture(TEMPLATE_SYSTEMID, VERSION.toString(), SYS_ID, TITLE);
                break;
            case RESOURCE_TYPE.TEMPLATE.toLowerCase():
                {
                    GetTemplateSubResource(TEMPLATE_SYSTEMID, String(VERSION))
                        .then((res) => {
                            const { result, msg, data } = res.data;
                            if (result === 'Success') {
                                setSelectedTemplate({
                                    TargetTemplateInfo: Target[0],
                                    TemplateSubResource: data
                                })
                            } else
                                window.AddAlertMsg({ type: 'error', msg })
                        })
                    break;
                }
            default:
                console.error('Invalid Type');
                return;
        }
    }

    useEffect(()=>{
        const pagesArray = Array.from({ length: ContentsInfo.MAX_PAGE }, (_, index) => index);
        setPageNumber(pagesArray);
    }, [ContentsInfo]);

    const MovePage = (_type) =>{
        switch (_type) {
            case 'FirstPage':
                setCurrentPage(0);
                break;
            case 'PrevPage':
                if(CurrentPage >= 1){
                    setCurrentPage(CurrentPage-1);
                }
                break;
            case 'NextPage':
                if(CurrentPage < ContentsInfo.MAX_PAGE-1){
                    setCurrentPage(CurrentPage+1);
                }
                break;
            case 'LastPage':
                setCurrentPage(ContentsInfo.MAX_PAGE-1);
                break;
            default:
                break;
        }
    }

    const Pagination = () => {
        const maxPageNumber = PageNumber[PageNumber.length - 1];
        const flagNumber = Math.floor(SHOW_PAGING_NUMBER / 2);
        let startNumber = Math.max(CurrentPage - flagNumber, 0);
        let lastNumber = Math.min(CurrentPage + flagNumber, maxPageNumber);

        if(startNumber === 0){
            lastNumber = SHOW_PAGING_NUMBER - PAGE_OFFSET;
        }else if(lastNumber === maxPageNumber){
            startNumber = maxPageNumber - (SHOW_PAGING_NUMBER - PAGE_OFFSET);
        }

        return (
          <ul className="pagination">
            {PageNumber.map((pageNum) => {
                if(pageNum >= startNumber && pageNum <= lastNumber){
                    return (
                        <li
                            key={pageNum}
                            className={pageNum === CurrentPage ? 'selectPage' : ''}
                            onClick={() =>setCurrentPage(pageNum)}
                        >
                            {pageNum + PAGE_OFFSET}
                        </li>
                    )
                }
                return null;
            })}
          </ul>
        );
    };

    return (
        <GlobalResourceLayout>
            {SelectedTemplate && <GlobalResourceTemplate enuSpace={enuSpace} SelectedTemplateState={[SelectedTemplate, setSelectedTemplate]}/>}
            {!SelectedTemplate &&
                <>
                    <SearchBox>
                        <SearchBar 
                            placeholder='Keyword...'
                            isReset={false}
                            style={{ height: '38px', width: '100%' }}
                            searchEvent={(searchText) => {
                                const {isTemplate, isSymbol, isPicture, isImage} = CheckTabState(CurrentTab);
                                setCurrentPage(0);
                                GetSearchResource(searchText, 0, isTemplate, isSymbol, isPicture, isImage)
                            }}
                        />
                    </SearchBox>

                    {/* 탭 */}
                    <CustomRepositoryRadio 
                        attributeName='GlobalResourceTeb'
                        height="40px"
                        isUseKeyLabel={true}
                        dataList={RESOURCE_TYPE}
                        selectedValue={CurrentTab}
                        changeEvent={(name, value) => {
                            setCurrentPage(0);
                            setCurrentTab(value);
                            setSelectedResource('');
                        }}
                    />

                    <SearchList>
                        {BoardList?.map((item, index) => {
                            const { SYS_ID, THUMBNAIL, TITLE, PROFILE_SYSTEMID, NICKNAME, DESCRIPTION, DATE } = item;
                            const ClassName = SelectedResource === SYS_ID ? 'selected' : '';
                            const imgSrc = `data:image/png;base64,${THUMBNAIL}`

                            switch (CurrentTab) {
                                case RESOURCE_TYPE.TEMPLATE:
                                    return (
                                        <TemplateCard key={SYS_ID} className={ClassName} onClick={(e) => handleItemClick(SYS_ID)}>
                                            <div className="projectImgBox" >
                                                <img src={imgSrc} alt="" onError={(e) => {  }} />
                                            </div>

                                            <div className='infoBox'>
                                                <div className="mainInfo">
                                                    <img src={`/profile/${PROFILE_SYSTEMID}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                                                    <div className='title'>
                                                        <div className="projectTitle">{`${TITLE}`}</div>
                                                        <div className="creator">{`${NICKNAME}`}</div>
                                                    </div>
                                                </div>
                                                
                                                <div className="description" title={DESCRIPTION}>
                                                    {DESCRIPTION}
                                                </div>
                                                <div className="projectDate">
                                                    <span>{DATE}</span>
                                                </div>
                                            </div>
                                        </TemplateCard>
                                    )
                                default:
                                    return (
                                        <ResourceCard key={SYS_ID} title={TITLE} className={ClassName} onClick={() => handleItemClick(SYS_ID)}>
                                            <img src={imgSrc} alt="" onError={(e) => {  }} />
                                            <span>{TITLE}</span>
                                        </ResourceCard>
                                    )
                            }
                        })}
                    </SearchList>
                </>
            }
            
            <SearchBottomBox justifyType='space-between'>
                <PaginationBar>
                    <span onClick={(e) => MovePage('FirstPage')}><MdOutlineFirstPage /></span>
                    <span onClick={(e) => MovePage('PrevPage')}><MdOutlineChevronLeft /></span>
                    <Pagination />
                    <span onClick={(e) => MovePage('NextPage')}><MdOutlineChevronRight /></span>
                    <span onClick={(e) => MovePage('LastPage')}><MdOutlineLastPage /></span>
                </PaginationBar>
                <div>
                    <BtnSecondary label="Cancel" onClick={(e) => DeleteModal(_Modal.id)} />
                    <BtnPrimary
                        style={{width: '70px'}}
                        label={CurrentTab === RESOURCE_TYPE.PICTURE ? 'Add' : CurrentTab === RESOURCE_TYPE.TEMPLATE ? 'Ok' : 'Draw'} 
                        disabled={SelectedResource === ''}
                        onClick={BtnActionHandler}
                    />
                </div>
            </SearchBottomBox>
        </GlobalResourceLayout>
    )
}

const GlobalResourceLayout = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const SearchBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 16px;
`;

const CustomRepositoryRadio = styled(RepositoryRadio)`
    border-radius: 4px;

    &>div{
        input + label{
            border-radius: 2px;
        }
    }
`;

const SearchList = styled.ul`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 156px);
    border-bottom: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    padding: 8px 0;
    box-sizing: border-box;
    overflow-y: overlay;
    align-content: flex-start;
    gap: 8px;
`;

const ResourceCard = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100px;
    height: 126px;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 4px;
    gap: 4px;

    img{
        width: 94px;
        height: 80px;
        padding: 0 4px;
        object-fit: contain;
    }

    span{
        font-size: .9rem;
        text-align: center;
        color: ${({theme}) => theme.base.font_color_Black};
        width: 100px;
        line-height: 1.4;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.selected, &:hover{
        border-color: ${({ theme }) => theme.base.border_color_Blue};
    }
`;

const SearchBottomBox = styled(ModalBottomBox)`
    &>div{
        margin-top: auto;
    }
`;

const TemplateCard = styled.li`
    position: relative;
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
    border-radius: 4px;
    /* padding: 8px; */
    box-sizing: border-box;
    height: 196px;
    cursor: pointer;

    &.selected{
        border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
    }

    .projectImgBox{
        position: relative;
        width: 100%;
        height: 80px;
        background-color: ${({ theme }) => theme.base.background_color_Gray};
        border-bottom: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 4px 4px 0 0;
        overflow: hidden;

        &>img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        img:not([src]) {
            display: none;
        }
    }

    .description{
        margin-block: 8px;
        font-size: .85rem;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical
    }

    .infoBox{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 8px;
        gap: 8px;
    }

    .mainInfo{
        position: relative;
        display: flex;
        gap: 6px;

        img{
            width: 38px;
            height: 38px;
            overflow: hidden;
            border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
            border-radius: 50%;
            box-sizing: border-box;
        }

        .title{
            width: calc(100% - 44px);

            .projectTitle{
                font-size: 1rem;
                font-weight: bold;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .creator{
                margin-top: 6px;
                font-size: .85rem;
                opacity: .6;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .projectDate{
        font-size: .8rem;
        opacity: .6;
    }
`;