import { useState, useEffect, useCallback} from "react";

import {InputWithVerticalLabel} from "components/molecule/InputFieldLabel";
import {BtnGhost, BtnPrimary, BtnSecondary} from "components/atom/Button";
import { ModalBottomBox, BgPopup, WarningMsg, Popup, SubTitle} from 'components/css/common';
import { CheckUser } from 'hooks/useMember';

import {
    GetFriendList,
    RemoveFriend,
    SendFriendRequest,
} from 'api/SnsApi';

import styled from "styled-components"
import { MdPersonAddAlt1, MdRefresh, MdClear} from "react-icons/md";

export default function FriendListContent(){
    const systemId = localStorage.getItem('systemId');
    const email = localStorage.getItem('email');

    const [FriendList, setFriendList] = useState([]);
    const [Msg, setMsg] = useState('');
    const [AddFriendModalInfo, setAddFriendModalInfo] = useState({
        visibility: false,
        ResponseUser: '',
        ResponseUserNick: '',
        ResponseUserProfile: '',
    });

    const GetFriends = useCallback(() => {
        GetFriendList(systemId)
            .then(res => {
                const { result, msg, data } = res.data;
                if (result === 'Success') {
                    setFriendList(data);
                } else {
                    window.AddAlertMsg({ type: 'error', msg });
                }
            }).catch(err => {
                window.AddAlertMsg({ type: 'error', msg: err });
            });
    }, [systemId]);

    useEffect(() => {
        GetFriends();
    }, [GetFriends]);

    const DeleteFriendHandler = (TargetEMAIL, GROUP_SYSTEMID) => {
        if (!window.confirm(`"${TargetEMAIL}" 님을 삭제하시겠습니까?`)) return;

        RemoveFriend(systemId, [TargetEMAIL])
            .then(res => {
                const { result } = res.data;
                if (result === 'Success') {
                    GetFriends();
                }
            })
            .catch(err => {
                window.AddAlertMsg({ type: 'error', msg: err });
            })
    };

    const CheckUserEmail = (e) => {
        if (AddFriendModalInfo.ResponseUser === '') {
            setMsg('Email을 입력해주세요.');
            return;
        }

        CheckUser(AddFriendModalInfo.ResponseUser)
            .then(res => {
                const { result, msg, data} = res.data;
                if (result === 'Success') {
                    const { NICKNAME: ResponseUserNick, PROFILE_SYSTEMID: ResponseUserProfile } = data;
                    setAddFriendModalInfo(prev => ({ ...prev, ResponseUserNick, ResponseUserProfile }));
                }else {
                    setMsg(msg);
                }
            })
            .catch(err => {
                setMsg(err);
            })
    }

    return (
        <>
            <Header>
                <SubTitle className='title'>{FriendList?.length || 0}명</SubTitle>
                <div>
                    <BtnGhost 
                        label={<MdRefresh />}
                        title='Reload'
                        onClick={(e) => GetFriends()} 
                    />
                    <BtnGhost 
                        label={<MdPersonAddAlt1 />} 
                        title='Add Friend'
                        onClick={(e) => {
                            setMsg('');
                            setAddFriendModalInfo(prev => ({ ...prev, visibility: true }))
                        }} 
                    />
                </div>
            </Header>

            <MemberList>
                {FriendList?.map((FriendInfo) => {
                    const { NICKNAME, EMAIL, PROFILE_SYSTEMID } = FriendInfo;
                    return (
                        <FriendCard key={PROFILE_SYSTEMID}>
                            <img src={`/profile/${PROFILE_SYSTEMID}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                            <div>
                                <h4>{NICKNAME}</h4>
                                <p>{EMAIL}</p>
                                <MdClear className="DeleteBtn" 
                                    onClick={(e) => DeleteFriendHandler(EMAIL)} 
                                />
                            </div>
                        </FriendCard>
                    );
                })}
            </MemberList>

            {AddFriendModalInfo.visibility &&
                <ModalContent width={'300px'}>
                    <BgPopup 
                        onClick={(e) => setAddFriendModalInfo(prev => ({ ...prev, visibility: false }))}
                    />
                    <div className="PopupWrap">
                        {AddFriendModalInfo.ResponseUserNick
                            ? 
                            <CheckUserInfo>
                                <img src={`/profile/${AddFriendModalInfo.ResponseUserProfile}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                                <div id="resUserInfo">
                                    <div id="NickName">{AddFriendModalInfo.ResponseUserNick}</div>
                                    <div id="Email">{AddFriendModalInfo.ResponseUser}</div>
                                </div>
                            </CheckUserInfo>
                            : 
                            <InputWithVerticalLabel
                                id='ResponseUser'
                                label='Email'
                                placeholder='ENU@enu-tech.co.kr'
                                isRequired={true}
                                propsState={[AddFriendModalInfo, setAddFriendModalInfo]}
                            />
                        }
                        
                        <ModalBottomBox>
                            <WarningMsg>{Msg}</WarningMsg>
                            <BtnSecondary 
                                label="Cancel" 
                                onClick={(e) => setAddFriendModalInfo(prev => ({ ...prev, visibility: false, ResponseUser: '', ResponseUserNick: '', ResponseUserProfile: ''}))} 
                            />

                            {AddFriendModalInfo.ResponseUserNick
                                ? <BtnPrimary
                                    label="Send"
                                    onClick={() => {
                                        SendFriendRequest(email, AddFriendModalInfo.ResponseUser)
                                            .then(res => {
                                                const { result, msg } = res.data;
                                                if (result === 'Success') {
                                                    setAddFriendModalInfo(prev => ({ ...prev, visibility: false, ResponseUser: '', ResponseUserNick: '', ResponseUserProfile: ''}));
                                                    window.AddToastMsg(msg);
                                                } else
                                                    setMsg(msg);
                                            }).catch(err => {
                                                setMsg(err);
                                            })
                                    }}
                                />
                                : <BtnPrimary
                                    label="Ok"
                                    onClick={CheckUserEmail}
                                />
                            }
                        </ModalBottomBox>
                    </div>
                </ModalContent>
            }
        </>
    )
}

const Header = styled.div`  
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 16px;

    &>div{
        display: flex;
        gap: 8px;
    }
`;

const MemberList = styled.ul`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    margin-top: 8px;
    margin-bottom: 24px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    overflow-y: auto;
`;

const FriendCard = styled.li`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 12px 16px;
    box-sizing: border-box;

    &:hover{
        background-color: ${({ theme }) => theme.base.background_color_PaleGray};
        .DeleteBtn{
            display: block;
        }
    }

    &>img{
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        box-sizing: border-box;
        overflow: hidden;
        object-fit: cover;
        border-color: ${({theme}) => theme.base.border_color_Gray};
    }

    &>div{
        display: flex;
        position: relative;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding-block: 4px;
        gap: 8px;
        box-sizing: border-box;
        width: calc(100% - 56px);
        color: ${({theme}) => theme.base.font_color_Black};
        
        h4{
            font-size: 1rem;
            font-weight: bold;
        }

        p{
            font-size: .9rem;
            opacity: .6;
        }

        &>.DeleteBtn{
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            font-size: 1.2rem;
            color: ${({theme}) => theme.base.font_color_Red};
            padding: 2px;
            border: 1px solid ${({theme}) => theme.base.border_color_Red};
            border-radius: 2px;
            cursor: pointer;

            &:hover{
                background-color: ${({theme}) => theme.base.background_color_LightRed};
            }
        }
    }
`;

const ModalContent = styled(Popup)`

`;

const CheckUserInfo = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 8px;

    img{
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;
        width: 58px;
        height: 58px;
        box-sizing: border-box;
        overflow: hidden;
        object-fit: cover;
        background-color: #fff;
        border-color: ${({theme}) => theme.base.border_color_Gray};
    }

    #resUserInfo{
        position: relative;
        width: calc(100% - 58px);
        color: ${({theme}) => theme.base.font_color_Black};

        #NickName{
            font-size: 1.1rem;
            font-weight: bold;
            padding: 8px 0;
        }

        #Email{
            font-size: 1rem;
            opacity: .6;
        }
    }
`;