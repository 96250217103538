import React, {useState, useEffect} from "react";
import { useDispatch } from 'react-redux';

import ProjectPageContent from "components/template/ProjectPageContent";
import {LoadingLayer} from "components/organism/LoadingPage";
import useProjectData from "hooks/useProjectData";
import useEnuSpaceModule from 'hooks/useEnuSpaceModule';
import useLoadProject from 'hooks/useLoadProject';
import useWindowFun from 'hooks/useWindowFun';

import { setBaseData, setTreeData, deleteTreeData, InitProjectData, AllClearCanvasView} from 'reducers/projectDataReducer'
import { AllClearModalPopup } from 'reducers/modulePopupReducer';
import { showRightMenu } from 'reducers/rightMenuReducer';

function EditModePage({userId, projectId, projectName}){
    const dispatch = useDispatch()
    const {DeleteNodeData} = useProjectData();
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [enuSpace] = useEnuSpaceModule();
    
    const BASE_DATA = {
        userId,
        projectId,
        projectName
    }

    const URL = '/Repository/'+ BASE_DATA.userId +'/'+ BASE_DATA.projectId + '/'+BASE_DATA.projectName +'.enup';
    console.log(URL);
    useLoadProject(enuSpace,  URL, isLoadingPage);
    
    useEffect(()=>{
        DeleteNodeData();
    }, [DeleteNodeData]);

    dispatch(setBaseData(BASE_DATA));

    // 프로젝트 정보를 받아서 Explorer 창 업데이트 및 첫 픽처 페이지 오픈
    window.Re_UpdateProjectCtrl = (_msg) => {
        console.log(`%c Re_UpdateProjectCtrl---`, 'color: blue');
        const resData = JSON.parse(_msg);
        if(resData['type'] === 'success'){
            const projectTreeData = resData['data'];
            dispatch(setTreeData(projectTreeData));
            setIsLoadingPage(false);
        }else if(resData['type'] === 'failed'){
            
        }
    }

    useEffect(() => {
        dispatch(InitProjectData());
        dispatch(deleteTreeData());

        return () => {
            dispatch(AllClearModalPopup());
            dispatch(showRightMenu({
                type: null, top: null, left: null, data: {}
            }));
            dispatch(AllClearCanvasView());
        };
    }, [dispatch]);

    const {SetGlobalValues, RefreshAccessToken, ShowLink, EditModeShowMarkdown, ShowDocumentModal, cursorType} = useWindowFun(enuSpace);
    window.Re_SetGlobalValues = SetGlobalValues;
    window.Re_RefreshAccessToken = RefreshAccessToken;
    window.Re_ShowLink = ShowLink;
    window.Re_ShowMarkdown = EditModeShowMarkdown;
    window.Re_ShowDocument = ShowDocumentModal;

    return(
        <>
            <LoadingLayer marginTop={"30px"} isLoadingPage={isLoadingPage}/>
            <ProjectPageContent enuSpace={enuSpace} style={{cursor: cursorType}}/>
        </>
    )
}

export default EditModePage;