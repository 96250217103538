import { useState, useEffect, useContext } from "react";

import {
    EnuIconFolderOpen,
    EnuIconFolderClose,
    EnuIconFile,
} from "components/common/EnuSpaceIcon"
import { ARCHIVE_TREE_TYPE } from 'components/common/EnuSpaceEnum'
import { BaseInfoContext } from 'context/ArchiveContext'

import styled from 'styled-components';

export default function ArchiveTreeRowContent({ title, type, path, onClickEvent, isShow = true, children }) {
    const [initInput, setInitInput] = useState({ OriginalValue: title, Value: title });
    const [IsShow] = useState(isShow);
    const { BaseInfo } = useContext(BaseInfoContext);

    useEffect(() => {
        setInitInput({ OriginalValue: title, Value: title });
    }, [title]);

    const SetFrontIcon = (iconType) => {
        if (iconType === ARCHIVE_TREE_TYPE.FOLDER) {
            return (
                <FrontIconContent onClick={(e) => { }}>
                    <EnuIconFolderClose />
                    {children
                        ? <> {isShow ? <EnuIconFolderOpen /> : <EnuIconFolderClose />}</>
                        : <EnuIconFolderClose />
                    }
                </FrontIconContent>
            )
        } else {
            return (
                <FrontIconContent onClick={(e) => { }}>
                    <EnuIconFile />
                </FrontIconContent>
            )
        }
    }

    return (
        <>
            <TreeRow
                currentPath={BaseInfo.path === path}
                data-path={path}
                onClick={(e) => {
                    onClickEvent && onClickEvent();
                }}
            >
                {SetFrontIcon(type)}
                <TitleText title={initInput.Value}>{initInput.Value}</TitleText>
            </TreeRow>
            {children && IsShow ? children : null}
        </>
    )
}

const TreeRow = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
    box-sizing: border-box;
    background-color: ${({ currentPath, theme }) => currentPath ? theme.base.background_color_LightBlue : 'null'};
    cursor: pointer;

    &.ProjectName >input{
        font-weight: 500;
    }

    &:hover, &:focus{
        background-color: ${({ theme }) => theme.base.background_color_LightBlue};
        span{ display: block; }
    }
`;

const TitleText = styled.span`
    height: auto;
    padding: 4px 2px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
`;

const FrontIconContent = styled.span`
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding-right: 0;
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    img, svg{
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
    }
`;