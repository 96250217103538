import { createSlice } from "@reduxjs/toolkit";

const findIndexById = (state, id) => state.findIndex(item => item.id === id);

let initialState = [];
const modalPopupSlice = createSlice({
    name: 'modalPopup',
    initialState,
    reducers: {
        addModalPopup(state, action){
            const currentIndex = findIndexById(state, action.payload.id);

            if (currentIndex === -1) {
                // 모델이 없을경우 추가
                return [...state, action.payload];
            }

            if(state.length-1 === currentIndex) return; // 이미 최상인 경우
    
            // 최상으로 위치 변경
            const updatedState = [...state];
            const [removedItem] = updatedState.splice(currentIndex, 1);
            updatedState.push(removedItem);
            return updatedState;
        },
        // 기존의 데이터 + 전달받은 데이터
        updateModalData(state, action){
            const existingIndex = findIndexById(state, action.payload.id);
            if (existingIndex !== -1) {
                // 이미 존재하는 경우
                const updatedState = [...state];
                updatedState[existingIndex] = {
                    ...updatedState[existingIndex],
                    data: {
                        ...updatedState[existingIndex].data,
                        ...action.payload.newData
                    }
                };
                return updatedState;
            }
        },
        // 기존의 데이터는 삭제하고, 전달받은 데이터로 수정
        editModalData(state, action){
            const existingIndex = findIndexById(state, action.payload.id);
            if (existingIndex !== -1) {
                // 이미 존재하는 경우
                const updatedState = [...state];
                updatedState[existingIndex] = {
                    ...updatedState[existingIndex],
                    data: {
                        ...action.payload.newData
                    }
                };
                return updatedState;
            }
        },
        removeModalPopup(state, action){
            return state.filter(_index => _index.id !== action.payload)
        },
        BackToTopModal(state, action){
            const currentIndex = findIndexById(state, action.payload);

            if(currentIndex === -1) return;             //없는 경우
            if(state.length-1 === currentIndex) return; // 이미 최상인 경우
    
            const updatedState = [...state];
            const [removedItem] = updatedState.splice(currentIndex, 1);
            updatedState.push(removedItem);
            return updatedState;
        },
        AllClearModalPopup(state){
            return [];
        },
    }
})

export const { addModalPopup, removeModalPopup, BackToTopModal, AllClearModalPopup, updateModalData, editModalData} = modalPopupSlice.actions;

export default modalPopupSlice.reducer;