import {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Pagination from 'components/atom/Pagination';
import { TableContent, ChartTitle, SelectContent, GroupName, BgPopup, ModalBottomBox, InputContent, WarningMsg} from 'components/css/common';
import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import useSQLite from 'hooks/useSQLite';
import {AccessAxios} from 'components/Axios';
import Modal from 'components/modal_new/Modal';

import styled from "styled-components";
import { MdAdd, MdClear, MdModeEdit } from "react-icons/md";
function SQLiteRuntimeModal({enuSpace, _Modal}){
    const {SQLiteFileList, GetSQLiteFileList, SelectSQLite, InsertSQLite, UploadSQLiteFile} = useSQLite('');
    const {userId: MEMBER_SYSTEMID, projectId: PROJECT_SYSTEMID} = useSelector(state => state.projectData.BASE_DATA);
    const Mode = _Modal.data.mode || "";
    
    const location = useLocation();

    const [selectedFile, setSelectedFile] = useState(_Modal.data.file || '-');
    const [selectedTable, setSelectedTable] = useState('-');
    const [TableList, setTableList] = useState([]);
    const [TableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPosts, setCurrentPosts] = useState();
    const [selectRow, setSelectRow] = useState();
    const [selectedRowInfo, setSelectedRowInfo] = useState({});
    const [CreateModal, setCreateModal] = useState({
        visibility: false,
        data: null
    });
    const [fileData, setFileData] = useState();
    const [AddColumnToggle, setAddColumnToggle] = useState(false);
    const [EditColumnToggle, setEditColumnToggle] = useState(false);

    const [AddColumnInfo, setAddColumnInfo] = useState({});
    const [EditColumnInfo, setEditColumnInfo] = useState({});

    useEffect(() => {
        GetSQLiteFileList();
    }, [GetSQLiteFileList]);
    
    const SelectFile = (e) => {
        setSelectedFile(e.target.value);
    }
    const SelectTable = (e) => {
        setSelectedTable(e.target.value);
        funcSelect(e.target.value)
    }

    const CloseModal = (e) => setCreateModal({visibility:false, data: null});

    const handleOnChange = (e) => {
        setCreateModal(prevState => ({ ...prevState, data: { ...prevState.data, msg: '' }}));
        setFileData(e.target.files);
    }

    const AddColumn = (e) => {
        setCurrentPage(Math.ceil(TableData.length / 10));
        setAddColumnToggle(!AddColumnToggle)
    }

    const AddColumnChange = (e, header) => {
        setAddColumnInfo((prevInfo) => ({
            ...prevInfo,
            [header]: e.target.value
        }));
    };

    const FuncAddColumn = async(e) => {
        const columns = Object.keys(AddColumnInfo).join(', ');
        const values = Object.values(AddColumnInfo).map(value => `'${value}'`).join(', ');
        
        const query = `INSERT INTO ${selectedTable} (${columns}) VALUES (${values})`;

        await InsertSQLite(`sqlite\\${selectedFile}`, query)
        .then((res) => 
        {
            if(res.data.Result.toLowerCase() === 'ok')
            {
                setAddColumnToggle(false);
                funcSelect();
            }
        })
    };

        

    const EditColumnChange = (e, header) => {
        setEditColumnInfo((prevInfo) => ({
            ...prevInfo,
            [header]: e.target.value
        }));
    }
    
    const EditColumn = (e) => {
        setEditColumnToggle(!EditColumnToggle)
    }

    const FuncEditColumn = async (e) => {
        const updates = Object.keys(EditColumnInfo).map(key => `${key} = '${EditColumnInfo[key]}'`).join(', ');
    
        const whereClause = Object.keys(selectedRowInfo).map(key => `${key} = '${selectedRowInfo[key]}'`).join(' AND ');
    
        const query = `UPDATE ${selectedTable} SET ${updates} WHERE ${whereClause}`;
    
        await InsertSQLite(`sqlite\\${selectedFile}`, query)
        .then((res) => 
        {
            if(res.data.Result.toLowerCase() === 'ok')
            {
                setEditColumnToggle(false);
                funcSelect();
            }
        })
    };
    

    const DeleteColumn = async(e) => {
        if(window.confirm("컬럼을 삭제하시겠습니까?"))
        {
            const whereClause = Object.keys(selectedRowInfo).map(key => `${key} = '${selectedRowInfo[key]}'`).join(' AND ');
            const query = `DELETE FROM ${selectedTable} WHERE ${whereClause}`;

            await InsertSQLite(`sqlite\\${selectedFile}`, query)
            .then((res) => 
            {
                if(res.data.Result.toLowerCase() === 'ok')
                {
                    setSelectedRowInfo();
                    setSelectRow();
                    funcSelect();
                }
            })
        }
    }

    useEffect(()=>{
        setSelectRow()
        setSelectedRowInfo()
        setEditColumnInfo()
    }, [currentPosts]);
    
    useEffect(() => {   
        setAddColumnToggle(false);
        setAddColumnInfo({});
        setEditColumnToggle(false);
        setCurrentPage(1);
    },[selectedTable, selectedFile])

    const selectedRowInfoChange = (index, obj) => {
        if(selectRow === index)
        {
            setSelectRow();
        }
        else
        {
            setSelectRow(index);
        }
        
        if(selectedRowInfo === obj)
        {
            setSelectedRowInfo();
            setEditColumnInfo();
        }
        else
        {
            setEditColumnToggle(false);
            setSelectedRowInfo(obj);
            setEditColumnInfo(obj);
        }

        
    }
    
    const funcSelect = async (table) => {
        await SelectSQLite('sqlite\\' + selectedFile, `SELECT * FROM ${table}`)
            .then((res) => setTableData(res.data.Data));
    };

    useEffect(() => {
        async function fetchData()
        {
            if(selectedFile !== '-')
            {
                var MSG={ };
                if(location.pathname === '/Publish/runtimeMode' || location.pathname === '/Publish/link')
                {
                    MSG={
                        MEMBER_SYSTEMID:'Publish',
                        PROJECT_SYSTEMID,
                        FILE_NAME:'sqlite/' + selectedFile
                    }
                }
                else
                {
                    MSG={
                        MEMBER_SYSTEMID,
                        PROJECT_SYSTEMID,
                        FILE_NAME:'sqlite/' + selectedFile
                    }
                }
                await AccessAxios.post('/api/SQLite/GetSQLiteTableList', MSG)
                    .then(res => 
                    {
                        setTableList(res.data.Data);
                    })
            }
        }
        fetchData()
    },[selectedFile, MEMBER_SYSTEMID, PROJECT_SYSTEMID, location.pathname ])
    


    return(
        <div style={{maxHeight:'80%', height:'80%'}}>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <div>
                    <GroupName width={100} htmlFor='attrType' className="labelTitle">File Name</GroupName>
                    <SelectContent value={selectedFile} onChange={SelectFile} >
                        <option key="-" value="-">-</option>
                        {(SQLiteFileList.child && SQLiteFileList.child.length !== 0) &&
                            SQLiteFileList.child.map((_tableName) => (
                                <option key={_tableName.title} value={_tableName.title}>
                                {_tableName.title}
                            </option>
                        ))}
                    </SelectContent>
                </div>
              
            </div>
            {TableList && selectedFile !== '-' &&
            <div style={{paddingTop:'12px'}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div>
                    <GroupName width={100} htmlFor='attrType' className="labelTitle">Table Name</GroupName>
                    <SelectContent value={selectedTable} onChange={SelectTable} >
                        <option key="-" value="-">-</option>
                        {(TableList && TableList !== 0) &&
                            TableList.map((_tableName) => (
                            <option key={_tableName.content} value={_tableName.content}>
                                {_tableName.content}
                            </option>
                        ))}
                    </SelectContent>
                    </div>
                </div>
            </div>
            }
            {TableData !== undefined && TableData.length > 0 && 
                <>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <ChartTitle className='title'>{`${selectedTable} _ ${TableData.length || 0}개`}</ChartTitle>
                        {Mode === 'edit' &&
                            <div style={{display:'flex', gap:'8px', height:'32px', marginTop:'8px'}}>
                                <HoverItem onClick={AddColumn} style={{width:'80px'}}>
                                    <MdAdd style={{ color: 'royalblue' }} />&nbsp; Add
                                </HoverItem>
                                {selectedRowInfo === undefined ? 
                                    <HoverItem title="Select Column" style={{width:'80px', backgroundColor:'#efefef', cursor:'default'}}>
                                        <MdModeEdit style={{ color: 'royalblue' }}/>&nbsp; Edit 
                                    </HoverItem>
                                    :
                                    <HoverItem title="Edit Column" onClick={EditColumn} style={{width:'80px'}}>
                                        <MdModeEdit style={{ color: 'royalblue' }}/>&nbsp; Edit 
                                    </HoverItem>
                                }
                                    
                                {selectedRowInfo === undefined ? 
                                    <HoverItem title="Select Column" style={{width:'80px', backgroundColor:'#efefef', cursor:'default'}}>
                                        <MdClear style={{ color: 'royalblue' }} />&nbsp; Delete
                                    </HoverItem>
                                    :
                                    <HoverItem title="Delete Column" onClick={DeleteColumn} style={{width:'80px'}}>
                                        <MdClear style={{ color: 'royalblue' }} />&nbsp; Delete
                                    </HoverItem>
                                }
                                
                            </div>
                        }
                    </div>
                    <Table>
                        <div>
                            <table>
                            <thead>
                                <tr>
                                {TableData &&
                                    Object.keys(TableData[0]).map((header) => (
                                    <th key={header}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {currentPosts &&
                                currentPosts.map((data, index) => (
                                    <tr
                                    key={index}
                                    className={selectRow === index ? 'selectedRow' : ''}
                                    onClick={(e) => {
                                        selectedRowInfoChange(index, data);
                                    }}
                                    >
                                    {Object.keys(data).map((key, subIndex) => (
                                        data === selectedRowInfo && EditColumnToggle ? (
                                        <td key={subIndex} onClick={(e) => e.stopPropagation()}>
                                            <input
                                            type="text"
                                            style={{ border: '1px gray solid' }}
                                            defaultValue={selectedRowInfo[key]}
                                            onChange={(e) => EditColumnChange(e, key)}
                                            />
                                        </td>
                                        ) : (
                                        <td key={subIndex}>{data[key]}</td>
                                        )
                                    ))}
                                    </tr>
                                ))}
                                {AddColumnToggle && currentPage === Math.ceil(TableData.length / 10) && (
                                <tr>
                                    {Object.keys(TableData[0]).map((header) => (
                                    <td key={header}>
                                        <input
                                        type="text"
                                        style={{ border: '1px gray solid' }}
                                        placeholder={'input value: ' + header}
                                        onChange={(e) => AddColumnChange(e, header)}
                                        />
                                    </td>
                                    ))}
                                </tr>
                                )}
                            </tbody>
                            </table>
                            {AddColumnToggle && currentPage === Math.ceil(TableData.length / 10) && (
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                                <HoverItem title="Add Column" style={{ width: '80px' }} onClick={FuncAddColumn}>
                                Add
                                </HoverItem>
                                <HoverItem title="Add Cancle" style={{ width: '80px' }} onClick={AddColumn}>
                                Cancel
                                </HoverItem>
                            </div>
                            )}
                            {EditColumnToggle && 
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                                <HoverItem title="Edit Column" style={{ width: '80px' }} onClick={FuncEditColumn}>
                                Edit
                                </HoverItem>
                                <HoverItem title="Edit Cancle" style={{ width: '80px' }} onClick={EditColumn}>
                                Cancel
                                </HoverItem>
                        </div>
                            }
                        </div>
                    </Table>
                    <Pagination _dataList={TableData} _currentPage={[currentPage, setCurrentPage]} amount={10} _setCurrentPosts={setCurrentPosts}/>
                </>
            }
            {/* SQLITE 파일 업로드 Modal */}
            {(CreateModal['visibility'] && CreateModal['data']['type']==='Upload File') &&
                <div style={{position: 'fixed',top: '0px',left: '0px',width: '100%', height: '100%',  zIndex: '10'}}>
                    <BgPopup onClick={CloseModal}/>
                    <div>
                    <Modal _title={CreateModal['data']['type']} _height={182} _width={200} _enableResizing={false} _closeModal={CloseModal}>
                        <FlexContent>
                            <InputContent type={"file"} accept=".db, .sqlite" onChange={handleOnChange}/>
                        </FlexContent>
                        <WarningMsg>{CreateModal['data']['msg']}</WarningMsg>
                        <ModalBottomBox>
                            <BtnSecondary label="Cancel" onClick={CloseModal} />
                            <BtnPrimary label="Upload" onClick={(e) => {
                                const fileExt = fileData[0].name.split('.').pop();
                                if(fileExt !== 'db'&& 
                                fileExt !== 'sqlite'&&
                                fileExt !== 'sqlitedb' &&
                                fileExt !== 'sqlite3' &&
                                fileExt !== 'db3'
                                ){
                                    setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:'SQLite db파일을 선택해주세요.'}}))
                                    return;
                                }

                                UploadSQLiteFile(fileData, CreateModal['data']['path'])
                                    .then((res) => {
                                        if (res === true) {
                                            CloseModal();
                                            GetSQLiteFileList();
                                        } else {
                                            setCreateModal(prevState => ({ ...prevState, data: { ...prevState.data, msg: '이미 사용중인 이름 입니다.' } }))
                                        }
                                    }).catch((err) => {
                                        setCreateModal(prevState => ({ ...prevState, data: { ...prevState.data, msg: err } }))
                                    });
                            }} />
                        </ModalBottomBox>
                    </Modal>
                    </div>
                </div>
            }
        </div>
        
    )
}

export default SQLiteRuntimeModal;

//스타일----------------------------------------
const Table = styled(TableContent)`
    height: calc(100% - 80px);

    th, td {
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            width: 200px;
            height: 32px;
            text-align: center;
            box-sizing: border-box;
            vertical-align: middle;
            word-break: break-all;
            font-size: .9rem;
        }

    td{
        user-select: text;
    }

    &.selectedRow {
        background-color:${({ theme }) => theme.base.background_color_LightBlue};
    }
`;



const FlexContent = styled.div`
    display: flex;
    align-items: baseline;

    &>span{
        margin-left: 8px;
    }
`;

const HoverItem = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    border:1px #eee solid;
    padding:4px;
    border-radius:4px;
    &:hover
    {
        background-color :  ${({theme}) => theme.base.background_color_LightBlue};
    }
`;
