import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import { dracula } from "react-syntax-highlighter/dist/cjs/styles/prism";

function Markdown({_markdownInfo, _rootPath = '/', _pathInfo}){
    const transformImageUri = (uri) => {
        // 이미지 경로가 상대 경로인 경우
        if (uri.startsWith('./')) {
          // 상대 경로에서 파일 이름을 추출합니다.
          const fileName = uri.substring(2);

          const searchParams = new URLSearchParams(window.location.search);
          const PathInfo = _pathInfo === undefined ? searchParams.get('path') : _pathInfo
          const arrayPath = PathInfo.split('\\');
          arrayPath.pop();

          // 현재 경로와 파일 이름을 합쳐서 새로운 경로를 만듭니다.
          return _rootPath + arrayPath.join('/') + '/' + fileName;
        }

        // 이미지 경로가 상대 경로가 아닌 경우는 그대로 반환합니다.
        return uri;
    }
    
    return(
        <ManualContent className="markdown-body">
            <ReactMarkdown 
                children={_markdownInfo}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]} 
                transformImageUri={transformImageUri}

                components={{
                    img: ({node, ...props}) => <img style={{maxWidth: '100%'}}{...props} alt=""/>,
                    code({node, inline, className, children, ...props}) {
                      const match = /language-(\w+)/.exec(className || '')
                      return !inline && match ? (
                        <SyntaxHighlighter
                          {...props}
                          children={String(children).replace(/\n$/, '')}
                          language={match[1]}
                          style={dracula}
                          PreTag="div"
                        />
                      ) : (
                        <code {...props} className={className}>
                          {children}
                        </code>
                      )
                    }
                  }}
            />
        </ManualContent>
    )
}

export default Markdown;

//스타일 ------------------------------------
const ManualContent = styled.div`
    padding: 8px 16px;
    width: 100%;
    box-sizing: border-box;
`