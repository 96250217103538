import {AccessAxios} from 'components/Axios';
const BaseURL = '/api/EnuCanvas';

/**
 * enuCanvas를 생성하는 함수
 * @param {String} MEMBER_SYSTEMID   - 사용자 시스템 아이디
 * @param {Number} PUBLISH_MODE      - publish 모드
 * @param {String} ENUCANVAS_NAME    - 캔버스 이름
 * @param {String} DESCRIPTION       - 캔버스 설명
 * @returns 
 */
export const CreateEnuCanvas = async(MEMBER_SYSTEMID, PUBLISH_MODE, ENUCANVAS_NAME, DESCRIPTION) =>{
    // CONFIG, PICTURE_TAG 고정된 값을 사용
    const MSG = {
        MEMBER_SYSTEMID,
        PUBLISH_MODE,
        ENUCANVAS_NAME, 
        DESCRIPTION, 
        CONFIG : '', 
        PICTURE_TAG : '<svg outside-color="rgb(70,70,70)" background-color="rgb(211,211,211)">',
    };
    try{
        const res = await AccessAxios.post(`${BaseURL}/CreateEnuCanvas`, MSG);
        return res;
    }catch(err){
        throw err;
    }   
}

/**
 * enuCanvas 업데이트 함수
 * 변경할 내용만 API 에 요청한다.
 * EnuCanvasName 변경시에만 PRE_ENUCANVAS_NAME, AFT_ENUCANVAS_NAME 사용 
 * @param {String} MEMBER_SYSTEMID      - 사용자 Id
 * @param {String} ENUCANVAS_SYSTEMID   - EnuCanvas Id
 * @param {String} PRE_ENUCANVAS_NAME   - 현 EnuCanvas Name
 * @param {String} AFT_ENUCANVAS_NAME   - 변경할 EnuCanvas Name
 * @param {String} DESCRIPTION          - EnuCanvas 설명
 * @param {Number} PUBLISH_MODE         - publish 모드
 * @returns 
 */
export const UpdateEnuCanvasInfo = async(MEMBER_SYSTEMID, ENUCANVAS_SYSTEMID, PRE_ENUCANVAS_NAME, AFT_ENUCANVAS_NAME, DESCRIPTION, PUBLISH_MODE) =>{
    const MSG = {
        MEMBER_SYSTEMID, 
        ENUCANVAS_SYSTEMID, 
        PRE_ENUCANVAS_NAME: AFT_ENUCANVAS_NAME ? PRE_ENUCANVAS_NAME : undefined,    //EnuCanvasName 변경시에만 param 사용
        AFT_ENUCANVAS_NAME, 
        DESCRIPTION, 
        PUBLISH_MODE,
    };
    try{
        const res = await AccessAxios.post(`${BaseURL}/UpdateEnuCanvasInfo`, MSG);
        return res;
    }catch(err){
        throw err;
    }   
}

/**
 * enuCanvas 리스트를 가져오는 함수
 * @param {String} MEMBER_SYSTEMID   - 사용자 시스템 아이디
 * @returns 
 */
export const GetEnuCanvasList = async(MEMBER_SYSTEMID) =>{
    const MSG = {MEMBER_SYSTEMID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetEnuCanvasList`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * enuCanvas 삭제하는 함수
 * @param {String} MEMBER_SYSTEMID 
 * @param {String} ENUCANVAS_SYSTEMID
 * @returns 
 */
export const DeleteEnuCanvas = async(MEMBER_SYSTEMID, ENUCANVAS_SYSTEMID) =>{
    const MSG = {MEMBER_SYSTEMID, ENUCANVAS_SYSTEMID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/DeleteEnuCanvas`, MSG);
        return res;
    }catch(err){
        throw err;
    }
};

/**
 * enuCanvas 썸네일 이미지 저장 함수
 * @param {String} MEMBER_SYSTEMID 
 * @param {String} ENUCANVAS_SYSTEMID
 * @param {String} SVG_SYS_ID
 * @param {String} FILEDATA
 * @returns 
 */
export const SaveCanvasThumbnail = async(MEMBER_SYSTEMID, ENUCANVAS_SYSTEMID, SVG_SYS_ID, FILEDATA) =>{
    const MSG = {MEMBER_SYSTEMID, ENUCANVAS_SYSTEMID, SVG_SYS_ID, FILEDATA};
    try{
        const res = await AccessAxios.post(`${BaseURL}/SaveCanvasThumbnail`, MSG);
        return res;
    }catch(err){
        throw err;
    }
};

/**
 * enuCanvas 썸네일 이미지 가져오는 함수
 * @param {String} MEMBER_SYSTEMID 
 * @param {String} ENUCANVAS_SYSTEMID
 * @param {String} SVG_SYS_ID
 * @param {String} TIME
 * @returns 
 */
export const GetCanvasThumbnail = async(MEMBER_SYSTEMID, ENUCANVAS_SYSTEMID, SVG_SYS_ID, TIME) =>{
    const MSG = {MEMBER_SYSTEMID, ENUCANVAS_SYSTEMID, SVG_SYS_ID, TIME};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetCanvasThumbnail`, MSG);
        return res;
    }catch(err){
        throw err;
    }
};

/**
 * enuCanvas 전체 썸네일 이미지 가져오는 함수
 * @param {String} MEMBER_SYSTEMID 
 * @param {String} ENUCANVAS_SYSTEMID
 * @returns 
 */
export const GetCanvasThumbnailAll = async(ENUCANVAS_SYSTEMID) =>{
    const MSG = {ENUCANVAS_SYSTEMID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetCanvasThumbnailAll`, MSG);
        return res;
    }catch(err){
        throw err;
    }
};

/**
 * enuCanvas MainPage 명 불러오는 함수
 * @param {String} MEMBER_SYSTEMID 
 * @param {String} ENUCANVAS_SYSTEMID
 * @param {String} ENUCANVAS_NAME
 * @returns 
 */
export const GetActiveView = async(MEMBER_SYSTEMID, ENUCANVAS_SYSTEMID, ENUCANVAS_NAME) =>{
    const MSG = {MEMBER_SYSTEMID, ENUCANVAS_SYSTEMID, ENUCANVAS_NAME};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetActiveView`, MSG);
        return res;
    }catch(err){
        throw err;
    }
};