
import {useState, useEffect} from "react";

import styled from "styled-components";
import PropTypes from 'prop-types';
import { MdOutlineArrowDropDown,  MdOutlineArrowDropUp} from "react-icons/md";

export default function PropertiesContent({_Title, _Toggle, _ToggleValue, children, _isTitle= true}){
    const [isShow, setIsShow] = useState(_ToggleValue !== false);   //자식 활성화 여부

    //_Toggle을 사용시에만 _ToggleValue 에따라 초기 state값을 설정
    useEffect((e)=>{
        if(_ToggleValue !== undefined)
            setIsShow(_ToggleValue);
    },[_ToggleValue]);

    const handlerToggle = () => {
        if (_ToggleValue === false) return;
        setIsShow(!isShow);
    };

    const ArrowIcon = () => <span>{isShow ? <MdOutlineArrowDropDown /> : <MdOutlineArrowDropUp />}</span>

    return(
        <Content isShow={_isTitle ? (children ? isShow : false) : _isTitle}>
            {_isTitle && 
                <Title isCursor={_Toggle ? _ToggleValue : true}>
                    <span onClick={handlerToggle}>
                        <p title={_Title}>{_Title}</p>
                        {_Toggle 
                            ? (_ToggleValue && children) ? ArrowIcon() : null
                            : ArrowIcon()
                        }            
                    </span>
                    {_Toggle && _Toggle}
                </Title>
            }

            {isShow &&  <ChildrenDiv>{children}</ChildrenDiv> }
        </Content>
    )
}


PropertiesContent.propTypes = {
    _Title: PropTypes.string.isRequired,       // 타이틀명(필수)
}

// 스타일-----------------------------------------------------
const ChildrenDiv = styled.div`
    display: flex;
    flex-direction: column;

    >div{
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-left: 8px;
        max-width: 350px;
        
        &.margin-top-div{
            margin-top: 16px;
        }
    }
`;

const Content = styled.div`
    position: relative;
    padding: 8px;
    padding-bottom: ${({isShow}) => isShow ? '16px' : null};
    cursor: default;
    font-size: .9rem;
    color: ${({theme}) => theme.base.font_color_Black};
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
`;

const Title = styled.div`
    font-weight: 500;
    padding-left: 8px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 350px;

    &>span{
        display: flex;
        position: relative;
        width: 100%;
        align-items: center;
        cursor: ${({isCursor}) => isCursor ? 'pointer' : 'default'};

        >p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 28px;
            line-height: 1.8rem;
            user-select: none;
        }

        &>span{
            color: ${({theme}) => theme.base.font_color_DarkGray};
            font-size: large;
            padding-left: 8px;
            display: inline-block;
            width: 16px;
            height: 16px;
            overflow: hidden;
        }
        
        >label{
            position: absolute;
            right: 0;
        }
    }
`;