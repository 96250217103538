import {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";

function TrendInput({_id , _value, _valueType = "string", _ChangeEvent, ...props}){
    const [input, setInput] = useState('');

    useEffect(()=>{
        setInput(_value);
    }, [_value]);

    const BlurEvent = (e) =>{
        const JsonData = [ { attributeName : e.target.id, attributeValue : e.target.value } ];
        _ChangeEvent(JsonData);
    }

    const KeyDownEvent = (e) =>{
        if(e.key === 'Enter'){
            const JsonData = [ { attributeName : e.target.id, attributeValue : e.target.value } ];
            _ChangeEvent(JsonData);
        }
    }

    return(
        <InputBox name={_id} id={_id} type={_valueType==="string" ? "text" : "number"} value={input || ""} onKeyDown={KeyDownEvent} onBlur={BlurEvent} onChange={(e)=>setInput(e.target.value)} {...props}/>
    )
}

export default TrendInput;

TrendInput.propTypes = {
    _id: PropTypes.string,                      //label, input의 연결 
    _valueType: PropTypes.string,               //int, float 등 타입
}

// 스타일-----------------------------------------------------
const InputBox = styled.input`
    width: 146px;
    margin-left: 8px;
`;