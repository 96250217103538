import {useState, useEffect} from "react";
import PropertiesContent from "components/atom/PropertiesContent";
import useImageList from 'hooks/useImageList';

import styled from "styled-components"

export default function DescContent({_data, _ChangeEvent, _disabled}){
    const {
        desc: NodeDesc,
        from: NodeFrom,
        imageDescKey: NodeImageDescKey,
    } = _data;
    const [nodeDesc, setNodeDesc] = useState('');
    const {DescriptionData, SetDescription} = useImageList();

    useEffect((e)=>{
        setNodeDesc(NodeDesc);
    }, [NodeDesc]);

    const ChangeNodeDesc = (attributeName, attributeValue) =>{
        const JsonData = [{attributeName,attributeValue}]
        if(NodeFrom === 'ImageListContent'){
            const updatedDescriptionData = {...DescriptionData};
            updatedDescriptionData[NodeImageDescKey]['Description'] = attributeValue;
            SetDescription(JSON.stringify({...updatedDescriptionData}));
        }else {
            _ChangeEvent(JsonData)
        }
    }

    if(NodeDesc || NodeDesc === ''){
        return (
            <PropertiesContent _Title="Desc"  >
                <TextArea style={{ 'height': 'auto' }}>
                    <textarea name="desc" title="desc" value={nodeDesc}
                        onChange={(e) => {
                            setNodeDesc(e.target.value)
                        }}
                        onKeyDown={(e) => {
                            if (e.nativeEvent.isComposing)
                                return;

                            if (e.key === 'Enter' && e.shiftKey) {
                                return;
                            } else if (e.key === 'Enter') {
                                e.preventDefault();
                                e.target.blur();
                            } else if (e.ctrlKey && e.key === 'a') {
                                e.preventDefault();
                                e.target.select();
                            }
                        }}
                        onBlur={(e)=>{
                            ChangeNodeDesc('desc', e.target.value)
                        }}
                        disabled={_disabled}
                    />
                </TextArea>
            </PropertiesContent>
        )
    }
}

const TextArea = styled.div`
    textarea{
        margin-left: 8px;
        width: 100%;
        height: 56px;
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 2px;
        overflow:auto;
        resize: none;
        line-height:20px;

        &:focus, &:hover {
            border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
        }

        &:defined {
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            outline: none;
        }
    }    
`;