import { useState, useEffect, useRef } from 'react';

import {BtnPrimary, BtnSecondary, BtnGhost} from "components/atom/Button";
import CodeEditor from "components/atom/CodeEditor";
import {GroupName, ChartTitle, SelectContent, ModalBottomBox} from 'components/css/common';
import {SUPPORT_FUNCTION} from 'components/common/EnuSpaceEnum'
import useModalPopup from 'hooks/useModalPopup';

import styled from "styled-components";

export default function AddEvent({enuSpace, _Modal}){
    const {
        id: ModalId,
        data:{
            id: ModalDataId,
            mode: ModalDataMode,
            object_name: ModalDataObjectName,
            CurrentSvgInfo: ModalDataCurrentSvgInfo,
            EventInfoList: ModalDataEventInfoList,
            eventGroupName: ModalDataEventGroupName,
            eventName: ModalDataEventName,
        }
    } = _Modal;

    const IsAddMode = ModalDataMode === 'Add';
    const IsObjectNameUse = ModalDataObjectName ==='use';

    const { DeleteModal, UpdateModalData} = useModalPopup();
    const [SelectedEvent, setSelectedEvent] = useState('');
    const [TempScript, setTempScript] = useState('');
    const [OutputConsolLog, setOutputConsolLog] = useState('');

    useEffect(()=>{
        if(ModalDataMode === 'Add'){
            const eventfunction = ModalDataEventInfoList.eventfunction ;
            for (const EventName of Object.values(SUPPORT_FUNCTION)){
                if(eventfunction === null || eventfunction[EventName] === undefined){
                    const initFunctionCode = InitEventMap(EventName);
                    setSelectedEvent(initFunctionCode)
                    setTempScript(Object.values(initFunctionCode)[0]);
                    break;
                }
            }
        }else {
            const initEventScript = { [ModalDataEventName]: ModalDataEventInfoList[ModalDataEventGroupName][ModalDataEventName], };
            setSelectedEvent(initEventScript);
            setTempScript(Object.values(initEventScript)[0]);
        }
        setOutputConsolLog('');
    }, [ModalDataMode, ModalDataEventInfoList, ModalDataEventName, ModalDataEventGroupName]);

    const InitEventMap = (EventName) => {
        const isKeyEvent = [SUPPORT_FUNCTION.ONKEYDOWN, SUPPORT_FUNCTION.ONKEYUP].includes(EventName);
        const functionParam = isKeyEvent ? 'keycode, ctrl, alt, shift' : '';
        return {[`${EventName}`]: `function ${EventName}(${functionParam})\n\n\t--TODO Add your lua script code here\n\t\nend`}
    }

    const ChangeEventType = (e) =>{
        setSelectedEvent(InitEventMap(e.target.value))
    };

    const ExecuteScriptCode = (e) =>{
        const eventName = Object.keys(SelectedEvent)[0];
        const symbolName = ModalDataCurrentSvgInfo?.symbolName || "";

        enuSpace.ExecuteScript(ModalDataCurrentSvgInfo['URL'], ModalDataId, eventName, ModalDataCurrentSvgInfo['Type'], symbolName);
    }

    const CompileScriptCode = (e) => {
        RegistScript();
    }

    const AcceptScriptCode = (e) => {
        RegistScript();
        DeleteModal(ModalId);
    }

    const AppendToConsole = (_msg) => OutputConsolLog === '' ? setOutputConsolLog(_msg) : setOutputConsolLog(`${OutputConsolLog}\n${_msg}`);

    const RegistScript = () => {
        let eventName = Object.keys(SelectedEvent)[0];
        let eventGroupName = 'eventfunction';

        const arrSupportFunction = Object.entries(SUPPORT_FUNCTION)
                                        .filter(([key, value]) => key !== 'USERFUNCTION')
                                        .map(([key, value]) => value);
        
        const isUserfunction = (!arrSupportFunction.includes(eventName) || eventName === '_userfunction');
        if (isUserfunction) {
            eventName = TempScript.substring(TempScript.indexOf(' ') + 1, TempScript.indexOf('('));
            eventGroupName = 'userfunction';
            if (eventName === '_userfunction') {
                AppendToConsole('"_userfunction" 명은 사용하실수 없습니다.')
                return;
            }
        }

        const symbolName = ModalDataCurrentSvgInfo?.symbolName || "";
        enuSpace.RegistScript(ModalDataCurrentSvgInfo['URL'], ModalDataId, eventName, ModalDataCurrentSvgInfo['Type'], TempScript, symbolName);
        window.ReloadEventTree();

        //[eventName]: tempScript
        const EventInfoList = { eventfunction: null, userfunction: null };
        EventInfoList[eventGroupName] = { [eventName]: TempScript };

        const copyModalData = {
            ..._Modal,
            data: {
                id: ModalDataId,
                mode: 'Edit',
                EventInfoList,
                CurrentSvgInfo: ModalDataCurrentSvgInfo,
                eventGroupName,
                eventName,
            },
        }

        UpdateModalData(
            copyModalData['id'], 
            {
                id: ModalDataId,
                mode: 'Edit',
                EventInfoList,
                CurrentSvgInfo: ModalDataCurrentSvgInfo,
                eventGroupName,
                eventName,
            }
        );
    }

    window.Re_RegistScript = (_msg) =>{
        const {type, data} = JSON.parse(_msg);
        if(data[0] === null)
            return;

        if(type === 'success'){
            AppendToConsole(data);
        }else if(type === 'failed'){
            AppendToConsole(data);
        }
    }

    window.Re_CompileScript = (_msg) =>{
        const {type, data} = JSON.parse(_msg);
        if(type === 'success'){
            AppendToConsole(data);
        }else if(type === 'failed'){
            AppendToConsole(data);
        }
    }

    //크기 조절 관련 함수
    const EditBox = useRef();
    const OutputBox = useRef();
    const [isReSize, setIsReSize] = useState(false);
    const [initialPos, setInitialPos] = useState('');
    const [initialSize, setInitialSize] = useState('');

    const initial = (e) => {
        setInitialPos(e.clientY);
        setInitialSize({ EditBox: EditBox.current.offsetHeight, OutputBox: OutputBox.current.offsetHeight });
        setIsReSize(true);
        EditBox.current.style.userSelect = 'none';
        OutputBox.current.style.userSelect = 'none';
    }

    const resize = (e) => {
        if (isReSize) {
            const MaxHeight = OutputBox.current.parentNode.offsetHeight - 100;
            let EditHeight = parseInt(initialSize['EditBox']) + parseInt(e.clientY - initialPos);
            let OutputHeight = parseInt(initialSize['OutputBox']) - parseInt(e.clientY - initialPos);

            if (EditHeight < 108) {
                EditHeight = 108;
                OutputHeight = MaxHeight - 156;
            } else if (OutputHeight < 108) {
                OutputHeight = 108;
                EditHeight = MaxHeight - 156;
            }

            OutputBox.current.style.height = `${OutputHeight}px`;
            const editHeight = OutputHeight + 24 + 38 + 62;
            EditBox.current.style.height = `calc(100% - ${editHeight}px)`;
        }
    }

    const onDragEnd = (e) => {
        resize(e);
        setIsReSize(false);
        EditBox.current.style.userSelect = 'auto';
        OutputBox.current.style.userSelect = 'auto';
    }
    //  /크기 조절 관련 함수

    return(
        <>
            <ModalHeader>
                <div>
                    <div style={{ width: '180px', display: 'flex', alignItems: 'baseline' }}>
                        <GroupName width={36} htmlFor='ObjectId'>ID: </GroupName>
                        <EventId name="EventId" onChange={(e) => { }} title={ModalDataId || ''} value={ModalDataId || ''} />
                    </div>
                    <div style={{ width: '200px' }}>
                        <GroupName htmlFor='ObjectEventType'>Event: </GroupName>
                        <SelectContent
                            name="ObjectEventTypeSelect"
                            value={Object.keys(SelectedEvent)[0]}
                            title={Object.keys(SelectedEvent)[0]}
                            onChange={ChangeEventType}
                            onFocus={(e) => document.activeElement.blur()}
                            width={144}
                            disabled={!IsAddMode}
                        >
                            {/* 이벤트 추가시 */}
                            {IsAddMode &&
                                Object.entries(SUPPORT_FUNCTION).map((EventInfo) => {
                                    const [, EventName] = EventInfo;
                                    const ActiveEvent = ModalDataEventInfoList.eventfunction;

                                    if(ActiveEvent === null || ActiveEvent[EventName] === undefined ) {
                                        return <option key={EventName} value={EventName}>{EventName}</option>
                                    }else 
                                        return null;
                                })
                            }

                            {/* 이벤트 수정시 */}
                            {!IsAddMode && SelectedEvent &&
                                <option key={Object.keys(SelectedEvent)[0]} value={Object.keys(SelectedEvent)[0]}>{Object.keys(SelectedEvent)[0]}</option>
                            }
                        </SelectContent>
                    </div>
                </div>
                <BtnGhost label="Execute" onClick={(e) => { setOutputConsolLog(''); ExecuteScriptCode(e); }} />
            </ModalHeader>

            <EditContent ref={EditBox}>
                <CodeEditor _CodeData={Object.values(SelectedEvent)[0]} _tempScript={setTempScript} _isReadOnly={IsObjectNameUse}/>
            </EditContent>

            <OutputContent ref={OutputBox}>
                <OutputTitle draggable='true' onDragStart={initial} onDrag={resize} onDragEnd={onDragEnd}>Output</OutputTitle>
                <CodeEditor _CodeData={OutputConsolLog} _isReadOnly={true}/>
            </OutputContent>

            <ModalBottomBox>
                <BtnSecondary label="Accept" onClick={AcceptScriptCode} disabled={IsObjectNameUse} />
                <BtnPrimary label="Compile" onClick={CompileScriptCode} disabled={IsObjectNameUse} />
            </ModalBottomBox>
        </>
    )
}

//스타일--------------------------
const ModalHeader =  styled.div`
    display: flex;
    align-items: center;
    justify-content:space-between;
    cursor:default; 

    &>div{
        display: flex;
        align-items: center;
    }
`;

const EventId = styled.input`
    overflow: hidden;
    text-overflow: ellipsis;
    height: 38px;
    box-sizing: border-box;
    font-size: .9rem;
    border: 0 none;
    &:focus {
        outline: none;
    }
`;

const EditContent = styled.div`
    cursor:text; 
    height: calc(100% - 232px);
    min-height: 108px;

    &>div{
        height: 100%;
    }
`

const OutputContent = styled.div`
    min-height: 108px;
    height: 108px;

    &>span{
        margin-top: 0;
    }

    &>div{
        height: calc(100% - 28px);
    }
`
const OutputTitle = styled(ChartTitle)`
    width: 100%;

    &:hover{
        cursor: row-resize;
    }
`