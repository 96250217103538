import { useState } from "react";
import styled from "styled-components";
// import { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import { Rnd } from "react-rnd";
import { MdClose, MdFullscreen, MdFullscreenExit } from "react-icons/md";

function Modal({_id, _title, _x, _y, _height, _width, _minWidth, _minHeight, _enableResizing, _disableDragging, _isHeader ,_onClick, _closeModal, children, _style}){
    const [isFullSize, setIsFullSize] = useState(false);
    const [defStyle, setDefStyle] = useState({ 
        x:_x === undefined ? window.innerWidth / 2 - _width/2 : _x, 
        y:_y === undefined ? window.innerHeight / 2 - _height/2 : _y, 
        width: _width, 
        height: _enableResizing ? _height : undefined, 
    });

    const StopEventBubbling = (e) =>e.stopPropagation();

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if(isMobile){
        return (
            <ContentMobile id={_id} onClick={(e) => _onClick && _onClick(e)}>
                {_isHeader &&
                    <ModalHeaderMobile title={_title}>
                        {_title}
                        {/* <button className="close" onClick={(e) => {
                            console.log('close')
                            _closeModal(e)
                            e.stopPropagation()
                        }}> &times; </button> */}
                        <MdClose className="close"
                            onClick={(e) => {
                                _closeModal(e);
                                e.stopPropagation();
                            }}
                        />
                    </ModalHeaderMobile>
                }
                <ChildrenContentMobile height={_height} style={_style} onMouseDown={StopEventBubbling}>
                    {children}
                </ChildrenContentMobile>
            </ContentMobile>
        )
    }else{
        return(
            <Content id={_id} onClick={(e)=>_onClick && _onClick(e)}>
                <Rnd className="ModalStyle"
                    bounds="body"
                    position={{
                        x: isFullSize ? 0 : defStyle.x,
                        y: isFullSize ? 40 : defStyle.y,
                    }}
                    size={{
                        width: isFullSize ? "100%" : defStyle.width,
                        height: isFullSize ? "100%" : defStyle.height,
                    }}
                    minWidth={_minWidth}
                    minHeight={_minHeight}
                    enableResizing={_enableResizing && !isFullSize}
                    disableDragging={isFullSize ? true : _disableDragging}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        setDefStyle(pevData => ({
                                ...pevData,
                                x: position.x,
                                y: position.y,
                                width: pevData.width + delta.width,
                                height: pevData.height + delta.height,
                            }));
                    }}
                    onDragStop={(e, d) => {
                        if (!isFullSize) {
                            setDefStyle(pevData => ({
                                    ...pevData,
                                    x: d.x,
                                    y: d.y,
                                }));
                        }
                    }}
                >
                    {_isHeader &&
                        <ModalHeader>
                            <span>{_title}</span>

                            <div className="buttons">
                                {_enableResizing && (
                                    isFullSize
                                        ?
                                        <MdFullscreenExit className="btn_screen"
                                            onClick={(e) => {
                                                setIsFullSize(false);
                                                e.stopPropagation();
                                            }} />
                                        : <MdFullscreen className="btn_screen"
                                            onClick={(e) => {
                                                setIsFullSize(true);
                                                e.stopPropagation();
                                            }}
                                        />
                                )
                                }
                                <MdClose className="close"
                                    onClick={(e)=>{
                                        _closeModal(e);
                                        e.stopPropagation();
                                    }}
                                />

                            </div>
                        </ModalHeader>
                    }
                    <ChildrenContent height={_height} style={_style} onMouseDown={StopEventBubbling}>
                        {children}
                    </ChildrenContent>
                </Rnd>
            </Content>
        )
    }
}

export default Modal;

Modal.propTypes ={
    // _top : PropTypes.number,        // popup 위치
    // _right : PropTypes.number,      // popup 위치
    // _width : PropTypes.number,      // popup 위치
}

Modal.defaultProps = {
    _width : 340,
    _height : 150,
    _minWidth: 340,
    _minHeight: 150,
    _enableResizing:true,
    _disableDragging:false,
    _isHeader:true,
}


//스타일--------------------------------
const Content = styled.div`
    .ModalStyle{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.3rem;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
        /* overflow: hidden; */
        z-index:9;

        &>div:last-child{
            div:nth-child(2){
                z-index: 1;
            }
            div:nth-child(3){
                z-index: 1;
            }
            div:nth-child(7){
            }
            div:nth-child(8){
            }
        }
    }
`;

const ModalHeader = styled.header`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    border-radius: 0.3rem 0.3rem 0 0;
    padding: 8px 16px;
    font-weight: bold;
    box-sizing: border-box;
    color: ${({theme}) => theme.base.font_color_Black};
    background-color: ${({theme}) => theme.base.background_color_PaleGray};
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    user-select: none;

    &>span{
        width: 100%;
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: contents;
    }

    .buttons{
        display: flex;
        gap: 8px;

        svg{
            width: 30px;
            height: 30px;
            padding: 3px;
            box-sizing: border-box;
            font-size: 1.2rem;
            text-align: center;
            color: ${({theme}) => theme.base.font_color_DarkGray};
            background-color: transparent;
            outline: none;
            cursor: pointer;
            border: 0;

            &.btn_screen:hover{
                color: ${({theme}) => theme.base.font_color_DarkBlue};
            }

            &.close:hover{
                color: ${({theme}) => theme.base.font_color_Red};
            }
        }
    }
`;

const ChildrenContent = styled.div`
    padding: 8px 16px 16px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 48px);
    box-sizing: border-box;
    cursor: default;
    overflow: auto;
    user-select: none;
    
    &>div{
        box-sizing: border-box;
        margin-top: 8px;
    }
`;

const ContentMobile = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #fff;
`;

const ModalHeaderMobile = styled(ModalHeader)`
    height: 38px;
    padding: 0;
    padding-left: 8px;
    display: flex;
    align-items: center;

    svg{
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        padding: 4px;
        box-sizing: border-box;
        color: ${({ theme }) => theme.base.font_color_DarkGray};
    }
`;

const ChildrenContentMobile = styled(ChildrenContent)`
    height: calc(100% - 38px);
`;