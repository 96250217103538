import { Vector3, PointLight } from "@babylonjs/core";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";

export default class Light {
    constructor(EnuScene) {
        const light = new PointLight(
            "pointLight",
            new Vector3(0, 5, 0),
            EnuScene.scene
        );
        this.uniqueId = light.uniqueId;
        this.id = light.id;
        this.name = light.name;
        this.position = light.position;
        this.objectType = SUPPORT_OBJECT_TYPE.LIGHT;

        EnuScene.addLightToMap(this.uniqueId, this);
    }

    setPosition(position) {
        const { x, y, z } = position;
        this.position.x = x || this.position.x;
        this.position.y = y || this.position.y;
        this.position.z = z || this.position.z;
    }

    getPropertyMap() {
        return {
            uniqueId: this.uniqueId,
            id: this.id,
            name: this.name,
            position: {
                x: this.position.x,
                y: this.position.y,
                z: this.position.z,
            },
        };
    }
}
