import { GUI3DManager } from "@babylonjs/gui/3D";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";

export default class ENUGUI3DManager {
    constructor(EnuScene, name = 'GUI3DManager') {
        const object = new GUI3DManager(EnuScene.scene);
        this._origin = object;
        this._name = object.name;
        
        this.sub = [];
        this.objectType = SUPPORT_OBJECT_TYPE.GUI_3DMANAGER;
        EnuScene.addGUIToMap("GUI3DManager", this);
    }

    set name(_name) { this._name = _name; }
    get name() { return this._name; }

    set origin(_origin) { this._origin = origin;}
    get origin() { return this._origin; }

    addControl(GUI3D) {
        this.origin.addControl(GUI3D);
        //TODO: No uniqueID for GUI3D, another key?
        this.sub.push(GUI3D);
    }
    getPropertyMap() {
        return {
            name: this.name,
            objectType: this.objectType,
        };
    }
}
