import { useState, useEffect, useCallback } from "react";

import { BtnGhost, BtnSecondary } from "components/atom/Button";
import { ContentPage, ContentSectionHeader, ContentSection, NewContentBox } from "components/css/common";
import NewArchiveFormModal from "components/modal_new/NewArchiveFormModal";
import { RepositoryCard } from "components/molecule/ProjectCard";
import { ARCHIVE_TYPE, PUBLISH_TYPE, AUTHORITY_TYPE } from "components/common/EnuSpaceEnum";
import { GetArchiveProjectListByType, GetArchiveProjectAccessListByType, DeleteArchiveProject } from "api/ArchiveApi";

import { useNavigate } from 'react-router-dom'
import styled from "styled-components";
import { MdInsertLink } from "react-icons/md";

export default function ArchiveRepository() {
    const navigate = useNavigate();
    const [ArchiveList, setArchiveList] = useState([]);
    const [NewArchiveForm, setNewArchiveForm] = useState();

    const systemId = localStorage.getItem('systemId');
    const nickname = localStorage.getItem('nickname');
    const userMail = localStorage.getItem('email');

    const LoadArchiveList = useCallback(() => {
        const myList = GetArchiveProjectListByType(systemId, ARCHIVE_TYPE.ARCHIVE)
            .then(res => {
                const { result, data } = res.data;
                if (result === "Success") {
                    return { myList: data };
                } else {
                    return { myList: [] };
                }
            })
        const publishList = GetArchiveProjectAccessListByType(systemId, ARCHIVE_TYPE.ARCHIVE)
            .then(res => {
                const { result, data } = res.data;
                if (result === "Success") {
                    return { publishList: data };
                } else {
                    return { publishList: [] };
                }
            })

        Promise.all([myList, publishList])
            .then(res => {
                const Result = res.reduce((acc, cur) => ({ ...acc, ...cur }), {});
                const { myList, publishList } = Result;
                const notSelf = publishList?.filter(item => item.IS_SELF === false);
                setArchiveList([...myList || [], ...notSelf || []])
            }).catch(err => {
                window.AddAlertMsg({ type: 'error', msg: `${err}` });
            })

    }, [systemId])

    useEffect(() => {
        LoadArchiveList();
    }, [LoadArchiveList]);


    return (
        <>
            {NewArchiveForm && <NewArchiveFormModal ArchiveType="Archive" DataState={[NewArchiveForm, setNewArchiveForm]} onReload={LoadArchiveList} />}
            <ContentPage>
                {/* 레이아웃 통일을 위해 존재 */}
                <ContentSectionHeader />

                <ContentSection>
                    <NewContentBox>
                        <BtnGhost
                            label="New Archive"
                            onClick={(e) => {
                                setNewArchiveForm({ userId: systemId, userNickname: nickname, email: userMail, mode: 'new', publishType: PUBLISH_TYPE.PRIVATE })
                            }}
                        />
                    </NewContentBox>

                    {ArchiveList &&
                        ArchiveList.map((Archive, index) => {
                            const { ARCHIVE_SYSTEMID, PUBLISH_MODE, PROFILE_SYSTEMID, ARCHIVE_NAME, NICKNAME, CREATED_STAMP, DESCRIPTION, AUTHORITY } = Archive;

                            const AccessType = Object.keys(AUTHORITY_TYPE).find(key => AUTHORITY_TYPE[key] === AUTHORITY);
                            const publishType = Object.keys(PUBLISH_TYPE).find(key => PUBLISH_TYPE[key] === PUBLISH_MODE);

                            const DeleteAccessCard = (e) => {
                                if (!window.confirm(`"${ARCHIVE_NAME}" 을 삭제하시겠습니까?`)) return;

                                DeleteArchiveProject(ARCHIVE_SYSTEMID)
                                    .then(res => {
                                        const { result, msg } = res.data;
                                        if (result === 'Success') {
                                            LoadArchiveList();
                                        } else
                                            window.AddAlertMsg({ type: 'error', msg: `삭제에 실패했습니다. : ${msg}` });
                                    }).catch(err => {
                                        window.AddAlertMsg({ type: 'error', msg: `삭제에 실패했습니다. : ${err}` });
                                    })
                            }

                            return (
                                <RepositoryCard
                                    key={ARCHIVE_SYSTEMID + index}
                                    PublishType={publishType}
                                    AccessType={AccessType}
                                    ProfileId={PROFILE_SYSTEMID}
                                    ProjectName={ARCHIVE_NAME}
                                    Creator={NICKNAME}
                                    Date={CREATED_STAMP}
                                    EventOption={(e) => {
                                        setNewArchiveForm({
                                            mode: 'edit',
                                            userId: systemId,
                                            userNickname: nickname,
                                            archiveId: ARCHIVE_SYSTEMID,
                                            archiveName: ARCHIVE_NAME,
                                            publishType: PUBLISH_MODE,
                                            description: DESCRIPTION,
                                        })
                                    }}
                                    EventDelete={DeleteAccessCard}
                                    Buttons={
                                        <>
                                            <ButtonContent
                                                className='btn_link'
                                                type='sm'
                                                label={<MdInsertLink />}
                                                title='link'
                                                onClick={(e) => {
                                                    const copyText = `https://${window.location.host}/Archive/link?archive=${ARCHIVE_SYSTEMID}`;
                                                    navigator.clipboard.writeText(copyText);
                                                    window.AddToastMsg(`link Copy Success`);
                                                }} />
                                            <ButtonContent
                                                type='sm'
                                                label='Edit'
                                                title='Edit'
                                                onClick={(e) => {
                                                    navigate(`/Archive/Settings?archive=${ARCHIVE_SYSTEMID}`,);
                                                }}
                                            />
                                        </>
                                    }
                                />
                            )
                        })
                    }
                </ContentSection>
            </ContentPage>
        </>
    )
}

const ButtonContent = styled(BtnSecondary)`
    padding: 4px 8px !important;
    height: 30px !important;
    min-width: 0;

    &.btn_link{
        padding: 0  4px!important;
        &:hover{
            border-color: ${({ theme }) => theme.base.border_color_Blue};
            background-color: ${({ theme }) => theme.base.background_color_LightBlue};
        }
        svg{
            font-size: 1.3rem;
            color:  ${({ theme }) => theme.base.border_color_DarkBlue};
        }
    }
`;