import { useDispatch } from 'react-redux';
import { addModalPopup, removeModalPopup, AllClearModalPopup, BackToTopModal as BackToTop, updateModalData, editModalData } from 'reducers/modulePopupReducer';

export default function useModalPopup() {
    const dispatch = useDispatch();

    /**
    모달 팝업 추가 액션을 디스패치하는 함수
    @param {string} _id - 모달 팝업 아이디
    @param {string} _title - 모달 팝업 제목
    @param {string} _type - 모달 팝업 종류
    @param {object} _data - 모달 팝업에 전달할 데이터 객체
    */
    const AddModal = (_id, _title, _type, _data) => {
        dispatch(addModalPopup({
            id : _id,
            title: _title,
            type: _type,
            data: _data,
        }));
    }

    /**
     * 
     * @param {string} id    - 모달 팝업 아이디
     * @param {object} newData - 모달 팝업에 전달할 데이터 객체 data에 대한 변경 데이터
     */
    const UpdateModalData = (id, newData) => {
        dispatch(updateModalData({id, newData}));
    }

    /**
     * 
     * @param {string} id    - 모달 팝업 아이디
     * @param {object} newData - 모달 팝업에 전달할 새로운 데이터
     */
    const EditModalData = (id, newData) => {
        dispatch(editModalData({id, newData}));
    }

    /**
    모달 팝업 제거 액션을 디스패치하는 함수
    @param {string} _id - 모달 팝업 아이디
    */
    const DeleteModal = (_id) => {
        dispatch(removeModalPopup(_id));
    }

    /**
    모달 팝업을 맨 위로 올리는 액션을 디스패치하는 함수
    @param {string} _id - 모달 팝업 아이디
    */
    const BackToTopModal = (_id) => {
        dispatch(BackToTop(_id));
    }


    /**
    모달 팝업 모두 제거
    */
    const AllDeleteModalPopup = () =>{
        dispatch(AllClearModalPopup());
    }

    return {AddModal, DeleteModal, AllDeleteModalPopup, BackToTopModal, UpdateModalData, EditModalData};
}