import { useState} from "react";

import {BtnSecondary} from "components/atom/Button";
import SearchBar from "components/atom/SearchBar";
import ToolBarBtnList from "components/molecule/ToolBarBtnList";
import PublishMenuBtn from 'components/molecule/PublishMenuBtn'
import {EnuSymbol} from "components/common/EnuSpaceIcon"
import useProjectData from 'hooks/useProjectData';
import {AccessAxios} from 'components/Axios';

import { MdCenterFocusStrong } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from "styled-components";

function ProjectHeader({enuSpace}){
    const { SetMode, ProjectSave} = useProjectData();
    const [ViewScale, setViewScale] = useState(0);

    const TreeData = useSelector(state => state.projectData.TREE_DATA);
    const {userId, projectId} = useSelector(state => state.projectData.BASE_DATA);

    const SaveEvent = (e, _param = null) => {
        const ActivateViewPath = enuSpace.GetActivateView();
        ProjectSave(enuSpace, ActivateViewPath, TreeData, _param);
    }

    window.Re_WheelScale = (_scaleValue) => setViewScale(_scaleValue);

    window.Re_RunTimeOnOff = (_msg) =>{
        const {type, data} = JSON.parse(_msg);
        if(type === 'success'){
            SetMode(data);
        }else if(type === 'failed'){
            window.AddAlertMsg({type:'error', text:data});
        }
    }

    return(
        <HeaderContent>
            <HeaderTopContent>
                <Link to='/' className="EnuSymbol"> <EnuSymbol /> </Link>

                <ul>
                    <li onClick={(e)=>SaveEvent(e, 'save')}>Save All</li>
                    <li onClick={(e)=>{
                        window.open(`https://expnuni.github.io/enuspaceMeta_doc/`,
                            'EnuspaceManual',
                            'width=1000,height=660,location=yes,status=no,scrollbars=yes,menubar=yes,titlebar=yes,toolbar=yes');
                    }} >help</li>
                </ul>
                <SearchBar 
                    placeholder='help...' 
                    style={{height:'26px', width:'200px'}} 
                    searchEvent={(searchText)=>{
                        AccessAxios.post('/api/Chatbot/SendMessage', {
                            MEMBER_SYSTEMID: userId,
                            PROJECT_SYSTEMID: projectId,
                            MESSAGE: searchText,
                        }).then(res => {
                            const {result, msg, data} = res.data;
                            if(result === 'Success'){
                                window.alert(data);
                            }else{
                                window.AddAlertMsg({type:'error', msg});
                            }
                        }).catch(err => {
                            window.AddAlertMsg({type:'error', msg:err});
                        });
                    }}
                />
            </HeaderTopContent>
            <HeaderBottomContent>
                <ToolBarBtnList enuSpace={enuSpace}/>
                
                <div style={{'display':'flex', 'alignItems':'center', gap:'16px'}}>
                    <BtnSecondary type='sm' title={"AutoFit"} 
                        label={<MdCenterFocusStrong style={{fontSize: '1.1rem', color:'#1565C0'}} onClick={(e)=> enuSpace.RunAutoScale("Canvas")}/>} 
                        onClick={(e)=> enuSpace.RunAutoScale("Canvas")}
                    />

                    <PublishMenuBtn enuSpace={enuSpace} userId={userId} projectId={projectId} treeData={TreeData} saveEvent={SaveEvent}/>

                    <label id="btn_runtime" title="run time mode">
                        <input role="switch" type="checkbox" />
                        <img src="/img/toolbar/btn_play.png" alt="play" onClick={(e) =>enuSpace.RunTimeOnOff()}/>
                    </label>
                    <ScaleContent>
                        <input id="CanvasScale" title="Canvas Scale" type='number' value={ViewScale} 
                            onChange={(e)=>setViewScale(e.target.value)} 
                            onKeyDown={(e)=>{if(e.key === 'Enter') e.target.blur()}} 
                            onBlur={(e)=>enuSpace.SetScaleAtView(e.target.value)}
                        />
                        <span>%</span>
                    </ScaleContent>
                </div>
            </HeaderBottomContent>
        </HeaderContent>
    )
}

export default ProjectHeader;

//스타일--------------------------------------
const HeaderContent = styled.header`
    height: 76px;
    border-bottom: 1px solid ${({theme}) => theme.base.background_color_Gray};
    box-sizing: border-box;
    user-select: none;
`;

const HeaderTopContent = styled.div`
    background-color: ${({theme}) => theme.base.border_color_LightGray};
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 16px;

    &>ul{
        display: flex;
        align-items: baseline;
        justify-content: center;
        align-items: center;

        &>li{
            font-size: 0.9rem;
            color: ${({theme}) => theme.base.font_color_Black};
            padding: 6px;
            cursor: default;
            user-select: none;

            &:hover {
                color: ${({theme}) => theme.base.font_color_Blue};
                cursor: pointer;
            }
        }
    }

    .EnuSymbol svg{
        width: 34px;
        height: 28px;
    }
`;

const HeaderBottomContent = styled.div`
    justify-content: space-between;
    height: 48px;
    display: flex;
    padding: 0px 8px;
    align-items: center;

    #btn_runtime {
        overflow: hidden;
        width: 46px;
        height: 48px;
        position: relative;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;

        input{
            display: none;

            &:checked + img{
                right: 0;
            }
        }

        &>img{
            position: absolute;
            top: 0;
        }
    }
`;

const ScaleContent = styled.div`
    border: 1px solid ${({theme}) => theme.base.border_color_Gray};
    border-radius: 4px;
    box-sizing: border-box;
    font-size : .9rem;
    padding-right: 8px;
    user-select: none;

    input {
        width: 40px;
        text-align: right;
        color: ${({ theme }) => theme.base.font_color_Black};
        font-size: .9rem;
        height: 28px;
        box-sizing: border-box;
        border: 0 none;

        &:focus-visible{
            outline: 0;
        }
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    label{
        margin-left: 4px;
        color: ${({theme}) => theme.base.font_color_DarkGray};
    }
`;