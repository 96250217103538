import { useState, useEffect, useCallback } from "react";

import {SetDescription, GetImageFileList, GetDescription, UploadImages, DeleteFiles, MoveFiles, CreateFolder, RenameFile} from 'api/Project';

import { useSelector } from 'react-redux';

export default function useImageList(_path = 'resource'){
    const {userId: MEMBER_SYSTEMID, projectId: PROJECT_SYSTEMID} = useSelector(state => state.projectData.BASE_DATA);

    const [ImageList, setImageList] = useState([]);
    const [DescriptionData, setDescriptionData] = useState();
    const [CurrentFolderPathArray, setCurrentFolderPathArray] = useState([]);

    // 이미지 리스트 가져오기
    const fetchImageList = useCallback(async (PATH) => {
        GetImageFileList(MEMBER_SYSTEMID, PROJECT_SYSTEMID, PATH)
            .then(res => {
                setImageList(res.data['Data']);
                setCurrentFolderPathArray(PATH.split('/'));
                GetDescription(MEMBER_SYSTEMID, PROJECT_SYSTEMID)
                    .then(res => {
                        const { result, data } = res.data;
                        if (result === 'Success')
                            setDescriptionData(JSON.parse(data));
                    });
            }).catch(err => {
                window.AddAlertMsg({ type: 'error', msg: err });
            })
    }, [MEMBER_SYSTEMID, PROJECT_SYSTEMID]);

    useEffect(() => {
        fetchImageList(_path);
    }, [fetchImageList, _path]);

    // 이미지 리스트 새로고침
    const RefetchImageList = (_path = CurrentFolderPathArray.join("/")) => {
        fetchImageList(_path);
    };

    const SetDescriptionData = (tempStrData) => {
        const description = JSON.stringify(tempStrData);
        SetDescription(MEMBER_SYSTEMID, PROJECT_SYSTEMID, description)
            .then(res => {
                const { result } = res.data;
                if (result === 'Success') {
                    setDescriptionData(JSON.parse(description));
                }
            })
    }

    // 이미지 업로드
    const AddFetchImage = async (_FileData) => {
        if (_FileData.length === 0)
            return;

        UploadImages(MEMBER_SYSTEMID, PROJECT_SYSTEMID, CurrentFolderPathArray, _FileData)
            .then(({ res, fileNames }) => {
                // TODO: 파일이름 중복일 경우 res false 로 처리됨 
                if (res) {
                    let NewDescription = {};
                    fileNames.forEach(FileName => {
                        const Path = (`${CurrentFolderPathArray.join('/')}/${FileName}`).replace('resource/', '');
                        NewDescription[Path] = {
                            Description: "",
                            Path,
                            FileName,
                        };
                    });

                    // SetDescription(JSON.stringify({...DescriptionData, ...NewDescription}));
                    SetDescriptionData({ ...DescriptionData, ...NewDescription })
                }
                RefetchImageList();
                return res;
            }).catch(err => {
                window.AddAlertMsg({ type: 'error', msg: err })
            })
    }

    /**
     * 파일을 삭제한다
     * @param {Array} _ArrImagePath - 삭제할 파일 경로 (path 포함)
     */
    const DeleteFetchImage = async (_ArrImagePath) => {
        if (!_ArrImagePath)
            return;

        DeleteFiles(MEMBER_SYSTEMID, PROJECT_SYSTEMID, _ArrImagePath)
            .then(res => {
                const tempData = { ...DescriptionData };
                const PathInfo = _ArrImagePath.replace('resource/', '');

                // PathInfo의 . 유무에 따라 파일인지 폴더인지 구분
                if (PathInfo.includes('.')) {
                    delete tempData[PathInfo];
                } else {
                    const itemsToDelete = Object.keys(tempData).filter(path => path.startsWith(PathInfo));
                    itemsToDelete.forEach(path => delete tempData[path]);
                }

                // SetDescription(JSON.stringify(tempData));
                SetDescriptionData(tempData)

                RefetchImageList();
                return res;
            }).catch(err => {
                window.AddAlertMsg({ type: 'error', msg: err })
            })
    }

    // 이미지 이동
    const MoveFetchFile = async (_fileName, _fromPath, _toPath) => {
        if (!_fileName || !_fromPath || !_toPath)
            return;

        try{
            const res = await MoveFiles(MEMBER_SYSTEMID, PROJECT_SYSTEMID, _fromPath, _toPath, _fileName);
            
            if (res.data === 'success') {
                const tempData = { ...DescriptionData };
                _fileName.forEach(FileName => {
                    const FromPathInfo = (`${_fromPath}/${FileName}`).replace('resource/', '');
                    const ToPathInfo = (`${_toPath}/${FileName}`).replace('resource/', '');

                    if (FileName.includes('.')) {
                        tempData[ToPathInfo] = {
                            ...tempData[FromPathInfo],
                            Path: ToPathInfo,
                        };
                        delete tempData[FromPathInfo];
                    } else {

                    }
                });
                // SetDescription(JSON.stringify(tempData));
                SetDescriptionData(tempData);
            }
            RefetchImageList();
            return res;
        }catch(err){
            window.AddAlertMsg({type:'error', msg:err})
        }
    }

    // 폴더 생성
    const AddFetchFolder = async (_folderName) => {
        if (!_folderName)
            return;

        CreateFolder(MEMBER_SYSTEMID, PROJECT_SYSTEMID, CurrentFolderPathArray, _folderName)
            .then(res => {
                RefetchImageList();
                return res;
            }).catch(err => {
                window.AddAlertMsg({ type: 'error', msg: err })
            })
    }

    /**
     * 이름 변경
     * @param {string} _old_FILE_NAME   - 기존 파일 이름 (path 포함)
     * @param {string} _neW_FILE_NAME   - 새로운 파일 이름 (path 포함)
     * @returns 
     */
    const ChangeName = async(_old_FILE_NAME, _neW_FILE_NAME) =>{
        try{
            const res = await RenameFile(MEMBER_SYSTEMID, PROJECT_SYSTEMID, _old_FILE_NAME, _neW_FILE_NAME);

            if (res.data['result'] === 'Success') {
                const tempData = { ...DescriptionData };
                const OldPathInfo = _old_FILE_NAME.replace('resource\\', '');
                const NewPathInfo = _neW_FILE_NAME.replace('resource\\', '');

                // PathInfo의 . 유무에 따라 파일인지 폴더인지 구분
                if (NewPathInfo.includes('.')) {
                    const OldData = { ...tempData[OldPathInfo] };
                    OldData.Path = NewPathInfo;
                    const pathSegments = NewPathInfo.split('/');
                    OldData.FileName = pathSegments[pathSegments.length - 1];
                    delete tempData[OldPathInfo];
                    tempData[NewPathInfo] = OldData;
                } else {
                    const itemsToUpdate = Object.keys(tempData).filter(path => path.startsWith(OldPathInfo));

                    itemsToUpdate.forEach(path => {
                        const updatedPath = path.replace(OldPathInfo, NewPathInfo);
                        const newPathSegments = updatedPath.split('/');
                        const updatedData = {
                            ...tempData[path],
                            Path: updatedPath,
                            FileName: newPathSegments[newPathSegments.length - 1],
                        };
                        delete tempData[path];
                        tempData[updatedPath] = updatedData;
                    });
                }
                SetDescriptionData(tempData);
            }

            return res;
        }catch(err){
            window.AddAlertMsg({type:'error', msg:err})
        }
    }

    return {
        ImageList, 
        CurrentFolderPathArray,
        DescriptionData,
        setDescriptionData,
        RefetchImageList,
        AddFetchImage,
        DeleteFetchImage, 
        MoveFetchFile, 
        AddFetchFolder, 
        ChangeName,
    };
}
