import {useState, useEffect, useCallback} from "react";

import NodeTreeRowContent from "components/atom/NodeTreeRowContent"
import {TreeUlContent} from "components/css/common";

import {  useSelector } from 'react-redux';
import styled from "styled-components";
import { MdSearch, MdClose } from "react-icons/md";

export default function NodeTree({enuSpace}){
    const SelectedNodeId = useSelector(state => state.selectNodeData.id);
    const ARRAY_ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ARRAY_ACTIVE_PICTURE_URL);
    
    const [Search, setSearch] = useState('');
    const [NodeTreeData, setNodeTreeData] = useState([]);
    const [FilterNodeData, setFilterNodeData] = useState([]);
    const [SelectedId, setSelectedId] = useState([]);

    const GetNodeTree = useCallback(() =>{
        const arrayUrl = ARRAY_ACTIVE_PICTURE_URL;
        if(!Array.isArray(arrayUrl))
            throw new Error('An invalid argument was passed to the GetNodeTree function. Expected an array.');

        const [svgType, type, fileName, symbolName] = arrayUrl;
        
        switch(svgType){
            case 'picture':
                enuSpace.GetNodeTree(arrayUrl.join('\\'), svgType, '');
                break;
            case 'library':
                enuSpace.GetNodeTree(`${svgType}\\${type}\\${fileName}`, type, `#${symbolName}`);
                break;
            default:
                throw new Error(`Unhandled action type: ${svgType}`);
        }
    }, [enuSpace, ARRAY_ACTIVE_PICTURE_URL]);

    useEffect(()=>{
        GetNodeTree();
    }, [GetNodeTree]);

    const NodeDataFilter = useCallback((Array, Search, NodeData) =>{
        NodeData.forEach(element => {
            const { child, id, object_name } = element;

            // group은 조회 내용에서 제외한다.
            if(object_name !== 'g' && id.toLowerCase().includes(Search.toLowerCase())){
                Array.push({ id, object_name, child: null, });
            }

            if(child)
                NodeDataFilter(Array, Search, child)
        });
    }, []);

    useEffect(()=>{
        if(!Search){
            setFilterNodeData(NodeTreeData)
            return;
        }

        let FilterData = [];
        NodeDataFilter(FilterData, Search, NodeTreeData)
        setFilterNodeData(FilterData)

    }, [Search, NodeTreeData, NodeDataFilter]);

    window.Re_GetNodeTree = (_NodeInfoList) =>{
        const {data} = JSON.parse(_NodeInfoList);
        setNodeTreeData(data?.child);
    }

    const SelectedNode = (arrayNodeId) =>{
        enuSpace.SelectObjects('Canvas', JSON.stringify(arrayNodeId));
        enuSpace.GetNodeData("Canvas");
    }

    const RowClickEvent = (id, e) => {
        let targetId = [...SelectedId];
    
        if (e.shiftKey) {
            targetId = targetId.includes(id)
                ? targetId.filter(item => item !== id)
                : [...targetId, id];
        } else {
            targetId = targetId.includes(id) && targetId.length === 1
                ? []
                : [id];
        }
    
        SelectedNode(targetId);
    };

    useEffect(() => {
        const arrayId = Array.isArray(SelectedNodeId) ? SelectedNodeId : (SelectedNodeId ? [SelectedNodeId] : []);
        setSelectedId(arrayId);
    }, [SelectedNodeId]);

    const RenderChildTree = (nodeChildInfo) =>{
        return nodeChildInfo.slice().reverse().map((childInfo, index)=>{
            const {child, id, object_name} = childInfo;

            return(
                <li key={id}>
                    <NodeTreeRowContent
                        id={id}
                        title={id}
                        isSelected={SelectedId.includes(id)}
                        objectName={object_name}
                        onClickEvent={RowClickEvent}
                    >
                        {child && <UlContent>{(RenderChildTree(child))}</UlContent>}
                    </NodeTreeRowContent>
                </li>
            )
        })
    }

    return(
        <NodeTreeContent>
            <FilterContent>
                <div>
                    <MdSearch className='Icons SearchIcon' />
                    <input
                        className='SearchInput'
                        name='searchKeyword'
                        type='text'
                        placeholder='Keyword...'
                        value={Search}
                        onChange={(e) => setSearch( e.target.value )}
                    />
                    <MdClose className='Icons CloseIcon' onClick={(e => setSearch(''))} />
                </div>
            </FilterContent>
            <div className='treeBoy'>
                {FilterNodeData?.slice().reverse().map((nodeInfo, index) => {
                    const { child, id, object_name } = nodeInfo;

                    return (
                        <NodeTreeRowContent
                            key={id}
                            id={id}
                            title={id}
                            isSelected={SelectedId.includes(id)}
                            objectName={object_name}
                            onClickEvent={RowClickEvent}
                        >
                            {child && <UlContent>{(RenderChildTree(child))}</UlContent>}
                        </NodeTreeRowContent>
                    )
                })}
            </div>
        </NodeTreeContent>
    )
}

const NodeTreeContent = styled.div`
    height: 100%;
    overflow-y: auto;

    .treeBoy{
        padding-left: 14px;
    }
`

const FilterContent = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    gap: 4px;
    padding:0 8px;
    height: 40px;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
    background-color: #FFF;
    z-index: 1;
    user-select: none;

    &>div{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;

        &>div{
            label{
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                height: 24px;
                width: 28px;
                font-size: .9rem;
                color: ${({theme}) => theme.base.font_color_DarkGray};
                border-radius: 4px;
                box-sizing: border-box;
                cursor: pointer;
            }

            input{
                display: none;

                &:hover + label{
                    color: ${({theme}) => theme.base.font_color_DarkBlue};
                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                }
                
                &:checked + label{
                    font-weight: bold;
                    color: ${({theme}) => theme.base.font_color_DarkBlue};
                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                    border: 1px solid ${({theme}) => theme.base.border_color_Blue};
                }
            }
        }
    }

    .Icons{
        padding: 4px;
        height: 24px;
        width: 24px;
        box-sizing: border-box;
    }

    .SearchIcon{
        color: ${({theme}) => theme.base.font_color_DarkBlue};
    }

    .CloseIcon{
        cursor: pointer;

        &:hover{
            color: ${({theme}) => theme.base.font_color_Red};
        }
    }

    .SearchInput{
        position: relative;
        flex-grow: 1;
        height: 100%;
        border: none;
        box-sizing: border-box;

        &:focus{
            outline: none;
        } 
    }
`;

const UlContent = styled(TreeUlContent)`
    li {
        padding-left: 12px;
      }
`;