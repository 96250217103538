import {
    RectIcon,
    CircleIcon,
    EllipseIcon,
    DiamondIcon,
    RightArrowIcon,
    IsoscelesTriangleIcon,
    CardIcon,
    RoundRect,
} from "components/common/EnuCanvasIcon"

import styled from "styled-components"

const SupportShape = {
    Rect: () => <RectIcon />,
    RoundRect: () => <RoundRect />,
    Diamond: () => <DiamondIcon />,
    Circle: () => <CircleIcon />,
    Ellipse: () => <EllipseIcon />,
    IsoscelesTriangle: () => <IsoscelesTriangleIcon />,
    Card: () => <CardIcon />,
    RightArrow: () => <RightArrowIcon />,
}

export default function EnuCanvasToolBar({EnuCanvasTools, SupportColor, StateActiveTool, setIsSubOptionName, SetEnuCanvasTools, handleColorClick, handlerActiveTool, setMemoInfo}){
    const [ActiveTool, setActiveTool] = StateActiveTool;

    const handleShapeClick = (selectedShape) => {
        setActiveTool(prev => ({
            ...prev,
            ToolName: 'Draw',
            icon: SupportShape[selectedShape](),
            Type: selectedShape,
        }));

        SetEnuCanvasTools(prev => prev.map((item) => {
            if(item.ToolName === 'Draw'){
                return {...item, icon: SupportShape[selectedShape](), Type: selectedShape}
            }
            return item;
        }))

        handlerActiveTool('Type', {changeValue: selectedShape});
    };

    return(
        <ToolBar>
            <div>
                {EnuCanvasTools?.map((item, idx) => {
                    const { ToolName, icon, isOption, Border, Fill, TextType, TextAlign, Type } = item;
                    const id = `btn_${ToolName}`;

                    const handleClick = (e) => {
                        e.stopPropagation();

                        // 메모는 선택되지 않고 모달창을 띄웁니다.
                        if(ToolName === "Memo"){
                            setIsSubOptionName('Memo');
                            setActiveTool(prev => ({ ...prev, isOption: false, }));
                            setMemoInfo({
                                systemID: '',
                                text: '',
                                color: '#2B2B2B',
                                backgroundColor: '#FFFFFF'
                            })
                            return;
                        }

                        if (ActiveTool.ToolName === ToolName && isOption) {
                            setActiveTool(prev => ({
                                ...prev,
                                isOption: !prev.isOption,
                                Border,
                                Fill,
                            }));
                        } else {
                            setIsSubOptionName('');
                            setActiveTool(prev => ({
                                ToolName: prev.ToolName === ToolName ? prev.ToolName : ToolName,
                                isOption: false,
                                Border,
                                Fill,
                                icon,
                                TextAlign,
                                TextType,
                                Type,
                            }));

                            handlerActiveTool('ToolName', 
                                {   ToolName,
                                    Border,
                                    Fill,
                                    icon,
                                    TextAlign,
                                    TextType,
                                    Type,}
                                );
                        }
                    };

                    return (
                        <EnuCanvasMenu 
                            key={id} 
                            id={id} 
                            title={ToolName}
                            isOption={isOption}
                            SelectedBorderColor={Border}
                            SelectedFillColor={Fill}
                            className={ActiveTool.ToolName === ToolName ? 'selectedTool' : ''}
                            onClick={handleClick}
                        >
                            {icon}

                            {isOption && ActiveTool.ToolName === ToolName && ActiveTool.isOption &&
                                <div className='OptionBox' onClick={(e) => e.stopPropagation()}>
                                    {ActiveTool.ToolName === 'Draw' 
                                    ? (
                                        Object.keys(SupportShape).map((shape, idx) => (
                                            <ShapeChip
                                                key={idx}
                                                title={shape}
                                                className={ActiveTool.icon.type.name === SupportShape[shape]().type.name ? 'selectedIcon' : ''}
                                                onClick={() => {
                                                    handleShapeClick(shape)
                                                    setActiveTool(prev => ({ ...prev, isOption: false, }));
                                                }}
                                            >
                                                {SupportShape[shape]()}
                                            </ShapeChip>
                                        ))
                                    ) 
                                    : (
                                        Object.keys(SupportColor.Border).map((colorValue, idx) => (
                                            <ColorChip
                                                key={idx}
                                                Color={SupportColor.Border[colorValue]}
                                                className={Border === SupportColor.Border[colorValue] ? 'selectedColor' : ''}
                                                onClick={(e) => handleColorClick(ToolName, colorValue)}
                                            />
                                        ))
                                    )}
                                </div>
                            }
                        </EnuCanvasMenu>
                    )
                })}
            </div>
        </ToolBar>
    )
}

const ToolBar = styled.div`
    width: 56px;
    border-right: 1px solid ${({theme}) => theme.base.border_color_Gray};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const EnuCanvasMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 16px auto;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;

    &:hover {
        background-color: ${({theme}) => theme.base.background_color_PaleGray};

        path{
            fill: ${({theme}) => theme.base.font_color_Black};
        }
    }

    &.selectedTool {
        background-color: ${({title, SelectedBorderColor, SelectedFillColor, isOption, theme }) => {
            if (title === 'TextBox' || isOption) {
                return SelectedFillColor || SelectedBorderColor || theme.base.font_color_Gray;
            } else {
                return theme.base.font_color_Gray;
            }
        }};

        border-color: ${({ SelectedBorderColor, SelectedFillColor, theme }) => {
            if (SelectedFillColor) {
                return SelectedFillColor === '#FFFFFF' ? theme.base.border_color_Blue : 'transparent';
            } else if (SelectedBorderColor) {
                return SelectedBorderColor === '#FFFFFF' ? theme.base.border_color_Blue : 'transparent';
            } else {
                return 'transparent';
            }
        }};

        path{
            fill: ${({ SelectedBorderColor, SelectedFillColor, theme }) => {
                if (SelectedFillColor) {
                    return SelectedFillColor === '#FFFFFF' ? SelectedBorderColor === '#FFFFFF' ? theme.base.border_color_Gray : SelectedBorderColor  : SelectedBorderColor;
                } else if (SelectedBorderColor) {
                    return SelectedBorderColor === '#FFFFFF' ? theme.base.border_color_Blue : '#FFFFFF';
                } else {
                    return theme.base.border_color_Gray;
                }
            }};
        }

        &::after{
            content: '';
            display: ${({ isOption }) => isOption ? 'block' : 'none'} ;
            position: absolute;
            bottom: 3px;
            right: 2px;
            width: 0;
            height: 0;
            border-bottom: 4px solid  ${({ SelectedBorderColor, SelectedFillColor, theme }) => {
        if (SelectedFillColor) {
            return SelectedFillColor === '#FFFFFF' ? theme.base.border_color_Blue : '#FFFFFF';
        } else if (SelectedBorderColor) {
            return SelectedBorderColor === '#FFFFFF' ? theme.base.border_color_Blue : '#FFFFFF';
        }
    }};
            border-top: 4px solid transparent;
            border-left: 4px solid transparent;
            border-right: 4px solid  ${({ SelectedBorderColor, SelectedFillColor, theme }) => {
        if (SelectedFillColor) {
            return SelectedFillColor === '#FFFFFF' ? theme.base.border_color_Blue : '#FFFFFF';
        } else if (SelectedBorderColor) {
            return SelectedBorderColor === '#FFFFFF' ? theme.base.border_color_Blue : '#FFFFFF';
        }
    }};
        }
    }

    &::after{
        content: '';
        display: ${({isOption}) => isOption ? 'block' : 'none'} ;
        position: absolute;
        bottom: 3px;
        right: 2px;
        width: 0;
        height: 0;
        border-bottom: 4px solid ${({theme}) => theme.base.background_color_Gray};
        border-top: 4px solid transparent;
        border-left: 4px solid transparent;
        border-right: 4px solid ${({theme}) => theme.base.background_color_Gray};
    }

    .OptionBox {
        position: absolute;
        top: 0;
        left: 58px;
        min-width: 64px;
        width: 136px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
        border-radius: 0.3rem;
        z-index: 1;
        padding: 16px;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        box-sizing: border-box;
        cursor: default;
    }
`;

const ColorChip = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 0.2rem;
    background-color: ${({Color}) => Color};
    border: 1px solid ${({ Color, theme }) => Color === '#FFFFFF' ? theme.base.border_color_Gray : Color};
    box-sizing: border-box;
    cursor: pointer;

    &:hover{
        outline: 4px solid ${({theme}) => theme.base.border_color_LightGray};
    }
    
    &.selectedColor{
        outline: 4px solid ${({theme}) => theme.base.border_color_Gray};
    }
`;

const ShapeChip = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 0.2rem;
    cursor: pointer;

    &.selectedIcon{
        outline: 4px solid ${({theme}) => theme.base.border_color_Gray};
        background-color: ${({theme}) => theme.base.border_color_Gray};

        path{
            fill: #FFF!important;
        }
    }

    path{
        fill: ${({theme}) => theme.base.font_color_Gray}!important;
    }

    &:hover{
        path{
            fill: ${({theme}) => theme.base.font_color_Black}!important;
        }
    }
`;