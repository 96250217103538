import React, { useState, memo } from 'react';

import useHdf5 from 'hooks/useHdf5';
import {
  EnuIconFolderOpen,
  EnuIconFolderClose,
  EnuIconDB,
} from "components/common/EnuSpaceIcon"

import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoizeOne from 'memoize-one';
import styled from 'styled-components';
import { MdTableView } from "react-icons/md";

const Hdf5Tree = ({ _data, _AddHDF5Data, _MouseRightClick, _setIsLoadingPage }) => {
  const { GetHDF5Hierachy} = useHdf5('');
  const [openedNodeIds, setOpenedNodeIds] = useState([]);

  const SetFrontIcon = (_type, _collapsed) => {
    if (_type === "DATASET") {
      return (
        <FrontIconContent>
          <EnuIconDB />
        </FrontIconContent>
      );
    } else if (_type === "hdf5") {
      return (
        <>
          <FrontIconContent>
            {_collapsed ? <EnuIconFolderOpen /> : <EnuIconFolderClose />}
          </FrontIconContent>
          <FrontIconContent>
            <MdTableView />
          </FrontIconContent>
        </>
      );
    } else {
      return (
        <FrontIconContent >
          {_collapsed ? <EnuIconFolderOpen /> : <EnuIconFolderClose />}
        </FrontIconContent>
      )
    }
  }

  const Row = memo(({ data, index, style }) => {
    const { flattenedData, onOpen, onSelect } = data;
    const node = flattenedData[index];
    const {
      depth: NodeDepth,
      path: NodePath,
      type: NodeType,
      isLastChild: NodeIsLastChild,
      collapsed: NodeCollapsed,
      fileName: NodeFileName,
      title: NodeTitle,
    } = node;

    const guideElements = [];
    for (let i = 0; i < NodeDepth - 1; i++) {
      guideElements.push(<GuideLine key={`${NodePath}-v-line-${i}`} className={[i === 0 ? 'v-f-line' : 'v-line', (NodeIsLastChild && i === NodeDepth - 2) ? 'lastChild' : null]} />);
    }

    if (NodeDepth > 1)
      guideElements.push(<GuideLine key={`${NodePath}-h-line`} className='h-line' />);

    return (
      <ListContent style={{ ...style }} depth={NodeDepth - 1} 
        onClick={(e) => { onOpen(node);}} 
        onContextMenu={_MouseRightClick}
      >
        {guideElements}
        <GroupContent>
          {NodeType && SetFrontIcon(NodeType, NodeCollapsed) }
          <Hdf5Row onClick={e => {onSelect(e, node);}} data-type={NodeType} data-path={NodePath} data-filename={NodeFileName}>{NodeTitle}</Hdf5Row>
        </GroupContent>
      </ListContent>
    );
  }, areEqual);

  const getItemData = memoizeOne((onOpen, onSelect, flattenedData) => ({
    onOpen,
    onSelect,
    flattenedData,
  }));

  const flattenOpened = treeData => {
    const result = [];
    for (let node of _data) {
      flattenNode(node, 1, result);
    }
    return result;
  };

  const flattenNode = (node, depth, result, pathData, hdf5FileName = null, isLastChild = false) => {
    const { title, type, child } = node;
    const fileName = type === 'hdf5' ? title : hdf5FileName;
    const path = pathData === undefined ? `${title}` : `${pathData}/${title}`;

    let collapsed = !openedNodeIds.includes(path);
    result.push({
      title,
      type,
      hasChildren: child && child.length > 0,
      depth,
      collapsed,
      fileName,
      path,
      isLastChild
    });

    if (collapsed && child) {
      const numChildren = child.length;
      child.forEach((_child, index) => {
        const _isLastChild = index === numChildren - 1;
        flattenNode(_child, depth + 1, result, path, fileName, _isLastChild);
      });
    }
  };


  const FindHdf5Node = (node, arrPath) => {
    const [currentNodeTitle, ...remainingPath] = arrPath;

    if (node.title !== currentNodeTitle)
      return null;

    if (!remainingPath.length) {
      return node;
    }
    if (node.child) {
      for (let i = 0; i < node.child.length; i++) {
        const child = node.child[i];
        const result = FindHdf5Node(child, remainingPath);
        if (result) {
          return result;
        }
      }
    }
  }

  const onOpen = node => (
    node.collapsed 
      ? setOpenedNodeIds([...openedNodeIds, node.path]) 
      : setOpenedNodeIds(openedNodeIds.filter(path => path !== node.path))
  );

  const onSelect = (e, node,) => {
    e.stopPropagation();
    const {
      type: TargetType,
      path: TargetPath,
    } = e.target.dataset;
    
    if (TargetType === 'hdf5') {
      const FindData = FindHdf5Node(_data[0], TargetPath.split('/'));

      // 이미 자식으로 데이터가 있을시 tree 열기 닫기 처리 만 한다.
      if (FindData['child'] !== null) {
        onOpen(node);
        return;
      }

      _setIsLoadingPage(true);
      GetHDF5Hierachy(TargetPath.replace('hdf5/', ''))
        .then(res => {
          _AddHDF5Data(TargetPath.split('/'), res.data);
          _setIsLoadingPage(false);
        })
        .catch(err => {
          console.log(err);
          _setIsLoadingPage(false);
        });
    } else if(['GROUP', 'root', 'folder'].includes(TargetType)) {
      onOpen(node);
    }
  };

  const flattenedData = flattenOpened(_data);
  const itemData = getItemData(onOpen, onSelect, flattenedData);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List className="List" height={height} itemCount={flattenedData.length} itemSize={32} width={width} itemKey={index => flattenedData[index].title + index} itemData={itemData}>
          {Row}
        </List>
      )}
    </AutoSizer>
  );
};

export default Hdf5Tree;

// 스타일-----------------------------------------------------
const ListContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  box-sizing: border-box;
`;

const GroupContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  box-sizing: border-box;
  flex-grow: 1;

  &:hover{
    background-color: ${({ theme }) => theme.base.background_color_LightBlue};
    span{ display: block; }
  }
`;

const FrontIconContent = styled.span`
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding-right: 0;
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    img{
        position: absolute;
        top: 0;
        left: 0;
    }

    svg{
      font-size: 1.1rem;
      color: ${({ theme }) => theme.base.font_color_DarkBlue};
    }
`;

const Hdf5Row = styled.div`
  height: auto;
  width:30px;
  padding: 4px 0 4px 4px;
  flex-grow: 1;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:disabled{
      padding: 0 0 0 4px;
      background-color: transparent;
      border: 0 none;
  }
`;

const GuideLine = styled.div`
  display: block;
  position: relative;

  &.v-f-line, &.v-line{
    height: 100%;
    // border-right: 1px dashed #ccc;
    margin-left: 0;
    box-sizing: border-box;
  }

  &.v-f-line{
    width: 8px;
  }
  
  &.v-line{
    width: 14px;
  }

  &.h-line{
    top: 16px;
    left: 0;
    width: 6px;
    height: 1px;
    
    &:before {
      content: "";
      position: absolute;
      top: -16px; 
      left: 0;
      width: 8px; 
      height: 1px;
      // border-top: 1px dashed #ccc;
    }
  }

  &.lastChild{
    &:after {
        content: "";
        background-color: #FFF;
        position: absolute;
        height: 50%;
        width: 10px;
        right: -8px;
        bottom: 0;
    }
  }
`;