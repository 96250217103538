import { useState } from "react";

import FontFamily from 'components/common/FontFamily';
import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";
import {RadioComponent} from "components/atom/RadioContent";
import SelectList from "components/atom/SelectList";
import ColorComponent from "components/molecule/ColorComponent";
import {FontSizeIcon} from 'components/common/EnuSpaceIcon'
import { GroupName } from 'components/css/common';

import styled from "styled-components";

export default function ContourFontList({_data, _ChangeEvent, _disabled, ...props}){
    const [fontClass] = useState(new FontFamily());
    const [showTab, setShowTab] = useState('x-axis');

    const ChangeSelect = (attributeName, attributeValue) => _ChangeEvent([{attributeName,attributeValue}])

    const AxisFormatTypeData = {number: "number",text: "text",}
    const ValueFormatTypeData = {number: "number","engineering unit": "eng unit",}

    const ShowTabModified = showTab.replace('-', '_');

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title={`Font (${showTab})`}>
            <div>
                <RadioComponent
                    attributeName='fontTab'
                    dataList={{'x-axis': 'x-axis', 'y-axis':'y-axis', 'value':'value'}} 
                    selectedValue={showTab}
                    disabled={_disabled}
                    changeEvent={(_name, _selectTabName)=> setShowTab(_selectTabName)}
                />
            </div>
            <div>
                <SelectList
                    attributeName={`${showTab}-font-family`} 
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={_data[`${ShowTabModified}_font_family`]}
                    {...commonProps}
                />
            </div>
            <div>
                <ColorComponent label="text" attributeName={`${showTab}-font-color`}  isGradient={false} isVisibility={false} isOpacity={false} {...commonProps} />
                <CustomInputComponent
                    label={<FontSizeIcon />}
                    attributeName={`${showTab}-font-size`} 
                    labelWidth='24px'
                    valueType='number'
                    value={_data[`${ShowTabModified}_font_size`]} 
                    {...commonProps}
                />
            </div>
            <div>
                <GroupName width={76}>format</GroupName>
                <RadioComponent
                    attributeName={`${showTab}-format`} 
                    dataList={showTab === 'value' ? ValueFormatTypeData : AxisFormatTypeData} 
                    selectedValue={_data[`${ShowTabModified}_format`]} 
                    disabled={_disabled}
                    changeEvent={ChangeSelect}
                />
            </div>
            {showTab === 'value'
                ? _data[`${showTab.replace(/-/g, '_')}_format`] === 'number' && 
                    <div>
                        <GroupName style={{width:'100%', maxWidth:'194px', minWidth:'130px'}}>decimal point</GroupName>
                        <CustomInputComponent
                            attributeName='value-decimal-point'
                            labelWidth='24px'
                            valueType='number'
                            value={_data['value_decimal_point']} 
                            {...commonProps}
                        />
                    </div>
                : _data[`${ShowTabModified}_format`] === 'text' &&
                    <div>
                        <InputComponent
                            attributeName={`${showTab}-text`}
                            value={_data[`${ShowTabModified}_text`]} 
                            {...commonProps}
                        />
                    </div>
            }
        </PropertiesContent>
    )
}

const CustomInputComponent = styled(InputComponent)`
    width: 80px;
`;