import {AccessAxios} from 'components/Axios';

/**
 * 내가 생성한 DB테이블 정보를 가져온다.
 * @param {string} MEMBER_SYSTEMID 유저 아이디
 * @returns 
 */
export const GetMyTableList = async (MEMBER_SYSTEMID) => {
    const MSG = { MEMBER_SYSTEMID }
    try {
        const res = await AccessAxios.post('/api/DAQ/Meta/GetMyTableInfo', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 공유된 DB테이블 정보를 가져온다.
 * @param {string} MEMBER_SYSTEMID  유저 아이디
 * @returns 
 */
export const GetPrivateTableList = async (MEMBER_SYSTEMID) => {
    const MSG = { MEMBER_SYSTEMID }
    try {
        const res = await AccessAxios.post('/api/DAQ/Meta/GetPrivateTableInfo', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * DB권한이 public인 DB테이블 정보를 가져온다.
 * @returns 
 */
export const GetPublicTableList = async () => {
    try {
        const res = await AccessAxios.post('/api/DAQ/Meta/GetPublicTableInfo');
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * DB 상세 정보를 가져온다.
 * @param {string} MEMBER_SYSTEMID  유저 아이디
 * @param {string} TABLE_ID         테이블 ID
 * @returns 
 */
export const GetTableDetail = async (MEMBER_SYSTEMID, TABLE_ID) => {
    const MSG = { MEMBER_SYSTEMID, TABLE_ID }
    try {
        const res = await AccessAxios.post('/api/DAQ/Meta/GetTableDetail', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}


/**
 Device를 등록한다.
 * @param {string} _MSG JSON 형식의 메세지 {  
    "MEMBER_SYSTEMID": "123", (Not null, Not empty)
    "DB_TABLE_NAME": "Table1", (Not null, Not empty)
    "DB_TYPE": "Postgresql", (Default: Postgresql)
    "DESCRIPTION": "123"
    "TAG_INFO_LIST": []
}
 */
export const CreateTable = async (_MSG) => {
    try{
        const res = await AccessAxios.post('/api/DAQ/Meta/CreateTable', _MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 Device의 table 정보를 update 한다..
 * @param {string} MEMBER_SYSTEMID 유저 아이디
 * @param {string} TABLE_ID 테이블 아이디   
 * @param {Object} Data 테이블 정보 {
        "DB_TABLE_NAME": "string",
        "ACQUISITION_TYPE": "string",
        "DEVICE_IP": "string",
        "GET_URL": "string",
        "SET_URL": "string",
        "DB_TYPE": "string",
        "DESCRIPTION": "string"
    }
 */
export const UpdateTable = async (MEMBER_SYSTEMID, TABLE_ID, Data) => {
    const MSG = { MEMBER_SYSTEMID, TABLE_ID, Data }
    try{
        const res = await AccessAxios.post('/api/DAQ/Meta/UpdateTable', MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 Device를 삭제한다.
 * @param {string} MEMBER_SYSTEMID 유저 아이디
 * @param {string} TABLE_ID 테이블ID
 */
export const DeleteTable = async (MEMBER_SYSTEMID, TABLE_ID) => {
    const MSG = {MEMBER_SYSTEMID, TABLE_ID }
    try{
        const res = await AccessAxios.post(`/api/DAQ/Meta/DeleteTable`, MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
테이블의 데이터를 가져온다.
* @param {string} MEMBER_SYSTEMID 유저 아이디
* @param {string} TABLE_ID 테이블 ID
*/
export const GetTagList = async (MEMBER_SYSTEMID, TABLE_ID) => {
    const MSG = { MEMBER_SYSTEMID, TABLE_ID }
    try{
        const res = await AccessAxios.post('api/DAQ/Meta/GetAllTagFromTable', MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 tag 정보를 원하는 태그에 현재 시간으로 값을 추가합니다.
 * @param {string} Id  유저 아이디
 * @param {string} Table  테이블 이름
 * @param {Array} TagsAndValue  태그와 값의 배열 [{Tag: '태그이름', Value: '값'}]
 */
 export const SetTagValues = async (Id, Table, TagsAndValue) => {
    const MSG = { Id, Table, TagsAndValue }

    try{
        const res = await AccessAxios.post('/api/DAQ/Meta/SetTagValues', MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 tag 정보를 추가한다.
 * @param {string} MEMBER_SYSTEMID  유저 아이디
 * @param {string} TABLE_ID  테이블 아이디
 * @param {Array} TAG_INFO_LIST  태그와 값의 배열 [{TAG: '태그이름', DATA_TYPE: '데이터 타입(string, int, double)', DESCRIPTION: '태그 설명', IO_TYPE:디바이스 Input/Output 타입, VALUE: '태그 값'}]
 */
 export const AddTag = async (MEMBER_SYSTEMID, TABLE_ID, TAG_INFO_LIST) => {
    const MSG = { MEMBER_SYSTEMID, TABLE_ID, TAG_INFO_LIST}

    try{
        const res = await AccessAxios.post('/api/DAQ/Meta/AddTag', MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 tag 정보를 삭제한다.
 * @param {string} MEMBER_SYSTEMID  유저 아이디
 * @param {string} TABLE_ID  테이블 id
 * @param {Array} TAG_LIST  태그와 값의 배열 ["Tag1", "Tag2"]
 */
 export const DeleteTag = async (MEMBER_SYSTEMID, TABLE_ID, TAG_LIST) => {
    const MSG = { MEMBER_SYSTEMID, TABLE_ID, TAG_LIST }

    try{
        const res = await AccessAxios.post('/api/DAQ/Meta/DeleteTag', MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 tag 정보를 업데이트한다
 * @param {string} MEMBER_SYSTEMID  유저 아이디
 * @param {string} TABLE_ID  테이블 id
 * @param {string} TAG  태그 이름
 * @param {Object} Data    태그 정보 {
        "tag" : "변경할 태그명",
        "type": "변경할  타입",
        "description": "변경할 설명",
        "IOType": "변경할 IOType"
    }
 */
 export const UpdateTag = async (MEMBER_SYSTEMID, TABLE_ID, TAG, Data) => {
    const MSG = {MEMBER_SYSTEMID, TABLE_ID, TAG, Data}
    try{
        const res = await AccessAxios.post('/api/DAQ/Meta/UpdateTag', MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 * 원하는 태그의 정보를 반환합니다
 * @param {string} MEMBER_SYSTEMID       -유저 아이디
 * @param {Array[object]} TABLE_AND_TAGS - 테이블과 태그 정보 [{TABLE_ID: '테이블 ID', TAG: '태그 이름'}]
 * @param {boolean} USEVARIABLENAME       -기본값 true
 * @returns 
 */
export const GetSelectedTag = async (MEMBER_SYSTEMID, TABLE_AND_TAGS, USEVARIABLENAME = true) => {
    const MSG = {MEMBER_SYSTEMID, TABLE_AND_TAGS, USEVARIABLENAME }
    try{
        const res = await AccessAxios.post('/api/DAQ/Meta/GetSelectedTag', MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 * API Key를 가져온다.
 * @param {string} MEMBER_SYSTEMID   유저 아이디
 * @param {table} TABLE_ID 테이블 id
 * @returns API Key
 */
export const GetAPIKey = async (MEMBER_SYSTEMID, TABLE_ID) => {
    const MSG = {MEMBER_SYSTEMID, TABLE_ID}
    try{
        const res = await AccessAxios.post('/api/DAQ/Meta/GetAPIKey', MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 * API Key 재발급
 * @param {string} MEMBER_SYSTEMID   유저 아이디
 * @param {table} TABLE_ID 테이블 id
 * @returns API Key
 */
export const RegenerateAPIKey = async (MEMBER_SYSTEMID, TABLE_ID) => {
    const MSG = {MEMBER_SYSTEMID, TABLE_ID}
    try{
        const res = await AccessAxios.post('/api/DAQ/Meta/RegenerateAPIKey', MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 * 스케줄 활성화/비활성화
 * @param {string} MEMBER_SYSTEMID   - 유저 아이디
 * @param {string} SCHEDULE_ID     - 
 * @param {boolean} ACTIVATION    - 활성화/비활성화
 * @returns 
 */
export const SetScheduleActivation = async (MEMBER_SYSTEMID, SCHEDULE_ID, ACTIVATION) => {
    const MSG = {MEMBER_SYSTEMID, SCHEDULE_ID, ACTIVATION}
    try{
        const res = await AccessAxios.post('/api/DAQ/Schedule/Meta/SetScheduleActivation', MSG);
        return res['data'];
    }catch(err){
        throw err;
    }
}

/**
 * Table의 권한을 가지고 있는 member를 가져온다.
 * @param {string} MEMBER_SYSTEMID  - 회원ID
 * @param {string} TABLE_ID         - 테이블 ID
 * @param {boolean} CONTAIN_SELF         - 본인 포함 여부 기본값 false
 * @returns 
 */
export const GetAccessibleMemberList = async (MEMBER_SYSTEMID, TABLE_ID, CONTAIN_SELF = false) => {
    const MSG = { MEMBER_SYSTEMID, TABLE_ID, CONTAIN_SELF }
    try {
        const res = await AccessAxios.post('/api/DAQ/Auth/GetAccessibleMemberList', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * Table의 권한을 member에게 부여한다.
 * @param {string} MEMBER_SYSTEMID          - 회원ID
 * @param {string} TABLE_ID                 - 테이블 ID
 * @param {object} TARGET_MEMBER_AUTH   - 권한을 부여할 회원 정보 [{email: 'string', read: 0, write: 0, edit: 0, manage: 0, master: 0}]
 * @returns 
 */
export const SetTargetMemberAuthorization = async (MEMBER_SYSTEMID, TABLE_ID, TARGET_MEMBER_AUTH) => {
    const MSG = { MEMBER_SYSTEMID, TABLE_ID, TARGET_MEMBER_AUTH }
    try {
        const res = await AccessAxios.post('/api/DAQ/Auth/SetTargetMemberAuthorization', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 해당 회원이 Table에 대한 권한을 가져온다.
 * @param {string} MEMBER_SYSTEMID  - 회원ID
 * @param {string} TABLE_ID         - 테이블 ID
 * @returns 
 */
export const GetMemberAuthorization = async (MEMBER_SYSTEMID, TABLE_ID) => {
    const MSG = { MEMBER_SYSTEMID, TABLE_ID}
    try {
        const res = await AccessAxios.post('/api/DAQ/Auth/GetMemberAuthorization', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * TableType에 따른 table list를 가져온다.
 * @param {string} MEMBER_SYSTEMID 
 * @param {string} TableType 
 * @returns table list
 */
export const GetDeviceList = async (MEMBER_SYSTEMID, TableType) => {
    try {
        let resDat = '';
        switch (TableType) {
            case 'My':
                resDat = await GetMyTableList(MEMBER_SYSTEMID)
                break;
            case 'Private':
                resDat = await GetPrivateTableList(MEMBER_SYSTEMID)
                break;
            case 'Public':
                resDat = await GetPublicTableList()
                break;
            default:
                break;
        }

        const { RESULT, MSG, DATA } = resDat.data;
        if (RESULT === 'Ok') {
            return DATA;
        } else {
            throw new Error(`GetDeviceList Error : [${TableType}] ${MSG}`);
        }
    } catch (error) {
        throw new Error(`GetDeviceList Error : ${error}`);
    }
}

/**
 * table의 데이터 수집 Schedule을 세팅한다.
 * @param {string} MEMBER_SYSTEMID 
 * @param {string} TABLE_ID 
 * @param {string} SCHEDULE_NAME 
 * @param {string} METHOD 
 * @param {string} URL 
 * @param {string} BODY 
 * @param {number} SCHEDULE_INTERVAL 
 * @param {string} DESCRIPTION 
 * @param {string} PARSING_SCRIPT 
 * @returns 
 */
export const  CreateSchedule = async (MEMBER_SYSTEMID, TABLE_ID, SCHEDULE_NAME, METHOD, URL, BODY, SCHEDULE_INTERVAL, DESCRIPTION, PARSING_SCRIPT) => {
    const MSG = { MEMBER_SYSTEMID, TABLE_ID, SCHEDULE_NAME, METHOD, URL, BODY, SCHEDULE_INTERVAL, DESCRIPTION, PARSING_SCRIPT}
    try {
        const res = await AccessAxios.post('/api/DAQ/Schedule/Meta/CreateSchedule', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * schedule을 업데이트 한다.
 * @param {string} MEMBER_SYSTEMID 
 * @param {string} SCHEDULE_ID 
 * @param {string} TABLE_ID 
 * @param {object} DATA     -SCHEDULE_NAME, METHOD, URL, BODY, SCHEDULE_INTERVAL, DESCRIPTION, PARSING_SCRIPT
 * @returns 
 */
export const UpdateSchedule = async(MEMBER_SYSTEMID, SCHEDULE_ID, TABLE_ID, DATA) =>{
    const MSG = { MEMBER_SYSTEMID, SCHEDULE_ID, TABLE_ID, DATA}
    try {
        const res = await AccessAxios.post('/api/DAQ/Schedule/Meta/UpdateSchedule', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * schedule을 삭제한다.
 * @param {string} MEMBER_SYSTEMID 
 * @param {string} SCHEDULE_ID 
 * @returns 
 */
export const DeleteSchedule = async(MEMBER_SYSTEMID, SCHEDULE_ID) =>{
    const MSG = {MEMBER_SYSTEMID, SCHEDULE_ID}
    try {
        const res = await AccessAxios.post('/api/DAQ/Schedule/Meta/DeleteSchedule', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * table의 모든 schedule 정보를 가져온다.
 * @param {string} MEMBER_SYSTEMID 
 * @param {string} TABLE_ID 
 * @returns 
 */
export const GetScheduleInfo = async(MEMBER_SYSTEMID, TABLE_ID)=>{
    const MSG = {MEMBER_SYSTEMID, TABLE_ID}
    try {
        const res = await AccessAxios.post('/api/DAQ/Schedule/Meta/GetScheduleInfo', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * tag의 특정 구간 데이터를 일정 주기만큼 가져온다.
 * @param {string} MEMBER_SYSTEMID 
 * @param {string} TABLE_ID 
 * @param {string} TAG
 * @param {Date} START_TIME
 * @param {Date} END_TIME
 * @param {number} DURATION
 * @param {boolean} USE_VARIABLE_NAME
 * @returns 
 */
export const GetTagHistory = async (MEMBER_SYSTEMID, TABLE_ID, TAG, START_TIME, END_TIME, DURATION, TIME_ZONE, USE_VARIABLE_NAME = true) => {
    if (TIME_ZONE === undefined || TIME_ZONE === '' || TIME_ZONE === null) {
        TIME_ZONE = 'Asia/Seoul';
    }
    const MSG = {MEMBER_SYSTEMID, TABLE_ID, TAG, START_TIME, END_TIME, DURATION, TIME_ZONE, USE_VARIABLE_NAME}
    try {
        const res = await AccessAxios.post('/api/DAQ/Meta/GetTagHistory', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * tag의 가장 최근 데이터를 가져온다.
 * @param {string} MEMBER_SYSTEMID 
 * @param {Array[object]} TABLE_AND_TAGS - 테이블과 태그 정보 [{TABLE_ID: '테이블 ID', TAG: '태그 이름'}]
 * @returns 
 */
export const GetTagValues = async(MEMBER_SYSTEMID, TABLE_AND_TAGS )=>{
    const MSG = {MEMBER_SYSTEMID, TABLE_AND_TAGS}
    try {
        const res = await AccessAxios.post('/api/DAQ/Meta/GetTagValues', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}
