import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";
import SelectList from "components/atom/SelectList";
import ColorComponent from "components/molecule/ColorComponent"
import { GroupName } from 'components/css/common';

import styled from "styled-components";

export default function SeriesSymbolInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        symbol_width: NodeSymbolWidth,
        symbol_height: NodeSymbolHeight,
        symbol_type: NodeSymbolType,
        symbol_stroke_width: NodeSymbolStrokeWidth,
        symbol_step: NodeSymbolStep,
        type: NodeType,
    } = _data;

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return (
        <PropertiesContent _Title="Symbol">
            <div>
                <InputComponent label='W' attributeName='symbol-width' valueType='number' labelWidth='24px' value={NodeSymbolWidth} {...commonProps}/>
                <InputComponent label='H' attributeName='symbol-height' valueType='number' labelWidth='24px' value={NodeSymbolHeight} {...commonProps}/>
            </div>
            <div>
                <GroupName width={76}>type</GroupName>
                <SelectList
                    attributeName="symbol-type"
                    dataList={["none", "circle", "rect", "triangle"]}
                    selectedValue={NodeSymbolType}
                    {...commonProps}
                />
            </div>
            <div>
                <ColorComponent label="fill" attributeName="symbol-fill" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
            <div>
                <ColorComponent label="stroke" attributeName="symbol-stroke" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
            <div>
                <CustomGroupName >stroke-width</CustomGroupName>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='symbol-stroke-width' 
                    valueType='number' 
                    labelWidth='24px' 
                    value={NodeSymbolStrokeWidth} 
                    {...commonProps} 
                />
            </div>
            {(NodeSymbolStep !== undefined && NodeType !== 'series-realtime-stem') &&
                <div>
                    <CustomInputComponent label='step' attributeName='symbol-step' valueType='number' labelWidth='50px' value={NodeSymbolStep} {...commonProps}/>
                </div>
            }
        </PropertiesContent>
    );
}

const CustomGroupName = styled(GroupName)`
    width: 100%;
    max-width: 208px;
    min-width: 120px;
`;

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;