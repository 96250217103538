import PropertiesContent from "components/atom/PropertiesContent";
import LabelAndInput from "babylon/components/atom/LabelAndInput";
import useDataController from "babylon/hooks/useDataController";

import { GroupName, XYZInput } from 'components/css/common';

export default function ContourProperties({selectBabylonData, SelectedMesh}) {
    const {
        hiddenPoints: MeshHiddenPoints, 
        subdivision: MeshSubdivisions
    } = selectBabylonData;

    const {UploadDataByMesh} = useDataController();      

    return (
            <PropertiesContent 
                _Title="ContourProperties"
            >
                {<LabelAndInput 
                    id='hiddenPoints'
                    label='hiddenPoints'
                    inputType='string'
                    value={MeshHiddenPoints}
                    labelWidth="50%"
                    changeEvent={(e)=>{
                        const targetValue = Number(e.target.value);
                        SelectedMesh.hiddenPoints = targetValue;
                        UploadDataByMesh(SelectedMesh);
                    }}
                />}

                 {MeshSubdivisions &&
                <XYZInput>
                    <GroupName>Subdivision</GroupName>
                    <div>
                        {['x', 'y', 'z'].map((axis) => {
                            const KeyValue = `subdivisions.${axis}`;
                            return (
                                <LabelAndInput
                                    key={KeyValue}
                                    id={KeyValue}
                                    label={axis.toUpperCase()}
                                    inputType='number'
                                    value={MeshSubdivisions[axis] || '0'}
                                    changeEvent={(e)=>{
                                        const targetValue = Number(e.target.value);
                                        SelectedMesh.setSubdivision({ [axis]: targetValue});
                                        UploadDataByMesh(SelectedMesh);
                                    }}
                                />
                            )
                        })}
                    </div>
                </XYZInput>
            }    
            </PropertiesContent>            
    )
}