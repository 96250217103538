import {useState, useEffect, useRef, useCallback} from "react";
import {CheckUseVideo} from 'hooks/useEnterWorld';

import styled from "styled-components";
import { MdOutlineVideocam, MdOutlineVideocamOff} from "react-icons/md";

export default function VideoContent({enuSpace}) {
    const videoRef = useRef();
    const videoCanvasRef = useRef();

    const [startVideoInfo, setStartVideoInfo] = useState({
        isUseVideo: false,
        isInterval: false,
    });

    const InitVideo = (e) => {
        const constraints = { video: { width: 110, height: 83 } };
        // console.log(navigator.mediaDevices.getUserMedia(constraints))
        navigator.mediaDevices.getUserMedia(constraints)
            .then((mediaStream) => {
                videoRef.current.srcObject = mediaStream;
            })
            .catch(err => {
                console.log("미디어 오류-   ", err)
            })
    }

    const StopVideo = (e) => {
        const mediaStream = videoRef.current.srcObject;
        if (mediaStream) {
            const tracks = mediaStream.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;

            //캔버스 내용 지우기
            const canvasContext = videoCanvasRef.current.getContext('2d');
            canvasContext.clearRect(0, 0, videoCanvasRef.current.width, videoCanvasRef.current.height);
            setStartVideoInfo({...startVideoInfo, isInterval : false});

            enuSpace.MetaRequest('UpdateVideoOn', 'false');
        }
    }

    const SetVideoImage = useCallback(() =>{
        const context = videoCanvasRef.current.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, videoCanvasRef.current.width, videoCanvasRef.current.height);
        const imageDataUrl = videoCanvasRef.current.toDataURL('image/jpeg');
        enuSpace.MetaRequest('UpdateCam', imageDataUrl);
    }, [enuSpace]);

    useEffect(()=>{
        if(startVideoInfo.isUseVideo && startVideoInfo.isInterval){
            enuSpace.MetaRequest('UpdateVideoOn', 'true');
            InitVideo();
        }
    }, [enuSpace, startVideoInfo]);

    useEffect(() => {
        let intervalId;
    
        if (startVideoInfo.isInterval) {
          intervalId = setInterval(() => {
            SetVideoImage();
          }, 250);
        }
    
        return () => {
          clearInterval(intervalId);
        };
      }, [startVideoInfo, SetVideoImage]);

      const handleLoadedMetadata = () => {
        videoRef.current.play();
    };

    return (
        <VideoBtn>
            <div>
                {startVideoInfo.isInterval
                    ? <MdOutlineVideocam className="btn btn_video" onClick={StopVideo}/>
                    : <MdOutlineVideocamOff className="btn btn_video" onClick={(e) => {
                        CheckUseVideo()
                            .then((res) => {
                                if (res) {
                                    setStartVideoInfo({ isUseVideo: true, isInterval: true });
                                } else {
                                    setStartVideoInfo({ isUseVideo: false, isInterval: false });
                                    alert('사용가능한 카메라가 없습니다.');
                                }
                            })
                            .catch((err) => {
                                setStartVideoInfo({ isUseVideo: false, isInterval: false });
                                alert('사용가능한 카메라가 없습니다.');
                            });
                    }} />
                }
            </div>
            <video ref={videoRef} id="videoElement" width="110" height="83" onLoadedMetadata={handleLoadedMetadata}  style={{position:'absolute', bottom:'0', right:'0', background:'red', zIndex:'-99', display:'none'}} />
            <canvas ref={videoCanvasRef} id="videoCanvas" width="110" height="83" style={{position:'absolute', bottom:'0', right:'10%', background:'red', zIndex:'-99', display:'none'}} />
        </VideoBtn>
    )
}

const VideoBtn = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &&>div .btn{
        height: 30px;
        width: 30px;
        color: ${({theme}) => theme.base.font_color_DarkGray};
        border: 1px solid ${({theme}) => theme.base.border_color_Gray};
        border-radius: 4px;
        box-sizing: border-box;

        &.btn_video{
            padding: 3px;
            &:hover{
                color: ${({theme}) => theme.base.font_color_DarkBlue};
                border-color: ${({theme}) => theme.base.border_color_Blue};
            }
        }
    }
`;