import { AccessAxios} from 'components/Axios';

const BaseURL = '/api/SNS';

//MSG_TYPE
//Request Friend: 0
//Accept Friend: 1
//Reject Friend: 2
//
//REQUESTER_CHECK
//Not Confirm: 0
//Confirm: 1
//
//RESPONSER_CHECK
//Not Confirm: 0
//Accept: 1

/**
 * 친구 요청을 보낸다.
 * @param {String} REQUESTER_EMAIL  - 요청자 이메일
 * @param {String} RESPONSER_EMAIL  - 응답자 이메일
 * @returns 
 */
export const SendFriendRequest = (REQUESTER_EMAIL, RESPONSER_EMAIL) => {
    const MSG = { REQUESTER_EMAIL, RESPONSER_EMAIL}
    try{
        const res = AccessAxios.post(`${BaseURL}/SendFriendRequest`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 친구 요청을 받는다.
 * @param {String} MEMBER_SYSTEMID   - 회원 시스템 ID
 * @param {String} LANGUAGE          - 언어
 * @returns 
 */
export const GetFriendAlarmList = (MEMBER_SYSTEMID, LANGUAGE='kr') => {
    const MSG = { MEMBER_SYSTEMID, LANGUAGE }
    try{
        const res = AccessAxios.post(`${BaseURL}/GetFriendAlarmList`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 친구 요청에 대한 응답을 확인한다.
 * @param {String} MSG_TYPE              - 메시지 타입
 * @param {String} RESPONSER_SYSTEMID    - 응답자 시스템 ID
 * @returns 
 */
export const CheckFriendResponse = (MSG_TYPE=0, RESPONSER_SYSTEMID) => {
    const MSG = { MSG_TYPE, RESPONSER_SYSTEMID }
    try{
        const res = AccessAxios.post(`${BaseURL}/CheckFriendResponse`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 친구 요청을 수락한다.
 * @param {String} REQUESTER_EMAIL  - 요청자 이메일
 * @param {String} RESPONSER_EMAIL  - 응답자 이메일
 * @returns 
 */
export const AcceptFriendRequest = (REQUESTER_EMAIL, RESPONSER_EMAIL) => {
    const MSG = { REQUESTER_EMAIL, RESPONSER_EMAIL}
    try{
        const res = AccessAxios.post(`${BaseURL}/AcceptFriendRequest`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 친구 요청을 거절한다.
 * @param {String} REQUESTER_EMAIL  - 요청자 이메일
 * @param {String} RESPONSER_EMAIL  - 응답자 이메일
 * @returns 
 */
export const RejectFriendRequest = (REQUESTER_EMAIL, RESPONSER_EMAIL) => {
    const MSG = { REQUESTER_EMAIL, RESPONSER_EMAIL}
    try{
        const res = AccessAxios.post(`${BaseURL}/RejectFriendRequest`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 친구리스트를 가져온다.
 * @param {String} MEMBER_SYSTEMID - 회원 시스템 ID
 * @returns 
 */
export const GetFriendList = (MEMBER_SYSTEMID) => {
    const MSG = { MEMBER_SYSTEMID }
    try{
        const res = AccessAxios.post(`${BaseURL}/GetFriendList`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 친구를 삭제한다.
 * @param {String} MEMBER_SYSTEMID   - 회원 시스템 ID
 * @param {Array[String]} FRIENDS_EMAIL     - 친구 이메일
 * @returns 
 */
export const RemoveFriend = (MEMBER_SYSTEMID, FRIENDS_EMAIL) =>{
    const MSG = { MEMBER_SYSTEMID, FRIENDS_EMAIL}
    try{
        const res = AccessAxios.post(`${BaseURL}/RemoveFriend`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 그룹 생성
 * @param {String} MEMBER_SYSTEMID          - 회원 시스템 ID
 * @param {String} GROUP_NAME               - 그룹 이름
 * @param {String} DESCRIPTION              - 설명
 * @param {Array[String]} FRIENDS_EMAIL     - 친구 이메일
 * @returns 
 */
export const  CreateGroup = (MEMBER_SYSTEMID, GROUP_NAME, DESCRIPTION, FRIENDS_EMAIL) => {
    const MSG = { MEMBER_SYSTEMID, GROUP_NAME, DESCRIPTION, FRIENDS_EMAIL}
    try{
        const res = AccessAxios.post(`${BaseURL}/CreateGroup`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 그룹을 제거 한다.
 * @param {String} MEMBER_SYSTEMID 
 * @param {String} GROUP_SYSTEMID 
 * @returns 
 */
export const DeleteGroup = (MEMBER_SYSTEMID, GROUP_SYSTEMID) => {
    const MSG = { MEMBER_SYSTEMID, GROUP_SYSTEMID}
    try{
        const res = AccessAxios.post(`${BaseURL}/DeleteGroup`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 그룹 리스트정보를 업데이트 한다.
 * @param {String} MEMBER_SYSTEMID              - 회원 시스템 ID
 * @param {String} GROUP_SYSTEMID               - 그룹 시스템 ID
 * @param {String} GROUP_NAME                   - 그룹 이름
 * @param {String} DESCRIPTION                  - 설명
 * @param {Array[String]} ADD_MEMBER_EMAIL      - 추가 멤버 이메일
 * @param {Array[String]} REMOVE_MEMBER_EMAIL   - 제거 멤버 이메일
 * @returns 
 */
export const UpdateGroup = (MEMBER_SYSTEMID, GROUP_SYSTEMID, GROUP_NAME, DESCRIPTION, ADD_MEMBER_EMAIL, REMOVE_MEMBER_EMAIL) => {
    const MSG = { MEMBER_SYSTEMID, GROUP_SYSTEMID, GROUP_NAME, DESCRIPTION, ADD_MEMBER_EMAIL, REMOVE_MEMBER_EMAIL}
    try{
        const res = AccessAxios.post(`${BaseURL}/UpdateGroup`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 그룹 리스트를 가져온다.
 * @param {String} MEMBER_SYSTEMID  - 회원 시스템 ID
 * @returns 
 */
export const GetGroupList = (MEMBER_SYSTEMID) => {
    const MSG = { MEMBER_SYSTEMID }
    try{
        const res = AccessAxios.post(`${BaseURL}/GetGroupList`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 그룹 멤버 리스트를 가져온다.
 * @param {String} GROUP_SYSTEMID   - 그룹 시스템 ID 
 * @returns 
 */
export const GetGroupMemberList = (GROUP_SYSTEMID) => {
    const MSG = { GROUP_SYSTEMID }
    try{
        const res = AccessAxios.post(`${BaseURL}/GetGroupMemberList`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 그룹 과 멤버 리스트를 가져온다.
 * @param {String} GROUP_SYSTEMID   - 회원 시스템 ID
 * @returns 
 */
export const GetGroupListWithMember = (MEMBER_SYSTEMID) => {
    const MSG = { MEMBER_SYSTEMID }
    try{
        const res = AccessAxios.post(`${BaseURL}/GetGroupListWithMember`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 그룹에 멤버를 추가한다.
 * @param {String} GROUP_SYSTEMID           - 그룹 시스템 ID
 * @param {Array[String]} FRIENDS_EMAIL     - 친구 이메일
 * @returns 
 */
export const AddGroupMember = (GROUP_SYSTEMID, FRIENDS_EMAIL) => {
    const MSG = { GROUP_SYSTEMID, FRIENDS_EMAIL }
    try{
        const res = AccessAxios.post(`${BaseURL}/AddGroupMember`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 그룹 멤버를 제거한다.
 * @param {String} GROUP_SYSTEMID 
 * @param {Array[String]} FRIENDS_EMAIL 
 * @returns 
 */
export const RemoveGroupMember = (GROUP_SYSTEMID, FRIENDS_EMAIL) => {
    const MSG = { GROUP_SYSTEMID, FRIENDS_EMAIL }
    try{
        const res = AccessAxios.post(`${BaseURL}/RemoveGroupMember`, MSG);
        return res;
    } catch (err) {
        throw err;
    }
}