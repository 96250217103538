import { useState ,useRef } from "react";

import {BtnPrimary, BtnSecondary, BtnGhost} from "components/atom/Button";
import MouseRightClickMenuContent from "components/molecule/MouseRightClickMenuContent";
import {ModalBottomBox} from "components/css/common";
import {EnuIconFolderClose} from "components/common/EnuSpaceIcon"
import {IMG_RESOURCE_TYPE} from "components/common/EnuSpaceEnum";

import useRightMenu from "hooks/useRightMenu";
import useImageList from 'hooks/useImageList';
import useModalPopup from 'hooks/useModalPopup';

import styled from "styled-components";
import { useSelector, shallowEqual } from 'react-redux';
import { MdClear, MdHome, MdDriveFileRenameOutline } from "react-icons/md";

function ImageContent({enuSpace, _Modal}){
    const {AddModal, DeleteModal} = useModalPopup();

    const {userId, projectId} = useSelector(state => state.projectData.BASE_DATA);

    const InputFile = useRef(null);
    const [selectImage, setSelectImage] = useState('');
    const [FileData, setFileData] = useState('');
    const [MoveTargetFile, setMoveTargetFile] = useState('');
    const [imageVersion, setImageVersion] = useState(0);    //이미지 캐시를 위한 버전
    const {
        ImageList,
        CurrentFolderPathArray,
        RefetchImageList, 
        AddFetchImage, 
        DeleteFetchImage, 
        MoveFetchFile, 
        AddFetchFolder,
    } = useImageList();

    const RightMenuInfo = useSelector(state => {
        if (state.rightMenu.type === 'imageModal')
            return state.rightMenu;
        return null;
    }, shallowEqual);
    const {ShowRightMenu, CloseRightMenu} = useRightMenu();

    // Lift Image List 갱신을 위한 이벤트
    const ReloadImageTreeEvent = () =>{
        if(window.ReloadImageTreeList !== undefined)
            window.ReloadImageTreeList();
    }

    const AddImage = () => {
        AddFetchImage(FileData)
            .then(res => {
                setFileData('');
                InputFile.current.value = '';
                ReloadImageTreeEvent();
            })
            .catch(err => {
                window.AddAlertMsg({ type: 'error', msg: err });
            });
    }

    const DeleteItem = (e, targetName, targetPath)=>{
        if (!window.confirm(`"${targetName}" 을 삭제하시겠습니까?`)) return;

        DeleteFetchImage(targetPath)
            .then(res => {
                ReloadImageTreeEvent();
            })
            .catch(err => {
                window.AddAlertMsg({type:'error', msg:err});
            });
        e.stopPropagation();
    }

    const AddFolder = (e) => {
        const getNewFolderName = (count) => `NewFolder_${count}`;
        const childTitleArray = ImageList[0]?.child?.map(child => child.title) || [];

        let count = 0;      
        while (childTitleArray.includes(getNewFolderName(count))) {
            count++;
        }
    
        AddFetchFolder(getNewFolderName(count))
            .then(res => {
                ReloadImageTreeEvent();
            })
            .catch(err => {
                window.AddAlertMsg({type:'error', msg:err});
            });
    }

    const SelectedFolder = (pathIndex) =>{
        const selectPath = CurrentFolderPathArray.slice(0, pathIndex+1);
        RefetchImageList(selectPath.join("/"));
    }

    const MouseRightClick = (e, targetName) => {
        e.preventDefault();
        const translateData = document.getElementById('ImageModal').childNodes[0].style.transform;
        const arrTranslateData = translateData.substring(translateData.indexOf('(') + 1, translateData.indexOf(')')).split(',').map(item => parseInt(item));
        ShowRightMenu({
            type: 'imageModal',
            top: parseInt(e.clientY - arrTranslateData[1]),
            left: parseInt(e.clientX - arrTranslateData[0]),
            data: { targetName }
        });
    }

    const MoveFileDrop= (folderName) =>{
        const fileName = MoveTargetFile;
        const fromPath = CurrentFolderPathArray.join('/');
        let toPath;

        if(folderName === '...'){
            const copyCurrentPath = JSON.parse(JSON.stringify(CurrentFolderPathArray));
            copyCurrentPath.pop();
            toPath = copyCurrentPath.join('/');
        }else{
            toPath = fromPath + '/'+folderName;
        }

        MoveFileDropFolder([fileName], fromPath, toPath);
    }

    const MoveFileDropPath = (_index) =>{
        const fileName = MoveTargetFile;
        const fromPath = CurrentFolderPathArray.join('/');
        let toPath = '';

        for (let count = 0; count <= _index; count++) {
            toPath += count === 0 ? CurrentFolderPathArray[count] : '/'+CurrentFolderPathArray[count];
        }

        MoveFileDropFolder([fileName], fromPath, toPath);
    }

    const MoveFileDropFolder = (_arrFileName , _fromPath, _toPath) =>{
        if(`${_fromPath}/${_arrFileName.join('')}` === _toPath) return;

        MoveFetchFile(_arrFileName, _fromPath, _toPath)
            .then(res => {
                const {result} = res.data;
                if(result === 'Success'){
                    ReloadImageTreeEvent();
                }else{
                    if(res.data['state'] === 'NameConflict'){
                        AddModal('ChangeImageModalAlert', res.data['state'], 'alert', {text: "이미 존재하는 파일명입니다."})
                    }
                }
            })
            .catch(err => {
                window.AddAlertMsg({type:'error', msg:err});
            });
    }

    const DrawImage = (e) =>{
        const canvasSpace = document.getElementById("canvasSpace");
        const x = canvasSpace.clientWidth/2;
        const y = canvasSpace.clientHeight/2;
        const imageJson = {
            TYPE : "image",
            PATH : `/${CurrentFolderPathArray.join('/')}/${selectImage}`
        }

        enuSpace.CreateObject('Canvas', 'image', JSON.stringify(imageJson), x, y);
    };

    const RenderFolderItem = (itemName, OptionIcon, props) => {
        return (
            <ItemContent 
                {...props}
                onDoubleClick={(e) => {
                    itemName === '...' ? CurrentFolderPathArray.pop() : CurrentFolderPathArray.push(itemName);
                    RefetchImageList();
                }}
                onDrop={(e)=>MoveFileDrop(itemName)}
                onDragOver={(e) => e.preventDefault()}
            >
                <EnuIconFolderClose className='icon_folder' />
                <span {...props}>{itemName}</span>
                {OptionIcon && OptionIcon}
            </ItemContent>
        )
    }

    const RenderImgList = (menu) => menu?.map(({ type: ItemType, title: ItemTitle }, indx)=>{
        const ImageSrc = `${CurrentFolderPathArray.join('/')}/${ItemTitle}`;
        const imageJson = {
            TYPE : "image",
            PATH : `/${ImageSrc}`
        }
        const commonProps = {
            key: ItemTitle,
            title: ItemTitle,
            'data-name': ItemTitle,
            onContextMenu: (e)=>MouseRightClick(e, ItemTitle),
            draggable: true,
            onDragStart: (e) => {
                if(ItemType === IMG_RESOURCE_TYPE.IMAGE)
                    e.dataTransfer.setData('text/plain', JSON.stringify({ ImageSrc:imageJson, type: 'image' }))
                setMoveTargetFile(ItemTitle);
            },
            onDragEnd: (e) => setMoveTargetFile(''),
        };
        
        const closeIcon = () => (
            <MdClear 
                className="btn_close" 
                onClick={(e)=>DeleteItem(e, ItemTitle, ImageSrc)} 
            />
        );

        switch (ItemType) {
            case IMG_RESOURCE_TYPE.FOLDER:
                return RenderFolderItem(ItemTitle, closeIcon(), {...commonProps});
            case IMG_RESOURCE_TYPE.IMAGE:
                const isSelected = ItemTitle === selectImage;
                return (
                    <ItemContent
                        {...commonProps}
                        className={isSelected ? 'selectImage' : ''}
                        onClick={(e) => setSelectImage(isSelected ? '' : ItemTitle)}
                    >
                        <img
                            alt=''
                            src={`Repository/${userId}/${projectId}/${ImageSrc}?v=${imageVersion}`}
                        />
                        <span title={ItemTitle}>{ItemTitle}</span>
                        {closeIcon()}
                    </ItemContent>
                )
            default:
                return console.error('Invalid ItemType');
        }
    })

    window.ReloadImageList = ()=>{
        DeleteModal('ChangeImageModalFileName');
        RefetchImageList();
        setImageVersion(imageVersion+1);
        ReloadImageTreeEvent();
    }

    return(
        <>
            <Header>
                <input 
                    type="file" 
                    id="file" 
                    accept=".png, .jpg, .gif, .webp" 
                    multiple 
                    ref={InputFile} 
                    onChange={(e)=>setFileData(e.target.files)}
                />
                <BtnGhost label="UpLoad" onClick={AddImage}/>
            </Header>

            {/* 이미지 리소스 리스트 영역 */}
            <ImageResourcePath>
                <span 
                    className="btn_home" 
                    onClick={(e)=>SelectedFolder(0)} 
                    onDrop={(e)=>MoveFileDropPath(0)} 
                    onDragOver={(e) => e.preventDefault()}>
                        <MdHome />
                    </span>
                {CurrentFolderPathArray?.slice(1).map((_path, index)=>(
                        <span 
                            key={index+1} 
                            onClick={(e)=>SelectedFolder(index+1)}
                            onDrop={(e)=>MoveFileDropPath(index+1)} 
                            onDragOver={(e) => e.preventDefault()}
                        >
                            {_path}
                        </span>
                ))}
            </ImageResourcePath>
            
            <ImageListContent>
                {ImageList?.[0]?.type === IMG_RESOURCE_TYPE.FOLDER &&
                    RenderFolderItem('...')
                }

                {ImageList?.[0] &&
                    RenderImgList(ImageList[0]['child'])
                }
            </ImageListContent>

            <ModalBottomBox justifyType={'space-between'}>
                <BtnGhost label={"+ Add Folder"}  onClick={AddFolder} />
                <div>
                    <BtnSecondary label="Cancel" onClick={(e)=>DeleteModal(_Modal.id)} />
                    <BtnPrimary label="Draw" onClick={DrawImage} disabled={selectImage === ''}/>
                </div>
            </ModalBottomBox>

            {/* 우클릭 메뉴 */}
            {( RightMenuInfo?.type === 'imageModal') &&
                <MouseRightClickMenuContent _positionTop={RightMenuInfo.top} _positionLeft={RightMenuInfo.left}>
                    <li onClick={(e) => {
                        const originalName = RightMenuInfo.data.targetName;
                        AddModal(
                            'ChangeImageModalFileName', 
                            `Rename (${originalName})`, 
                            'ChangeImageModalFileName', 
                            { originalName, path: CurrentFolderPathArray, MemberSYSID: userId, ProjectSYSID: projectId, }
                        );

                        CloseRightMenu();
                        e.stopPropagation();
                    }}>
                        <span><MdDriveFileRenameOutline style={{ 'color': 'darkgoldenrod' }} />Rename</span>
                    </li>
                </MouseRightClickMenuContent>
            }
        </>
    )
}

export default ImageContent;

//스타일----------------------------------------

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    gap: 8px;

    >input{
        border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
        flex-grow: 1;
        border-radius: 4px;
        padding: 6px;
        cursor: pointer;

        &:hover{
            border: 1px solid ${({theme}) => theme.base.border_color_Blue};
        }
    }
`;

const ImageResourcePath = styled.div`
    display: flex;
    align-items: center;
    height: 38px;
    font-size: .9rem;
    box-sizing: border-box;
    border: 1px solid ${({theme}) => theme.base.border_color_Gray};
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    user-select: none;

    &>span{
        padding: 0 8px;
        position: relative;
        cursor: pointer;

        &:hover{
            color: ${({theme}) => theme.base.font_color_DarkBlue};
        }

        &:not(:first-child)::before{
            content: "/";
            position: absolute;
            left: -4px;
        }
    }

    &>.btn_home{
        svg{
            font-size: 1.2rem;
            color: ${({theme}) => theme.base.font_color_DarkGray};

            &:hover{
                color: ${({theme}) => theme.base.font_color_Blue};
            }
        }
    }
`;

const ImageListContent = styled.div`
    position: relative;
    margin-top: 0!important;
    border-radius: 0 0 4px 4px;
    border: 1px solid ${({theme}) => theme.base.border_color_Gray};
    height: calc(100% - 164px);
    display: flex;
    flex-wrap: wrap;
    overflow-y: overlay;
    padding: 8px;
    box-sizing: border-box;
    align-items: flex-start;
    align-content: flex-start
`;

const ItemContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    width: 120px;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #fff;
    user-select: none;

    &.selectImage{
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
        border-radius: 4px;
    }

    &:hover{
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
        border-radius: 4px;

        svg{
            display: block;
        }
    }

    img{
        box-sizing: border-box;
        width: 100px;
        height: 100px;
        object-fit: scale-down;
        padding-bottom: 8px;
    }

    .icon_folder{
        width: 100px;
        height: 100px;
        padding: 16px;
        box-sizing: border-box;
    }

    span {
        font-size: .9rem;
        text-align: center;
        color: ${({theme}) => theme.base.font_color_Black};
        width: 100px;
        line-height: 1.1;
        word-break: break-all;
    }

    .btn_close{
        position: absolute;
        top: 4px;
        right: 4px;
        color: ${({theme}) => theme.base.font_color_Red};
        font-size: 1rem;
        border: 1px solid ${({theme}) => theme.base.font_color_Red};
        border-radius: 50%;
        padding: 4px;
        display: none;
        background-color: rgba(255,255,255,.6);

        &:hover{
            color: #fff;
            background-color: ${({theme}) => theme.base.font_color_Red};
        }
    }
`;