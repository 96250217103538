import { useState, useEffect, useCallback } from 'react';

import BoardAccessList from 'components/organism/BoardAccessList'
import AccessSetting from 'components/organism/AccessSetting'
// import ArchiveContent from 'components/organism/ArchiveContent'
import ArchiveBoardHeader from 'components/organism/ArchiveBoardHeader'
import Notification from 'components/atom/Notification'
import ArchiveContent from 'components/organism/ArchiveContent'
import { AUTHORITY_TYPE } from "components/common/EnuSpaceEnum";
import { GetArchiveMemberAccess, GetArchiveProjectInfo, GetRepoTree } from "api/ArchiveApi"
import { BaseInfoContext } from 'context/ArchiveContext'

import styled from "styled-components"
import { useLocation } from "react-router-dom";

export default function ArchiveSettingPage() {
    const [isAccessSetting, setIsAccessSetting] = useState(false);
    const [GrantedFriends, setGrantedFriends] = useState([]);
    const [BaseInfo, setBaseInfo] = useState({});
    const [MyAccess, setMyAccess] = useState();

    const systemId = localStorage.getItem('systemId');
    const userMail = localStorage.getItem('email');

    const [ArchivePathInfo, setArchivePathInfo] = useState({
        TreeData: [],
        CurrentData: [],
        CurrentIDX: null,
        Path: [],
    });

    const GetArchiveTree = useCallback(async (path = null) => {
        if (!BaseInfo.pageId || !BaseInfo.userId) return;

        const resultData = await GetRepoTree(BaseInfo.pageId, BaseInfo.userId, path);

        let { result, data } = resultData.data;

        if (result === "Success") {
            return { parentId: path, data: data || null };
        } else {
            return { parentId: path, data: null };
        }
    }, [BaseInfo.pageId, BaseInfo.userId]);

    const SetArchivePathInfo = useCallback(async (pathArray) => {
        const root = await GetArchiveTree();
        let TreeData = root.data;
        let CurrentData = root.data;
        let CurrentIDX = null;

        let index = 0;

        function findNodeByName(nodes, pathName) {
            for (const node of nodes) {
                if (node._NAME === pathName) {
                    return node;
                }
                if (node.children && node.children.length > 0) {
                    const foundInChildren = findNodeByName(node.children, pathName);
                    if (foundInChildren) {
                        return foundInChildren;
                    }
                }
            }
            return null;
        }

        while (pathArray.length > index) {
            const pathName = pathArray[index]
            const targetInfo = findNodeByName(TreeData, pathName);
            CurrentIDX = targetInfo.IDX;
            const targetData = await GetArchiveTree(CurrentIDX);
            if (targetData.data !== null)
                targetInfo.children = targetData.data;
            CurrentData = targetData.data;
            index++;
        }

        setArchivePathInfo(prev => ({
            ...prev,
            TreeData,
            CurrentData,
            CurrentIDX,
            IdxPath: [],
            Path: pathArray,
        }))

    }, [GetArchiveTree]);

    useEffect(() => {
        if (BaseInfo.path === undefined) return;

        const pathArray = BaseInfo.path?.split('/').filter(Boolean) || [];
        SetArchivePathInfo(pathArray);
    }, [BaseInfo.path, SetArchivePathInfo]);

    const location = useLocation();
    useEffect(() => {
        const Search = location.search;
        const searchParams = new URLSearchParams(Search);
        const pageId = searchParams.get('archive');
        const path = searchParams.get('path');

        GetArchiveProjectInfo(pageId)
            .then((res) => {
                const { result, data, msg } = res.data;
                if (result === 'Success') {
                    setBaseInfo({
                        userId: systemId,
                        email: userMail,
                        pageId: pageId,
                        pageName: data.ARCHIVE_NAME,
                        publishType: data.PUBLISH_MODE,
                        description: data.DESCRIPTION,
                        usedStorage: data.ARCHIVE_SIZE,
                        maxStorageSize: data.ARCHIVE_SIZE_LIMIT,
                        path,
                    })
                } else {
                    window.AddAlertMsg({ type: 'error', msg: `${msg}` });
                }
            })
    }, [location, systemId, userMail]);

    const LoadGrantedFriends = (pageId, userId) => {
        GetArchiveMemberAccess(pageId, userId)
            .then((res) => {
                const { result, data } = res.data;
                if (result === 'Success') {
                    // 접속자의 권한정보를 setMyAccessData 저장
                    const MyInfo = data.find((e) => e.MEMBER_SYSTEMID === userId);
                    setMyAccess(MyInfo.AUTHORITY);
                    setGrantedFriends(data);
                }
            })
            .catch((err) => {
                window.AddAlertMsg({ type: 'error', msg: `${err}` });
            })
    }

    useEffect(() => {
        if (!BaseInfo.pageId && !BaseInfo.userId) return;
        LoadGrantedFriends(BaseInfo.pageId, BaseInfo.userId);
    }, [BaseInfo.pageId, BaseInfo.userId]);

    const ActiveContent = () => {
        if (isAccessSetting) {
            return <AccessSetting
                BaseInfoState={[BaseInfo, setBaseInfo]}
                GrantedFriends={GrantedFriends}
                ReloadGrantedFriends={(e) => {
                    LoadGrantedFriends(BaseInfo.pageId, BaseInfo.userId)
                }}
                CloseEvent={(e) => setIsAccessSetting(false)}
            />
        } else {
            return (
                <BaseInfoContext.Provider value={{ BaseInfo }}>
                    <ArchiveContent ArchivePathInfo={ArchivePathInfo} SetArchivePathInfoEvent={SetArchivePathInfo} />
                </BaseInfoContext.Provider>
            )
        }
    }

    return (
        <>
            <Notification />
            <ArchiveBoardHeader BaseInfo={BaseInfo} MyAccess={MyAccess} IsAccessPageState={[isAccessSetting, setIsAccessSetting]} closeUrl='/Archive' />
            <Main>
                <ViewContent>
                    {ActiveContent()}
                </ViewContent>

                {MyAccess !== AUTHORITY_TYPE.NORMAL &&
                    <AccessContent>
                        <BoardAccessList
                            PublishType={BaseInfo.publishType}
                            GrantedFriends={GrantedFriends}
                        />
                    </AccessContent>
                }
            </Main>
        </>
    )
}

const Main = styled.main`
    position: relative;
    width: 100%;
    height: calc(100vh - 46px);
    display: flex;
`;

const ViewContent = styled.article`
    position: relative;
    flex: 1;
`;

const AccessContent = styled.aside`
    position: relative;
    width: 300px;
    border-left: 1px solid ${({ theme }) => theme.base.border_color_Gray};
`;