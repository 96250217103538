import { useState, useEffect,  useCallback } from "react";

import {BtnSecondary} from "components/atom/Button";
import { ModalBottomBox, RowTypeTableContent} from "components/css/common";
import { ARCHIVE_POST_TYPE, AUTHORITY_TYPE } from "components/common/EnuSpaceEnum";
import {GetBoardPostList, SearchBoardPost} from "api/ArchiveApi";
import Checkbox from "components/atom/Checkbox";
import SearchBar from "components/atom/SearchBar"
import {PaginationBar} from 'components/css/common';

import styled from 'styled-components';
import { MdOutlineFirstPage, MdOutlineLastPage, MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { useNavigate } from 'react-router-dom'

const POST_PER_PAGE = 15;
const PAGE_OFFSET = 1;
const SHOW_PAGING_NUMBER = 5;

// 서버에서 MAX_PAGE 0부터 시작 화면의 paging 넘버는 1부터 시작
export default function BoardContent({BaseInfo, MyAccess, IsLinkPage=false}){
    const navigate = useNavigate();
    const [InputData, setInputData] = useState('');
    const [ContentsInfo, setContentsInfo] = useState({});
    const [BoardList, setBoardList] = useState();

    const [BaseUrl, setBaseUrl] = useState('');
    const [CurrentPage, setCurrentPage] = useState();
    const [PageNumber, setPageNumber] = useState([0]);

    useEffect(() => {
        setCurrentPage((BaseInfo?.page - 1) || 0)
    }, [BaseInfo?.page]);

    useEffect(()=>{
        setBaseUrl(`/Board/${IsLinkPage? 'link': 'Settings'}?border=${BaseInfo.pageId}`)
    }, [IsLinkPage, BaseInfo.pageId]);

    const LoadBoardList = useCallback(async(CurrentPage) => {
        if(!BaseInfo.pageId) return;
        const paramData = {
            ARCHIVE_SYSTEMID: BaseInfo.pageId,
            MEMBER_SYSTEMID: BaseInfo.userId,
            PAGE_NUMBER: CurrentPage,
            PAGE_SIZE: POST_PER_PAGE
        };

        let response;
        if(BaseInfo.keyword){
            // keyword가 있으면 키워드검색으로 판단
            response = await SearchBoardPost({...paramData, SEARCH_WORD: BaseInfo.keyword})
        }else {
            response= await GetBoardPostList({...paramData})
        }

        const {result, msg, data} = response.data;
        if (result === 'Success') {
            if (data){
                const { MAX_NUM, MAX_PAGE, POST_LIST } = data;
                setContentsInfo({ MAX_NUM, MAX_PAGE });
                setBoardList(POST_LIST);
            }else{
                setContentsInfo({});
                setBoardList();
            }
        } else {
            window.AddAlertMsg({ type: 'error', msg })
        }
    }, [BaseInfo.pageId, BaseInfo.userId, BaseInfo.keyword]);

    useEffect(()=>{
        if(CurrentPage === undefined) return;
        LoadBoardList(CurrentPage);
    }, [LoadBoardList, CurrentPage]);

    useEffect(()=>{
        const pagesArray = Array.from({ length: ContentsInfo.MAX_PAGE }, (_, index) => index);
        setPageNumber(pagesArray);
    }, [ContentsInfo]);

    useEffect(()=>{
        if(!BaseInfo.keyword){
            setInputData('');
        }else{
            setInputData(BaseInfo.keyword);
        }
    }, [BaseInfo.keyword]);

    const Pagination = () => {
        const maxPageNumber = PageNumber[PageNumber.length - 1];
        const flagNumber = Math.floor(SHOW_PAGING_NUMBER / 2);
        let startNumber = Math.max(CurrentPage - flagNumber, 0);
        let lastNumber = Math.min(CurrentPage + flagNumber, maxPageNumber);

        if(startNumber === 0){
            lastNumber = SHOW_PAGING_NUMBER - PAGE_OFFSET;
        }else if(lastNumber === maxPageNumber){
            startNumber = maxPageNumber - (SHOW_PAGING_NUMBER - PAGE_OFFSET);
        }

        return (
          <ul className="pagination">
            {PageNumber.map((pageNum) => {
                if(pageNum >= startNumber && pageNum <= lastNumber){
                    return (
                        <li
                            key={pageNum}
                            className={pageNum === CurrentPage ? 'selectPage' : ''}
                            onClick={() =>{
                                navigate( `${BaseUrl}&page=${pageNum+1}`);
                            }}
                        >
                            {pageNum + PAGE_OFFSET}
                        </li>
                    )
                }
                return null;
            })}
          </ul>
        );
    };

    const MovePage = (_type) =>{
        switch (_type) {
            case 'FirstPage':
                    navigate( `${BaseUrl}&page=1`);
                break;
            case 'PrevPage':
                if(CurrentPage >= 1){
                    navigate( `${BaseUrl}&page=${CurrentPage}`);
                }
                break;
            case 'NextPage':
                if(CurrentPage < ContentsInfo.MAX_PAGE-1){
                    navigate( `${BaseUrl}&page=${CurrentPage+2}`);
                }
                break;
            case 'LastPage':
                    navigate( `${BaseUrl}&page=${ContentsInfo.MAX_PAGE}`);
                break;
            default:
                break;
        }
    }

    return(
        <BaseContent>
            <ContentHeader>
                <div id="BorderListTitle">
                    {BaseInfo.pageName} <span>({ContentsInfo.MAX_NUM || '0'})</span>
                </div>
                <div id="SearchContent">
                    <SearchBar 
                        initKeyword={InputData}
                        placeholder="search..."
                        isReset={false}
                        searchEvent={(keyword)=>{
                            let url = `${BaseUrl}&page=1`;
                            if (keyword !== '')
                                url += `&keyword=${keyword}`;
                            navigate(url, { state: BaseInfo });
                        }}
                    />
                </div>
            </ContentHeader>

            <BoardTable>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className="no">번호</th>
                                <th className="title">제목</th>
                                <th className="author">작성자</th>
                                {/* <th className="views">조회수</th> */}
                                <th className="date">작성일</th>
                                {(MyAccess === AUTHORITY_TYPE.MANAGER || MyAccess === AUTHORITY_TYPE.MASTER) &&

                                    <th className="public">공개여부</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {BoardList && 
                                BoardList.map((board)=>{
                                    const {ORDER_IDX, TITLE, CREATED_STAMP, POST_TYPE, NICKNAME, IS_OPEN, POST_IDX} = board;
                                    const createdStamp = CREATED_STAMP.replace(/-/g, '.').replace(/T/g, '  ');
                                    const postType = Object.entries(ARCHIVE_POST_TYPE).find((item) => item[1] === POST_TYPE && item[1] !== ARCHIVE_POST_TYPE.NORMAL);
                                    const className = postType ? postType[0] : '';
                                    
                                    return(
                                        <tr 
                                            key={ORDER_IDX} 
                                            className={className}
                                            onClick={(e)=>{
                                                navigate(`${BaseUrl}&page=${CurrentPage}&num=${POST_IDX}`, {state: BaseInfo});
                                            }}
                                        >
                                            <td className="no">{ORDER_IDX}</td>
                                            <td className="title">
                                                {POST_TYPE === ARCHIVE_POST_TYPE.ANNOUNCEMENT && <span>공지사항</span>}
                                                {TITLE}
                                            </td>
                                            <td className="author">{NICKNAME}</td>
                                            {/* <td className="views">10000</td> */}
                                            <td className="date">{createdStamp}</td>

                                            {(MyAccess === AUTHORITY_TYPE.MANAGER || MyAccess === AUTHORITY_TYPE.MASTER) &&
                                                <td className="public">
                                                    <Checkbox
                                                        _id={ORDER_IDX}
                                                        checked={IS_OPEN}
                                                        onChange={(e)=>{}}
                                                    />
                                                </td>
                                             }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </BoardTable>

            <BottomBox marginTop='16px'>
                <PaginationBar>
                    <span onClick={(e) => MovePage('FirstPage')}><MdOutlineFirstPage /></span>
                    <span onClick={(e) => MovePage('PrevPage')}><MdOutlineChevronLeft /></span>
                    <Pagination />
                    <span onClick={(e) => MovePage('NextPage')}><MdOutlineChevronRight /></span>
                    <span onClick={(e) => MovePage('LastPage')}><MdOutlineLastPage /></span>
                </PaginationBar>

                {MyAccess !== AUTHORITY_TYPE.NONE &&
                    <BtnSecondary 
                        label="Create" 
                        onClick={(e) => {
                            navigate( `${BaseUrl}&page=${CurrentPage}&num=Create`, {state: BaseInfo} );
                        }}
                    />
                }
            </BottomBox>
        </BaseContent>
    )
}

const BaseContent = styled.section`
    position: relative;
    width: 100%;
    height: 898px;
    padding: 32px 24px 24px 24px;
    box-sizing: border-box;
`;

const ContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 16px;

    #BorderListTitle{
        font-size: 1.4rem;
        font-weight: bold;
        color: ${({theme}) => theme.base.font_color_Black};

        span{
            font-size: 1.2rem;
            font-weight: normal;
            color: ${({theme}) => theme.base.font_color_DarkGray};
        }
    }

    #SearchContent{
        display: flex;
        gap: 8px;

        select {
            width: 100px;
        }

        input{
            width: 260px;
        }
    }
`;

const BottomBox = styled(ModalBottomBox)`
    position: relative;
    width: 100%;
    justify-content: center;

    &>div{
        margin-top: 0;
    }

    &>button{
        position: absolute;
        top: 0;
        right: 0; 
    }
`;

const BoardTable = styled(RowTypeTableContent)`
    height: calc(100% - 110px);
    
    &>div{
        overflow: hidden;
    }

    .ANNOUNCEMENT, .STICKY{
        background-color: ${({theme}) => theme.base.background_color_LightGray};
    }

    .no{
        width: 56px;
        text-align: center;
        user-select: none;
    }

    .title{

        span{
            padding: 2px 4px;
            font-size: 0.8rem;
            border-radius: 4px;
            margin-right: 6px;
            color: #fff;
            background-color: ${({theme}) => theme.base.font_color_Orange};
        }
    }

    .author{
        width: 120px;
        text-align: center;
        user-select: none;
    }

    .views{
        width: 56px;
        user-select: none;
        text-align: center;
    }

    .date{
        font-size: 0.85rem;
        width: 90px;
        text-align: center;
        user-select: none;
    }

    .public{
        width: 70px;
        text-align: center;
        user-select: none;
        
        &>div:hover{
            border: 0 none;
        }

        &>div label{
            justify-content: center;
        }
    }
`;