import { useContext } from "react";

import PropertiesContentAdd from "components/atom/PropertiesContentBtn";
import { SelectMeshContext, SceneContext } from "context/SceneContext";
import {BtnGhost} from "components/atom/Button";
import useModalPopup from 'hooks/useModalPopup';
import useDataController from 'babylon/hooks/useDataController';

import styled from 'styled-components';
import {MdClear} from "react-icons/md";

export default function EventContent({selectBabylonData}){
    const {EnuScene} = useContext(SceneContext);
    const {SelectedMesh} = useContext(SelectMeshContext);
    const {name: SelectedName, event:SelectedEventMap} = selectBabylonData;
    const {AddModal} = useModalPopup();
    const {UploadDataByMesh} = useDataController();

    return(
        <div style={{userSelect: 'none'}}>
            <PropertiesContentAdd 
                _Title={SelectedName ? `(${SelectedName})Event` : "Event" } 
                _btn={SelectedName && 
                    <BtnGhost 
                        label="Add" 
                        type='sm' 
                        onClick={(e)=>{
                            AddModal(SelectedMesh.uniqueId, 'Script Editor', 'Event', {uniqueId: SelectedMesh.uniqueId, mode: 'Add', selectedData:selectBabylonData});
                        }} 
                />} 
            />

            {SelectedEventMap && 
                Object.keys(SelectedEventMap).map((eventName) => {
                    return (
                        <EventRow
                            key={eventName}
                            title={eventName}
                            onClick={(e) => {
                                AddModal(`${SelectedMesh.uniqueId}.${eventName}`,
                                    'Script Editor', 'Event',
                                    { uniqueId: SelectedMesh.uniqueId, mode: 'Edit', eventName, selectedData: selectBabylonData });
                            }}
                        >
                            <span>{eventName}</span>
                            <MdClear onClick={(e) => {
                                e.stopPropagation();
                                if (!window.confirm(`${eventName} 이벤트를 삭제하시 겠습니까?`)) return;
                                EnuScene.removedEventFromScene(SelectedMesh, eventName);
                                UploadDataByMesh(SelectedMesh);
                            }} />
                        </EventRow>
                        )
                    })
            }
        </div>
    )
}

const EventRow = styled.li`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 4px 8px 4px 16px;
    box-sizing: border-box;
    cursor: pointer;

    &>span{
        width: calc(100% - 28px);
        line-height: 32px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    &:hover{
        background-color: ${({theme}) => theme.base.background_color_LightBlue};
        &>svg{
            display:block;
        }
    }

    &::after{
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        bottom:0;
        width: calc(100% - 16px);
        background-color: ${({theme}) => theme.base.border_color_LightGray};
    }

    &:before{
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 6px;
        height: 16px;
        width: 4px;
        border-radius: 2px;
        background-color: #64dd17;
        transform: translate(-50%, -50%);
    }

    svg{
        font-size: 1.3rem;
        padding: 2px;
        color: ${({theme}) => theme.base.font_color_Red};
        border: 1px solid transparent;
        border-radius: 4px;
        display:none;
        cursor: pointer;

        &:hover{
            border-color: ${({theme}) => theme.base.font_color_Red};
            background-color: ${({theme}) => theme.base.background_color_LightRed};
        }
    }
`;