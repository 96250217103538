import {useState, useEffect, useCallback} from 'react'

export default function useWebSocketControl(socketUrl) {
    const [ws, setWs] = useState(null);
    
    useEffect(()=>{
        if(!socketUrl) return;

        const ws = new WebSocket(socketUrl);
        ws.onopen = () => {
            setWs(ws);
        };

        return () =>{
            if (ws && ws.readyState === WebSocket.OPEN){
                // ws.send();
            }

            ws.close();
        }
    }, [socketUrl]);

    const send = useCallback((message)=>{
        if (ws && ws.readyState === WebSocket.OPEN){
            ws.send(JSON.stringify(message));
        }else if (ws && ws.readyState === WebSocket.CLOSED){
            const reWs = new WebSocket(ws.url);
            reWs.onopen = () => {
                setWs(reWs);
            };
        }
    }, [ws]);

    const onMessage = useCallback((callback)=>{
        if(ws){
            ws.onmessage = callback;
        }
    }, [ws]);

    return {ws, send, onMessage};
}

export function useWebSocketFunction(socketUrl) {
    const [URL, setURL] = useState();
    const [ws, setWs] = useState(null);
    
    useEffect(()=>{
        setURL(socketUrl);
    }, [socketUrl]);

    useEffect(()=>{
        return () =>{
            if (ws && ws.readyState === WebSocket.OPEN){
                // ws.send();
            }

            ws && ws.close();
        }
    }, [ws]);

    const ConnectSock = (sendMsg) =>{
        const newWs = new WebSocket(URL);
        newWs.onopen = () => {
            setWs(newWs);

            sendMsg && newWs.send(JSON.stringify(sendMsg));
        };
    }

    const CloseSock = () =>{
        if(ws){
            ws.close();
            setWs(null)
        }
    }

    const send = useCallback((message)=>{
        if (ws && ws.readyState === WebSocket.OPEN){
            ws.send(JSON.stringify(message));
        }else if (ws && ws.readyState === WebSocket.CLOSED){
            const reWs = new WebSocket(URL);
            reWs.onopen = () => {
                setWs(reWs);
            };
        }
    }, [ws, URL]);

    const onMessage = useCallback((callback)=>{
        if(ws){
            ws.onmessage = callback;
        }
    }, [ws]);

    return {ws, send, onMessage, ConnectSock, CloseSock};
}