import {AccessAxios} from 'components/Axios';
const BaseURL = '/api/Repository';

/**
 * 프로젝트 리스트 가져오기
 * @param {string} MEMBER_SYSTEMID  사용자 아이디
 */
export const GetMyProjectList = async (MEMBER_SYSTEMID) => {
    const MSG = { MEMBER_SYSTEMID }
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetMyProjectList`, MSG);
        return res;
    } catch (err) {
        throw new Error(`프로젝트 리스트 로딩에 실패했습니다. : ${err}`);
    }
}

/**
 * 유자가 등록한 publish 리스트 가져오기
 * @param {string} MEMBER_SYSTEMID  사용자 아이디
 */
export const GetMyPublishList = async (MEMBER_SYSTEMID) => {
    const MSG = { MEMBER_SYSTEMID }
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetMyPublishList`, MSG);
        return res;
    } catch (err) {
        throw new Error(`프로젝트 리스트 로딩에 실패했습니다. : ${err}`);
    }
}

/**
 * 프로젝트 생성
 * @param {string} MEMBER_SYSTEMID          - 사용자 아이디
 * @param {string} PROJECT_NAME             - 프로젝트 이름
 * @param {string} PROJECT_TITLE             - 프로젝트 타이틀
 * @param {string} DESCRIPTION              - 프로젝트 설명
 * @param {string} RENDERING                - 렌더링 방식 (2D, 3D) 기본값 2D
 */
export const CreateProject = async (MEMBER_SYSTEMID, PROJECT_NAME, PROJECT_TITLE, DESCRIPTION, RENDERING="2D") => {
    const MSG = { MEMBER_SYSTEMID, PROJECT_NAME, PROJECT_TITLE, DESCRIPTION, RENDERING};
    
    try {
        const res = await AccessAxios.post(`${BaseURL}/CreateProject`, MSG);
        return res;
    } catch (err) {
        throw new Error(`프로젝트 생성에 실패했습니다. : ${err}`);
    }
}

/**
 * 프로젝트를 삭제한다.
 * @param {string} MEMBER_SYSTEMID - 사용자 아이디 
 * @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
 */
export const DeleteProject = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID) => {
    const MSG = {MEMBER_SYSTEMID, PROJECT_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/DeleteProject`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * Publish를 삭제한다.
 * @param {string} PUBLISH_SYSTEMID  - publish 아이디
 */
export const DeletePublish = async (PUBLISH_SYSTEMID) => {
    const MSG = {PUBLISH_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/DeletePublish`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 프로젝트 정보를 변경한다.
 * @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
 * @param {string} PROJECT_TITLE     - 프로젝트 타이들
 * @param {string} description      - 프로젝트 설명
 */
export const UpdateProjectConfig = async (PROJECT_SYSTEMID, PROJECT_TITLE, description) => {
    const MSG = {PROJECT_SYSTEMID, PROJECT_TITLE, description}
    try {
        const res = await AccessAxios.post(`${BaseURL}/UpdateProjectConfig`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 프로젝트 이름을 변경한다.
 * @param {string} MEMBER_SYSTEMID      - 사용자 아이디
 * @param {string} PROJECT_SYSTEMID     - 프로젝트 아이디
 * @param {string} PROJECT_NAME         - 변경할 프로젝트 이름
 */
export const UpdateProjectName = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, PROJECT_NAME) => {
    const MSG = {MEMBER_SYSTEMID, PROJECT_SYSTEMID, PROJECT_NAME}
    try {
        const res = await AccessAxios.post(`${BaseURL}/UpdateProjectName`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 프로젝트 상세정보를 가져온다.
 * @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
 */
export const GetProjectConfig = async (PROJECT_SYSTEMID) => {
    const MSG = {PROJECT_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetProjectConfig`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 프로젝트를 공개한다.
 * @param {string} MEMBER_SYSTEMID  - 사용자 아이디
 * @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
 * @param {number} PUBLISH_MODE     - 공개 모드 (public:0, private:1)
 * @param {number} USE_METAVERSE      - 메타버스 사용 여부 (0, 1)
 * @param {number} USE_AVATAR        - 아바타 사용 여부 (0, 1)
 * @param {string} ACCESSLIST         - 접근 권한이 있는 사용자 리스트
 */
export const PublishProject = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, PUBLISH_MODE, USE_METAVERSE, USE_AVATAR, ACCESSLIST) => {
    const MSG = {MEMBER_SYSTEMID, PROJECT_SYSTEMID, PUBLISH_MODE, USE_METAVERSE, USE_AVATAR, ACCESSLIST}
    try {
        const res = await AccessAxios.post(`${BaseURL}/PublishProject`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 프로젝트에 권한이 있는 사용자를 가져온다.
 * @param {string} MEMBER_SYSTEMID - 사용자 아이디
 * @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
 */
export const GetPublishMemberList = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID) => {
    const MSG = {PROJECT_SYSTEMID, MEMBER_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetPublishMemberList`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 배포된 프로젝트에 접근 권한이 있는 유저 리스트 추가
 * @param {string} PROJECT_SYSTEMID     - 프로젝트 아이디 
 * @param {Array} accessList            - 사용자 리스트
 */
export const InsertPublishMemberList = async (PROJECT_SYSTEMID, accessList) => {
    const MSG = {PROJECT_SYSTEMID, accessList}
    try {
        const res = await AccessAxios.post(`${BaseURL}/InsertPublishMemberList`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 배포된 프로젝트에 접근 권한이 있는 유저 리스트 삭제
 * @param {string} PROJECT_SYSTEMID     - 프로젝트 아이디 
 * @param {Array} accessList            - 사용자 리스트
 */
export const DeletePublishMemberList = async (PROJECT_SYSTEMID, accessList) => {
    const MSG = {PROJECT_SYSTEMID, accessList}
    try {
        const res = await AccessAxios.post(`${BaseURL}/DeletePublishMemberList`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 해당 프로젝트에 대한 Edit 권한이 있는 유저 리스트 반환
 * @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
 */
export const GetProjectEditMemberList = async (PROJECT_SYSTEMID) => {
    const MSG = {PROJECT_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetProjectEditMemberList`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 해당 프로젝트에 대한 Edit 권한이 있는 유저 리스트 추가
 * @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
 * @param {Array} accessList       - 사용자 리스트  
 */
export const InsertProjectEditMemberList = async (PROJECT_SYSTEMID, accessList) => {
    const MSG = {PROJECT_SYSTEMID, accessList}
    try {
        const res = await AccessAxios.post(`${BaseURL}/InsertProjectEditMemberList`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 해당 프로젝트에 대한 Edit 권한이 있는 유저 리스트 삭제
 * @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
 * @param {Array} accessList       - 사용자 리스트  
 */
export const DeleteProjectEditMemberList = async (PROJECT_SYSTEMID, accessList) => {
    const MSG = {PROJECT_SYSTEMID, accessList}
    try {
        const res = await AccessAxios.post(`${BaseURL}/DeleteProjectEditMemberList`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 다른 유저가 만들었지만, 해당 유저에도 프로젝트 Edit 권한이 있는 프로젝트 리스트 반환
 * @param {string} MEMBER_SYSTEMID - 사용자 아이디
 */
export const GetEditProjectList = async (MEMBER_SYSTEMID) => {
    const MSG = {MEMBER_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetEditProjectList`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 배포용 프로젝트 중, Public으로 배포된 프로젝트 리스트 반환
 */
export const GetPublicProjectList = async () => {
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetPublicProjectList`);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * 배포용 프로젝트 중, Private로 배포되었으며, 접근 권한이 있는 프로젝트 리스트 반환
 * @param {string} MEMBER_SYSTEMID - 사용자 아이디
 */
export const GetPrivateProjectList = async (MEMBER_SYSTEMID) => {
    const MSG = {MEMBER_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetPrivateProjectList`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * publish 프로젝트 정보를 가져온다.
 * @param {string} PUBLISH_SYSTEMID - publish 아이디
 */
export const GetPublishProjectInfo = async (PUBLISH_SYSTEMID) => {
    const MSG = {PUBLISH_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetPublishProjectInfo`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * ProjectPublishInfo 프로젝트 정보를 가져온다.
 * @param {string} MEMBER_SYSTEMID  - 유저 아이디
 * @param {string} PROJECT_SYSTEMID  - project 아이디
 */
export const GetProjectPublishInfo = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID) => {
    const MSG = {MEMBER_SYSTEMID, PROJECT_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetProjectPublishInfo`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * publish 프로젝트 모드 정보를 가져온다.
 * @param {string} PUBLISH_SYSTEMID     - publish 아이디
 */
export const GetPublishModeInfo = async (PUBLISH_SYSTEMID) => {
    const MSG = {PUBLISH_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetPublishModeInfo`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * publish 프로젝트 정보를 가져온다.
 * @param {string} PUBLISH_SYSTEMID     - publish 아이디
 */
export const GetPublicPublishConfig = async (PUBLISH_SYSTEMID) => {
    const MSG = {PUBLISH_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetPublicPublishConfig`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}

/**
 * private 프로젝트 정보를 가져온다.
 * @param {string} MEMBER_SYSTEMID     - 사용자 아이디
 * @param {string} PUBLISH_SYSTEMID     - publish 아이디
 */
export const GetPrivatePublishConfig = async (MEMBER_SYSTEMID, PUBLISH_SYSTEMID) => {
    const MSG = {MEMBER_SYSTEMID, PUBLISH_SYSTEMID}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetPrivatePublishConfig`, MSG);
        return res;
    }catch(err){
        throw err;
    }
}