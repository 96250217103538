import { createSlice } from "@reduxjs/toolkit";

const selected3dObjectDataSlice = createSlice({
    name: 'selectBabylonData',
    initialState: {},
    reducers:{
        setBabylonData(state, action){
            return { ...state, ...action.payload };
        },
        changeBabylonData(state, action){
            const strValue = action.payload;
            const [key, value] = Object.entries(strValue)[0];

            if(key.includes('position')){
                const [positionKey, positionValue] = Object.entries(value)[0];
                state['position'][positionKey] = positionValue;
            }else{
                state[key] = value;
            }
        },
        deleteBabylonData(state){
            for(let data in state)
                delete state[data];
        }
    }
})

export const { setBabylonData, deleteBabylonData, changeBabylonData } = selected3dObjectDataSlice.actions;
export default selected3dObjectDataSlice.reducer;