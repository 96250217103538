import {useId ,useState, useEffect} from "react";
import { floatToStr } from 'components/Utility'
import styled from "styled-components";

export default function InputComponent({label, labelWidth ,attributeName, value, valueType = 'text' , changeEvent, disabled, children, isAllowEmptyInputReset = true ,...props}){
    const ComponentId = useId();
    const [Input, setInput] = useState('');
    const [Type, setType] = useState(valueType);

    useEffect(()=>{
        if(value === undefined){
            setInput('');
        }else if(Array.isArray(value)){
            setType('text')
            setInput('Mixed');
        }else{
            setType(valueType)
            setInput(valueType === 'number' ? floatToStr(value) : value);
        }
    }, [value, valueType]);

    const ChangeValue = (e) =>{        
        if(isAllowEmptyInputReset && e.target.value === ''){
            if(Array.isArray(value)){
                setType('text')
                setInput('Mixed');
            }else{
                setType(valueType)
                setInput(valueType === 'number' ? floatToStr(value) : value);
            }
            return;
        }

        if(valueType === 'number' && Number.isNaN(Number(Input))){
            setType('text')
            setInput('Mixed');
            return;
        }

        const JsonData = [ { attributeName: attributeName || label, attributeValue : e.target.value } ];
        changeEvent && changeEvent(JsonData);
    }

    const KeyDownEvent = (e) =>{
        const {key, ctrlKey, target} = e;
        if(key === 'Enter'){
            target.blur();
        }else if (ctrlKey && key === 'a'){
            e.preventDefault();
            target.select();
        }else if (key === 'ArrowUp' || key === 'ArrowDown'){
            if(Type !== 'number') return;
            ChangeValue(e);
        }
    }

    return(
        <DivRow {...props}>
            {label && <Label labelWidth={labelWidth} htmlFor={ComponentId} >{label}</Label> }
            <InputStyle
                id={ComponentId}
                name={attributeName || label}
                title={attributeName || label}
                value={Input}
                type={Type}
                disabled={disabled}
                labelWidth={labelWidth}
                onKeyDown={KeyDownEvent}
                onBlur={ChangeValue}
                onChange={(e)=>setInput(e.target.value)}
                onClick={(e)=>{
                    if(e.target.value === 'Mixed')
                        e.target.select();
                }}
            />
            {children && children}
        </DivRow>
    )
}

const DivRow = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 28px;
    gap: 6px;
`;

const Label = styled.label`
    font-size: .9rem;
    padding: 6px 0 6px 0;
    min-width: 24px;
    width: ${({labelWidth}) => labelWidth || 'auto'};
    height: 100%;
    color: ${({theme}) => theme.base.font_color_DarkGray};
    box-sizing: border-box;
    display: inline-block;
    user-select: none;
    text-align: right;
`;

const InputStyle = styled.input`
    font-size: .9rem;
    flex-grow: 1;
    width: ${({labelWidth}) => labelWidth ? `calc(100% - ${labelWidth} - 6px )` : 'auto'};
    height: 100%;
    box-sizing: border-box;
    border: 0 none;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};

    &:hover{
        border-color: ${({theme}) => theme.base.border_color_Blue};
    }

    &:focus{
        outline:none;
        border-color: ${({theme}) => theme.base.border_color_Blue};
    }

    &:disabled{
        border-color: transparent;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;