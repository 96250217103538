import MouseRightClickMenuContent from "components/molecule/MouseRightClickMenuContent"
import {
    EnuIconAlignBottom,
    EnuIconAlignTop,
    EnuIconAlignRight,
    EnuIconAlignMiddle,
    EnuIconAlignLeft,
    EnuIconAlignCenter,
    EnuIconDistributeVertically,
    EnuIconDistributeHorizontally,
    EnuIconSendToBack,
    EnuIconSendBackward,
    EnuIconBringToFront,
    EnuIconBringForward,
} from "components/common/EnuSpaceIcon"

import { useSelector } from 'react-redux';
import { MdArrowRight } from "react-icons/md";

export default function CanvasMenu({ enuSpace, rightMenuInfo}) {
    const CANVAS_NAME = "Canvas";
    const ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ACTIVE_PICTURE_URL);

    return (
        <MouseRightClickMenuContent _positionTop={rightMenuInfo['top']} _positionLeft={rightMenuInfo['left']} isChild={true}>
            <li>
                <span>Group<MdArrowRight className="icon_arrow" /></span>
                <ul>
                    <li onClick={(e) => enuSpace.SetGroup(CANVAS_NAME)} ><span>Group</span></li>
                    <li onClick={(e) => enuSpace.SetUnGroup(CANVAS_NAME)} ><span>UnGroup</span></li>
                </ul>
            </li>
            <span className="menuLine" />
            <li>
                <span>Bring to Front<MdArrowRight className="icon_arrow" /></span>
                <ul>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ORDER", "bringToFront", "")} ><EnuIconBringToFront /><span>bringToFront</span></li>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ORDER", "bringForward", "")} ><EnuIconBringForward /><span>bringForward</span></li>
                </ul>
            </li>
            <li>
                <span>Send to Back<MdArrowRight className="icon_arrow" /></span>
                <ul>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ORDER", "sendToBack", "")} ><EnuIconSendToBack /><span>sendToBack</span></li>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ORDER", "sendBackward", "")} ><EnuIconSendBackward /><span>sendBackward</span></li>
                </ul>
            </li>
            <li>
                <span>Align<MdArrowRight className="icon_arrow" /></span>
                <ul>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ALIGN", "align_left", "")}><EnuIconAlignLeft /><span>left</span></li>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ALIGN", "align_center", "")}><EnuIconAlignCenter /><span>center</span></li>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ALIGN", "align_right", "")}><EnuIconAlignRight /><span>right</span></li>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ALIGN", "align_horizontally", "")}><EnuIconDistributeHorizontally /><span>horizontally</span></li>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ALIGN", "align_top", "")}><EnuIconAlignTop /><span>top</span></li>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ALIGN", "align_middle", "")}><EnuIconAlignMiddle /><span>middle</span></li>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ALIGN", "align_bottom", "")}><EnuIconAlignBottom /><span>bottom</span></li>
                    <li onClick={(e) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, "ALIGN", "align_vertically", "")}><EnuIconDistributeVertically /><span>vertically</span></li>
                </ul>
            </li>
        </MouseRightClickMenuContent>
    )
}