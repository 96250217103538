

import { useState, useEffect } from "react";

import ModalSpace from "babylon/components/modal_new/ModalSpace";
import SessionExpired from 'components/organism/SessionExpired';
import Project3DHeader from "babylon/components/organism/Project3DHeader";
import LeftMenuContent from "babylon/components/organism/LeftMenuContent";
import CanvasContent from "babylon/components/organism/CanvasContent";
import RightMenuContent from "babylon/components/organism/RightMenuContent";
import { SceneContext, SelectMeshContext } from "context/SceneContext";
import useRightMenu from "hooks/useRightMenu";
import useProject3DLoader from "babylon/hooks/useProject3DLoader";

import styled from "styled-components";
import {AccessAxios} from 'components/Axios';

export default function Project3DPageContent({ BASE_DATA }) {
    const { CloseRightMenu } = useRightMenu();
    const [isLog, setIsLog] = useState(false);
    const [EnuScene, setEnuScene] = useState();
    const [SelectedMesh, setSelectedMesh] = useState(null);
    const [IsRunTimeMode, setIsRunTimeMode] = useState(false);
    const [IsInspector, setIsInspector] = useState(false);
    const [URL] = useState(`/Repository/${BASE_DATA.userId}/${BASE_DATA.projectId}/picture/scene1.json`);
    useProject3DLoader({ URL, EnuScene });
    
    window.GetHDF5Data = async function(link, filename, datapath){
        const url = link ? link : 'https://192.168.0.91:1004/HDF5/GetHDF5DataByJson';
        const data = {
            FILE_NAME: filename,
            HIERACHY: datapath,
            SHOWCHILD: true
        };
        const external_url = 'https://localhost:7135/api/JSExternal/JSHttpRequest'
        const external_data = {
            URL: url,
            METHOD: 'POST',
            BODY: JSON.stringify(data)
        }
                
        let response = AccessAxios.post(external_url, external_data)

        return response
        
    };

    useEffect(()=>{
        if(!EnuScene) return;

        EnuScene?.setRunTimeMode(IsRunTimeMode);
        EnuScene.scene.getEngine().resize();
    }, [IsRunTimeMode, EnuScene, IsInspector]);
    
    return(
        <SceneContext.Provider value={{ EnuScene, setEnuScene }}>
            <SelectMeshContext.Provider value={{ SelectedMesh, setSelectedMesh }}>
                <div onClick={CloseRightMenu} style={{ userSelect: 'none', minWidth: '900px' }}>
                    <SessionExpired />
                    <ModalSpace />
                    <Project3DHeader IsRunTimeModeState={[IsRunTimeMode, setIsRunTimeMode]} setIsInspector={setIsInspector}/>
                    <MainContent>
                        {(!IsRunTimeMode && !IsInspector) && <LeftMenuContent IsLogWindow={[isLog, setIsLog]} />}
                        <CanvasContent IsLogWindow={isLog}/>
                        {(!IsRunTimeMode && !IsInspector) && <RightMenuContent />}
                    </MainContent>
                </div>
            </SelectMeshContext.Provider>
        </SceneContext.Provider>
    );
}

// 스타일------------------------------------
const MainContent = styled.main`
    position: relative;
    display: flex;
    flex-direction: row;
    height: calc(100vh - 76px);
`;