import { useState, useEffect, useCallback } from "react";

import {BtnGhost, BtnSecondary} from "components/atom/Button";
import {ContentPage, ContentSectionHeader, ContentSection, NewContentBox} from "components/css/common";
import NewArchiveFormModal from "components/modal_new/NewArchiveFormModal";
import { RepositoryCard} from "components/molecule/ProjectCard";
import {ARCHIVE_TYPE, PUBLISH_TYPE, AUTHORITY_TYPE} from "components/common/EnuSpaceEnum";
import {GetArchiveProjectListByType, GetArchiveProjectAccessListByType, DeleteArchiveProject} from "api/ArchiveApi";

import { useNavigate } from 'react-router-dom'
import styled from "styled-components";
import { MdInsertLink} from "react-icons/md";

export default function BoardRepository(){
    const navigate = useNavigate();
    const [BoardList, setBoardList] = useState([]);
    const [NewBoardForm, setNewBoardForm] = useState();

    const systemId = localStorage.getItem('systemId');
    const nickname = localStorage.getItem('nickname');
    const userMail = localStorage.getItem('email');

    const LoadBoardList = useCallback(() => {
        const myList =  GetArchiveProjectListByType(systemId, ARCHIVE_TYPE.BOARD)
                            .then(res => {
                                const { result, data } = res.data;
                                if (result === "Success") {
                                    return {myList : data};
                                } else {
                                    return { myList: [] };
                                }
                            })

        const publishList = GetArchiveProjectAccessListByType(systemId, ARCHIVE_TYPE.BOARD)
                            .then(res => {
                                const { result, data } = res.data;
                                if (result === "Success") {
                                    return {publishList : data};
                                } else {
                                    return {publishList : []};
                                }
                            })


        Promise.all([myList, publishList])
            .then(res => {
                const Result = res.reduce((acc, cur) => ({ ...acc, ...cur }), {});
                const { myList, publishList } = Result;
                const notSelf = publishList?.filter(item => item.IS_SELF === false );
                setBoardList([...myList || [], ...notSelf || []])
            }).catch(err => {
                window.AddAlertMsg({ type: 'error', msg: `${err}` });
            })
                
    }, [systemId])

    useEffect(()=>{
        LoadBoardList();
    }, [LoadBoardList]);

    return(
        <>
            {NewBoardForm && <NewArchiveFormModal ArchiveType="Board" DataState={[NewBoardForm, setNewBoardForm]} onReload={LoadBoardList} />}
            <ContentPage>
                {/* 레이아웃 통일을 위해 존재 */}
                <ContentSectionHeader />

                <ContentSection>
                    <NewContentBox>
                        <BtnGhost
                            label="New Board"
                            onClick={(e) => {
                                setNewBoardForm({ userId: systemId, userNickname: nickname, email: userMail, mode: 'new', publishType: PUBLISH_TYPE.PRIVATE })
                            }}
                        />
                    </NewContentBox>

                    {BoardList &&
                        BoardList.map((Board, index) => {
                            const {ARCHIVE_SYSTEMID, PUBLISH_MODE, PROFILE_SYSTEMID, ARCHIVE_NAME, NICKNAME, CREATED_STAMP, DESCRIPTION, AUTHORITY} = Board;
                            const AccessType = Object.keys(AUTHORITY_TYPE).find(key => AUTHORITY_TYPE[key] === AUTHORITY);
                            const publishType = Object.keys(PUBLISH_TYPE).find(key => PUBLISH_TYPE[key] === PUBLISH_MODE);

                            const DeleteAccessCard = (e) => {
                                if (!window.confirm(`"${ARCHIVE_NAME}" 을 삭제하시겠습니까?`)) return;

                                DeleteArchiveProject(ARCHIVE_SYSTEMID)
                                    .then(res => {
                                        const { result, msg } = res.data;
                                        if (result === 'Success') {
                                            LoadBoardList();
                                        } else
                                            window.AddAlertMsg({ type: 'error', msg: `삭제에 실패했습니다. : ${msg}` });
                                    }).catch(err => {
                                        window.AddAlertMsg({ type: 'error', msg: `삭제에 실패했습니다. : ${err}` });
                                    })
                            }

                            return (
                                <RepositoryCard
                                    key={ARCHIVE_SYSTEMID + index}
                                    PublishType={publishType}
                                    AccessType={AccessType}
                                    ProfileId={PROFILE_SYSTEMID}
                                    ProjectName={ARCHIVE_NAME}
                                    Creator={NICKNAME}
                                    Date={CREATED_STAMP}
                                    EventOption={(e) => {
                                        setNewBoardForm({
                                            mode: 'edit',
                                            userId: systemId,
                                            userNickname: nickname,
                                            archiveId: ARCHIVE_SYSTEMID,
                                            archiveName: ARCHIVE_NAME,
                                            publishType: PUBLISH_MODE,
                                            description: DESCRIPTION,
                                        })
                                    }}
                                    EventDelete={DeleteAccessCard}
                                    Buttons={
                                        <>
                                            <ButtonContent
                                                className='btn_link'
                                                type='sm'
                                                label={<MdInsertLink />}
                                                title='link'
                                                onClick={(e) => {
                                                    const copyText = `https://${window.location.host}/Board/link?border=${ARCHIVE_SYSTEMID}&page=1`;
                                                    navigator.clipboard.writeText(copyText);
                                                    window.AddToastMsg(`link Copy Success`);
                                                }} />
                                            <ButtonContent 
                                                type='sm' 
                                                label='Edit'
                                                title='Edit'
                                                onClick={(e) => {
                                                    navigate(`/Board/Settings?border=${ARCHIVE_SYSTEMID}&page=1`,);
                                                }} 
                                            />
                                        </>
                                    }
                                />
                            )
                        })
                    }
                </ContentSection>
            </ContentPage>
        </>
    )
}

const ButtonContent = styled(BtnSecondary)`
    padding: 4px 8px !important;
    height: 30px !important;
    min-width: 0;

    &.btn_link{
        padding: 0  4px!important;
        &:hover{
            border-color: ${({theme}) => theme.base.border_color_Blue};
            background-color: ${({theme}) => theme.base.background_color_LightBlue};
        }
        svg{
            font-size: 1.3rem;
            color:  ${({theme}) => theme.base.border_color_DarkBlue};
        }
    }
`;
