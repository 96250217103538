import { useState, useEffect } from 'react';

import {GetDescription} from 'api/Project';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

export default function HmiSymbolListContent({enuSpace}){
    const TreeData = useSelector(state => state.projectData.TREE_DATA);
    const {userId, projectId} = useSelector(state => state.projectData.BASE_DATA);
    const [HmiList, setHmiList] = useState([]);
    const [SelectedHmi, setSelectedHmi] = useState('');
    const [UseSymbolArray, setUseSymbolArray] = useState([]);
    const [templateSymbol, setTemplateSymbol] = useState({});

    useEffect(()=>{
        let mapHmiData = {};
        try {
            for (let _treeData of TreeData) {
                if (_treeData['title'] === 'library') {
                    const libraryData = _treeData['child']?.find(child => child['title'] === 'hmi');
                    if (libraryData && libraryData['child'] !== null) {
                        for (let _hmiData of libraryData['child']) {
                            const symbolURl = `library\\hmi\\${_hmiData['title']}`;
                            mapHmiData[_hmiData['title']] = symbolURl;
                        }
                    }
                }
            }

            setHmiList(mapHmiData);
            if(Object.values(mapHmiData)[0] !== undefined)
                enuSpace.GetSymbolList(Object.values(mapHmiData)[0]);
        } catch (error) {
            window.AddAlertMsg({type:'error', msg:'HMI Symbol List Load Failed'});
        }
    }, [TreeData, enuSpace]);

    useEffect(()=>{
        GetDescription(userId, projectId, 'temp')
            .then(res => {
                const { result, data } = res.data;
                if (result === "Success") {
                    const {templateInfo, templateSymbol} = JSON.parse(data);
                    setTemplateSymbol({templateInfo, templateSymbol});
                }
            })
    }, [userId, projectId]);

    useEffect(()=>{
        setSelectedHmi(Object.keys(HmiList)?.length === 0 ? "templateSymbol" : "");
    }, [HmiList]);

    window.Re_GetSymbolList = (_symbolList) =>{
        const {type, data} = JSON.parse(_symbolList);
        if(type === 'success'){
            setUseSymbolArray(data ? data.split(',') : null);
        }else if(type === 'failed'){
            setUseSymbolArray('');
        }
    }

    return(
        <Aside>
            {/* hmi 파일 선택 Select */}
            <SelectContent 
                name='SymbolSelect' 
                value={SelectedHmi} 
                onChange={(e)=>{
                    const TargetValue = e.target.value;
                    setSelectedHmi(TargetValue);

                    if(TargetValue !== 'templateSymbol')
                        enuSpace.GetSymbolList(HmiList[TargetValue]);
                }} 
            >
                {Object.keys(HmiList).map((hmiName) => (
                    <option key={hmiName} value={hmiName}>{hmiName}</option>
                ))}

                {/* templateSymbol 이 있는 경우 추가 */}
                {templateSymbol?.templateSymbol &&
                    <option value='templateSymbol'>template Symbol</option>
                }
            </SelectContent>

            <UseSymbolNameList>
                {/* templateSymbol 이 있는 경우 templateSymbol을 출력*/}
                {(SelectedHmi === 'templateSymbol' && templateSymbol?.templateSymbol) &&
                    templateSymbol?.templateSymbol.map((symbolInfo, index) => {
                        const { TITLE, THUMBNAIL, SYMBOL_SYSTEMID } = symbolInfo
                        const { VERSION, TEMPLATE_SYSTEMID } = templateSymbol.templateInfo
                        const imgSrc = `data:image/png;base64,${THUMBNAIL}`

                        return (
                            <Symbol
                                key={index}
                                draggable="true"
                                onDragStart={(e) => {
                                    const ImageSrc = {
                                        TYPE: 'template',
                                        VERSION: VERSION,
                                        TEMPLATE_SYSTEMID: TEMPLATE_SYSTEMID,
                                        SYMBOL: SYMBOL_SYSTEMID,
                                        FILENAME: TITLE
                                    }
                                    e.dataTransfer.setData('text/plain', JSON.stringify({ ImageSrc, type: 'symbol' }))
                                }}
                            >
                                <img draggable="false" src={imgSrc} alt="" onError={(e) => e.target.src = 'img\\ExplorerTree\\icon_symbol.png'} />
                                <span title={TITLE}>{TITLE}</span>
                            </Symbol>
                        )

                    })
                }
                
                {SelectedHmi !== 'templateSymbol' &&
                    UseSymbolArray?.map((_symbolName, index) => {
                        return (
                            <Symbol
                                key={index}
                                draggable="true"
                                onDragStart={(e) => {
                                    const ImageSrc = {
                                        TYPE: 'symbol',
                                        NAME: `${_symbolName}`
                                    }
                                    e.dataTransfer.setData('text/plain', JSON.stringify({ ImageSrc, type: 'symbol' }))
                                }}
                            >
                                <img
                                    draggable="false"
                                    alt=''
                                    src={`Repository\\${userId}\\${projectId}\\thumbnail\\hmi_${_symbolName}.png`}
                                    onError={(e) => e.target.src = 'img\\ExplorerTree\\icon_symbol.png'}
                                />
                                <span title={_symbolName}>{_symbolName}</span>
                            </Symbol>
                        )
                    })}
            </UseSymbolNameList>
        </Aside>
    )
}

//스타일---------------------------------------------
const Aside = styled.aside`
    position: relative;
    height: 100%;
`;

const SelectContent = styled.select`
    font-size: .9rem;
    height: 38px;
    width: calc(100% - 32px);
    box-sizing: border-box;
    border: 1px solid  ${({theme}) => theme.base.border_color_Gray};
    border-radius: 4px;
    margin: 16px;
    cursor: pointer;

    &:disabled{
        cursor:default;
        background-color: ${({theme}) => theme.base.background_color_PaleGray};
    }
`;

const UseSymbolNameList = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border-top: 1px solid  ${({theme}) => theme.base.border_color_Gray};
    padding: 8px;
    overflow: auto;
    height: calc(100% - 88px);
`;

const Symbol = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #fff;
    user-select: none;

    &:hover{
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
        border-radius: 4px;

        svg{
            display: block;
        }
    }

    img{
        box-sizing: border-box;
        width: 46px;
        height: 46px;
        object-fit: scale-down;
    }

    span {
        font-size: .9rem;
        color: ${({theme}) => theme.base.font_color_Black};
        width: 100%;
        line-height: 1.1;
        padding-left: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;