import { useEffect, useState, useRef, useCallback } from "react"

import { BtnText } from "components/atom/Button";

import { useResizeDetector } from 'react-resize-detector';
import styled from "styled-components"
import { MdClose, MdFormatListBulleted, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdHorizontalRule, MdAdd, MdFullscreen, MdMoreHoriz, MdMinimize, MdScreenShare  } from "react-icons/md";
import { Document, Page, pdfjs } from 'react-pdf';
// import { isImmutableDefault } from "@reduxjs/toolkit";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SharePdfModalChild({ _BASE_DATA, RequestMsg, PdfInfo, connectUser, isMinsize, FocusInfo, SelectedPdfState, setIsShareUser, ...props }) {
    const { SHARED_PDF_ID, HOST_MEMBER_SYSTEMID, PDF_NAME, MEMBER_SYSTEMID_LIST, PAGE_INDEX} = PdfInfo;
    const {info: ConnectUserInfo} = connectUser;
    const { projectId, userId } = _BASE_DATA;
    const [SelectedPdf, setSelectedPdf] = SelectedPdfState;
    const PDF_URL = `/Repository/publish/${projectId}/${PDF_NAME}`
    const ModalElement = document.getElementById(SHARED_PDF_ID);

    const DocumentRef = useRef(null);
    const PdfPageRef = useRef(null);
    const { width, height, ref } = useResizeDetector();
    const [isMenuList, setMenuList] = useState(false);

    const [ClassNames, setClassNames] = useState('');
    const [scale, setScale] = useState({
        value: 1.0,
        inputValue: 100,
    });
    const [numPages, setNumPages] = useState(null);
    const [isSideBar, setIsSideBar] = useState(false);
    const [pageNumber, setPageNumber] = useState({
        value: 1,
        inputValue: 1,
    });
    const [, setIsLoading] = useState(true);

    const TogglePaceClass = useCallback((e) => {
        const canvasElement = ModalElement?.querySelector('.pdfDocument');
        if(canvasElement){
            const hasHorizontalScrollbar = canvasElement.scrollWidth > canvasElement.clientWidth;
            const hasVerticalScrollbar = canvasElement.scrollHeight > canvasElement.clientHeight;

            canvasElement.classList.toggle('onScroll', hasHorizontalScrollbar || hasVerticalScrollbar);
        }
    }, [ModalElement]);

    useEffect(()=>{
        if(width < 330 || isMinsize){
            setClassNames('minStyle');
            setIsSideBar(false);
        }else{
            setClassNames('')
        }

        TogglePaceClass()
    }, [width, height, isMinsize, TogglePaceClass]);

    useEffect(()=>{
        if(isMinsize)
            setMenuList(false)
    }, [isMinsize]);

    useEffect(()=>{
        const Page = PAGE_INDEX || 1;
        setPageNumber({value: Page, inputValue: Page})
    }, [PAGE_INDEX]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (ref.current && ref.current.classList.contains('PageEvent')) {
                ref.current.classList.remove('PageEvent');
            }
        }, 350);
    
        if (ref.current)
            ref.current.classList.add('PageEvent');
    
        return () => clearTimeout(timerId);
    }, [ref, PAGE_INDEX]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setIsLoading(false);
    }

    const ChangePageHost = (PAGE_INDEX)=>{
        if(userId === HOST_MEMBER_SYSTEMID)
            RequestMsg('UpdateHostPageIndex', {SHARED_PDF_ID, PAGE_INDEX})
    }

    const handlePageChange = (newPage) => {
        const clampedPage = Math.min(Math.max(newPage, 1), numPages);
        setPageNumber({ value: clampedPage, inputValue: clampedPage });
        ChangePageHost(clampedPage);
    };

    const PageInputChange = (e) => setPageNumber({ ...pageNumber, inputValue: e.target.value });

    const EnterKeyEvent = (e) => {if (e.key === 'Enter') e.target.blur();}
    const PageBlurEvent = (e) => {
        let value = Number(pageNumber.inputValue);
        value = Math.max(1, Math.min(value, numPages));

        setPageNumber({ inputValue: String(value), value });
        ChangePageHost(value);
    }

    const handleScaleChange = (delta) => {
        setScale((prev) => {
            const newValue = Math.max(0.1, prev.value + delta);
            const newInputValue = Math.max(10, Number(prev.inputValue) + delta * 100);
            return { value: newValue, inputValue: newInputValue };
        });
    };

    const ScaleBlurEvent = (e) => {
        const value = Number( scale.inputValue);
        const newValue = Math.max(0.1, value / 100);
        const newInputValue = Math.max(10, value);
        setScale({ value: newValue, inputValue: newInputValue});
    };

    return (
        <PdfSpace id={SHARED_PDF_ID}
            ref={ref}
            className={`${FocusInfo?.hasOwnProperty(userId) ? "onFocus" : ""} ${SelectedPdf === SHARED_PDF_ID ? "SelectedPdf" : ""}`}
            onMouseLeave={(e)=>setMenuList(false)}
            onClick={e=> {
                e.target.focus()
                RequestMsg('UpdatePDFFocus', {SHARED_PDF_ID, PAGE_INDEX: pageNumber.value})
            }} {...props}
        >
            {/* 맨상단 바 */}
            {!isMinsize &&
                <PdfHeader>
                    <span title={PDF_NAME.replace('document/', '')}>{PDF_NAME.replace('document/', '')}</span>
                    <div className="buttons">

                        {/* 프로필이미지 */}
                        <div className="ProfileBox">
                            <img
                                className={FocusInfo?.hasOwnProperty(HOST_MEMBER_SYSTEMID) ? "onFocus" : ""}
                                src={`${ConnectUserInfo.filter(info => info.MEMBER_SYSTEMID === HOST_MEMBER_SYSTEMID)[0].PROFILE}`} 
                                alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} 
                            />
                            {MEMBER_SYSTEMID_LIST.map((memberSystemId, index) => {
                                const [userInfo] = ConnectUserInfo.filter(info => info.MEMBER_SYSTEMID === memberSystemId);
                                return (
                                    <img 
                                        key={index} 
                                        className={FocusInfo?.hasOwnProperty(userInfo?.MEMBER_SYSTEMID) ? "onFocus" : ""} 
                                        src={`${userInfo?.PROFILE}`} alt="" 
                                        onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }}
                                    />
                                )
                            })}
                        </div>

                        {SelectedPdf === SHARED_PDF_ID
                            ? <MdMinimize onClick={(e) => setSelectedPdf('')} />
                            : <MdFullscreen onClick={(e) => setSelectedPdf(SHARED_PDF_ID)} />
                        }

                        {HOST_MEMBER_SYSTEMID === userId &&
                            <MdMoreHoriz className="PdfMenu" onClick={(e)=>setMenuList(true)} />
                        }
                    </div>
                </PdfHeader>
            }

            {/* PDF 영역 */}
            <PdfContent isMinsize={isMinsize} className={ClassNames}>
                <div>
                    {!isMinsize &&
                        <Header className={ClassNames}>
                            <div>
                                <BtnText type='btn_side' label={<MdFormatListBulleted className="btn" />} onClick={(e) => setIsSideBar(prv => !prv)} />
                            </div>
                            <div>
                                {/* 페이지 변경 */}
                                <BtnText
                                    label={<MdKeyboardArrowLeft className="btn" />}
                                    onClick={() => handlePageChange(pageNumber.value - 1)}
                                />
                                <span>
                                    <input type="number" value={pageNumber.inputValue}
                                        onChange={PageInputChange}
                                        onBlur={PageBlurEvent}
                                        onKeyDown={EnterKeyEvent}
                                    />
                                    &nbsp; / {numPages}
                                </span>
                                <BtnText
                                    label={<MdKeyboardArrowRight className="btn" />}
                                    onClick={() => handlePageChange(pageNumber.value + 1)}
                                />
                            </div>
                            <div style={{ gap: '0' }}>
                                {/* 스케일 변경 */}
                                <BtnText
                                    type='btn_scale'
                                    label={<MdHorizontalRule className="btn" />}
                                    onClick={() => handleScaleChange(-0.1)}
                                />
                                <input type="number" value={scale.inputValue}
                                    className="scaleInput"
                                    onChange={(e) => setScale({ ...scale, inputValue: e.target.value })}
                                    onBlur={ScaleBlurEvent}
                                    onKeyDown={EnterKeyEvent}
                                />
                                <span className="scaleInput">%</span>
                                <BtnText 
                                    type='btn_scale' 
                                    label={<MdAdd className="btn" />} 
                                    onClick={() => handleScaleChange(0.1)}
                                />
                            </div>
                        </Header>
                    }
                    <div className="body">
                        {(isSideBar && !isMinsize) &&
                            <SideBar>
                                <Document
                                    className="SideDocument"
                                    file={PDF_URL}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >

                                    {Array.from(
                                        new Array(numPages),
                                        (el, index) => (
                                            <Page
                                                key={`page_${index + 1}`}
                                                className={pageNumber.value === index + 1 ? "active" : ""}
                                                pageNumber={index + 1}
                                                scale={0.3}
                                                renderAnnotationLayer={false}
                                                renderTextLayer={false}
                                                onClick={(e) =>{
                                                    setPageNumber({value: index + 1, inputValue: index + 1})
                                                    ChangePageHost(index + 1)
                                                }}
                                            />
                                        ),
                                    )}
                                </Document>
                            </SideBar>
                        }
                        <Document
                            className={`pdfDocument ${isSideBar ? "sideBar" : ''}`}
                            inputRef={DocumentRef}
                            file={PDF_URL}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page
                                // canvasBackground="transparent"
                                pageNumber={pageNumber.value}
                                scale={scale.value}
                                canvasRef={PdfPageRef}
                                onLoadSuccess={TogglePaceClass}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                            />
                        </Document>
                    </div>
                </div>
            </PdfContent>

            {/* focus 시 border 표시를 위한 처리 */}
            <div className="FocusLine FocusLine_top" />
            <div className="FocusLine FocusLine_right" />
            <div className="FocusLine FocusLine_bottom" />
            <div className="FocusLine FocusLine_left" />

            {/* PDF 메뉴 */}
            {isMenuList &&
                <>
                    <div className="MenuBg" onClick={(e)=>setMenuList(false)}/>
                    <PdfMenuBox>
                        <MenuUi>
                            <li onClick={(e)=>{
                                setIsShareUser({
                                    visibility: true,
                                    path: PDF_NAME,
                                    SharedUser: MEMBER_SYSTEMID_LIST,
                                    SHARED_PDF_ID,
                                })
                                setMenuList(false)
                            }}>
                                <span> <MdScreenShare style={{ 'color': 'royalblue' }}/>Share</span>
                            </li>
                            <li onClick={(e)=>{
                                RequestMsg("CloseSharedPDF", { SHARED_PDF_ID });
                                setSelectedPdf('');
                            }}>
                                <span> <MdClose /> Close </span>
                            </li>
                        </MenuUi>
                    </PdfMenuBox>
                </>
            }
        </PdfSpace>
    )
}

const PdfSpace = styled.div`
    position: relative;

    &.onFocus, &.PageEvent{
        .FocusLine{
            position: absolute;
            opacity: .6;
            z-index: 10;
        }

        .FocusLine_top, .FocusLine_bottom{
            width: 100%;
            height: 6px;
        }
        .FocusLine_top{top: 0;}
        .FocusLine_bottom{bottom: 0;}

        .FocusLine_right, .FocusLine_left{
            top: 0;
            width: 6px;
            height: 100%;
        }
        .FocusLine_left{left: 0;}
        .FocusLine_right{right: 0;}
    }

    &.onFocus {
        .FocusLine{
            background-color: ${({ theme }) => theme.base.border_color_Blue};
        }
    }
    
    &.PageEvent{
        .FocusLine{
            background-color: ${({ theme }) => theme.base.border_color_Red};
        }
    }

    .MenuBg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* background-color: red; */
        z-index: 9;
    }

`;

const ModalHeader = styled.header`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    border-radius: 0.3rem 0.3rem 0 0;
    padding: 8px 8px 8px 16px;
    font-weight: bold;
    box-sizing: border-box;
    color: ${({ theme }) => theme.base.font_color_Black};
    background-color: ${({ theme }) => theme.base.background_color_PaleGray};
    border-bottom: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
    user-select: none;

    &>span{
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .buttons{
        display: flex;
        gap: 0;

        svg{
            width: 30px;
            height: 24px;
            padding: 3px;
            box-sizing: border-box;
            font-size: 1.2rem;
            text-align: center;
            color: ${({ theme }) => theme.base.font_color_DarkGray};
            background-color: transparent;
            outline: none;
            cursor: pointer;
            border: 0;

            &:hover{
                color: ${({ theme }) => theme.base.font_color_DarkBlue};
            }

            &.close:hover{
                color: ${({ theme }) => theme.base.font_color_Red};
            }
        }

        .ProfileBox{
            display: flex;
            gap: 4px;

            img{
                width: 26px;
                height: 26px;
                border-radius: 50%;
                object-fit: cover;

                &.onFocus{
                    outline: 2px solid ${({ theme }) => theme.base.success_color};
                }
            }
        }
    }
`;

const PdfHeader = styled(ModalHeader)`
    height: 40px;
    border-radius: 0;
    background-color: ${({ theme }) => theme.base.background_color_Gray};
    border-bottom: 0 none;
`;

const PdfContent = styled.div`
    position: relative;
    width: 100%;
    height: ${({ isMinsize }) => !isMinsize ? 'calc(100% - 40px)' : '100%'};
    background-color: #343a40;

    &>div{
        height: 100%;

        &>.body{
            position: relative;
            display: flex;
            width: 100%;
            height: ${({ isMinsize }) => !isMinsize ? 'calc(100% - 40px)' : '100%'};

            .pdfDocument{
                position: relative;
                height: 100%;
                width: 100%;
                overflow: overlay;
                margin: 0 auto;
                
                .react-pdf__Page{
                    background-color: transparent!important;
                }

                &:not(.onScroll){
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &>div{
                        height: auto;
                    }
                }

                &.sideBar{
                    width: calc(100% - 180px);
                }

                &>div{
                    height: 100%;
                }
            }
        }
    }

    &.minStyle{
        .pdfDocument{
            display: flex;
            justify-content: center;
            align-items: center;

            canvas {
                width: 100%!important;
                height: auto!important;
            }
        }
    }
`;

const Header = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    height: 40px;
    background-color: #292E33;
    z-index: 9;
    box-shadow: rgba(0, 0, 0, .5) 0px 0px 0px 1px, rgba(0, 0, 0, .5) 0px 2px 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;

    &>div{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #fff;

        button{
            padding: 8px;
            box-sizing: border-box;
        }

        .btn{
            color: #fff;
            font-size: 1.2rem;
            opacity: .6;

            &:hover{
                opacity: 1;
            }
        }

        input{
            color: #fff;
            width: 36px;
            text-align: center;
            background-color: #0B0D0E;
            outline: 0 none;
            border: 0 none;

            &[type="number"]::-webkit-outer-spin-button,
            &[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    &.minStyle{
        .btn_scale, .btn_side, .scaleInput{
            display: none;
        }
    }
`;

const SideBar = styled.div`
    position: relative;
    width: 180px;
    height: 100%;
    border-right: 1px solid rgba(255, 255, 255, .3);
    overflow-y: overlay;
    
    &>.SideDocument{
        position: relative;
        width: 100%;
        height: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 8px;
        box-sizing: border-box;

        canvas {
            width: 100%!important;
            height: auto!important;
            cursor: pointer;

            &:hover{
                outline: 8px solid ${({ theme }) => theme.base.border_color_Blue};
            }
        }
    }

    .active{
        outline: 8px solid ${({ theme }) => theme.base.border_color_Blue};
    }
`;

const PdfMenuBox = styled.div`
    position: absolute;
    background-color: #fff;
    top: 40px;
    right: 10px;
    z-index: 11;
    display: flex;
    border-radius: 4px;
    padding: 8px 24px 8px 8px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
    flex-wrap: wrap;
    cursor: default;
    user-select: none;
`;


const MenuUi = styled.ul`
    a{
        text-decoration: none;
    }
    
    span{
        color: ${({theme}) => theme.base.font_color_Black};
        font-size: 0.9rem;
        display: flex;
        width: 100%;
        padding: ${({ isChild }) => isChild ? '6px 0' : '6px 8px'};

        >.icon_arrow{
            position: absolute;
            top: 25%;
            right: 0;
        }

        &>svg{
            font-size: initial;
            padding-right: 4px;
        }

        &>img{
            padding-right: 4px;
        }
    }

    .menuLine{
        width: 100%;
        position: relative;

        &:after{
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: ${({ isChild }) => isChild ? '110%' : '100%'};
            height: 1px;
            background-color: ${({theme}) => theme.base.background_color_Gray};
        }
    }

    li:hover{
        span {background-color :  ${({theme}) => theme.base.background_color_LightBlue};

        &>ul{
            display:block;
        }
    }

    &>li{
        position: relative;
        width: 100%;
        padding: ${({ isChild }) => isChild ? '0 8px' : null};

        &>ul{
            position: absolute;
            top: 0;
            left: 100%;
            background-color: #fff;
            z-index: 9;
            border-radius: 4px;
            padding: 8px;
            box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
            cursor: default;
            display:none;

            &>li{
                display: flex;
                align-items: center;
                position: relative;
                gap: 6px;
                padding: 0 8px;
                box-sizing: border-box;

                &>span{
                    width: calc(100% - 32px);
                }

                svg{
                    width: 16px;
                }
            }
        }
    }
}`;