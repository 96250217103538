import { createSlice } from "@reduxjs/toolkit";

const SetCanvasView = (_CANVAS_VIEW, _actionPayload, _canvasMode = 'design') =>{
    let count = 0;
    let newCanvasId = `canvas_${count}`;

    const arrayActionPayload = _actionPayload.split('\\');
    while(_CANVAS_VIEW.activeCanvas[newCanvasId] !== undefined){
        if(arrayActionPayload[0] === 'library' && arrayActionPayload[1] === 'hmi') // hmi 일 경우
        {
            if(_canvasMode === 'design') {
                // design 모드일 경우
                if(arrayActionPayload.length === _CANVAS_VIEW.activeCanvas[newCanvasId].length && _CANVAS_VIEW.activeCanvas[newCanvasId].split('\\')[2] === arrayActionPayload[2]){ // 이미 등록된 hmi 심볼일 경우 값만 변경 
                    _CANVAS_VIEW.currentView = _actionPayload;
                    break;
                }
            }else if(_CANVAS_VIEW.activeCanvas[newCanvasId] === _actionPayload){  // code 모드일 경우
                _CANVAS_VIEW.currentView = _actionPayload;
                break;
            }
        }else if(_CANVAS_VIEW.activeCanvas[newCanvasId] === _actionPayload){    // 일반적인 picture 경우
            _CANVAS_VIEW.currentView = _actionPayload;
            break;
        }
           
        count++;
        newCanvasId = `canvas_${count}`;
    }
    
    _CANVAS_VIEW.currentView = newCanvasId;
    _CANVAS_VIEW.activeCanvas[newCanvasId] = _actionPayload;
    _CANVAS_VIEW.canvasMode[newCanvasId] = _canvasMode;
}

let initialState = {
    MODE_TYPE: false,
    ACTIVE_PICTURE_URL:null,
    ARRAY_ACTIVE_PICTURE_URL:[],
    ACTIVE_BTN:{},
    BASE_DATA:{},
    TREE_DATA:{},
    CANVAS_VIEW:{
        currentView: '',
        activeCanvas: {},
        canvasMode:{},
    },
}

const projectDataSlice = createSlice({
    name : 'projectData',
    initialState,
    reducers: {
        setTreeData(state, action){
            state['TREE_DATA'] = action.payload;
        },
        deleteTreeData(state){
            state['TREE_DATA'] = {};
        },
        setBaseData(state, action){
            Object.entries(action.payload).forEach(([key, value])=>{
                state['BASE_DATA'][key] = value;
            });
        },
        setCanvasView(state, action){
            state['CANVAS_VIEW'] = action.payload;
            state["ACTIVE_PICTURE_URL"] = action.payload["activeCanvas"][action.payload["currentView"]];

            if(action.payload["activeCanvas"][action.payload["currentView"]] === undefined)
                return;

            state["ARRAY_ACTIVE_PICTURE_URL"] = [];
            state["ARRAY_ACTIVE_PICTURE_URL"] = action.payload["activeCanvas"][action.payload["currentView"]].split('\\');
        },
        updateCurrentView(state, action){
            const canvasId = action.payload['canvasId'];
            state['CANVAS_VIEW'].currentView = canvasId;
            state['CANVAS_VIEW'].canvasMode[canvasId] = action.payload['mode'];
        },
        changeCanvasViewName(state, action){
            const originPath = `${action.payload['path']}\\${action.payload['originalName']}`
            const changePath = `${action.payload['path']}\\${action.payload['changeName']}`

            const arrActiveCanvas = state['CANVAS_VIEW']['activeCanvas'];

            for(let _key of Object.keys(arrActiveCanvas)){
                const svgURL = arrActiveCanvas[_key];
                const arrSvgURL = svgURL.split('\\');

                if(arrSvgURL[0] === 'library'){
                    const arrOriginPath = originPath.split('\\');
                    const isTarget = arrOriginPath.length === 3 
                                    ? `${arrSvgURL[0]}\\${arrSvgURL[1]}\\${arrSvgURL[2]}` === originPath 
                                    : `${arrSvgURL[0]}\\${arrSvgURL[1]}\\${arrSvgURL[2]}\\${arrSvgURL[3]}` === originPath;

    
                    if(isTarget){
                        arrActiveCanvas[_key] = arrOriginPath.length === 3 ? `${changePath}\\${arrSvgURL[3]}` : changePath;
                    }
                }else{
                    if(svgURL === originPath){
                        arrActiveCanvas[_key] = changePath;
                    }
                }
            }
        },
        changeActiveCanvasOrder(state, action){
            const { targetId, toCanvas } = action.payload;
            const { activeCanvas, canvasMode, currentView } = state.CANVAS_VIEW;

            const newActiveCanvas = { ...activeCanvas };
            [newActiveCanvas[targetId], newActiveCanvas[toCanvas]] = [newActiveCanvas[toCanvas], newActiveCanvas[targetId]];

            const newCanvasMode = { ...canvasMode };
            [newCanvasMode[targetId], newCanvasMode[toCanvas]] = [newCanvasMode[toCanvas], newCanvasMode[targetId]];

            const newCurrentView = currentView === targetId ? toCanvas : (currentView === toCanvas ? targetId : currentView);

            state.CANVAS_VIEW = { ...state.CANVAS_VIEW, activeCanvas: newActiveCanvas, canvasMode: newCanvasMode, currentView: newCurrentView };
        },
        updateActivePictureURL(state, action){
            state["ACTIVE_PICTURE_URL"] = action.payload;
            state["ARRAY_ACTIVE_PICTURE_URL"] = [];
            state["ARRAY_ACTIVE_PICTURE_URL"] = action.payload.split('\\');
        },
        insertCanvasView(state, action){
            SetCanvasView(state['CANVAS_VIEW'], action.payload);

            state["ACTIVE_PICTURE_URL"] = action.payload;

            state["ARRAY_ACTIVE_PICTURE_URL"] = [];
            state["ARRAY_ACTIVE_PICTURE_URL"] = action.payload.split('\\');
        },
        viewCodeEditor(state, action){
            SetCanvasView(state['CANVAS_VIEW'], action.payload, 'code');

            state["ACTIVE_PICTURE_URL"] = action.payload;
            state["ARRAY_ACTIVE_PICTURE_URL"] = [];
            state["ARRAY_ACTIVE_PICTURE_URL"] = action.payload.split('\\');
        },
        AllClearCanvasView(state){
            state['CANVAS_VIEW'] = {
                currentView: '',
                activeCanvas: {},
                canvasMode:{},
            }
        },
        toggleActiveBtn(state, action){
            const currentBtnName = state.ACTIVE_BTN;
            const newBtnName = action.payload;

            if (!currentBtnName && !newBtnName) return;
            state.ACTIVE_BTN = (currentBtnName === newBtnName) ? '' : newBtnName;
        },
        toggleModeType(state, action){
            // editMode: false, runtimeMode: true
            state['MODE_TYPE'] = action.payload;
        },
        InitProjectData(state){
            //FIXME: 일단 canvas 내용만 삭제
            for(let _key of Object.keys(state['CANVAS_VIEW']['activeCanvas'])){
                delete state['CANVAS_VIEW']['activeCanvas'][_key];
            }

            state['CANVAS_VIEW']['currentView'] = '';
        },
    }
})

export const {setBaseData, setTreeData, deleteTreeData, updateCurrentView, changeCanvasViewName, updateActivePictureURL, insertCanvasView, viewCodeEditor, setCanvasView, toggleActiveBtn, toggleModeType, InitProjectData, AllClearCanvasView, changeActiveCanvasOrder} = projectDataSlice.actions;

export default projectDataSlice.reducer;
