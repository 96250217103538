export const BoxMeshIcon = ({ border="#B3b3B3", fill="transparent" }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="bg" fillRule="evenodd" clipRule="evenodd"
                d="M9.35078 5L5 8.48063V19H15.5194L19 14.6492V5H9.35078Z" fill={fill} />
            <path className="border" fillRule="evenodd" clipRule="evenodd"
                d="M9 4L4 8V20H16L20 15V4H9ZM9.35078 5L5.60078 8H15.293L18.293 5H9.35078ZM19 5.70718L16 8.70718V18.3992L19 14.6492V5.70718ZM5 19V9H15V19H5Z"
                fill={border} />
        </svg>
    )
}

export const CylinderMeshIcon = ({ border = "#B3b3B3", fill = "transparent" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path className="bg" fill={fill} fillRule="evenodd"
                d="M19 6c0-1.657-3.134-3-7-3S5 4.343 5 6v12c0 1.657 3.134 3 7 3s7-1.343 7-3V6Z"
                clipRule="evenodd" />
            <path className="border" fill={border} fillRule="evenodd"
                d="M12 4c1.83 0 3.44.32 4.556.798.56.24.948.5 1.182.737.23.233.262.389.262.465 0 .076-.032.232-.262.465-.234.238-.623.497-1.182.737C15.44 7.68 13.83 8 12 8c-1.83 0-3.44-.32-4.556-.798-.56-.24-.948-.5-1.182-.737C6.032 6.232 6 6.076 6 6c0-.076.032-.232.262-.465.234-.238.623-.497 1.182-.737C8.56 4.32 10.17 4 12 4ZM5 6c0-1.657 3.134-3 7-3s7 1.343 7 3v12c0 1.657-3.134 3-7 3s-7-1.343-7-3V6Zm13 1.546V18c0 .076-.032.232-.262.465-.234.238-.623.497-1.182.737C15.44 19.68 13.83 20 12 20c-1.83 0-3.44-.32-4.556-.798-.56-.24-.948-.5-1.182-.737C6.032 18.232 6 18.076 6 18V7.546C7.225 8.417 9.454 9 12 9s4.775-.583 6-1.454Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const ContourMeshIcon = ({ border = "#B3b3B3", fill = "transparent" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill="#fff" d="M4 4h16v16H4z" />
            <path className="border" fill={border} fillRule="evenodd"
                d="M4 4v16h16V4H4Zm10 1h-4v4h4V5Zm0 5h-4v4h4v-4Zm1-5v4h4V5h-4Zm4 5h-4v4h4v-4Zm-4 5h4v4h-4v-4Zm-1 4v-4h-4v4h4ZM9 5v4H5V5h4Zm0 9v-4H5v4h4Zm0 1v4H5v-4h4Z"
                clipRule="evenodd" />
            <path className="contourBg" fill={fill} d="M5 15h4v4H5z" />
            <path className="contourBg" fill={fill} fillOpacity=".75" d="M15 15h4v4h-4z" />
            <path className="contourBg" fill={fill} fillOpacity=".6" d="M15 10h4v4h-4z" />
            <path className="contourBg" fill={fill} fillOpacity=".45" d="M15 5h4v4h-4z" />
            <path className="contourBg" fill={fill} fillOpacity=".6" d="M10 5h4v4h-4z" />
            <path className="contourBg" fill={fill} fillOpacity=".75" d="M5 5h4v4H5zM10 10h4v4h-4z" />
            <path className="contourBg" fill={fill} fillOpacity=".85" d="M5 10h4v4H5zM10 15h4v4h-4z" />
        </svg>
    )
}