import { useState, useEffect } from 'react';

import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {ModalBottomBox, InputContent, WarningMsg} from "components/css/common";
import useModalPopup from "hooks/useModalPopup"
import useProjectData from 'hooks/useProjectData'

import styled from "styled-components";

const CheckRegular = (_fileName, SetMsg) => {
    // eslint-disable-next-line
    const special_pattern = /[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/?]/gi;

    if(!isNaN(_fileName[0])){
        SetMsg && SetMsg("파일명은 숫자로 시작할수 없습니다.");
        return true;
    }else if(special_pattern.test(_fileName)){
        SetMsg && SetMsg("특수 문자를 사용할수 없습니다.");
        return true;
    }else if(_fileName.includes('.')){
        SetMsg && SetMsg("'.'은 사용할수 없습니다.");
        return true;
    }
}

export default function ChangeTreeFileName({enuSpace, _Modal}){
    const {SetTreeData, ChangeFileName} = useProjectData();
    const {DeleteModal} = useModalPopup();
    const [value, setValue]= useState('');
    const [msg, setMsg]= useState('');

    const {
        id: ModalID,
        data:{
            path: ModalDataPath,
            originalName: ModalDataOriginalName,
        }
    } = _Modal;

    const CloseModal = (e) =>DeleteModal(ModalID);

    useEffect(()=>{
        let fileName, extension = null;
        if(ModalDataOriginalName.includes('.svg')){  //.svg 있음O
            fileName = ModalDataOriginalName.substring(0, ModalDataOriginalName.lastIndexOf('.svg'));
            extension ='.svg';
        }else if(ModalDataOriginalName.includes('.lua')){  //.lua 있음O
            fileName = ModalDataOriginalName.substring(0, ModalDataOriginalName.lastIndexOf('.lua'));
            extension ='.lua';
        }else{
            fileName = ModalDataOriginalName;
        }

        setValue({fileName, extension});
    }, [ModalDataOriginalName]);

    const RenameEvent = (e) =>{
        const arrayPath = ModalDataPath.split('\\');

        const {fileName: newFileName, extension} = value;
        const newFileExtension = extension || '';
        const finalFileName = `${newFileName}${newFileExtension}`;

        if(newFileName === ''){
            setMsg("파일 명을 입력해주세요.")
            return;
        }

        if(CheckRegular(newFileName, setMsg)) return;

        if(finalFileName === ModalDataOriginalName){
            CloseModal();
            return;
        }

        if(value['extension'] === '.lua'){
            enuSpace.RenameGlobalLua(`${ModalDataPath}\\${ModalDataOriginalName}`, `${ModalDataPath}\\${finalFileName}`);
            CloseModal();
        }else{
            if(arrayPath[0] === 'library'){
                if(arrayPath.length === 2)
                    enuSpace.RenameSvg(ModalDataOriginalName, finalFileName, arrayPath[1]);
                else
                    enuSpace.RenameSymbol(ModalDataPath, ModalDataOriginalName, finalFileName);
            }else
                enuSpace.RenameSvg(ModalDataOriginalName, finalFileName, ModalDataPath);
        }

    }

    window.Re_RenameSvg =(_msg) => handleRename(_msg);
    window.Re_RenameSymbol =(_msg) => handleRename(_msg);

    const handleRename = (_msg) =>{
        const {type, data} = JSON.parse(_msg);
        if(type === 'success'){
            SetTreeData(JSON.parse(enuSpace.GetProjectTree()));
            const {fileName: newFileName, extension} = value;
            const newFileExtension = extension || '';
            const finalFileName = `${newFileName}${newFileExtension}`;
            ChangeFileName(ModalDataPath, ModalDataOriginalName, finalFileName);
            CloseModal();
        }else if(type === 'failed')
            setMsg(data);
    }

    const ChangeEvent = (e) => {
        setMsg('');
        setValue({...value, fileName:e.target.value});
    }

    const StopEventBubbling = (e) => e.stopPropagation();

    return(
        <div>
            <FlexContent>
                <InputContent value={value['fileName'] || ''} onChange={ChangeEvent} onMouseDown={StopEventBubbling} onFocus={(e)=>e.target.select()} />
                {value['extension'] && <span>{value['extension'] }</span>}
            </FlexContent>
            <MsgContent>{msg}</MsgContent>
            <ModalBottomBox>
                <BtnSecondary label="Cancel" onClick={CloseModal} />
                <BtnPrimary label="Rename" onClick={RenameEvent}/>
            </ModalBottomBox>
        </div>
    )
}

//스타일--------------------------------------------
const FlexContent = styled.div`
    display: flex;
    align-items: baseline;

    &>span{
        margin-left: 8px;
    }
`;

const MsgContent = styled(WarningMsg)`
    position: absolute;
    margin-top: 4px;
`;