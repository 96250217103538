import React, { useEffect, useMemo } from "react";

import RuntimeModeContent from "components/template/RuntimeModeContent";
import useEnuSpaceModule from 'hooks/useEnuSpaceModule';
import useProjectData from "hooks/useProjectData";
import useRightMenu from "hooks/useRightMenu";
import useAudio from 'hooks/useAudio';
import {GuestLogin} from 'hooks/useMember'

import { setBaseData, InitProjectData } from 'reducers/projectDataReducer'
import { AllClearModalPopup } from 'reducers/modulePopupReducer';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function RuntimeModePage() {
    const dispatch = useDispatch()
    const [enuSpace] = useEnuSpaceModule();
    const {DeleteNodeData} = useProjectData();
    const {CloseRightMenu} = useRightMenu();
    useAudio(enuSpace); // 오디오 재생
    const location = useLocation()
    const BASE_DATA = useMemo(() => {
        return {
            userId: location?.state?.userId,
            userNickname: location.state?.userNickname,
            projectId: location?.state?.projectId,
            projectName: location?.state?.projectName,
            isMetaverse: location?.state?.isMetaverse,
            creatorId: location?.state?.creatorId,
            currentURL: location?.pathname,
        }
    }, [
        location?.state?.userId, 
        location?.state?.projectId, 
        location?.state?.projectName, 
        location.state?.userNickname,
        location?.state?.isMetaverse,
        location?.state?.creatorId,
        location?.pathname,
    ]);

    useEffect(()=>{
        localStorage.setItem('publishId', BASE_DATA.projectId); 
        localStorage.removeItem('projectId');

        dispatch(setBaseData(BASE_DATA));
    }, [BASE_DATA, dispatch]);

    useEffect(()=>{
        if(BASE_DATA?.userId === null && BASE_DATA?.isMetaverse === 0){
            GuestLogin('')
            .then(res => {
                const { result, msg } = res.data;
                if(result === 'Success'){
                    const {
                        Authorization,
                        MEMBER_SYSTEMID,
                        NICKNAME,
                        RefreshToken
                    } = JSON.parse(msg);

                    sessionStorage.setItem('systemId', MEMBER_SYSTEMID);
                    sessionStorage.setItem('Authorization', Authorization);
                    sessionStorage.setItem('refreshtoken', RefreshToken);
                    sessionStorage.setItem('nickname', NICKNAME);

                }
            })
        }

        return ()=>{
            sessionStorage.removeItem('Authorization');
            sessionStorage.removeItem('refreshtoken');
            sessionStorage.removeItem('systemId');
            sessionStorage.removeItem('nickname');
            sessionStorage.removeItem('publishId');
        }
    }, [BASE_DATA?.userId, BASE_DATA?.isMetaverse]);
    
    useEffect(()=>{
        DeleteNodeData();
    }, [DeleteNodeData]);

    // 프로젝트 정보를 받아서 Explorer 창 업데이트 및 첫 픽처 페이지 오픈
    window.Re_UpdateProjectCtrl = (_msg) => {
        const resData = JSON.parse(_msg);
        if (resData['type'] === 'success') {
    
        } else if (resData['type'] === 'failed') {
            console.log('프로젝트 로드 실패');
        }
    }

    useEffect(() => {
        dispatch(InitProjectData());

        return () => {
            dispatch(AllClearModalPopup());
        };
    }, [dispatch]);

    return (
        <div onClick={(e)=>CloseRightMenu()}>
            <RuntimeModeContent enuSpace={enuSpace} _BASE_DATA={BASE_DATA}/>
        </div>
    )
}

export default RuntimeModePage;