import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import MouseRightClickMenuContent from "components/molecule/MouseRightClickMenuContent";
import TreeRowContent from 'components/atom/TreeRowContent';
import Modal from 'components/modal_new/Modal';
import {ModalBottomBox, InputContent, TreeUlContent} from "components/css/common";
import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {BgPopup} from 'components/css/common'
import {GetRealTarget} from 'components/Utility';
import useRightMenu from "hooks/useRightMenu";
import useSQLite from 'hooks/useSQLite';
import useModalPopup from 'hooks/useModalPopup';
import {WarningMsg} from "components/css/common"

import styled from 'styled-components';
import { MdDriveFileRenameOutline, MdClear, MdOutlineFileUpload, MdTableView} from "react-icons/md";

function SQLiteContent({enuSpace}){
    const {AddModal} = useModalPopup();
    const [isOpenTree, setIsOpenTree] = useState();
    const {SQLiteFileList, GetSQLiteFileList, SelectSQLite, GetSQLiteTableList, RenameDocument, DeleteDocuments, UploadSQLiteFile} = useSQLite('');
    const [fileData, setFileData] = useState();
    
    const RightMenuInfo = useSelector(state => {
        if (state.rightMenu.type === 'sqlite')
            return state.rightMenu;
        return null;
    }, shallowEqual);
    const {ShowRightMenu, CloseRightMenu} = useRightMenu();

    // redex로 관리 하지 않고 현 컨포너트에서 모달 관리를 위한 state
    const [CreateModal, setCreateModal] = useState({
        visibility: false,
        data: null
    });
    
    const CloseModal = (e) => setCreateModal({visibility:false, data: null});

    const MouseRightClick = (e) => {
        e.stopPropagation();
        const Target = GetRealTarget(e.target);
        const {type, value, path} = Target.dataset;

        if(!type || type === 'Table'){
            CloseRightMenu();
            return;
        }

        ShowRightMenu({'type': 'sqlite', 'top': e.pageY, 'left': e.pageX, data:{type, value, path}});
    }

    const UploadFile = (e) => {
        setCreateModal({...CreateModal, visibility: true, data:{type: 'Upload File', name: '', path:RightMenuInfo.data.path, msg: ''}}); 
        setFileData('');
    }
    const RenameEvent = (e) => {
        const {value, path} = RightMenuInfo.data;
        setCreateModal({...CreateModal, visibility: true, data:{type: 'Rename', name: value, path, msg: '', oldName: value}})
    };

    const TableViewEvent = (e) => {
        const data = {
            child: SQLiteFileList.child,
            mode: 'view',
            file:RightMenuInfo.data.value
        };
    
        AddModal("SQLiteRuntimeModal", "SQLITE viewer", "SQLiteRuntimeModal", data);
    }

    const TableEditEvent = (e) => {
        const data = {
            child: SQLiteFileList.child,
            mode: 'edit',
            file:RightMenuInfo.data.value
        };
    
        AddModal("SQLiteRuntimeModal", "SQLITE editor", "SQLiteRuntimeModal", data);
    }

    const DeleteDocumentsEvent = (e) => {
        const {value, path} = RightMenuInfo.data;
        if(window.confirm(`"${value}" 를 삭제하시겠습니까?`)){
            DeleteDocuments([path])
                .then((res)=>{
                    GetSQLiteFileList();
                }).catch((err)=>{
                    console.log(err);
                });
        }
    }

    const handleOnChange = (e) => {
        setCreateModal(prevState => ({ ...prevState, data: { ...prevState.data, msg: '' } }));
        setFileData(e.target.files);
    }

    //FIXME: sqlite tree 리스트에 폴더있는 경우를 고려하지 않음 
    const DoubleClickEvent = (e) => {
        const Target = GetRealTarget(e.target);
        const {value:targetValue, type:fileType, path:filePath} = Target.dataset;

        if (fileType === 'sqlite')
            GetSQLiteTableList(filePath);
        else if (fileType === 'Table') {
            // fileType 이 Table 일 경우 path 정보가 sqlite\[파일명]\[테이블명] 형태이기 때문에 -> sqlite\[파일명] 으로 변경 해야 함 
            SelectSQLite(filePath.substring(0, filePath.lastIndexOf('\\')), `SELECT * FROM ${targetValue}`)
                .then(res => {
                    const resData = res.data;
                    if (resData.Result === 'OK') {
                        AddModal(targetValue, targetValue, 'SQLiteTableData', resData.Data);
                    } else {
                        console.error(resData.Msg);
                    }
                }).catch(err => {
                    console.error(err);
                });
        }
    }
    
    const RenderTree = (menu, path='sqlite') => {
        return menu.map((item) => {
            const {title, content, type, child} = item;
            const _path = path+'\\'+ (title ?? content) ;
            return(
                <li key={_path}>
                    <TreeRowContent _checkOpenTree={[isOpenTree, setIsOpenTree]} _title={title ?? content} _type={type} _path={_path} _onDoubleClick={DoubleClickEvent}>
                        {child &&
                            <TreeUlContent>
                                {(RenderTree(child, _path))}
                            </TreeUlContent>
                        }
                    </TreeRowContent>
                </li>
            )
        })
    }
    
    return(
        <div style={{'height':'100%', 'overflowY': 'auto', 'paddingLeft':'8px'}} onClick={CloseRightMenu} onContextMenu={(e)=>{e.preventDefault(); MouseRightClick(e); }}>
            <TreeRowContent _title="sqlite" _type="root" _path="sqlite" >
                <TreeUlContent>
                    {SQLiteFileList['child'] && RenderTree(SQLiteFileList['child'])}
                </TreeUlContent>
            </TreeRowContent>

            {/* 우클릭 매뉴 */}
            {(RightMenuInfo?.type === 'sqlite') &&
                <MouseRightClickMenuContent _positionTop={RightMenuInfo['top']} _positionLeft={RightMenuInfo['left']}>
                    {RightMenuInfo['data']['type'] === 'sqlite'
                        ?
                            <>
                                <li onClick={TableViewEvent}><span><MdTableView style={{ 'color': 'darkgoldenrod' }} />View</span> </li>
                                <li onClick={TableEditEvent}><span><MdTableView style={{ 'color': 'darkgoldenrod' }} />Edit</span> </li>
                                <li onClick={RenameEvent}><span><MdDriveFileRenameOutline style={{ 'color': 'darkgoldenrod' }} />Rename</span> </li>
                                <span className="menuLine" />
                                <li onClick={DeleteDocumentsEvent}><span><MdClear style={{ 'color': 'mediumvioletred' }} />Delete</span> </li>
                            </>
                        : 
                            <li onClick={UploadFile}><span><MdOutlineFileUpload style={{ color: 'royalblue' }} />upload file</span> </li>
                    }
                </MouseRightClickMenuContent>
            }

            {/* 모달 창 파일 또는 폴더 명을 입력 받음 */}
            {(CreateModal['visibility'] && CreateModal['data']['type'] ==='Rename') && 
                <CreateModalContent>
                    <BgPopup onClick={CloseModal}/>
                    <Modal _title={CreateModal['data']['type']} _height={182} _enableResizing={false}  _closeModal={CloseModal}>
                        <FlexContent>
                            <InputContent 
                                value={CreateModal['data']['name'].replace('.db', '')} 
                                onChange={(e)=>setCreateModal(prevState => ({...prevState, data:{...prevState.data, name:e.target.value, msg:''}}))}/>
                            {(CreateModal['data']['type']==='Create File' ||  CreateModal.data?.oldName.search('.db') !== -1) && <span>.db</span>}
                        </FlexContent>
                        <WarningMsg>{CreateModal['data']['msg']}</WarningMsg>
                        <ModalBottomBox>
                            <BtnSecondary label="Cancel" onClick={CloseModal}/>
                            <BtnPrimary label="Rename" onClick={(e)=>{
                                if (CreateModal['data']['name'] === '') {
                                    setCreateModal(prevState => ({ ...prevState, data: { ...prevState.data, msg: '파일 명을 입력해주세요.' } }))
                                    return;
                                }
                                const {path, name} = CreateModal.data;
                                const filePath = path.substring(0, path.lastIndexOf('\\'));
                                const newName = CreateModal.data.oldName.search('.db') === -1 ? name : `${name}.db`;

                                RenameDocument(filePath, CreateModal.data.oldName, newName)
                                    .then((res) => {
                                        if (res === 'success') {
                                            CloseModal();
                                            GetSQLiteFileList();
                                        } else {
                                            setCreateModal(prevState => ({ ...prevState, data: { ...prevState.data, msg: '이미 사용중인 이름 입니다.' } }))
                                        }
                                    }).catch((err) => {
                                        setCreateModal(prevState => ({ ...prevState, data: { ...prevState.data, msg: err } }))
                                    });
                            }} />
                        </ModalBottomBox>
                    </Modal>
                </CreateModalContent>
            }

            {/* 모달 창 파일 업로드 */}
            {(CreateModal['visibility'] && CreateModal['data']['type']==='Upload File') &&
                <CreateModalContent>
                    <BgPopup onClick={CloseModal}/>
                    <Modal _title={CreateModal['data']['type']} _height={182} _width={200} _enableResizing={false} _closeModal={CloseModal}>
                        <FlexContent>
                            <InputContent type={"file"} accept=".db, .sqlite" onChange={handleOnChange}/>
                        </FlexContent>
                        <WarningMsg>{CreateModal['data']['msg']}</WarningMsg>
                        <ModalBottomBox>
                            <BtnSecondary label="Cancel" onClick={CloseModal} />
                            <BtnPrimary label="Upload" onClick={(e) => {
                                const fileExt = fileData[0].name.split('.').pop();
                                if(fileExt !== 'db'&& 
                                fileExt !== 'sqlite'&&
                                fileExt !== 'sqlitedb' &&
                                fileExt !== 'sqlite3' &&
                                fileExt !== 'db3'
                                ){
                                    setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:'SQLite db파일을 선택해주세요.'}}))
                                    return;
                                }

                                UploadSQLiteFile(fileData, CreateModal['data']['path'])
                                    .then((res) => {
                                        if (res === true) {
                                            CloseModal();
                                            GetSQLiteFileList();
                                        } else {
                                            setCreateModal(prevState => ({ ...prevState, data: { ...prevState.data, msg: '이미 사용중인 이름 입니다.' } }))
                                        }
                                    }).catch((err) => {
                                        setCreateModal(prevState => ({ ...prevState, data: { ...prevState.data, msg: err } }))
                                    });
                            }} />
                        </ModalBottomBox>
                    </Modal>
                </CreateModalContent>
            }
        </div>
    )
}

export default SQLiteContent;

// 스타일-----------------------------------------------------
const CreateModalContent = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 10;
`;

const FlexContent = styled.div`
    display: flex;
    align-items: baseline;

    &>span{
        margin-left: 8px;
    }
`;