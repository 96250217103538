
import {useState, useEffect} from "react";

import {BtnPrimary, BtnSecondary, BtnSubDanger} from "components/atom/Button";
import {isPassword } from 'components/Utility';
import {InputWithVerticalLabel, VerticalLabelInput} from 'components/molecule/InputFieldLabel';
import {Popup, BgPopup, ModalBottomBox, WarningMsg, InputContent} from 'components/css/common'
import useMember, {UploadProfileImage, UpdateAccount, UpdatePassword ,Withdrawal} from 'hooks/useMember';

import styled from "styled-components";
import { MdImage } from "react-icons/md";

export default function MyInfo({_UserInfo, HasAccessPw}){
    const {Logout} = useMember();
    const systemId = localStorage.getItem('systemId');
    const profileId = localStorage.getItem('profileId');

    const [message, setMessage] = useState('');
    const [profileInfo, setProfileInfo] = useState({ visible: false, fileData: '', });
    const [password, setPassword] = useState({ visible: false, CurrentPW: '', NewPW: '', ConfirmNewPW: '', });
    const {EMAIL, MEMBERNAME, NICKNAME} = _UserInfo;
    const [userInfo, setUserInfo] = useState({EMAIL:'', MEMBERNAME:'', NICKNAME:''});

    useEffect(()=>{
        setUserInfo({EMAIL, MEMBERNAME, NICKNAME});
    }, [EMAIL, MEMBERNAME, NICKNAME]);

    useEffect(()=>{
        setMessage('');
    }, [profileInfo, password]);

    const onChangeFile = (e) => {
        setMessage('');
        const Files = e.target.files;
        const maxSize = 20 * 1024 * 1024;  //20MB
        const fileSize = Files[0]?.size;

        if(!Files || !Files[0]){
            document.getElementById('tempProfile').src = '/img/basicProfileImg.jpg';
            setProfileInfo({...profileInfo, fileData: ''});
            return;
        }

        if(fileSize > maxSize){
            setMessage("첨부파일 사이즈는 20MB 이내로 등록 가능합니다.");
            e.target.value = null;
            return
        }

        const reader = new FileReader();
        reader.onload = function(e) {
            document.getElementById('tempProfile').src = e.target.result;
        };
        
        reader.readAsDataURL(Files[0]);
        setProfileInfo({...profileInfo, fileData: Files});
    }

    const UploadProfile= (e)=>{
        UploadProfileImage(systemId, profileInfo.fileData)
            .then(res => {
                const {result, msg} = res['data'];
                if (result=== 'Success') {
                    const reader = new FileReader();
                    reader.onload = function (e) {
                        document.getElementById('preview').src = e.target.result;
                        document.getElementById('HeaderPreview').src = e.target.result;
                    };
                    reader.readAsDataURL(profileInfo.fileData[0]);
                    alert('프로필 이미지 업로드 성공');
                    setProfileInfo({visible: false, fileData: ''});
                } else 
                    setMessage(`profile 이미지 업로드 실패: ${msg}`);
            }).catch(err => {
                setMessage(`profile 이미지 업로드 실패: ${err}`);
            });
    }

    const UpdateUserInfo = (e) => {
        if(userInfo.MEMBERNAME === ''){
            alert('Name을 입력해주세요.');
            return;
        }

        if(userInfo.NICKNAME === ''){
            alert('Nickname을 입력해주세요.');
            return;
        }

        UpdateAccount(systemId, userInfo.MEMBERNAME, userInfo.NICKNAME)
            .then(res => {
                const {result, msg} = res.data;
                if(result === 'Success'){
                    localStorage.setItem('name', userInfo.MEMBERNAME);
                    localStorage.setItem('nickname', userInfo.NICKNAME);

                    alert('회원정보가 변경되었습니다.');
                }else
                    alert('업데이트 실패: ', msg);
            }).catch(err => {
                console.error('회원정보 업데이트 실패: ', err);
            });
    }

    const WithdrawalEvent = (e) => {
        if (window.confirm('정말 탈퇴하시겠습니까?')) {
            Withdrawal(systemId, HasAccessPw)
                .then(res => {
                    console.log(res.data);
                    const { result, msg } = res.data;
                    if (result === "Success") {
                        alert('탈퇴되었습니다.');
                        Logout();
                    } else if (result === "Fail") {
                        alert(msg);
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    const handlerUpdatePassword = (e) => {
        if(password.CurrentPW === ''){
            setMessage('Current Password를 입력해주세요.');
            return;
        }

        if(password.NewPW === ''){
            setMessage('New Password를 입력해주세요.');
            return;
        }

        if(password.ConfirmNewPW === ''){
            setMessage('Confirm New Password를 입력해주세요.');
            return;
        }

        if(password.NewPW !== password.ConfirmNewPW){
            setMessage('New Password와 Confirm New Password가 일치하지 않습니다.');
            return;
        }

        if(!isPassword(password.NewPW)){
            setMessage(`Password는 문자, 숫자를 최소 1개 이상 사용해야합니다. (8자리 이상)`);
            return;
        }

        UpdatePassword(systemId, password.CurrentPW, password.NewPW)
            .then(res => {
                const {result, msg} = res.data;

                if(result === 'Success'){
                    alert('비밀번호가 변경되었습니다.');
                    setPassword({visible: false, CurrentPW: '', NewPW: '', ConfirmNewPW: '',})
                }else
                    setMessage('비밀번호가 변경 실패: ', msg);
            }).catch(err => {
                console.error('비밀번호 변경 실패: ', err);
            });
    }
    

    return(
        <PageSpace>
            <UserInfo>
                <div className='ProfileImgBox'>
                    <img id='preview' src={`/profile/${profileId}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                        <MdImage onClick={(e) => { setProfileInfo({ ...profileInfo, visible: true }); setMessage('') }} />
                </div>

                <div className='UserInfoBox'>
                    <VerticalLabelInput
                        label='Email'
                        isRequired={true}
                        value={userInfo.EMAIL}
                        disabled={true}
                    />
                    <VerticalLabelInput
                        label='Name'
                        placeholder='이엔유'
                        isRequired={true}
                        value={userInfo.MEMBERNAME}
                        onChangeEvent={(MEMBERNAME) => {
                            setUserInfo(prev => ({ ...prev, MEMBERNAME }))
                        }}
                    />
                    <VerticalLabelInput
                        label='Nickname'
                        isRequired={true}
                        value={userInfo.NICKNAME}
                        onChangeEvent={(NICKNAME) => {
                            setUserInfo(prev => ({ ...prev, NICKNAME }))
                        }}
                    />
                    <ModalBottomBox justifyType="space-between">
                        <BtnSubDanger label="탈퇴" onClick={WithdrawalEvent} />
                        <div>
                            <BtnSecondary label="비밀번호 변경" onClick={(e) => setPassword({ visible: true, CurrentPW: '', NewPW: '', ConfirmNewPW: '', })} />
                            <BtnPrimary label="Update" onClick={UpdateUserInfo} />
                        </div>
                    </ModalBottomBox>
                </div>
            </UserInfo>

            {/* 회원 프로필 이미지 변경 modal */}
            {profileInfo.visible &&
                <ProfileImageModal>
                    <BgPopup onClick={(e) => setProfileInfo({ ...profileInfo, visible: false })} />
                    <div className="PopupWrap">
                        <div id='tempProfileBox'>
                            <img id='tempProfile' src={`/profile/${profileId}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                        </div>
                        <InputContent style={{ width: '100%' }} type={"file"}  accept=".jpg, .jpeg, .png" onChange={onChangeFile} />

                        <WarningMsg >{message}</WarningMsg>
                        <ModalBottomBox>
                            <BtnSecondary label="Cancel" onClick={(e) => setProfileInfo({ ...profileInfo, visible: false })} />
                            <BtnPrimary label="Update" onClick={UploadProfile} />
                        </ModalBottomBox>
                    </div>
                </ProfileImageModal>
            }

            {/* 비밀번호 변경 modal */}
            {password.visible &&
                <PasswordModal>
                    <BgPopup onClick={(e) => setPassword({ ...password, visible: false })} />
                    <div className="PopupWrap">
                        <InputWithVerticalLabel id='CurrentPW' label='Current Password' inputType="password" propsState={[password, setPassword]} placeholder='Current Password' isRequired={true} />
                        <InputWithVerticalLabel id='NewPW' label='New Password' inputType="password" propsState={[password, setPassword]} placeholder='New Password' isRequired={true} />
                        <InputWithVerticalLabel id='ConfirmNewPW' label='Confirm New Password' inputType="password" propsState={[password, setPassword]} placeholder='Confirm New Password' isRequired={true} />

                        <WarningMsg >{message}</WarningMsg>
                        <ModalBottomBox>
                            <BtnSecondary label="Cancel" onClick={(e) => setPassword({ ...password, visible: false })} />
                            <BtnPrimary label="Update" onClick={handlerUpdatePassword} />
                        </ModalBottomBox>
                    </div>
                </PasswordModal>
            }
        </PageSpace>
    )
}

const PageSpace = styled.div`
    position: relative;
    width: 100%;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;

const UserInfo = styled(Content)`
    padding-top: 40px;
    align-items: center;
    user-select: none;

    .ProfileImgBox{
        position: relative;
        height: 100px;
        width: 100px;

        img{
            height: 100%;
            width: 100%;
            border-radius: 50%;
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            background-color: #FFF;
            box-sizing: border-box;
        }

        svg{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 30px;
            height: 30px;
            padding: 4px;
            border-radius: 50%;
            color: ${({ theme }) => theme.base.font_color_DarkBlue};
            background-color: #FFF;
            border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
            box-sizing: border-box;
            cursor: pointer;

            &:hover{
                background-color: ${({ theme }) => theme.base.background_color_DarkBlue};
                border-color: ${({ theme }) => theme.base.border_color_DarkBlue};
                color: #FFF;
            }
        }
    }

    .UserInfoBox{
        margin-top: 24px;
        width: 100%;
    }
`;

const ProfileImageModal = styled(Popup)`
    position: fixed;
    top: 72px;
    width: 100%;
    height: calc(100% - 72px);
    overflow: hidden;
    z-index: 15;
    
    .PopupWrap{
        width: 400px;
        padding: 24px;

        #tempProfileBox{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;

            &>img{
                width: 100px;
                height: 100px;
                border-radius: 50%;
                border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
                background-color: #FFF;
                box-sizing: border-box;
            }
        }
    }
`
const PasswordModal = styled(Popup)`
    position: fixed;
    top: 72px;
    width: 100%;
    height: calc(100% - 72px);
    overflow: hidden;
    z-index: 15;

    .PopupWrap{
        width: 400px;
        padding: 24px;
    }
`;
