import PropertiesContent from "components/atom/PropertiesContent";
import {SvgDropdown} from "components/atom/ImageDropdown";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";

import {
    EnuIconStrokeMiter, 
    EnuIconStrokeBevel, 
    EnuIconStrokeRound, 
    EnuIconStrokeStrokeLinecapRound, 
    EnuIconStrokeStrokeLinecapSquare, 
    EnuIconStrokeStrokeLinecapButt,
} from 'components/common/EnuSpaceIcon'

// StrokeStyleSelect 목록에 사용되는 이미지
const linecap = {
    butt : <EnuIconStrokeStrokeLinecapButt />,
    square : <EnuIconStrokeStrokeLinecapSquare />,
    round : <EnuIconStrokeStrokeLinecapRound />,
};

const linejoin = {
    miter : <EnuIconStrokeMiter />,
    round : <EnuIconStrokeRound />,
    bevel : <EnuIconStrokeBevel />
};

export default function BrushPenInputList({_data, _ChangeEvent, _ChangePickerData,  _disabled}){
    
    const{
        stroke_width: NodeStrokeWidth,
        stroke_dasharray: NodeStrokeDasharray,
        stroke_linecap: NodeStrokeLinecap,
        stroke_linejoin: NodeStrokeLinejoin
    }=_data;

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };
    
    return(
        <PropertiesContent _Title="Brush & Pen">
            { _data.fill &&
                <div>
                    <ColorComponent label="fill" changePickerEvent={_ChangePickerData} {...commonProps}/>
                </div>
            }
            <div>
                <ColorComponent label="stroke" changePickerEvent={_ChangePickerData} {...commonProps}/>
            </div>

            <div style={{'gap': '6px'}}>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='stroke-width' valueType='number' labelWidth='24px' value={NodeStrokeWidth} {...commonProps}
                />
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_dasharray.png" alt="stroke_dasharray" />}
                    attributeName='stroke-dasharray' valueType='number' labelWidth='24px' value={NodeStrokeDasharray} {...commonProps}
                />
                <SvgDropdown attributeName="stroke-linecap" optionMap={linecap} value={NodeStrokeLinecap} {...commonProps}/>
                <SvgDropdown attributeName="stroke-linejoin" optionMap={linejoin} value={NodeStrokeLinejoin} {...commonProps}/>
            </div>
        </PropertiesContent>
    )
}