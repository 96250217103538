import { useState } from 'react';

import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {ModalBottomBox} from "components/css/common";
import useModalPopup from "hooks/useModalPopup"

import styled from "styled-components";

function InputBox({enuSpace, _Modal}){
    const {DeleteModal} = useModalPopup();
    const [EditValue, setEditValue] = useState(_Modal.data.targetData.text);

    const CloseModal = (e) => DeleteModal(_Modal.id);

    const ChangeEvent = (e) => {
        setEditValue(e.target.value);
    }

    const setEditBox = (e) => {
        enuSpace.SetNodeData(enuSpace.GetClassPointer(_Modal['data']['targetData']['addr']), 'text', EditValue);
        CloseModal();
    }

    return(
        <div>
            <FlexContent>
                <InputContent value={EditValue}  onChange={ChangeEvent}/>
            </FlexContent>
            <ModalBottomBox>
                <BtnSecondary label="Cancel" onClick={CloseModal}/>
                <BtnPrimary label="Ok" onClick={setEditBox}/>
            </ModalBottomBox>
        </div>
    )
}

export default InputBox;

//스타일--------------------------------------------
const FlexContent = styled.div`
    display: flex;
    align-items: baseline;

    &>span{
        margin-left: 8px;
    }
`;

const InputContent = styled.input`
    margin-top: 8px;
    height: auto;
    width: 100%;
    border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    border-radius: 2px;
    padding: 4px 2px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    flex-grow: 1;

    &:focus{
        outline:none;
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
    }
    
    &:disabled{
        padding: 0;
        background-color: transparent;
        border: 0 none;
    }
`;