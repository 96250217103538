import {useState, useEffect, useContext} from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';
import {ProcessArrayInput} from "components/Utility" 
import { TransientModalContext } from "context/TransientModalContext";

import { MdOutlineArrowDropDown} from "react-icons/md";

const useDropdown = ({ _id, _imageList, _value, _disabled, _ChangeEvent }) => {
    const [isOption, setIsOption] = useState(false);
    const [selectValue, setSelectValue] = useState();

    useEffect(() => {
        if (_value === undefined)
            setSelectValue(Object.keys(_imageList)[0]);
        else
            setSelectValue(_value);
    }, [_value, _imageList]);

    const openOption = (e) => {
        if (selectValue === "" || _disabled) return;
        setIsOption(!isOption);
    }

    const selectItem = (value) => {
        setSelectValue(value);
        const JsonData = [{ attributeName: _id, attributeValue: value }];
        _ChangeEvent(JsonData);
    }

    return {
        _id,
        isOption,
        selectValue,
        openOption,
        selectItem,
        setIsOption,
        disabled: _disabled && "disable"
    };
}

export default function ImageDropdown(props){
    const { _id, isOption, selectValue, openOption, selectItem, disabled, setIsOption } = useDropdown(props);

    return (
        <Select onClick={openOption} title={_id} data-value={selectValue} disabled={disabled}>
            <img src={props._imageList[selectValue]} alt={selectValue} />
            <span><MdOutlineArrowDropDown style={{ opacity: `${props._disabled ? .3 : 1}` }} /></span>
            {isOption &&
                <SelectList>
                    <ul>
                        {
                            Object.keys(props._imageList).map((data) => (
                                <li key={data} onClick={() => selectItem(data)}>
                                    <img src={props._imageList[data]} alt={data} data-type={props._id} data-value={data} title={data} />
                                </li>
                            ))
                        }
                    </ul>
                </SelectList>
            }
            {isOption && <BgSelect onClick={(e) => setIsOption(false)}></BgSelect>}
        </Select>
    );
}

export function SvgDropdown({attributeName, value, optionMap, changeEvent, disabled}){
    const [selectValue, setSelectValue] = useState();
    const {TransientModalInfo, setTransientModalInfo} = useContext(TransientModalContext);

    useEffect(() => {
        setSelectValue(ProcessArrayInput(value));
    }, [value, optionMap]);

    return(
        <SelectContent
            title={attributeName}
            disabled={disabled}
            onClick={(e) => {
                if (disabled) return;
                e.stopPropagation();
                setTransientModalInfo({Id:attributeName})
            }}
        >
            {selectValue === "Mixed" ? <span className="mixed">-</span> : optionMap[selectValue]}
            <span><MdOutlineArrowDropDown /></span>
            {TransientModalInfo?.Id === attributeName &&
                <SelectList onMouseDown={(e) => e.stopPropagation()}>
                    <ul>
                        {Object.entries(optionMap)?.map(([key, value]) => (
                            <li key={key}
                                className={selectValue === key ? 'selected' : null}
                                onClick={(e) => {
                                    changeEvent && changeEvent([{ attributeName, attributeValue: key }]);
                                    setTransientModalInfo();
                                    e.stopPropagation();
                                }}
                            >
                                {value}
                            </li>
                        ))

                        }
                    </ul>
                </SelectList>
            }
        </SelectContent>
    )
}

ImageDropdown.prototype = {
    onClick: PropTypes.func,
}

// 스타일-----------------------------------------------------
const Select = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    box-sizing: border-box;
    margin-left: 4px;
    height: 22px;
    border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    border-radius: 2px;
    cursor: ${(props)=>props.disabled?'default':'pointer'};

    &.disable, &.disable:hover{
        border: 1px solid white;
    }
    
    &:hover{
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
    }
    
    &>span{
        color: ${({theme}) => theme.base.font_color_DarkGray};
        font-size: medium;
    }
`;

const SelectList = styled.div`
    position: absolute;
    width: 100%;
    top: 22px;
    left: 0;
    border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    border-radius: 4px;
    background-color: #FFF;
    z-index: 9;
    box-shadow: rgba(0, 0, 0, .15) 0px 8px 16px;

    &>ul >li >img, svg{
        padding: 4px 8px;

        &:hover {
            background-color : ${({theme}) => theme.base.background_color_LightBlue};
        }
    }

    .selected{
        background-color : ${({theme}) => theme.base.background_color_LightBlue};
    }
`;

const BgSelect = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 8;
`;

const SelectContent = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 22px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ disabled, theme}) => disabled ? 'white' :theme.base.border_color_LightGray};
    border-radius: 2px;
    cursor: ${({disabled})=> disabled ? 'default' :'pointer'};

    &:hover{
        border-color: ${({ disabled, theme}) => disabled ? 'white' :theme.base.border_color_Blue};
    }

    &>span{
        color: ${({theme}) => theme.base.font_color_DarkGray};
        font-size: medium;
        opacity:  ${({disabled})=> disabled ? .3 : 1};
    }

    .mixed{
        position: relative;
        width: 16px;
        height: 16px;
        text-align: center;
    }
`;