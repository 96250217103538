export const PrivacyPolicy = [
    {
        "article": "제 1조 목적",
        "content": "이엔유주식회사(이하 '회사'라고 함)는 다음의 목적을 위하여 최소한의 개인정보를 수집하여 처리합니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.",
        "subItems": [
            {
                "term": "고객문의 처리 : 고객의 신원 확인, 요청 · 문의사항 확인, 사실조사를 위한 연락 · 통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다."
            },
            {
                "term": "법위반 제보 처리 : 법위반 제보 민원 처리 및 결과 회신을 위해 개인정보를 처리합니다."
            }
        ]
    },
    {
        "article": "제 2조 개인정보의 제3자 제공",
        "content": "회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다."
    },
    {
        "article": "제 3조 개인정보 수집 정보",
        "subItems": [
            {
                "term": "회사는 이용자에게 회사의 서비스를 제공하기 위하여 다음과 같은 정보를 수집합니다.",
                "subMote": "- 필수 수집정보: 아이디, 비밀번호, 이메일 주소, 이름, 생년월일 및 연락처"
            },
            {
                "term": "회사는 이용자의 서비스 이용 및 부정이용의 확인 및 분석을 위하여 다음과 같은 정보를 수집합니다.",
                "subMote": "- 필수 수집 정보: 서비스 이용기록, 쿠기, 접속지 정보 및 기기정보"
            }
        ],
        "warring":"*부정이용: 회원 탈퇴 후 재가입, 상품구매 후 구매취소 등을 반복적으로 행하는 등 회사가 제공하는 경제상 이익을 불⦁편법적으로 수취하는 행위, 이용약관 등에서 금지하고 있는 행위, 명의도용 등의 불⦁편법행위 등을 말합니다. 수집되는 정보는 회사 서비스 이용에 따른 통계⦁분석에 이용될 수 있습니다."
    },
    {
        "article": "제 4조 개인정보 수집 방법",
        "content": "회사는 다음과 같은 방법을 이용자의 개인정보를 수집합니다.",
        "subItems": [
            {
                "term": "이용자가 회사의 홈페이지에 자신의 개인정보를 입력하는 방식"
            },
            {
                "term": "어플리케이션 등 회사가 제공하는 홈페이지 외의 서비스를 통해 이용자가 자신의 개인정보를 입력하는 방식"
            },
            {
                "term": "이용자가 제공하는 개인정보에 대해 명시적으로 동의하는 방식"
            }
        ]
    },
    {
        "article": "제 5조 개인정보의 이용",
        "content": "회사는 개인정보를 다음 각 호의 경우에 이용합니다.",
        "subItems": [
            {
                "term": "이용문의에 대한 회신, 불만의 처리 등 이용자에 대해 서비스 개선을 위한 경우"
            },
            {
                "term": "회사의 서비스를 제공하기 위한 경우"
            },
            {
                "term": "신규 서비스 개발을 위한 경우"
            },
            {
                "term": "인구통계학적 분석, 서비스 방문 및 이용기록의 분석을 위한 경우"
            },
            {
                "term": "개인정보 및 관심에 기반한 이용자간 관게의 형성을 위한 경우"
            },
            {
                "term": "법령 및 회사 약관을 위한하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재를 위한 경우"
            }
        ]
    },
    {
        "article": "제 6조 개인정보 보유 및 이용 기간",
        "subItems": [
            {
                "term": "회사는 이용자의 개인정보에 대해 개인정보의 수집⦁이용 목적이 달성을 위한 기간 동안 개인정보를 보유 및 이용합니다."
            },
            {
                "term": "전항에도 불구하고 회사는 내부 방침에 의해 서비스 부정이용기록은 부정 가입 및 이용 방지를 위하여 회원 탈퇴 시점으로부터 최대 1년간 보관합니다."
            }
        ]
    },
    {
        "article": "제 7조 법령에 따른 개인정보 보유 및 이용기간",
        "content": "회사는 관계 법령에 따라 다음과 같인 개인정보를 보유 및 이용합니다.",
        "subItems": [
            {
                "term": "전자상거래 등에서의 소비자보호에 관한 법률에 따른 보유정보 및 보유기간",
                "subDefinitions":[
                    "계약 또는 청약철회 등에 관한 기록: 5년",
                    "대금결제 및 재화 등의 공급에 관한 기록: 5년",
                    "소비자의 불만 또는 분쟁처리에 관한 기록: 3년",
                    "표시⦁광고에 관한 기록: 6개월"
                ]
            },
            {
                "term": "통신비밀보호법에 따른 보유정보 및 보유기간",
                "subDefinitions":["웹사이트 로그 기록 자료: 3개월"]
            },
            {
                "term": "전자금융거래법에 따른 보유정보 및 보유기간",
                "subDefinitions":["전자금융거래에 관한 기록: 5년"]
            },
            {
                "term": "위치정보의 보호 및 이용 등에 관한 법률",
                "subDefinitions":["개인위치정보에 관한 기록: 6개월"]
            }
        ]
    },
    {
        "article": "제 8조 개인정보의 파기",
        "subItems": [
            {
                "term": "회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다."
            },
            {
                "term": "개인정보 파기의 절차 및 방법은 다음과 같습니다.",
                "subDefinitions":[
                    "파기절차 : 회사 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 책임 하에 내부방침 절차에 따라 개인정보를 파기합니다.",
                    "파기방법 : 회사는 전자적 파일 형태로 기록 · 저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다."
                ]
            }
        ]
    },
    {
        "article": "제 9조 서비스미용자에 대한 개인정보처리",
        "subItems": [
            {
                "term": "회사는 1년 동안 회사의 서비스를 이용하지 않은 이용자의 개인정보는 원칙적으로 이용자에게 사전통지하고 개인정보를 파기하거나 별도로 분리하여 저장합니다."
            },
            {
                "term": "회사는 자익 미이용 이용자의 개인정보는 별도로 분리되어 안전하게 보관되며, 해당 이용자의 통지는 분리 보관 처리 일을 기준으로 최소 30일 이전에 전자우편주소로 전송됩니다."
            },
            {
                "term": "장기 미이용 이용자는 회사의 미이용자 DB를 별도로 분리하기 전에 계속 서비스를 이용하고자 하는 경우 웹사이트에 로그인하시면 됩니다"
            },
            {
                "term": "장기 미이용 이용자는 웹사이트에 로그인할 경우 이용자의 동의에 따라 본인의 계정을 복원할 수 있습니다."
            },
            {
                "term": "회사는 분리 보관된 개인정보를 4년간 보관후 지체없이 파기합니다."
            }
        ]
    },
    {
        "article": "제 10조 정보주체와 법정대리인의 권리·의무 및 행사방법",
        "subItems": [
            {
                "term": "정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다."
            },
            {
                "term": "제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다."
            },
            {
                "term": "제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다."
            },
            {
                "term": "개인정보 열람 및 처리정지 요구는 개인정보 보호법 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다."
            }
            ,{
                "term": "개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다."
            },
            {
                "term": "회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다."
            }
        ]
    },
    {
        "article": "제 11조 이용자의 의무",
        "subItems": [
            {
                "term": "이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다."
            },
            {
                "term": "타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다."
            },
            {
                "term": "이용자는 전자우편주소, 비밀번호 등에 대한 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다."
            }
        ]
    },
    {
        "article": "제 12조 개인정보의 안전성 확보 조치",
        "content": "회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.",
        "subItems": [
            {
                "term": "관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등"
            },
            {
                "term": "기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치"
            },
            {
                "term": "물리적 조치 : 전산실, 자료보관실 등의 접근통제"
            }
        ]
    },
    {
        "article": "제 13조(개인정보 유출 등에 대한 조치)",
        "content": "회사는 개인정보의 분실·도난·유출(이하 \"유출 등\"이라 한다) 사실을 안 때에는 지체 없이 다음 각 호의 모든 사항을 해당 이용자에게 알리고 방송통신위원회 또는 한국인터넷진흥원에 신고합니다.",
        "subItems": [
            {
                "term": "유출 등이 된 개인정보 항목"
            },
            {
                "term": "유출 등이 발생한 시점"
            },
            {
                "term": "이용자가 취할 수 있는 조치"
            },
            {
                "term": "정보통신서비스 제공자 등의 대응 조치"
            },
            {
                "term": "이용자가 상담 등을 접수할 수 있는 부서 및 연락처"
            }
        ]
    },
    {
        "article": "제 14조(개인정보 유출 등에 대한 조치의 예외)",
        "content": "회사는 전조에도 불구하고 이용자의 연락처를 알 수 없는 등 정당한 사유가 있는 경우에는 회사의 홈페이지에 30일 이상 게시하는 방법으로 전조의 통지를 갈음하는 조치를 취할 수 있습니다."
    },
    {
        "article": "제 15조(이용자의 쿠키 설치 선택권)",
        "subItems": [
            {
                "term": "이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다."
            },
            {
                "term": "다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스는 이용에 어려움이 있을 수 있습니다."
            }
        ]
    },
    {
        "article": "제 16조(쿠키 설치 허용 지정 방법)",
        "content": "쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.",
        "subItems": [
            {
                "term": "[도구] 메뉴에서 [인터넷 옵션]을 선택합니다."
            },
            {
                "term": "[개인정보 탭]을 클릭합니다."
            },
            {
                "term": "[고급] 설정에서 설정하시면 됩니다."
            }
        ]
    },
    {
        "article": "제 17조(회사의 개인정보 보호 책임자 지정)",
        "content": "회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.",
        "note" : ["개인정보 보호 책임자", "성명: 김성호", "전화번호: 070-4244-2268", "아매알: master@enu-tech.co.kr"]
    }
]