import { useState, useEffect, useCallback } from "react";

import {BtnGhost, BtnSecondary} from "components/atom/Button";
import NewCanvasFormModal from "components/modal_new/NewCanvasFormModal";
import {ContentPage, ContentSectionHeader, ContentSection, NewContentBox} from "components/css/common";
import {GetEnuCanvasList, DeleteEnuCanvas} from 'api/EnuCanvasApi'
import { RepositoryCard} from 'components/molecule/ProjectCard'
import { PUBLISH_TYPE } from "components/common/EnuSpaceEnum";

import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { MdInsertLink } from "react-icons/md";

export default function CanvasRepository(){
    const navigate = useNavigate();
    const userId = localStorage.getItem('systemId');
    const userNickname = localStorage.getItem('nickname');
    
    const [CanvasList, setCanvasList] = useState([])
    const [newCanvasForm, setNewCanvasForm] = useState({ visibility: false,});

    const CanvasListLoad = useCallback(() => {
        GetEnuCanvasList(userId)
            .then(res => {
                const {result, data} = res.data;
                if(result=== 'Success')
                    setCanvasList(data);
                else
                    setCanvasList([]);
            })
            .catch(err => {
                window.AddAlertMsg({type:'error', msg:`Canvas 리스트 로딩에 실패했습니다. : ${err}`});
            });
    }, [userId]);

    useEffect(()=>{
        CanvasListLoad();
    }, [CanvasListLoad]);

    const OpenNewCanvas = (e) => {
        setNewCanvasForm({
            userId,
            userNickname,
            visibility: true,
            CanvasName: '',
            description: '',
            publishType: PUBLISH_TYPE.PRIVATE,
            mode: 'new',
        });
    };

    const CanvasEdit = (Info) => {
        const {
            ENUCANVAS_NAME: CanvasName, 
            DESCRIPTION: description, 
            PUBLISH_MODE: publishType, 
            ENUCANVAS_SYSTEMID: eunCanvasId,
        } = Info;

        setNewCanvasForm({
            userId,
            userNickname,
            visibility: true,
            CanvasName,
            description,
            publishType,
            eunCanvasId,
            mode: 'edit',
            originData:{
                CanvasName,
                description,
                publishType
            }
        });
    }

    const StartCanvas = (canvasId, canvasName) => {
        localStorage.setItem('publishId', canvasId); 
        localStorage.removeItem('projectId', );

        navigate('/RunEnuCanvas', {
            state: {
                userId,
                userNickname,
                canvasId,
                canvasName,
            }
        });
    }
    
    return(
        <>
        {newCanvasForm.visibility && 
            <NewCanvasFormModal 
                StateData={[newCanvasForm, setNewCanvasForm]} 
                CreateCanvas={StartCanvas}
                ReloadEvent={CanvasListLoad}
            />
        }

        <ContentPage>
            <ContentSectionHeader>
            </ContentSectionHeader>
            <ContentSection>
                <NewContentBox>
                    <BtnGhost label="New Canvas" onClick={OpenNewCanvas} />
                </NewContentBox>

                {CanvasList &&
                    CanvasList.map((CanvasInfo, index) => {
                        const {ENUCANVAS_SYSTEMID, PUBLISH_MODE, PROFILE_SYSTEMID, ENUCANVAS_NAME, NICKNAME, CREATED_STAMP, THUMBNAIL} = CanvasInfo;
                        const publishType = Object.keys(PUBLISH_TYPE).find(key => PUBLISH_TYPE[key] === PUBLISH_MODE);

                        const DeleteProjectEvent = (e) =>{
                            if(window.confirm(`"${ENUCANVAS_NAME}" 을 삭제하시겠습니까?`)){
                                DeleteEnuCanvas(userId, ENUCANVAS_SYSTEMID)
                                    .then(res=>{
                                        const {result, msg} = res.data;
                                        if(result === "Success"){
                                            CanvasListLoad();
                                        }else{
                                            window.AddAlertMsg({type:'error', msg});
                                        }
                                    }).catch(err=>{
                                        window.AddAlertMsg({type:'error', msg:`삭제에 실패했습니다. : ${err}`});
                                    })
                            }
                        }

                        return(
                            <RepositoryCard 
                                key={ENUCANVAS_SYSTEMID}
                                PublishType={publishType}
                                ThumbnailSrc={`data:image/jpeg;base64,${THUMBNAIL}`}
                                ProfileId={PROFILE_SYSTEMID}
                                ProjectName={ENUCANVAS_NAME}
                                Creator={NICKNAME}
                                Date={CREATED_STAMP}
                                EventOption={(e)=>CanvasEdit(CanvasInfo)}
                                EventDelete={DeleteProjectEvent}
                                Buttons={
                                    <>
                                        <ButtonContent 
                                            className='btn_link' 
                                            type='sm' 
                                            title='link' 
                                            label={<MdInsertLink />} 
                                            onClick={(e) => {
                                                navigator.clipboard.writeText(`https://${window.location.host}/Canvas/link?${ENUCANVAS_SYSTEMID}&${ENUCANVAS_NAME}&0`);
                                                window.AddToastMsg(`link Copy Success`);
                                            }} 
                                        />
                                        <ButtonContent 
                                            type='sm'
                                            title='Run'
                                            label='Run' 
                                            onClick={(e)=>StartCanvas(ENUCANVAS_SYSTEMID, ENUCANVAS_NAME)} 
                                        />
                                    </>
                                }
                            />
                        )
                    })
                }
            </ContentSection>
        </ContentPage>
        </>
    )
}

const ButtonContent = styled(BtnSecondary)`
    padding: 4px 8px !important;
    height: 30px !important;
    min-width: 0;

    &.btn_link{
        padding: 0  4px!important;

        &:hover{
            border-color: ${({theme}) => theme.base.border_color_Blue};
            background-color: ${({theme}) => theme.base.background_color_LightBlue};
        }
        svg{
            font-size: 1.3rem;
            color:  ${({theme}) => theme.base.border_color_DarkBlue};
        }
    }
`;