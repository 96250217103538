import {useState, useEffect} from 'react';

import {BtnSecondary, BtnGhost} from "components/atom/Button";
import {DatabaseAuthorization} from 'components/modal_new/DatabaseModals'
import { InputContent, GroupName} from 'components/css/common';
import {GetAPIKey, RegenerateAPIKey} from 'api/DeviceApi'

import styled from "styled-components"
import { MdReplay, MdSupervisorAccount} from "react-icons/md";

export default function DBTagPageHeader({userId, email, lever, deviceInfo}){
    const { DB_TABLE_NAME, TABLE_ID } = deviceInfo;
    const [ApiKey, SetApiKey] = useState('');
    const [isShowModal, setIsShowModal] = useState();

    useEffect(() => {
        GetAPIKey(userId, TABLE_ID)
            .then((res) => {
                const { RESULT, MSG, DATA } = res;
                RESULT === 'Ok' ? SetApiKey(DATA) : alert(MSG)
            })
            .catch((err) => alert(err));
    }, [userId, TABLE_ID]);

    return (
        <Header>
            <div id="TableName">
                <span>{DB_TABLE_NAME || ''}</span>
            </div>
            <div className="APiKeyBox">
                <GroupName width={70}>ApiKey</GroupName>
                <InputContent name="Tag" value={ApiKey} title={ApiKey} onChange={null} readOnly={true} disabled={true} />
                {['Manage', 'Master'].includes(lever) &&
                    <BtnSecondary
                        label={<MdReplay />}
                        onClick={(e) => {
                            RegenerateAPIKey(userId, TABLE_ID)
                                .then((res) => {
                                    const { RESULT, MSG, DATA } = res;
                                    RESULT === 'Ok' ? SetApiKey(DATA) : alert(MSG)
                                })
                                .catch((err) => alert(err))
                        }}
                    />
                }
                {['Manage', 'Master'].includes(lever) &&
                    <BtnGhost
                        label={<MdSupervisorAccount />}
                        style={{ marginLeft: '8px' }}
                        onClick={(e) => setIsShowModal(userId)}
                    />
                }
            </div>
            {isShowModal && <DatabaseAuthorization userInfo={{ userId, email }} tableId={TABLE_ID} closeEvent={(e) => setIsShowModal()} />}
        </Header>
    )
}

const Header = styled.div`
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    #TableName {
        font-size: 1.3rem;
        font-weight: bold;
    }

    .APiKeyBox{
        display: flex;
        align-items: center;
        gap: 8px;
        width: 480px;
    }
`;