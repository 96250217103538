import { useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';
import {AccessAxios} from 'components/Axios';

function useHdf5() {
      const {userId: MEMBER_SYSTEMID, projectId: PROJECT_SYSTEMID} = useSelector(state => state.projectData.BASE_DATA);
      const [Hdf5List, setHdf5List] = useState([]);
      const Project = localStorage.getItem('projectId');

      const GetUserDBList = useCallback(async () => {
            if(!MEMBER_SYSTEMID || !Project ) return;

            const MSG = {
                  MEMBER_SYSTEMID,
                  PROJECT_SYSTEMID : Project,
                  filE_EXT: ['h5'],
            }

            AccessAxios.post('/api/HDF5/GetHDF5FileList', MSG)
                  .then(res => {
                        setHdf5List(res.data['Data']);
                  }).catch(err => {
                        window.AddAlertMsg({type:'error', msg:err});
                  })
      }, [MEMBER_SYSTEMID, Project]);

      useEffect(() => {
            GetUserDBList();
      }, [GetUserDBList]);

      const GetHDF5Hierachy = async (_fileName) => {
            const MSG = {
                  MEMBER_SYSTEMID,
                  PROJECT_SYSTEMID,
                  HIERACHY: "/",
                  filE_NAME: _fileName
            }

            return await AccessAxios.post('/api/HDF5/GetHDF5HierachyByJson', MSG)
                  .then(res => {
                        return res;
                  }).catch(err => {
                        throw err;
                  })
      }

      const AddHDF5Data = (_arrPath, _newData) => {
            const clonedHdf5List = JSON.parse(JSON.stringify(Hdf5List));
            const targetChild = FindHdf5Node(clonedHdf5List[0], _arrPath);;

            if (targetChild && _newData['child']) {
                  targetChild.child =[..._newData['child']];
                  setHdf5List(clonedHdf5List);
            }
            return clonedHdf5List;
      }

      const FindHdf5Node = (node, arrPath) => {
            const [currentNodeTitle, ...remainingPath] = arrPath;

            if (node.title === currentNodeTitle) {
                  if (!remainingPath.length)
                        return node;

                  const child = node.child || [];
                  for (const subNode of child) {
                        const result = FindHdf5Node(subNode, remainingPath);
                        if (result) {
                              return result;
                        }
                  }
                  for (let i = 0; i < node.child.length; i++) {
                        const child = node.child[i];
                        const result = FindHdf5Node(child, remainingPath);
                        if (result)
                              return result;
                  }
            }
            return null;
      }

      return {Hdf5List, GetHDF5Hierachy, AddHDF5Data, setHdf5List, GetUserDBList, FindHdf5Node};
}

export default useHdf5;