import styled from "styled-components";
import { MdOutlineClose, MdPlayCircleOutline, MdMoreHoriz } from "react-icons/md";

export function MainProjectCard({thumbnailImgSrc, profileID, projectTitle, creator, publishStamp, playRunTimeEvent, children}) {
    return (
        <MainCred onClick={playRunTimeEvent}>
            <MdPlayCircleOutline className='Btn_play' />
            <div className="projectImgBox" >
                <img src={thumbnailImgSrc} alt="" onError={(e) => { e.target.style.display = 'none' }} />
            </div>
            <CardInfo>
                <MainInfo>
                    <img src={`/profile/${profileID}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                    <div className='title'>
                        <div className="projectTitle">{`${projectTitle}`}</div>
                        <div className="creator">{`${creator}`}</div>
                    </div>

                </MainInfo>
                <div className='subInfo'>
                    <div className="projectDate">
                        <span>{publishStamp && publishStamp.substring(0, publishStamp.indexOf('T'))}</span>
                        <span>{publishStamp && publishStamp.substring(publishStamp.indexOf('T') + 1)}</span>
                    </div>
                    {children &&
                        <div className='buttons'>
                            {children}
                        </div>
                    }
                </div>
            </CardInfo>
        </MainCred>
    )
}

export function DBCard({profileID, title, creator, activeToggle, accessLevel, publishStamp, children , ...props}){
    return(
        <DBCredContent {...props}>
            <CardInfo>
                <MainInfo>
                    <img src={`/profile/${profileID}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                    <div className='title'>
                        <div className="projectTitle">{title}</div>
                        <div className="creator">{creator}</div>
                    </div>
                </MainInfo>
                <div>
                    <div className={`accessLevel ${accessLevel}`}>
                        {accessLevel}
                    </div>
                    <div className='subInfo'>
                        <div className="projectDate">
                            <span>{publishStamp && publishStamp.substring(0, publishStamp.indexOf('T'))}</span>
                            <span>{publishStamp && publishStamp.substring(publishStamp.indexOf('T') + 1)}</span>
                        </div>
                        {children &&
                            <div className='buttons'>
                                {children}
                            </div>
                        }
                    </div>
                </div>
            </CardInfo>
        </DBCredContent>
    )
}

export function RepositoryCard({PublishType, AccessType, ThumbnailSrc, RenderingType, ProfileId, ProjectName, Creator, Date, EventOption, EventDelete, Buttons}){

    return(
        <Card title={ProjectName}>
            <div className='overMenu'>
                {EventOption && <MdMoreHoriz className='btn btnOption' title="Option" onClick={EventOption}/>}
                {EventDelete && <MdOutlineClose className='btn btnDeleted' title="Delete" onClick={EventDelete} />}
            </div>
            {PublishType && 
                <PublishLabel
                    isThumbnail={ThumbnailSrc}
                    className={PublishType?.toUpperCase()}
                >
                    {PublishType?.toLowerCase()}
                </PublishLabel>
            }
            {ThumbnailSrc &&
                <ThumbnailImg>
                    <img src={ThumbnailSrc} alt="" onError={(e) => { e.target.style.display = 'none' }} />
                </ThumbnailImg>
            }
            <CardInfo>
                {RenderingType && 
                    <div className="renderingType" data-content='3D'></div>
                }
                <MainInfo>
                    <img src={`/profile/${ProfileId}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                    <div className='title'>
                        <div className="projectTitle">{ProjectName}</div>
                        <div className="creator">{Creator}</div>
                    </div>
                    {AccessType &&
                        <div className={`accessType ${AccessType}`}>{AccessType}</div>
                    }
                </MainInfo>
                <div className='subInfo'>
                    <div className="projectDate">
                        <span>{Date}</span>
                    </div>
                    {Buttons &&
                        <div className='buttons'>
                            {Buttons}
                        </div>
                    }
                </div>
            </CardInfo>
        </Card>
    )
}

const Card = styled.div`
    position: relative;
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
    border-radius: 8px;
    box-sizing: border-box;
    min-width: 240px;
    height: auto;
    overflow: hidden;

    @media screen and (min-width:1024px) {
        width: calc(100% / 4 - 12px);
    } 
    
    @media screen and (max-width:1090px) {
        width: calc(100% / 3 - 11px);
    } 

    @media screen and (max-width:850px) {
        width: calc(100% / 2 - 8px);
    }

    @media screen and (max-width:560px) {
        width: calc(100%);
    }

    &:hover{
        .overMenu{
            display: flex;
        }
    }

    .overMenu{
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 76px;
        background: linear-gradient(180deg, rgba(43, 43, 43, 0.3) 0%, rgba(217, 217, 217, 0) 100%);
        border-radius: 8px 8px 0px 0px;
        gap: 8px;
        justify-content: end;
        padding: 8px;
        box-sizing: border-box;
        z-index: 8;

        .btn{
            font-size: 1.5rem;
            background-color: #FFF;
            border-radius: 2px;
            z-index: 1;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        .btnDeleted{
            color: ${({ theme }) => theme.base.font_color_Red};
            
            &:hover{
                background-color: ${({ theme }) => theme.base.font_color_Red};
                color: #FFF;
            }
        }

        .btnOption{
            color: ${({ theme }) => theme.base.font_color_DarkBlue};
            
            &:hover{
                background-color: ${({ theme }) => theme.base.font_color_Blue};
                color: #FFF;
            }
        }
    }
`;

const PublishLabel = styled.div`
    position: ${({isThumbnail}) => isThumbnail ? 'absolute' : 'relative'};
    display: inline-block;
    font-size: 1rem;
    padding: 6px 8px;
    width: 90px;
    box-sizing: border-box;
    margin-top: 16px;
    border-radius: 0 4px 4px 0;
    color: #fff;
    z-index: 8;

    &.PUBLIC{
        background-color: ${({theme}) => theme.base.font_color_Orange};
    }

    &.PRIVATE{
        background-color: ${({theme}) => theme.base.font_color_DarkBlue};
    }
`;

const ThumbnailImg = styled.div`
    position: relative;
    width: 100%;
    height: 118px;
    background-color: ${({ theme }) => theme.base.background_color_Gray};
    border-bottom: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
    overflow: hidden;
    box-sizing: border-box;

    &>img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const CardInfo = styled.div`
    position: relative;
    width: 100%;
    padding: 16px 8px 8px 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .subInfo{
        width: 100%;
        min-height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .projectDate{
            font-size: .8rem;
            opacity: .6;
        }

        .buttons{
            display: flex;
            z-index: 0;
            gap: 8px;
        }
    }

    .renderingType{
        position: absolute;
        top: -40px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #FFF;
        box-shadow:rgba(0, 0, 0, .15) 0px 2px 4px;

        &::before{
            content: attr(data-content);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: .9rem;
            font-weight: bold;
            color: ${({theme}) => theme.base.font_color_DarkBlue};
        }
    }
`;

const MainInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    img{
        width: 38px;
        height: 38px;
        overflow: hidden;
        border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
        border-radius: 50%;
        box-sizing: border-box;
    }

    .title{
        width: calc(100% - 46px);
        flex-grow: 1;

        .projectTitle{
            font-size: 1rem;
            font-weight: bold;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .creator{
            margin-top: 8px;
            font-size: .85rem;
            opacity: .6;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .accessType{
        position: relative;
        width: 64px;
    }

    .MASTER{ color: ${({theme}) => theme.AccessColor.Master}; }
    .MANAGER{ color: ${({theme}) => theme.AccessColor.Manage}; }
    .NORMAL{ color: ${({theme}) => theme.AccessColor.Write}; }
`;

const CredContent = styled.div`
    position: relative;
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
    border-radius: 8px;
    box-sizing: border-box;
    min-width: 240px;
    height: auto;
    
    .projectImgBox{
        position: relative;
        width: 100%;
        height: 118px;
        background-color: ${({ theme }) => theme.base.background_color_Gray};
        border-bottom: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 8px 8px 0 0;
        overflow: hidden;

        &>img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        img:not([src]) {
            display: none;
        }
    }

    .infoBox{
        display: flex;
        box-sizing: border-box;
        padding: 16px 8px 8px 8px;
        box-sizing: border-box;

        img{
            width: 38px;
            height: 38px;
            overflow: hidden;
            border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
            border-radius: 50%;
            box-sizing: border-box;
        }

        &>div{
            flex-grow: 1;
            width: calc(100% - 46px);
            color: ${({ theme }) => theme.base.font_color_Black};

            .title{
                width: 100%;

                .projectTitle{
                    font-size: 1rem;
                    font-weight: bold;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .creator{
                    margin-top: 8px;
                    font-size: .85rem;
                    opacity: .6;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .subInfo{
                width: 100%;
                min-height: 30px;
                margin-top: 4px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                

                .projectDate{
                    font-size: .8rem;
                    opacity: .6;

                    span:first-child{
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    .buttons{
        display: flex;
        z-index: 0;
        gap: 8px;
    }

    @media screen and (min-width:1024px) {
        width: calc(100% / 4 - 12px);
    } 
    
    @media screen and (max-width:1090px) {
        width: calc(100% / 3 - 11px);
    } 

    @media screen and (max-width:850px) {
        width: calc(100% / 2 - 8px);
    }

    @media screen and (max-width:560px) {
        width: calc(100%);
    }
`;

const DBCredContent = styled(Card)`
    cursor: pointer;

    .accessLevel{
        margin-top: 6px;
        margin-bottom: 4px;

        &.Read{
            color: ${({theme}) => theme.AccessColor.Read};
        }
        &.Write{
            color: ${({theme}) => theme.AccessColor.Write};
        }
        &.Edit{
            color: ${({theme}) => theme.AccessColor.Edit};
        }
        &.Manage{
            color: ${({theme}) => theme.AccessColor.Manage};
        }
        &.Master{
            color: ${({theme}) => theme.AccessColor.Master};
        }
    }
`;

const MainCred = styled(CredContent)`
    cursor: pointer;
    
    .Btn_play{
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 5rem;
        z-index:6;
    }

    &:hover{
        .Btn_play{
            display: block;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(43, 43, 43, .3);
            backdrop-filter: blur(3px);
            z-index:4;
            border-radius: 8px;
        }
    }

    .buttons{
        z-index: 9!important;
    }
`;