import { useState } from "react";

import styled from "styled-components";
import {MdClear, MdArrowRight, MdArrowDropDown  } from "react-icons/md";

export default function PropertySheetRowContent({ValueL, ValueR, Depth = 0, Type, Desc, children, Delete, Readonly, OnClick}){
    const [IsShow, setIsShow] = useState(false);
    
    const [DataType, VariableName] = ValueL.split(' ');

    const onClickEvent = (e) => {
        if(OnClick && Type !== 'header' && !Readonly)
            OnClick(e, Type, Desc, ValueL, ValueR)
    }


    if(Type === 'header'){
        return(
            <PropertySheetHeader>
                <LeftContent
                    isValueR={ValueR !== null}
                >
                    {ValueL}
                </LeftContent>
                <RightContent
                    isValueR={ValueR !== null}
                >
                    {ValueR}
                </RightContent>
            </PropertySheetHeader>
        )
    }

    return(
        <>
            <PropertySheetRow 
                className={Readonly && 'readonly'} 
                onClick={(e)=>setIsShow(!IsShow)}
            >
                <LeftContent isValueR={ValueR !== null} Depth={Depth}>
                    {IsShow ? <MdArrowDropDown className="icon" /> : <MdArrowRight className="icon" /> }
                    <p className="dataType" style={{'opacity': '.6'}} title={DataType}>{DataType}</p>
                    <p className="variableName" title={VariableName}>{VariableName}</p>
                </LeftContent>

                
                <RightContent
                    isValueR={ValueR !== null}
                    className={Readonly && 'readonly'}
                    title={ValueR}
                    onClick={onClickEvent}
                >
                    {ValueR}
                </RightContent>
                
                <DeleteBtn className="btn_delete" title="Delete">
                    <MdClear onClick={(e) => Delete(ValueL)}/>
                </DeleteBtn>
            </PropertySheetRow>
            {children && 
                IsShow && children
            }
        </>
    )
}

// 스타일-----------------------------------------------------
const PropertySheetBase = styled.div`
    display: flex;
    position: relative;
    height: 32px;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    box-sizing: border-box;
    user-select: none;
`;

const PropertySheetHeader = styled(PropertySheetBase)`
    background-color: ${({theme}) => theme.base.background_color_Gray};

    div{
        cursor: default;
    }
`;

const PropertySheetRow = styled(PropertySheetBase)`
    &.readonly{
        background-color: ${({theme}) => theme.base.background_color_PaleGray};
    }

    &:hover{
        background-color: ${({theme}) => theme.base.background_color_LightBlue};

        &>.btn_delete{
            display:block;
        }
    }
`;

const LeftContent = styled.div`
    height: auto;
    flex-grow: 1;
    width: calc(100% - 30%);
    line-height: 28px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: ${props =>  props.isValueR ? 18 + (14 * props.Depth)  : 0 + (14 * props.Depth)}px;

    .icon {
        width: 16px;
        height: 16px;
        display: ${props =>  props.isValueR ? 'none' : 'block' };
    }    

    .dataType{
        font-size: .8rem;
        width: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap;
        box-sizing: border-box;
    }

    .variableName{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap;
        box-sizing: border-box;
        flex-grow: 1;
        width: calc(100% - 58px);
    }
`;

const RightContent = styled.div`
    height: auto;
    border-left: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    width: 30%;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
    padding-left: 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: ${props =>  props.isValueR ? 'block' : 'none' };

    &.readonly{
        cursor: default;
    }
`;

const DeleteBtn = styled.span`
    position: absolute;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    flex-shrink: 0;
    display:none;
    
    svg{
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 1.3rem;
        border-radius: 4px;
        padding: 4px;
        transform: translate(-50%, -50%);
        color: ${({theme}) => theme.base.font_color_Red};
        background-color: rgba(255,255,255,0.6);

        :hover{
            border: 1px solid ${({theme}) => theme.base.font_color_Red};
            background-color: ${({theme}) => theme.base.background_color_LightRed};
        }
    }
`;