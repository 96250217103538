import styled, { keyframes } from "styled-components";

const randomInt = (max) => Math.floor(Math.random() * max) + 1;
const multipleBoxShadow = (n) => {
    const Size = window.innerWidth < 3000 ? 3000 : window.innerWidth;
    let value = `${randomInt(Size)}px ${randomInt(Size)}px #FFF`;
    for (let i = 2; i <= n; i++) {
      value = `${value}, ${randomInt(Size)}px ${randomInt(Size)}px #FFF`;
    }
    return value;
  };

const animStar = keyframes`
  from { transform: translateY(0px); }
    to { transform: translateY(-2000px); }`;

function SpaceBackground({_height = '100%'}){
    return(
        <SpaceBg style={{height: `${_height}`}}>
            <div id="stars" className="starBase"></div>
            <div id="stars2" className="starBase"></div>
            <div id="stars3" className="starBase"></div>
        </SpaceBg>
    )
}

export default SpaceBackground;

// 스타일 ------------------------------------
const SpaceBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    overflow: hidden;

    .starBase{
        background: transparent;

        &:after {
            content: ' ';
            position: absolute;
            top: 2000px;
            background: transparent;
        }
    }

    #stars{
        width: 1px;
        height: 1px;
        box-shadow: ${multipleBoxShadow(700)};
        animation: ${animStar} 50s linear infinite;

        &:after {
            width: 1px;
            height: 1px;
            box-shadow: ${multipleBoxShadow(700)};
        }
    }
    #stars2{
        width: 2px;
        height: 2px;
        box-shadow: ${multipleBoxShadow(200)};
        animation: ${animStar} 100s linear infinite;

        &:after {
            width: 2px;
            height: 2px;
            box-shadow: ${multipleBoxShadow(200)};
        }
    }
    #stars3{
        width: 3px;
        height: 3px;
        box-shadow: ${multipleBoxShadow(100)};
        animation: ${animStar} 150s linear infinite;
      
        &:after {
          width: 3px;
          height: 3px;
          box-shadow: ${multipleBoxShadow(100)};
        }
    }
`;