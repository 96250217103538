import { useState, useEffect } from 'react';
import {useColorPicker} from 'react-best-gradient-color-picker'

import { getRGB, ToHexString } from 'components/Utility';

const getAngle = (x1, y1, x2, y2) => {
    if (y1 === y2) {
        return x2 < x1 ? -90 : 90;
    }

    if (x1 === x2 && y2 > y1) {
        return 180;
    }

    const rad = Math.atan2(y1 - y2, x2 - x1);
    let a = rad * 180 / Math.PI;

    if (y2 > y1 && x2 > x1) {
        a = 180 + a;
    } else if (y2 > y1 && x2 < x1) {
        a = -180 + a;
    }

    return a;
};

export default function useColorInfo({rgb, opacity = 1, gradientInfoMap}){
    const [ColorValue, setColorValue] = useState('rgba(0,0,0,1)');
    const [ColorDataMap, setColorDataMap] = useState({});
    const [GradientPos, setGradientPos] = useState({});
    const { valueToHex: ValueToHex, isGradient: IsGradient, gradientType: GradientType } = useColorPicker(ColorValue, setColorValue);

    useEffect(()=>{
        if(!rgb) return;

        const getOpacityData = (opacity) => {
            if (Array.isArray(opacity)) {
                return 'Mixed';
            }
            return opacity;
        };

        if(Array.isArray(rgb)){
            let MixedName ='Mixed';
            rgb.forEach(indexItem => {
                if(indexItem.includes("url")){
                    // 데이터중 Gradient가 하나라도 있다면 "Gradient" 설정 Gradient의 url 값은 같을수 없다.
                    MixedName = 'Gradient';
                }
            })

            setColorDataMap({
                fill: MixedName,
                opacity: getOpacityData(opacity),
            });

            setColorValue(MixedName);

        }else if(rgb.includes("url")){
            const {stop, 'gradient.object_name': gradientType} = gradientInfoMap;

            if(stop === undefined) return;

            let stopList = [];
            let strStop = '';

            stop.forEach(stopInfo => {
                let fillValueRGB, opacity, stropName, offset;

                for (let _key of Object.keys(stopInfo)) {
                    if (_key.includes('offset')) {
                        offset = stopInfo[_key];
                        stropName = _key.substring(0, _key.indexOf('.'));
                    } else if (_key.includes('stop_color')) {
                        fillValueRGB = getRGB(stopInfo[_key]);
                    } else if (_key.includes('stop_opacity')) {
                        opacity = stopInfo[_key];
                    }
                }

                const RGBA = {
                    r: fillValueRGB['R'],
                    g: fillValueRGB['G'],
                    b: fillValueRGB['B'],
                    a: opacity,
                };

                stopList.push({
                    offset, stropName, RGBA
                })
                
                strStop += `,rgba(${RGBA.r},${RGBA.g},${RGBA.b},${RGBA.a}) ${offset}%`
            });

            if(gradientType === 'linearGradient'){
                const {'gradient.x1': x1, 'gradient.y1': y1, 'gradient.x2':x2, 'gradient.y2':y2} = gradientInfoMap;
                const angle = getAngle(x1, y1, x2, y2);
                setColorValue(`linear-gradient(${angle}deg ${strStop})`);
                setGradientPos({x1, y1, x2, y2})
            }else if(gradientType === 'radialGradient'){
                const {'gradient.cx': cx, 'gradient.cy': cy, 'gradient.fx': fx, 'gradient.fy':fy, 'gradient.r':r} = gradientInfoMap;
                setColorValue(`radial-gradient(circle at 50% 50% ${strStop})`);
                setGradientPos({cx, cy, fx, fy, r})
            }

        }else{
            const [R, G, B] = rgb.replace('rgb(', '').replace(')', '').split(',');

            setColorValue(`rgba(${R}, ${G}, ${B}, ${opacity})`);
            setColorDataMap({
                fill: `${ToHexString(R)}${ToHexString(G)}${ToHexString(B)}`,
                opacity: getOpacityData(opacity),
            });
        }
    }, [rgb, opacity, gradientInfoMap]);

    return {
        ColorValue,
        setColorValue,
        ColorDataMap,
        setColorDataMap,
        GradientPos,
        setGradientPos,
        ValueToHex,
        IsGradient,
        GradientType,
    }
}