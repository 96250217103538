import { Vector3, ArcRotateCamera } from "@babylonjs/core";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";

export default class Camera {
    constructor(EnuScene, canvas) {
        const camera = new ArcRotateCamera(
            "ArcRotateCamera",
            0,
            0,
            0,
            new Vector3(0, 0, 0),
            EnuScene.scene
        );
        camera.setPosition(new Vector3(5, 5, -300));
        camera.attachControl(canvas, true);

        this.uniqueId = camera.uniqueId;
        this.id = camera.id;
        this.name = camera.name;
        this.position = camera.position;
        this.objectType = SUPPORT_OBJECT_TYPE.CAMERA;

        EnuScene.addCameraToMap(this.uniqueId, this);
    }

    setPosition(position) {
        const { x, y, z } = position;
        this.position.x = x || this.position.x;
        this.position.y = y || this.position.y;
        this.position.z = z || this.position.z;
    }

    getPropertyMap() {
        return {
            uniqueId: this.uniqueId,
            id: this.id,
            name: this.name,
            position: {
                x: this.position.x,
                y: this.position.y,
                z: this.position.z,
            },
        };
    }
}
