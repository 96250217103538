
import {useContext, useEffect} from 'react';

import {BtnSecondary} from "components/atom/Button";
import {EnuSymbol} from "components/common/EnuSpaceIcon"
import BtnToggle from "components/atom/BtnToggle";
import { SceneContext, SelectMeshContext } from "context/SceneContext";
import MeshTool from "babylon/components/molecule/MeshTool"
import EnuTextBox from 'babylon/class/EnuTextBox';
import Camera from 'babylon/class/Camera';
import Light from 'babylon/class/Light';
import AssetTransformNode from 'babylon/class/Asset';
import Material from 'babylon/class/Material';
// import EnuGUIRectangle from 'babylon/class/EnuGUIRectangle';
import ENU3DButton from 'babylon/class/ENU3DButton';
import { CreateAxis } from "babylon/utility/CreatorUtility";
// import useDataController from "babylon/hooks/useDataController";
import { SaveFile } from 'api/Project3D'

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { SceneSerializer } from "@babylonjs/core";
// import { TextBlock } from "@babylonjs/gui"
// import { Button3D, GUI3DManager } from "@babylonjs/gui/3D"
import { Inspector } from '@babylonjs/inspector';


export default function Project3DHeader({IsRunTimeModeState, setIsInspector}){
    const { EnuScene } = useContext(SceneContext);
    const BASE_DATA = useSelector(state => state.projectData.BASE_DATA);
    const {userId, projectId} = BASE_DATA;
    const selectBabylonData = useSelector(state => state.selectBabylonData);
    const {SelectedMesh} = useContext(SelectMeshContext);
    const [IsRunTimeMode, setIsRunTimeMode] = IsRunTimeModeState;

    useEffect(()=>{
        return ()=>{
            // Inspector 활성화 상태로 프로젝트를 나간후 다시 접속하면 활성화 아이콘만 활성화 상태로 화면이 렌더링 된다.
            Inspector.Hide();
        }
    }, []);

    const SaveAll = (e) => {
        // const filename = projectName + '.json';
        const filename = 'scene1.json'
        const jsonScene = JSON.stringify(EnuScene.getAllMaps());

        SaveFile(userId, projectId, filename, jsonScene)
            .then((res) => {
                const { result, msg } = res.data;
                if (result === "Success") {
                    window.AddToastMsg('Success')
                } else {
                    window.AddAlertMsg({ type: 'error', msg })
                }
            }).catch((err) => {
                window.AddAlertMsg({ type: 'error', msg: err })
            })        
    }

    // const {UploadDataByMesh} = useDataController();

    return(
        <HeaderContent>
            <HeaderTopContent>
                <div>
                    <Link to='/' className="EnuSymbol"> <EnuSymbol /> </Link>
                    <ul>
                        <li onClick={(e)=>{
                            window.open(`https://expnuni.github.io/enuspaceMeta_doc/`,
                                'EnuspaceManual',
                                'width=1000,height=660,location=yes,status=no,scrollbars=yes,menubar=yes,titlebar=yes,toolbar=yes');
                        }} >Help</li>

                        <li
                            onClick={(e)=>{
                                let objectUrl = null;
                                let filename = "project.babylon";

                                if (objectUrl) {
                                    window.URL.revokeObjectURL(objectUrl);
                                }
                                
                                const serializedScene = SceneSerializer.Serialize(EnuScene.scene);
                                
                                const strScene = JSON.stringify(serializedScene);
                                
                                if (filename.toLowerCase().lastIndexOf(".babylon") !== filename.length - 8 || filename.length < 9) {
                                    filename += ".babylon";
                                }
                                
                                const blob = new Blob([strScene], { type: "octet/stream" });
                                
                                // turn blob into an object URL; saved as a member, so can be cleaned out later
                                objectUrl = (window.webkitURL || window.URL).createObjectURL(blob);
                                
                                const link = window.document.createElement("a");
                                link.href = objectUrl;
                                link.download = filename;
                                const click = document.createEvent("MouseEvents");
                                click.initEvent("click", true, false);
                                link.dispatchEvent(click);
                            }}
                        >
                            Download
                        </li>

                    </ul>
                </div>
                <div>
                    <ul>
                        <li id='btnInspector'
                            onClick={(e)=>{
                                if(Inspector.IsVisible){
                                    Inspector.Hide();
                                    setIsInspector(false);
                                }else{
                                    Inspector.Show(EnuScene.scene, {
                                        overlay: true,
                                    });
                                    setIsInspector(true);
                                }
                            }}
                        >
                            <BtnToggle 
                                _toggleID="Inspector" 
                                _isToggleValue={Inspector.IsVisible}
                            />
                            <span>Inspector</span>
                        </li>
                    </ul>
                </div>
            </HeaderTopContent>
            <HeaderBottomContent>
                <div className='toolbar'>
                    <MeshTool EnuScene={EnuScene} />

                    <BtnSecondary 
                        label='TextBox'
                        type='sm'
                        onClick={(e)=>{
                            new EnuTextBox(EnuScene);
                        }}
                    />

                    <button onClick={(e)=>{
                        console.log(EnuScene);
                    }}>Scene</button>
                    
                    <button onClick={(e)=>{
                        console.log(selectBabylonData);
                    }}>selectBabylonData</button>

                    <BtnSecondary 
                        label='Camera'
                        type='sm'
                        onClick={(e)=>{
                            new Camera(EnuScene, EnuScene.canvas);
                        }}
                    />
                    <BtnSecondary 
                        label='Light'
                        type='sm'
                        onClick={(e) => {
                            new Light(EnuScene);
                        }}
                    />
                    <BtnSecondary 
                        label='Axis'
                        type='sm'
                        onClick={(e) => {
                            CreateAxis(EnuScene.scene, 100);
                        }}
                    />

                    <button onClick={(e)=>{
                        SelectedMesh.redrawMesh();
                    }}>Redraw</button>

                    <button onClick={(e)=>{
                        SelectedMesh.switchWireframeMode(EnuScene);
                    }}>Wireframe</button>

                    <button onClick={(e)=>{
                        new Material(EnuScene, '_Test');
                    }}>Material</button>

                    {/* <button onClick={(e) => {     
                        const button3d = EnuScene.getGUIByKey("3DButton");
                        button3d.originGUI.positon = new Vector3(15, 0, 0);
                        console.log(button3d)
                        console.log(button3d.originGUI)
                    }}>TEST_BTN</button> */}

                    {/* <button onClick={(e) => {
                        const mesh = EnuScene.getMeshById("box");
                        new EnuGUIRectangle(EnuScene, 'UI', mesh);
                        
                        // guiRect.linkToMesh(mesh)
                    }}>GUI_Rect</button> */}

                    <button onClick={(e) => {
                        
                        const button = new ENU3DButton(EnuScene, '3DButton', 'META')
                        // const button = new Button3D('button', EnuScene.scene) 

                        // var text1 = new TextBlock();
                        // text1.text = "enuSpaceMETA";
                        // text1.color = "black";
                        // text1.fontSize = 24;
                        // button.content = text1;  
                        button.originGUI.mesh.billboardMode = 7;

                        console.log(button)
                        
                    }}>GUI_3DButton </button>


                    <button onClick={(e)=>{
                        SelectedMesh.hiddenPoints = [];
                    }}>Unhide  </button>

                    <button onClick={(e)=>{
                        console.log("Importing...");
                        new AssetTransformNode(EnuScene, `Repository/${userId}/${projectId}/modeling/`, "core.obj")
                    }}>Import Core Modeling</button>             

                </div>

                <button onClick={SaveAll}>Save All</button>
                <div className='btn_runtime'>
                    <img src="/img/toolbar/btn_play.png" alt="play" 
                        data-name={IsRunTimeMode} 
                        onClick={(e)=> setIsRunTimeMode(prev=>!prev)}
                    />
                </div>

            </HeaderBottomContent>
        </HeaderContent>
    )
}

//스타일--------------------------------------
const HeaderContent = styled.header`
    height: 76px;
    border-bottom: 1px solid ${({theme}) => theme.base.background_color_Gray};
    box-sizing: border-box;
    user-select: none;
`;

const HeaderTopContent = styled.div`
    background-color: ${({theme}) => theme.base.border_color_LightGray};
    display: flex;
    padding: 0px 8px;
    justify-content: space-between;

    &>div{
        display: flex;
        align-items: center;
        gap: 16px;
    }

    ul{
        display: flex;
        align-items: baseline;
        justify-content: center;
        align-items: center;

        &>li{
            font-size: 0.9rem;
            color: ${({theme}) => theme.base.font_color_Black};
            padding: 6px;
            cursor: default;
            user-select: none;

            &:hover {
                color: ${({theme}) => theme.base.font_color_Blue};
                cursor: pointer;
            }
        }
    }

    .EnuSymbol svg{
        width: 34px;
        height: 28px;
    }

    #btnInspector{
        display: flex;
        gap: 6px;
    }
`;

const HeaderBottomContent = styled.div`
    justify-content: space-between;
    height: 48px;
    display: flex;
    padding: 0px 8px;
    align-items: center;

    .toolbar{
        height: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .btn_runtime{
        overflow: hidden;
        width: 46px;
        height: 48px;
        position: relative;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;

        &:hover img{
            right: 0;
        }

        &>img{
            position: absolute;
            top: 0;
            &[data-name="true"]{
                right: 0;
            }
        }
    }
`;