

import {GetRealTarget} from 'components/Utility'
import MouseRightClickMenuContent from "components/molecule/MouseRightClickMenuContent";
import {
    EnuIconPicture,
    EnuIconStruct,
    EnuIconLibrary,
    EnuIconGlobal,
    EnuIconSymbol,
    EnuIconDB,
    EnuIconLua,
} from "components/common/EnuSpaceIcon"
import useModalPopup from 'hooks/useModalPopup';
import useProjectData from 'hooks/useProjectData';
import { viewCodeEditor} from 'reducers/projectDataReducer';

import { useDispatch, useSelector } from 'react-redux';
import { MdAdd, MdClear, MdDriveFileRenameOutline, MdFormatListBulleted, MdOutlineSave, MdCode, MdOutlineAddToPhotos } from "react-icons/md";

export default function ExplorerTreeMenu({enuSpace, setIsOpenTree, rightMenuInfo}){
    const dispatch = useDispatch();
    const {AddModal} = useModalPopup();
    const {userId, projectId, projectName} = useSelector(state => state.projectData.BASE_DATA);
    const TreeData = useSelector(state => state.projectData.TREE_DATA);

    const CanvasViewData = useSelector(state => state.projectData.CANVAS_VIEW);
    const {DeleteNodeData, UpdateCurrentView, UpdateArrayActivePictureURL, SetCanvasView} = useProjectData();

    // <li onClick={CopyPath}><span><MdCopyAll style={{ 'color': 'royalblue' }} />copy</span> </li>
    const MenuList = (data) => {
        const {rowId, rowType, rowPath, rowValue, targetRowElementInfo} = data;

        const getMenuContent = (label, icon, onClick) => {
            return (
                <li onClick={onClick}>
                    <span data-id={rowId} data-path={rowPath} data-value={rowValue}>
                        {icon}
                        {label}
                    </span>
                </li>)
        }

        const Add = (_label, _icon) => getMenuContent(_label, _icon, (e) => MouseRightMenu_Add(e));
        const AddSymbolContent = (_label, _icon) => getMenuContent(_label, _icon, (e) => AddSymbol(e, rowValue, rowPath));
        const NewInterfaceDB = getMenuContent('New Interface DB', <EnuIconDB />, (e) => MouseRightMenu_AddGlobalVariableDB(e));
        const Rename = getMenuContent('Rename', <MdDriveFileRenameOutline style={{ 'color': 'darkgoldenrod' }} />, (e) => MouseRightMenu_Rename(e));
        const SetGlobalVariable = getMenuContent('Add Interface Item', <MdAdd style={{ 'color': 'royalblue' }} />, (e) => MouseRightMenu_GlobalVariable(e, targetRowElementInfo));
        const ShowGlobalVariable = getMenuContent('Show Interface Item', <MdFormatListBulleted style={{ 'color': 'royalblue' }} />, (e) => MouseRightMenu_ShowGlobalVariable(e));
        const SetLocalVariable = getMenuContent('Add Variable Item', <MdAdd style={{ 'color': 'royalblue' }} />, (e) => MouseRightMenu_LocalVariable(e, targetRowElementInfo));
        const DeleteLocalVariable = getMenuContent('Delete Variable Item', <MdClear style={{ 'color': 'mediumvioletred' }} />, (e) => MouseRightMenu_DeleteLocalVariable(e, rowType));
        const AddStruct = getMenuContent('New Struct', <EnuIconStruct />, (e) => MouseRightMenu_AddStruct(e));
        const SaveItem = getMenuContent('Save', <MdOutlineSave style={{ 'color': 'steelblue' }} />, (e) => MouseRightMenu_Save(e));
        const DeleteItem = getMenuContent('Delete', <MdClear style={{ 'color': 'mediumvioletred' }} />, (e) => MouseRightMenu_Delete(e));
        const DeleteSymbolItem = getMenuContent('Delete', <MdClear style={{ 'color': 'mediumvioletred' }} />, (e) => MouseRightMenu_DeleteSymbol(e));
        const ViewCode = getMenuContent('Show Code', <MdCode style={{ 'color': 'steelblue' }} />, (e) => MouseRightMenu_ViewCode(e));
        
        const CreateGlobalLua = getMenuContent('New lua File', <EnuIconLua  />, (e) => MouseRightMenu_CreateLua(e));
        const DeleteGlobalLua = getMenuContent('Delete', <MdClear style={{ 'color': 'mediumvioletred' }} />, (e) => MouseRightMenu_DeleteLua(e));
        const SaveGlobalLua = getMenuContent('Save', <MdOutlineSave style={{ 'color': 'steelblue' }} />, (e) => MouseRightMenu_SaveGlobalLua(e));

        let MenuType;
        switch (rowType) {
            case undefined:
            case 'ProjectName':
                MenuType = (
                    <>
                        <li onClick={(e) => MouseRightMenu_AllSave(e)}><span data-path={rowPath} data-value={rowValue}><MdOutlineSave style={{ 'color': 'steelblue' }} />All Save</span></li>
                        <li onClick={(e) => MouseRightMenu_AddTemplates(e)}><span ><MdOutlineAddToPhotos style={{ 'color': 'steelblue' }} />Add Templates</span></li>
                    </>
                )
                break;

            case 'add':
                if (rowValue === 'task') {
                    MenuType = Add('New Task File');
                } else if (rowValue === 'global') {
                    MenuType = (<>{CreateGlobalLua}{NewInterfaceDB}{Add('New Global Variable', <EnuIconGlobal />)}</>);
                }else if (rowValue === 'hmi') {
                    MenuType = Add('New HMI Library File', <EnuIconLibrary />);
                } else if (rowValue === 'logic') {
                    MenuType = Add('New Logic Library File');
                } else if (rowValue === 'picture') {
                    MenuType = Add('New Picture File', <EnuIconPicture />);
                }
                break;

            case 'a.d':
                if (rowPath === 'global') {
                    MenuType = (<>{ViewCode}{AddStruct}{SetLocalVariable}{Rename}{<span className="menuLine" />}{SaveItem}{DeleteItem}</>);
                } else if (rowPath === 'library\\hmi') {
                    MenuType = (<>{ViewCode}{AddSymbolContent('Add Symbol', <EnuIconSymbol />)}{Rename}{<span className="menuLine" />}{SaveItem}{DeleteItem}</>);
                }
                break;

            case 'DB':
            case 'DB_empty':
                MenuType = (<>{ViewCode}{SetGlobalVariable}{Rename}{<span className="menuLine" />}{SaveItem}{DeleteItem}</>);
                break;
            case 'symbol':
                MenuType = (<>{Rename}{<span className="menuLine" />}{DeleteSymbolItem}</>);
                break;
            case 'file':
                MenuType = (<>{ViewCode}{Rename}{<span className="menuLine" />}{SaveItem}{DeleteItem}</>);
                break;

            case 'attribute':
                MenuType = (<>{DeleteLocalVariable}</>);
                break;
            case 'attribute-DB':
                MenuType = (<>{ShowGlobalVariable}{DeleteLocalVariable}</>);
                break;
            case 'struct':
                MenuType = (<>{DeleteLocalVariable}</>);
                break;
            case 'global-lua':
                MenuType = (<>{Rename}{<span className="menuLine" />}{SaveGlobalLua}{DeleteGlobalLua}</>);
                break;
            default:
                return;
        }

        return MenuType;
    }

    const MouseRightMenu_Add = (e) => handleMouseRightMenu(e);
    const MouseRightMenu_AddGlobalVariableDB = (e) => handleMouseRightMenu(e,  "DB");

    const handleMouseRightMenu = (e, type = undefined) => {
        const {value, path} = GetRealTarget(e.target, 'SPAN').dataset;
        CreateFile(e, value, path, type);
        setIsOpenTree(value);
    }

    const MouseRightMenu_GlobalVariable = (e, _targetRowElementInfo) =>{
        const {value, path} = GetRealTarget(e.target, 'SPAN').dataset;
        const targetPath = `${path}\\${value}`;
        
        let selectStruct = [];
        const ArrayChild = GetTargetChild(targetPath.split('\\'));
        if(ArrayChild !== undefined){
            for(let {type, title} of ArrayChild){
                if(type !== 'struct') continue;
                selectStruct.push(title);
            }
        }

        AddModal(targetPath, `Global Variable (${e.target.dataset.value})`, 'GlobalVariableDB', { targetPath, selectDbValue: [], selectStruct})
    }

    const MouseRightMenu_ShowGlobalVariable = (e) => {
        const Target = GetRealTarget(e.target, 'SPAN');
        ShowModalGlobalVariableDB(Target);
    }

    const MouseRightMenu_AddStruct = (e) =>{
        const {path:TargetPath, value:TargetValue} = GetRealTarget(e.target, 'SPAN').dataset;
        AddModal('New Struct', `New Struct`, 'Struct', { path: `${TargetPath}\\${TargetValue}`})
    }

    const MouseRightMenu_Save = (e) =>{
        const {path, value:fileName} = GetRealTarget(e.target, 'SPAN').dataset;
        const fileType = path.includes('library') ? path.split('\\')[1] : path;

        enuSpace.SaveSvg(userId, projectId, fileType, fileName);
        enuSpace.SaveThumbnail(fileType, `${path}\\${fileName}`, '.png', '');
        enuSpace.UpdateEnup(userId, projectId, 'enup', `${projectName}.enup`, '');
    }

    const AddSymbol = (e, _title, _path) => {
        e.preventDefault();
        const pathArray = _path.split('\\');
        pathArray.push(_title);
    
        if (pathArray[1] !== 'hmi') return;
    
        const getNewName = (_count) => `${_title.replace('.svg', '')}_${_count}`;
        
        let count = 0;
        let newName = getNewName(count);

        const targetChild = GetTargetChild(pathArray);
        if (targetChild !== undefined) {
            const checkArray = targetChild.map(_targetData => _targetData.title);
            
            while (checkArray.includes(newName)) {
                count++;
                newName = getNewName(count);
            }
        }
        
        setIsOpenTree(_title);
        enuSpace.AddSymbol(`${_path}\\${_title}`, newName);
    };

    const MouseRightMenu_LocalVariable = (e, targetRowElementInfo) => {
        const {path:svgPath, value:svgName} = GetRealTarget(e.target, 'SPAN').dataset;
        const url = `${svgPath}\\${svgName}`;

        //Add Variable Item
        AddModal(url, `Variable (${svgName})`, 'AddVariableItem', { url, type: 'global', targetRowElementInfo});
    }

    const MouseRightMenu_DeleteLocalVariable = (e, _rowType) =>{
        const {path:URL, value} = GetRealTarget(e.target, 'SPAN').dataset;

        if(_rowType === 'attribute-DB'){
            enuSpace.DeleteVariable(URL, 'global', '', `attribute\\${value}`);
            // 띄어쓰기 기준으로 편집한다. 예) attribute-DB\\Struct_SKN3 SKN3 => attribute-DB\\Struct_SKN3
            enuSpace.DeleteVariable(URL, 'global', '', `struct\\${value}`.split(" ").slice(0, 1).toString());
        }else
            enuSpace.DeleteVariable(URL, 'global', '', `${_rowType}\\${value}`);
    }

    const MouseRightMenu_Rename = (e) =>{
        const {path, value:originalName} = GetRealTarget(e.target, 'SPAN').dataset;
        AddModal(`${path}\\${originalName}`, `Rename (${originalName})`, 'ChangeTreeFileName', { originalName, changeName : '', path});
    }

    const MouseRightMenu_ViewCode = (e) =>{
        const {path, value} = GetRealTarget(e.target, 'SPAN').dataset;
        const insertValue = `${path}\\${value}`;
        const temp = JSON.parse(JSON.stringify(CanvasViewData));

        //Node정보(화면 right 영역)를 비운다.
        DeleteNodeData();
        for(let canvasId of Object.keys(temp['activeCanvas'])){
            if(temp['activeCanvas'][canvasId] === insertValue){
                temp['currentView'] = canvasId;
                UpdateCurrentView({canvasId, mode:'code'});
                UpdateArrayActivePictureURL(CanvasViewData['activeCanvas'][canvasId]);
                return;
            }
        }
        dispatch(viewCodeEditor(insertValue));
    }

    const MouseRightMenu_Delete =(e)=>{
        const {path: targetPath, value: targetName} = GetRealTarget(e.target, 'SPAN').dataset;
        const fileType = targetPath.includes('library') ? targetPath.split('\\')[1] :targetPath;

        if (!window.confirm(`"${targetName}" 을 삭제하시겠습니까?`)) return;

        DeletedActiveCanvas(targetPath, targetName)

        if (enuSpace.GetActivateView() === `${fileType}\\${targetName}`) {
            const pictureChild = TreeData.find(item => item.title === 'picture')?.child;
            if (pictureChild.length === 1) {
                enuSpace.SetActivateView('');
            } else {
                const newActivateView = pictureChild.filter((item) => item.title !== targetName)[0].title;
                enuSpace.SetActivateView(`${fileType}\\${newActivateView}`);
            }
        }

        enuSpace.DeleteSvg(userId, projectId, fileType, targetName);
    }

    const MouseRightMenu_DeleteSymbol =(e)=>{
        const {path: targetPath, value: targetName} = GetRealTarget(e.target, 'SPAN').dataset;

        if (!window.confirm(`"${targetName}" 을 삭제하시겠습니까?`)) return;

        DeletedActiveCanvas(targetPath, targetName)
        enuSpace.DeleteSymbol(targetPath, targetName);
    }

    const MouseRightMenu_AllSave = (e) =>{
        const AllSave = (_fileType, _childData) =>{
            if(_childData['child'] !== null){
                for(let _target of _childData['child']){
                    enuSpace.SaveSvg(userId, projectId, _fileType, _target['title']);
                    enuSpace.SaveThumbnail(_fileType, `${_target['path']}\\${_target['title']}`,'.png', '');
                }
            }
        }

        for(let _targetData of TreeData){
            const fileType = _targetData['title'];

            if(fileType === 'library'){
                for(let _child of _targetData['child']){
                    const libraryType = _child['title'];
                    AllSave(libraryType, _child);
                }
            }else{
                AllSave(fileType, _targetData);
            }
        }

        enuSpace.UpdateEnup(userId, projectId, 'enup', `${projectName}.enup`, '');
    }

    const MouseRightMenu_AddTemplates = (e) =>{
        const X = e.clientX;
        const Y = e.clientY;
        AddModal(`AddTemplates\\${projectId}`, `Add Templates`, 'AddTemplates', {Pos:{X, Y}});
    }

    // global DB 생성시 _globalDB="DB"
    const CreateFile = (e, _title, _path, _globalDB) => {
        e.preventDefault();
        let SvgPath = _path === undefined ? _title : `${_path}\\${_title}`;

        const newSvgName = CreateAutoNewName(SvgPath);
        const type = _globalDB === "DB" ? `${_title}\\DB` : _title;

        if (type === 'picture') {
            const pictureChild = TreeData.find(item => item.title === 'picture')?.child;
            if (pictureChild === null)
                enuSpace.SetActivateView(`${type}\\${newSvgName}`);
        }
        enuSpace.CreateSvg(newSvgName, type);
    }

    //_svgPath 에서 child Array를 반환
    const GetTargetChild = (_svgPath) => {
        let target = TreeData.find((node) => node.title === _svgPath[0]);
        if (!target)
            return null;

        let children = target.child;
        for (let i = 1; i < _svgPath.length; i++) {
            target = children.find((node) => node.title === _svgPath[i]);
            if (!target) {
                return null;
            }
            children = target.child;
        }
        return children;
    }

    const CreateAutoNewName = (_path, _type='svg') => {
        const SvgPath = _path.split('\\');
        let count = 0;
        let svgType = SvgPath.length === 1 ? SvgPath[0] : SvgPath[1];
    
        const getNewName = (_count) => `${svgType}_${_count}.${_type}`;
    
        const targetChild = GetTargetChild(SvgPath);
        if (targetChild !== null) {
            const checkArray = targetChild?.map(_targetData => _targetData.title);
            
            let newName = getNewName(count);
            while (checkArray?.includes(newName)) {
                count++;
                newName = getNewName(count);
            }
            return newName;
        }
    
        return `${svgType}_${count}.${_type}`;
    };

    const ShowModalGlobalVariableDB = (_Target) => {
        const {id: table_ID, path, value } = _Target.dataset;
        const targetPath = `${path}\\${value}`;
        AddModal(`Show\\${targetPath}`, `Show Global Variable (${value})`, 'ShowGlobalVariableDB', { targetPath, table_ID});
    }

    /**
     * 삭제 하는 파일이 캔버스에 활성화 되어 있다면 redux에 저장된 캔버스 정보를 삭제한다.
     * @param {string} _targetPath 
     * @param {string} _targetName 
     */
    const DeletedActiveCanvas = (_targetPath, _targetName) => {
        const fullPath = `${_targetPath}\\${_targetName}`;
        const temp = JSON.parse(JSON.stringify(CanvasViewData));
        const activeCanvasKeys = Object.keys(temp['activeCanvas']);

        const deletedKeyList = activeCanvasKeys.filter(key => temp['activeCanvas'][key].includes(fullPath));

        deletedKeyList.forEach(key => {
            delete temp['activeCanvas'][key];
            delete temp['canvasMode'][key];
        });

        if (deletedKeyList.includes(temp['currentView'])) {
            temp['currentView'] = '';
        }

        if (Object.keys(temp['activeCanvas']).length === 0 && document.getElementById("Canvas") !== null) {
            enuSpace.DeleteView("Canvas");
            document.getElementById("Canvas").remove();
        } else {
            temp['currentView'] = activeCanvasKeys.find(item => !deletedKeyList.includes(item));
        }

        SetCanvasView(temp);
        DeleteNodeData();
    }

    const MouseRightMenu_CreateLua = (e) =>{
        const {value, path} = GetRealTarget(e.target, 'SPAN').dataset;
        let SvgPath = path === undefined ? value : `${path}\\${value}`;
        const newSvgName = CreateAutoNewName(SvgPath, 'lua');
        enuSpace.CreateGlobalLua(`${SvgPath}\\${newSvgName}`);
        setIsOpenTree(value);
    }

    const MouseRightMenu_SaveGlobalLua = (e) => {
        const {value, path} = GetRealTarget(e.target, 'SPAN').dataset;
        enuSpace.SaveGlobalLua(userId, projectId, `${path}\\${value}`)
    }

    const MouseRightMenu_DeleteLua = (e) =>{
        e.preventDefault();
        const {value, path} = GetRealTarget(e.target, 'SPAN').dataset;

        if (!window.confirm(`"${value}" 을 삭제하시겠습니까?`)) return;

        enuSpace.DeleteGlobalLua(userId, projectId, `${path}\\${value}`);
        enuSpace.UpdateEnup(userId, projectId, 'enup', `${projectName}.enup`, '');   
    }

    return(
        <MouseRightClickMenuContent _positionTop={rightMenuInfo['top']} _positionLeft={rightMenuInfo['left']}>
            {MenuList(rightMenuInfo.data)}
        </MouseRightClickMenuContent>
    )
}