import { useState, useEffect} from 'react';

import EnuCanvasPage from "components/template/EnuCanvasPage";
import {LoadingLayer} from "components/organism/LoadingPage";
import GuestNameInput from "components/organism/GuestNameInput";
import useEnuSpaceModule from 'hooks/useEnuSpaceModule';
import useEnterEnuCanvas from 'hooks/useEnterEnuCanvas';
import useWindowFun from 'hooks/useWindowFun';
import useEnuCanvasFun from 'hooks/useEnuCanvasFun';
import {SaveCanvasThumbnail} from 'api/EnuCanvasApi'

import { useLocation } from 'react-router-dom'

export default function LinkCanvas(){
    const [enuSpace] = useEnuSpaceModule();
    const [isLoadingPage, setIsLoadingPage] = useState(false);  //setIsLoadingPage
    const [, setACTIVE_PICTURE_URL] = useState(''); //ACTIVE_PICTURE_URL
    const {messages, RequestMsg, setMessages, connection} = useEnterEnuCanvas();
    const location = useLocation();

    const [FirstLoad, setFirstLoad] = useState(true);
    const [isIframe, setIsIframe] = useState(false);
    const [iframeData, setIframeData] = useState({});
    const [BaseInfo, setBaseInfo] = useState({});
    // 닉네임 입력 박스
    const [isInputBox, setIsInputBox] = useState({ visibility: false, userNickname: '', });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsIframe(window.self !== window.top);
        setIsLoading(false);
      }, []);

      const handleMessage = (event) => {
        if (event.origin === window.location.origin && event.data.userId) {
            setIframeData(event.data);
        }
        };

      window.addEventListener('message', handleMessage);

    useEffect(() => {
        return () => {
        setFirstLoad(false);
        setIframeData({})
        setIsLoading(true);
        };
    }, []);

    useEffect(()=>{
        if (isLoading) return; 
        const Search = location.search;
        const [canvasId, canvasName, index] = Search.substring(1, Search.length).split('&');

        if(isIframe){
            if(iframeData){
                setBaseInfo({
                    userId: iframeData.userId,
                    userNickname: iframeData.userNickname,
                    creatorId: iframeData.creatorId,
                    canvasId,
                    canvasName,
                    index,
                });
            }
        }
        else{
            const systemId = localStorage.getItem('systemId') || sessionStorage.getItem('systemId');
    
            if(systemId === null || systemId === undefined){
                setIsInputBox(prevIsInputBox => ({ ...prevIsInputBox, visibility: true }));
            }else{
                const nickname = localStorage.getItem('nickname') || sessionStorage.getItem('nickname');
                const creatorId = localStorage.getItem('creatorId') || sessionStorage.getItem('creatorId');
    
                setBaseInfo({
                    userId: systemId,
                    userNickname: nickname,
                    creatorId,
                    canvasId,
                    canvasName,
                    index,
                });
            }
        }

    }, [isLoading, location.search, iframeData, isIframe]);

    useEffect(()=>{
        return ()=>{
            if(isInputBox.userNickname !== ''){
                sessionStorage.removeItem('Authorization');
                sessionStorage.removeItem('refreshtoken');
                sessionStorage.removeItem('systemId');
                sessionStorage.removeItem('nickname');
            }
        }
    }, [isInputBox]);

    useWindowFun(enuSpace, setIsLoadingPage);
    useEnuCanvasFun({
        enuSpace,
        messages,
        RequestMsg,
        setMessages,
    })

    useEffect(()=>{
        if(!BaseInfo?.userId)
            return;
        if(!FirstLoad) return;
        if(connection === undefined) return;

        connection.invoke('EnterEnuCanvas', {
            ENUCANVAS_SYSTEMID: BaseInfo.canvasId,
            MEMBER_SYSTEMID: BaseInfo.userId,
            NICKNAME: BaseInfo.userNickname,
        });

        setFirstLoad(false);
    }, [BaseInfo, connection, FirstLoad]);

    window.Re_Canvas_LoadCanvas = (json) => {
        const Json = JSON.parse(json);
        const index = BaseInfo.index;
        let ActivateViewPath = '';
        if (Json[index] !== null && Json[index] !== undefined)
            ActivateViewPath = Json[index].PICTURE;
        else
            ActivateViewPath = Json[0].PICTURE;

        const width = document.getElementById('Canvas_box').offsetWidth;
        const height = document.getElementById('Canvas_box').offsetHeight;
        enuSpace.CreateView('Canvas', width, height);

        if (ActivateViewPath !== '' && ActivateViewPath !== undefined) {
            setACTIVE_PICTURE_URL(ActivateViewPath);
            enuSpace.SetView('Canvas', ActivateViewPath, false, "Canvas");
        }
        enuSpace.CanvasRequest("SetSelectNode", "");
    }

    window.Re_Canvas_SaveThumbnail = (sysID, imgData, creatorID) => {
        SaveCanvasThumbnail(creatorID, BaseInfo.canvasId, sysID, imgData);
    }

    return(
        <>
            <LoadingLayer marginTop={"30px"} isLoadingPage={isLoadingPage}/>
            <EnuCanvasPage enuSpace={enuSpace} BASE_DATA={BaseInfo}/>

            {isInputBox.visibility &&
                <GuestNameInput 
                    value={isInputBox.userNickname} 
                    onClickEvent={(guestName)=>{
                        setIsInputBox({userNickname: guestName,  visibility: false})

                        const nickname = localStorage.getItem('nickname');
                        const creatorId = localStorage.getItem('creatorId');
                        const Search = location.search;
                        const [canvasId, canvasName, index] = Search.substring(1, Search.length).split('&');
            
                        setBaseInfo({
                            userId: guestName,
                            userNickname: nickname,
                            creatorId,
                            canvasId,
                            canvasName,
                            index,
                        });
                    }}
                />
            }
        </>
    )   
}