import {useState ,useEffect, useRef } from "react";
import styled, {css} from "styled-components";

export default function MouseRightClickMenuContent({_positionTop, _positionLeft, children, isChild = false ,...props}){
    const MenuContent = useRef();
    const [pos, setPos] = useState({
        X: MenuContent.current ? (_positionTop - MenuContent.current.offsetHeight) : _positionTop,
        Y: _positionLeft
    });

    useEffect(()=>{
        if(MenuContent.current){
            if(window.innerHeight < MenuContent.current.offsetHeight +_positionTop){
                setPos({X:_positionTop - MenuContent.current.offsetHeight, Y:_positionLeft});
            }else{
                setPos({X:_positionTop, Y:_positionLeft});
            }
        }
    }, [_positionTop, _positionLeft]);

    return(
        <Content ref={MenuContent} {...props} style={{'top' : pos['X'], 'left':pos['Y']}}>
            <MouseRightClickMenu isChild={isChild}>
                {children}
            </MouseRightClickMenu>
        </Content>
    )
}

//스타일-------------------------------------------------
const Content = styled.div`
    position: fixed;
    background-color: #fff;
    z-index: 9;
    display: flex;
    border-radius: 4px;
    padding: 8px 24px 8px 8px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
    flex-wrap: wrap;
    cursor: default;
    user-select: none;
`;

const MouseRightClickMenu = styled.ul`
    a{
        text-decoration: none;
    }
    
    span{
        color: ${({theme}) => theme.base.font_color_Black};
        font-size: 0.9rem;
        display: flex;
        width: 100%;
        padding: ${({ isChild }) => isChild ? '6px 0' : '6px 8px'};

        >.icon_arrow{
            position: absolute;
            top: 25%;
            right: 0;
        }

        &>svg{
            font-size: initial;
            padding-right: 4px;
        }

        &>img{
            padding-right: 4px;
        }
    }

    .menuLine{
        width: 100%;
        position: relative;

        &:after{
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: ${({ isChild }) => isChild ? '110%' : '100%'};
            height: 1px;
            background-color: ${({theme}) => theme.base.background_color_Gray};
        }
    }

    li:hover{
        ${({ isChild }) => isChild 
            ? css`background-color :  ${({theme}) => theme.base.background_color_LightBlue};`
            : css`span {background-color :  ${({theme}) => theme.base.background_color_LightBlue};}` 
        };
        &>ul{
            display:block;
        }
    }

    &>li{
        position: relative;
        width: 100%;
        padding: ${({ isChild }) => isChild ? '0 8px' : null};

        &>ul{
            position: absolute;
            top: 0;
            left: 100%;
            background-color: #fff;
            z-index: 9;
            border-radius: 4px;
            padding: 8px;
            box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
            cursor: default;
            display:none;

            &>li{
                display: flex;
                align-items: center;
                position: relative;
                gap: 6px;
                padding: 0 8px;
                box-sizing: border-box;

                &>span{
                    width: calc(100% - 32px);
                }

                svg{
                    width: 16px;
                }
            }
        }
    }
`;