import {useEffect, useState} from "react";
import {BtnText} from "components/atom/Button";

import {
    EnuSymbol,
    // EnuIconAlignBottom,
    // EnuIconAlignTop,
    // EnuIconAlignRight,
    // EnuIconAlignMiddle,
    // EnuIconAlignLeft,
    // EnuIconAlignCenter,
    // EnuIconDistributeVertically,
    // EnuIconDistributeHorizontally,
    // EnuIconSendToBack,
    // EnuIconSendBackward,
    // EnuIconBringToFront,
    // EnuIconBringForward,
} from "components/common/EnuSpaceIcon"

import {
    FillColorIcon,
    BorderColorIcon,
} from "components/common/EnuCanvasIcon"

import styled from "styled-components"
import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdClose } from "react-icons/md";
import { GetCanvasThumbnailAll } from "api/EnuCanvasApi";

// const alignList = [
//     { name: 'align_left', icon: <EnuIconAlignLeft/>},
//     { name: 'align_center', icon: <EnuIconAlignCenter/>},
//     { name: 'align_right', icon: <EnuIconAlignRight/>},
//     { name: 'align_horizontally', icon: <EnuIconDistributeHorizontally/>},
//     { name: 'align_top', icon: <EnuIconAlignTop/>},
//     { name: 'align_middle', icon: <EnuIconAlignMiddle/>},
//     { name: 'align_bottom', icon: <EnuIconAlignBottom />},
//     { name: 'align_vertically', icon: <EnuIconDistributeVertically/>},
// ];

// const orderList = [
//     { name: 'bringToFront', icon: <EnuIconBringToFront />},
//     { name: 'sendToBack', icon: <EnuIconSendToBack />},
//     { name: 'bringForward', icon: <EnuIconBringForward />},
//     { name: 'sendBackward', icon: <EnuIconSendBackward />},
// ];

export default function EnuCanvasHeader({enuSpace, BASE_DATA, StateSubOption, StateActiveTool, SupportColor, handleColorClick, SetEnuCanvasTools, handlerActiveTool}){
    const navigate = useNavigate();
    const {canvasName, canvasId } = BASE_DATA;
    // const [ViewScale, setViewScale] = useState(100);
    const [ActiveTool] = StateActiveTool;
    const [isSubOptionName, setIsSubOptionName] = StateSubOption;
    const [isCanvasList, setIsCanvasList] = useState(false);
    const [pictureList, setPictureList] = useState([]);
    const [thumbnailList, setThumbnailList] = useState([]); 
    const [activateView, setActivateView] = useState('');
    const [activateViewIndex, setActivateViewIndex] = useState(-1);

    useEffect(() => {
        const index = pictureList.findIndex(key => key.PICTURE === activateView);
        if(index === -1) return;
        setActivateViewIndex(index);
    }, [pictureList, activateView]);

    const SetView = (path) => {
        // path = 'picture\\NoName.svg'
        // enuSpace.SetView('Canvas', path, false, "Canvas");
        let parts = path.split('\\');
        enuSpace.CanvasRequest('MoveSVG', JSON.stringify({SVG_NAME: parts[1], SVG_TYPE: parts[0]}));
    }

    const LeftButton = () => {
        if(activateViewIndex <= 0) return;
        const pictureName = pictureList[activateViewIndex-1].PICTURE;
        SetView(pictureName);
    }

    const RightButton = () => {
        const pictureLength = pictureList.length;
        const nextIndex = activateViewIndex + 1;

        if (nextIndex === pictureLength) {
            const pictureArray = pictureList.map((item) => item.PICTURE);
            let fileNumber = 2;
            while (pictureArray.includes(`picture\\NoName${fileNumber}.svg`)) {
                fileNumber++;
            }

            enuSpace.CanvasRequest('CreateSVG', JSON.stringify({SVG_NAME: `NoName${fileNumber}`, SVG_TYPE: 'picture', SVG_SEQ: pictureLength+1}));
            SetView(`picture\\NoName${fileNumber}.svg`);
        }else{
            SetView(pictureList[nextIndex].PICTURE);
        }
    }
    
    
    const RenderSubOption = (ActiveTool) =>{
        const {ToolName,  Border:BorderColor, Fill:FillColor} = ActiveTool;

        switch(ToolName){
            case 'Draw':
                return <ul className='SubOption'>
                            <li>
                                <span onClick={(e)=>ClickSubOption(e, 'ShapeBorder')}>
                                    <BorderColorIcon selectedColor={BorderColor}/>
                                </span>
                                {isSubOptionName === 'ShapeBorder' && RenderColorMenu('Draw', 'Border')}
                            </li>
                            <li>
                                <span onClick={(e)=>ClickSubOption(e, 'ShapeFill')}>
                                    <FillColorIcon selectedColor={FillColor}/>
                                </span>
                                {isSubOptionName === 'ShapeFill' && RenderColorMenu('Draw', 'Fill')}
                            </li>
                        </ul>;
            default:
                // console.error('Invalid ToolName');
                break;
        }
    }

    const ClickSubOption = (e, optionName) => {
        e.stopPropagation();
        setIsSubOptionName(optionName);
    };

    const RenderColorMenu = (toolName, colorType) => {
        const colorMap = SupportColor[colorType];
        return(
            <ul className='SubOptionMenu' onClick={(e) => e.stopPropagation()}>
                {Object.keys(colorMap).map((colorValue, idx) => (
                    <li key={idx}>
                        <ColorChip
                            key={idx}
                            Color={colorMap[colorValue]}
                            className={ActiveTool[colorType] === colorMap[colorValue] ? 'selectedColor' : ''}
                            onClick={(e) => handleColorClick(toolName, colorValue, colorType)}
                        />
                    </li>
                ))}
            </ul>
        )
    };

    // const RenderSortMenu = (list, SortType) => (
    //     <ul>
    //         {list.map((item) => (
    //             <li 
    //                 key={item.name} 
    //                 onClick={(e) => {console.log(activateView, SortType, item.name);enuSpace.EventHandler(activateView, SortType, item.name, "");}}
    //             >
    //                 {item.icon}
    //             </li>
    //         ))}
    //     </ul>
    // );
    
    const SetThumbnail = async () => {
        setIsCanvasList(prev => !prev)
        if(isCanvasList) return;
        try {
            const res = await GetCanvasThumbnailAll(canvasId);
            if(res.data.result === 'Success')
            {
                const data = res.data.data;
                let thumbnailList = [];
                for(let key in data)
                {
                    thumbnailList[data[key].SVG_ID] = data[key].THUMBNAIL;
                }
                setThumbnailList(thumbnailList);
            }
        } catch (err) {
            console.error(err);
        }
    }
    window.Re_Picture_List = (json)  => setPictureList(JSON.parse(json));
    window.Re_SetView_Canvas = ()    => setActivateView(enuSpace.GetActivateView());

    return(
        <>
            <Header>
                <div id='EnuCanvasInfo'>
                    <div className='HeaderLeft'>
                        <BtnText label={<EnuSymbol />} onClick={(e) => navigate(-1)} />
                        <span>{canvasName || 'Canvas'}</span>
                    </div>

                    <div className='HeaderCenter'>
                        <BtnText
                            label={<MdKeyboardArrowLeft className="btn" />}
                            onClick={LeftButton}
                        />
                        <span onClick={(e)=>SetThumbnail()}>{activateViewIndex+1}/{pictureList.length}</span>
                        <BtnText
                            label={<MdKeyboardArrowRight className="btn" />}
                            onClick={RightButton}
                        />
                    </div>

                    <div className='HeaderRight'>
                        {/* <ScaleContent>
                            <input id="viewScale" type='number' value={ViewScale}
                                onChange={(e) => setViewScale(e.target.value)}
                                onKeyDown={(e) => { if (e.key === 'Enter') e.target.blur() }}
                                onBlur={(e) => enuSpace.SetScaleAtView(e.target.value)}
                            />
                            <label htmlFor="viewScale">%</label>
                        </ScaleContent> */}
                    </div>
                </div>

                <div id='EnuCanvasSubInfo'>
                    <div className='HeaderLeft'>
                        {RenderSubOption(ActiveTool)}
                    </div>
                    {/* <div className='HeaderRight'>
                        {RenderSortMenu(alignList, "ALIGN")}
                        {RenderSortMenu(orderList, "ORDER")}
                    </div> */}
                </div>
            </Header>

            {isCanvasList &&
                <CanvasContent onClick={(e) => setIsCanvasList(false)}>
                    <div>
                        <ul>
                            {
                                pictureList?.map((pictureInfo, index) => {
                                    const {PICTURE, PICTURE_SYS_ID: SVG_ID} = pictureInfo;
                                    const imgSrc = thumbnailList[SVG_ID] ? `data:image/jpeg;base64,${thumbnailList[SVG_ID]}` : "https://via.placeholder.com/160";
                                    const isActive = activateView === PICTURE;

                                    return(
                                        <li 
                                            key={index}
                                            className={isActive ? 'active' : ''}
                                            onClick={(e) => {SetView(PICTURE);}}
                                        >
                                            <MdClose 
                                                className="btnClose"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    enuSpace.CanvasRequest('DeleteSVG', JSON.stringify({SVG_ID}));
                                                }}
                                            />
                                            <img src={imgSrc} alt="canvas"/>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </CanvasContent>
            }
        </>
    )
}

const CanvasContent = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    width: 100%;
    height: calc(100% - 50px);
    z-index: 10;

    &>div{
        position: relative;
        width: 100%;
        height: 160px;
        background-color: #fff;
        border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
        box-shadow: rgb(0 0 0 / 15%) 0px 8px 16px;
    }

    ul{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0 24px;
        /* justify-content: center; */
        align-items: center;
        gap: 24px;
        overflow: auto;
        box-sizing: border-box;

        li {
            position: relative;
            width: 160px;
            height: 100px;
            flex-shrink: 0;
            border-radius: 4px;
            outline: 1px solid ${({theme}) => theme.base.border_color_Gray};

            &.active{
                outline: 4px solid ${({theme}) => theme.base.border_color_Blue};
            }
            
            &:hover{
                outline-color: ${({theme}) => theme.base.border_color_DarkBlue};
            }

            &> img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .btnClose{
                position: absolute;
                top: 0;
                right: 0;
                font-size: 1.2rem;
                padding: 4px;
                border: 1px solid ${({theme}) => theme.base.border_color_Gray};
                border-radius: 50%;
                background-color: #fff;
                transform: translate(25%, -25%);
                color: ${({theme}) => theme.base.font_color_Gray};
                cursor: pointer;

                &:hover{
                    color: ${({theme}) => theme.base.font_color_Red};
                }
            }
        }
    }
`;

const Header = styled.header`
    #EnuCanvasInfo{
        justify-content: space-between;
        height: 50px;
        display: flex;
        padding: 0px 8px;
        align-items: center;
        border-bottom: 1px solid ${({ theme }) => theme.base.border_color_Gray};
        box-sizing: border-box;

        .HeaderLeft{
            display: flex;
            align-items: center;
        
            span{
                font-size: 1.2rem;
                font-weight: bold;
                color: ${({ theme }) => theme.base.font_color_Black};
            }
        }

        .HeaderCenter {
            display: flex;
            gap: 8px;
            align-items: center;
            font-size: 1.1rem;
            color: ${({ theme }) => theme.base.font_color_Black};

            button{
                padding: 8px;
                svg{
                    font-size: 1.5rem;
                    color: ${({ theme }) => theme.base.font_color_DarkGray};
                }
            }

            span {
                border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
                padding: 6px 8px;
                border-radius: 4px;
                box-sizing: border-box;
                cursor: pointer;
                user-select: none;
                color: ${({ theme }) => theme.base.font_color_Black};

                &:hover{
                    border-color: ${({ theme }) => theme.base.border_color_Blue};
                }
            }
        }
    }

    #EnuCanvasSubInfo{
        position: relative;
        display: flex;
        height: 40px;
        border-bottom: 1px solid ${({ theme }) => theme.base.border_color_Gray};
        padding: 0px 8px;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;

        .HeaderLeft{
            display: flex;
            gap: 8px;

            &>div{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            }

            .SubOption{
                display: flex;
                gap: 8px;
                align-items: center;

                &>li{
                    position: relative;
                    padding: 4px;
                    cursor: pointer;

                    &:hover{
                        background-color: ${({theme}) => theme.base.background_color_LightBlue};
                    }

                    svg{
                        font-size: 1.3rem;
                    }

                    .SubOptionMenu{
                        position: absolute;
                        top: 46px;
                        left: 0;
                        min-width: 64px;
                        width: 136px;
                        background-color: #fff;
                        box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
                        border-radius: 0.3rem;
                        padding: 16px;
                        z-index: 4;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 16px;
                        box-sizing: border-box;
                        cursor: default;
                    }

                    #TextType{
                        font-size: 1rem;
                        color: ${({theme}) => theme.base.font_color_Black};
                    }

                    .TextTypeMenu{
                        width: 180px;
                        gap: 8px;
                        
                        li{
                            position: relative;
                            width: 100%;

                            div{
                                width: 100%;
                                padding: 4px;
                                box-sizing: border-box;
                                cursor: pointer;

                                &:hover{
                                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                                }
                            }
                        }

                        .selectedType{
                            &::after{
                                content: '';
                                position: absolute;
                                left: -8px;
                                top: 50%;
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                background-color: ${({theme}) => theme.base.success_color};
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
            }
        }

        .HeaderRight{
            display: flex;
            gap: 24px;
            
            &>ul{
                position: relative;
                width: 100%;
                display: flex;
                gap: 0px;

                &::after{
                    content: '';
                    position: absolute;
                    left: -12px;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: ${({ theme }) => theme.base.border_color_DarkGray};
                }
                
                li{
                    padding: 4px;
                    cursor: pointer;

                    &:hover{
                        background-color: ${({theme}) => theme.base.background_color_LightBlue};
                    }
                }
            }
        }
    }
`;

// const ScaleContent = styled.div`
//     border: 1px solid ${({theme}) => theme.base.border_color_Gray};
//     border-radius: 4px;
//     box-sizing: border-box;
//     font-size : .9rem;
//     padding-right: 8px;
//     user-select: none;

//     input {
//         width: 40px;
//         text-align: right;
//         color: ${({ theme }) => theme.base.font_color_Black};
//         font-size: .9rem;
//         height: 28px;
//         box-sizing: border-box;
//         border: 0 none;

//         &:focus-visible{
//             outline: 0;
//         }
//     }

//     input[type="number"]::-webkit-outer-spin-button,
//     input[type="number"]::-webkit-inner-spin-button {
//         -webkit-appearance: none;
//         -moz-appearance: none;
//         appearance: none;
//     }

//     label{
//         margin-left: 4px;
//         color: ${({theme}) => theme.base.font_color_DarkGray};
//     }
// `;

const ColorChip = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 0.2rem;
    background-color: ${({Color}) => Color};
    border: 1px solid ${({ Color, theme }) => Color === '#FFFFFF' ? theme.base.border_color_Gray : Color};
    box-sizing: border-box;
    cursor: pointer;

    &:hover{
        outline: 4px solid ${({theme}) => theme.base.border_color_LightGray};
    }
    
    &.selectedColor{
        outline: 4px solid ${({theme}) => theme.base.border_color_Gray};
    }
`;