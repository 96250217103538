import { useState, useEffect } from 'react';
import moment from 'moment';

import { TableContent } from 'components/css/common';
import { BtnSecondary} from "components/atom/Button";
// import { GetTagHistory } from 'api/DeviceApi'

import 'react-datepicker/dist/react-datepicker.css';
import styled from "styled-components"
import { MdDownload } from "react-icons/md";

const marginBottom = 16;
export default function TableModal({enuSpace, _Modal}){
    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());
    // const [interval, setInterval] = useState(1000);
    // const [dateRange, setDateRange] = useState({ startDate: new Date(), endDate: new Date() });
    // const [dateModal, setDateModal] = useState(false);
    // const [data, setData] = useState();
    const [parsed_data, setParsed_data] = useState([]);
    // const [TAG, setTag] = useState({});
    // const MEMBER_SYSTEMID = _Modal.data[0].MEMBER_SYSTEMID;
    // const DB_TABLE_ID = _Modal.data[0].DB_TABLE_ID;

    useEffect(() => {
        let dataArray = _Modal.data;
        if (!Array.isArray(_Modal.data)) {
            dataArray = Object.values(_Modal.data);
        }
        
        parsing_data(dataArray);

    },[_Modal.data])

    // const func_GetTagHistory = (props) => {
    //     setIsLoadingPage(true)
    //     const promises = props.map((prop) => {
    //         const start_time = new Date(prop.START_TIME);
    //         const end_time = new Date(prop.END_TIME);

    //         return GetTagHistory(prop.MEMBER_SYSTEMID, prop.DB_TABLE_ID, prop.TAG, start_time, end_time, prop.INTERVAL);
    //     });
        
    //     Promise.all(promises)
    //         .then((res) => {
    //             setData([]);
    //             const dataArray = res.map((item) => item.data);
    //             setData(dataArray)
    //             setIsLoadingPage(false)
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }

    // const func_GetTagHistoryBtn = (start_date, end_date) => {
    //     setIsLoadingPage(true)
    //     let duration = 1000;
    //     const diffInDays = Math.ceil((end_date - start_date) / (1000 * 60 * 60 * 24)); 
    //     if (diffInDays <= 1) 
    //     {
    //         duration = 1000; 
    //     } else if (diffInDays <= 7) 
    //     {
    //         duration = 20000; 
    //     } 
    //     else if (diffInDays <= 30) 
    //     {
    //         duration = 80000;
    //     }
    //     else if (diffInDays <= 90) 
    //     {
    //         duration = 240000;
    //     }
    //     else 
    //     {
    //         duration = 1200000;
    //     }
    //     const promises = () => {
    //         GetTagHistory(
    //             MEMBER_SYSTEMID,
    //             DB_TABLE_ID,
    //             TAG,
    //             moment.utc(start_date).toISOString(),
    //             moment.utc(end_date).toISOString(),
    //             duration        
    //         ).then((res) => {
    //             //setData([]);
    //             const dataArray = res.map((item) => item.data);
    //             //setData(dataArray)
    //             parsing_data(dataArray);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });

    //         promises();
    //     };
    // }

    
    
    const parsing_data = (props) => {
        let datasets = {};
        if(props !== undefined)
        {
            props.forEach((prop) => {
                let unescapedData = "";
                let unquotedData = "";
                let parsedData = "";
                if(prop.TYPE === 'func')
                {
                    parsedData = prop.DATA;
                }
                else
                {
                    unescapedData = prop.DATA.replace(/\\"/g, '"');
                    unquotedData = unescapedData.slice(1, -1); 
                    parsedData = JSON.parse(unquotedData);
                }
                // setStartDate(new Date(parsedData[0].TIMESTAMP))
                // setEndDate(new Date(parsedData[parsedData.length - 1].TIMESTAMP))
                // setTag(parsedData[0].TAG)
                parsedData.forEach((item) => {
                    if (item.RESULT === 'Ok' && !isNaN(item.VALUE)) {
                        const timestamp = new Date(item.TIMESTAMP).getTime();
                        
                        if (!datasets[item.TAG]) {
                            datasets[item.TAG] = [];
                        }
        
                        datasets[item.TAG].push({
                            label: item.TAG,
                            timestamp,
                            value: parseFloat(item.VALUE),
                        });
                    }
                });
            });
            const formattedDatasets = Object.entries(datasets).map(([label, data]) => {
                const formattedData = data.map((item) => ({
                    label: item.label,
                    timestamp: moment(item.timestamp).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss.SSS'),
                    value: item.value,
                }));
                const timestamps = formattedData.map((item) => item.timestamp);
                const values = formattedData.map((item) => item.value);
                const labels = formattedData.map((item) => item.label);
        
                const sampledData = timestamps.map((timestamp, index) => ({
                    x: timestamp,
                    y: values[index],
                    label: labels[index]
                }));
        
                return {
                    label,
                    data: sampledData
                };
            });
            setParsed_data(formattedDatasets);
        }
    }
    
    const data_download = async () => {
        if (parsed_data !== undefined) {
            const newDataArray = parsed_data.map(item => item.data);
            try {
                const csvData = [];
                const headerRow = ['idx', 'TIMESTAMP'];
                const timestamps = [];

                newDataArray.forEach((tagData, index) => {
                    headerRow.push(tagData[0].label);
                    timestamps.push(tagData.map(data => {
                        const date = moment(data.x).tz('Asia/Seoul').format('YYYY-MM-DDTHH:mm:ss.SSS');
                        return date;
                    }));
                    
                });
                csvData.push(headerRow.join(','));

                const maxLength = Math.max(...newDataArray.map(data => data.length));

                for (let i = 0; i < maxLength; i++) {
                    const rowData = [i + 1]; 
                    rowData.push(timestamps[0][i]);
                    newDataArray.forEach(tagData => {
                        if (tagData[i]) {
                            rowData.push(tagData[i].y || '');
                        } else {
                            rowData.push('');
                            rowData.push('');
                        }
                    });
                    csvData.push(rowData.join(','));
                }

                const csvString = csvData.join('\n');
            
                const blob = new Blob([csvString], { type: 'text/csv' });
        
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'ChartData_CSVFile.csv';
        
                document.body.appendChild(link);
                link.click();
        
                document.body.removeChild(link);
            } catch (error) {
                console.log('Error', error);
            }
        }
    }

    return(
        <>
            <SelectTableInfoBox>
                <BtnBox>
                    <div> 
                        <div style={{position:'absolute', zIndex: '8',  display:'flex', }}>
                            {/* <MdCalendarMonth style={{fontSize:"1.3rem"}}/>
                            <DatePicker
                                selected={startDate}
                                onChange={(date)=>setStartDate(date)}
                                dateFormat="yyyy/MM/dd h:mm aa"
                                timeInputLabel="START TIME: "
                                timeFormat="HH:mm"
                                timeCaption="time"
                                timeIntervals={15}
                                showTimeSelect
                                wrapperClassName="time-picker-wrapper"
                            /> 

                            <DatePicker
                                selected={endDate}
                                onChange={(date)=>setEndDate(date)}
                                dateFormat="yyyy/MM/dd h:mm aa"
                                timeInputLabel="START TIME: "
                                timeFormat="HH:mm"
                                timeCaption="time"
                                timeIntervals={15}
                                showTimeSelect
                                wrapperClassName="time-picker-wrapper"
                            /> 
                            <BtnSecondary type='sm' label='조회' onClick={() => {
                                func_GetTagHistoryBtn(startDate, endDate);
                            }}/> */}
                        </div>
                    </div>
                    <div style={{display:'flex', gap:'4px'}}>
                        <BtnSecondary title='csv Download' type='sm' label={<MdDownload />} onClick={() => {data_download();}}/>
                    </div>
                    
                </BtnBox>
                
            </SelectTableInfoBox>

            <SeverDBContent>
                <GlobalVariableDBTable>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th className='date'>Date</th>
                                    {parsed_data.length > 0 && parsed_data.map((data, index) => (
                                        <th key={index} className='label'>{data.label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                            {parsed_data.length > 0 && parsed_data[0].data.map((data, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td className='date'>{data.x}</td>
                                    {parsed_data.map((rowData, index) => (
                                        <td key={index} className='label'>{rowData.data[rowIndex].y}</td>
                                    ))}
                                </tr>
                            ))}
                            
                            </tbody>
                            
                        </table>
                    </div>
                </GlobalVariableDBTable>
            </SeverDBContent> 
        </>
    )
};


//스타일-----------------------------------------------
const SeverDBContent = styled.div`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 8px;
    height: ${(props) => props.isVisibility ? 'calc(85% - 102px)' : 'calc(85% - 56px)'};
    flex-direction: column;
`


const GlobalVariableDBTable = styled(TableContent)`
    height: calc(100% - 44px);
    z-index:1;

    .table-container {
        overflow-x: auto;
        max-width: 100%;
    }

    table {
        width: 100%;
        min-width: 600px; 
    }

    .date {
        width: 180px;
        text-align: center;
    }

    .label {
        width: 100px;
        text-align: center;
    }

    .index {
        width:200px;
    }

    .name {
        text-align:center;
    }

 `;

 const BtnBox = styled.div`
    margin-bottom:${marginBottom}px;
    display:flex;
    justify-content:space-between;

    .btn{
        display:flex;
    
    }

    .btn > * {
       
    }

    .date{
        width:200px;
    }
    
    .datebox{
        display:flex;
        gap:4px;
        padding:4px;
        cursor:pointer;
    }

    .download > * {
        font-size:1.5rem;
    }
    
    .interval {
        display:flex;
        gap:8px;
    }

    .interval InputContent{
        height:75%;
    }
`

const SelectTableInfoBox = styled.div`
    
`;

