import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import InputComponent from "components/atom/InputComponent";

export default function Snapshot({_data, _ChangeEvent, _disabled, ...props}){
    const {
        id: NodeId,
        snapshot: NodeSnapshot,
        snapshot_interval: NodeSnapshotInterval,
    } = _data;
    
    return(
        <PropertiesContent 
            _Title="Snapshot" 
            _Toggle={
                <BtnToggle 
                    _toggleID="snapshot" 
                    _isToggleValue={NodeSnapshot} 
                    _disabled={(!NodeId || _disabled )&& true} 
                    _IsToggle={(attributeName, attributeValue) => _ChangeEvent([{ attributeName, attributeValue }])}
                />
            } 
            _ToggleValue={!['0', 'false', undefined].includes(NodeSnapshot)}
        >
            <div>
                <InputComponent 
                    label='interval' 
                    attributeName='snapshot-interval' 
                    valueType='number' 
                    value={NodeSnapshotInterval} 
                    disabled={_disabled}
                    changeEvent={_ChangeEvent} 
                />
            </div>
        </PropertiesContent>
    )
}