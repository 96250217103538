import { useSelector } from 'react-redux';
import ModalContent from "./ModalContent";

function ModalSpace({enuSpace}){
    const ModalList = useSelector(state => state.modalPopup);

    return(
        <>
            {ModalList && 
                ModalList.map((modal, index) => (
                    <ModalContent key={modal.id} _index={index} enuSpace={enuSpace} _Modal={modal}/>
                ))
            }
        </>
    )
}

export default ModalSpace;
