import { useState } from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import {RadioComponent} from "components/atom/RadioContent";
import InputComponent from "components/atom/InputComponent";
import SelectList from "components/atom/SelectList";
import ColorComponent from "components/molecule/ColorComponent";
import FontFamily from 'components/common/FontFamily';
import {FontSizeIcon} from 'components/common/EnuSpaceIcon';
import { GroupName, ToggleRect } from 'components/css/common';

import styled from "styled-components";
import { MdStart, MdFormatItalic } from "react-icons/md";

export default function TrendLabelInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        label_font_family: NodeLabelFontFamily,
        label_font_weight: NodeLabelFontWeight,
        label_font_style: NodeLabelFontStyle,
        label_visible: NodeLabelVisible,
        label: NodeLabel,
        label_rotation: NodeLabelRotation,
        label_gap: NodeLabelGap,
        label_position: NodeLabelPosition,
        focus: NodeFocus,
        label_font_size: NodeLabelFontSize,
    } = _data;

    const [fontClass] = useState(new FontFamily());

    const PositionData_ud = { 'label_position_up': <MdStart style={{"transform":"rotate(-90deg)"}}/>, 'label_position_down': <MdStart style={{"transform":"rotate(90deg)"}}/> }
    const PositionData_lr = { 'label_position_left': <MdStart style={{"transform":"rotate(180deg)"}}/>, 'label_position_right': <MdStart style={{"transform":"rotate(deg)"}}/> }

    const SetNodeLabelPosition = () =>{
        if(NodeFocus === 'AxisX'){
            return NodeLabelPosition  === 'up' ? 'label_position_up' : 'label_position_down'
        }else{
            return NodeLabelPosition  === 'left' ? 'label_position_left' : 'label_position_right'
        }
    }

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return (
        <PropertiesContent _Title="label" 
            _Toggle={
                 <BtnToggle _toggleID="label-visible" _isToggleValue={NodeLabelVisible} _disabled={_disabled} _IsToggle={(attributeName, attributeValue)=> _ChangeEvent( [{attributeName, attributeValue}])}/>
            } 
            _ToggleValue={!['0', 'false', undefined].includes(NodeLabelVisible)}
        >
            <div>
                <CustomInputComponent label='label' valueType='number' labelWidth='44px' value={NodeLabel} {...commonProps}/>
            </div>
            <div>
                <InputComponent label='rotate' attributeName='label-rotation' labelWidth='46px' valueType='number' value={NodeLabelRotation} {...commonProps}/>
                <InputComponent label='gap' attributeName='label-gap' labelWidth='46px' valueType='number' value={NodeLabelGap} {...commonProps}/>
            </div>
            <div>
                <GroupName width={76}>position</GroupName>
                <RadioComponent
                    attributeName='legend-pos'
                    dataList={NodeFocus === 'AxisX' ? PositionData_lr : PositionData_ud } 
                    selectedValue={SetNodeLabelPosition()}
                    disabled={_disabled}
                    changeEvent={(attributeName, attributeValue) =>_ChangeEvent([{attributeName, attributeValue}])}
                />
            </div>
            <div className="margin-top-div">
                <SelectList
                    attributeName="label-font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeLabelFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <SelectList
                    attributeName="label-font-weight"
                    dataList={fontClass.GetFontWeight(NodeLabelFontFamily)}
                    selectedValue={fontClass.CheckFontWeight(NodeLabelFontFamily, NodeLabelFontWeight)}
                    {...commonProps}
                />
                <InputComponent
                    label={<FontSizeIcon />}
                    attributeName="label-font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeLabelFontSize}
                    {...commonProps}
                />
                <ToggleRect 
                    disabled={_disabled}
                    onClick={(e)=>{
                        if (_disabled) return;
                        const attributeValue = Array.isArray(NodeLabelFontStyle) ? 'italic' : ['normal','0'].includes(NodeLabelFontStyle) ? 'italic' : 'normal';
                        _ChangeEvent([{attributeName: 'label-font-style', attributeValue}]);
                    }}
                >
                    <MdFormatItalic className={Array.isArray(NodeLabelFontStyle) ? '' :  ['normal', '0'].includes(NodeLabelFontStyle) ? '' : 'active'}/>
                </ToggleRect>
            </div>
            <div>
                <ColorComponent label="font" attributeName="label-font-color" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
        </PropertiesContent>
    );
}

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;