import { TransformNode, SceneLoader } from "@babylonjs/core";
import Base from "babylon/class/Base";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";
import Custom from "babylon/class/Custom"

export default class AssetTransformNode extends Base {
    constructor(EnuScene, url, filename, fromSavedFile = false) {
        //TODO: Asset file extension -> switch
        const asset_root = new TransformNode(`__${filename}_root__`);
        const childrenList = [];

        super(EnuScene, asset_root);

        //TODO: if no such file, throw an error
        if (fromSavedFile !== true) {
            SceneLoader.ImportMesh(
                "",
                url,
                filename,
                EnuScene.scene,
                (meshes, particleSystems, skeletons) => {
                    meshes.forEach((mesh) => {
                        mesh.parent = asset_root;
                        new Custom(EnuScene, mesh, asset_root);
                        childrenList.push(mesh.uniqueId)
                    });

                    this.children = asset_root.getChildren();
                    this.childrenList = childrenList;
                    console.log(`Asset ${filename} imported.`);
                }
            );
        } else {
            this.children = [];
        }
        // asset_root.rotation.x = Math.PI / 2;        

        this._url = url;
        this._filename = filename;
        this.objectType = SUPPORT_OBJECT_TYPE.ASSETTRANSFORMNODE;
    }

    set url(url) { this._url = url;}
    get url() { return this._url; }
    
    set filename(filename) {this._filename = filename;}
    get filename() {return this._filename;}

    getPropertyMap() {
        const basePropertyMap = super.getPropertyMap();
        return {
            ...basePropertyMap,
            url: this.url,
            filename: this.filename,
            objectType: this.objectType,
            childrenList: this.childrenList,
        };
    }
}
