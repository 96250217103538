import { useState, useEffect} from 'react';

import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {SelectContentBottom, GroupName, ModalBottomBox, WarningMsg} from "components/css/common";
import useModalPopup from "hooks/useModalPopup"
import useProjectData from 'hooks/useProjectData'
import {SUPPORT_SVG_TYPE, DATA_TYPE} from "components/common/EnuSpaceEnum";

import styled from "styled-components";
import { MdStar } from "react-icons/md";

export default function VariableContent({enuSpace, _Modal}){
    const {
        id: ModalId,
        type: ModalType,
        data:{
            attribute: ModalDataAttribute,
            originalValue: ModalDataOriginalValue,
            symbolName: ModalSymbolName,
            type: ModalDataType,
            url: ModalDataUrl,
            useID: ModalDataUseID,
            targetRowElementInfo: ModalTargetRowElementInfo,
            desc: ModalDesc,
        }
    } = _Modal;

    const {DeleteModal} = useModalPopup();
    const CloseModal = (e) =>DeleteModal(ModalId);
    const {DeleteNodeData, SetTreeData, SetNodeData} = useProjectData();
    const [UserStructList, setUserStructList] = useState(); // 사용자가 추가한 struct 리스트
    const [CreateInfo, setCreateInfo] = useState({
        attrType: DATA_TYPE.BOOL,
        attrVariable: '',
        attrValue: '',
        attrDesc: '',
        error:{
            msg : ''
        }
    });
    
    const IsEditVariableItem = ModalType === 'EditVariableItem';
    const IsSupportDataType = Object.values(DATA_TYPE).includes(CreateInfo.attrType);

    useEffect(() => {
        if (ModalType !== 'EditVariableItem') return;

        let [attrType, attrVariable] = ModalDataAttribute.split(' '); // bool 3 => [bool, 3]

        if (attrType.includes('#')) {
            attrType = attrType.replace('#', '');
            attrVariable = `#${attrVariable}`;
        }

        setCreateInfo({
            attrType,
            attrVariable,
            attrValue: ModalDataOriginalValue,
            attrDesc: ModalDesc,
            error: {
                msg: ''
            }
        });
    }, [ModalType, ModalDataAttribute, ModalDataOriginalValue, ModalDesc]);

    const CreateVariable = (e) => {
        const {attrVariable: strName , attrValue: strValue, attrType, attrDesc} = CreateInfo;
        if(strName === ''){
            setCreateInfo({...CreateInfo, error:{ msg : 'Variable을 입력해 주세요.'}})
            return;
        }

        let strSvgUrl = ModalDataUrl;
        let strUseID = '';

        if(IsEditVariableItem){
            if(ModalDataType === SUPPORT_SVG_TYPE.PICTURE){
                strSvgUrl = strSvgUrl.join('\\');
                strUseID = ModalDataUseID;
            }else if(ModalDataType === SUPPORT_SVG_TYPE.HMI){
                const URL = strSvgUrl.slice();
                URL.pop();
                strSvgUrl = URL.join('\\');
                strUseID = ModalDataUseID;
            }else{
                const URL = strSvgUrl.slice();
                URL.pop();
                strSvgUrl = URL.join('\\');
            }

            enuSpace.ModifyVariable(strSvgUrl, ModalDataType, ModalSymbolName, strName, strValue, strUseID);
        }else
            enuSpace.CreateVariable(strSvgUrl, ModalDataType, ModalSymbolName || '', attrType, strName, strValue, attrDesc, "");
    }
    window.Re_CreateVariable = (_msg)=> CreateOrUpdateNode(_msg);
    window.Re_ModifyVariable = (_msg)=> CreateOrUpdateNode(_msg);

    const CreateOrUpdateNode = (_msg) =>{
        const {type, data} = JSON.parse(_msg);
        if(type === 'success'){
            SetTreeData(JSON.parse(enuSpace.GetProjectTree()));
            DeleteNodeData();
            SetNodeData(ModalTargetRowElementInfo);

            if(typeof window.ReloadVariableTree === 'function')
                window.ReloadVariableTree();        

            if(ModalTargetRowElementInfo.path !== undefined){
                window.OpenTreeAfterCreate(ModalTargetRowElementInfo['path'][1])
            }

            CloseModal();
        }else if(type === 'failed'){
            setCreateInfo({...CreateInfo, error:{ msg : data}})
        }
    }

    // 사용자가 추가한 Struct 리스트를 가져와 useState에 저장
    window.Re_GetDefineStructName_AddVariableItem = (_JsonData) =>{
        const GlobalDataList = _JsonData.data.Global;
        if(GlobalDataList === undefined) return;

        // Variable 탭에서 Add 번튼을 눌려 모달탕을 띄운 경우
        const isAllPush = ModalTargetRowElementInfo.from === 'Variable';
        const VariableList = GlobalDataList.reduce((_VariableList, _GlobalData) => {
            const {type, file} = _GlobalData;
            if (type && (file === ModalDataUrl || isAllPush)) {
                type.forEach(_struct => _VariableList.push(_struct));
            }
            return _VariableList;
        }, []);
    
        setUserStructList(VariableList.length === 0 ? null : VariableList);
    }

    const props = {
        type: DATA_TYPE.STRING,
        onChange: (e) => setCreateInfo({...CreateInfo, [`${e.target.name}`]: e.target.value,  error:{msg : ''}}),
        onMouseDown: (e) => e.stopPropagation(),
    }

    return(
        <>
            <FlexDiv>
                <GroupName width={100} htmlFor='attrType' className="labelTitle">Type</GroupName>
                <SelectContentBottom
                    id='VariableDataType'
                    value={CreateInfo.attrType}
                    disabled={IsEditVariableItem}
                    onChange={(e)=>setCreateInfo({...CreateInfo, attrType: e.target.value})} 
                    onClick={(e)=> enuSpace.GetDefineStructName('AddVariableItem')} 
                    onFocus={(e) =>document.activeElement.blur()}
                >
                    {DATA_TYPE && 
                        Object.values(DATA_TYPE).map((DataType)=>( <option key={DataType} value={DataType}>{DataType}</option> ))
                    }

                    {/* 사용자가 추가한 Struct */}
                    {(UserStructList) && <optgroup style={{'color': '#EDEDED'}} label="-------------"></optgroup>}
                    {UserStructList&&
                        UserStructList.map((Struct)=>(  <option key={Struct} value={Struct}>{Struct}</option> ))
                    }
                </SelectContentBottom>
            </FlexDiv>
            <FlexDiv>
                <GroupName width={100} htmlFor='attrVariable' ><MdStar className="required" />Variable</GroupName>
                <Input 
                    id='attrVariable' 
                    name='attrVariable' 
                    value={CreateInfo.attrVariable || ''} 
                    disabled={IsEditVariableItem}
                    {...props}
                />
            </FlexDiv>
            <FlexDiv>
                <GroupName width={100} htmlFor='attrValue' >Initial Value</GroupName>
                <Input 
                    id='attrValue' 
                    name='attrValue' 
                    value={CreateInfo.attrValue || ''} 
                    disabled={!IsSupportDataType}
                    {...props} 
                />
            </FlexDiv>
            <FlexDiv>
                <GroupName width={100} htmlFor='attrDesc' >Description</GroupName>
                <Input 
                    id='attrDesc' 
                    name='attrDesc' 
                    value={CreateInfo.attrDesc || ''} 
                    disabled={!IsSupportDataType}
                    {...props} 
                />
            </FlexDiv>

            {CreateInfo.error.msg !== '' && 
               <WarningMsg style={{marginTop: '16px'}}>{CreateInfo.error.msg}</WarningMsg>
            }
            
            <ModalBottomBox>
                <BtnSecondary label="Cancel"  onClick={CloseModal}/>
                <BtnPrimary label="Accept" onClick={CreateVariable}/>
            </ModalBottomBox>
        </>
    )
}

// 스타일------------------------------------------------------
const FlexDiv = styled.div`
    display: flex;
`;

const Input = styled.input`
    font-size: .9rem;
    height: 28px;
    box-sizing: border-box;
    border: 0 none;
    flex-grow: 1;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};

    &:focus{
        outline:none;
        border-bottom: 1px solid ${({theme}) => theme.base.border_color_Blue};
    }

    &:selection{
        background-color: ${({theme}) => theme.base.background_color_lightBlue};
        border: 0 none;
        border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    }

    &:disabled{
        background-color: ${({theme}) => theme.base.background_color_PaleGray};
    }
`;