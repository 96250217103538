import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";

import styled from "styled-components";

export default function ChartDataList({_data, _ChangeEvent, _disabled, ...props}){
    const {data, label} = _data;

    const commonProps = {
        changeEvent: _ChangeEvent,
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title="Data">
            <div>
                <CustomInputComponent label='data' labelWidth='38px' value={data} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='label' labelWidth='38px' value={label} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent className="paddingLeft" label='num' attributeName='label-num' labelWidth='30px' value={label} {...commonProps}/>
            </div>
        </PropertiesContent>
    )
}

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }

    &.paddingLeft{
        padding-left: 8px;
    }
`;