import { useId } from "react";
import styled from "styled-components";
import { MdStar } from "react-icons/md";

export default function InputFieldLabel({id, label, placeholder, propsState, inputType = 'text', children, checkEvent, isRequired = false}){
    const [state, setState] = propsState;

    return(
        <div>
            <Label htmlFor={id}>
                {isRequired && <MdStar className="inputRequired" />}{label}
            </Label>
            <InputContent>
                <input 
                    type={inputType} 
                    id={id} 
                    name={id} 
                    value={state[id]}
                    placeholder={placeholder}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setState(prevState => ({ ...prevState, [id]: newValue }));
                        checkEvent && checkEvent(id);
                    }}
                    autoComplete="off"
                />
                { children }
            </InputContent>
        </div>
    )
}

// 240610 VerticalLabelInput를 사용
export function InputWithVerticalLabel({id, label, placeholder, propsState, inputType = 'text', children, checkEvent, isRequired = false, onKeyDownEvent, disabled = false}){
    const [state, setState] = propsState;

    const Submit = (e) =>{
        if(e.key === 'Enter') 
            onKeyDownEvent && onKeyDownEvent(e);
    }

    return(
        <div>
            <Label htmlFor={id}>
                {isRequired && <MdStar className="inputRequired" />}{label}
            </Label>
            <InputContent>
                <input 
                    type={inputType} 
                    id={id} 
                    name={id} 
                    value={state[id] || ''}
                    placeholder={placeholder}
                    onKeyDown={Submit} 
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setState(prevState => ({ ...prevState, [id]: newValue }));
                        checkEvent && checkEvent(id);
                    }}
                    autoComplete="off"
                    disabled={disabled}
                />
                { children }
            </InputContent>
        </div>
    )
}

export function VerticalLabelInput({label, placeholder, inputType = 'text', value, isRequired=false, errorMsg, inputRef, disabled, onKeyDownEvent, onChangeEvent, onBlurEvent}){
    const inputId = useId();

    const Submit = (e) =>{
        if(e.key === 'Enter') 
            onKeyDownEvent && onKeyDownEvent(e);
    }

    return(
        <Content>
            <Label htmlFor={inputId}>
                {isRequired && <MdStar className="inputRequired" />}{label}
            </Label>
            <Input 
                ref={inputRef}
                id={inputId}
                type={inputType}
                placeholder={placeholder}
                value={value || ''}
                autoComplete="off"
                disabled={disabled}
                onKeyDown={Submit}
                onChange={(e) => {
                    onChangeEvent && onChangeEvent(e.target.value)
                }}
                onBlur={(e)=>{
                    onBlurEvent && onBlurEvent(e.target.value);
                }}
            />
            <ErrorMsg>
                {errorMsg}
            </ErrorMsg>
        </Content>
    )
}

const Label = styled.label`
    font-size: .9rem;
    color: ${({ theme }) => theme.base.font_color_DarkGray};

    &>svg{
        font-size: .6rem;
        color: ${({ theme }) => theme.base.background_color_Red};
    }
`;

const InputContent = styled.div`
    display: flex;
    margin-top: 8px;
    margin-bottom: 16px;
   
    &>input{
        height: 38px;
        border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
        border-radius: 4px;
        padding: 6px 16px;
        box-sizing: border-box;
        flex-grow: 1;

        &::placeholder {
            color: ${({ theme }) => theme.base.font_color_Gray};
        }

        &:focus{
            outline: 1px solid ${({ theme }) => theme.base.border_color_DarkBlue};
        }

        &:disabled{
            border: 0 none;
            background-color: ${({ theme }) => theme.base.background_color_LightGray};
        }
    }

    &>button{
        margin-left: 8px;
    }
`;

const Input = styled.input`
    height: 38px;
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    border-radius: 4px;
    padding: 6px 10px;
    box-sizing: border-box;
    flex-grow: 1;
    margin: 6px 0 2px;

    &::placeholder {
        color: ${({ theme }) => theme.base.font_color_Gray};
    }

    &:focus{
        outline: 2px solid ${({ theme }) => theme.base.border_color_Blue};
    }

    &:disabled{
        border: 0 none;
        background-color: ${({ theme }) => theme.base.background_color_LightGray};
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

const ErrorMsg = styled.span`
    font-size: .9rem;
    min-height: 14px;
    color: ${({ theme }) => theme.base.font_color_Red};
    margin-bottom: 6px;
`;