import { useState } from 'react';

import useModalPopup from 'hooks/useModalPopup';
import Notification from "components/atom/Notification";
import MouseRightClickMenuContent from "components/molecule/MouseRightClickMenuContent";
import useRightMenu from "hooks/useRightMenu";

import { MdOutlineShare, MdFlipToFront } from "react-icons/md";
import styled from "styled-components"
import { useSelector, shallowEqual } from 'react-redux';

export default function RuntimeCanvas({enuSpace, setIsShareUser, _width = '100%', children}) {
    const BASE_DATA = useSelector(state => state.projectData.BASE_DATA);
    const [MousePos, setMousePos] = useState({x:0, y:0});

    const {AddModal} = useModalPopup();

    const RightMenuInfo = useSelector(state => {
        if (state.rightMenu.type === 'RunTimePdf')
            return state.rightMenu;
        return null;
    }, shallowEqual);
    const {ShowRightMenu, CloseRightMenu} = useRightMenu();
    
    const MouseRightClick = (e) => {
        e.preventDefault();
        CloseRightMenu();
    }

    const GetNodeInfo = (e) =>enuSpace.GetNodeData("Canvas");

    const NewWindowOpenPdf = (PdfPath = RightMenuInfo.data.path) =>{;
        localStorage.setItem('projectId',  BASE_DATA.projectId);
        
        window.open(
            `/Publish/DocumentViewer?path=${PdfPath}&extension=pdf&mode=read`, 
            PdfPath, 
            `width=700, height=900, location=no, status=no, scrollbars=yes, menubar=no`
        );
    }

    const SharedPdf = (e) => setIsShareUser(prev => ({...prev, visibility: true, path: RightMenuInfo.data.path}));

    window.Re_GetNodeData = (resData) =>{
        const targetData = resData.data[0];
        if(resData.type === 'success' && targetData.object_name === 'pg-editbox'){
            AddModal('InputBox', `InputBox (${targetData.id})`, 'InputBox', {targetData, ACTIVE_PICTURE_URL: targetData.url});
        }
    }

    window.Re_ShowDocument = (path, page) =>{
        const MetaverseFlag = enuSpace.GetMetaverseFlag();
        if(MetaverseFlag)
            ShowRightMenu({'type':'RunTimePdf', 'top':MousePos.y, 'left':MousePos.x, 'data':{path, page}});
        else
            NewWindowOpenPdf(path);
    }

    return (
        <CanvasBox width={_width} id="canvasSpace" onContextMenu={MouseRightClick}
            onMouseMove={(e)=>{
                setMousePos({x: e.pageX, y: e.pageY});
            }}
        >
            {children && children}
            <Notification />
            <div id="Canvas_box" 
                onContextMenu={(e) => e.preventDefault()} 
                onClick={(e)=>GetNodeInfo(e)}
                onKeyDown={(e)=>{
                    if( e.ctrlKey && e.key === 'a') 
                        e.preventDefault();
                }}
            >
                {RightMenuInfo?.type === 'RunTimePdf' && 
                    <MouseRightClickMenuContent _positionTop={RightMenuInfo['top']} _positionLeft={RightMenuInfo['left']}>
                        <li onClick={(e)=>NewWindowOpenPdf()}>
                            <span><MdFlipToFront style={{ 'color': 'steelblue' }}/>Open Pdf</span>
                        </li>
                        <li onClick={SharedPdf}>
                            <span><MdOutlineShare style={{'color': 'royalblue' }}/>Share Pdf</span>
                        </li>
                    </MouseRightClickMenuContent>
                }
            </div>
        </CanvasBox>
    )
}

const CanvasBox = styled.div`
    position: relative;
    width: ${({width}) => width };

    &>div{
        height: 100%;
    }

    canvas:focus-visible{
        outline: 0px none;
    }
`;
