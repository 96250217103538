import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
// import { encode } from "@msgpack/msgpack";

export default function useDAQEnterWorld(enuSpace) {
  const [connection, setConnection] = useState();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const BASE_DATA = useSelector(state => state.projectData.BASE_DATA);

  const DAQRequest = useCallback(() => {
    const {userId: MEMBER_SYSTEMID, projectId:PROJECT_SYSTEMID} = BASE_DATA;
    // console.log('useDAQEnterWorld: ', MSG)
    enuSpace.DAQRequest('EnterWorld', JSON.stringify({MEMBER_SYSTEMID, PROJECT_SYSTEMID}));
  }, [enuSpace, BASE_DATA]);

  useEffect(() => {
    if (connection) DAQRequest();

    return async () => {
      if (connection === undefined) return;
      try {
        await connection.stop();
      } catch (e) {
        console.error(e);
      }
    }
  }, [connection, DAQRequest]);

  const connectionStart = async () => {
    try {
        const connection = new HubConnectionBuilder()
            .withUrl(`https://${window.location.host}/DAQ/Hub/PublishHub`)
            .withHubProtocol(new MessagePackHubProtocol())
            .configureLogging(LogLevel.Information)
            .build();

        connection.on("GET_TAG_VALUES", (message) => {
        enuSpace.DAQRequest('GetTagValues', message);
      });

      connection.onclose(e => {
        setConnection();
        setMessages([]);
        setUsers([]);
      });

      await connection.start();
      setConnection(connection);
      return connection;
    } catch (err) {
      throw err;
    }
  }

  /**
   * ��û �޽��� ����
   * @param {string} msgKey   - �̺�Ʈ �� ex) "EnterWorld", "Navigation", "AvatarMove" ��
   * @param {Object} message  - �޽��� ��ü ex) { "PUBLISH_SYSTEMID": "test", "MEMBER_SYSTEMID": "�׽�Ʈ" }
   * @param {Object} _connection - _connection ��ü (�⺻��: connection)
   */
  const RequestMsg = async (msgKey, message, _connection = connection) => {
      try {
          // const encoded: Uint8Array = encode(message);
          //console.log(message);
          //console.log(encoded);
      await _connection.invoke(msgKey, message);
    } catch (err) {
      throw err;
    }
  }

  const closeConnection = async () => {
    try {
      await connection.stop();
    } catch (err) {
      throw err;
    }
  }


    window.Re_DAQ_Enter_World = (MSG) => {
        RequestMsg("EnterWorld", JSON.parse(MSG));
  }

  return { connection, messages, setMessages, users, connectionStart, closeConnection }
}