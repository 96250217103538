import { useState, useEffect, useRef } from "react";

import { BtnPrimary } from "components/atom/Button";
import { VerticalLabelInput } from "components/molecule/InputFieldLabel";
import { isEmail } from "components/Utility";
import { WarningMsg } from "components/css/common";
import useMember from "hooks/useMember";

import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

function LoginPage() {
  const navigate = useNavigate();
  const { Login } = useMember();

  const [loginForm, setLoginForm] = useState({ email: "", pw: "" });

  const inputRef = useRef({ email: null, pw: null });
  const [Msg, setMsg] = useState("");

  useEffect(() => {
    setMsg("");
  }, [loginForm]);

  const SubmitEvent = (e) => {
    e.preventDefault();

    if (loginForm.email === "") {
      inputRef.current.email.focus();
      setMsg({ email: "Email 입력해주세요." });
      return;
    } else if (!isEmail(loginForm.email)) {
      inputRef.current.email.focus();
      setMsg({ email: "잘못된 Email 형식입니다." });
      return;
    }

    if (loginForm.pw === "") {
      inputRef.current.pw.focus();
      setMsg({ pw: "Password 입력해주세요." });
      return;
    }

    Login(loginForm.email, loginForm.pw)
      .then((res) => {
        const { result, msg } = res.data;
        if (result === "Fail") {
          setMsg({ common: "로그인 실패 다시 입력해주세요." });
          return;
        }

        const {
          Authorization,
          RefreshToken,
          MEMBER_SYSTEMID,
          NICKNAME,
          MEMBERNAME,
          PROFILE_SYSTEMID,
        } = JSON.parse(msg);

        localStorage.setItem("systemId", MEMBER_SYSTEMID);
        localStorage.setItem("nickname", NICKNAME);
        localStorage.setItem("name", MEMBERNAME);
        localStorage.setItem("profileId", PROFILE_SYSTEMID);
        localStorage.setItem("email", loginForm.email);
        localStorage.setItem("Authorization", Authorization);
        localStorage.setItem("refreshtoken", RefreshToken);

        navigate(-1);
      })
      .catch((err) => {
        setMsg({ common: "로그인 실패 다시 입력해주세요." });
      });
  };

  return (
    <Content>
      <VerticalLabelInput
        label="Email"
        placeholder="ENUMETACORE@enu-tech.co.kr"
        inputRef={(el) => (inputRef.current.email = el)}
        value={loginForm.email}
        errorMsg={Msg?.email || ""}
        onChangeEvent={(email) => {
          setLoginForm((prev) => ({ ...prev, email }));
        }}
      />
      <VerticalLabelInput
        label="Password"
        inputType="password"
        inputRef={(el) => (inputRef.current.pw = el)}
        value={loginForm.pw}
        errorMsg={Msg?.pw || ""}
        onKeyDownEvent={SubmitEvent}
        onChangeEvent={(pw) => {
          setLoginForm((prev) => ({ ...prev, pw }));
        }}
      />

      <div className="LinkBox">
        <WarningMsg>{Msg?.common}</WarningMsg>
        <Link to="/ResetPassword"> 비밀번호 찾기 </Link>
      </div>
      <BtnPrimary label="Login" onClick={SubmitEvent} />
    </Content>
  );
}

export default LoginPage;

// 스타일 ------------------------------------
const Content = styled.div`
  position: relative;
  top: 28%;
  width: 310px;
  padding: 16px 24px;
  margin: 0 auto;
  border-radius: 0.3rem;
  z-index: 9;
  background-color: #fff;
  user-select: none;

  & > button {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    width: 100%;
    height: 48px;
  }

  .LinkBox {
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.base.font_color_Black};
      &:hover {
        color: ${({ theme }) => theme.base.font_color_DarkGray};
      }
    }
  }
`;
