import {AccessAxios} from 'components/Axios';
const BaseURL = '/api/Template';

/**
 * 템플릿 json을 받아서 템플릿을 생성한다.
 * @param {string} msg 템플릿 json
 * @returns 
 */
export const MakeTemplate = async (msg) => {
    try {
        const res = await AccessAxios.post(`${BaseURL}/MakeTemplate`, msg);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 템플릿 버전 정보를 가져온다.
 * @param {string} MEMBER_SYSTEMID  - 회원 시스템 아이디
 * @param {string} PROJECT_SYSTEMID - 프로젝트 시스템 아이디
 * @returns 
 */
export const GetTemplateVerInfo = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID) => {
    try {
        const msg = {MEMBER_SYSTEMID, PROJECT_SYSTEMID};
        const res = await AccessAxios.post(`${BaseURL}/GetTemplateVerInfo`, msg);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * repository의 템플릿 리스트를 가져온다.
 * @param {string} QUERY - 검색어
 * @param {Number} LIMIT - 한페이지에 표출할 개수
 * @param {Number} PAGE - 보여지는 페이지
 * @param {boolean} TEMPLATE_FILTER - 템플릿 필터
 * @param {boolean} SYMBOL_FILTER - 심볼 필터
 * @param {boolean} PICTURE_FILTER - 픽처 필터
 * @param {boolean} IMAGE_FILTER - 이미지 필터
 * @param {boolean} CATEGORY_FILTER - 카테고리 필터
 * @param {Array} CATEGORY - 카테고리 리스트
 * @returns 
 */
export const GetSearchData = async (QUERY, LIMIT, PAGE, TEMPLATE_FILTER, SYMBOL_FILTER, PICTURE_FILTER, IMAGE_FILTER, CATEGORY_FILTER, CATEGORY) => {
    try {
        const msg = {QUERY, LIMIT, PAGE, TEMPLATE_FILTER, SYMBOL_FILTER, PICTURE_FILTER, IMAGE_FILTER, CATEGORY_FILTER, CATEGORY};
        const res = await AccessAxios.post(`${BaseURL}/Search`, msg);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 템플릿의 서브 리소스를 가져온다.
 * @param {string} TEMPLATE_SYSTEMID 
 * @param {string} TEMPLATE_VERSION 
 * @returns 
 */
export const GetTemplateSubResource = async (TEMPLATE_SYSTEMID, TEMPLATE_VERSION) => {
    try {
        const msg = {TEMPLATE_SYSTEMID, TEMPLATE_VERSION};
        const res = await AccessAxios.post(`${BaseURL}/GetTemplateSubResource`, msg);
        return res;
    } catch (err) {
        throw err;
    }
};
