import { useState } from "react";

import FontFamily from 'components/common/FontFamily';
import PropertiesContent from "components/atom/PropertiesContent";
import SelectList from "components/atom/SelectList";
import TextAlignRadioContent from "components/atom/TextAlignRadioContent";
import BtnToggle from "components/atom/BtnToggle";
import InputComponent from "components/atom/InputComponent";
import {FontSizeIcon} from 'components/common/EnuSpaceIcon'
import ColorComponent from "components/molecule/ColorComponent";
import { ToggleRect } from 'components/css/common';

import styled from "styled-components";
import { MdFormatItalic } from "react-icons/md";

export default function SymbolTextInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        text: NodeText,
        font_style: NodeFontStyle,
        use_text: NodeUseText,
        font_family: NodeFontFamily,
        font_weight: NodeFontWeight,
        font_size: NodeFontSize,
        horizontal_align: NodeHorizontalAlign,
        vertical_align: NodeVerticalAlign,
    } =_data;
    const [fontClass] = useState(new FontFamily());

    //폰트 사용 여부 이벤트
    const IsUseText = (attributeName , attributeValue) =>{
        const JsonData = [{attributeName,attributeValue}]

        if (attributeValue === 'true') {   // 테스트 사용시 기본값
            JsonData.push(
                { attributeName: 'text-color',          attributeValue: 'rgb(0,0,0)' },
                { attributeName: 'font-family',         attributeValue: 'NotoSansKR' },
                { attributeName: 'font-size',           attributeValue: '20' },
                { attributeName: 'font-weight',         attributeValue: 'regular' },
                { attributeName: 'font-style',          attributeValue: 'normal' },
                { attributeName: 'text-color-opacity',  attributeValue: '1' },
                { attributeName: 'horizontal-align',    attributeValue: 'left' },
                { attributeName: 'vertical-align',      attributeValue: 'top' },
                { attributeName: 'text',                attributeValue: '' }
            )
        }
        _ChangeEvent(JsonData)
    }

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title="Text" 
            _Toggle={ 
                <BtnToggle _toggleID="use-text" _isToggleValue={NodeUseText} _IsToggle={IsUseText} _disabled={Array.isArray(NodeUseText) ? true : _disabled}/>
            } 
            _ToggleValue={Array.isArray(NodeUseText) ? false : !['0', 'false', undefined].includes(NodeUseText)}
        >
            <div>
                <SelectList
                    attributeName="font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <SelectList
                    attributeName="font-weight"
                    dataList={fontClass.GetFontWeight(NodeFontFamily)}
                    selectedValue={NodeFontWeight}
                    {...commonProps}
                />
                <InputComponent
                    label={<FontSizeIcon />}
                    attributeName="font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeFontSize}
                    {...commonProps}
                />
            </div>
            <div>
                <ColorComponent
                    label="text"
                    attributeName="text-color"
                    isGradient={false}
                    isVisibility={false}
                    {...commonProps}
                />
            </div>

            <AlignDiv>
                <TextAlignRadioContent 
                    attributeName='horizontal-align' 
                    selectValue={NodeHorizontalAlign} 
                    {...commonProps}
                />
                <TextAlignRadioContent 
                    attributeName='vertical-align' 
                    alignType='vertical' 
                    selectValue={NodeVerticalAlign} 
                    {...commonProps}
                />
                <ToggleRect 
                    disabled={_disabled}
                    onClick={(e)=>{
                        if (_disabled) return;
                        const attributeValue = Array.isArray(NodeFontStyle) ? 'italic' : ['normal'].includes(NodeFontStyle) ? 'italic' : 'normal';
                        _ChangeEvent([{attributeName: 'font-style', attributeValue}]);
                    }}
                >
                    <MdFormatItalic className={Array.isArray(NodeFontStyle) ? '' :  ['normal'].includes(NodeFontStyle) ? '' : 'active'}/>
                </ToggleRect>
            </AlignDiv>

            <TextArea>
                <textarea 
                    name="text" 
                    title="text" 
                    value={Array.isArray(NodeText) ? 'Mixed' : NodeText || ''}
                    onClick={(e)=>{
                        if(e.target.value === 'Mixed') e.target.select();
                    }}
                    onChange={(e) => {
                        _ChangeEvent([{attributeName: 'text', attributeValue: e.target.value}])
                    }}
                    onKeyDown={(e)=>{
                        if (e.nativeEvent.isComposing)
                            return;
                        
                        if( e.key === 'Enter' && e.shiftKey){
                            return;
                        }else if(e.key === 'Enter'){
                            e.preventDefault();
                            e.target.blur();
                        }else if (e.ctrlKey && e.key === 'a'){
                            e.preventDefault();
                            e.target.select();
                        }
                    }}
                    disabled={_disabled}
                />
            </TextArea>
        </PropertiesContent>
    )
}

//스타일-------------------------
const TextArea = styled.div`
    height: auto;

    textarea{
        margin-left: 8px;
        width: 100%;
        height: 56px;
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 2px;
        overflow:auto;
        resize: none;
        line-height:20px;

        &:focus, &:hover {
            border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
        }

        &:defined {
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            outline: none;
        }
    }    
`;

const AlignDiv = styled.div`
    margin-left: 8px;
    gap: 8px;
`;