import React, { useState } from "react";

import ProjectExplorerTree from "babylon/components/organism/ProjectExplorerTree";
import {
    EnuIconProjectExplorer,
} from 'components/common/EnuSpaceIcon';

import ResizeDiv from "components/atom/ResizeDiv";
import styled from "styled-components";

const MENUS = {
    PROJECT_EXPLORER: 'projectExplorer',
}

export default function LeftMenuContent({ IsLogWindow }) {
    const [showTab, setShowTab] = useState(MENUS.PROJECT_EXPLORER);

    const [Search, setSearch] = useState({
        IsUse: false,
        Keyword: '',
    });

    const LeftMenuIcon = (type) => {
        switch (type) {
            case MENUS.PROJECT_EXPLORER:
                return <EnuIconProjectExplorer draggable="false" />;
            default:
                return console.error('LeftMenuIcon Error');
        }
    }

    return (
        <>
            <LeftMenu>
                <div>
                    {Object.entries(MENUS).map((menu, index) => {
                        const [key, value] = menu;
                        return (
                            <div key={key} className={showTab === value ? 'selectedMenu' : ''} onClick={() => setShowTab(value)} >
                                {LeftMenuIcon(value)}
                            </div>
                        )
                    })}
                </div>
            </LeftMenu>

            <ResizeDiv _pos={'right'} _width={250}>
                {showTab === MENUS.PROJECT_EXPLORER && 
                    <ProjectExplorerTree 
                        SearchState={[Search, setSearch]}
                    />
                }
            </ResizeDiv>
        </>
    )
}

// 스타일-----------------------------------------------------
const LeftMenu = styled.div`
    width: 56px;
    border-right: 1px solid ${({ theme }) => theme.base.border_color_Gray};

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    
    & > div{
        &> div {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin: 16px auto;
            border-radius: 4px;
            cursor: pointer;
            /* overflow: hidden; */
            user-select: none;
    
            &:hover {
                background-color: ${({ theme }) => theme.base.background_color_PaleGray};
            }
    
            &.selectedMenu {
                background-color: ${({ theme }) => theme.base.background_color_LightBlue};

                &>svg path{
                    fill: ${({ theme }) => theme.base.font_color_Blue} !important;
                }
    
                /* &>img {
                    position: absolute;
                    right: 0;
                } */
            }
        }
    }
`;