import { useEffect, useContext, useState } from "react";

import LogWindow from "components/atom/LogWindow";
import Notification from "components/atom/Notification";
import SceneComponent from 'babylon/SceneComponent'
import { SceneContext, SelectMeshContext } from "context/SceneContext";
import { CreateAxis } from "babylon/utility/CreatorUtility";
import useDataController from "babylon/hooks/useDataController";

import styled from "styled-components";
import { Vector3, ArcRotateCamera, PointLight, Color4, HighlightLayer } from "@babylonjs/core";
import EnuAdvancedDynamicTexture from 'babylon/class/AdvancedDynamicTexture';
import ENUGUI3DManager from 'babylon/class/ENUGUI3DManager'
import "@babylonjs/loaders/OBJ";

const onSceneReady = (EnuScene, canvas) => {
    const scene = EnuScene.scene;
    // 이 카메라는 .bablyon에만 저장되고 ENUclass에는 저장되지 않음
    // scene은 camera 없이 render시 에러. 기본 카메라 설정 필수.
    
    // 카메라 시점 임시 변경. 추후 다시 0,0,0 좌표로 변경 고려.
    const camera = new ArcRotateCamera("support", -Math.PI/8, Math.PI/2, 15, new Vector3(0, 3, 0), scene);
        
    // const camera = new ArcRotateCamera("support", 0, Math.PI, 10, new Vector3(0, 5, 0), scene);
    // camera.setPosition(new Vector3(5, 10, -25));
    camera.attachControl(canvas, true);
    camera.inputs.attached.mousewheel.wheelPrecision = 10;
    
    const light = new PointLight("support", new Vector3(0, 3, 0), scene);
    scene.registerBeforeRender(() => light.position = camera.position);
    
    const CoTAxis = CreateAxis(scene, 100);
    EnuScene.addChildToRootNode(CoTAxis);

    new EnuAdvancedDynamicTexture(EnuScene);
    new ENUGUI3DManager(EnuScene);

    // scene.clearColor = new Color4(0.24, 0.24, 0.24, 1);
    scene.clearColor = new Color4(0.4, 0.4, 0.4, 1);

    // TODO: 240403_ 추후 사용 고려
    // const axis = new AxesViewer(scene,1);
    // axis.scaleLines = .5;
    // camera.onViewMatrixChangedObservable.add(() => {
    //     const p = camera.position.clone();
    //     p.addInPlace(camera.getDirection(new Vector3(0, 0, 16)));
    //     p.addInPlace(camera.getDirection(new Vector3(0, -6, 0)));
    //     p.addInPlace(camera.getDirection(new Vector3(-5, 0, 0)));
    //     axis.xAxis.position = p.clone();
    //     axis.yAxis.position = p.clone();
    //     axis.zAxis.position = p.clone();
    // });
};

const onRender = (scene) => {
        
};

export default function CanvasContent({IsLogWindow}){
    const {EnuScene} = useContext(SceneContext);
    const {SelectedMesh, setSelectedMesh} = useContext(SelectMeshContext);
    const {ClearData, UploadDataByMesh} = useDataController();
    
    // 선택된 메쉬의 하이라이트
    const [Highlight, setHighlight] = useState(null);

    useEffect(()=>{

    },[IsLogWindow]);

    useEffect(()=>{
        if (!EnuScene) return;

        if(Highlight === null){
            const hi = new HighlightLayer("hl1", EnuScene.scene, {
                isStroke: true,
                mainTextureFixedSize: 2048
            });
            setHighlight(hi);
        }
    },[EnuScene, Highlight]);

    // 사용자가 선택한 메쉬표시
    useEffect(() => {
        if (!EnuScene) return;

        const BabylonScene = EnuScene.scene;
        BabylonScene.onPointerDown = function (evt, pickResult) {
            // console.log(evt, pickResult)
            const PickedMesh = pickResult.pickedMesh;
            if (['support'].includes(PickedMesh?.id)) return;

            if (pickResult.hit) {
                if (SelectedMesh) {
                    Highlight.removeMesh(SelectedMesh.originMesh)
                }

                Highlight.addMesh(PickedMesh, new Color4(1, 0.5, 0, 1));
                setSelectedMesh(EnuScene.getObjectByKey(PickedMesh.uniqueId));
            } else if (SelectedMesh) {
                Highlight.removeMesh(SelectedMesh.originMesh)
                setSelectedMesh(null);
            }
        }
    }, [EnuScene, SelectedMesh, setSelectedMesh, Highlight]);

    // 선택된 메쉬의 정보를 redux에 저장
    useEffect(() => {
        ClearData();

        if (SelectedMesh) {
            UploadDataByMesh(SelectedMesh);
        }
    }, [SelectedMesh, ClearData, UploadDataByMesh]);
    
    // 선태된 메쉬 해제
    window.resetSelectedMesh = () => {
        Highlight.removeMesh(SelectedMesh.originMesh)
        setSelectedMesh(null);
    };

    return(
        <Content
            onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Delete' && SelectedMesh) {
                    EnuScene.deleteObjectByKey(SelectedMesh.uniqueId);
                    SelectedMesh.originMesh.dispose();
                    return;
                }
            }}
        >
            <Notification />
            <CanvasBody id="canvasSpace" className='show'>
                <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas"/>
            </CanvasBody>
            
            {/* FIXME: logWindow 높이 고정으로 처리 되어있음 flex-grow: 1; 처리시 설정한 높이로 출력이 안됨. */}
            <LogWindow _visibility={IsLogWindow}/>
        </Content>
    )
}

//스타일--------------------------------------
const Content = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.base.background_color_PaleGray};
    width: 0;
`;

const CanvasBody = styled.div`
    position: relative;

    &.hidden{
        display: none;
    }

    &.show{
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    
        &>div{  
            background-color: ${({theme}) => theme.base.background_color_PaleGray}; 
            width: 100%;
            height: 100%;
        }
    }

    canvas:focus-visible{
        outline: none;
    }
    canvas{
        width: 100%;
        height: 100%;
    }
`;