import {useState} from "react";

import {
    EnuIconNodeTreeGroup,
    EnuIconNodeTreeRect,
    EnuIconNodeTreeCircle,
    EnuIconNodeTreeUse,
    EnuIconNodeTreePolygon,
    EnuIconNodeTreeEllipse,
    EnuIconNodeTreeText,
    EnuIconNodeTreePgEditbox,
    EnuIconNodeTreeImage,
    EnuIconNodeTreeLine,
    EnuIconNodeTreePolyline,
} from "components/common/EnuSpaceIcon"

import styled from "styled-components";
import { MdArrowRight, MdArrowDropDown  } from "react-icons/md";

export default function NodeTreeRowContent({id, title, objectName, children, onClickEvent, isSelected=false ,isShow = true}){
    const [IsShow, setIsShow] = useState(isShow);

    const SetFrontIcon = () =>{
        const iconMap = {
            "g": <EnuIconNodeTreeGroup/>,
            "rect": <EnuIconNodeTreeRect />,
            "circle": <EnuIconNodeTreeCircle />,
            "polygon": <EnuIconNodeTreePolygon />,
            "ellipse": <EnuIconNodeTreeEllipse />,
            "line": <EnuIconNodeTreeLine />,
            "polyline": <EnuIconNodeTreePolyline />,
            "text": <EnuIconNodeTreeText />,
            "pg-editbox": <EnuIconNodeTreePgEditbox />,
            "image": <EnuIconNodeTreeImage />,
            "use": <EnuIconNodeTreeUse />,
        }

        const groupArrowIcon = () =>{
            if(IsShow){
                return <MdArrowDropDown className="groupArrowIcon" />
            }else{
                return <MdArrowRight className="groupArrowIcon"/>
            }
        }

        return(
            <FrontIconContent>
                {objectName === 'g' && groupArrowIcon() }
                {iconMap[objectName]}
            </FrontIconContent>
        )
    }

    return(
        <>
            <TreeRow
                id={id}
                isSelected={isSelected}
                onDoubleClick={(e) => {
                    setIsShow(!IsShow)
                }}
                onClick={(e => onClickEvent && onClickEvent(id, e))}
            >
                {SetFrontIcon()}
                <TitleText title={id} >{title}</TitleText>
            </TreeRow>
            {children && IsShow ? children : null}
        </>
    )
}

const TreeRow = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
    box-sizing: border-box;
    gap: 4px;
    background-color: ${({isSelected, theme}) => isSelected ? theme.base.background_color_LightBlue : null};

    &.ProjectName >input{
        font-weight: 500;
    }

    &:hover, &:focus{
        background-color: ${({theme}) => theme.base.background_color_LightBlue};
        span{ display: block; }
    }

    .groupArrowIcon{
        position: absolute;
        top:0;
        left: -12px;
        color: ${({theme}) => theme.base.font_color_DarkGray};
    }
`;

const TitleText = styled.span`
    height: auto;
    font-size: .85rem;
    padding: 4px 2px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
`;

const FrontIconContent = styled.span`
    position: relative;
    display: inline-block;
    padding-right: 0;
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    img, svg{
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
    }
`;