import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({component}){
    const Token =  localStorage.getItem('Authorization');
    return(
        Token ? component : <Navigate to="/login" {...alert("로그인이 필요합니다.")} />
    )
}

export default PrivateRoute;