import { useEffect, useState } from 'react';

import MainHeader from 'components/organism/MainHeader';
import Footer from 'components/organism/Footer';
import SpaceBackground from "components/atom/SpaceBackground";
import Notification from "components/atom/Notification";
import {UserInfoContext} from "context/UserInfoContext"

import {CheckAccessToken} from 'hooks/useMember'

import { useLocation, Navigate } from 'react-router-dom';
import styled from "styled-components";

export default function Layout(props){
    const location = useLocation();
    let PathName = location.pathname;
    const hostname = document.location.hostname;

    const [USER_INFO, setUSER_INFO] = useState({Authorization:null, systemId:null, nickname:null, email:null })

    const Authorization = localStorage.getItem('Authorization');
    useEffect(()=>{
        if(Authorization === null){
            localStorage.clear();
            setUSER_INFO({Authorization:null, systemId:null, nickname:null, email:null });
        }else{
            CheckAccessToken(Authorization)
                .then(res => {
                    const {result} = res.data;
                    if(result === "Success"){
                        const systemId = localStorage.getItem('systemId');
                        const nickname = localStorage.getItem('nickname');
                        const email = localStorage.getItem('email');
                        setUSER_INFO({Authorization, systemId, nickname, email})
                    }
                }).catch(err =>{
                    localStorage.clear();
                    setUSER_INFO({Authorization:null, systemId:null, nickname:null, email:null });
                })
        }
    }, [Authorization]);

    // PathName이 /로 끝나면 /를 제거하고 다시 렌더링
    if(PathName.length > 1 && PathName.endsWith('/')){
        PathName = PathName.replace(/\/\/+/g, '/').replace(/\/$/, '');
        return <Navigate to={PathName} replace={true} />;
    }

    if (process.env.NODE_ENV !== 'development' && hostname === 'enuspace.com') {
        const port = document.location.port ? `:${document.location.port}` : '';
        const pathname = document.location.pathname !== '/' ? document.location.pathname : '';
        const redirectUrl = `https://www.enuspace.com${port}${pathname}`;
        return window.location.href = redirectUrl;
    }

    const isUserPage = ['/DB', '/DB/Tag', '/MyRepository', '/Canvas', '/Board', '/Archive','/MyPage', '/Groups','/terms-of-service'].includes(PathName);
    const isFooterVisible = ['/', '/terms-of-service'].includes(PathName);

    if(['/', '/login', '/signUp', '/ResetPassword'].includes(PathName) || isUserPage){
        return(
            <MainContent PathName={PathName}>
                <UserInfoContext.Provider value={{USER_INFO}}>
                    <Notification />
                    <MainHeader _isFixed={isUserPage} />
                    {props.children}
                    {isFooterVisible && <Footer />}
                    {!(isUserPage) && <SpaceBackground _height={ ['/login', '/signUp', '/ResetPassword'].includes(PathName) ? '100%' :'600px'}/>}
                </UserInfoContext.Provider>
            </MainContent>
        )
    }else{
        return <>{props.children}</>
    }
    
    // digital twin
    // AppRoute digital twin -> '/' , main -> '/Main'
    // const isUserPage = ['/DB', '/DB/Tag', '/MyRepository', '/Canvas', '/Board', '/Archive','/MyPage', '/Groups','/terms-of-service'].includes(PathName);
    // const isFooterVisible = ['/Main', '/terms-of-service'].includes(PathName);
    // const isDigital = ['/'].includes(PathName);

    // if(['/Main', '/login', '/signUp', '/ResetPassword'].includes(PathName) || isUserPage){
    //     return(
    //         <MainContent PathName={PathName}>
    //             <UserInfoContext.Provider value={{USER_INFO}}>
    //                 <Notification />
    //                 {!isDigital && <MainHeader _isFixed={isUserPage} />}
    //                 {props.children}
    //                 {isFooterVisible && <Footer />}
    //                 {!isDigital && !(isUserPage) && <SpaceBackground _height={ ['/login', '/signUp', '/ResetPassword'].includes(PathName) ? '100%' :'600px'}/>}
    //             </UserInfoContext.Provider>
    //         </MainContent>
    //     )
    // }else{
    //     return <>{props.children}</>
    // }
}

const MainContent = styled.div`
    position: relative;
    height: ${({PathName}) =>(['/signUp', '/login', '/DB', '/DB/Tag', '/MyRepository', '/Canvas', '/Board', '/Archive', '/MyPage', '/Groups', '/ResetPassword'].includes(PathName) ? '100%' : 'auto')};
    width: 100%;
    min-height: ${({ PathName }) => (PathName === '/signUp' || PathName === '/DB/Tag' ? '800px' : '500px')};
    background: ${({PathName, theme}) => ['/DB', '/DB/Tag', '/MyRepository', '/Canvas', '/Board', '/Archive', '/MyPage', '/Groups'].includes(PathName) ? theme.base.background_color_PaleGray : null};
    overflow: ${({PathName}) =>(['/MyRepository', '/Canvas', '/Board', '/Archive',].includes(PathName) ? 'auto' : null)};
`;