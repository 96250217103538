import { useState, useEffect } from "react";

import {InputWithVerticalLabel} from 'components/molecule/InputFieldLabel';
import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {isEmail } from 'components/Utility';
import {WarningMsg, ModalBottomBox} from "components/css/common"
import {SendValidationCode, CheckValidationEmail, ResetPassword as ResetPasswordAPI } from 'hooks/useMember';

import { useNavigate } from 'react-router-dom';
import styled from "styled-components"

export default function ResetPassword(){
    const navigate = useNavigate();
    const [isNext, setNext] = useState(1);
    const [message, setMessage] = useState('');

    const [VerifyData, setVerifyData] = useState({ EMAIL: '', Code: '', disabled: false });
    const [seconds, setSeconds] = useState(300);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const [isActive, setIsActive] = useState(false);

    useEffect(()=>{
        setMessage('');
    }, [VerifyData.EMAIL, VerifyData.Code]);

    const CheckEmailFormat = (email) => {
        if (email === '') {
            setMessage('Email 입력해주세요.');
            return false;
        } else if (!isEmail(email)) {
            setMessage('잘못된 Email 형식입니다.');
            return false;
        }else
            return true;
    }

    const VerifyEmail = (e) => {
        setMessage('');
        if(!CheckEmailFormat(VerifyData.EMAIL)) return;
        
        setVerifyData({...VerifyData, disabled: true});
        SendValidationCode(VerifyData.EMAIL)
            .then(res=>{
                const {result, msg} = res.data;
                
                if(result === "Success"){
                    setSeconds(300);
                    setIsActive(true);
                    setNext(2);
                }else{
                    setMessage(msg);
                }
            }).catch(err=>{
                setMessage(`이메일 인증 오류: ${err}`);
            }).finally(()=>{
                setVerifyData({...VerifyData, disabled: false});
            })
    }

    const VerifyCode = (e) => {
        setMessage('');
        if(VerifyData.Code === ''){
            setMessage('Code를 입력해주세요.');
            return false;
        }

        CheckValidationEmail( VerifyData.EMAIL, VerifyData.Code)
            .then(res=>{
                const {result, msg} = res.data;
                if(result === "Success"){
                    ResetPasswordAPI(VerifyData.EMAIL)
                        .then(res=>{
                            const {result, msg} = res.data;
                            if(result === "Success"){
                                alert("Email로 임시 비밀번호를 발송하였습니다.");
                                navigate('/');

                            }else{
                                setMessage(msg);
                            }
                        }).catch(err=>{
                            setMessage(`Reset Password 오류: ${err}`);
                        });
                }else{
                    setMessage(msg);
                }
            })
            .catch(err=>{
                setMessage(`이메일 인증 오류: ${err}`);
            });
    }

    useEffect(() => {
        let interval;
        if (isActive) {
            interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(prev => prev - 1);
                } else {
                    clearInterval(interval);
                    setIsActive(false);
                    setNext(2);
                }
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [seconds, isActive]);
    
    switch (isNext) {
        case 1:
            return(
                <ContentBox>
                    <div className='title'>Email Check</div>

                    <InputWithVerticalLabel id='EMAIL' label='Email' propsState={[VerifyData, setVerifyData]} placeholder='ENUMETACORE@enu-tech.co.kr' isRequired={true}/>
                    
                    <ModalBottomBox>
                        {message && <WarningMsg> {message} </WarningMsg> }
                        <BtnSecondary label={'Cancel'} onClick={(e)=>navigate('/')} disabled={VerifyData.disabled}/>
                        <BtnPrimary label={'Next'} onClick={VerifyEmail} disabled={VerifyData.disabled}/>
                    </ModalBottomBox>
                </ContentBox>
            )
        case 2:
            return(
                <ContentBox>
                    <div className='title'>Email Verify</div>

                    <InputWithVerticalLabel id='EMAIL' label='Email' propsState={[VerifyData, setVerifyData]} isRequired={true} disabled={true}>
                        <p className="timer">{String(minutes).padStart(2, '0')}:{String(remainingSeconds).padStart(2, '0')}</p>
                    </InputWithVerticalLabel>
                    <InputWithVerticalLabel id='Code' label='Code' propsState={[VerifyData, setVerifyData]} placeholder='인증코드' isRequired={true} />
                    
                    <ModalBottomBox>
                        {message && <WarningMsg> {message} </WarningMsg> }
                        <BtnSecondary label={'Cancel'} onClick={(e)=>{
                            setIsActive(false); 
                            setMessage(''); 
                            setNext(1);
                            setVerifyData({ EMAIL: '', Code: '', disabled: false });
                        }} />
                        <BtnPrimary label={'Reset'} onClick={VerifyCode} />
                    </ModalBottomBox>
                </ContentBox>
            )
        default:
            return null;
    }
}

const ContentBox = styled.div`
    position: absolute;
    top: 53%;
    left: 50%;
    width: 360px;
    transform: translate(-50%, -50%);
    padding: 2rem;
    border-radius: 0.3rem;
    z-index:1;
    background-color: #fff;
    user-select: none;

    &>.title{
        font-size: 1.25rem;
        text-align: center;
        color: ${({ theme }) => theme.base.font_color_Black};
        margin-bottom: 16px;
        font-weight: bold;
        line-height: 30px;
    }

    .timer{
        font-size: 1rem;
        color: ${({ theme }) => theme.base.font_color_DarkBlue};
        height: 38px;
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 4px;
        padding: 8px;
        box-sizing: border-box;
        margin-left: 8px;
    }

    &>button{
        display: flex;
        justify-content: center;
        margin-top: 40px;
        width: 100%;
        height: 48px;
    }
`;