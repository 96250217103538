
import { useState, useEffect } from "react";
import styled from "styled-components";

export default function LabelAndInput({ id, label, value, labelWidth = '28px' ,inputType, changeEvent ,disabled, children, ...props }) {
    const [input, setInput] = useState('');

    useEffect(()=>{
        if(value !== undefined){
            setInput(value);
        }else{
            setInput('');
        }
    }, [value]);

    const ChangeValue = (e) =>{
        changeEvent && changeEvent(e);
    }

    const KeyDownEvent = (e) =>{
        e.stopPropagation();
        if(e.key === 'Enter'){
            e.target.blur();
            return;
        }

        if (e.ctrlKey && e.key === 'a'){
            e.preventDefault();
            e.target.select();
            return;
        }

        if(e.key === 'Delete'){
            setInput('');
            return;
        }
    }

    return (
        <DivRow labelWidth={labelWidth}>
            {label &&
                <label htmlFor={id} >{label}</label>
            }
            <input
                name={id}
                id={id}
                title={id}
                type={inputType || "text"}
                value={input}
                disabled={disabled}
                onKeyDown={KeyDownEvent}
                onBlur={ChangeValue}
                onChange={ChangeValue}
                {...props}
            />
            {children && children}
        </DivRow>
    )

}

const DivRow = styled.div`
    display: flex;

    >label{
        padding: 6px;
        padding-left: 8px;
        min-width: 28px;
        width: ${({ labelWidth }) => labelWidth};
        color: ${({ theme }) => theme.base.font_color_DarkGray};
        height: 28px;
        display: inline-block;
        box-sizing: border-box;
        font-size: 0.9rem;
        user-select: none;
    }

    >input {
        width: calc(100% - ${({ labelWidth }) => labelWidth});
        font-size: .9rem;
        height: 28px;
        box-sizing: border-box;
        border: 0 none;
        border-bottom: 1px solid ${({ theme }) => theme.base.border_color_LightGray};

        &:hover{
            border-color: ${({ theme }) => theme.base.border_color_Blue};
        }

        &:focus{
            outline:none;
            border-color: ${({ theme }) => theme.base.border_color_Blue};
        }
        &:selection{
            background-color: ${({ theme }) => theme.base.background_color_lightBlue};
            border: 0 none;
            border-color: ${({ theme }) => theme.base.border_color_LightGray};
        }

        &:disabled{
            border-color: transparent;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
`;