import { useState, useEffect } from "react";

import {useSelector } from 'react-redux';
import styled, { keyframes } from "styled-components"
import { MdWarningAmber, MdErrorOutline, MdClear } from "react-icons/md";

export default function Notification(){
    const {canvasMode, currentView:currentViewID} = useSelector(state => state.projectData.CANVAS_VIEW);
    const [toastMsg, setToastMsg] = useState([]);
    const [alertMsg, setAlertMsg] = useState([]);

    useEffect(() => {
        if (toastMsg.length > 0 && toastMsg.length < 6) {
            const timer = setTimeout(() => {
                setToastMsg(prevMessages => prevMessages.slice(1));
            }, 1400);
    
            return () => clearTimeout(timer);
        } else if (toastMsg.length >= 6) {
            setToastMsg(prevMessages => prevMessages.slice(1));
        }
    }, [toastMsg]);

    useEffect(() => {
        if (alertMsg.length >= 6) {
            setAlertMsg(prevMessages => prevMessages.slice(1));
        }
    }, [alertMsg]);

    // 10초 뒤 에러 메시지가 사라지는 함수
    useEffect(() => {
        if(alertMsg.length > 0){
            const timer = setTimeout(() => {
                const tenSecondsAgo = new Date().getTime() - 10000;
                // 생성된 지 10초 지난 메시지를 필터링하여 삭제
                // Alert된 메시지가 더이상 존재하지 않으면, timer 생성 안됨
                // timer가 존재한다 = alertMsg 배열이 1초마다 변경된다 => 1초마다 검증
                setAlertMsg(prevMessages => prevMessages.filter(alert => alert.id > tenSecondsAgo));
            }, 1000); 
            // 컴포넌트가 언마운트 될 때 타이머를 클리어
            return () => clearTimeout(timer);
        }
    }, [alertMsg]);

    window.AddToastMsg = (addMsg) => setToastMsg([...toastMsg, addMsg]);

    // id 값은 new Date().getTime() 을 사용한다.
    //window.AddAlertMsg({id: new Date().getTime(), type:'warning', msg});
    window.AddAlertMsg = (addMsg) =>{
        const {type, msg} = addMsg;
        const InputData = {id: new Date().getTime(), type, msg};
        setAlertMsg(prevMsgs => [...prevMsgs, InputData]);
    }

    
    const DeleteAlertMsg = (id) => {
        setAlertMsg(prevMsgs => prevMsgs.filter(prv => prv.id !== id));
    };

    return(
        <NotificationContainer CanvasType={canvasMode[currentViewID]}>
            {toastMsg && toastMsg.map((item, idx) => (
                <ToastMsgDiv key={idx}>{item}</ToastMsgDiv>
            ))}
            {alertMsg && alertMsg.map((item, idx) => {
                const {id, type, msg} = item;
                switch(type){
                    case 'error':
                        return (
                            <ErrorMsgDiv key={id}>
                                <div>
                                    <MdErrorOutline />
                                    <span>{msg}</span>
                                </div>
                                <MdClear className="btnClear" onClick={(e)=>DeleteAlertMsg(id)} />
                            </ErrorMsgDiv>
                        )
                    case 'warning':
                        return (
                            <WarningMsgDiv key={id}>
                                <div>
                                    <MdWarningAmber />
                                    <span>{msg}</span>
                                </div>
                                <MdClear className="btnClear" onClick={(e)=>DeleteAlertMsg(id)} />
                            </WarningMsgDiv>
                        )
                    default:
                        return (
                            <BaseMsg key={id}>
                                {msg}
                                <MdClear className="btnClear" onClick={(e)=>DeleteAlertMsg(id)} />
                            </BaseMsg>
                        )
                }
            })}
        </NotificationContainer>
    )
}

const NotificationContainer = styled.div`
    position: fixed;
    height: auto!important;
    right: 0;
    bottom: ${props => props.CanvasType === 'code' ? '60px' : '0'};
    z-index: 99;
    overflow: hidden;
    padding: 16px;
`;

const BaseMsg = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    background-color: #fff;
    width: 280px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .15) 0px 8px 16px;
    margin-top: 8px;
    animation: ${keyframes`
        from {
            transform: translateX(100%);
            opacity: 0;
        }
        to {
            transform: translateX(0);
            opacity: 1;
        }`} .2s linear;

    svg{
        width: 20px;
        height: 20px;

        &.btnClear{
            color: ${({ theme }) => theme.base.font_color_Black};
            opacity: 0.3;
            cursor: pointer;
            &:hover{
                color: ${({ theme }) => theme.base.font_color_Red};
                opacity: 1;
            }
        }
    }

    & > div {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        width: calc(100% - 36px);

        & > span {
            width: calc(100% - 28px);
            color: ${({ theme }) => theme.base.font_color_Black};
            font-size: 0.85rem;
            word-break: break-all;
            line-height: 1.6;
        }
    }
`;

const ToastMsgDiv = styled(BaseMsg)`
    border-left: 4px solid ${({theme}) => theme.base.success_color};
    font-size: 0.85rem;
    line-height: 1.6;
    word-break: break-all;
`;

const ErrorMsgDiv = styled(BaseMsg)`
    border-left: 4px solid ${({theme}) => theme.base.border_color_Red};

    &>div svg{
        color: ${({theme}) => theme.base.border_color_Red};
    }
`;

const WarningMsgDiv = styled(BaseMsg)`
    border-left: 4px solid ${({theme}) => theme.base.font_color_Orange};

    &>div svg{
        color: ${({theme}) => theme.base.font_color_Orange};
    }
`;