import {useState, useEffect} from 'react';
import Pagination from 'components/atom/Pagination';
import { TableContent, ChartTitle } from 'components/css/common';

import styled from "styled-components";

function TrendAddData({enuSpace, _Modal}){
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPosts, setCurrentPosts] = useState();
    const [selectRow, setSelectRow] = useState();

    useEffect(()=>{
        setSelectRow()
    }, [currentPosts]);

    return(
        <>
            <ChartTitle className='title'>{`${_Modal?.id || 'title'} _ ${_Modal?.data.length || 0}개`}</ChartTitle>
            <Table>
                <div>
                    {_Modal?.data &&
                        <table>
                            <thead>
                                <tr>
                                    {_Modal.data[0] !== undefined &&
                                        Object.keys(_Modal.data[0]).map((header) => (
                                            <th key={header}>{header}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {currentPosts && currentPosts.map((data, index) => (
                                    <tr key={index} className={selectRow === index ? 'selectedRow' : ''} onDoubleClick={(e)=>setSelectRow(index)}>
                                        {Object.keys(data).map((key, index) => (
                                            <td key={index}>{data[key]}</td>
                                        ))}
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </Table>
            <Pagination _dataList={_Modal?.data} _currentPage={[currentPage, setCurrentPage]} _setCurrentPosts={setCurrentPosts}/>
        </>
    )
}

export default TrendAddData;

//스타일----------------------------------------
const Table = styled(TableContent)`
    height: calc(100% - 80px);

    th, td {
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            width: 200px;
            height: 32px;
            text-align: center;
            box-sizing: border-box;
            vertical-align: middle;
            word-break: break-all;
            font-size: .9rem;
        }

        td{
            user-select: text;
        }
`;