import {BtnSecondary} from "components/atom/Button";
import {EnuSymbol} from 'components/common/EnuSpaceIcon'
import { AUTHORITY_TYPE} from "components/common/EnuSpaceEnum";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { MdClose, MdArrowBack  } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function ArchiveBoardHeader({ BaseInfo, IsAccessPageState, MyAccess, closeUrl }) {
    const navigate = useNavigate();
    const [isAccessSetting, setIsAccessSetting] = IsAccessPageState;
    
    return (
        <Header>
            <div>
                <Link to='/' id="enuLogo"><EnuSymbol /></Link>
                <span>{BaseInfo.pageName}</span>
            </div>
            <div>
                {MyAccess === AUTHORITY_TYPE.MASTER &&
                    <BtnSecondary
                        label='권한관리'
                        type='sm'
                        style={{ 'marginRight': '16px' }}
                        onClick={(e) => setIsAccessSetting(true)}
                    />
                }
                <MdArrowBack
                    className="btn btn_close"
                    title="Back"
                    onClick={() => {
                        if (isAccessSetting)
                            setIsAccessSetting(false);
                        else
                            navigate(-1);
                    }}
                />
                <MdClose
                    className="btn btn_close"
                    title="Close"
                    onClick={() => closeUrl && navigate(closeUrl)}
                />
            </div>
        </Header>
    )
}

const Header = styled.header`
    position: relative;
    width: 100%;
    z-index: 9;
    height: 46px;
    background-color: ${({theme}) => theme.base.background_color_PaleGray};
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
    box-sizing: border-box;
    padding: 0 24px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;

    #enuLogo{
        &>svg{
            padding: 4px;
            height: 28px;
            box-sizing: border-box;
        }
    }

    &>div{
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        &>span{
            font-size: 1.2rem;
            font-weight: 500;
            cursor: default;
        }
    }

    &>div .btn{
        height: 30px;
        width: 30px;
        color: ${({theme}) => theme.base.font_color_DarkGray};
        border: 1px solid ${({theme}) => theme.base.border_color_Gray};
        border-radius: 4px;
        box-sizing: border-box;

        &.btn_close{
            &:hover{
                color: ${({theme}) => theme.base.font_color_Red};
                border-color: ${({theme}) => theme.base.font_color_Red};
            } 
        }

        &.btn_base{
            padding: 3px;
            &:hover{
                color: ${({theme}) => theme.base.font_color_DarkBlue};
                border-color: ${({theme}) => theme.base.border_color_Blue};
            } 
        }
    }
`;