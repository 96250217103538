import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PropertiesInputList from 'components/molecule/PropertiesInputList';
import LayoutInputList from 'components/molecule/LayoutInputList';
import TransformInputList from 'components/molecule/TransformInputList';
import BrushPenInputList from 'components/molecule/BrushPenInputList';
import SymbolTextInputList from 'components/molecule/SymbolTextInputList';
import TextInputList from 'components/molecule/TextInputList';
import EditboxInputList from 'components/molecule/EditboxInputList';
import ContourList from 'components/molecule/contour/ContourList';
import ContourFontList from 'components/molecule/contour/ContourFontList';
import ContourLegendList from 'components/molecule/contour/ContourLegendList';
import ContourGuiList from 'components/molecule/contour/ContourGuiList';
import ElevationList from 'components/molecule/contour/ElevationList';
import ChartDataList from 'components/molecule/contour/ChartDataList';
import TrendFocusList from 'components/molecule/trend/TrendFocusList';
import TrendGuiList from 'components/molecule/trend/TrendGuiList';
import TrendTitleInputList from 'components/molecule/trend/TrendTitleInputList';
import TrendLegendInputList from 'components/molecule/trend/TrendLegendInputList';
import TrendGridInputList from 'components/molecule/trend/TrendGridInputList';
import TrendXAxisInputList from 'components/molecule/trend/TrendXAxisInputList';
import TrendYAxisInputList from 'components/molecule/trend/TrendYAxisInputList';
import TrendBaseLineInputList from 'components/molecule/trend/TrendBaseLineInputList';
import SeriesSymbolInputList from 'components/molecule/trend/SeriesSymbolInputList';
import TrendLabelInputList from 'components/molecule/trend/TrendLabelInputList';
import TrendTickInputList from 'components/molecule/trend/TrendTickInputList';
import HistogramSeriesInputList from 'components/molecule/histogram/HistogramSeriesInputList';
import RadarGridInputList from 'components/molecule/radar/RadarGridInputList';
import RadarGridLevelInputList from 'components/molecule/radar/RadarGridLevelInputList';
import RadarGridAxisInputList from 'components/molecule/radar/RadarGridAxisInputList';
import RadarSeriesInputList from 'components/molecule/radar/RadarSeriesInputList';
import RealtimeValueInputList from 'components/molecule/realtime/RealtimeValueInputList';
import RealtimeSeriesInputList from 'components/molecule/realtime/RealtimeSeriesInputList';
import RealtimeLabelInputList from 'components/molecule/realtime/RealtimeLabelInputList';
import TableRowColInputList from 'components/molecule/table/TableRowColInputList';
import TableInputList from 'components/molecule/table/TableInputList';
import TableCellInputList from 'components/molecule/table/TableCellInputList';
import SvgCanvasInputList from 'components/molecule/SvgCanvasInputList';
import GridInputList from 'components/molecule/GridInputList';
import ImageInfoContent from 'components/molecule/ImageInfoContent';
import AlignContent from 'components/molecule/AlignContent';
import DescContent from "components/molecule/DescContent";
import FocusMode from "components/molecule/FocusMode";
import Snapshot from "components/molecule/Snapshot";
import CanvasLinkList from 'components/molecule/CanvasLinkList';

import styled from "styled-components";

function PropertiesListContent({enuSpace}){
    const ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ACTIVE_PICTURE_URL);  //활성화 된 picture URL
    const GetData = useSelector(state => state.selectNodeData);                             //캠퍼스 클릭시 선택된 node 정보
    const {
        id:NodeId,
        lock: NodeLock,
        type: NodeType,
        object_name: NodeObjectName,
        from: NodeFrom,
    } = GetData;
    const [isLock, setIsLock] = useState(false);                                            //lock 여부

    useEffect(()=>{
        setIsLock((NodeLock === '1' || NodeLock === 'true') ? true : false);
    }, [NodeLock, enuSpace]);

    const ChangeEvent = (JsonData) => {
        for(let index in JsonData)
            JsonData[index].id = Array.isArray(NodeId) ? NodeId.join(',') : NodeId;

        if(NodeLock==='1' && JsonData[0].attributeName !== 'lock') return;
        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"SETNODEDATA", JSON.stringify(JsonData), "");
        enuSpace.GetNodeData("Canvas");
    }

    const ChangePickerData = (_colorType) =>{
        switch (_colorType[0]) {
            case 'changeType':
                    if(_colorType[1] === 'solid')
                        enuSpace.DeleteGradient('Canvas', NodeId);
                    else if(_colorType[1] === 'gradient'){
                        enuSpace.CreateGradient('Canvas', NodeId);
                    }
                break;

            case 'changeGradientColor':
                enuSpace.ModifyGradient('Canvas', NodeId, _colorType[1].replace(/\s+/g, ' '));
                break;
            default:
                break;
        }
    }

    const RenderProperties = (_type) => {
        const commonProps = {
            _data: GetData,
            _ChangeEvent: ChangeEvent,
            _disabled: isLock,
        };
        
        const components = (_type) => {
            if(NodeFrom === 'ExplorerTree'){
                return []
            }

            // 타입이 다른 다중 객체 선택시 TransformInputList 내용만 표시
            if(Array.isArray(_type)){
                return([<TransformInputList key="TransformInputList" {...commonProps} />]);
            }

            switch (_type){
                case 'polygon':
                case 'rect':
                case 'circle':
                case 'ellipse':
                case 'path':
                    return([
                        <LayoutInputList key="LayoutInputList" {...commonProps} />,
                        <TransformInputList key="TransformInputList" {...commonProps} />,
                        <BrushPenInputList key="BrushPenInputList" _ChangePickerData={ChangePickerData} {...commonProps} />,
                        <SymbolTextInputList key="SymbolTextInputList" {...commonProps} />,
                    ])
                case 'line':
                case 'polyline':
                    return([
                        <LayoutInputList key="LayoutInputList" {...commonProps} />,
                        <TransformInputList key="TransformInputList" {...commonProps} />,
                        <BrushPenInputList key="BrushPenInputList" _ChangePickerData={ChangePickerData} {...commonProps} />,
                    ])
                case 'text':
                    return([
                        <LayoutInputList key="LayoutInputList" {...commonProps} />,
                        <TransformInputList key="TransformInputList" {...commonProps} />,
                        <TextInputList key="TextInputList" {...commonProps} />,
                    ])
                case 'pg-editbox':
                    return([
                        <LayoutInputList key="LayoutInputList" {...commonProps} />,
                        <TransformInputList key="TransformInputList" {...commonProps} />,
                        <BrushPenInputList key="BrushPenInputList" _ChangePickerData={ChangePickerData}{...commonProps} />,
                        <EditboxInputList key="EditboxInputList" {...commonProps} />,
                    ])
                case 'audio':
                case 'image':
                    return([
                        <ImageInfoContent key="ImageInfoContent" _data={GetData} {...commonProps} />,
                        <LayoutInputList key="LayoutInputList" {...commonProps} />,
                        <TransformInputList key="TransformInputList" {...commonProps} />,
                    ])
                case 'g':
                    return([
                        <TransformInputList key="TransformInputList" {...commonProps} />,
                        <FocusMode key="FocusMode" {...commonProps} />,
                    ])
                case 'use':
                    return([
                        <TransformInputList key="TransformInputList" {...commonProps} />,
                        <FocusMode key="FocusMode" {...commonProps} />,
                        <Snapshot key="Snapshot" {...commonProps} />,
                    ])
                case 'symbol':
                case 'svg':
                    return([
                        <LayoutInputList key="LayoutInputList" {...commonProps} />,
                        <SvgCanvasInputList key="SvgCanvasInputList" {...commonProps} />,
                        <GridInputList key="GridInputList" {...commonProps} />,
                        <FocusMode key="FocusMode" {...commonProps} />,
                    ])
                case 'external-link':
                    return([
                        <LayoutInputList key="LayoutInputList" {...commonProps} />,
                        <TransformInputList key="TransformInputList" {...commonProps} />,
                        <CanvasLinkList key="CanvasLinkList" {...commonProps} />,
                    ])
                case 'pg-table':
                    return ([
                        <LayoutInputList key="LayoutInputList" {...commonProps} />,
                        <TransformInputList key="TransformInputList" {...commonProps} />,
                        <BrushPenInputList key="BrushPenInputList" _ChangePickerData={ChangePickerData} {...commonProps} />,
                        <TableInputList key="TableInputList" {...commonProps} />,
                        <TableRowColInputList key="TableRowColInputList" {...commonProps} />,
                    ])
                case 'pg-tablecell':
                    return([
                        <LayoutInputList key="LayoutInputList" {...commonProps} />,
                        <BrushPenInputList key="BrushPenInputList" _ChangePickerData={ChangePickerData} {...commonProps} />,
                        <TableCellInputList key="TableCellInputList" {...commonProps} />,
                    ])
                case 'pg-trend':
                    if (NodeType === 'trend-contour') {
                        return [
                            <LayoutInputList key="LayoutInputList" {...commonProps} />,
                            <TransformInputList key="TransformInputList" {...commonProps} />,
                            <BrushPenInputList key="BrushPenInputList" _ChangePickerData={ChangePickerData} {...commonProps} />,
                            <ContourList key="ContourList" {...commonProps} />,
                            <ContourFontList key="ContourFontList" {...commonProps} />,
                            <ContourLegendList key="ContourLegendList" {...commonProps} />,
                            <ContourGuiList key="ContourGuiList" {...commonProps} />,
                            <ElevationList key="ElevationList" {...commonProps} />,
                            <ChartDataList key="ChartDataList" {...commonProps} />,
                        ]
                    } else if (['trend-histogram', 'trend-radar', 'trend-realtime'].includes(NodeType)) {
                        return [
                            <LayoutInputList key="LayoutInputList" {...commonProps} />,
                            <TransformInputList key="TransformInputList" {...commonProps} />,
                            <BrushPenInputList key="BrushPenInputList" _ChangePickerData={ChangePickerData} {...commonProps} />,
                            <TrendGuiList key="TrendGuiList" {...commonProps} />,
                        ]
                    }
                    break;
                case 'pg-trendcomponent':
                    if (NodeType === 'title-base') {
                        return [
                            <LayoutInputList key="LayoutInputList" {...commonProps} />,
                            <TrendTitleInputList key="TrendTitleInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'legend-histogram') {
                        return [
                            <LayoutInputList key="LayoutInputList" {...commonProps} />,
                            <TrendLegendInputList key="TrendLegendInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'grid-histogram') {
                        return [
                            <LayoutInputList key="LayoutInputList" {...commonProps} />,
                            <TrendGridInputList key="TrendGridInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'series-histogram-bar') {
                        return [
                            <HistogramSeriesInputList key="HistogramSeriesInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'legend-radar') {
                        return [
                            <LayoutInputList key="LayoutInputList" {...commonProps} />,
                            <TrendLegendInputList key="TrendLegendInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'grid-radar') {
                        return [
                            <LayoutInputList key="LayoutInputList" {...commonProps} />,
                            <RadarGridInputList key="RadarGridInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'grid-radar-level') {
                        return [
                            <RadarGridLevelInputList key="RadarGridLevelInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'grid-radar-axis') {
                        return [
                            <RadarGridAxisInputList key="RadarGridAxisInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'series-radar') {
                        return [
                            <RadarSeriesInputList key="RadarSeriesInputList" {...commonProps} />,
                            <SeriesSymbolInputList key="SeriesSymbolInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'legend-realtime') {
                        return [
                            <LayoutInputList key="LayoutInputList" {...commonProps} />,
                            <TrendLegendInputList key="TrendLegendInputList" {...commonProps} />,
                            <RealtimeValueInputList key="RealtimeValueInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'grid-realtime') {
                        return [
                            <LayoutInputList key="LayoutInputList" {...commonProps} />,
                            <TrendGridInputList key="TrendGridInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'axis-histogram-x' || NodeType === 'axis-realtime-x') {
                        return [
                            <TrendXAxisInputList key="TrendXAxisInputList" {...commonProps} />,
                            <TrendLabelInputList key="TrendLabelInputList" {...commonProps} />,
                            <TrendTickInputList key="TrendTickInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'axis-histogram-y' ) {
                        return [
                            <TrendYAxisInputList key="TrendYAxisInputList" {...commonProps} />,
                            <TrendLabelInputList key="TrendLabelInputList" {...commonProps} />,
                            <TrendTickInputList key="TrendTickInputList" {...commonProps} />,
                        ]
                    }else if (NodeType === 'axis-realtime-y') {
                        return [
                            <TrendYAxisInputList key="TrendYAxisInputList" {...commonProps} />,
                            <TrendLabelInputList key="TrendLabelInputList" {...commonProps} />,
                            <TrendTickInputList key="TrendTickInputList" {...commonProps} />,
                            <TrendBaseLineInputList key="TrendBaseLineInputList" {...commonProps} />,
                        ]
                    } else if (NodeType === 'series-realtime-line' || 'series-realtime-bar' || 'series-realtime-stem' || 'series-realtime-stair') {
                        return [
                            <RealtimeSeriesInputList key="RealtimeSeriesInputList" {...commonProps} />,
                            <RealtimeLabelInputList key="RealtimeLabelInputList" {...commonProps} />,
                            <SeriesSymbolInputList key="SeriesSymbolInputList" {...commonProps} />,
                        ]
                    }
                    break;
                default:
                    break;
            }
        }
        
        const RenderItem = components(_type);
        return RenderItem;
    };
      
      
    return(
        <PropertiesList>
            <AlignContent enuSpace={enuSpace}/>
            {(NodeObjectName !== undefined && NodeObjectName.includes('trend') && NodeType !== 'trend-contour') &&
                <TrendFocusList key={'FocusList'} _data={GetData} _ChangeEvent={ChangeEvent}/>
            }
            {NodeId && <PropertiesInputList _data={GetData} _ChangeEvent={ChangeEvent} _disabled={isLock}/>}
            <Scroll>
                {
                    NodeObjectName && RenderProperties(NodeObjectName)
                }
                <DescContent _data={GetData} _ChangeEvent={ChangeEvent} _disabled={isLock} />
            </Scroll>
        </PropertiesList>
    )
}

export default PropertiesListContent;


const PropertiesList = styled.div`
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
`;

const Scroll = styled.div`
    overflow: auto;
    height: calc(100% - 156px);
`;