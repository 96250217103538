import { Layout } from "components/css/common"
import { TernsOfServiceData } from "components/common/TernsOfService"
import { PrivacyPolicy } from "components/common/PrivacyPolicy"

import styled from "styled-components"

export default function TernsOfService() {
    const handleBookmarkClick = (id) => {
        const bookmarkSection = document.getElementById(id);
        if (bookmarkSection) {
            bookmarkSection.scrollIntoView({ alignToTop:true ,behavior: 'smooth', block: 'start'});
        }
    }

    return (
        <ServiceInfo>
            <ContentsBox>
                <Title>목차</Title>
                <InfoBox>
                    <button onClick={(e)=>handleBookmarkClick("TernsOfServiceData")}>1. 이용약관</button>
                    <button onClick={(e)=>handleBookmarkClick("PrivacyPolicy")}>2. 개인정보 처리방침</button>
                </InfoBox>
            </ContentsBox>

            <div className="sectionBox">
                <div id="TernsOfServiceData" className="flagPoint"></div>
                <Title>이용약관</Title>
                <InfoBox>
                    {TernsOfServiceData?.map((list, index) => {
                        const { article, content, subItems } = list;
                        return (
                            <div key={index} className="section">
                                <div className="sectionTitle">{article}</div>
                                {content && <div className="content">{content}</div>}
                                {subItems &&
                                    <ol className="subContents">
                                        {subItems.map((subItems, index) => (
                                            <li key={index}>
                                                {subItems?.term}
                                                {subItems.subDefinitions &&
                                                    <ul className="definitions">
                                                        {subItems.subDefinitions.map((Definitions, index) => (
                                                            <li key={index}>{Definitions}</li>
                                                        ))}
                                                    </ul>
                                                }
                                            </li>
                                        ))}
                                    </ol>
                                }
                            </div>
                        )
                    })}
                </InfoBox>
            </div>
            <div className="sectionBox">
                <div id="PrivacyPolicy" className="flagPoint"></div>
                <Title id="PrivacyPolicy">개인정보 처리방침</Title>
                <InfoBox>
                    {PrivacyPolicy?.map((list, index) => {
                        const { article, content, subItems, warring, note } = list;
                        return (
                            <div key={index} className="section">
                                <div className="sectionTitle">{article}</div>
                                {content && <div className="content">{content}</div>}
                                {subItems &&
                                    <ol className="subContents">
                                        {subItems.map((subItems, index) => (
                                            <li key={index}>
                                                {subItems?.term}
                                                {subItems.subMote &&
                                                    <p>{subItems.subMote}</p>
                                                }
                                                {subItems.subDefinitions &&
                                                    <ul className="definitions">
                                                        {subItems.subDefinitions.map((Definitions, index) => (
                                                            <li key={index}>{Definitions}</li>
                                                        ))}
                                                    </ul>
                                                }
                                            </li>
                                        ))}
                                    </ol>
                                }
                                {note &&
                                    note.map((item, index)=>(
                                        <p key={index} className="note">{item}</p>
                                    ))
                                }
                                {warring &&
                                    <p className="warring">{warring}</p>
                                }
                            </div>
                        )
                    })}
                </InfoBox>
            </div>
        </ServiceInfo>
    )
}

const ServiceInfo = styled(Layout)`
    margin: 24px auto;
    color: ${({theme}) => theme.base.font_color_Black};
    line-height: 1.4;

    &>.sectionBox{
        position: relative;
        margin-top: 88px;

        &>.flagPoint{
            position: absolute;
            top: -88px;
            visibility: hidden;
        }
    }
`;

const Title = styled.div`
    font-size: 1.6rem;
    font-weight: bold;
`;

const InfoBox = styled.div`
    border-top: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    padding: 16px 0;
    margin-top: 8px;
    
    .sectionTitle{
        font-weight: bold;
        margin-bottom: 8px;
    }

    .content{
        margin-bottom: 8px;
    }

    .section:not(:first-child){
        margin-top: 24px;
    }

    .subContents{
        padding-inline-start: 1rem;
    }

    .definitions {
        padding-inline-start: 1.6rem;

        li{
            list-style: upper-roman;
        }
    }

    .warring{
        margin-top: 8px;
        font-size: .9rem;
        color: ${({ theme }) => theme.base.font_color_Red};
    }

    .note{
        font-size: .9rem;
        font-weight: bold;
    }

    li{
        font-size: .9rem;
        list-style: auto;
        margin-top: 4px;
        color: ${({ theme }) => theme.base.font_color_DarkGray};
    }
`;

const ContentsBox = styled.div`
    &>div{
        &>button{
            font-size: 1.1rem;
            border: none;
            background-color: transparent;
            display: block;
            color: ${({ theme }) => theme.base.font_color_Blue};
            cursor: pointer;
            margin-bottom: 8px;

            &:hover{
                text-decoration: underline;
            }
        }
    }
`;