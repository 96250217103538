import { useState, useReducer } from "react"

import {BtnPrimary, BtnSecondary, BtnGhost} from "components/atom/Button"
import useModalPopup from "hooks/useModalPopup"
import useProjectData from 'hooks/useProjectData'
import {InputContent, TableContent, ModalBottomBox, GroupName, WarningMsg } from "components/css/common";

import styled from "styled-components";
import { MdClear, MdStar } from "react-icons/md";

function reducer(state, action){
    switch(action.type){
        case 'changeName':{
            return {...state, name: action.name}
        }
        case 'changeTempInfo':{
            return {...state, temp: {...state.temp, [action.key]: action.value}}
        }
        case 'addAttr':{
            return {...state, attr: [...state.attr, action.attr], temp: {Type:'', SourceType:'local', Variable:'', Initial:'', Desc:''}}
        }
        case 'deleteAttr':{
            const newAttr = state.attr.filter((_, index) => index !== action.index);
            return {...state, attr:newAttr}
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`); 
    }
}

const init = {
    name: '',
    attr: [],
    temp: {Type:'', SourceType:'local', Variable:'', Initial:'', Desc:''}
}

export default function AddStruct({enuSpace, _Modal}){
    const {SetTreeData} = useProjectData();
    const {DeleteModal} = useModalPopup();

    const [CreateStructInfo, setCreateStructInfo] = useReducer(reducer, init);
    const [UserAddedVariables, setUserAddedVariables] = useState(null);

    const [Msg, setMsg] = useState('');

    const CloseModal = (e) => DeleteModal(_Modal.id);

    window.Re_CreateStruct = (_msg) =>{
        const {type} = JSON.parse(_msg);
        if(type === 'success'){
            SetTreeData(JSON.parse(enuSpace.GetProjectTree()));
            window.AddToastMsg(`Struct가 생성되었습니다.`);
            const URL = _Modal.data.path.split('\\');
            window.OpenTreeAfterCreate(URL[1]);
            CloseModal();
        }else if(type=== 'failed'){
            
        }
    }

    window.Re_GetDefineStructName_AddStruct = (_JsonData) =>{
        const GlobalDataList = _JsonData.data.Global;

        if(GlobalDataList === undefined) return;

        const VariableList = GlobalDataList.reduce((_VariableList, _GlobalData) => {
            if (_GlobalData.type && (_GlobalData.file === _Modal.data.path)) {
                _GlobalData.type.forEach(_struct => _VariableList.push(_struct));
            }
            return _VariableList;
        }, []);
    
        setUserAddedVariables(VariableList.length === 0 ? null : VariableList);
    }

    return(
        <>
            <Header>
                <GroupName width={100}>Struct Name </GroupName>
                <InputContent 
                    name="Initial" 
                    type='string' 
                    value={CreateStructInfo.name} 
                    onChange={(e) => {
                        setMsg('');
                        setCreateStructInfo({type:'changeName', name: e.target.value})
                    }}
                />
            </Header>

            <InputContentBox>
                <div>
                    <GroupName width={94} align='right' htmlFor='Type' className="Type">
                            <MdStar className="required" />Type
                    </GroupName>
                    <select 
                        value={CreateStructInfo.temp.Type} 
                        onClick={(e)=>enuSpace.GetDefineStructName('AddStruct')}
                        onChange={(e)=>setCreateStructInfo({type:'changeTempInfo', key:'Type', value:e.target.value})}
                    >
                        <option value=''></option>
                        <option value='bool'>bool</option>
                        <option value='int'>int</option>
                        <option value='float'>float</option>
                        <option value='double'>double</option>
                        <option value='string'>string</option>
                        {UserAddedVariables && <optgroup style={{'color': '#EDEDED'}} label="-------------"></optgroup>}
                        {UserAddedVariables&&
                            UserAddedVariables.map((item)=>(  <option key={item} value={item}>{item}</option> ))
                        }
                    </select>
                    <GroupName width={96} align='right' htmlFor='Variable' className="Variable">
                        <MdStar className="required" />Variable
                    </GroupName>
                    <input 
                        value={CreateStructInfo.temp.Variable} 
                        onChange={(e)=>setCreateStructInfo({type:'changeTempInfo', key:'Variable', value:e.target.value})}
                    />

                    <GroupName width={96} align='right' htmlFor='Initial' className="Initial">
                        Initial
                    </GroupName>
                    <input 
                        value={CreateStructInfo.temp.Initial} 
                        disabled={UserAddedVariables?.includes(CreateStructInfo.temp.Type)} 
                        onChange={(e)=>setCreateStructInfo({type:'changeTempInfo', key:'Initial', value:e.target.value})}
                    />
                </div>
                <div>
                    <GroupName width={100} align='right' htmlFor='Desc' className="Desc">
                        Desc
                    </GroupName>
                    <input 
                        value={CreateStructInfo.temp.Desc} 
                        disabled={UserAddedVariables?.includes(CreateStructInfo.temp.Type)}
                        onChange={(e)=>setCreateStructInfo({type:'changeTempInfo', key:'Desc', value:e.target.value})} 
                    />
                    <BtnGhost 
                        label="Add" 
                        type='sm' 
                        onClick={(e) => {
                            const { Type, Variable } = CreateStructInfo.temp;
                            if (!Type || !Variable) {
                                setMsg(`${!Type ? 'Type' : 'Variable'}을 입력해 주세요.`);
                                return;
                            }
                            
                            if (CreateStructInfo.attr.some(item => item.Variable === Variable)) {
                                setMsg(`'${Variable}' 이미 사용중 입니다.`);
                                return;
                            };
                            setCreateStructInfo({type:'addAttr', attr: CreateStructInfo.temp})
                        }} 
                    />
                </div>
            </InputContentBox>
            <VariableTableList>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className='type'>Type</th>
                                <th className='Variable'>Variable</th>
                                <th className='Initial'>Initial</th>
                                <th className='Desc'>Desc</th>
                                <th className='Btn'>Del</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CreateStructInfo.attr.map((item, index)=>{
                                const {Type, Variable, Initial, Desc} = item;
                                return(
                                    <tr key={index}>
                                        <th className='type'>{Type}</th>
                                        <th className='Variable VariableName'>{Variable}</th>
                                        <th className='Initial'>{Initial}</th>
                                        <th className='Desc'>{Desc}</th>
                                        <th className='Btn'>
                                            <MdClear onClick={(e)=>setCreateStructInfo({type:'deleteAttr', index})} />
                                        </th>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </VariableTableList>

            {Msg && <WarningMsg>{Msg}</WarningMsg>}

            <ModalBottomBox>
                <BtnSecondary label="Cancel" onClick={CloseModal} />
                <BtnPrimary
                    label="Ok"
                    onClick={(e) => {
                        const { name, attr } = CreateStructInfo;
                        if (name === '') {
                            setMsg('Struct Name을 입력해 주세요.');
                            return;
                        }
                        enuSpace.CreateStruct(_Modal.data.path, JSON.stringify( { name, attr }));
                    }}
                />
            </ModalBottomBox>
        </>
    )
}

//스타일----------------------------------------
const Header = styled.div`
    display: flex;
    align-items: center;
`;


const InputContentBox = styled.div`
    padding-bottom: 8px;
    width : 100%;

    &>div{
        display: flex;
        height: 38px;
        width : 100%;
        align-items: center;
        gap: 4px;

        &>input {
            flex-grow: 1;
            font-size: .9rem;
            height: 28px;
            box-sizing: border-box;
            border: 0 none;
            border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};

            &:focus{
                outline:none;
                border-bottom: 1px solid ${({theme}) => theme.base.border_color_Blue};
            }
            &:selection{
                background-color: ${({theme}) => theme.base.background_color_lightBlue};
                border: 0 none;
                border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};
            }
        }

        &>select{
            width: 90px;
            border: 0 none;
            border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};
            &:focus{
                outline: 0 none;
            }
        }

        &:last-child {
            justify-content: space-around;
            
            &>button{
                margin-left: 16px;
            }
        }
    }
`;

const VariableTableList = styled(TableContent)`
    height: calc(100% - 212px);

    .selectTable{
        background-color:${({theme}) => theme.base.background_color_LightBlue};
    }

    .table{
        width: 12%;
        text-align: center;
    }

    .checkbox {
        padding: 0 8px;
        width:30px;
        text-align: center;
    }

    .type {
        padding: 0;
        width: 68px;
        text-align: center;
    }
    
    .tagName {
        width: 26%;
        text-align: center;
    }

    .value {
        width: 34%;
    }

    .Btn{
        padding: 0 8px;
        width: 48px;
        text-align: center;

        svg{
            cursor: pointer;
            color: ${({theme}) => theme.base.border_color_Red};
            border: 1px solid ${({theme}) => theme.base.border_color_Red};
            padding: 4px;
            border-radius: 4px;
        }
    }
`;