import PropertiesContent from "components/atom/PropertiesContent";
import LabelAndInput from "babylon/components/atom/LabelAndInput";
import useDataController from "babylon/hooks/useDataController";

export default function TextBoxInfo({selectBabylonData, SelectedMesh}) {
    const {
        text, 
        size,
        resolution,
        depth,
    } = selectBabylonData;

    const {UploadDataByMesh} = useDataController();      

    return (
         
        <PropertiesContent 
            _Title="TextBoxInfo"
        >
            {<LabelAndInput 
                id='text'
                label='text'
                inputType='string'
                value={text}
                labelWidth="30%"
                changeEvent={(e)=>{
                    const targetValue = Number(e.target.value);
                    SelectedMesh.text = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}

            <LabelAndInput 
                id='size'
                label='size'
                inputType='number'
                value={size}
                labelWidth="30%"
                changeEvent={(e)=>{
                    const targetValue = Number(e.target.value);
                    SelectedMesh.size = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />
            <LabelAndInput 
                id='resolution'
                label='resolution'
                inputType='number'
                value={resolution}
                labelWidth="30%"
                changeEvent={(e)=>{
                    const targetValue = Number(e.target.value);
                    SelectedMesh.resolution = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />
            <LabelAndInput 
                id='depth'
                label='depth'
                inputType='number'
                value={depth}
                labelWidth="30%"
                changeEvent={(e)=>{
                    const targetValue = Number(e.target.value);
                    SelectedMesh.depth = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />
        </PropertiesContent>
    )
}