import DocMd from 'components/molecule/DocMd';
import DocPdf from 'components/molecule/DocPdf';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

export default  function DocumentPage(){
    const location = useLocation();
    const userId = localStorage.getItem('systemId');
    const projectId = localStorage.getItem('projectId');

    // url 파라미터 값 가져오기
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode'); 
    const PathInfo = searchParams.get('path');
    const extension = searchParams.get('extension');

    switch(extension){
        case 'md':
            return <DocMd BASE_DATA={{ userId, projectId }} path={PathInfo} mode={mode}/>
        case 'pdf':
            return (
                <ContentBox>
                    <DocPdf BASE_DATA={{ userId, projectId }} path={PathInfo} mode={mode}/>
                </ContentBox>
            )
        default:
            return (
                <ContentBox>
                    지원하지 않은 파일 형식입니다.
                </ContentBox>
            )

    }
}

const ContentBox = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;