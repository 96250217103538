import { AccessAxios } from "components/Axios";

const BaseURL = '/api/Archive';

/**
 * Archive 프로젝트 정보를 가져옵니다.
 * @param {String} ARCHIVE_SYSTEMID 
 * @returns 
 */
export const GetArchiveProjectInfo = async (ARCHIVE_SYSTEMID) => {
    const MSG = {ARCHIVE_SYSTEMID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetArchiveProjectInfo`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * board 또는 Archive 프로젝트를 생성합니다.
 * @param {String} MEMBER_SYSTEMID      - 사용자 시스템 아이디
 * @param {String} ARCHIVE_NAME         - 프로젝트 이름
 * @param {String} DESCRIPTION          - 프로젝트 설명
 * @param {Number} PUBLISH_MODE         - PUBLISH_MODE 타입 [PUBLIC: 0, PRIVATE: 1]
 * @param {Number} ARCHIVE_TYPE         - 프로젝트 타입 [board: 0, archive: 1]
 * @returns 
 */
export const CreateArchiveProject = async (MEMBER_SYSTEMID, ARCHIVE_NAME, DESCRIPTION, PUBLISH_MODE, ARCHIVE_TYPE ) => {
    const MSG = {MEMBER_SYSTEMID, ARCHIVE_NAME, DESCRIPTION, PUBLISH_MODE, ARCHIVE_TYPE};
    try{
        const res = await AccessAxios.post(`${BaseURL}/CreateArchiveProject`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * board 또는 Archive 프로젝트를 수정합니다.
 * @param {String} ARCHIVE_SYSTEMID          - Archive 프로젝트 시스템 아이디
 * @param {String} ARCHIVE_NAME              - 프로젝트 이름
 * @param {String} DESCRIPTION               - 프로젝트 설명
* @param {Number} PUBLISH_MODE         - PUBLISH_MODE 타입 [PUBLIC: 0, PRIVATE: 1]
 * @returns 
 */
export const UpdateArchiveProject = async (ARCHIVE_SYSTEMID, ARCHIVE_NAME, DESCRIPTION, PUBLISH_MODE) => {
    const MSG = {ARCHIVE_SYSTEMID, ARCHIVE_NAME, DESCRIPTION, PUBLISH_MODE};
    try{
        const res = await AccessAxios.post(`${BaseURL}/UpdateArchiveProject`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * Archive 프로젝트를 삭제합니다.
 * @param {String} ARCHIVE_SYSTEMID - Archive 프로젝트 시스템 아이디
 * @returns 
 */
export const DeleteArchiveProject = async (ARCHIVE_SYSTEMID) => {
    const MSG = {ARCHIVE_SYSTEMID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/DeleteArchiveProject`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * Archive 프로젝트 리스트를 가져옵니다.
 * @param {String} MEMBER_SYSTEMID    - 사용자 시스템 아이디
 * @returns                           - Archive, board 프로젝트 리스트
 */
export const GetArchiveProjectList = async (MEMBER_SYSTEMID) => {
    const MSG = {MEMBER_SYSTEMID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetArchiveProjectList`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 선택한 타입의 프로젝트의 정보를 가져옵니다.
 * @param {String} MEMBER_SYSTEMID  - 사용자 시스템 아이디
 * @param {Number} ARCHIVE_TYPE     - 프로젝트 타입 [board: 0, archive: 1]
 * @returns 
 */
export const GetArchiveProjectListByType = async (MEMBER_SYSTEMID, ARCHIVE_TYPE) => {
    const MSG = {MEMBER_SYSTEMID, ARCHIVE_TYPE};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetArchiveProjectListByType`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 공유 받은 선택한 타입의 Archive list 정보를 가져옵니다.
 * @param {String} MEMBER_SYSTEMID  - 사용자 시스템 아이디
 * @param {Number} ARCHIVE_TYPE     - 프로젝트 타입 [board: 0, archive: 1]
 * @returns 
 */
export const GetArchiveProjectAccessListByType = async (MEMBER_SYSTEMID, ARCHIVE_TYPE) => {
    const MSG = {MEMBER_SYSTEMID, ARCHIVE_TYPE};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetArchiveProjectAccessListByType`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 해당 프로젝트의 카테고리를 가져옵니다.
 * @param {String} ARCHIVE_SYSTEMID     - Archive 프로젝트 시스템 아이디
 * @returns 
 */
export const GetCategory = async (ARCHIVE_SYSTEMID) => {
    const MSG = {ARCHIVE_SYSTEMID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetCategory`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 게시물을 등록한다.
 * @param {String} ARCHIVE_SYSTEMID 
 * @param {String} MEMBER_SYSTEMID 
 * @param {String} TITLE 
 * @param {String} CONTENT 
 * @param {Number} IS_OPEN          - 공개여부 [공개: 1, 비공개: 0]
 * @param {Number} POST_TYPE        - 게시글 타입 [일반: 0, 고정글: 1, 공지글: 2]
 * @param {String} TEMP_SYSTEMID    - uuid 32자
 * @returns 
 */
export const CreateBoardPost = async (ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, TITLE, CONTENT, IS_OPEN, POST_TYPE, TEMP_SYSTEMID) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, TITLE, CONTENT, IS_OPEN, POST_TYPE, TEMP_SYSTEMID};

    try{
        const res = await AccessAxios.post(`${BaseURL}/CreateBoardPost`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * boar post 내용을 업데이트 한다.
 * @param {String} ARCHIVE_SYSTEMID 
 * @param {String} MEMBER_SYSTEMID 
 * @param {Number} POST_IDX 
 * @param {String} TITLE 
 * @param {String} CONTENT 
 * @param {Number} IS_OPEN 
 * @param {Number} POST_TYPE 
 * @param {String} TEMP_SYSTEMID 
 * @param {Array[Number]} DELETE_FILE_IDX 
 * @returns 
 */
export const UpdateBoardPost = async (ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, POST_IDX, TITLE, CONTENT, IS_OPEN, POST_TYPE, TEMP_SYSTEMID, DELETE_FILE_IDX) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, POST_IDX, TITLE, CONTENT, IS_OPEN, POST_TYPE, TEMP_SYSTEMID, DELETE_FILE_IDX};

    try{
        const res = await AccessAxios.post(`${BaseURL}/UpdateBoardPost`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 게시글을 삭제한다.
 * @param {String} ARCHIVE_SYSTEMID     - Archive 프로젝트 시스템 아이디
 * @param {String} MEMBER_SYSTEMID      - 사용자 시스템 아이디
 * @param {Number} POST_IDX             - 게시글 인덱스
 * @returns 
 */
export const DeleteBoardPost = async (ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, POST_IDX) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, POST_IDX};
    try{
        const res = await AccessAxios.post(`${BaseURL}/DeleteBoardPost`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * archive list를 가져온다.
 * @param {String} ARCHIVE_SYSTEMID     - Archive 프로젝트 시스템 아이디
 * @param {String} MEMBER_SYSTEMID      - 사용자 시스템 아이디
 * @param {Number} PAGE_NUMBER          - 페이지 넘버
 * @param {Number} PAGE_SIZE            - 한페이지 당 데이터 수
 * @returns 
 */
export const GetBoardPostList = async ({ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, PAGE_NUMBER, PAGE_SIZE}) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, PAGE_NUMBER, PAGE_SIZE};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetBoardPostList`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 검색 키워드를 포함한 게시판을 조회 한다.
 * @param {String} ARCHIVE_SYSTEMID - Archive 프로젝트 시스템 아이디
 * @param {String} MEMBER_SYSTEMID  - 사용자 시스템 아이디
 * @param {Number} PAGE_NUMBER      - 페이지 넘버
 * @param {Number} PAGE_SIZE        - 한페이지 당 데이터 수
 * @param {String} SEARCH_WORD      - 검색 키워드
 * @returns 
 */
export const SearchBoardPost = async({ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, PAGE_NUMBER, PAGE_SIZE, SEARCH_WORD}) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, PAGE_NUMBER, PAGE_SIZE, SEARCH_WORD};
    try{
        const res = await AccessAxios.post(`${BaseURL}/SearchBoardPost`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * board의 상세 게시글을 가져옵니다.
 * @param {String} ARCHIVE_SYSTEMID     - Archive 프로젝트 시스템 아이디
 * @param {String} MEMBER_SYSTEMID      - 사용자 시스템 아이디
 * @param {Number} POST_IDX             - 게시글 인덱스
 * @returns 
 */
export const GetBoardPost = async (ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, POST_IDX) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, POST_IDX};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetBoardPost`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 사용자의 권한 정보를 가져온다.
 * @param {String} MEMBER_SYSTEMID  - 사용자 시스템 아이디
 * @returns 
 */
export const GetArchiveProjectAccessList = async (MEMBER_SYSTEMID) => {
    const MSG = {MEMBER_SYSTEMID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetArchiveProjectAccessList`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 해당 Archive 프로젝트의 사용자 권한을 추가 또는 수정합니다.
 * @param {String} ARCHIVE_SYSTEMID     - Archive 프로젝트 시스템 아이디 
 * @param {String} MEMBER_SYSTEMID      - 사용자 시스템 아이디
 * @param {Array[String]} OTHER_ACCESS  - {"EMAIL": "string", "AUTHORITY": 0}  AUTHORITY type : [NORMAL: 0, MANAGER: 1, MASTER: 2]
 * @returns 
 */
export const AddOrUpdateArchiveAccess = async (ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, OTHER_ACCESS) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, OTHER_ACCESS};
    try{
        const res = await AccessAxios.post(`${BaseURL}/AddOrUpdateArchiveAccess`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * OTHER_EMAIL 의 사용자 권한을 삭제합니다.
 * @param {String} ARCHIVE_SYSTEMID     - Archive 프로젝트 시스템 아이디
 * @param {String} MEMBER_SYSTEMID      - 사용자 시스템 아이디
 * @param {Array[String]} OHTER_EMAIL   - 사용자 이메일 리스트
 * @returns 
 */
export const DeleteArchiveAccess = async (ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, OHTER_EMAIL) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, OHTER_EMAIL};
    try{
        const res = await AccessAxios.post(`${BaseURL}/DeleteArchiveAccess`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 해당 프로젝트의 사용자 권한을 가져옵니다.
 * @param {String} ARCHIVE_SYSTEMID     - Archive 프로젝트 시스템 아이디
 * @param {String} MEMBER_SYSTEMID      - 사용자 시스템 아이디
 * @returns 
 */
export const GetArchiveMemberAccess = async (ARCHIVE_SYSTEMID, MEMBER_SYSTEMID) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetArchiveMemberAccess`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * board의 댓글을 작성한다.
 * @param {String} ARCHIVE_SYSTEMID     - Archive 프로젝트 시스템 아이디
 * @param {String} MEMBER_SYSTEMID      - 사용자 시스템 아이디
 * @param {Number} POST_IDX             - board Post Index
 * @param {String} REPLY                - 답글 내용
 * @returns 
 */
export const CreateBoardReply = async(ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, POST_IDX, REPLY) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, POST_IDX, REPLY};
    try{
        const res = await AccessAxios.post(`${BaseURL}/CreateBoardReply`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * board의 댓글을 제거한다.
 * @param {String} MEMBER_SYSTEMID      - 사용자 시스템 아이디
 * @param {Number} POST_IDX             - board Post Index
 * @param {Number} REPLY_IDX            - 답글 Index
 * @returns 
 */
export const DeleteBoardReply = async(MEMBER_SYSTEMID, POST_IDX, REPLY_IDX) => {
    const MSG = { MEMBER_SYSTEMID, POST_IDX, REPLY_IDX};
    try{
        const res = await AccessAxios.post(`${BaseURL}/DeleteBoardReply`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 댓글을 수정한다.
 * @param {String} MEMBER_SYSTEMID  - 사용자 시스템 아이디
 * @param {Number} POST_IDX         - board Post Index
 * @param {Number} REPLY_IDX        - 댓글 index
 * @param {String} REPLY            - 댓글 내용
 * @returns 
 */
export const UpdateBoardReply = async(MEMBER_SYSTEMID, POST_IDX, REPLY_IDX, REPLY) => {
    const MSG = {MEMBER_SYSTEMID, POST_IDX, REPLY_IDX, REPLY};
    try{
        const res = await AccessAxios.post(`${BaseURL}/UpdateBoardReply`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 자료실의 tree 정보를 가져온다.
 * @param {String} ARCHIVE_SYSTEMID     - 자료실 ID
 * @param {String} MEMBER_SYSTEMID      - 사용자 시스템 아이디
 * @param {Number} REPO_ID              - 폴더 ID (미 임렵시 Root)
 * @returns 
 */
export const GetRepoTree = async(ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, REPO_ID) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, REPO_ID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/GetRepoTree`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 자료실에 폴더를 생성한다.
 * @param {String} ARCHIVE_SYSTEMID - 자료실 Id
 * @param {String} MEMBER_SYSTEMID  - 사용자 시스템 ID
 * @param {String} FOLDER_NAME      - 폴더명
 * @param {Number} PARENT_REPO_ID   - 생성 위치
 * @returns 
 */
export const CreateRepoFolder = async(ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, FOLDER_NAME, PARENT_REPO_ID) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, FOLDER_NAME, PARENT_REPO_ID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/CreateRepoFolder`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}

/**
 * 자료실의 데이터를 삭제한다.
 * @param {String} ARCHIVE_SYSTEMID 
 * @param {String} MEMBER_SYSTEMID 
 * @param {Number} REPO_ID 
 * @returns 
 */
export const DeleteRepoFolderOrFile = async(ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, REPO_ID) => {
    const MSG = {ARCHIVE_SYSTEMID, MEMBER_SYSTEMID, REPO_ID};
    try{
        const res = await AccessAxios.post(`${BaseURL}/DeleteRepoFolderOrFile`, MSG);
        return res;

    }catch(err){
        throw err;
    }
}