import { useState, useEffect } from 'react';

import { getRGB, hexToRgb, setColor, findParentWithClass} from 'components/Utility';

import styled from 'styled-components';
import ColorPicker, {useColorPicker} from 'react-best-gradient-color-picker'

function ModalColorPickerContent({_colorId, _color, _ChangeEvent}){
    const [fill, setFill] = useState('');
    const [ColorRGBA, setColorRGBA] = useState('');
    const [ColorPickerValue, setColorPickerValue] = useState('rgba(0,0,0)');
    const [isModal, setIsModal] = useState({
        visibility: false,
        topPosition: 0,
    });

    const { valueToHex } = useColorPicker(ColorPickerValue, setColorPickerValue);
    const hexString = valueToHex();

    useEffect(()=>{
        const targetColor = _color.color;
        const fillValueRGB = getRGB(targetColor);

        setColorRGBA({
            r: fillValueRGB['R'],
            g: fillValueRGB['G'],
            b: fillValueRGB['B'],
            a: 1,
        });
        setFill(setColor(targetColor));
    }, [_color]);

    useEffect(()=>{
        setColorPickerValue(`rgb(${ColorRGBA['r']},${ColorRGBA['g']},${ColorRGBA['b']}`);
    },[ColorRGBA]);

    const ChangeFill = (e) =>{
        const pattern =/[^0-9a-fA-F]/;
        setFill(e.target.value.replace(pattern, ''));
    }

    const ChangeValue = (e) =>{
        let attributeValue = e.target.value;
        if(e.target.name.includes('opacity')){
            attributeValue = attributeValue / 100;
        }else{
            if (attributeValue.length === 6)
            attributeValue = hexToRgb(e.target.value);
            else if (attributeValue.length === 3) {
                const hex = attributeValue.split('').map(char => char + char).join('');
                attributeValue = hexToRgb(hex);
            } else {
                attributeValue = _color.color;
                setFill(hexString.replace('#', ''));
            }
        }
        
        const JsonData = [{ attributeName : e.target.id, attributeValue}];
        _ChangeEvent(JsonData);
    }

    const KeyDownEvent = (e) => {if(e.key === 'Enter') ChangeValue(e)};
    const BlurEvent = (e) =>ChangeValue(e);

    const ShowColorPicker = (e) => {
        const {clientY, clientX} = e;
        const ParentDiv = findParentWithClass(e.target, "ModalStyle");
        const [ , transformX, transformY] = ParentDiv.style.transform.match(/translate\((-?\d+\.?\d*)px,\s*(-?\d+\.?\d*)px\)/);
        const [ClickPosX, ClickPosY] = [clientX - transformX, clientY - transformY];
        const AlignPos = {
            Y:(ParentDiv.offsetHeight < ClickPosY + 250) ? ClickPosY - 250 : ClickPosY,
            X: ClickPosX,
        };
        setIsModal({visibility: true, AlignPos});
    }
    
    const CloseColorPicker = () => {
        setIsModal(false);
    };

    const ChangeColorPicker = (e) =>{
        //eslint-disable-next-line
        const reg = /[\)\(]/gi;
        const arrRGBA = e.substring(e.indexOf('('), e.length).replace(reg, '').split(',');
        const JsonData = [
            { attributeName : _colorId, attributeValue :`rgb(${arrRGBA[0]},${arrRGBA[1]},${arrRGBA[2]})`}
        ];
        _ChangeEvent(JsonData);
        setColorPickerValue(e);
    }

    const ColorPickerModal = (e) =>{
        return(
            <ColorPickerModalContent  alignPos={isModal['AlignPos']} onClick={(e)=>e.preventDefault()}>
                <BgColorPicker onClick={ CloseColorPicker } />
                <div className='ColorPicker'>
                    <ColorPicker value={ColorPickerValue} onChange={ChangeColorPicker} 
                        hidePresets = {true}
                        hideAdvancedSliders = {true}
                        hideColorGuide = {true}
                        hideOpacity= {true}
                        hideControls = {true}
                        width = {270}
                        height = {140}
                    />
                </div>
            </ColorPickerModalContent>
        )
    }

    return(
        <>
            <Content>
                <div>
                    <ColorChip style={{background:`${ColorPickerValue}`}} onClick={ShowColorPicker}/>
                    <ColorInputBox id={_colorId} type="text" value={fill} onChange={ChangeFill} onKeyDown={KeyDownEvent} onBlur={BlurEvent} maxLength={6}/>
                </div>
            </Content>
            {isModal['visibility'] && ColorPickerModal() }
        </>
    )
}

export default ModalColorPickerContent;

// 스타일--------------------------------------------------------------
const Content = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &>div {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
    }

    .GradientType{
        font-weight: 500;
    }
`;

const ColorChip = styled.span`
    display: inline-block;
    width: 26px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    border-radius: 2px;
    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
`;

const ColorInputBox = styled.input`
    font-size: .9rem;
    height: 28px;
    box-sizing: border-box;
    border: 0 none;
    width: 56px;
    margin-left: 4px;
    flex-grow: 1;
    background-color: transparent;

    &:focus-visible{
        outline: 1px solid ${({theme}) => theme.base.border_color_DarkBlue};
    }
`;

const ColorPickerModalContent = styled.div`
    position: fixed;
    left: ${props => `${props.alignPos.X}px;` };
    top:${props => `${props.alignPos.Y}px;` };
    z-index: 9;

    &>.ColorPicker{
        width: 270px;
        padding: 8px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
        z-index: 10;
    }
`;

const BgColorPicker = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    cursor: default;
`;