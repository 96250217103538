export default class Axis {
    constructor(object, {x=0, y=0, z=0}, type){
        if(!object)
            throw new Error("object is not defined");

        this._object = object;
        this._type = type;
        this._x = x;
        this._y = y;
        this._z = z;
    }

    get x(){ return this._x;}
    set x(x){
        this._x = x;
        this._object[this._type].x = x;
    }

    get y(){ return this._y;}
    set y(y){
        this._y = y;
        this._object[this._type].y = y;
    }

    get z(){return this._z;}
    set z(z){
        this._z = z;
        this._object[this._type].z = z;
    }
}