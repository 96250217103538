export const SUPPORT_SVG_TYPE = {
    PICTURE: 'picture',
    HMI: 'hmi',
}

export const DATA_TYPE = {
    BOOL: 'bool',
    INT: 'int',
    LONG: 'long',
    FLOAT: 'float',
    DOUBLE: 'double',
    STRING: 'string',
}

export const SUPPORT_FUNCTION = {
    ONCLICK: '_onclick',
    ONLOAD: '_onload',
    ONMOUSEDOWN: '_onmousedown',
    ONMOUSEMOVE: '_onmousemove',
    ONMOUSEOUT: '_onmouseout',
    ONMOUSEOVER: '_onmouseover',
    ONMOUSEUP: '_onmouseup',
    ONMOUSEWHEEL: '_onmousewheel',
    ONTASKVIEW: '_ontaskview',
    ONKEYDOWN: '_onkeydown',
    ONKEYUP: '_onkeyup',
    USERFUNCTION: '_userfunction',
}

export const RESOURCE_TYPE = {
    SYMBOL : "Symbol",
    IMAGE : "Image",
    PICTURE : "Picture",
    TEMPLATE : "Template",
}

export const TEMPLATE_RESOURCE_TYPE = {
    SYMBOL : "Symbol",
    IMAGE : "Image",
    PICTURE : "Picture",
}

export const IMG_RESOURCE_TYPE = {
    FOLDER: 'folder',
    IMAGE: 'image',
}

export const ARCHIVE_TYPE = {
    BOARD: 0,
    ARCHIVE: 1,
}

export const ARCHIVE_POST_TYPE = {
    NORMAL: 0,          // 일반글
    STICKY: 1,          // 고정글
    ANNOUNCEMENT: 2,    // 공지글
}

export const AUTHORITY_TYPE = {
    NORMAL: 0,
    MANAGER: 1, //(일반사용자 읽기 쓰기 지정, 게시글 카테고리 이동, 게시글 삭제)
    MASTER: 2,  //(매니저 지정,게시판 카테고리 만들기, 카테고리 삭제)
    NONE: 3,
}

export const ARCHIVE_TREE_TYPE = {
    FOLDER: 0,
    FILE: 1,
}

// TODO: 추후 삭제 예정
export const ARCHIVE_ACCESS_TYPE = {
    READ: 0,
    WRITE: 1,
    EDIT: 2,
    MANAGE: 3,
    MASTER: 4,
}

// TODO: ACCESS_LEVEL와 PUBLISH_TYPE는 같은 내용 (240318)
// PUBLIC: 0, PRIVATE: 1로 정의
// 기존 ACCESS_LEVEL로 처리된 부분은 PUBLISH_TYPE로 변경 필요
// ACCESS_LEVEL는 사용하지 않는다.
export const ACCESS_LEVEL = { 
    PUBLIC: 'public',
    PRIVATE: 'private',
}

export const PUBLISH_TYPE = {
    PUBLIC: 0,
    PRIVATE: 1,
}
////////////////////////////