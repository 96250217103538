import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import PropertiesContentAdd from "components/atom/PropertiesContentBtn";
import {BtnGhost} from "components/atom/Button";
import useModalPopup from 'hooks/useModalPopup';

import styled from 'styled-components';
import {MdClear} from "react-icons/md";

function EventInfoContent({enuSpace}){
    const ARRAY_ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ARRAY_ACTIVE_PICTURE_URL);  //활성화 된 picture URL
    const [CurrentSvgInfo, setCurrentSvgInfo] = useState([]);
    const {
        id: NodeID,
        path: NodePath,
        from: NodeFrom,
        object_name: NodeObjectName,
        type: NodeType,
        isMultiNode,
    } = useSelector(state => state.selectNodeData);                                         //캠퍼스 클릭시 선택된 node 정보
    const [EventInfoList, setEventInfoList] = useState('');
    const {AddModal} = useModalPopup();

    const InitEventTree = useCallback((e) => {
        if(isMultiNode) return;

        const [svgType, ...PictureURL] = ARRAY_ACTIVE_PICTURE_URL;

        let URL = '';
        let Type = '';
        let symbolName = '';

        //ExploreTree 클릭으로 EventTree load
        if (NodeFrom === 'ExplorerTree') {
            URL = NodePath.join('\\');
            Type = NodePath[0];
        } else {
            switch (svgType) {
                case 'picture':
                    URL = `${svgType}\\${PictureURL[0]}`;
                    Type = svgType;
                    break;

                case 'library':
                    URL = `${svgType}\\${PictureURL[0]}\\${PictureURL[1]}`;
                    Type = PictureURL[0];
                    symbolName = `#${PictureURL[2]}`;
                    break;

                default:
                    break;
            }
        }

        setCurrentSvgInfo({ URL, Type, symbolName});
        enuSpace.GetEventTree(URL, NodeID, Type, symbolName);
    }, [enuSpace, ARRAY_ACTIVE_PICTURE_URL, NodeFrom, NodeID, NodePath, isMultiNode]);

    useEffect(()=>{
        if(NodeID === undefined){
            setEventInfoList('');
            return;
        }

        InitEventTree();
    }, [NodeID, InitEventTree]);

    const ShowEventScript = (e, eventGroupName, eventName) =>{
        if (e.defaultPrevented) return;
        e.preventDefault();
        AddModal(`${NodeID}.${eventName}`, 'Script Editor (lua)', 'Event', { id:NodeID, mode: 'Edit', EventInfoList, CurrentSvgInfo, eventGroupName, eventName, object_name:NodeObjectName});
    }

    const DeleteScriptCode = (e, eventGroupName, eventName) => {
        e.preventDefault();
        const {symbolName, URL, Type} = CurrentSvgInfo;
        if(!window.confirm(`"${eventName}" 이벤트를 삭제하기 겠습니까?`)) return;
        enuSpace.DeleteScript(URL, NodeID, eventName, Type,  symbolName || '');

        //useState 업데이트
        const tempEventIfoList = JSON.parse(JSON.stringify(EventInfoList));
        delete tempEventIfoList[eventGroupName][eventName];
        setEventInfoList(tempEventIfoList);
        InitEventTree();
    }

    window.ReloadEventTree = () => InitEventTree();

    window.Re_GetEventInfoList = (_EventInfoList) =>{
        const {type, data} = JSON.parse(_EventInfoList);
        if(type === 'success')
            setEventInfoList(data);
        else if(type === 'failed')
            setEventInfoList('');
    }

    window.Re_DeleteScript = (_msg) =>{
        const {type, data} = JSON.parse(_msg);
        if(type === 'success'){

        }else if(type === 'failed'){
            AddModal('Re_DeleteScript', 'failed', 'alert', { text: data });
        }
    }

    const RenderEventList = (_keyValue) => (
        <ul>
            {Object.keys(EventInfoList[_keyValue]).map((_EventTypeName) => {
                return (
                    <EventRow key={_EventTypeName} title={_EventTypeName} onClick={(e)=>ShowEventScript(e, _keyValue, _EventTypeName)}>
                        <span>{_EventTypeName}</span>
                        {NodeObjectName !== 'use' &&
                            <MdClear onClick={(e) => {DeleteScriptCode(e, _keyValue, _EventTypeName)}}/>
                        }
                    </EventRow>
                )
            })}
        </ul>
    )

    return(
        <div style={{userSelect: 'none'}}>
            <PropertiesContentAdd 
                _Title={NodeObjectName ? `(${NodeObjectName})Event` : "Event" } 
                _btn={
                    (NodeID && NodeObjectName !== 'use' && NodeType !== 'DB_empty' && NodeType !== 'attribute' && (NodeType !== 'a.d' || NodePath[0] === 'global'))  && 
                    <BtnGhost 
                        label="Add" type='sm' 
                        onClick={(e) => AddModal(NodeID, 'Script Editor (lua)', 'Event', { id:NodeID, mode: 'Add', EventInfoList, CurrentSvgInfo })}
                    />
                } 
            />

            {EventInfoList['userfunction'] && RenderEventList('userfunction') }
            {EventInfoList['eventfunction'] && RenderEventList('eventfunction') }
        </div>
    )
}

export default EventInfoContent;

//스타일-------------------------------
const EventRow = styled.li`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 4px 8px 4px 16px;
    box-sizing: border-box;
    cursor: pointer;

    &>span{
        width: calc(100% - 28px);
        line-height: 32px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    &:hover{
        background-color: ${({theme}) => theme.base.background_color_LightBlue};
        &>svg{
            display:block;
        }
    }

    &::after{
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        bottom:0;
        width: calc(100% - 16px);
        background-color: ${({theme}) => theme.base.border_color_LightGray};
    }

    &:before{
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 6px;
        height: 16px;
        width: 4px;
        border-radius: 2px;
        background-color: #64dd17;
        transform: translate(-50%, -50%);
    }

    svg{
        font-size: 1.3rem;
        padding: 2px;
        color: ${({theme}) => theme.base.font_color_Red};
        border: 1px solid transparent;
        border-radius: 4px;
        display:none;
        cursor: pointer;

        &:hover{
            border-color: ${({theme}) => theme.base.font_color_Red};
            background-color: ${({theme}) => theme.base.background_color_LightRed};
        }
    }
`;