import {Spinner} from 'components/css/common';
import Draggable from 'react-draggable';
import styled from "styled-components";

export default function LoadingPage(){
    return(
        <LoadingContent>
            Loading....
        </LoadingContent>
    )
}

export function LoadingLayer({marginTop, isLoadingPage, size="24px", margin="-12px"}){
    if(!isLoadingPage)
        return null;

    return(
        <Draggable>
            <Loading marginTop={marginTop}>
                <Content>
                    <Spinner size={size} margin={margin} />
                    <span>Loading....</span>
                </Content>
            </Loading>
        </Draggable>
    )
}


// 스타일------------------------------------
const LoadingContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;

    font-size: 2rem;
    color: rgba(255, 255, 255, 0.8);

    // background: url('/img/Loading_icon.gif') no-repeat center center fixed;
`;

const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* fixed로 변경하여 윈도우 기준 중앙 정렬 */
    top: ${({ marginTop }) => `calc(${marginTop ? marginTop : '0px'} + 50vh - 36px)`};
    left: 50%;
    transform: translate(-50%, -50%);
    width: 205px;
    height: 72px;
    background-color: transparent;
    z-index: 10; /* 모달처럼 다른 요소 위에 표시되도록 */
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    padding: 24px 32px;
    border-radius: 0.3rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;

    span{
        font-size: 1.3rem;
        color: ${({ theme }) => theme.base.font_color_Black};
        margin-left: 16px;
    }
`;