import { useState, useEffect } from 'react';
import {BgPopup, WarningMsg} from 'components/css/common'
import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import useMember from 'hooks/useMember';
import { isEmail } from 'components/Utility';

import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

function SessionExpired (){
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const systemId = localStorage.getItem('systemId');

    window.SessionLoginPopup = () => {
        setShowLoginPopup(true);
    }

    useEffect(()=>{
        return () =>{
            delete window.SessionLoginPopup;
        }
    }, []);

    const navigate = useNavigate();
    const {Login} = useMember();

    const [loginForm, setLoginForm] = useState({ email: '', pw: '', msg: '' });

    const ChangeInputData = (e) => {
        const Target = e.target;
        
        if (Target.name === 'email')
            setLoginForm({ ...loginForm, email: Target.value, msg: ''});
        else if (Target.name === 'pw')
            setLoginForm({ ...loginForm, pw: Target.value, msg: '' });
    }

    const checkSubmit = (e) => {
        e.preventDefault();

        if (loginForm.email === '') {
            setLoginForm({ ...loginForm, msg: 'Email 입력해주세요.' })
            return;
        } else if (!isEmail(loginForm.email)) {
            setLoginForm({ ...loginForm, msg: '잘못된 Email 형식입니다.' })
            return;
        }

        if (loginForm.pw === '') {
            setLoginForm({ ...loginForm, msg: 'Password 입력해주세요.' })
            return;
        }

        Login(loginForm.email, loginForm.pw)
            .then(res => {
                const responseData = res['data'];
                if(responseData['result'] === 'Fail'){
                    setLoginForm({ ...loginForm, msg: '로그인 실패 다시 입력해주세요' });
                    return;
                }

                const {
                    Authorization,
                    RefreshToken,
                    MEMBER_SYSTEMID, 
                    NICKNAME, 
                    MEMBERNAME, 
                    PROFILE_SYSTEMID
                } = JSON.parse(responseData['msg']);

                if(systemId !== MEMBER_SYSTEMID){
                    setLoginForm({ ...loginForm, msg: '계정정보가 다릅니다. 다시확인하여 주십시요.' });
                    return;
                }

                localStorage.setItem('systemId', MEMBER_SYSTEMID);
                localStorage.setItem('nickname', NICKNAME);
                localStorage.setItem('name', MEMBERNAME);
                localStorage.setItem('profileId', PROFILE_SYSTEMID);
                localStorage.setItem('email', loginForm.email);
                localStorage.setItem('Authorization', Authorization);
                localStorage.setItem('refreshtoken', RefreshToken); 

                setLoginForm({ email: '', pw: '', msg: '' })
                setShowLoginPopup(false);

            }).catch(err => {
                setLoginForm({ ...loginForm, msg: '로그인 실패 다시 입력해주세요.' })
            });
    }

    const Submit = (e) =>{
        if(e.key === 'Enter') checkSubmit(e);
    }

    return(
        <Content isOpen={showLoginPopup}>
            <BgPopup/>
            <LoginContent>
                <span className='title'>로그인 정보가 만료되었습니다.<br/>재인증이 필요합니다.</span>
                <Label htmlFor='UserEmail'>Email</Label>
                <InputContent id='UserEmail' value={loginForm['email'] || ''} onChange={ChangeInputData} name="email" placeholder='ENUMETACORE@enu-tech.co.kr' autoComplete="email"/>
                <Label htmlFor='UserPw'>Pw</Label>
                <InputContent id='UserPw' value={loginForm['pw'] || ''} onChange={ChangeInputData} onKeyDown={Submit} name="pw" type="password" placeholder='password'/>
                <WarningMsg>{loginForm['msg']}</WarningMsg>
                <BtnPrimary label="Reauthenticate" onClick={checkSubmit}/>
                <BtnSecondary label="Home" onClick={(e)=>{
                    if(window.confirm('정말 홈으로 이동하시겠습니까? (작업중인 정보는 저장되지 않습니다.)')){
                        localStorage.removeItem('Authorization');
                        localStorage.removeItem('refreshtoken');
                        navigate('/');
                    }
                }}/>
            </LoginContent>
        </Content>
    )
}

export default SessionExpired;

//스타일----------------------------------------------
const Content = styled.div`
    display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
`;

const LoginContent = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 45%;
    left: 50%;
    width: 350px;
    padding: 2rem;
    border-radius: 0.3rem;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background-color: #FFF;
    z-index: 20;

    &>span.title{
        font-size: 1.2rem;
        font-weight: bold; 
        text-align: center;
        line-height: normal;
        margin-bottom: 24px;
        color: ${({ theme }) => theme.base.font_color_Black};
    }

    &>button{
        display: flex;
        justify-content: center;
        margin-top: 8px;
        width: 100%;
        height: 48px;
    }
`;

const InputContent = styled.input`
    margin-top: 8px;
    height: auto;
    width: 100%;
    height: 48px;
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    border-radius: 4px;
    padding: 8px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    flex-grow: 1;
    margin-bottom: 16px;

    &:focus{
        outline:none;
        border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
    }
    
    &::placeholder {
        color: ${({ theme }) => theme.base.font_color_DarkGray};
        opacity: 0.3;
    }
`;

const Label = styled.label`
    color: ${({ theme }) => theme.base.font_color_DarkGray};
    font-size: .9rem;
`;