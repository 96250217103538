import { useState } from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import Checkbox from "components/atom/Checkbox";
import {RadioComponent} from "components/atom/RadioContent";
import SelectList from "components/atom/SelectList";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import {FontSizeIcon} from 'components/common/EnuSpaceIcon';
import FontFamily from 'components/common/FontFamily';
import { GroupName } from 'components/css/common';

import styled from "styled-components";

export default function RealtimeLabelInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        label_format: NodeLabelFormat,
        min_label_visible: NodeMinLabelVisible,
        max_label_visible: NodeMaxLabelVisible,
        label_font_family: NodeLabelFontFamily,
        label_font_size: NodeLabelFontSize,
    } = _data;
    const [fontClass] = useState(new FontFamily());

    const ChangeVisible = (e) => {
        const attributeName = e.target.name;
        _ChangeEvent([{ attributeName, attributeValue: e.target.checked ? 'visible' : 'hidden' }]);
    };

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return (
        <PropertiesContent _Title="Label">
            <div>
                <GroupName width={70}>format</GroupName>
                <RadioComponent
                    attributeName='label-format'
                    dataList={{number: "number", "engineering unit": "eng unit"}} 
                    selectedValue={NodeLabelFormat}
                    disabled={_disabled}
                    changeEvent={(attributeName, attributeValue) => _ChangeEvent( [{ attributeName, attributeValue }])}
                />
            </div>
            <div>
                <Checkbox _id='min-label-visible' checked={NodeMinLabelVisible === '1'} onChange={ChangeVisible} _disabled={_disabled}>min-label-visible</Checkbox>
            </div>
            <div>
                <Checkbox _id='max-label-visible' checked={NodeMaxLabelVisible === '1'} onChange={ChangeVisible} _disabled={_disabled}>max-label-visible</Checkbox>
            </div>
            <div className="margin-top-div">
                <SelectList
                    attributeName="label-font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeLabelFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <ColorComponent label="font" attributeName="label-font-color" isGradient={false} isVisibility={false} isOpacity={false} {...commonProps} />
                <CustomInputComponent
                    label={<FontSizeIcon />}
                    attributeName="font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeLabelFontSize}
                    {...commonProps}
                />
            </div>
        </PropertiesContent>
    );
}

const CustomInputComponent = styled(InputComponent)`
    width: 104px;
`;