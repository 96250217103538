export const floatToStr = (data) => {
    if (data === '')
        return '';

    let result = parseFloat(data).toFixed(2); 
    return Number(result);
};

export const rgbToHex = (rgbType) => {
    /* 
    ** 컬러값과 쉼표만 남기고 삭제하기. 
    ** 쉼표(,)를 기준으로 분리해서, 배열에 담기. 
    */
    var rgb = rgbType.replace(/[^%,.\d]/g, "").split(",");

    rgb.forEach(function (str, x, arr) {

        /* 컬러값이 "%"일 경우, 변환하기. */
        if (str.indexOf("%") > -1) str = Math.round(parseFloat(str) * 2.55);

        /* 16진수 문자로 변환하기. */
        str = parseInt(str, 10).toString(16);
        if (str.length === 1) str = "0" + str;

        arr[x] = str;
    });

    return rgb.join("");
}

export const hexToRgb = (hex) => {

    const red = parseInt(hex[0] + hex[1], 16);
    const green = parseInt(hex[2] + hex[3], 16);
    const blue = parseInt(hex[4] + hex[5], 16);

    const returnValue = 'rgb(' + red + ',' + green + ',' + blue + ')';
    return returnValue;
}

export const setColor = (str) => {
    if (str === undefined) return;
    if (str.includes('rgb')) {
        return rgbToHex(str);
    } else if (str.includes('#')) {
        return str.substr(1);
    }
    return str;
}

export const getRGB = (RGB) => {
    let returnValue = { R: 0, G: 0, B: 0 };
    if (RGB.includes('#')) {
        const HEX = RGB.replace('#', '');
        returnValue = {
            R: parseInt(HEX[0] + HEX[1], 16),
            G: parseInt(HEX[2] + HEX[3], 16),
            B: parseInt(HEX[4] + HEX[5], 16),
        }
    } else {
        let rgb = RGB.substr(4);
        // eslint-disable-next-line
        rgb = rgb.replace('\)', '');
        rgb = rgb.split(',');

        returnValue = {
            R: rgb[0],
            G: rgb[1],
            B: rgb[2],
        }
    }
    return returnValue;
}

export const isEmail = (email) => {
    if (email === 'admin' || email === 'enu')
        return true;
    // eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return emailRegex.test(email);
};
// eslint-disable-next-line
export const isPassword = (password) => {
    const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegEx.test(password);
};

/**
 * _nodeName 까지 올라가는 target을 찾는다.
 * @param {object} _target      - e.target 
 * @param {string} _nodeName    - 찾고자 하는 nodeName (default: DIV)
 * @returns                     - 찾은 target
 */
export const GetRealTarget = (_target, _nodeName = 'DIV') => {
    let Target = _target;
    while (Target.nodeName !== _nodeName) {
        Target = Target.parentElement;
    }
    return Target;
}

/**
 * className이 _className인 target을 찾는다.
 * @param {object} element 
 * @param {string} className 
 * @returns 
 */
export const findParentWithClass = (element, className) => {
    let parent = element;
    while (parent) {
        if (parent.classList && parent.classList.contains(className)) {
            return parent;
        }
        parent = parent.offsetParent;
    }
    return null;
}


/**
 * 0 ~ 100 사이의 숫자만 반환
 * @param {number} value 
 */
export const scaleOpacityToPercentage = (value) => {
    const pattern = /[^0-9]/;
    let OpacityValue = value.replace(pattern, '');
    if (OpacityValue > 100) {
        OpacityValue = 100;
    } else if (OpacityValue < 0) {
        OpacityValue = 0;
    }

    return OpacityValue;
}

export const SkeletonTable = ({ col, row }) => {
    const skeletonRows = [];

    for (let i = 0; i < row; i++) {
        const skeletonRow = (
            <tr key={i} className='skeleton'>
                {Array.from({ length: col }, (_, j) => <td key={j}><div/></td>)}
            </tr>
        );
        skeletonRows.push(skeletonRow);
    }

    return skeletonRows;
}

/**
 * 시간 포맷을 반환한다. parameter가 없으면 현재 시간을 반환한다.
 * @param {string} Time 
 * @returns 2023-10-12 17:23:59
 */
export const SetTimeFormat = (Time = null) => {
    const now = new Date(Time || Date.now());
    const year = now.getFullYear().toString().padStart(4, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const date = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
};

/**
 * DB 권한 레벨을 반환 한다.
 * @param {object} userAuth - { READ_AUTH, WRITE_AUTH, EDIT_AUTH, MANAGE_AUTH, MASTER_AUTH }
 * @returns string - 권한 레벨
 */
export const displayHighestAuth = (userAuth) => {
    const AuthLevels = [
        { level: 'READ', label: 'Read' },
        { level: 'WRITE', label: 'Write' },
        { level: 'EDIT', label: 'Edit' },
        { level: 'MANAGE', label: 'Manage' },
        { level: 'MASTER', label: 'Master' },
    ];

    let highestAuth = null;

    AuthLevels.forEach(auth => {
        if (userAuth[auth.level] === 1) {
            highestAuth = auth;
        }
    });

    return highestAuth ? highestAuth.label : '-';
}

/**
 * 파일 사이즈 포맷을 반환 한다.
 * @param {Number} sizeInBytes 
 * @returns 
 */
export const FormatFileSize = (sizeInBytes) => {
    const KB = 1024;
    const MB = KB * KB;
    const GB = MB * KB;

    if (sizeInBytes >= GB) {
        return (sizeInBytes / GB).toFixed(2) + ' GB';
    } else if (sizeInBytes >= MB) {
        return (sizeInBytes / MB).toFixed(2) + ' MB';
    } else if (sizeInBytes >= KB) {
        return (sizeInBytes / KB).toFixed(2) + ' KB';
    } else {
        return sizeInBytes + ' bytes';
    }
}

/**
 * 데이터 형태를 확인한다.
 * @param {*} data 
 * @returns 데이터가 array일 경우 "Mixed" 문자를 반환
 */
export const ProcessArrayInput = (data) =>{
    if(Array.isArray(data)){
        return "Mixed";
    }else{
        return data;
    }
}

/**
 * 10진수 색상 값을 두 자리 16진수 문자열로 변환합니다.
 * 
 * 이 함수는 색상 값을 나타내는 10진수 문자열(0-255)을 입력받아,
 * 이를 정수로 변환한 후 16진수 문자열로 변환합니다. 결과 문자열이
 * 두 자리가 되도록 앞에 0을 추가합니다.
 * 
 * @param {string} value - 10진수 색상 값을 나타내는 문자열 (0-255).
 * @returns {string} 두 자리 16진수 문자열로 변환된 색상 값.
 */
export const ToHexString = (value) => {
    return parseInt(value, 10).toString(16).padStart(2, "0");
}