class FontFamily {

    constructor(){
        this.fontFamilyList = {
            "NotoSansKR" : {
                "base_weight" : "regular",
                "font_weight": [ "black", "bold", "medium", "regular", "light", "demiLight", "thin" ]
            },
            "NotoSerifKR" : {
                "base_weight" : "regular",
                "font_weight": [ "black", "bold", "medium", "regular", "light", "extraLight", "semiBold" ]
            },
            "NanumBarunGothic" : {
                "base_weight" : "normal",
                "font_weight": [ "normal", "bold", "light", "ultraLight" ]
            },
            "NanumGothic" : {
                "base_weight" : "normal",
                "font_weight": [ "normal", "bold", "extraBold", "light" ]
            }
        };
    }

    GetFontFamilyList(){
        return this.fontFamilyList;
    };

    IsFontFamily(fontName){
        return Object.keys(this.fontFamilyList).includes(fontName);
    }

    CheckFontFamily(fontName){
        if(this.IsFontFamily(fontName)){
            return fontName;
        }else
            return 'NotoSansKR';
    }

    CheckFontWeight(fontName, fontWeight){
        const font = this.CheckFontFamily(fontName);
        if(this.fontFamilyList[font]['font_weight'].includes(fontWeight)){
            return fontWeight
        }else
            return this.fontFamilyList[font]['base_weight'];
    }

    GetFontWeight(fontName){
        if(this.IsFontFamily(fontName)){
            return this.fontFamilyList[fontName]['font_weight'];
        }else
            return  this.fontFamilyList['NotoSansKR']['font_weight'];
    };
}

export default FontFamily;