import { useState, useEffect } from 'react';

import Markdown from 'components/atom/Markdown'
import {PublishReadDocument} from 'hooks/useDocument';

function ShowDocument({enuSpace, _Modal}){
    const {projectId, pathInfo} = _Modal.data;
    const [MarkdownInfo, setMarkdownInfo] = useState('');

    useEffect(()=>{
        PublishReadDocument(projectId, pathInfo)
            .then((res)=>{
                setMarkdownInfo(res);
            }).catch((err)=>{

            });
    }, [projectId, pathInfo]);

    return(
        <Markdown _markdownInfo={MarkdownInfo} _rootPath={`Repository/publish/${projectId}/`} _pathInfo={pathInfo}/>
    )
}

export default ShowDocument;