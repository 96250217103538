import PropertiesContent from "components/atom/PropertiesContent";
import Checkbox from "components/atom/Checkbox";

export default function ContourGuiList({_data, _disabled, ...props}){
    const {
        grid_visible: NodeGridVisible,
        legend_visible: NodeLegendVisible,
        x_axis_visible: NodeXAxisVisible,
        y_axis_visible: NodeYAxisVisible,
        value_visible: NodeValueVisible,
    } = _data;

    const ChangeVisible = (e) => {
        const attributeName = e.target.name;
        const isVisible = _data[attributeName.replace(/-/g, '_')] === '1';
        props._ChangeEvent([{ attributeName, attributeValue: isVisible ? 'hidden' : 'visible' }]);
    };

    const commonProps = {
        onChange: ChangeVisible,
        _disabled,
    }

    return(
        <PropertiesContent _Title="Gui">
            <div style={{flexWrap: 'wrap'}}>
                <Checkbox _id='grid-visible' checked={NodeGridVisible === '1'} {...commonProps}>grid</Checkbox>
                <Checkbox _id='legend-visible' checked={NodeLegendVisible === '1'} {...commonProps}>legend</Checkbox>
                <Checkbox _id='x-axis-visible' checked={NodeXAxisVisible === '1'} {...commonProps}>x-axis</Checkbox>
                <Checkbox _id='y-axis-visible' checked={NodeYAxisVisible === '1'} {...commonProps}>y-axis</Checkbox>
                <Checkbox _id='value-visible' checked={NodeValueVisible=== '1'} {...commonProps}>value</Checkbox>    
            </div>
        </PropertiesContent>
    )
}