export const SUPPORT_OBJECT_TYPE = {
    CONTOUR: 'contour',
    BOX: 'box',
    CYLINDER: 'cylinder',
    TEXTBOX: 'textbox',
    ASSETTRANSFORMNODE: 'AssetTransformNode',
    CUSTOM: 'custom',
    CAMERA: 'camera',
    LIGHT: 'light',
    MATERIAL: 'material',
    ADT: 'AdvancedDynamicTexture',
    GUI_RECT: 'GUIRectangle',
    GUI_3DMANAGER: 'GUI3DManager',
    GUI_3DBUTTON: 'GUI3DButton'
}

export const SUPPORT_FUNCTION = {
    ONCLICK: '_onclick',
    ONLOAD: '_onload',
    ONMOUSEDOWN: '_onmousedown',
    ONMOUSEMOVE: '_onmousemove',
    ONMOUSEOUT: '_onmouseout',
    ONMOUSEOVER: '_onmouseover',
    ONMOUSEUP: '_onmouseup',
    ONMOUSEWHEEL: '_onmousewheel',
    ONTASKVIEW: '_ontaskview',
    ONKEYDOWN: '_onkeydown',
    ONKEYUP: '_onkeyup',
}

export const SUPPORT_FONT = {
    DROIDSANSBOLD: 'Droid Sans_Bold',
    DROIDSANSREGULAR: 'Droid Sans_Regular',
    KENNEYFUTUREREGULAR: 'Kenney Future Regular'
}