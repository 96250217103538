
import React from "react";
import styled from "styled-components";

function OptionToggleBtn({children, disabled, ...props}){
    return(
        <Content {...props} disabled={disabled}>
            {children}
        </Content>
    )
}

export default OptionToggleBtn;

// 스타일-----------------------------------------------------     background-color: red;
const Content = styled.span`
    position: relative;
    overflow: hidden;
    width: 16px;
    height: 16px;
    user-select: none;
    cursor: ${({disabled})=> disabled ? "default" : "pointer"};

    &:hover{
        svg path{
            fill: ${({theme}) => theme.base.background_color_Blue};
        }
    }
`;