import PropertiesContent from "components/atom/PropertiesContent";
import LabelAndInput from "babylon/components/atom/LabelAndInput";
import useDataController from "babylon/hooks/useDataController";

export default function ContourDataProperties({selectBabylonData, SelectedMesh}) {
    const {
        data, 
        minElevation,
        maxElevation,
        colorElevation
    } = selectBabylonData;

    const {UploadDataByMesh} = useDataController();      

    return (
         
        <PropertiesContent 
            _Title="ContourData"
        >
            {<LabelAndInput 
                id='data'
                label='data'
                inputType='string'
                value={data}
                labelWidth="30%"
                changeEvent={(e)=>{
                    const targetValue = Number(e.target.value);
                    SelectedMesh.data = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}
            {<LabelAndInput 
                id='minElevation'
                label='minElevation'
                inputType='number'
                value={minElevation || '0'}
                labelWidth="50%"
                changeEvent={(e)=>{
                    const targetValue = Number(e.target.value);
                    SelectedMesh.minElevation = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}
            {<LabelAndInput 
                id='maxElevation'
                label='maxElevation'
                inputType='number'
                value={maxElevation || '0'}
                labelWidth="50%"
                changeEvent={(e)=>{
                    const targetValue = Number(e.target.value);
                    SelectedMesh.maxElevation = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}
            {<LabelAndInput 
                id='colorElevation'
                label='colorElevation'
                inputType='string'
                value={JSON.stringify(colorElevation)}
                labelWidth="50%"
                changeEvent={(e)=>{
                    const targetValue = JSON.parse(e.target.value);
                    SelectedMesh.colorElevation = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}
            
        </PropertiesContent>

        
    )
}