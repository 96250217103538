import { useEffect, useRef } from "react";
import { useState } from "react";

import {RadioComponent} from "components/atom/RadioContent";
import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {InputContent} from 'components/css/common'

import styled from "styled-components";
import { MdSend, MdVolumeUp, MdDriveFileMoveOutline, MdOutlineSave, MdFlipToFront, MdOutlineShare } from "react-icons/md";
import {Axios,AccessAxios} from 'components/Axios';
import useRightMenu from "hooks/useRightMenu";
import { useSelector, shallowEqual } from 'react-redux';
import MouseRightClickMenuContent from "components/molecule/MouseRightClickMenuContent";

function ChattingContent({enuSpace, _ChatList, BASE_DATA, NICKNAME, connectUser, setIsShareUser}){
    const [Chat, setChat] = useState('');
    const [ChatType, setChatType] = useState('Local');
    const [ChatList, setChatList] = _ChatList;
    const [File, setFile] = useState(null);
    const messageListRef = useRef();
    const [showTooltip, setShowTooltip] = useState('');
    const {ShowRightMenu, CloseRightMenu} = useRightMenu();

    const RightMenuInfo = useSelector(state => {
        if (state.rightMenu.type === 'ChattingPdf')
            return state.rightMenu;
        return null;
    }, shallowEqual);

    useEffect(()=>{
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }, [ChatList]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setFile(file);
            event.target.value = '';
        } else {
          alert('PDF 파일만 업로드할 수 있습니다.');
        }
    };

    const handleButtonClick = () => {
        document.getElementById('file-input').click();
    };

    const handleDownload = async (fileName, publishId) => {
        console.log(fileName, publishId)
        try {
            const response = await Axios.get('api/Repository/downloadtempfile', {
            params: {
                fileName,
                param : publishId
            },
            responseType: 'blob' // 응답 형식을 blob으로 설정
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const SendMsg = (e)=>{
        if(!File && Chat === '') return;

        let strChatType = `${ChatType}Chat`;
        let ChatData = Chat;
        let SendData = {};
        let ToEngineData = {};
        let ToNickname = '';
        
        
        if(ChatData[0] === '@'){
            strChatType = 'WhisperChat';
            ChatData = ChatData.substring(1, ChatData.length);
            if(ChatData.indexOf(' ') !== -1){
                ToNickname = ChatData.substring(0, ChatData.indexOf(' '));
                ChatData = ChatData.substring(ChatData.indexOf(' ')+1, ChatData.length);
            } else {
                ToNickname = ChatData;
                ChatData = '';
            }
        }

        ToEngineData = {
            Chat:ChatData
        }
        if(ToNickname) ToEngineData.To_Nickname = ToNickname;

        SendData = {
            MEMBER_SYSTEMID : BASE_DATA?.userId ,
            MESSAGE : ChatData,
            TONICKNAME : ToNickname,
            MsgType : `Res_${strChatType}`,
            NICKNAME,
            SENDTIME : new Date(),
            DATA : null,
        }

        // file 첨부 시 
        if(File)
        {
            const formData = new FormData();
            formData.append('file', File);  // 파일 추가
            formData.append('param', BASE_DATA.projectId);  // 프로젝트 id 추가

            AccessAxios.post(`api/Repository/UploadTempFile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },})
            .then(res => {
                if(res.data.result !== 'Success'){
                    setFile(null);
                    setChat('');
                    return;
                }
                SendData.DATA = File.name;
                let FileData = {File:File.name}
                if(ToNickname) FileData.To_Nickname = ToNickname;
                enuSpace.MetaRequest(strChatType, JSON.stringify(FileData));
                setFile(null);
            }).catch(err =>{
                throw err;
            })
        }

        setChat('');
        setChatList((prevChat) => [...prevChat, SendData]);
        if(!ToEngineData.Chat) return;
        enuSpace.MetaRequest(strChatType, JSON.stringify(ToEngineData));
    }

    const MouseRightClick = (e) => {
        e.preventDefault();
    }
    const mouseDownEvent = (event, DATA) => {
        if(event.button === 0){
            // 좌클릭
            handleDownload(DATA, BASE_DATA.projectId)
        } else if (event.button === 2){
            // 우클릭
            ShowRightMenu({'type':'ChattingPdf', 'top':event.clientY, 'left':event.clientX, 'data':{file:DATA}});
        }
        event.stopPropagation();
    };

    const NewWindowOpenPdf = (PdfPath = 'chat/'+RightMenuInfo.data.file) =>{;
        localStorage.setItem('projectId',  BASE_DATA.projectId);
        
        window.open(
            `/Publish/DocumentViewer?path=${PdfPath}&extension=pdf&mode=read`, 
            PdfPath, 
            `width=700, height=900, location=no, status=no, scrollbars=yes, menubar=no`
        );
    }

    const SharedPdf = (e) => setIsShareUser(prev => ({...prev, visibility: true, path: 'chat/'+RightMenuInfo.data.file}));
    return(
        <ChattingSpace onContextMenu={MouseRightClick} onMouseDown={CloseRightMenu}>
            <Header>
                <UserProfileList>
                    {connectUser &&
                        Object.entries(connectUser?.profileList).map((ProfileInfo, index)=>{
                            const [userName, profileUrl] = ProfileInfo;
                            return <li key={index} title={userName}><img src={profileUrl} onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} alt=""/></li>
                        })
                    }
                </UserProfileList>
                <div>
                    {/* <button onClick={(e)=>console.log(ChatList)}>sd</button> */}
                    {/* <button onClick={(e)=>console.log(connectUser.profileList)}>connectUser</button> */}
                    {/* <BtnPrimary label="..." onClick={(e)=>{}}/> */}
                </div>
            </Header>
            <MessageList ref={messageListRef}>
                {ChatList.map((item) => {
                    const {MsgType, SENDTIME, MESSAGE, NICKNAME:SEND_USER_NICKNAME, TONICKNAME, DATA} = item;
                    const dateObject = new Date(SENDTIME);
                    const formattedDate = dateObject.toLocaleString("ko-KR", { timeZone: "Asia/Seoul" });
                    if(!DATA && !MESSAGE) return <></>;
    
                    if(MsgType === "alert"){
                        return (
                            <div className={`reqMsg ${MsgType}`} key={SENDTIME}>
                                <div className="msgInfo">{MESSAGE}</div>
                            </div>
                        )
                    }else if(NICKNAME === SEND_USER_NICKNAME){
                        const tempStyle = MESSAGE ? '1rem' : '0';
                        return (
                            <div className={`reqMsg ${MsgType}`} key={SENDTIME}>
                                {MsgType === 'Res_WorldChat' && <MdVolumeUp className="ic_worldChat" />}
                                <div className="msgBox">
                                    <div>
                                        {
                                            DATA
                                            ? 
                                            <BtnSecondary 
                                                label={
                                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                                        <MdDriveFileMoveOutline style={{ marginRight: '8px' }} />
                                                        {DATA}
                                                    </div>
                                                    } 
                                                style={{height : '100%', marginBottom:tempStyle, borderRadius: '8px 0 8px 8px'}} 
                                                onMouseDown={(e) => mouseDownEvent(e, DATA)}
                                            />
                                            : null
                                        }
                                        {
                                            MESSAGE ? 
                                            (
                                                MsgType === "Res_WhisperChat"
                                                ?<div className="msgInfo">{`(${TONICKNAME}) ${MESSAGE}`}</div>
                                                :<div className="msgInfo">{MESSAGE}</div>
                                            )
                                            : null
                                        }
                                        <span className="time">{formattedDate}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className={`resMsg ${MsgType}`} key={SENDTIME}>
                                <img className="userProfile" src={connectUser?.profileList?.[SEND_USER_NICKNAME]} onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} alt=""/>
                                <div className="msgBox">
                                    <span className="userNickname">{SEND_USER_NICKNAME}{MsgType === 'Res_WorldChat' && <MdVolumeUp className="ic_worldChat" />}</span>
                                    <div>
                                        {
                                            DATA
                                            ? 
                                            <BtnSecondary 
                                                label={
                                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                                        <MdDriveFileMoveOutline style={{ marginRight: '8px' }} />
                                                        {DATA}
                                                    </div>
                                                    } 
                                                style={{height : '100%', borderRadius: '0 8px 8px 8px'}} 
                                                onMouseDown={(e) => mouseDownEvent(e, DATA)}
                                            />
                                            : null
                                        }
                                        {
                                            MESSAGE ? 
                                            (
                                                MsgType === "Res_WhisperChat"
                                                ?<div className="msgInfo">{`(${SEND_USER_NICKNAME}) ${MESSAGE}`}</div>
                                                :<div className="msgInfo">{MESSAGE}</div>
                                            )
                                            : null
                                        }
                                        <span className="time">{formattedDate}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </MessageList>
            <OptionSpace>
                <ChattingOptionRadio
                    attributeName='ChatType'
                    dataList={{Local:'Local', World:'World'}} 
                    selectedValue={ChatType}
                    changeEvent={(name, value)=>setChatType(value)}
                />
            </OptionSpace>
            <InputSpace>
                {
                    File ?
                        <BtnPrimary 
                            label={<MdDriveFileMoveOutline />} 
                            style={{height : '50%',
                                    position: 'absolute',
                                    left: '0.5rem',
                                    padding: '0'
                                }} 
                            onMouseOver={(e)=>{if(!showTooltip)setShowTooltip(File.name)}}
                            onMouseOut={(e)=>{setShowTooltip('')}}
                        /> : null
                }
                {
                    showTooltip ?
                    <div style={{
                        position: 'absolute',
                        backgroundColor:'#E3F2FD',
                        transform: 'translate(0, -2rem)',
                        borderRadius: '4px',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        padding:'0.5rem',
                    }}>
                        {showTooltip}
                    </div>
                    : null
                }
                <InputContent 
                    type="text"
                    name="ChatMsg"
                    placeholder={!File?'Message...':''}
                    value={Chat} 
                    style={{textIndent: File? '2rem' : '0'}}
                    onChange={(e)=>{ if(e.target.value.length < 360) setChat(e.target.value)}} 
                    onKeyDown={(e)=>{if(e.key === 'Enter') SendMsg()}}
                />
                <input
                  id="file-input"
                  type="file"
                  accept="application/pdf"
                  style={{ display: 'none' }}  // input을 숨김
                  onChange={handleFileChange}
                />
                <BtnPrimary 
                    label={<MdDriveFileMoveOutline />} 
                    style={{height : '100%'}} 
                    onClick={handleButtonClick}
                />
                <BtnPrimary 
                    label={<MdSend />} 
                    style={{height : '100%'}} 
                    onClick={SendMsg}
                />
            </InputSpace>
            {RightMenuInfo?.type === 'ChattingPdf' && 
                    <MouseRightClickMenuContent _positionTop={RightMenuInfo['top']} _positionLeft={RightMenuInfo['left']} onMouseDown={(e)=>e.stopPropagation()}>
                        <li onClick={(e)=>handleDownload(RightMenuInfo.data.file, BASE_DATA.projectId)}>
                            <span><MdOutlineSave  style={{ 'color': 'steelblue' }}/>Save Pdf</span>
                        </li>
                        <li onClick={(e)=>NewWindowOpenPdf()}>
                            <span><MdFlipToFront style={{ 'color': 'steelblue' }}/>Open Pdf</span>
                        </li>
                        <li onClick={SharedPdf}>
                            <span><MdOutlineShare style={{'color': 'royalblue' }}/>Share Pdf</span>
                        </li>
                    </MouseRightClickMenuContent>
                }
        </ChattingSpace>
    )
}

export default ChattingContent;

const CommonSpace = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
`;

const ChattingSpace = styled(CommonSpace)`
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid ${({theme}) => theme.base.border_color_Gray};
`;

const Header = styled(CommonSpace)`
    height: 50px;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_DarkGray};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 8px;
    box-sizing: border-box;
`;

const UserProfileList = styled.ul`
    position: relative;
    width: 100%;
    height: 36px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 6px;
    overflow: hidden;

    li{
        position: relative;
        width: 36px;
        height: 100%;
        border: 1px solid ${({theme}) => theme.base.border_color_Gray};
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        box-sizing: border-box;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

const MessageList = styled(CommonSpace)`
    height: calc(100% - 138px);
    overflow-y: auto;
    padding: 8px;

    &>div{
        position: relative;
        margin-bottom: 16px;
        width: 100%;
    }

    .userProfile{
        border-width: 1px;
        border-style: solid;
        border-color: ${({theme}) => theme.base.border_color_Gray};
        border-radius: 50%;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        box-sizing: border-box;
        overflow: hidden;
        object-fit: cover;
        background-color: #fff;
    }

    .msgBox{
        position: relative;
        max-width: calc(100% - 40px);
        box-sizing: border-box;

        &>div{
            margin-top: 8px;
        }
    }
    
    .userNickname{
        position: relative;
        font-size: .9rem;
        font-weight: bold;
        color: ${({theme}) => theme.base.font_color_DarkGray};

        &>.ic_worldChat{
            position: absolute;
            top: 2px;
            right: -24px;
        }
    }

    .time {
        font-size: .8rem;
        color: ${({theme}) => theme.base.font_color_DarkGray};
        opacity: 1;
    }

    .msgInfo{
        font-size: 1rem;
        line-height: 1.3;
        padding: 8px;
        box-sizing: border-box;
        word-break: break-all;
        color: ${({theme}) => theme.base.font_color_Black};
        
    }

    .resMsg{
        display: flex;

        >.msgBox{
            padding-right: 16px;
        }
        
        .msgInfo{
            border-radius: 0 8px 8px 8px;
            background-color: ${({theme}) => theme.base.background_color_LightBlue};
            border: 1px solid ${({theme}) => theme.base.border_color_Blue};
        }
    }

    .reqMsg{
        display: flex;
        flex-direction: reverse;
        justify-content: end;
        
        .msgBox > div{
            margin-top: 0;
        }

        .msgInfo{
            border-radius: 8px 0 8px 8px;
            background-color: ${({theme}) => theme.base.background_color_Blue};
            color: #fff;
        }
    }

    .ic_worldChat{
            font-size: 1.2rem;
            color: ${({ theme }) => theme.base.font_color_Orange};
    }

    .reqMsg.alert{
        display: flex;
        justify-content: center;
        align-items: center;

        &>.msgInfo{
            font-size: .9rem;
            color: ${({theme}) => theme.base.font_color_Black};
            background-color: transparent;
        }
    }

    .reqMsg.Res_WorldChat{
        &>.ic_worldChat{
            margin: 10px 4px
        }
    }
    
    .resMsg.Res_WorldChat{
        img{
            outline: 2px solid ${({theme}) => theme.base.font_color_Orange};
        }
    }

    .resMsg.Res_WhisperChat{
        .msgInfo{
            border: 2px dashed ${({theme}) => theme.base.border_color_Blue};
        }
    }
`;

const OptionSpace = styled(CommonSpace)`
    border-top: 1px solid ${({theme}) => theme.base.border_color_Gray};
    background-color: ${({theme}) => theme.base.background_color_PaleGray};

    &>div{
        padding: 8px 16px;

        input{
            &:checked + label{
                color: ${({theme}) => theme.base.font_color_DarkBlue};
                background-color: #fff;
            }
            &:not(:checked) + label{
                color: ${({theme}) => theme.base.font_color_Gray};
                background-color: ${({theme}) => theme.base.background_color_PaleGray};
            }
        }
    }
`;

const InputSpace = styled(CommonSpace)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 8px;
    
    background-color: ${({theme}) => theme.base.background_color_PaleGray};

    >button{
        margin-left: 8px;
    }
`;

const ChattingOptionRadio = styled(RadioComponent)`
    padding: 0!important;
`;