
import { useId } from "react";

import styled from "styled-components";

// TODO: RadioContent 사용시 주의 필요 Node 속성 관련 처리는 RadioComponent 사용
const Reader = (_radioName, _listData, _selectedValue, _ChangeEvent, _disabled, _titleList, _labelVisibleType) => {
    return (
        Object.entries(_listData).map(([key, value]) => {
            return (
                <div key={key}>
                    <input 
                        id={`${key}_${_radioName}`} 
                        type='radio' 
                        name={_radioName} 
                        value={value} 
                        onChange={(e) => _ChangeEvent(key, value)} 
                        checked={_selectedValue === value} 
                        disabled={_disabled}
                    />
                    <label 
                        htmlFor={`${key}_${_radioName}`} 
                        className={_disabled ? 'disabled' : null} 
                        title={_titleList && _titleList[key]}
                    >
                        {_labelVisibleType === 'Key' ? key : value}
                    </label>
                </div>
            )
        })
    )
}

function RadioContent({_radioName, _listData, _selectedValue, _ChangeEvent, _disabled, _titleList, _labelVisibleType='value', _height = '22px', ...props}){
    return(
        <BasicType _height={_height} {...props}>
            {_listData && Reader(_radioName, _listData, _selectedValue, _ChangeEvent, _disabled, _titleList, _labelVisibleType)}
        </BasicType>
    )
}

function RepositoryRadioContent({_radioName, _listData, _selectedValue, _ChangeEvent, _disabled, _titleList, _labelVisibleType='value', _height = '22px' , ...props}){
    return(
        <RepositoryType _height={_height} {...props}>
            {_listData && Reader(_radioName, _listData, _selectedValue, _ChangeEvent, _disabled, _titleList, _labelVisibleType)}
        </RepositoryType>
    )
}

function TabRadioContent({_radioName, _listData, _selectedValue, _ChangeEvent, _disabled, _titleList, _labelVisibleType='value',  _height = '22px' , ...props}){
    return(
        <TabType _height={_height} {...props}>
            {_listData && Reader(_radioName, _listData, _selectedValue, _ChangeEvent, _disabled, _titleList, _labelVisibleType)}
        </TabType>
    )
}

/////////////////////////////////////////////////////////////////////////// 추가된 코드

const RenderArray = (list) =>{
    // TODO: array 형태가 필요시 추가
}

const RenderMap = (radioId, height, attributeName, dataList, titleList, selectedValue, changeEvent, isUseKeyLabel, disabled) =>{
    return Object.entries(dataList).map(([key, value]) => {
        const id = `${radioId}_${key}`;
        
        return (
            <div
                key={id}
                height={height}
            >
                <input 
                    id={id} 
                    type='radio' 
                    name={attributeName} 
                    value={key} 
                    checked={selectedValue === (isUseKeyLabel ? value : key)} 
                    disabled={disabled}
                    onChange={() => changeEvent(attributeName, isUseKeyLabel ? value : key)}
                />
                <label 
                    htmlFor={id} 
                    className={disabled ? 'disabled' : null}
                    title={titleList ? titleList[key] : attributeName}
                >
                    {isUseKeyLabel ? key : value}
                </label>
            </div>
        )
    })
}

function RadioComponent({attributeName, dataList, titleList, selectedValue, height='26px', isUseKeyLabel=false ,changeEvent, disabled, ...props}){
    const radioId = useId();

    return(
        <AttributeRadio height={height} disabled={disabled} {...props}>
            {dataList &&
                Array.isArray(dataList)
                    ? RenderArray(radioId, height, attributeName, dataList, titleList, selectedValue, changeEvent, isUseKeyLabel, disabled)
                    : RenderMap(radioId, height, attributeName, dataList, titleList, selectedValue, changeEvent, isUseKeyLabel, disabled)
            }
        </AttributeRadio>
    )
}

function HighlightedRadio({attributeName, dataList, titleList, selectedValue, height='26px', isUseKeyLabel=false, changeEvent, disabled, ...props}){
    const radioId = useId();

    return(
        <HighlightedRadioStyle height={height} disabled={disabled} {...props}>
            {dataList &&
                Array.isArray(dataList)
                    ? RenderArray(radioId, height, attributeName, dataList, titleList, selectedValue, changeEvent, isUseKeyLabel, disabled)
                    : RenderMap(radioId, height, attributeName, dataList, titleList, selectedValue, changeEvent, isUseKeyLabel, disabled)
            }
        </HighlightedRadioStyle>
    )
}

function RepositoryRadio({attributeName, dataList, titleList, selectedValue, height='38px', isUseKeyLabel=false, changeEvent, disabled, ...props}){
    const radioId = useId();

    return(
        <RepositoryRadioStyle height={height} disabled={disabled} {...props}>
            {dataList &&
                Array.isArray(dataList)
                    ? RenderArray(radioId, height, attributeName, dataList, titleList, selectedValue, changeEvent, isUseKeyLabel, disabled)
                    : RenderMap(radioId, height, attributeName, dataList, titleList, selectedValue, changeEvent, isUseKeyLabel, disabled)
            }
        </RepositoryRadioStyle>
    )
}

export {RepositoryRadioContent, TabRadioContent, RadioComponent, HighlightedRadio, RepositoryRadio};
export default RadioContent;


// 스타일-----------------------------------------------------
const RadioBaseStyle = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: ${({ height }) => height};
    box-sizing: border-box;
    user-select: none;
    border-radius: 6px;

    &>div{
        width: 100%;
        height: 100%;

        &>input{
            display: none;

            &:checked + label{
                &:hover{
                    border-width: 1px;
                    border-style: solid;
                }

                &.disabled{
                    opacity: 1;
                }
            }
            
            &:not(:checked) + label{
                opacity: .6;

                &:hover{
                    border-width: 1px;
                    border-style: solid;
                    opacity: 1;
                }

                &.disabled{
                    opacity: .3;
                    
                    &:hover{
                        opacity: .3;
                    }
                }
            }
        }

        &>label{
            flex-grow: 1;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            border-radius: 4px;

            &>svg{
                font-size: 1rem;
            }

            &.disabled{
                cursor: default;
            }
        }
    }
`;

const AttributeRadio = styled(RadioBaseStyle)`
    background-color: ${({ disabled, theme }) => disabled ? "transparent" : theme.base.background_color_Gray};
    border: 3px solid ${({ disabled, theme }) => disabled ? "transparent" : theme.base.background_color_Gray};

    &>div{
        &>input{
            &:checked + label{
                background-color: #fff;
                
                &:hover{
                    background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                    border-color: ${({ theme }) => theme.base.background_color_Blue};
                }

                &.disabled{
                    background-color: #fff;
                    border-color: transparent;
                }
            }
            
            &:not(:checked) + label{
                border-color: transparent;

                &:hover{
                    background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                    border-color: ${({ theme }) => theme.base.background_color_Blue};
                }

                &.disabled{
                    background-color: transparent;
                    border-color: transparent;
                    color: ${({ theme }) => theme.base.font_color_Gray};
                    
                    &:hover{
                        background-color: transparent;
                    }
                }
            }
        }
    }
`;

const HighlightedRadioStyle = styled(RadioBaseStyle)`
    background-color: ${({ disabled, theme }) => disabled ? "transparent" : theme.base.background_color_Blue};
    border: 3px solid ${({ disabled, theme }) => disabled ? "transparent" : theme.base.background_color_Blue};

    &>div{
        &>input{
            &:checked + label{
                background-color: #fff;
                color: ${({ theme }) => theme.base.font_color_DarkBlue};
                
                &:hover{
                    background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                    border-color: ${({ theme }) => theme.base.background_color_Blue};
                }

                &.disabled{
                    background-color: #fff;
                    border-color: transparent;
                }
            }
            
            &:not(:checked) + label{
                border-color: transparent;

                &:hover{
                    background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                    border-color: ${({ theme }) => theme.base.background_color_Blue};
                }

                &.disabled{
                    background-color: transparent;
                    border-color: transparent;
                    color: ${({ theme }) => theme.base.font_color_Gray};
                    
                    &:hover{
                        background-color: transparent;
                    }
                }
            }
        }
    }
`;

const RepositoryRadioStyle = styled(RadioBaseStyle)`
    width: auto;
    border-radius: 8px;
    background-color: ${({ disabled, theme }) => disabled ? "transparent" : theme.base.background_color_Gray};
    border: 3px solid ${({ disabled, theme }) => disabled ? "transparent" : theme.base.background_color_Gray};

    &>div{
        label{
            border-radius: 6px;
        }

        &>input{

            &:checked + label{
                background-color: #fff;
                color: ${({ theme }) => theme.base.font_color_DarkBlue};
                
                &:hover{
                    background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                    border-color: ${({ theme }) => theme.base.background_color_Blue};
                }

                &.disabled{
                    background-color: #fff;
                    border-color: transparent;
                }
            }
            
            &:not(:checked) + label{
                border-color: transparent;

                &:hover{
                    background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                    border-color: ${({ theme }) => theme.base.background_color_Blue};
                }

                &.disabled{
                    background-color: transparent;
                    border-color: transparent;
                    color: ${({ theme }) => theme.base.font_color_Gray};
                    
                    &:hover{
                        background-color: transparent;
                    }
                }
            }
        }
    }
`;

const Radio = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    flex-grow: 1;
    height: ${({ _height }) => _height};
    user-select: none;

    &>div{
        width: 100%;
        height: 100%;

        &>input{
            display: none;
    
            &:checked + label{
                &.disabled{
                    background-color: transparent;
                    border-color: transparent;
                    &:hover{
                        background-color: transparent;
                    }
                }
            }

            &:disabled:not(:checked) + label{
                border-color: transparent;
                opacity: .3;
            }
        }

        &>label{
            border-width: 1px;
            border-style: solid;
            flex-grow: 1;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;

            &>svg{
                font-size: 1rem;
            }

            &.disabled{
                cursor: default;
            }
        }
    }
`;

const BasicType = styled(Radio)`
    &>div{
        &>input{
            &:checked + label{
                background-color: ${({ theme }) => theme.base.background_color_PaleGray};
                &:hover{
                    background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                }
            }
        }
        &>label{
            border-color: ${({theme}) => theme.base.border_color_LightGray};
            background-color: #fff;
            &:hover{
                background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                border-color: ${({ theme }) => theme.base.background_color_Blue};
            }
            &.disabled{
                &:hover{
                    background-color: #FFF;
                    border-color: ${({ theme }) => theme.base.border_color_LightGray};
                }
            }
        }
    }
`;

const RepositoryType = styled(Radio)`
    &>div{
        height: 32px;
        &>input{
            &:checked + label{
                color: #FFF;
                background-color: ${({ theme }) => theme.base.border_color_Blue};
                &:hover{
                    background-color: ${({ theme }) => theme.base.background_color_DarkBlue};
                }
            }
        }
        &>label{
            font-size: 1rem;
            color: ${({theme}) => theme.base.font_color_DarkBlue};
            border-color: ${({theme}) => theme.base.border_color_Blue};
            &:hover{
                background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                border-color: ${({ theme }) => theme.base.background_color_Blue};
            }
            &.disabled{
                &:hover{
                    background-color: #FFF;
                    border-color: ${({ theme }) => theme.base.border_color_LightGray};
                }
            }
        }
    }
`;

const TabType = styled(Radio)`
    padding: 8px;
    background-color:  ${({ theme }) => theme.base.background_color_LightGray};
    height: 32px;
    border-radius: 4px;
    gap: 8px;

    &>div{
        &:first-child >input + label, &:last-of-type >input + label {
            border-radius:  4px;
        }

        &>input{
            &:checked + label{
                color: ${({theme}) => theme.base.font_color_Black};
                background-color: #fff;
                box-shadow: rgba(0, 0, 0, .15) 0px 1px 2px;
                &:hover{
                    border-color: ${({ theme }) => theme.base.background_color_Blue};
                }
            }
        }
        &>label{
            font-size: 1rem;
            border-radius: 4px;
            border-color: transparent;
            color: ${({theme}) => theme.base.font_color_DarkGray};

            &:hover{
                background-color: #fff;
            }
            &.disabled{
                &:hover{
                }
            }
        }
    }
`;