import React, {useState, useEffect, useCallback} from "react"

import NewProjectFormModal from 'components/modal_new/NewProjectFormModal'
import {BtnGhost, BtnSecondary} from "components/atom/Button";
import { RepositoryCard} from 'components/molecule/ProjectCard'
import {RepositoryRadio} from 'components/atom/RadioContent'
import TemplateContent from 'components/organism/TemplateContent'
import { BgPopup, ContentPage, ContentSectionHeader, ContentSection, NewContentBox} from 'components/css/common'
import Modal from 'components/modal_new/Modal'
import {GetMyProjectList, GetMyPublishList, DeleteProject, DeletePublish} from 'api/RepositoryApi'
import { PUBLISH_TYPE } from "components/common/EnuSpaceEnum";

import styled from "styled-components"
import { useNavigate } from 'react-router-dom';
import {  MdInsertLink,} from "react-icons/md";

export default function Repository(){
    const navigate = useNavigate();
    const [projectList, setProjectList] = useState([]);
    const [projectListType, setProjectListType] = useState('Edit');

    const [NewProjectMode, setNewProjectMode] = useState();
    const [TemplateVisibility, setTemplateVisibility] = useState(false);

    const systemId = localStorage.getItem('systemId');
    const nickname = localStorage.getItem('nickname');

    const projectListLoad = useCallback(() => {
        GetMyProjectList(systemId)
            .then(res => {
                const {result, data} = res.data;
                if(result=== 'Success')
                    setProjectList(data);
            })
            .catch(err => {
                window.AddAlertMsg({type:'error', msg:`프로젝트 리스트 로딩에 실패했습니다. : ${err}`});
            });
    }, [systemId]);

    const myPublishListLoad = () => {
        GetMyPublishList(systemId)
            .then(res => {
                const {result, data} = res.data;
                if(result === 'Success')
                    setProjectList(data);
            })
            .catch(err => {
                window.AddAlertMsg({type:'error', msg:`프로젝트 리스트 로딩에 실패했습니다. : ${err}`});
            });
    };
    
    useEffect(()=>{
        projectListLoad();
    }, [projectListLoad]);

    const ChangeSelect = (name, listType) => {
        setProjectListType(listType);
        setProjectList();

        setTimeout(() => {
            listType === 'Edit' ? projectListLoad() : myPublishListLoad();
        }, 100);
    }

    const RenderEditCard = (project) =>{
        const {PROJECT_SYSTEMID, NICKNAME, PROFILE_SYSTEMID, PROJECT_TITLE, MEMBER_SYSTEMID, LASTREVISE_STAMP, RENDERING, PROJECT_NAME} = project;
        const thumbnailImgSrc = `Repository\\${MEMBER_SYSTEMID}\\${PROJECT_SYSTEMID}\\thumbnail\\project_thumbnail.jpeg`
        const data = `${LASTREVISE_STAMP.substring(0, LASTREVISE_STAMP.indexOf('T'))} ${LASTREVISE_STAMP.substring(LASTREVISE_STAMP.indexOf('T') + 1)}`;

        const DeleteProjectEvent = (e) => {
            if (window.confirm(`"${PROJECT_TITLE}" 을 삭제하시겠습니까?\n(publish 데이터도 같이 삭제됩니다.)`)) {
                DeleteProject(MEMBER_SYSTEMID, PROJECT_SYSTEMID)
                    .then(res => {
                        projectListLoad();
                    }).catch(err => {
                        window.AddAlertMsg({ type: 'error', msg: err });
                    });
            }
        }
        
        const RenderingType = RENDERING === '3D' ? '3D' : '';
        const StartEnuspace = (_modeType) => {
            const mode = _modeType === 'edit' ? 'editMode' : 'runtimeMode';
            const URL = `/MyRepository/${mode}${RenderingType}`;
    
            navigate(URL, {
                state: {
                    userId: systemId,
                    userNickname: nickname,
                    projectId: PROJECT_SYSTEMID,
                    projectName: PROJECT_NAME,
                    isMetaverse: 0,
                }
            });
        }
    
        return(
            <RepositoryCard
                key={PROJECT_SYSTEMID}
                RenderingType={RenderingType}
                ProfileId={PROFILE_SYSTEMID}
                ProjectName={PROJECT_TITLE}
                ThumbnailSrc={thumbnailImgSrc}
                Creator={NICKNAME}
                Date={data}
                EventOption={(e)=> setNewProjectMode(project)}
                EventDelete={DeleteProjectEvent}
                Buttons={
                    <>
                        <ButtonContent type='sm' title='Runtime mode' label='Run' onClick={(e)=>StartEnuspace('run')}/>
                        <ButtonContent type='sm' title='Edit mode' label='Edit' onClick={(e)=>StartEnuspace('edit')} />
                    </>
                }
            />
        )
    }

    const RenderPublishCard = (project) =>{
        const { MEMBER_SYSTEMID, PROJECT_SYSTEMID, PROJECT_TITLE, NICKNAME, PUBLISH_STAMP, PUBLISH_SYSTEMID, PROJECT_NAME, USE_METAVERSE, PROFILE_SYSTEMID, PUBLISH_MODE} = project;
        const thumbnailImgSrc = `Repository\\${MEMBER_SYSTEMID}\\${PROJECT_SYSTEMID}\\thumbnail\\project_thumbnail.jpeg`
        const publishType = Object.keys(PUBLISH_TYPE).find(key => PUBLISH_TYPE[key] === PUBLISH_MODE);

        const DeleteProjectEvent = (e) =>{
            if(window.confirm(`"${PROJECT_TITLE}" publish 데이터를 삭제하시겠습니까?`)){
                DeletePublish(PUBLISH_SYSTEMID)
                    .then(res=>{
                        myPublishListLoad();
                    }).catch(err=>{
                        window.AddAlertMsg({ type: 'error', msg: err });
                    });
            }
        }

        const StartEnuspace = (e) => {
            localStorage.setItem('publishId', PUBLISH_SYSTEMID); 
            localStorage.removeItem('projectId', ); 
    
            navigate('/Publish/runtimeMode', {
                state: {
                    userId: systemId,
                    userNickname: nickname,
                    projectId: PUBLISH_SYSTEMID,
                    projectName: PROJECT_NAME,
                    isMetaverse: USE_METAVERSE,
                }
            });
        }

        return(
            <RepositoryCard
                key={PROJECT_SYSTEMID}
                PublishType={publishType}
                ProfileId={PROFILE_SYSTEMID}
                ProjectName={PROJECT_NAME}
                ThumbnailSrc={thumbnailImgSrc}
                Creator={NICKNAME}
                Date={PUBLISH_STAMP}
                EventDelete={DeleteProjectEvent}
                Buttons={
                    <>
                        <ButtonContent type='sm' label='Run' title='Runtime mode' onClick={StartEnuspace}/>
                        <ButtonContent 
                            className='btn_link' 
                            type='sm' 
                            title='link'
                            label={<MdInsertLink />} 
                            onClick={(e)=>{
                                navigator.clipboard.writeText(`https://${window.location.host}/Publish/link?${PUBLISH_SYSTEMID}&${PROJECT_NAME}`);
                                window.AddToastMsg(`link Copy Success`);
                            }} 
                        />
                    </>
                }
            />
        )
    }


    return (
        <RepositoryContent>
            {NewProjectMode && <NewProjectFormModal userId={systemId} processType={NewProjectMode} reLoadEvent={projectListLoad} closeEvent={(e)=>setNewProjectMode()} />}

            {TemplateVisibility &&
                <TemplateSpace>
                    <CustomBgPopup onClick={(e)=>setTemplateVisibility(false)}/>
                    <TemplateModal>
                        <Modal
                            _id='TemplateModal'
                            _width={1040}
                            _height={800}
                            _minWidth={530}
                            _minHeight={680}
                            _x={document.getElementById('root').offsetWidth / 2 - 520} 
                            _y={document.getElementById('root').offsetHeight/2 - 430} 
                            _title='Template' 
                            // _enableResizing={false}
                            _closeModal={(e)=>setTemplateVisibility(false)}
                        >
                            <TemplateContent />
                        </Modal>
                    </TemplateModal>
                </TemplateSpace>
            }

            <ContentPage>
                <ContentSectionHeader>
                    <RepositoryRadio 
                        attributeName='listType'
                        dataList={{ Edit: 'Edit', Publish: 'Publish' }}
                        titleList={{ Edit: 'Edit', Publish: 'Publish' }}
                        selectedValue={projectListType}
                        changeEvent={ChangeSelect}
                    />
                </ContentSectionHeader>
                {
                    projectListType === 'Edit'
                        ?
                        <ContentSection>
                            <NewContentBox>
                                <BtnGhost label="Templates" onClick={(e)=> setTemplateVisibility(true)} />
                                <BtnGhost label="New Project" onClick={(e)=>setNewProjectMode('2D')} />
                                <BtnGhost label="New 3D Project" onClick={(e) => setNewProjectMode('3D')}/>
                            </NewContentBox>

                            {projectList &&
                                projectList.map((project) => RenderEditCard(project) )
                            }
                        </ContentSection>
                        : <ContentSection>
                            {projectList &&
                                projectList.map((project) =>RenderPublishCard(project))
                            }
                        </ContentSection>
                }

            </ContentPage>
        </RepositoryContent>
    );
}


// 스타일-------------------------------
const RepositoryContent = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 72px);
    overflow: auto;
`;

const TemplateModal = styled.div`
    #TemplateModal{
        &>div > div{
            padding: 0;

            &>div{
                margin-top: 0;
            }
        }
    }
`;

const TemplateSpace = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index : 12;
    overflow: hidden;
`;

const CustomBgPopup = styled(BgPopup)`
    top: 72px;
`;

const ButtonContent = styled(BtnSecondary)`
    padding: 4px 8px !important;
    height: 30px !important;
    min-width: 0;

    &.btn_link{
        padding: 0  4px!important;
        &:hover{
            border-color: ${({theme}) => theme.base.border_color_Blue};
            background-color: ${({theme}) => theme.base.background_color_LightBlue};
        }
        svg{
            font-size: 1.3rem;
            color:  ${({theme}) => theme.base.border_color_DarkBlue};
        }
    }
`;
