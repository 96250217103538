import { useEffect, useCallback, useState } from 'react';

import {BtnGhost, BtnPrimary, BtnSecondary, BtnSubDanger} from "components/atom/Button";
import {
    GetFriendList, 
    GetGroupListWithMember, 
    CreateGroup, 
    RemoveGroupMember, 
    UpdateGroup,
    DeleteGroup,
} from 'api/SnsApi';
import { ModalBottomBox, BgPopup, WarningMsg, Popup, SubTitle, InputContent, GroupName, TableContent, ChartTitle} from 'components/css/common';

import styled from "styled-components";
import { MdRefresh, MdClear, MdGroupAdd, MdStar, MdMoreHoriz} from "react-icons/md";

export default function GroupListContent(){
    const systemId = localStorage.getItem('systemId');
    
    const [Msg, setMsg] = useState('');
    const [GroupList, setGroupList] = useState([]);
    const [AddGroupModalInfo, setAddGroupModalInfo] = useState({
        visibility: false,
        Mode: 'New',
        GROUP_NAME: '',
        DESCRIPTION: '',
        FRIENDS_EMAIL: [],
        FRIENDS_INFO: {},
    });

    const [FriendList, setFriendList] = useState([]);
    const GetGroups = useCallback(() => {
        GetGroupListWithMember(systemId)
            .then(res => {
                const { result, msg, data } = res.data;
                if (result === 'Success') {
                    setGroupList(data);
                } else {
                    window.AddAlertMsg({ type: 'error', msg });
                }
            }).catch(err => {
                window.AddAlertMsg({ type: 'error', msg: err });
            });
    }, [systemId]);

    const GetFriends = () => {
        GetFriendList(systemId)
            .then(res => {
                const { result, msg, data } = res.data;
                if (result === 'Success') {
                    setFriendList(data);
                } else {
                    window.AddAlertMsg({ type: 'error', msg });
                }
            }).catch(err => {
                window.AddAlertMsg({ type: 'error', msg: err });
            });
    };

    useEffect(() => {
        GetGroups();
    }, [GetGroups]);

    const DeleteFriendInGroupHandler = ( GroupName, GroupId ,TargetEMAIL) => {
        if(!window.confirm(`"${GroupName}" 그룹에서 "${TargetEMAIL}" 님을 삭제하시겠습니까?`)) return;

        RemoveGroupMember(GroupId, [TargetEMAIL])
            .then(res => {
                const {result} = res.data;
                if(result === 'Success'){
                    GetGroups();
                }
            })
            .catch(err => {
                window.AddAlertMsg({type:'error', msg:err});
            })
    };

    const ChangeGroupInputData = (e) => {
        setMsg('');
        const { name, value } = e.target;
        setAddGroupModalInfo(prev => ({ ...prev, [name]: value }));
    }

    const ToggleFriendEmail = (name, email) => {
        setAddGroupModalInfo(prev => {
            const isCheck = prev.FRIENDS_EMAIL.includes(email);
            const updatedEmails = isCheck
                ? prev.FRIENDS_EMAIL.filter(friendEmail => friendEmail !== email)
                : [...prev.FRIENDS_EMAIL, email];

            const updatedFriendsInfo = { ...prev.FRIENDS_INFO };
            isCheck
                ? delete updatedFriendsInfo[email]
                : updatedFriendsInfo[email] = name;

            return {
                ...prev,
                FRIENDS_EMAIL: updatedEmails,
                FRIENDS_INFO: updatedFriendsInfo
            };
        });
    };

    const CreateGroupHandler = (e) => {
        const {GROUP_NAME, DESCRIPTION, FRIENDS_EMAIL} = AddGroupModalInfo;
        if(GROUP_NAME === ''){
            setMsg('Group Name을 입력해주세요.');
            return;
        }
        CreateGroup(systemId, GROUP_NAME, DESCRIPTION, FRIENDS_EMAIL)
            .then(res => {
                const {result, msg} = res.data;
                if(result === 'Success'){
                    setAddGroupModalInfo(prev => ({ ...prev, visibility: false }));
                    GetGroups();
                    window.AddToastMsg(msg);
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    const UpdateGroupHandler = (e) => {
        const {GROUP_NAME, DESCRIPTION, FRIENDS_EMAIL, GROUP_SYSTEMID, OriginData} = AddGroupModalInfo;
        const { FRIENDS_EMAIL: OriginFriendsEmail} = OriginData;
        
        if(GROUP_NAME === ''){
            setMsg('Group Name을 입력해주세요.');
            return;
        }

        const ADD_MEMBER_EMAIL = FRIENDS_EMAIL.filter(item => !OriginFriendsEmail.includes(item));
        const REMOVE_MEMBER_EMAIL = OriginFriendsEmail.filter(item => !FRIENDS_EMAIL.includes(item));

        UpdateGroup(systemId, GROUP_SYSTEMID, GROUP_NAME, DESCRIPTION, ADD_MEMBER_EMAIL, REMOVE_MEMBER_EMAIL)
            .then(res => {
                const {result, msg} = res.data;
                if(result === 'Success'){
                    setAddGroupModalInfo(prev => ({ ...prev, visibility: false }));
                    GetGroups();
                    window.AddToastMsg(msg);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const DeleteGroupHandler = (e) => {
        const {GROUP_SYSTEMID, GROUP_NAME} = AddGroupModalInfo;
        if(!window.confirm(`"${GROUP_NAME}" 그룹을 삭제하시겠습니까?`)) return;

        DeleteGroup(systemId, GROUP_SYSTEMID)
            .then(res => {
                const {result, msg} = res.data;
                if(result === 'Success'){
                    setAddGroupModalInfo(prev => ({ ...prev, visibility: false }));
                    GetGroups();
                    window.AddToastMsg(msg);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <>
            <Header>
                <SubTitle className='title'>{GroupList?.length || 0}개</SubTitle>

                <div>
                    <BtnGhost 
                        label={<MdRefresh />}
                        title='Reload' 
                        onClick={(e) => GetGroups()} 
                    />
                    <BtnGhost 
                        label={<MdGroupAdd />}
                        title='Add Group'
                        onClick={(e) => {
                            setMsg('');
                            GetFriends();
                            setAddGroupModalInfo(prev => ({ ...prev, visibility: true, Mode: 'New', GROUP_NAME: '', DESCRIPTION: '', FRIENDS_EMAIL: [], FRIENDS_INFO: {} }))
                        }} 
                    />
                </div>
            </Header>

            <MemberList>
                {GroupList?.map((GroupInfo) => {
                    const { GROUP_SYSTEMID, GROUP_NAME, GROUPMEMBERLIST, DESCRIPTION } = GroupInfo;
                    return (
                        <GroupCard key={GROUP_SYSTEMID}>
                            <div className="GroupInfo">
                                <h4>{GROUP_NAME} ({GROUPMEMBERLIST.length || 0})</h4>
                                <div>
                                    <MdMoreHoriz className="EditBtn"
                                        onClick={(e) => {
                                            let FRIENDS_EMAIL = [];
                                            let FRIENDS_INFO = {};
                                            GROUPMEMBERLIST.forEach((MemberInfo) => {
                                                const { NICKNAME, EMAIL } = MemberInfo;
                                                FRIENDS_EMAIL.push(EMAIL);
                                                FRIENDS_INFO[EMAIL] = NICKNAME;
                                            });

                                            setMsg('');
                                            GetFriends();
                                            setAddGroupModalInfo({ visibility: true, Mode: 'Edit', GROUP_NAME, DESCRIPTION, FRIENDS_EMAIL, FRIENDS_INFO, GROUP_SYSTEMID, OriginData: { FRIENDS_EMAIL } });
                                        }}
                                    />
                                </div>
                            </div>
                            <ul>
                                {GROUPMEMBERLIST?.map((MemberInfo) => {
                                    const { NICKNAME, EMAIL, PROFILE_SYSTEMID } = MemberInfo;
                                    return (
                                        <FriendCard key={PROFILE_SYSTEMID}>
                                            <img src={`/profile/${PROFILE_SYSTEMID}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                                            <div>
                                                <h4>{NICKNAME}</h4>
                                                <p>{EMAIL}</p>
                                                <MdClear className="DeleteBtn" 
                                                    onClick={(e) => DeleteFriendInGroupHandler(GROUP_NAME, GROUP_SYSTEMID, EMAIL)}
                                                />
                                            </div>
                                        </FriendCard>
                                    );
                                })}
                            </ul>
                        </GroupCard>
                    )
                })}
            </MemberList>

            {AddGroupModalInfo.visibility &&
                <ModalContent width={'560px'}>
                    <BgPopup onClick={(e) => setAddGroupModalInfo(prev => ({ ...prev, visibility: false }))} />
                    <div className="PopupWrap">
                        <div className="InputList">
                            <div>
                                <GroupName width={120}><MdStar className="required" />Group Name</GroupName>
                                <InputContent name="GROUP_NAME" value={AddGroupModalInfo.GROUP_NAME} placeholder="Group Name" onChange={ChangeGroupInputData} />
                            </div>
                            <div>
                                <GroupName width={120}>Description</GroupName>
                                <InputContent name="DESCRIPTION" value={AddGroupModalInfo.DESCRIPTION} placeholder="Description" onChange={ChangeGroupInputData} />
                            </div>
                            <div className="TableSpace">
                                <div>
                                    <ChartTitle className='title'>Friend List</ChartTitle>
                                    <MemberListTable>
                                        <div>
                                            <table>
                                                <tbody>
                                                    {FriendList?.map((FriendInfo, index) => {
                                                        const { PROFILE_SYSTEMID, NICKNAME, EMAIL } = FriendInfo;
                                                        const NameAndEmail = `${NICKNAME} (${EMAIL})`;
                                                        const isChecked = AddGroupModalInfo.FRIENDS_EMAIL.includes(EMAIL);

                                                        if (isChecked) return null;
                                                        return (
                                                            <tr key={PROFILE_SYSTEMID} onClick={e => ToggleFriendEmail(NICKNAME, EMAIL)}>
                                                                <td>{NameAndEmail}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </MemberListTable>
                                </div>
                                <div>
                                    <ChartTitle className='title'>{`Selected Friend _ ${AddGroupModalInfo.FRIENDS_EMAIL.length || 0} 명`}</ChartTitle>
                                    <MemberListTable>
                                        <div>
                                            <table>
                                                <tbody>
                                                    {Object.entries(AddGroupModalInfo.FRIENDS_INFO).map(([EMAIL, NICKNAME], index) => {
                                                        const NameAndEmail = `${NICKNAME} (${EMAIL})`;
                                                        return (
                                                            <tr key={EMAIL} onClick={e => ToggleFriendEmail(NICKNAME, EMAIL)}>
                                                                <td>{NameAndEmail}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </MemberListTable>
                                </div>
                            </div>
                        </div>
                        <ModalBottomBox  justifyType={AddGroupModalInfo.Mode === 'Edit' && 'space-between'}>
                            {AddGroupModalInfo.Mode === 'Edit' && 
                                <BtnSubDanger label="Delete" onClick={DeleteGroupHandler}/>
                            }
                            <div>
                                <WarningMsg>{Msg}</WarningMsg>
                                <BtnSecondary label="Cancel" onClick={(e) => setAddGroupModalInfo(prev => ({ ...prev, visibility: false }))} />
                                {AddGroupModalInfo.Mode === 'Edit'
                                    ? <BtnPrimary label="Edit" onClick={UpdateGroupHandler} />
                                    : <BtnPrimary label="Ok" onClick={CreateGroupHandler} />

                                }
                            </div>
                        </ModalBottomBox>
                    </div>
                </ModalContent>
            }
        </>
    )
}

const Header = styled.div`  
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 16px;

    &>div{
        display: flex;
        gap: 8px;
    }
`;

const MemberList = styled.ul`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    margin-top: 8px;
    margin-bottom: 24px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    overflow-y: auto;
`;

const GroupCard = styled.li`
    &>.GroupInfo{
        position: relative;
        height: 32px;
        margin: 12px 16px 0 16px;
        padding: 0 8px 0 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${({ theme }) => theme.base.background_color_Gray};

        &>h4{
            font-size: 1rem;
            font-weight: bold;
        }
        
        .EditBtn{
            font-size: 1.3rem;
            opacity: .6;
            border: 1px solid transparent;
            border-radius: 2px;
            cursor: pointer;

            &:hover{
                color: ${({ theme }) => theme.base.font_color_DarkBlue};
                border-color: ${({ theme }) => theme.base.border_color_DarkBlue};
                background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                opacity: 1;
            }
        }
    }
`;


const FriendCard = styled.li`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 12px 16px;
    box-sizing: border-box;

    &:hover{
        background-color: ${({ theme }) => theme.base.background_color_PaleGray};
        .DeleteBtn{
            display: block;
        }
    }

    &>img{
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        box-sizing: border-box;
        overflow: hidden;
        object-fit: cover;
        border-color: ${({theme}) => theme.base.border_color_Gray};
    }

    &>div{
        display: flex;
        position: relative;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding-block: 4px;
        gap: 8px;
        box-sizing: border-box;
        width: calc(100% - 56px);
        color: ${({theme}) => theme.base.font_color_Black};
        
        h4{
            font-size: 1rem;
            font-weight: bold;
        }

        p{
            font-size: .9rem;
            opacity: .6;
        }

        &>.DeleteBtn{
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            font-size: 1.2rem;
            color: ${({theme}) => theme.base.font_color_Red};
            padding: 2px;
            border: 1px solid ${({theme}) => theme.base.border_color_Red};
            border-radius: 2px;
            cursor: pointer;

            &:hover{
                background-color: ${({theme}) => theme.base.background_color_LightRed};
            }
        }
    }
`;

const ModalContent = styled(Popup)`
    .InputList{
        &>div{
            width: 100%;
            margin-bottom: 16px;
            &>input, &>select{
                width: calc(100% - 120px);
            }
        }
    }

    .TableSpace{
        position: relative;
        display: flex;
        gap: 0;
        width: 100%;
        height: 300px;
        margin-bottom: 0;
        gap: 24px;

        &>div{
            &>span{
                margin-top: 8px;
            }
        }
    }
`;

const MemberListTable = styled(TableContent)`
    height: calc(100% - 36px);
`;