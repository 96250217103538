import Modal from "components/modal_new/Modal";
import AddEvent from './AddEvent';
import useModalPopup from "hooks/useModalPopup"

function ModalContent({enuSpace, _Modal}){
    const {
        id: ModalID,
        title: ModalTitle,
        type: ModalType,
    } =_Modal;
    const {DeleteModal, BackToTopModal} = useModalPopup();

    const CloseModal = (e) => DeleteModal(ModalID);
    const BackToTop = (e) => BackToTopModal(ModalID);

    if(!ModalID) 
        return null;

    const ModalBaseProps = {
        _id: ModalID,
        _title: ModalTitle || ModalType,
        _onClick: BackToTop,
        _closeModal: CloseModal,
    }
    
    switch(ModalType){
        case 'Event':{
            return(
                <Modal {...ModalBaseProps} _width={510} _height={606} _minWidth={502} _minHeight={500}>
                    <AddEvent enuSpace={enuSpace} _Modal={_Modal} />
                </Modal>
            )
        }
        default:
            console.error('ModalType is not defined');
            break;
    }
}

export default ModalContent;