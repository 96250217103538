import PropertiesContent from "components/atom/PropertiesContent";
import ColorComponent from "components/molecule/ColorComponent";

export default function SvgCanvasInputList({_data, _ChangeEvent, _disabled}){
    const {background_color, outside_color} = _data;

    const commonProps = {
        labelWidth: 100,
        isGradient: false,
        isOpacity: false,
        isVisibility: false,
        changeEvent: _ChangeEvent,
        disabled: _disabled,
    };
    return(
        <PropertiesContent _Title="Canvas">
            {background_color &&
                <div>
                    <ColorComponent label="background" attributeName="background-color" {...commonProps}/>
                </div>
            }
            {outside_color &&
                <div>
                    <ColorComponent label="outside" attributeName="outside-color" {...commonProps}/>
                </div>
            }
        </PropertiesContent>
    )
}