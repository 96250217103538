import {AccessAxios} from 'components/Axios';

const BaseURL = '/api/Project';

/**
 * 프로젝트 저장
 * @param {string} MEMBER_SYSTEMID  사용자 아이디
 * @param {string} PROJECT_SYSTEMID  프로젝트 아이디
 * @param {string} FILE_TYPE  파일타입
 * @param {string} FILE_NAME  파일명
 * @param {string} DATA  데이터
 */
export const SaveFile = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, FILE_TYPE, FILE_NAME, DATA) => {
    const MSG = { MEMBER_SYSTEMID, PROJECT_SYSTEMID, FILE_TYPE, FILE_NAME, DATA }
    try {
        const res = await AccessAxios.post(`${BaseURL}/SaveFile`, MSG);
        return res;
    } catch (err) {
        throw new Error(`프로젝트 저장에 실패했습니다. : ${err}`);
    }
}

/**
 * 이미지 파일 관리에 대한 description을 저장한다.
 * @param {string} MEMBER_SYSTEMID  - 사용자 아이디 
 * @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
 * @param {string} DESCRIPTION      - json 포맷의 string 
 * @param {string} FILE_TYPE        - (description 폴더에 생성되는 파일명, 기본값 'image')
 * @returns
 */
export const SetDescription = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, DESCRIPTION, FILE_TYPE = 'image') => {
    const MSG = {
        MEMBER_SYSTEMID,
        PROJECT_SYSTEMID,
        FILE_TYPE,
        DESCRIPTION,
    }
    try {
        const res = await AccessAxios.post(`${BaseURL}/SetDescription`, MSG);
        return res;
    } catch (err) {
        throw new Error(err);
    }
}

/**
 * 이미지 리스트를 가져온다.
 * @param {string} MEMBER_SYSTEMID  - 사용자 아이디 
 * @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
 * @param {string} PATH             
 * @returns 
 */
export const GetImageFileList = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, PATH) => {
    const MSG = {
        MEMBER_SYSTEMID,
        PROJECT_SYSTEMID,
        PATH,
        FILE_EXT: ['jpg', 'png', 'webp', 'gif']
    }
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetImageFileList`, MSG);
        return res;
    } catch (err) {
        throw new Error(err);
    }
}

/**
 * 이미지 파일 관리에 대한 description을 가져온다.
 * @param {string} MEMBER_SYSTEMID - 사용자 ID
 * @param {string} PROJECT_SYSTEMID - 프로젝트 ID
 * @param {string} FILE_TYPE        - (description 폴더에 생성되는 파일명, 기본값 'image')
 * @returns image description에 대한 정보를 가져온다.
 */
export const GetDescription = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, FILE_TYPE='image') => {
    const MSG = {
        MEMBER_SYSTEMID,
        PROJECT_SYSTEMID,
        FILE_TYPE,
    }
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetDescription`, MSG);
        return res;
    } catch (err) {
        throw new Error(err);
    }
}

/**
 * 이미지 업로드 처리
 * @param {string} MEMBER_SYSTEMID - 사용자 ID
 * @param {string} PROJECT_SYSTEMID - 프로젝트 ID
 * @param {string} UploadFolderPath - 파일을 업로드할 위치
 * @param {file} FileData 
 * @returns 
 */
export const UploadImages = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, UploadFolderPath, FileData) => {
    let formData = new FormData();
    const fileNames = [];
    Array.from(FileData).forEach(file => {
        formData.append("filedata", file);
        fileNames.push(file.name); 
    })
    formData.append("MEMBER_SYSTEMID",  MEMBER_SYSTEMID);
    formData.append("PROJECT_SYSTEMID", PROJECT_SYSTEMID);
    formData.append("uploadpath", UploadFolderPath.join('/'));

    try {
        const res = await AccessAxios.post(`${BaseURL}/UploadImages`, formData);
        return {res, fileNames};
    } catch (err) {
        throw new Error(err);
    }
}

/**
 * 파일을 삭제한다
 * @param {string} MEMBER_SYSTEMID - 사용자 ID
 * @param {string} PROJECT_SYSTEMID - 프로젝트 ID
 * @param {Array} _ArrImagePath - 삭제할 파일 경로 (path 포함)
 */
export const DeleteFiles = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, ArrImagePath) => {
    const MSG = {
        MEMBER_SYSTEMID,
        PROJECT_SYSTEMID,
        filE_TYPE: 'none',
        FILE_NAMES: [ArrImagePath],
    }

    try {
        const res = await AccessAxios.post(`${BaseURL}/DeleteFiles`, MSG);
        return res;
    } catch (err) {
        throw new Error(err);
    }
}

/**
 * 파일을 이동시킨다
 * @param {string} MEMBER_SYSTEMID - 사용자 ID
 * @param {string} PROJECT_SYSTEMID - 프로젝트 ID
 * @param {string} FROM_PATH        - 파일 path
 * @param {string} TO_PATH          - 이동할 path
 * @param {Array} FILENAMES         - 이동시킬 파일 명
 */
export const MoveFiles = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, FROM_PATH, TO_PATH, FILENAMES) => {
    const MSG={
        MEMBER_SYSTEMID,
        PROJECT_SYSTEMID,
        FROM_PATH,
        TO_PATH,
        FILENAMES
    }

    try {
        const res = await AccessAxios.post(`${BaseURL}/MoveFiles`, MSG);
        return res;
    } catch (err) {
        throw new Error(err);
    }
}

/**
 * 폴더를 생성한다.
 * @param {string} MEMBER_SYSTEMID - 사용자 ID
 * @param {string} PROJECT_SYSTEMID - 프로젝트 ID
 * @param {string} path             - 생성 위치
 * @param {string} folderName       - 생성할 폴더명
 * @returns 
 */
export const CreateFolder = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, path, folderName) => {
    const MSG={
        MEMBER_SYSTEMID,
        PROJECT_SYSTEMID,
        foldername: path.join('/') +'/'+folderName,
    }

    try {
        const res = await AccessAxios.post(`${BaseURL}/CreateFolder`, MSG);
        return res;
    } catch (err) {
        throw new Error(err);
    }
}

/**
 * 이름 변경
* @param {string} MEMBER_SYSTEMID - 사용자 ID
* @param {string} PROJECT_SYSTEMID - 프로젝트 ID
 * @param {string} _old_FILE_NAME   - 기존 파일 이름 (path 포함)
 * @param {string} _neW_FILE_NAME   - 새로운 파일 이름 (path 포함)
 * @returns 
 */
export const RenameFile = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, _old_FILE_NAME, _neW_FILE_NAME) => {
    const MSG={
        MEMBER_SYSTEMID,
        PROJECT_SYSTEMID,
        filE_TYPE:'none',
        filE_NAME: _old_FILE_NAME,
        neW_FILE_NAME: _neW_FILE_NAME
    }

    try {
        const res = await AccessAxios.post(`${BaseURL}/RenameFile`, MSG);
        return res;
    } catch (err) {
        throw new Error(err);
    }
}