// { label: "match", type: "keyword" },
// { label: "hello", type: "variable", info: "(World)" },
// { label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro" }
// { label: "function", type: "keyword", info: "function", detail: "함수" },
// { label: "table", type: "keyword", info: "table", detail: "테이블" },


function createInfoElement(data) {
    const infoElement = document.createElement('div');
    infoElement.innerHTML = `${data}`;
    infoElement.className  = "codemirror-Enu-hint"
    return infoElement;
}

export default function LuaSyntax(context) {
    let word = context.matchBefore(/\w*/)
    if (word.from === word.to && !context.explicit)
        return null
    
    return {
        from: word.from,
        options: [
            { label: "function", type: "keyword"},
            { label: "function", type: "function", apply: "function Name() \n \t \nend" },
            { label: "if", type: "keyword" },
            { label: "else", type: "keyword" },
            { label: "elseif", type: "keyword" },
            { label: "then", type: "keyword" },
            { label: "end", type: "keyword" },
            { label: "for", type: "keyword" },
            { label: "while", type: "keyword" },
            { label: "do", type: "keyword" },
            { label: "local", type: "keyword" },
            { label: "return", type: "keyword" },
            { label: "nil", type: "keyword" },
            { label: "true", type: "keyword" },
            { label: "false", type: "keyword" },
            { label: "not", type: "keyword" },
            { label: "and", type: "keyword" },
            { label: "or", type: "keyword" },

            { label: "width", type: "property"},
            { label: "height", type: "property" },
            { label: "rx", type: "property" },
            { label: "ry", type: "property" },
            { label: "fill-visibility", type: "property" , info: (completion) => createInfoElement('"visible", "hidden"')},
            { label: "fill", type: "property"},
            { label: "fill-opacity", type: "property" },
            { label: "use-text", type: "property" },

            { label: "visible", type: "text", apply:'"visible"' },
            { label: "hidden", type: "text", apply:'"hidden"' },

            { label: "SetTagValue", type: "Enu", apply:"SetTagValue(Param1, Param2)",
                info:(completion) => createInfoElement(`
                    <p class="title">SetTagValue (Param1, Param2)</p>
                    <p>-DAQ(데이터취득)시스템에 데이터를 적재하는 함수입니다.</p>
                    <br />
                    <p>Parameter (인자)</p>
                    <p>- Param1 : DB Table Name</p>
                    <p>- Param2 : Lua Map</p>
                    <br />
                    <p>Return (반환값)</p>
                    <p>- 데이터 Tag의 정보</p>
                    <p>{
                        "Result" : "OK",
                        "Msg" : "Bla ~bla~",
                    }</p>
                    `)},
            { label: "ShowWebpage", type: "Enu", apply:"ShowWebpage(URL)",
                info:(completion) => createInfoElement(`
                    <p class="title">ShowWebpage (Param1</p>
                    <p>- Web page 주소를 읽어 iframe을 통해 화면을 호출하는 함수입니다.</p>
                    <br />
                    <p>Parameter (인자)</p>
                    <p>- - Param1 : URL</p>
                    <br />
                    <p>Remark</p>
                    <p>- iframe 사용을 차단한 Web page는 호출할 수 없습니다.</p>
                    `)},
        ]
    }
}