import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";
import OptionToggleBtn from "components/atom/OptionToggleBtn";
import {ProcessArrayInput} from "components/Utility" 
import {LockIcon, UnlockIcon, VisibleIcon, HiddenIcon} from 'components/common/EnuCanvasIcon'

export default function PropertiesInputList({_data, _disabled, ...props}){
    const {
        id: NodeId,
        z_index: NodeZIndex,
        object_name: NodeObjectName, 
        lock:NodeLock, 
        visibility: NodeVisibility, 
        from: NodeFrom,
        symbol: NodeSymbol,
    } = _data;

    const ChangeValue = (attributeName, attributeValue) =>props._ChangeEvent( [{attributeName, attributeValue }]);

    const SetTitle = (ObjectName) => {
        if(ObjectName === undefined) 
            return 'Properties';
        else if(ObjectName === 'use')
            return `(${NodeSymbol})Properties`;
        else
            return `(${ProcessArrayInput(ObjectName)})Properties`;
    };

    const SetId = (ObjectId) =>{
        if(Array.isArray(ObjectId)){
            return "Mixed"
        }else if(ObjectId?.includes('.')){
            return ObjectId?.split('.')[1]
        }else{
            return ObjectId;
        }
    }

    const IsDisabled = () =>{
        if(NodeFrom === 'ExplorerTree' || SetId(NodeId) === "Mixed"){
            return true;
        }else{
            return _disabled;
        }
    }

    return(
        <PropertiesContent _Title={SetTitle(NodeObjectName)}>
            <InputComponent label="ID" attributeName="id" value={SetId(NodeId)} changeEvent={props._ChangeEvent} disabled={IsDisabled()}>
                {NodeFrom !== 'ExplorerTree' &&
                    <>
                        {!Array.isArray(NodeLock) &&
                            <OptionToggleBtn title="Lock" onClick={(e)=>ChangeValue('lock', NodeLock === '1' ? '0' : '1')}>
                                {NodeLock === '1' ? <LockIcon /> : <UnlockIcon />}
                            </OptionToggleBtn>
                        }
                        {!Array.isArray(NodeVisibility) &&
                            <OptionToggleBtn title="Visibility" onClick={(e)=>ChangeValue('visibility', NodeVisibility === '1' ? '0' : '1')}>
                                { NodeVisibility === '1' ? <VisibleIcon /> : <HiddenIcon />}
                            </OptionToggleBtn>
                        }
                    </>
                }
            </InputComponent>
            {NodeZIndex && <InputComponent label="z-index" value={NodeZIndex} valueType="number" changeEvent={props._ChangeEvent} disabled={_disabled}/>}
        </PropertiesContent>
    )
}