import {useState, useEffect, useContext } from 'react';
import {BtnSecondary} from "components/atom/Button";
import {Layout} from 'components/css/common';
import {MainProjectCard} from 'components/molecule/ProjectCard';
import {GetPublicProjectList, GetPrivateProjectList} from 'api/RepositoryApi'
import {UserInfoContext} from "context/UserInfoContext"

import styled from "styled-components";
import {useNavigate} from 'react-router-dom';
import {MdNavigateBefore, MdNavigateNext, MdInsertLink } from "react-icons/md";

function MainPage(){
    const navigate = useNavigate();
    const { USER_INFO } = useContext(UserInfoContext);
    const { systemId, nickname } = USER_INFO;


    //private 페이지링 관련 
    const [private_posts, setPrivate_posts] = useState([]);
    const [private_currentPage, setPrivate_currentPage] = useState(1);

    //페이지링 관련 
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(()=>{
        if (systemId) {
            GetPrivateProjectList(systemId)
                .then((res) => {
                    const {result, data} = res.data;
                    if(result === "Success"){
                        setPrivate_posts(data);
                    }else{
                        setPrivate_posts([]);
                    }
                });
        }else{
            setPrivate_posts([]);
        }

        GetPublicProjectList()
            .then((res)=>{
                const resData = res['data'];
                if(resData['result'] === 'Success'){
                    setPosts(resData['data']);
                }
            })
    }, [systemId]);

    const PlayRunTime = (userId, userNickname, projectId, projectName, isMetaverse, creatorId) =>{
        const state = {userId, userNickname, projectId, projectName, isMetaverse, creatorId};
        navigate( '/Publish/runtimeMode',{state});
    }

    const ToElement = (id) => {
        const publicDiv = document.getElementById(id);
        if (publicDiv) 
            publicDiv.scrollIntoView({ behavior: "smooth" });
    }

    const ProjectListRender = ({Data, pageInfo, contentId}) =>{
        if(Data.length <= 0)
            return null;
            
        const {currentPage, setCurrentPage} = pageInfo;
        const postsPerPage = 24;
        const indexOfLastPost = currentPage * postsPerPage;
        const indexOfFirstPost = indexOfLastPost - postsPerPage;
        const currentPosts = Data.slice(indexOfFirstPost, indexOfLastPost);

        return (
            <>
                <ProjectCardListRow>
                    {currentPosts.map((item) => {
                        const { PUBLISH_SYSTEMID, MEMBER_SYSTEMID, PROJECT_NAME, PROJECT_TITLE, USE_METAVERSE, NICKNAME, PROFILE_SYSTEMID, PUBLISH_STAMP } = item;
                        const imgSrc = `Repository\\publish\\${PUBLISH_SYSTEMID}\\thumbnail\\project_thumbnail.jpeg`;
                        const playRunTime = (e) =>{
                            PlayRunTime(systemId, nickname, PUBLISH_SYSTEMID, PROJECT_NAME, USE_METAVERSE, MEMBER_SYSTEMID)
                        }
                        return (
                            <MainProjectCard 
                                key={PUBLISH_SYSTEMID} 
                                thumbnailImgSrc={imgSrc} 
                                profileID={PROFILE_SYSTEMID} 
                                projectTitle={PROJECT_TITLE}
                                creator={NICKNAME}
                                publishStamp={PUBLISH_STAMP}
                                playRunTimeEvent={playRunTime}
                                >
                                <ButtonContent 
                                    type='sm'
                                    title='Link'
                                    label={<MdInsertLink />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const copyText = `https://${window.location.host}/Publish/link?${PUBLISH_SYSTEMID}&${PROJECT_NAME}`;
                                        navigator.clipboard.writeText(copyText);
                                        window.AddToastMsg(`link Copy Success`);
                                    }} />
                            </MainProjectCard>
                        )
                    })
                    }
                </ProjectCardListRow>
                <PaginationContent>
                    {Data.length > postsPerPage &&
                        <>
                            <PaginationBtn
                                label={<><MdNavigateBefore style={{ fontSize: '1.3rem'}} /> Prev</>}
                                isVisibility={currentPage !== 1}
                                onClick={(e) => {
                                    setCurrentPage(currentPage - 1);
                                    ToElement(contentId);
                                }}
                            />
                            <PaginationBtn
                                label={<>Next <MdNavigateNext style={{ fontSize: '1.3rem' }} /></>} 
                                isVisibility={currentPage !== Math.ceil(posts.length / postsPerPage)}
                                onClick={(e) => {
                                    setCurrentPage(currentPage + 1);
                                    ToElement(contentId);
                                }}
                            />
                        </>
                    }
                </PaginationContent>
            </>
        )
    }

    return(
        <>
            <PromotionBanner>
                <BannerTextBox>
                    <span className='bannerTitle'>
                        Build the virtual world <br /> for industrial digital twin
                    </span>
                    <span className='bannerText'>
                        우리는 산업용에 필요한 가상세계를 제공합니다. <br />
                        산업용 맞춤형 가상세계에서 다양한 협업도구를 체험하십시요. <br />
                        지금 바로, 또 다른 디지털 트윈화된 세상을 만나보세요.<br />
                    </span>
                    
                    <BtnDocs
                        label='Read Docs'
                        onClick={(e) => {
                            window.open(`https://expnuni.github.io/enuspaceMeta_doc/`,
                                'EnuspaceManual',
                                'width=1000,height=660,location=yes,status=no,scrollbars=yes,menubar=yes,titlebar=yes,toolbar=yes');
                        }}
                    />

                </BannerTextBox>
            </PromotionBanner>
            <ProjectList>
                {private_posts.length > 0 &&
                    <div id='private' className='projectList'>
                        <div className='ListTitle'>My Contents</div>
                        <ProjectListRender Data={private_posts} pageInfo={{ currentPage: private_currentPage, setCurrentPage: setPrivate_currentPage }} contentId="private"/>
                    </div>
                }
                {posts.length > 0 &&
                    <div id='Public' className='projectList'>
                        <div className='ListTitle'>Shared Content</div>
                        <ProjectListRender Data={posts} pageInfo={{ currentPage, setCurrentPage }} contentId="Public"/>
                    </div>
                }
            </ProjectList>
        </>
    )
}

export default MainPage;

// 스타일 ------------------------------------
const PromotionBanner = styled.div`
    position: relative;
    height: 496px;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    user-select: none;
`;

const BannerTextBox = styled(Layout)`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    gap: 32px;

    span {
        color: #FFF;
    }

    .bannerTitle{
        font-weight: bold;
        line-height: 50px;
    }

    .bannerText{
        opacity: .6;
        line-height: 26px;
    }

    @media screen and (min-width:1024px) {
        .bannerTitle{ font-size: 2.3rem; }
        .bannerText{ font-size: 1rem; }
    }
    @media screen and (min-width:768px) and (max-width:1023px) {
        .bannerTitle{ font-size: 2.3rem; }
        .bannerText{ font-size: 1rem; }
    }

    @media screen and (max-width:767px) {
        align-items: center;

        .bannerTitle{ 
            font-size: 2rem; 
            text-align: center;
        }
        .bannerText{ 
            font-size: .86rem;
            text-align: center;
        }
    }
`;

const ProjectList = styled.div`
    position: relative;
    min-height: 370px;
    width: 100%;
    background-color: ${({ theme }) => theme.base.background_color_PaleGray};
    z-index: 1;
    user-select: none;

    &>div:nth-child(even){
            background-color:${({ theme }) => theme.base.border_color_LightGray};
    }

    .projectList{
        padding-top: 32px;

        .ListTitle{
            font-weight: bold;
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 24px;
            color: ${({ theme }) => theme.base.font_color_Black};
        }
    }
`;

const ProjectCardListRow = styled(Layout)`
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    /* height: 442px; */
    gap: 16px;
`
const PaginationContent = styled(Layout)`
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 48px;
    gap: 8px;
`;

const ButtonContent = styled(BtnSecondary)`
    height: 30px !important;
    min-width: 0;
    box-sizing: border-box;
    padding: 0  8px!important;

    &:hover{
        border-color: ${({theme}) => theme.base.border_color_Blue};
        background-color: ${({theme}) => theme.base.background_color_LightBlue};
    }
    svg{
        font-size: 1.3rem;
        color:  ${({theme}) => theme.base.border_color_DarkBlue};
    }
`;

const BtnDocs = styled(BtnSecondary)`
    background-color: ${({ theme }) => theme.base.font_color_DarkBlue};
    border-color: ${({ theme }) => theme.base.font_color_DarkBlue};
    color: #fff;

    &:hover{
        background-color: #0F4A8C;
        color: #fff;
    }
`;

const PaginationBtn = styled(BtnSecondary)`
    visibility: ${({isVisibility})=> isVisibility ? 'visible' : 'hidden'};
`;