import { useState, useEffect} from "react";

import styled from "styled-components";

export default function SelectListContentWithLabel({id, label, labelWidth = '28px', selectListData, selectedValue, disabled, callbackChangeEvent}){
    const [Value, setValue] = useState();

    useEffect((e)=>{
        setValue(selectedValue)
    }, [selectedValue]);

    const ChangeValue = (e) =>{
        setValue(e.target.value);
        callbackChangeEvent && callbackChangeEvent(e.target.value)
    }
    
    return(
        <DivRow labelWidth={labelWidth}>
            {label &&
                <label htmlFor={id} >{label}</label>
            }
            <select 
                title={id} 
                name={id} 
                value={Value || ''}
                disabled={disabled} 
                onChange={ChangeValue}
            >
                { selectListData &&
                    Object.entries(selectListData).map(([key, value])=>{
                        return(
                            <option key={key} value={value}>{key}</option>
                        )
                    })
                }
            </select>
        </DivRow>
    )
}

// 스타일--------------------------
const DivRow = styled.div`
    display: flex;

    >label{
        padding: 6px;
        padding-left: 8px;
        min-width: 28px;
        width: ${({ labelWidth }) => labelWidth};
        color: ${({ theme }) => theme.base.font_color_DarkGray};
        height: 28px;
        display: inline-block;
        box-sizing: border-box;
        font-size: 0.9rem;
        user-select: none;
    }

    >select{
        font-size: .9rem;
        height: 28px;
        width: calc(100% - ${({ labelWidth }) => labelWidth});
        box-sizing: border-box;
        border: 0 none;
        border-bottom: 1px solid  ${({theme}) => theme.base.border_color_LightGray};
        user-select: none;
        cursor: pointer;

        &:hover{
            border-color: ${({ theme }) => theme.base.border_color_Blue};
        }

        &:disabled{
            border-color: transparent;
            background-color: rgba(239, 239, 239, .3);
            opacity: 1;
        }
    }
`;


// const SelectContent = styled.select`
//     font-size: .9rem;
//     height: 28px;
//     box-sizing: border-box;
//     border: 0 none;
//     border-bottom: 1px solid  ${({theme}) => theme.base.border_color_LightGray};
//     margin-left: 8px;
//     flex-grow: 1;
//     user-select: none;

//     &:disabled{
//         border-color: transparent;
//         background-color: rgba(239, 239, 239, .3);
//         opacity: 1;
//     }
// `;