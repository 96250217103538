import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    type: null,
    top: null,
    left: null,
    data:{}
};
const rightMenuSlice = createSlice({
    name: 'rightMenu',
    initialState,
    reducers: {
        showRightMenu(state, action){
            return { ...state, ...action.payload };
        },
        closeRightMenu(state){
            if(state.type === null) return state;
            return initialState;
        },
    }
})

export const { showRightMenu, closeRightMenu} = rightMenuSlice.actions;

export default rightMenuSlice.reducer;
