import { useState, useEffect, useCallback } from "react";

import useModalPopup from "hooks/useModalPopup";
import ModalSpace from "components/modal_new/ModalSpace";
import { LoadingLayer } from "components/organism/LoadingPage";
import ChattingContent from "components/organism/ChattingContent";
import GuestNameInput from "components/organism/GuestNameInput";
import RuntimeCanvas from "components/atom/RuntimeCanvas";
import RuntimeHeader from "components/organism/RuntimeHeader";
import SharePdfModal from "components/modal_new/SharePdfModal";
import { BgPopup, Popup } from "components/css/common";
import ShareUserList from "components/molecule/ShareUserList";
import useEnterWorld from "hooks/useEnterWorld";
import useDAQEnterWorld from "hooks/useDAQEnterWorld";
import { LoadProject } from "hooks/useLoadProject";
import { GetEnuCanvasList } from "api/EnuCanvasApi";
import EnuFileModule from "components/atom/EnuFileModule";
import useWindowFun from "hooks/useWindowFun";

import styled from "styled-components";

function RuntimeModeContent({
  enuSpace,
  _BASE_DATA,
  isIframe,
  viewType = "normal",
  ...props
}) {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const { AddModal } = useModalPopup();
  const [isShareUser, setIsShareUser] = useState({
    visibility: false,
    path: "",
    SharedUser: [],
    SHARED_PDF_ID: "",
  });

  const [SharePdf, setSharePdf] = useState({});
  const [FocusPdf, setFocusPdf] = useState({});

  const [headerMenu, setHeaderMenu] = useState({
    isHeader: viewType !== "link",
    useChatting: false,
    isChatting: false,
    isAutoFix: false,
    isWheel: false,
    isVolume: true,
  });

  // 닉네임 입력 박스
  const [isInputBox, setIsInputBox] = useState({
    visibility: false,
    userNickname: "",
  });
  const [Chat, setChat] = useState([]);

  const {
    connectionStart,
    messages,
    setMessages,
    RequestMsg,
    connectUser,
    SettingAudio,
    DeletedAudio,
    userAudioElementList,
  } = useEnterWorld();
  const { connectionStart: DAQ_connectionStart } = useDAQEnterWorld(enuSpace);

  const INIT_ENTER_WORLD = () => {
    enuSpace.MetaRequest(
      "EnterWorld",
      JSON.stringify({ canvas: "Canvas", type: "init" })
    );
  };

  // 프로젝트 로드
  useEffect(() => {
    if (enuSpace === "") return;

    const { currentURL, userId, projectId, projectName } = _BASE_DATA;
    let loadProjectURL = null;

    if (currentURL === "/MyRepository/runtimeMode") {
      const isInfoCheck =
        userId !== undefined ||
        projectId !== undefined ||
        projectName !== undefined;
      loadProjectURL = isInfoCheck
        ? `/Repository/${userId}/${projectId}/${projectName}.enup`
        : null;
    } else if (currentURL === "/Publish/runtimeMode") {
      const isInfoCheck = projectId !== undefined || projectName !== undefined;
      loadProjectURL = isInfoCheck
        ? `/Repository/publish/${projectId}/${projectName}.enup`
        : null;
    } else if (currentURL === "/Publish/link") {
      loadProjectURL = `/Repository/publish/${_BASE_DATA?.projectId}/${_BASE_DATA?.projectName}.enup`;
    }

    LoadProject(enuSpace, loadProjectURL, setIsLoadingPage);
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    enuSpace.SetIsMobile(isMobile);
  }, [enuSpace, _BASE_DATA]);

  const PlayAudio = useCallback(
    (AUDIO_STREAM) => {
      if (!headerMenu.isVolume) return;

      Object.entries(userAudioElementList).forEach(([userIdKey, audio]) => {
        audio.src = `data:audio/wav;base64,${AUDIO_STREAM}`;
        audio.play();
      });
    },
    [userAudioElementList, headerMenu.isVolume]
  );

  useEffect(() => {
    const firstMessage = messages[0];
    if (!firstMessage) return;

    const strFunc = Object.keys(firstMessage)[0];
    const strJson = JSON.stringify(Object.values(firstMessage)[0]);

    if (enuSpace === "" || strFunc === undefined || strJson === undefined)
      return;

    enuSpace.MetaResponse(strFunc, strJson);

    // console.log('strFunc', strFunc);
    // console.log('strJson', JSON.parse(strJson));

    const NICKNAME = _BASE_DATA.userNickname || isInputBox.userNickname;
    if (strFunc === "Res_EnterWorld") {
      const arrayData = JSON.parse(strJson);
      arrayData.forEach((data) => {
        if (NICKNAME === data.NICKNAME) {
          _BASE_DATA.userId = data.MEMBER_SYSTEMID;
        } else {
          SettingAudio(data.MEMBER_SYSTEMID);
        }
        // setChat(prevChat => [...prevChat, {MsgType: 'alert', MESSAGE: `"${data.NICKNAME}"님이 입장하셨습니다.`}]);
      });
    }

    if (strFunc === "Res_LeaveWorld") {
      const arrayData = JSON.parse(strJson);
      // const [userInfo] = connectUser.info.filter(info => info.MEMBER_SYSTEMID === arrayData.MEMBER_SYSTEMID);
      // setChat(prevChat => [...prevChat, {MsgType: 'alert', MESSAGE: `"${userInfo.NICKNAME}"님이 퇴장하셨습니다.`}]);
      DeletedAudio(arrayData.MEMBER_SYSTEMID);
    }

    if (strFunc === "Res_AudioStream") {
      const { AUDIO_STREAM, MEMBER_SYSTEMID } = JSON.parse(strJson);
      if (_BASE_DATA.userId !== MEMBER_SYSTEMID) PlayAudio(AUDIO_STREAM);
    }

    if (
      ["Res_LocalChat", "Res_WorldChat", "Res_WhisperChat"].includes(strFunc)
    ) {
      const arrayData = JSON.parse(strJson);
      if(arrayData.FILE)
        arrayData.DATA = arrayData.FILE;
      if (NICKNAME !== arrayData.NICKNAME) {
        arrayData["MsgType"] = strFunc;
        setChat((prevChat) => [...prevChat, arrayData]);
      }
    }

    if (
      ["Res_EnterWorld", "From_To_Navigation", "Res_LeaveWorld"].includes(
        strFunc
      )
    ) {
      enuSpace.GetConnectedUsers();
    }

    if (strFunc === "Res_SharePDF") {
      const arrayData = JSON.parse(strJson);
      setSharePdf((prev) => ({
        ...prev,
        [arrayData.SHARED_PDF_ID]: arrayData,
      }));
      RequestMsg("UpdatePDFFocus", {
        SHARED_PDF_ID: arrayData.SHARED_PDF_ID,
        PAGE_INDEX: 1,
      });
    }

    if (strFunc === "Res_InviteSharedPDF") {
      const {
        SHARED_PDF_ID,
        MEMBER_SYSTEMID_LIST,
        HOST_MEMBER_SYSTEMID,
        PDF_NAME,
      } = JSON.parse(strJson);
      setSharePdf((prev) => {
        const updatedSharePdf = { ...prev };
        if (updatedSharePdf[SHARED_PDF_ID]) {
          const { MEMBER_SYSTEMID_LIST: prevMemberList } =
            updatedSharePdf[SHARED_PDF_ID];
          const ClosedUser = prevMemberList.filter(
            (item) => !MEMBER_SYSTEMID_LIST.includes(item)
          );
          ClosedUser.forEach((MemberSystemId) => {
            RequestMsg("LeaveSharedPDF", {
              SHARED_PDF_ID,
              MEMBER_SYSTEMID: MemberSystemId,
            });
          });

          updatedSharePdf[SHARED_PDF_ID].MEMBER_SYSTEMID_LIST =
            MEMBER_SYSTEMID_LIST;
        } else {
          updatedSharePdf[SHARED_PDF_ID] = {
            SHARED_PDF_ID,
            HOST_MEMBER_SYSTEMID,
            PDF_NAME,
            MEMBER_SYSTEMID_LIST,
          };
        }
        return updatedSharePdf;
      });
      RequestMsg("UpdatePDFFocus", { SHARED_PDF_ID, PAGE_INDEX: 1 });
    }

    if (strFunc === "Res_CloseSharedPDF") {
      const arrayData = JSON.parse(strJson);
      setSharePdf((prev) => {
        const updatedSharePdf = { ...prev };
        delete updatedSharePdf[arrayData.SHARED_PDF_ID];
        return updatedSharePdf;
      });

      //   const [FocusPdf, setFocusPdf] = useState({});
      const updatedFocusPdf = { ...FocusPdf };
      delete updatedFocusPdf[arrayData.SHARED_PDF_ID];
      setFocusPdf(updatedFocusPdf);
    }

    if (strFunc === "Res_LeaveSharedPDF") {
      const { SHARED_PDF_ID, MEMBER_SYSTEMID } = JSON.parse(strJson);

      setSharePdf((prev) => {
        const updatedSharePdf = { ...prev };
        if (MEMBER_SYSTEMID === _BASE_DATA.userId) {
          delete updatedSharePdf[SHARED_PDF_ID];
        }

        const pdfInfo = updatedSharePdf[SHARED_PDF_ID];
        if (pdfInfo) {
          // MEMBER_SYSTEMID_LIST에서 해당 MEMBER_SYSTEMID 제거
          pdfInfo.MEMBER_SYSTEMID_LIST = pdfInfo.MEMBER_SYSTEMID_LIST.filter(
            (memberId) => memberId !== MEMBER_SYSTEMID
          );
          updatedSharePdf[SHARED_PDF_ID] = pdfInfo;
        }
        return updatedSharePdf;
      });
    }

    if (strFunc === "Res_UpdateHostPageIndex") {
      const arrayData = JSON.parse(strJson);
      setSharePdf((prev) => {
        const updatedSharePdf = { ...prev };
        updatedSharePdf[arrayData.SHARED_PDF_ID].PAGE_INDEX =
          arrayData.PAGE_INDEX;
        return updatedSharePdf;
      });
    }

    if (strFunc === "Res_UpdatePDFFocus") {
      const { SHARED_PDF_ID, MEMBER_SYSTEMID, PAGE_INDEX } =
        JSON.parse(strJson);

      const updatedFocusPdf = { ...FocusPdf };
      for (const PdfId of Object.keys(updatedFocusPdf)) {
        if (
          typeof updatedFocusPdf[PdfId] === "object" &&
          !Array.isArray(updatedFocusPdf[PdfId])
        ) {
          updatedFocusPdf[PdfId] = Object.keys(updatedFocusPdf[PdfId]).reduce(
            (result, key) => {
              if (key !== MEMBER_SYSTEMID) {
                result[key] = updatedFocusPdf[PdfId][key];
              }
              return result;
            },
            {}
          );

          if (Object.keys(updatedFocusPdf[PdfId]).length === 0)
            delete updatedFocusPdf[PdfId];
        }
      }
      updatedFocusPdf[SHARED_PDF_ID] = {
        ...updatedFocusPdf[SHARED_PDF_ID],
        [MEMBER_SYSTEMID]: PAGE_INDEX,
      };
      setFocusPdf(updatedFocusPdf);
    }

    setMessages((prevMessages) => prevMessages.slice(1));
  }, [
    messages,
    setMessages,
    enuSpace,
    _BASE_DATA,
    isInputBox,
    SettingAudio,
    DeletedAudio,
    PlayAudio,
    RequestMsg,
    FocusPdf,
  ]);

  const onNicknameInput = (guestName) => {
    setIsInputBox({ userNickname: guestName, visibility: false });
    _BASE_DATA.userNickname = guestName;
    INIT_ENTER_WORLD();
  };

  window.Re_SetView_RunTime = () => {
    const DrawHandler = enuSpace.GetDrawHandler("Canvas");
    DrawHandler.SetWheelButton(false);

    const MetaverseFlag = enuSpace.GetMetaverseFlag();
    const MetaverseAvatarFlag = enuSpace.GetMetaverseAvatarFlag();
    const autoFit = enuSpace.GetMetaverseViewFixedFlag();
    const isMyRepositoryRuntimeMode =
      _BASE_DATA.currentURL === "/MyRepository/runtimeMode";
    const isUserIdUndefined = _BASE_DATA.userId === null;
    // const isMobileToggle = isMobile ? false : true;

    // if(!MetaverseFlag || !isMyRepositoryRuntimeMode) return;
    if (isMyRepositoryRuntimeMode || !MetaverseFlag) {
      DAQ_connectionStart(enuSpace);
      return;
    }

    if (MetaverseFlag) {
      if (MetaverseAvatarFlag)
        setHeaderMenu({
          ...headerMenu,
          useChatting: true,
          isChatting: false,
          isAutoFix: autoFit,
          isMinimap: true,
        });
      else
        setHeaderMenu({
          ...headerMenu,
          useChatting: true,
          isChatting: false,
          isAutoFix: autoFit,
        });
    }

    if (isUserIdUndefined)
      setIsInputBox((prevIsInputBox) => ({
        ...prevIsInputBox,
        visibility: true,
      }));
    else {
      INIT_ENTER_WORLD();
      DAQ_connectionStart(enuSpace);
    }
  };

  const CloseShareUserContent = (e) =>
    setIsShareUser({
      visibility: false,
      path: "",
      SharedUser: [],
      SHARED_PDF_ID: "",
    });

  window.Re_Meta_Enter_World = (PUBLISH_SYSTEMID, PICTURE_ID, POS_X, POS_Y) => {
    const { userId: MEMBER_SYSTEMID, userNickname } = _BASE_DATA;
    const NICKNAME =
      userNickname ||
      isInputBox.userNickname ||
      sessionStorage.getItem("nickname");
    connectionStart(MEMBER_SYSTEMID || NICKNAME)
      .then((connection) => {
        RequestMsg(
          "EnterWorld",
          {
            PUBLISH_SYSTEMID,
            PICTURE_ID,
            MEMBER_SYSTEMID,
            NICKNAME,
            POS_X,
            POS_Y,
          },
          connection
        );

        enuSpace.SetResize();
      })
      .catch((err) => {
        window.AddAlertMsg({
          type: "error",
          msg: `Re_Meta_Enter_World : ${err}`,
        });
      });
  };

  window.Re_RunEnuCanvas = (CanvasName) => {
    if (_BASE_DATA.creatorId === null || _BASE_DATA.creatorId === undefined)
      _BASE_DATA.creatorId = enuSpace.GetCreatorID();
    GetEnuCanvasList(_BASE_DATA.creatorId)
      .then((res) => {
        const { result, data } = res.data;
        if (result === "Success") {
          data.forEach((element) => {
            if (element.ENUCANVAS_NAME === CanvasName) {
              const url = `https://${window.location.host}/Canvas/link?${element.ENUCANVAS_SYSTEMID}&${CanvasName}`;
              AddModal(CanvasName, CanvasName, "ShowWebpage", {
                url,
                data: _BASE_DATA,
              });
              //window.open(url, `${element.ENUCANVAS_SYSTEMID}&${CanvasName}`, 'width=1280,height=960,location=no,status=no,scrollbars=yes,menubar=no');
            }
          });
        }
      })
      .catch((err) => {
        window.AddAlertMsg({ type: "error", msg: err });
      });
  };

  const {
    SetGlobalValues,
    RefreshAccessToken,
    RuntimeModeShowMarkdown,
    GetNodeData,
    ShowDocumentModal,
  } = useWindowFun(enuSpace, setIsLoadingPage);
  window.Re_SetGlobalValues = SetGlobalValues;
  window.Re_RefreshAccessToken = RefreshAccessToken;
  window.Re_ShowMarkdown = RuntimeModeShowMarkdown;
  window.Re_GetNodeData = GetNodeData;
  window.Re_ShowDocument = ShowDocumentModal;

  const width = window.innerWidth * 0.8; // 현재 창 너비의 80%
  const height = window.innerHeight * 0.8; // 현재 창 높이의 80%
  return (
    <>
      <LoadingLayer marginTop={"60px"} isLoadingPage={isLoadingPage} />
      <ModalSpace enuSpace={enuSpace} />
      <RuntimeHeader
        enuSpace={enuSpace}
        isIframe={isIframe}
        headerMenu={headerMenu}
        setHeaderMenu={setHeaderMenu}
        viewType={viewType}
      />
      <RuntimeViewContain
        isChatting={headerMenu.isChatting}
        isHeader={headerMenu.isHeader}
      >
        {headerMenu.isChatting && (
          <div className="chattingSpace">
            <ChattingContent
              enuSpace={enuSpace}
              _ChatList={[Chat, setChat]}
              BASE_DATA={_BASE_DATA}
              NICKNAME={_BASE_DATA?.userNickname || isInputBox.userNickname}
              connectUser={connectUser}
              setIsShareUser={setIsShareUser}
            />
          </div>
        )}

        <RuntimeCanvas enuSpace={enuSpace} setIsShareUser={setIsShareUser}>
          {Object.keys(SharePdf).length > 0 && (
            <SharePdfModal
              _BASE_DATA={_BASE_DATA}
              _width={width}
              _height={height}
              SharePdf={SharePdf}
              connectUser={connectUser}
              RequestMsg={RequestMsg}
              FocusPdf={FocusPdf}
              setIsShareUser={setIsShareUser}
            />
          )}
        </RuntimeCanvas>
      </RuntimeViewContain>
      <EnuFileModule enuSpace={enuSpace} />

      {isInputBox.visibility && (
        <GuestNameInput
          value={isInputBox.userNickname}
          onClickEvent={onNicknameInput}
        />
      )}

      {/* 공유 사용자 선택 모달 */}
      {isShareUser.visibility && (
        <ModalContent width="260px">
          <BgPopup onClick={CloseShareUserContent} />
          <ShareUserList
            myInfo={_BASE_DATA.userNickname || isInputBox.userNickname}
            connectUser={connectUser}
            shareUser={isShareUser.SharedUser}
            cancelCallback={CloseShareUserContent}
            shareCallback={(MEMBER_SYSTEMID_LIST) => {
              if (Object.keys(SharePdf).length >= 9) {
                window.AddAlertMsg({
                  type: "error",
                  msg: "PDF는 최대 9개까지 공유 가능합니다.",
                });
              } else {
                if (isShareUser.SHARED_PDF_ID === "") {
                  RequestMsg("SharePDF", {
                    PDF_NAME: isShareUser.path,
                    MEMBER_SYSTEMID_LIST,
                  });
                } else {
                  RequestMsg("InviteSharedPDF", {
                    SHARED_PDF_ID: isShareUser.SHARED_PDF_ID,
                    MEMBER_SYSTEMID_LIST,
                  });
                }
              }
              CloseShareUserContent();
            }}
          />
        </ModalContent>
      )}
    </>
  );
}

export default RuntimeModeContent;

// height: ${({isHeader}) => isHeader ? 'calc(100vh - 46px)' : '100vh'};
//스타일----------------------------------------
const RuntimeViewContain = styled.main`
  position: relative;
  /* height: calc(100vh - 61px); */
  height: 100vh;
  display: flex;

  .chattingSpace {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.8);
  }

  #canvasSpace {
    width: "100%";
    overflow: hidden;

    & > div {
      height: 100%;
    }
  }

  > div {
    height: 100%;
  }

  .Btn_showChat {
    position: absolute;
    top: 12px;
    left: 0;

    height: 36px;
    width: 36px;
    margin-left: 8px;
    color: ${({ theme }) => theme.base.font_color_DarkGray};
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    border-radius: 4px;
    padding: 3px;
    box-sizing: border-box;
    background-color: #fff;
    cursor: pointer;
    z-index: 20;
  }
`;

const ModalContent = styled(Popup)`
  z-index: 15;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .UserList {
    position: relative;
    height: 300px;
    border-block: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    li {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      padding-block: 8px;
      border-radius: 4px;
      box-sizing: border-box;
      border-style: solid;
      border-width: 1px;
      border-color: transparent;

      &:hover {
        background-color: ${({ theme }) =>
          theme.base.background_color_LightBlue};
        border-color: ${({ theme }) => theme.base.border_color_Blue};
      }

      input {
        cursor: pointer;
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      span {
        font-size: 1rem;
      }
    }
  }
`;
