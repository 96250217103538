import styled, { keyframes } from "styled-components";

export const GroupName = styled.span`
    height: 28px;
    width: ${({ width }) => width ? width + 'px' : '56px'};
    min-width: ${({ width }) => width ? width + 'px' : '56px'};
    padding: 6px 0;
    font-size: 0.85rem;
    color: ${({ theme }) => theme.base.font_color_DarkGray};
    display: inline-block;
    box-sizing: border-box;
    text-align: ${({ align }) => align ? align : 'left'};
    user-select: none;

    .required{
        font-size: 0.7rem;
        color: ${({ theme }) => theme.base.font_color_Red};
        margin-right: 4px;
    }
`;

export const XYZInput = styled.div`
    flex-direction: column;
    align-items: start!important;

    &>span{
        height: 24px;
        padding: 6px 8px 0px 8px;
    }

    &>div{
        display: flex;
        align-items: center;
        margin-left: 0!important;

        &>div:nth-child(n+2){
            margin-left: 8px;
        }

        &>div{
            position: relative;
            width: 33.3%;

            input{
                width: 100%;
            }
        }
    }
`;

export const SubTitle = styled.span`
    height: 28px;
    line-height: 1.8rem;
    padding-left: 8px;
    font-weight: 500;
`;

export const ChartTitle = styled.span`
    padding: 6px;
    font-size: .9rem;
    padding-left: 8px;
    color: ${({ theme }) => theme.base.font_color_DarkGray};
    height: 28px;
    display: inline-block;
    box-sizing: border-box;
    margin-top:16px;
`;

export const SelectContent = styled.select`
    font-size: .9rem;
    height: ${({ height }) => height ? height + 'px' : '38px'};
    width: ${({ width }) => width ? width + 'px' : null};
    flex-grow: 1;
    box-sizing: border-box;
    border: 1px solid  ${({ theme }) => theme.base.border_color_Gray};
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:focus {
        outline: none !important;
    }

    &:disabled {
        border-color: transparent;
        opacity: 1;
        color: ${({ theme }) => theme.base.font_color_Black};
        -webkit-appearance: none; /* WebKit/Blink */
        -moz-appearance: none; /* Gecko */
        appearance: none;
    }
`;

export const SelectContentBottom = styled.select`
    font-size: .9rem;
    height: 28px;
    box-sizing: border-box;
    border: 0 none;
    flex-grow: 1;
    border-bottom: 1px solid  ${({theme}) => theme.base.border_color_LightGray};
`;

export const InputContent = styled.input`
    box-sizing: border-box;
    border: 1px solid ${({theme}) => theme.base.border_color_Gray};
    border-radius: 4px;
    height: 100%;
    flex-grow: 1;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
        outline: none;
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
    }

    &::placeholder{
        color: ${({ theme }) => theme.base.font_color_Gray};
    }

    &:disabled {
        border-color: ${({ theme }) => theme.base.border_color_LightGray};
        background-color:  ${({ theme }) => theme.base.background_color_PaleGray};
    }
`;

export const TextareaContent = styled.textarea`
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    border-radius: 4px;
    padding: 8px;

    &:focus {
        outline: none;
        border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
    }

    &::placeholder{
        color: ${({ theme }) => theme.base.font_color_Gray};
    }

    &:disabled {
        border-color: ${({ theme }) => theme.base.border_color_LightGray};
        background-color:  ${({ theme }) => theme.base.background_color_PaleGray};
    }
`;

export const TableContent = styled.div`
    width:100%;
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    border-radius: 4px;
    box-sizing: border-box;

    &>div{
        overflow: auto;
        height: 100%;
    }

    table{
        width:100%;
        table-layout: fixed;
        border-collapse: collapse;

        th, td {
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            height: 32px;
            padding: 0 8px;
            box-sizing: border-box;
            vertical-align: middle;
            word-break: break-all;
            font-size: .9rem;

            &>input, &>select  {
                margin: 0;
                border: 0 none;
                height: 100%;
                width: 100%;
                background-color: transparent;
                box-sizing: border-box;

                &:focus-visible{
                    outline: 1px solid ${({ theme }) => theme.base.border_color_DarkBlue};
                }
            }
        }

        thead{
            position: sticky;
            top: 0px;
            background-color: ${({ theme }) => theme.base.background_color_Gray};
            font-size: .9rem;
            z-index: 9;
        }
        
        tbody{
            &>tr{
                cursor: pointer;

                &.selectedRow ,&:hover{
                    background-color:${({ theme }) => theme.base.background_color_LightBlue};
                }
            }

            td{
                line-height: 1.2rem;
            }

            .skeleton{
                
                td{
                    position: relative;
                    
                    &>div{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: calc(100% - 16px);
                        height: 14px;
                        transform: translate(-50%, -50%);
                        background-color: ${({ theme }) => theme.base.background_color_PaleGray};
                        overflow: hidden;
                        /* z-index: -9; */

                        &::before{
                            content: "";
                            display: block;
                            position: absolute;
                            left: -150px;
                            top: 0;
                            height: 100%;
                            width: 150px;
                            background: linear-gradient(
                                to right,
                                transparent 0%,
                                #FFF 50%,
                                transparent 100%
                            );
                            animation: ${keyframes`  from { left: -150px; } to { left: 100%; } `} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
                        }
                    }
                }
            }
        }
    }
`;

export const RowTypeTableContent = styled.div`
    width:100%;
    border-block: 1px solid ${({ theme }) => theme.base.border_color_DarkGray};
    box-sizing: border-box;

    &>div{
        overflow: auto;
        height: 100%;
    }

    table{
        width:100%;
        table-layout: fixed;
        border-collapse: collapse;

        th, td {
            border-bottom: 1px solid ${({ theme }) => theme.base.border_color_Gray};
            height: 32px;
            padding: 0 8px;
            box-sizing: border-box;
            vertical-align: middle;
            word-break: break-all;
            font-size: .9rem;

            &>input, &>select  {
                margin: 0;
                border: 0 none;
                height: 100%;
                width: 100%;
                background-color: transparent;
                box-sizing: border-box;

                &:focus-visible{
                    outline: 1px solid ${({ theme }) => theme.base.border_color_DarkBlue};
                }
            }
        }

        thead{
            position: sticky;
            top: 0px;
            height: 40px;
            background-color: ${({ theme }) => theme.base.background_color_Gray};
            border-bottom: 2px solid ${({ theme }) => theme.base.border_color_DarkBlue};
            font-size: .9rem;
            box-sizing: border-box;
            z-index: 9;
        }
        
        tbody{
            &>tr{
                height: 46px;
                cursor: pointer;

                &.selectedRow ,&:hover{
                    background-color:${({ theme }) => theme.base.background_color_LightBlue};
                }
            }

            td{
                line-height: 1.2rem;
            }

            .skeleton{
                
                td{
                    position: relative;
                    
                    &>div{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: calc(100% - 16px);
                        height: 14px;
                        transform: translate(-50%, -50%);
                        background-color: ${({ theme }) => theme.base.background_color_PaleGray};
                        overflow: hidden;
                        /* z-index: -9; */

                        &::before{
                            content: "";
                            display: block;
                            position: absolute;
                            left: -150px;
                            top: 0;
                            height: 100%;
                            width: 150px;
                            background: linear-gradient(
                                to right,
                                transparent 0%,
                                #FFF 50%,
                                transparent 100%
                            );
                            animation: ${keyframes`  from { left: -150px; } to { left: 100%; } `} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
                        }
                    }
                }
            }
        }
    }
`;

export const ModalBottomBox = styled.div`
    margin-top: ${({ marginTop }) => marginTop ? marginTop : '24px !important'};
    //margin-top: 24px !important;
    display: flex;
    justify-content: ${({ justifyType }) => justifyType ? justifyType : 'flex-end'};
    align-items: center;
    gap: 8px;

    &>div{
        display: flex;
        gap: 8px;
    }
`;

export const TreeUlContent = styled.ul`
    position: relative;
    margin: 0 0 0 8px;
    padding: 0;
    list-style: none;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        border-left: 1px dashed #ccc;
    }

    li {
        position: relative;
        line-height: 32px;
        padding-left: 6px;
  
        &:before {
          content: "";
          position: absolute;
          top: 16px; 
          left: 0;
          width: 8px; 
          height: 1px;
          border-top: 1px dashed #ccc;
        }
  
        &:last-child:before {
          background-color: white;
          height: auto;
          top: 16px; 
          left: -1px;
          bottom: 0;
        }
      }
  
      // Level 3+
      ul {
       margin-left: 8px;  /* half the indentation */
      }
`;

export const Layout = styled.div`
    @media screen and (min-width:1024px) {
        max-width: 1200px;
    }
    @media screen and (min-width:768px) and (max-width:1023px) {
        padding-left: 16px;
        padding-right: 16px;
    }
    @media screen and (max-width:767px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`

export const Popup = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    user-select: none;

    &>.PopupWrap{
        padding: 16px;
        width: ${({ width }) => width ? width : '500px'};
        border-radius: 0.3rem;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
        overflow: hidden;
        z-index:9;
    }
`;

export const BgPopup = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(43, 43, 43, .3);
    backdrop-filter: blur(5px);
    z-index: 9;
`;

export const ToggleRect = styled.span`
    user-select: none;
    img, svg{
        padding: 2px;
        border-width: 1px;
        border-style: solid;
        border-radius: 2px;
        margin-left: 8px;
        border-color: ${({ disabled, theme }) => disabled ? 'transparent' : theme.base.border_color_LightGray};
        color: ${({ theme }) => theme.base.font_color_Gray};
        cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
        background-color: ${({ disabled, theme }) => disabled ? 'transparent' : theme.base.background_color_LightGray};
        
        &.active {
            background-color: #fff;
            color: ${({ theme }) => theme.base.font_color_DarkGray};
        }

        &:not(.active){
            opacity: ${({ disabled }) => disabled ? .3: 1};
        }

        &:hover {
            border: 1px solid ${({ disabled, theme }) => disabled ? `0 none` : theme.base.border_color_Blue};
            background-color: ${({ disabled, theme }) => disabled ? `none` : theme.base.background_color_LightBlue};
        }
    }
`;

export const Spinner = styled.div`
    position: relative;
    width: ${({ size }) => size ? size : '20px'};
    height: ${({ size }) => size ? size : '20px'};
    z-index: 99;
    
    &::before{
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        margin-top: ${({ margin }) => margin ? margin : '-10px'};
        margin-left: ${({ margin }) => margin ? margin : '-10px'};
        border-radius: 50%;
        border: ${({ borerSize }) => borerSize ? borerSize : '2px'} solid transparent;
        border-top-color: ${({ theme }) => theme.base.border_color_Blue};
        border-bottom-color: ${({ theme }) => theme.base.border_color_Blue};
        animation: ${ keyframes` to {transform: rotate(360deg);} `} .8s ease infinite;
    }
`;

export const WarningMsg = styled.span`
    font-size: .9rem;
    color: ${({ theme }) => theme.base.font_color_Red};
`;

export const ContentPage = styled.div`
    @media screen and (min-width:1024px) {
        max-width: 1200px;
        margin: 0 auto;
    } 
    
    @media screen and (max-width:1090px) {
        margin: 0 16px;
    }
`;

export const ContentSectionHeader = styled.div`
    display: flex;
    padding-top: 16px;
    min-height: 32px;
    justify-content: center;
    
    &>div{
        &>div{
            width: 120px;
        }
    }
`;

export const ContentSection = styled.section`
    padding-bottom: 48px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    margin-top: 16px;
    user-select: none;
`;

export const NewContentBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 216px;
    border: 2px dashed ${({theme}) => theme.base.border_color_Gray};
    border-radius: 4px;
    padding: 8px;
    box-sizing: border-box;
    min-width: 240px;
    gap: 16px;

    &>button{
        width: 140px;
    }
    
    @media screen and (min-width:1024px) {
        width: calc(100% / 4 - 12px);
    } 
    
    @media screen and (max-width:1090px) {
        width: calc(100% / 3 - 11px);
    } 

    @media screen and (max-width:850px) {
        width: calc(100% / 2 - 8px);
    }

    @media screen and (max-width:560px) {
        width: calc(100%);
    }
`;

export const PaginationBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    margin-top: 8px;

    span{
        color: ${({theme}) => theme.base.font_color_DarkGray};
        border-radius: 4px;
        width: 28px;
        height: 28px;
        box-sizing: border-box;
        padding: 6px;
        cursor:pointer;

        &:hover{
            background-color:${({theme}) => theme.base.background_color_PaleGray};
        }
    }

    ul{
        margin: 0 16px;
        display: flex;

        li{
            width:28px;
            height:28px;
            padding: 6px;
            font-size: .9rem;
            color: ${({theme}) => theme.base.font_color_DarkGray};
            box-sizing: border-box;
            text-align: center;
            cursor:pointer;

            &:hover{
                background-color:${({theme}) => theme.base.background_color_PaleGray};
            }

            &.selectPage{
                color: ${({theme}) => theme.base.font_color_DarkBlue};
                background-color:${({theme}) => theme.base.background_color_LightBlue};
                border-radius: 4px;
            }
        }
    }
`;