import PropertiesContent from "components/atom/PropertiesContent";
import Checkbox from "components/atom/Checkbox";
import BtnToggle from "components/atom/BtnToggle";
import InputComponent from "components/atom/InputComponent";
import { GroupName } from 'components/css/common';

export default function TrendGuiList({_data, _ChangeEvent, _disabled }){
    const {
        realtime_mode: NodeRealtimeMode, 
        trend_cycle: NodeTrendCycle,
        use_scroll: NodeUseScroll
    } = _data;
    
    return(
        <PropertiesContent _Title={_data.type}>
            <div style={{ flexWrap: 'wrap' }}>
                {['title-visible', 'legend-visible', 'grid-visible'].map((item => {
                    const label = item.replace('-visible', '');
                    const isChecked = _data[item.replace(/-/g, '_')] === '1';
                    return(
                        <Checkbox 
                            key={item}
                            _id={item}
                            checked={isChecked} 
                            _disabled={_disabled}
                            onChange={(e)=>_ChangeEvent([{ attributeName: item, attributeValue: isChecked ? 'hidden' : 'visible' }])}
                        >
                            {label}
                        </Checkbox>
                    )
                }))}
            </div>
            {NodeRealtimeMode &&
                <div style={{ 'justifyContent': 'space-between' }}>
                    <GroupName width={130}>realtime-mode</GroupName>
                    <BtnToggle 
                        _toggleID="realtime-mode" 
                        _isToggleValue={NodeRealtimeMode === 'on' ? 'true' : 'false'} 
                        _disabled={_disabled}
                        _IsToggle={(attributeName, isToggle) => {
                            _ChangeEvent([{ attributeName, attributeValue: isToggle === 'true' ? 'on' : 'off' }]);
                        }} 
                    />
                </div>
            }

            {NodeRealtimeMode &&
                <div style={{ 'justifyContent': 'space-between' }}>
                    <GroupName width={130}>use-scroll</GroupName>
                    <BtnToggle
                        _toggleID="use-scroll"
                        _isToggleValue={NodeUseScroll}
                        _disabled={_disabled}
                        _IsToggle={(attributeName, isToggle) => {
                            _ChangeEvent([{ attributeName, attributeValue: isToggle === 'true' ? 'true' : 'false' }]);
                        }}
                    />
                </div>
            }


            {NodeTrendCycle &&
                <div>
                    <InputComponent label='trend-cycle' valueType='number' labelWidth='88px' value={NodeTrendCycle} changeEvent={_ChangeEvent} disabled={_disabled}/>
                </div>
            }
        </PropertiesContent>
    )
}