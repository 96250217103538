import {useState, useEffect, useRef} from "react"

import {BtnPrimary} from "components/atom/Button";
import {VerticalLabelInput} from "components/molecule/InputFieldLabel"
import Modal from 'components/modal_new/Modal'
import {ModalBottomBox, BgPopup, WarningMsg} from 'components/css/common'
import {CreateProject, UpdateProjectConfig, GetProjectConfig} from 'api/RepositoryApi'

import { useNavigate } from 'react-router-dom'
import styled from "styled-components";

export default function NewProjectFormModal({userId, processType, reLoadEvent, closeEvent}){
    const navigate = useNavigate();
    const [Msg, setMsg] = useState('');
    const inputRef = useRef({projectName: null, projectTitle: null});

    const [newProjectForm, setNewProjectForm] = useState({
        projectName : '',
        projectTitle : '',
        description : '',
        IsOpenRuntime: 0,
        createType: '',
    });
    const Is2Dor3D = ['2D', '3D'].includes(processType);
    const {projectName, projectTitle, projectId, description, createType} = newProjectForm;

    useEffect(() => {
        if (['2D', '3D'].includes(processType)) {
            setNewProjectForm(prev => ({ ...prev, createType: processType }))
        } else {
            // processType 이 2D, 3D 아니면 project Edit 

            const { PROJECT_SYSTEMID: projectId, PROJECT_NAME: projectName } = processType;
            GetProjectConfig(projectId)
                .then(res => {
                    const { result, msg, data } = res.data;
                    if (result === "Success") {
                        const { DESCRIPTION, PROJECT_TITLE: projectTitle } = data;
                        setNewProjectForm({
                            projectName,
                            projectId,
                            projectTitle,
                            description: DESCRIPTION || '',
                            IsOpenRuntime: 0
                        });
                    } else
                        window.AddAlertMsg({ type: 'error', msg });
                })
        }
    }, [processType]);
    
    
    const newProject = (e) =>{
        if(projectName === ''){
            inputRef.current.projectName.focus();
            setMsg({projectName: '프로젝트명을 입력해주세요.'});
            return;
        }

        const projectNameRegex = /^[a-zA-Z0-9_\s-]*$/;
        if (!projectNameRegex.test(projectName)) {
            inputRef.current.projectName.focus();
            setMsg({projectName: '영문으로만 가능하며, 특수 문자는 사용할 수 없습니다.'});
            return;
        }

        if(projectTitle === ''){
            inputRef.current.projectTitle.focus();
            setMsg({projectTitle: '프로젝트 타이틀을 입력해주세요.'});
            return;
        }
        
        setMsg('');

        const handleNavigation = (path, projectId) => navigate(path, { state: { userId, projectId, projectName, } });

        if(Is2Dor3D){
            CreateProject(userId, projectName, projectTitle, description, createType)
                .then(res => {
                    const { result, msg } = res.data;
                    if (result === 'Success') {
                        localStorage.setItem('projectId', msg);
                        localStorage.removeItem('publishId');

                        if (createType === '2D') {
                            handleNavigation('/MyRepository/editMode', msg);
                        } else if (createType === '3D') {
                            handleNavigation('/MyRepository/editMode3D', msg);
                        }

                        closeEvent && closeEvent()
                    } else {
                        setMsg({common: `프로젝트 생성에 실패하였습니다. ${msg}`});
                    }
                })
        }else{
            UpdateProjectConfig(projectId, projectTitle, description)
                .then(res => {
                    const {result, msg} = res.data;
                    if (result !== 'Success') {
                        setMsg({common: `프로젝트명 수정을 실패하였습니다. ${msg}`});
                        return;
                    }
                    reLoadEvent && reLoadEvent();
                    closeEvent && closeEvent()
                })
                .catch(err => {
                    setMsg({common: `프로젝트명 수정시 오류: ${err}`});
                });
        }
    };

    return(
        <ModalContent>
            <BgPopup onClick={(e)=>closeEvent && closeEvent()}/>
            <Modal 
                _x={document.getElementById('root').offsetWidth / 2 - 120} 
                _y={document.getElementById('root').offsetHeight/2 - 340} 
                _title={Is2Dor3D ? 'New Project' : 'Project Info Edit'} 
                _enableResizing={false} 
                _onClick={null} 
                _closeModal={(e)=>closeEvent && closeEvent()} 
            >
                <FlexContent>
                    <VerticalLabelInput
                        label='Project Name'
                        placeholder='Project Name'
                        isRequired={true}
                        inputRef={(el) => inputRef.current.projectName = el}
                        value={newProjectForm.projectName}
                        errorMsg={Msg?.projectName || ''}
                        onChangeEvent={(projectName) => {
                            setNewProjectForm(prev => ({ ...prev, projectName }))
                        }}
                    />
                    <VerticalLabelInput
                        label='Project Title'
                        placeholder='Project Title'
                        isRequired={true}
                        inputRef={(el) => inputRef.current.projectTitle = el}
                        value={newProjectForm.projectTitle}
                        errorMsg={Msg?.projectTitle || ''}
                        onChangeEvent={(projectTitle) => {
                            setNewProjectForm(prev => ({ ...prev, projectTitle }))
                        }}
                    />
                    <VerticalLabelInput
                        label='Description'
                        placeholder='Description'
                        // inputRef={archiveRef}
                        value={newProjectForm.description}
                        onChangeEvent={(description) => {
                            setNewProjectForm(prev => ({ ...prev, description }))
                        }}
                    />
                </FlexContent>
                <ModalBottomBox marginTop={"8px"}>
                    <WarningMsg >{Msg?.common}</WarningMsg>
                    <BtnPrimary label="Ok" onClick={newProject}/>
                </ModalBottomBox>
            </Modal>
        </ModalContent>
    );
}

//스타일----------------------------------------------
const ModalContent = styled.div`
    position: fixed;
    z-index: 9;
`;


const FlexContent = styled.div`
    &>div{
        margin-top: 0;
    }
`;