import { useDispatch } from 'react-redux';
import { showRightMenu, closeRightMenu } from 'reducers/rightMenuReducer';

export default function useRightMenu(targetMenu) {
    const dispatch = useDispatch();

    const ShowRightMenu = (_mapData) => {
        dispatch(showRightMenu({
            type: _mapData['type'],
            top: _mapData['top'],
            left: _mapData['left'],
            data: _mapData['data']
        }));
    }

    const CloseRightMenu = () => {
        dispatch(closeRightMenu());
    }
    return {ShowRightMenu, CloseRightMenu};
}