import { useState } from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import InputComponent from "components/atom/InputComponent";
import SelectList from "components/atom/SelectList";
import ColorComponent from "components/molecule/ColorComponent";
import FontFamily from 'components/common/FontFamily';
import { GroupName, ToggleRect } from 'components/css/common';
import {FontSizeIcon} from 'components/common/EnuSpaceIcon';

import styled from "styled-components";
import { MdFormatItalic } from "react-icons/md";

export default function RadarGridLevelInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        log_scale: NodeLogScale,
        min: NodeMin,
        max: NodeMax,
        num: NodeNum,
        unit: NodeUnit,
        decimal_point: NodeDecimalPoint,
        font_family: NodeFontFamily,
        font_weight: NodeFontWeight,
        font_size: NodeFontSize,
        font_style: NodeFontStyle,
        text_pos: NodeTextPos,
        text_gap: NodeTextGap,
        text_axis: NodeTextAxis,
        fill: NodeFill,
        fill_opacity: NodeFillOpacity,
        stroke_width: NodeStrokeWidth,
        stroke_opacity: NodeStrokeOpacity,
    } = _data;

    const [fontClass] = useState(new FontFamily());

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title="Grid Level">
            <Div>
                <GroupName width={84}>log-scale</GroupName>
                <BtnToggle 
                    _toggleID="log-scale" 
                    _isToggleValue={NodeLogScale} 
                    _disabled={_disabled}
                    _IsToggle={(attributeName, attributeValue)=> _ChangeEvent([{attributeName,attributeValue}])} 
                />
            </Div>
            <div>
                <InputComponent label='min' labelWidth='36px' valueType='number' value={NodeMin} {...commonProps}/>
                <InputComponent label='max' labelWidth='36px' valueType='number' value={NodeMax} {...commonProps}/>
            </div>
            <div>
                <InputComponent label='num' labelWidth='36px' valueType='number' value={NodeNum} {...commonProps}/>
                <InputComponent label='unit' labelWidth='36px' value={NodeUnit} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='decimal-point' labelWidth='150px' valueType='number' value={NodeDecimalPoint} {...commonProps}/>
            </div>
            <div className="margin-top-div">
                <SelectList
                    attributeName="font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <SelectList
                    attributeName="font-weight"
                    dataList={fontClass.GetFontWeight(NodeFontFamily)}
                    selectedValue={fontClass.CheckFontWeight(NodeFontFamily, NodeFontWeight)}
                    {...commonProps}
                />
                <InputComponent
                    label={<FontSizeIcon />}
                    attributeName="font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeFontSize}
                    {...commonProps}
                />
                <ToggleRect 
                    disabled={_disabled}
                    onClick={(e)=>{
                        if (_disabled) return;
                        const attributeValue = Array.isArray(NodeFontStyle) ? 'italic' : ['normal','0'].includes(NodeFontStyle) ? 'italic' : 'normal';
                        _ChangeEvent([{attributeName: 'font-style', attributeValue}]);
                    }}
                >
                    <MdFormatItalic className={Array.isArray(NodeFontStyle) ? '' :  ['normal', '0'].includes(NodeFontStyle) ? '' : 'active'}/>
                </ToggleRect>
            </div>
            <div>
                <ColorComponent label="font" attributeName="font-color" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
            <div>
                <GroupName width={84}>pos</GroupName>
                <SelectList attributeName='text-pos' dataList={["right-up", "right-down", "left-up", "left-down"]} selectedValue={NodeTextPos} {...commonProps} />
            </div>
            <div>
                <InputComponent label='gap' attributeName='text-gap' labelWidth='36px' valueType='number' value={NodeTextGap} {...commonProps}/>
                <InputComponent label='axis' attributeName='text-axis' labelWidth='36px' valueType='number' value={NodeTextAxis} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='fill' labelWidth='102px' value={NodeFill} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='fill-opacity' labelWidth='102px' value={NodeFillOpacity} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='stroke-width' labelWidth='102px' value={NodeStrokeWidth} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='stroke-opacity' labelWidth='102px' value={NodeStrokeOpacity} {...commonProps}/>
            </div>
        </PropertiesContent>
    )
}

//스타일-------------------------
const Div = styled.div`
    justify-content: space-between;
`;

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;
