import { useState, useEffect, useReducer, useCallback } from 'react';

import { BtnSecondary, BtnGhost} from "components/atom/Button";
import Pagination from 'components/atom/Pagination';
import { GroupName, ChartTitle, SelectContent, InputContent, TableContent, ModalBottomBox, WarningMsg } from 'components/css/common';
import { SkeletonTable } from 'components/Utility'
import useModalPopup from "hooks/useModalPopup"
import useProjectData from 'hooks/useProjectData'
import { GetDeviceList as funGetDeviceList, GetTagList } from 'api/DeviceApi'

import { useSelector } from 'react-redux';
import styled from "styled-components"
import { MdFilterAlt, MdTableChart, MdInsertChartOutlined   } from 'react-icons/md'

function reducer(state, action) {
    switch (action.type) {
        case 'ChangeTableType':
            return { ...state, tableInfo: { ...state.tableInfo, type: action.tableType } };
        case 'SetTableList':
            return { ...state, tableInfo: { ...state.tableInfo, tableList: action.tableList } };
        case 'SelectedTable':
            return { ...state, tableInfo: { ...state.tableInfo, selectTableId: action.selectTableId } };
        case 'SetTagList':
            return { ...state, tagInfo: { ...state.tagInfo, tagList: action.tagList } };
        case 'SelectedTag':
            return { ...state, tagInfo: { ...state.tagInfo, selectTagList: action.selectTagList } };
        case 'IsAllTagState':
            return { ...state, tagInfo: { ...state.tagInfo, isAllTag: action.isCheck } };
        case 'IsFilterState':
            return { ...state, filterInfo: { ...state.filterInfo, visibility: action.visibility } };
        case 'ChangeFilterType':
            return { ...state, filterInfo: { ...state.filterInfo, filterType: action.filterType } };
        case 'ChangeFilterValue':
            return { ...state, filterInfo: { ...state.filterInfo, filterValue: action.filterValue } };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

function PopupInterfaceDBModal({ enuSpace, _Modal }) {
    const { SetTreeData } = useProjectData();
    const {AddModal} = useModalPopup();
    const CloseModal = (e) => DeleteModal(_Modal.id);
    const { DeleteModal } = useModalPopup();
    const {userId: MEMBER_SYSTEMID} = useSelector(state => state.projectData.BASE_DATA);
    const systemId = localStorage.getItem('systemId');
    
    const [Msg, setMsg] = useState('');
    const [isLoadingPage, setIsLoadingPage] = useState(false);

    //페이지링 관련 
    const [currentPage, setCurrentPage] = useState(1);
    const [posts, setPosts] = useState([]);
    const [currentPosts, setCurrentPosts] = useState();

    const initVariableInfo = {
        targetPath: _Modal.data.targetPath,
        tableInfo: {
            type: 'My',
            tableList: [],
            selectTableId: null,
        },
        tagInfo: {
            isAllTag: false,
            tagList: [],
            selectTagList: [],
        },
        filterInfo: {
            visibility: false,
            filterType: 'TAG',
            filterValue: '',
        }
    }
    const [VariableInfo, setVariableInfo] = useReducer(reducer, initVariableInfo);

    /**
     * 디바이스 리스트를 가져온다.
     */
    const GetDeviceList = useCallback(async () => {
        try {
            const tableList = await funGetDeviceList(systemId, VariableInfo.tableInfo.type)
            setVariableInfo({ type: 'SetTableList', tableList });
            const firstTableId = tableList[0]?.TABLE_ID || null;
            setVariableInfo({ type: 'SelectedTable', selectTableId: firstTableId });
        } catch (error) {
            console.error(`GetDeviceList Error : ${error}`);
            window.AddAlertMsg({type:'error', msg:`${error}`})
        }
    }, [systemId, VariableInfo.tableInfo.type]);

    useEffect(() => {
        GetDeviceList();
    }, [GetDeviceList]);

    useEffect(() => {
        const SelectTableId = VariableInfo.tableInfo.selectTableId
        if (SelectTableId === null) {
            setPosts([]);
            setVariableInfo({ type: 'SetTagList', tagList: [] });
            return;
        }
        setPosts([]);
        // Table 변경시 선택한 Tag 정보 초기화
        setVariableInfo({ type: 'SelectedTag', selectTagList: [] });
        setVariableInfo({ type: 'IsAllTagState', isAllTag: false });
        setIsLoadingPage(true);
        GetTagList(systemId, SelectTableId)
            .then((res) => {
                const { DATA, RESULT, MSG } = res;
                if (RESULT === 'Ok') {
                    setPosts(DATA);
                    setVariableInfo({ type: 'SetTagList', tagList: DATA });
                } else {
                    setMsg(MSG);
                }
            })
            .catch((err) => {
                window.AddAlertMsg({type:'error', msg:`${err}`})
            })
            .finally(() => {
                setIsLoadingPage(false);
            })
    }, [systemId, VariableInfo.tableInfo.selectTableId]);

    const SelectTable = (e) => {
        setMsg('');
        const selectTableId = e.target.value;
        setVariableInfo({ type: 'SelectedTable', selectTableId });
    }

    // 필터 관련 정보 변경에 관한 함수
    const ChangeIsFilter = (e, EventType) => {
        setMsg('');
        if (EventType === 'visibility') {
            setVariableInfo({ type: 'IsFilterState', visibility: !VariableInfo.filterInfo.visibility });
        } else if (EventType === 'filterType') {
            const filterType = e.target.value;
            setVariableInfo({ type: 'ChangeFilterType', filterType });
        } else if (EventType === 'filterValue') {
            const filterValue = e.target.value;
            setVariableInfo({ type: 'ChangeFilterValue', filterValue });
        }
    };

    // 필터로 검색한 데이터를 화면에 표출
    const FilterData = (e) => {
        const strKeyword = VariableInfo.filterInfo.filterValue;
        const AllTagList = VariableInfo.tagInfo.tagList;

        if (strKeyword === '') {
            setPosts(AllTagList);
            return;
        }

        const Filter = AllTagList.filter(_data => {
            const filterTypeValue = _data[VariableInfo.filterInfo.filterType];
            return filterTypeValue !== null && filterTypeValue.includes(strKeyword);
        })

        setPosts(Filter);
    };

    const ChangeTableType = (e) => {
        if (isLoadingPage) return;
        setMsg('');
        setVariableInfo({ type: 'ChangeTableType', tableType: e.target.value });
    }

    const SelectDbValue = (e, addValueUrl) => {
        if (e.target === undefined) return;

        const selectDbValueList = VariableInfo.tagInfo.selectTagList;
        const RowTarget = e.currentTarget.closest("TR");
        const isCheck = RowTarget.querySelector('input[type="checkbox"]')?.checked ?? false;

        const selectTagList = isCheck
            ? selectDbValueList.filter(_value => _value !== addValueUrl)
            : [...selectDbValueList, addValueUrl];

        setVariableInfo({ type: 'SelectedTag', selectTagList });

        //checkBox를 클릭시 체크가 안되는 현상이 있어서 강제로 체크를 해준다.
        RowTarget.children[0].children[0].checked = !isCheck;
        CheckAllCheckBox();
    }

    //화면에 출력된 리스트의 체크박스가 모두 체크 되었는지 혹인 모두 체크된 상태라면 맨 상단 체크박스도 체크가 된다.
    const CheckAllCheckBox = () => {
        const DocumentCheckList = document.querySelectorAll(".check_list");
        const isCheck = Array.prototype.every.call(DocumentCheckList, _data => _data.checked);
        setVariableInfo({ type: 'IsAllTagState', isCheck });
    }

    const AllCheckEvent = (e) => {
        const selectDbValueList = VariableInfo.tagInfo.selectTagList;
        const tableId = VariableInfo.tableInfo.selectTableId;
        const isCurrentCheck = VariableInfo.tagInfo.isAllTag;

        for (const postData of currentPosts) {
            const { DB_TABLE_NAME, DATA_TYPE, VARIABLE_NAME, DESCRIPTION } = postData;
            const valueUrl = `${tableId}\\${DB_TABLE_NAME}\\${DATA_TYPE}\\${VARIABLE_NAME}\\${DESCRIPTION}`;

            if (isCurrentCheck) {
                // 모두 해제
                const index = selectDbValueList.indexOf(valueUrl);
                if (index !== -1) {
                    selectDbValueList.splice(index, 1);
                }
            } else {
                // 모두 선택
                if (postData['DATA_TYPE'] !== null && postData['DB_TABLE_NAME']) {
                    if (!selectDbValueList.includes(valueUrl)) {
                        selectDbValueList.push(valueUrl);
                    }
                }
            }
        }

        setVariableInfo({ type: 'SelectedTag', selectTagList: selectDbValueList });
        setVariableInfo({ type: 'IsAllTagState', isCheck: !isCurrentCheck });
    }
    
    const handleApiResponse = (resData, successCallback = null, failureCallback = null) => {
        if (resData['type'] === 'success') {
            successCallback && successCallback();
        } else if (resData['type'] === 'failed') {
            failureCallback && failureCallback(resData['data']);
        }
    };

    const addShowModal = async (e,items,type) => {
        if(items.length === 0){
            window.AddAlertMsg({type:'error', msg:'태그를 선택해주세요'});
            return;
        }
        e.stopPropagation();
        const data = [];
        var Id = "";

        items.forEach((item) => {
            const [ DB_TABLE_ID, , , TAG,  ] = item.split('\\')
            Id = Id + TAG;

            const currentTime = new Date();
            const start_time = new Date(currentTime.getTime() - 60 * 60 * 1000);
            
            var result = {};
            result.INTERVAL = '36000';
            result.MEMBER_SYSTEMID = MEMBER_SYSTEMID;
            result.DB_TABLE_ID = DB_TABLE_ID;
            result.TAG = TAG;
            result.START_TIME = start_time.toISOString();
            result.END_TIME = currentTime.toISOString();
            data.push(result);
        })

        if (type === 'chart') {
            AddModal(Id + "chart",`Chart`,"ChartModal",data);
        } else if (type === 'table') {
            AddModal(Id + "table",`Table`,"TableModal",data);
        }
        
    }

    window.Re_CreateVariable_DB = (_msg) => {
        const resData = JSON.parse(_msg);

        const successCallback = () => {
            SetTreeData(JSON.parse(enuSpace.GetProjectTree()));
            window.AddToastMsg("Interface Item이 저장 되었습니다.");
            CloseModal();
        };
        handleApiResponse(resData, successCallback);
    }

    window.Re_CreateStruct = (_msg) => {
        const resData = JSON.parse(_msg);

        const TableId = VariableInfo.tableInfo.selectTableId;
        const tableName = VariableInfo.tableInfo.tableList
            .filter(tableInfo => tableInfo.TABLE_ID === TableId)
            .map(tableInfo => tableInfo.DB_TABLE_NAME);

        const successCallback = () => {
            const svgPath = VariableInfo.targetPath;
            enuSpace.CreateVariable(svgPath, 'global', '', `Struct_${tableName}`, `${tableName}`, '', '', TableId);
        };

        const fileCallback = (Msg) => setMsg(Msg);
        handleApiResponse(resData, successCallback, fileCallback);
    }
    
    return (
        <>
            <SelectTableInfoBox>
                <GroupName width={100} htmlFor='attrType' className="labelTitle">Table Name</GroupName>
                <SelectContent id='tableAccessType' value={VariableInfo.tableInfo.type} onChange={ChangeTableType} >
                    <option value='My'>My</option>
                    <option value='Private'>Private</option>
                    <option value='Public'>Public</option>
                </SelectContent>

                <SelectContent value={VariableInfo.tableInfo.selectTableId || '-'} onChange={SelectTable} >
                    {(VariableInfo.tableInfo.tableList && VariableInfo.tableInfo.tableList.length !== 0)
                        ? VariableInfo.tableInfo.tableList.map((_tableName) => {
                            const { TABLE_ID, DB_TABLE_NAME } = _tableName;
                            return <option key={TABLE_ID} value={TABLE_ID}>{DB_TABLE_NAME}</option>
                        })
                        : <option key="-" value="-">-</option>
                    }
                </SelectContent>

                {/* <BtnGhost label="All Add" onClick={insertAllTableData} /> */}
                <FilterButton className={VariableInfo.filterInfo.visibility ? 'showFilter' : ''} onClick={(e) => ChangeIsFilter(e, 'visibility')}>
                    <MdFilterAlt />
                </FilterButton>
            </SelectTableInfoBox>

            {/*  필터  */}
            <FilterContent isVisibility={VariableInfo.filterInfo.visibility}>
                <GroupName width={100} htmlFor='Filter' className="labelTitle">Filter</GroupName>
                <SelectContent value={VariableInfo.filterInfo.filterType} onChange={(e) => ChangeIsFilter(e, 'filterType')}>
                    <option value='TAG'>Name</option>
                    <option value='VARIABLE_NAME'>Variable Name</option>
                </SelectContent>
                <InputContent type="text" id='Filter' name='Filter' value={VariableInfo.filterInfo.filterValue} placeholder='Filter' onChange={(e) => ChangeIsFilter(e, 'filterValue')} />
                <BtnGhost label="조회" onClick={FilterData} />
            </FilterContent>
            
            {/* 테이블  */}
            <SeverDBContent isVisibility={VariableInfo.filterInfo.visibility}>
                <ChartHeader>
                    <ChartTitle className='title'>{`Search Value _${posts !== undefined ? posts.length : 0} 개`}</ChartTitle>
                    <div className={VariableInfo.tagInfo.selectTagList.length > 0 ? 'abled' : 'disabled'}>
                        <MdInsertChartOutlined  title="차트 보기" onClick={(e)=> addShowModal(e,VariableInfo.tagInfo.selectTagList,'chart')}/>
                        <MdTableChart title="테이블 보기" onClick={(e) => addShowModal(e, VariableInfo.tagInfo.selectTagList, 'table')}/>
                    </div>
                </ChartHeader>
                <GlobalVariableDBTable>
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th className='checkbox'>
                                        <input type="checkbox" onClick={AllCheckEvent} onChange={AllCheckEvent} checked={VariableInfo.tagInfo.isAllTag || false} />
                                    </th>
                                    <th className='IOType'>IO Type</th>
                                    <th className='tagName'>Name</th>
                                    <th className='VariableName'>Variable Name</th>
                                    <th className='type'>Type</th>
                                    <th className='value'>Value</th>
                                    <th className='description'>Desc</th>
                                    <th className='chart'>chart</th>
                                    <th className='table'>table</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPosts && currentPosts.map((_valueIndex, index) => {
                                    const { DB_TABLE_NAME, TAG, DESCRIPTION, VALUE, DATA_TYPE, IO_TYPE, VARIABLE_NAME } = _valueIndex
                                    const tableId = VariableInfo.tableInfo.selectTableId;
                                    const valueUrl = `${tableId}\\${DB_TABLE_NAME}\\${DATA_TYPE}\\${VARIABLE_NAME}\\${DESCRIPTION}`;
                                    const isCheck = VariableInfo.tagInfo.selectTagList.includes(valueUrl) || false;
                                    const item = [
                                        tableId + '\\' +
                                        DB_TABLE_NAME + '\\' +
                                        DATA_TYPE + '\\' +
                                        TAG + '\\' +
                                        DESCRIPTION + '\\'
                                    ];
                                    return (
                                        <tr key={index} onClick={(e) => SelectDbValue(e, valueUrl)} data-tagname={TAG}>
                                            <td className='checkbox'>
                                                {(IO_TYPE && TAG) && <input className='check_list' type="checkbox" onChange={(e) => SelectDbValue(e, valueUrl)} checked={isCheck} />}
                                            </td>
                                            <td className='IOType' title={IO_TYPE}>{IO_TYPE}</td>
                                            <td className='tagName' title={TAG}>{TAG}</td>
                                            <td className='VariableName' title={VARIABLE_NAME}>{VARIABLE_NAME}</td>
                                            <td className='type' title={DATA_TYPE}>{DATA_TYPE}</td>
                                            <td className='value' title={VALUE}>{VALUE}</td>
                                            <td className='description' title={DESCRIPTION}>{DESCRIPTION}</td>
                                            <td className='chart' title="차트 보기"><MdInsertChartOutlined onClick={(e)=> addShowModal(e,item,'chart')}/></td>
                                            <td className='table' title="테이블 보기"><MdTableChart onClick={(e)=> addShowModal(e,item,'table')}/></td>
                                        </tr>
                                    );
                                })}

                                {isLoadingPage &&
                                    <SkeletonTable col={9} row={100} />
                                }
                            </tbody>
                            
                        </table>
                    </div>
                </GlobalVariableDBTable>
                <Pagination _dataList={posts} _currentPage={[currentPage, setCurrentPage]} _setCurrentPosts={setCurrentPosts} />
            </SeverDBContent>

            {/* select 테이블  */}
            {/* <SelectDbValueContent style={{ 'marginTop': '0' }}>
                <ChartTitle className='title'>{`Select Value _${VariableInfo.tagInfo.selectTagList.length} 개`}</ChartTitle>
                <GlobalVariableDBTable>
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th className='table' style={{ 'width': '20px' }}>Table</th>
                                    <th className='type' style={{ 'width': '20px' }}>Type</th>
                                    <th className='tagName' style={{ 'width': '60%' }}>Variable Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {VariableInfo.tagInfo.selectTagList && VariableInfo.tagInfo.selectTagList.map((_selectValue, index) => {
                                    // _selectValue index 0: TableId, 1: DB_TABLE_NAME, 2: DATA_TYPE, 3: VARIABLE_NAME, 4: DESCRIPTION
                                    const [, DB_TABLE_NAME, DATA_TYPE, VARIABLE_NAME,] = _selectValue.split('\\');
                                    return (
                                        <tr key={index}>
                                            <th className='table'>{DB_TABLE_NAME}</th>
                                            <td className='type'>{DATA_TYPE}</td>
                                            <td className='tagName'>{VARIABLE_NAME}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </GlobalVariableDBTable>
            </SelectDbValueContent> */}

            
            <ModalBottomBox>
                <WarningMsg>{Msg}</WarningMsg>
                <BtnSecondary label="Cancel" onClick={CloseModal} style={{zIndex:'10'}}/>
                
            </ModalBottomBox>
        </>
    )
}

export default PopupInterfaceDBModal;

//스타일-----------------------------------------------
const SeverDBContent = styled.div`
    display: flex;
    z-index:10;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 8px;
    height: ${(props) => props.isVisibility ? 'calc(85% - 102px)' : 'calc(85% - 56px)'};
    flex-direction: column;
`

const ChartHeader = styled.div`
    display:flex;
    justify-content:space-between;

    & > div {
        display:flex;
        margin-top:8px;
        margin-bottom:8px;
        
    }

    & > div > * {
        font-size:1.5rem;
        padding:8px;
        
    }

    & > .abled {
        color:${({ theme }) => (theme.base.font_color_Blue)};
        cursor:pointer;
    }

    & > .abled > *:hover {
        background-color: ${({ theme }) => theme.base.background_color_LightBlue};
        
    }

    & > .disabled {
        color:gray;
        opacity: .4;
    }
`;
const GlobalVariableDBTable = styled(TableContent)`
    height: calc(100% - 44px);
    .table{
        width: 12%;
        text-align: center;
    }

    .checkbox {
        width:30px;
        text-align: center;

        &>input{
            cursor: pointer;
        }
    }

    .IOType, .type {
        width: 72px;
        text-align: center;
    }

    .tagName, .VariableName {
        width: 120px;
        text-align: center;
    }

    .value {
        width: 15%;
    }

    .description {

    }
    
    .chart{
        width:60px;
        text-align:center;
    }

    .chart * {
        font-size:1.5rem;
        
    }

    .table{
        width:60px;
        text-align:center;
    }

    .table * {
        font-size:1.5rem;
        
    }
    
    tbody > tr > .chart, .table > * {
        color: ${({ theme }) => theme.base.font_color_Blue};
        cursor:pointer;
    }
 `;

const FilterButton = styled.div`
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;

    &:hover{
        background-color: ${({ theme }) => theme.base.background_color_PaleGray};
    }

    svg{
        width: 24px;
        height: 24px;
        color: ${({ theme }) => theme.base.font_color_DarkGray};
    }

    &.showFilter{
        background-color: ${({ theme }) => theme.base.background_color_LightBlue};
        svg{
            color: ${({ theme }) => theme.base.font_color_Blue};
        }
    }
`;

const SelectTableInfoBox = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    
    #tableAccessType{
        width: 140px;
        flex-grow: 0;
    }
`;

const FilterContent = styled.div`
    display: ${(props) => props.isVisibility ? 'flex' : 'none'};
    align-items: center;
    gap: 8px;

    &>select{
        width: 140px;
        flex-grow: 0;
    }
`;