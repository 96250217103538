import Modal from "./Modal";
import ChangeTreeFileName from './ChangeTreeFileName';
import ChangeImageModalFileName from './ChangeImageModalFileName';
import GlobalVariableDB from './GlobalVariableDB';
import VariableContent from './VariableContent';
import AddEvent from './AddEvent';
import LuaEdit from './LuaEdit';
import ShowGlobalVariableDB from './ShowGlobalVariableDB';
import AddStruct from './AddStruct';
import ImageContent from './ImageContent';
import TrendAddData from './TrendAddData';
import TrendAddAxisY from './TrendAddAxisY';
import InputBox from './InputBox';
import SQLiteTableData from './SQLiteTableData';
import ShowDocument from './ShowDocument';
import ShowWebpage from './ShowWebpage';
import EnuBoard from './EnuBoard';
import ColorListModal from './ColorListModal';
import DocPdfModal from './DocPdfModal';
import AddTemplates from './AddTemplates';
import PopupInterfaceDBModal from "./PopupInterfaceDBModal";
import TableModal from "./TableModal";
import GlobalResource from "./GlobalResource";
import ChartModal from "./ChartModal";
import SQLiteRuntimeModal from "./SQLiteRuntimeModal";

import {BgPopup} from 'components/css/common';
import useModalPopup from "hooks/useModalPopup"

function ModalContent({enuSpace, _Modal}){
    const {
        id: ModalID,
        title: ModalTitle,
        type: ModalType,
    } =_Modal;
    const {DeleteModal, BackToTopModal} = useModalPopup();

    const CloseModal = (e) => DeleteModal(ModalID);
    const BackToTop = (e) => BackToTopModal(ModalID);

    if(!ModalID) 
        return null;

    const ModalBaseProps = {
        _id: ModalID,
        _title: ModalTitle || ModalType,
        _onClick: BackToTop,
        _closeModal: CloseModal,
    }
    
    switch(ModalType){
        case 'AddTemplates': {
            return (
                <Modal {...ModalBaseProps} _x={_Modal.data.Pos.X} _y={_Modal.data.Pos.Y} _width={480} _height={394} _enableResizing={false}>
                    <AddTemplates enuSpace={enuSpace} _Modal={_Modal} />
                </Modal>
            )
        }
        case 'ChangeTreeFileName':{
            return(
                <Modal {...ModalBaseProps} _height={182} _enableResizing={false}>
                    <ChangeTreeFileName enuSpace={enuSpace} _Modal={_Modal} />
                </Modal>
            )
        }
        case 'GlobalVariableDB':{
            return(
                <Modal {...ModalBaseProps} _width={850} _height={850} _minWidth={600} _minHeight={500}>
                    <GlobalVariableDB enuSpace={enuSpace} _Modal={_Modal} />
                </Modal>
            )
        }
        case 'ShowGlobalVariableDB':{
            return(
                <Modal {...ModalBaseProps} _width={850} _height={560} _minWidth={600} _minHeight={500}>
                    <ShowGlobalVariableDB enuSpace={enuSpace} _Modal={_Modal} />
                </Modal>
            )
        }
        case 'EditVariableItem':
        case 'AddVariableItem':{
            return(
                <Modal {...ModalBaseProps} _width={382} _height={280} _enableResizing={false}>
                    <VariableContent enuSpace={enuSpace} _Modal={_Modal} />
                </Modal>
            )
        }
        case 'Event':{
            return(
                <Modal {...ModalBaseProps} _width={510} _height={606} _minWidth={502} _minHeight={500}>
                    <AddEvent enuSpace={enuSpace} _Modal={_Modal} />
                </Modal>
            )
        }
        case 'LuaEdit':{
            return(
                <Modal {...ModalBaseProps} _width={510} _height={606} _minWidth={502} _minHeight={500}>
                    <LuaEdit enuSpace={enuSpace} _Modal={_Modal} />
                </Modal>
            )
        }
        case 'ShowDocument':{
            return(
                <Modal {...ModalBaseProps} _width={510} _height={606} _minWidth={502} _minHeight={500} _style={{padding:'0'}}>
                    <ShowDocument enuSpace={enuSpace} _Modal={_Modal} />
                </Modal>
            )
        }
        case 'ShowWebpage':{
            const width = window.innerWidth * 0.8; // 현재 창 너비의 80%
            const height = window.innerHeight * 0.8; // 현재 창 높이의 80%
            return(
                // <Modal {...ModalBaseProps} _width={820} _height={450} _minWidth={400} _minHeight={300} _style={{padding:'0'}}>
                <Modal {...ModalBaseProps} _width={width} _height={height} _minWidth={400} _minHeight={300} _style={{padding:'0'}}>
                    <ShowWebpage enuSpace={enuSpace} _Modal={_Modal} />
                </Modal>
            )
        }
        case 'Struct':{
            return(
                <Modal {...ModalBaseProps} _width={740} _height={600} _minWidth={740} _minHeight={400}>
                    <AddStruct enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        case 'ImageModal':{
            return(
                <Modal {...ModalBaseProps} _width={668} _height={550} _minWidth={500} _minHeight={500}>
                    <ImageContent enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        case 'ChangeImageModalFileName':{
            return(
                <div>
                    <BgPopup onClick={ CloseModal }/>
                    <Modal {...ModalBaseProps} _height={182} _enableResizing={false}>
                        <ChangeImageModalFileName enuSpace={enuSpace} _Modal={_Modal} />
                    </Modal>
                </div>
            )
        }
        case 'TrendAddData':{
            return(
                <Modal {...ModalBaseProps} _width={740} _height={600} _minWidth={740} _minHeight={400} >
                    <TrendAddData enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        case 'TrendAddAxisY':{
            return(
                <Modal {...ModalBaseProps} _width={740} _height={600} _minWidth={740} _minHeight={400}>
                    <TrendAddAxisY enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        case 'InputBox':{
            return(
                <Modal {...ModalBaseProps} _width={400} _height={178} _enableResizing={false}>
                    <InputBox enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        case 'SQLiteTableData':{
            return(
                <Modal {...ModalBaseProps} _width={850} _height={800} _minWidth={600} _minHeight={500}>
                    <SQLiteTableData enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        case 'SQLiteRuntimeModal':{
            return(
                <Modal {...ModalBaseProps} _width={850} _height={800} _minWidth={600} _minHeight={500}>
                    <SQLiteRuntimeModal enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        case 'ColorListModal':{
            return(
                <Modal {...ModalBaseProps} _width={280} _height={580} _minWidth={280} _minHeight={300}>
                    <ColorListModal enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        case 'PdfModal':{
            const width = window.innerWidth * 0.8; // 현재 창 너비의 80%
            const height = window.innerHeight * 0.8; // 현재 창 높이의 80%
            return(
                // <Modal {...ModalBaseProps} _width={610} _height={740} _minWidth={400} _minHeight={400} _style={{padding: '0'}}>
                <Modal {...ModalBaseProps} _width={width} _height={height} _minWidth={400} _minHeight={400} _style={{padding: '0'}}>
                    <DocPdfModal enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        case 'EnuBoard':{
            return(
                <Modal {...ModalBaseProps} _width={800} _height={600} _minWidth={500} _minHeight={400}>
                    <EnuBoard enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        case 'Textarea':{
            return(
                <Modal {...ModalBaseProps} _width={850} _height={800} _minWidth={600} _minHeight={500}>
                    <textarea style={{
                                width: '100%',
                                height: '100%',
                                wordBreak: 'break-all',
                                lineHeight: '1.6rem',
                                resize: 'none', // 크기 변경 불가 설정
                                border: 'none', // 외각 라인 없애기
                                outline: 'none', // 포커스 아웃라인 없애기
                                padding: '0', // 내부 여백 없애기
                    }} value={_Modal.data.text} readOnly/>
                </Modal>
            )
        }
        case 'PopupInterfaceDB':{
            return(
                <Modal {...ModalBaseProps} _width={800} _height={600} _minWidth={700} _minHeight={500}>
                   <PopupInterfaceDBModal enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }
        
        case 'Chart':{
            return(
                <Modal {...ModalBaseProps} _width={1000} _height={800} _minWidth={400} _minHeight={400}>
                   <ChartModal enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }

        case 'Table':{
            return(
                <Modal {...ModalBaseProps} _width={800} _height={600} _minWidth={400} _minHeight={400}>
                   <TableModal enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }

        case 'GlobalResource':{
            return(
                <Modal {...ModalBaseProps} _width={440} _height={560} _minWidth={400} _minHeight={300}>
                    <GlobalResource enuSpace={enuSpace} _Modal={_Modal}/>
                </Modal>
            )
        }

        default:
            break;
    }
}

export default ModalContent;