import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

import { BtnSecondary} from "components/atom/Button";
import TrendInput from "components/atom/TrendInput";
import SelectList from "components/atom/SelectList";
import ModalColorPickerContent from "components/atom/ModalColorPickerContent";
import FontFamily from 'components/common/FontFamily';
import { TableContent, ChartTitle } from 'components/css/common';
import Checkbox from "components/atom/Checkbox";
import useProjectData from 'hooks/useProjectData'

import styled from "styled-components";
import {MdAdd, MdClear} from "react-icons/md";

export default function TrendAddAxisY({enuSpace, _Modal}){
    const {
        id: ModalId,
        axis_y_list: ModalAxisYList,
    } = _Modal.data;
    const {ChangeNodeData} = useProjectData();
    const ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ACTIVE_PICTURE_URL);  //활성화 된 picture URL

    const [fontClass] = useState(new FontFamily());
    const [AxisList, setAxisList] = useState([]);
    const [AxisData, setAxisData] = useState();
    const [SelectAxis, setSelectAxis] = useState();

    useEffect(()=>{
        setAxisList(ModalAxisYList !== '' ? ModalAxisYList.split(';') : []);
    }, [ModalAxisYList]);

    const CreateAxis = (e) =>{
        const jsonData = [{
            id: ModalId.split('.')[0],
            type: "axis-realtime-y",
        }];
        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"CREATE_OBJECT", JSON.stringify(jsonData), "TrendSeries");
    }

    window.Re_GetNodeData_TrendSeries = ({type, data}) =>{
        if(type=== 'success'){
            const _seriesList = data.axis_y_list.split(';');
            setAxisList(_seriesList);
        }else{
            window.AddAlertMsg({type:'error', msg:'Re_GetNodeData_TrendSeries Error'});
        }
    }

    const DeleteSeries = (e, axisName) =>{
        e.stopPropagation();
        let jsonData = [{id: `${ModalId.split('.')[0]}.${axisName}`, }];
        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"DELETE_OBJECT", JSON.stringify(jsonData), "TrendSeries");
        //FIXME: WASM 응답 여부와 상관없이 리스트에서 제외, 삭제가 실패해도 리스트에서 제외
        setAxisList(AxisList.filter((item) => item !== axisName));
        setAxisData();
        setSelectAxis();
    }

    const RowClick = (e, axisName) =>{
        e.preventDefault();
        setSelectAxis(axisName);
        let jsonData = {id: `${ModalId.split('.')[0]}.${axisName}`,};
        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"GETNODEDATA_BY_ID", JSON.stringify(jsonData), "AxisData");
    }

    window.Re_GetNodeData_AxisData = (_nodeData) =>{
        console.log(_nodeData);
        if(_nodeData.type === 'success'){
            setAxisData(_nodeData.data);
        }else{
            window.AddAlertMsg({type:'error', msg:'Re_GetNodeData_SeriesData Error'});
        }
    }

    const ChangeEvent = (JsonData) => {
        for(let index in JsonData)
            JsonData[index].id = `${ModalId.split('.')[0]}.${SelectAxis}`;

        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"SETNODEDATA", JSON.stringify(JsonData), "");
        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"GETNODEDATA_BY_ID", JSON.stringify({id: `${ModalId.split('.')[0]}.${SelectAxis}`}), "AxisData");
    }

    const DispatchUpdateEvent = (JsonData) =>{
        for(let item of JsonData){
            const {attributeName, attributeValue} = item;
            ChangeNodeData(attributeName?.replaceAll("-", "_") + ':' + attributeValue);
        }
    }

    const ChangeSelect = (attributeName, attributeValue) =>{
        const JsonData = [ { attributeName, attributeValue } ]
        ChangeEvent(JsonData);
        DispatchUpdateEvent(JsonData);
    }

    const ToggleLineWrapping = (e) =>{
        const JsonData = [{
                'id': `${ModalId.split('.')[0]}.${SelectAxis}`,
                'attributeName' : e.target.name,
                'attributeValue' : e.target.checked ? 'true' : 'false',
            }]
        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"SETNODEDATA", JSON.stringify(JsonData), "");
        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"GETNODEDATA_BY_ID", JSON.stringify({id: `${ModalId.split('.')[0]}.${SelectAxis}`}), "AxisData");
    }

    const commonProps = {
        _ChangeEvent: ChangeEvent,
    };

    return(
        <TrendComponent>
            <div>
                <ChartTitle className='title'>Series</ChartTitle>
                <Table>
                    <div>
                        <table className="SeriesTable">
                            <thead>
                                <tr>
                                    <th>Series ID</th>
                                    <th className="Btn">
                                        <BtnSecondary type='sm' title='Add' label={<MdAdd style={{fontSize:'1.2rem', color:'royalblue'}}/>} onClick={CreateAxis}/>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {AxisList.map((item) => (
                                    <tr key={item} className={SelectAxis === item ? 'active' : ''} onClick={(e)=>RowClick(e, item)}>
                                        <td>{item}</td>
                                        <td className="Btn BtnDelete">
                                            <BtnSecondary type='sm' title='Delete' label={<MdClear style={{fontSize:'1.2rem'}}/>} onClick={(e)=>DeleteSeries(e, item)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Table>
            </div>
            <div>
                <ChartTitle className='title'>Series Properties</ChartTitle>
                <Table>
                    <div>
                        <table className="PropertiesTable">
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                            {AxisData &&
                                <tbody>
                                    {/* <tr>
                                        <td>id</td>
                                        <td><TrendInput _id="id" _value={AxisData.id?.split('.')[1]} {...commonProps}/></td>
                                    </tr> */}
                                    <tr>
                                        <td>position</td>
                                        <td><TrendInput _id="axis-position" _value={AxisData.axis_position} {...commonProps}/></td>
                                    </tr>
                                    <tr>
                                        <td>decimal-point</td>
                                        <td><TrendInput _id="decimal-point" _value={AxisData.decimal_point} {...commonProps}/></td>
                                    </tr>
                                    <tr>
                                        <td>autoscale</td>
                                        <td><Checkbox _id='autoscale' checked={AxisData.autoscale === '1'} onChange={ToggleLineWrapping}>{AxisData.autoscale === '1' ? 'on' : 'off'}</Checkbox></td>
                                    </tr>
                                    {!['0', 'false'].includes(AxisData.autoscale) &&
                                        <>
                                            <tr>
                                                <td>max-padding</td>
                                                <td><TrendInput _id="max-padding" _value={AxisData.max_padding} {...commonProps} /></td>
                                            </tr><tr>
                                                <td>min-padding</td>
                                                <td><TrendInput _id="min-padding" _value={AxisData.min_padding} {...commonProps} /></td>
                                            </tr>
                                        </>
                                    }
                                    <tr>
                                        <td>max</td>
                                        <td><TrendInput _id="max" _value={AxisData.max} {...commonProps} /></td>
                                    </tr>
                                    <tr>
                                        <td>min</td>
                                        <td><TrendInput _id="min" _value={AxisData.min} {...commonProps} /></td>
                                    </tr>
                                    <tr>
                                        <td>axis-stroke</td>
                                        <td><ModalColorPickerContent _colorId="axis-stroke" _color={{ color: AxisData.axis_stroke}} {...commonProps}/></td>
                                    </tr>
                                    <tr>
                                        <td>axis-stroke-opacity</td>
                                        <td><TrendInput _id="axis-stroke-opacity" _value={AxisData.axis_stroke_opacity} {...commonProps} /></td>
                                    </tr>
                                    <tr>
                                        <td>stroke-width</td>
                                        <td><TrendInput _id="axis-stroke-width" _value={AxisData.axis_stroke_width} {...commonProps} /></td>
                                    </tr>

                                    <tr>
                                        <td>label-visible</td>
                                        <td><Checkbox _id='label-visible' checked={AxisData.label_visible === '1'} onChange={ToggleLineWrapping}>{AxisData.label_visible === '1' ? 'on' : 'off'}</Checkbox></td>
                                    </tr>
                                    {AxisData.label_visible === '1' &&
                                        <>
                                            <tr>
                                                <td className="depth-1">label</td>
                                                <td><TrendInput _id="label" _value={AxisData.label} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">rotate</td>
                                                <td><TrendInput _id="label-rotation" _value={AxisData.label_rotation} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">gap</td>
                                                <td><TrendInput _id="label-gap" _value={AxisData.label_gap} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">position</td>
                                                <td>
                                                    <SelectList
                                                        attributeName="label-position" 
                                                        dataList={AxisData.label_position}
                                                        selectedValue={AxisData.label_position}
                                                        changeEvent={ChangeSelect}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-family</td>
                                                <td>
                                                <SelectList
                                                    attributeName="label-font-family"
                                                    dataList={fontClass.GetFontFamilyList()}
                                                    selectedValue={AxisData.label_font_family}
                                                    changeEvent={ChangeSelect}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="depth-1">font-weight</td>
                                            <td>
                                                <SelectList
                                                    attributeName="label-font-weight"
                                                    dataList={fontClass.GetFontWeight(AxisData.label_font_family)}
                                                    selectedValue={fontClass.CheckFontWeight(AxisData.label_font_family, AxisData.label_font_weight)}
                                                    changeEvent={ChangeSelect}
                                                />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-size</td>
                                                <td><TrendInput _id="label-font-size" _value={AxisData.label_font_size} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-style</td>
                                                <td><Checkbox _id='label-font-style' checked={AxisData.label_font_style === '1'} onChange={ToggleLineWrapping}>{AxisData.label_font_style === '1' ? 'italic' : 'normal'}</Checkbox></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-color</td>
                                                <td><ModalColorPickerContent _colorId="label-font-color" _color={{ color: AxisData.label_font_color }} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-color-opacity</td>
                                                <td><TrendInput _id="label-font-color-opacity" _value={AxisData.label_font_color_opacity} {...commonProps} /></td>
                                            </tr>
                                        </>

                                    }

                                    <tr>
                                        <td>tick-visible</td>
                                        <td><Checkbox _id='tick-visible' checked={AxisData.tick_visible === '1'} onChange={ToggleLineWrapping}>{AxisData.tick_visible === '1' ? 'on' : 'off'}</Checkbox></td>
                                    </tr>
                                    {AxisData.tick_visible === '1' &&
                                        <>
                                            <tr>
                                                <td className="depth-1">rotation</td>
                                                <td><TrendInput _id="tick-rotation" _value={AxisData.tick_rotation} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">length</td>
                                                <td><TrendInput _id="tick-length" _value={AxisData.tick_length} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">count</td>
                                                <td><TrendInput _id="tick-count" _value={AxisData.tick_count} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">axis-gap</td>
                                                <td><TrendInput _id="tick-axis-gap" _value={AxisData.tick_axis_gap} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">text-gap</td>
                                                <td><TrendInput _id="tick-text-gap" _value={AxisData.tick_text_gap} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">format</td>
                                                <td>
                                                    <SelectList
                                                        attributeName="tick-format"
                                                        dataList={["number", "engineering unit"]}
                                                        selectedValue={AxisData.tick_format}
                                                        changeEvent={ChangeSelect}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">position</td>
                                                <td>
                                                    <SelectList
                                                        attributeName="tick-position"
                                                        dataList={["down", "up"]}
                                                        selectedValue={AxisData.tick_position}
                                                        changeEvent={ChangeSelect}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-family</td>
                                                <td>
                                                    <SelectList
                                                        attributeName="tick-font-family"
                                                        dataList={fontClass.GetFontFamilyList()}
                                                        selectedValue={AxisData.tick_font_family}
                                                        changeEvent={ChangeSelect}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-weight</td>
                                                <td>
                                                    <SelectList
                                                        attributeName="tick-font-weight"
                                                        dataList={fontClass.GetFontWeight(AxisData.tick_font_family)}
                                                        selectedValue={fontClass.CheckFontWeight(AxisData.tick_font_family, AxisData.tick_font_weight)}
                                                        changeEvent={ChangeSelect}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-size</td>
                                                <td><TrendInput _id="tick-font-size" _value={AxisData.tick_font_size} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-style</td>
                                                <td><Checkbox _id='tick-font-style' checked={AxisData.tick_font_style === '1'} onChange={ToggleLineWrapping}>{AxisData.tick_font_style === '1' ? 'italic' : 'normal'}</Checkbox></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-color</td>
                                                <td><ModalColorPickerContent _colorId="tick-font-color" _color={{ color: AxisData.tick_font_color }} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">font-color-opacity</td>
                                                <td><TrendInput _id="tick-font-color-opacity" _value={AxisData.tick_font_color_opacity} {...commonProps} /></td>
                                            </tr>
                                        </>
                                    }

                                    <tr>
                                        <td>baseline-visible</td>
                                        <td><Checkbox _id='baseline-visible' checked={AxisData.baseline_visible === '1'} onChange={ToggleLineWrapping}>{AxisData.baseline_visible === '1' ? 'on' : 'off'}</Checkbox></td>
                                    </tr>
                                    {AxisData.baseline_visible === '1' &&
                                        <>
                                            <tr>
                                                <td className="depth-1">value</td>
                                                <td><TrendInput _id="baseline-value" _value={AxisData.baseline_value} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">stroke</td>
                                                <td><ModalColorPickerContent _colorId="baseline-stroke" _color={{ color: AxisData.baseline_stroke }} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">stroke-opacity</td>
                                                <td><TrendInput _id="baseline-stroke-opacity" _value={AxisData.baseline_stroke_opacity} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">stroke-width</td>
                                                <td><TrendInput _id="baseline-stroke-width" _value={AxisData.baseline_stroke_width} {...commonProps} /></td>
                                            </tr>
                                            <tr>
                                                <td className="depth-1">tick-visible</td>
                                                <td><Checkbox _id='baseline-tick-visible' checked={AxisData.baseline_tick_visible === '1'} onChange={ToggleLineWrapping}>{AxisData.baseline_tick_visible === '1' ? 'on' : 'off'}</Checkbox></td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            }
                        </table>
                    </div>
                </Table>
            </div>
        </TrendComponent>
    )
}

//스타일----------------------------------------
const TrendComponent = styled.div`
    display: flex;
    flex-direction: row;
    height: calc(100% - 8px);

    &>div{
        position: relative;
        width: 55%;
        box-sizing: border-box;
        
        &:first-child{
            width: 45%;
            padding-right: 8px;
        }
    }
`;

const Table = styled(TableContent)`
    height: calc(100% - 28px);

    .SeriesTable{
        tr:hover .BtnDelete > button{
            color: ${({theme}) => theme.base.font_color_Red};
            border-color: ${({theme}) => theme.base.font_color_Red};

            &:hover{
                color: #fff;
                background-color: ${({theme}) => theme.base.font_color_Red};
            }
        }
        th, td{
            border: 0 none;
            border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};
        }
        .BtnDelete > button{
            display: flex;
            color: ${({theme}) => theme.base.font_color_Gray};
            background-color: transparent;
            border-color: transparent;
        }
    }

    .PropertiesTable{
        tr{
            cursor: default;
        }
    }

    .active{
        background-color: ${({ theme }) => theme.base.background_color_LightBlue};
        position: relative;
        
        td:first-child{
            &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: ${({ theme }) => theme.base.background_color_Blue};
            }
        }
    }

    .Btn{
        padding: 0;
        width: 38px;

        &>button{height: 31px;}
    }
    
    tbody td >div{
        height: 100%;
        padding: 0;
        margin: 0 !important;
        border-radius: 0;
    }

    .depth-1{
        padding-left: 24px;
    }
`;