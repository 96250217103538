import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";

export default function FocusMode({_data, _disabled, ...props}){
    const {
        id: NodeId,
        focus_mode: NodeFocusMode,
    } = _data;
    
    const IsFocusMode = (attributeName, attributeValue) =>{
        props._ChangeEvent([{ attributeName, attributeValue }])
    }

    return(
        <PropertiesContent 
            _Title="Focus Mode" 
            _Toggle={
                <BtnToggle _toggleID="focus-mode" _isToggleValue={NodeFocusMode} _disabled={(!NodeId || _disabled )&& true} _IsToggle={IsFocusMode}/>
            } 
            _ToggleValue={!['0', 'false', undefined].includes(NodeFocusMode)}
        />
    )
}