import React, {useState, useEffect} from "react";
import styled from "styled-components";

import ProjectExplorerTree from "components/organism/ProjectExplorerTree";
import HmiSymbolListContent from "components/organism/HmiSymbolListContent";
import ImageListContent from "components/organism/ImageListContent";
import ResizeDiv from "components/atom/ResizeDiv";
import Hdf5Content from "components/organism/Hdf5Content";
import SQLiteContent from "components/organism/SQLiteContent";
import DocumentContent from "components/organism/DocumentContent";
import TabBar from "components/molecule/TabBar";
import NodeTree from "components/organism/NodeTree";
import useProjectData from 'hooks/useProjectData'
import useModalPopup from 'hooks/useModalPopup';
import { 
    EnuIconProjectExplorer,
    EnuIconHmi,
    EnuIconImage,
    EnuIconHDF,
    EnuIconSQL,
    EnuIconDoc,
    EnuIconLog,
    EnuIconResource,
} from 'components/common/EnuSpaceIcon';

import { useSelector } from 'react-redux';

const SIDE_MENUS = {
    PROJECT_EXPLORER: 'projectExplorer',
    HMI: 'hmi',
    IMAGE: 'Image',
    HDF: 'HDF',
    SQLITE: 'SQLite',
    DOC: 'Doc',
}

const EXPLORER_TAB_MENU = {
    PROJECT: 'Project',
    LAYERS: 'Layers',
}

export default function NavTreeContent({enuSpace, _isLogWindow}){
    const NodeFrom = useSelector(state => state.selectNodeData.from);
    const {DeleteNodeData} = useProjectData();
    const [SideMenu, setSideMenu] = useState(SIDE_MENUS.PROJECT_EXPLORER);
    const [ExplorerTab, setExplorerTab] = useState(EXPLORER_TAB_MENU.PROJECT);
    const [isLog, setIsLog] = _isLogWindow;

    const [TreeFilter, setTreeFilter] = useState('all');
    const [Search, setSearch] = useState({
        IsUse: false,
        Keyword: '',
    });
    const {AddModal} = useModalPopup();

    useEffect(()=>{
        if(SideMenu !== SIDE_MENUS.IMAGE && NodeFrom === 'ImageListContent')
            DeleteNodeData();
    }, [SideMenu, NodeFrom, DeleteNodeData]);

    const LeftMenuIcon = (type) => {
        switch(type){
            case SIDE_MENUS.PROJECT_EXPLORER:
                return <EnuIconProjectExplorer draggable="false"/>;
            case SIDE_MENUS.HMI:
                return <EnuIconHmi draggable="false"/>;
            case SIDE_MENUS.IMAGE:
                return <EnuIconImage draggable="false"/>;
            case SIDE_MENUS.HDF:
                return <EnuIconHDF draggable="false"/>;
            case SIDE_MENUS.SQLITE:
                return <EnuIconSQL draggable="false"/>;
            case SIDE_MENUS.DOC:
                return <EnuIconDoc draggable="false"/>;
            default:
                return console.error('LeftMenuIcon Error');
        }
    }

    return(
        <>
            <LeftMenu>
                <div>
                    {Object.entries(SIDE_MENUS).map((menu, index) => {
                        const [key, value] = menu;
                        return(
                            <div key={key} title={value} className={SideMenu === value ? 'selectedMenu' : ''} onClick={()=>setSideMenu(value)} >
                                {LeftMenuIcon(value)}
                            </div>
                        )
                    })}
                </div>
                <div>
                    <div draggable="false" title="Global Resource"
                        onClick={(e)=>{
                            AddModal('GlobalResource', `Global Resource`, 'GlobalResource');
                        }}
                    >
                        <EnuIconResource draggable="false" />
                    </div>
                    <div className={isLog ? 'selectedMenu' : ''} draggable="false" onClick={(e)=>setIsLog(!isLog)}  title="log (ctrl + l)">
                        <EnuIconLog draggable="false" />
                        <input type="checkbox" id="isLog" onChange={(e)=>{}} checked={isLog} style={{display:'none'}}/>
                    </div>
                </div>
            </LeftMenu>

            <ResizeDiv enuSpace={enuSpace} _pos={'right'} _width={250}>
                {SideMenu === SIDE_MENUS.PROJECT_EXPLORER &&
                    <>
                        <TabBar
                            _selectedMenu={ExplorerTab}
                            _MenuList={[EXPLORER_TAB_MENU.PROJECT, EXPLORER_TAB_MENU.LAYERS]}
                            onClick={(e) => setExplorerTab(e.target.textContent)}
                        />

                        <ExplorerContainer>
                            {ExplorerTab === EXPLORER_TAB_MENU.PROJECT &&
                                <ProjectExplorerTree
                                    enuSpace={enuSpace}
                                    TreeFilter={TreeFilter}
                                    ChangeFilter={(FilterType) => setTreeFilter(FilterType)}
                                    SearchState={[Search, setSearch]}
                                />
                            }
                            {ExplorerTab === EXPLORER_TAB_MENU.LAYERS &&
                                <NodeTree 
                                    enuSpace={enuSpace}
                                />
                            }
                        </ExplorerContainer>
                    </>
                }
                
                {SideMenu === SIDE_MENUS.HMI     && <HmiSymbolListContent enuSpace={enuSpace} />}
                {SideMenu === SIDE_MENUS.IMAGE   && <ImageListContent enuSpace={enuSpace} />}
                                                <Hdf5Content enuSpace={enuSpace} _visibility={SideMenu === SIDE_MENUS.HDF}/>
                {SideMenu === SIDE_MENUS.SQLITE  && <SQLiteContent enuSpace={enuSpace}/>}
                {SideMenu === SIDE_MENUS.DOC     && <DocumentContent enuSpace={enuSpace} />}
            </ResizeDiv>
        </>
    )
}

// 스타일-----------------------------------------------------
const LeftMenu = styled.div`
    width: 56px;
    border-right: 1px solid ${({theme}) => theme.base.border_color_Gray};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    & > div{
        &> div {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin: 16px auto;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;
    
            &:hover {
                background-color: ${({theme}) => theme.base.background_color_PaleGray};
            }
    
            &.selectedMenu {
                background-color: ${({theme}) => theme.base.background_color_LightBlue};

                &>svg path{
                    fill: ${({theme}) => theme.base.font_color_Blue} !important;
                }
            }
        }
    }
`;

const ExplorerContainer = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 40px);
`;