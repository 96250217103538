import { useState, useEffect, useMemo } from "react"

import {LoadingLayer} from "components/organism/LoadingPage";
import EnuCanvasPage from "components/template/EnuCanvasPage";
import useEnuSpaceModule from 'hooks/useEnuSpaceModule';
import useWindowFun from 'hooks/useWindowFun';
import useEnterEnuCanvas from 'hooks/useEnterEnuCanvas';
import useEnuCanvasFun from 'hooks/useEnuCanvasFun';
import { setBaseData} from 'reducers/projectDataReducer'
import {SaveCanvasThumbnail} from 'api/EnuCanvasApi'

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function RunEnuCanvas(){
    const [enuSpace] = useEnuSpaceModule();
    const [isLoadingPage] = useState(false);  //setIsLoadingPage
    const [, setACTIVE_PICTURE_URL] = useState(''); //ACTIVE_PICTURE_URL
    const {messages, RequestMsg, setMessages, connection} = useEnterEnuCanvas();
    const dispatch = useDispatch();
    const location = useLocation();
    const [FirstLoad, setFirstLoad] = useState(true);
    
    const BASE_DATA = useMemo(() => {
        return {
            userId: location?.state?.userId,
            userNickname: location?.state?.userNickname,
            canvasId: location?.state?.canvasId,
            canvasName: location?.state?.canvasName
        }
    }, [location]);

    useEffect(()=>{
        dispatch(setBaseData(BASE_DATA));
    }, [BASE_DATA, dispatch]);
    
    useWindowFun(enuSpace);

    useEnuCanvasFun({
        enuSpace,
        messages,
        RequestMsg,
        setMessages,
    })

    useEffect(()=>{
        if(!BASE_DATA?.userId)
            return;
        if(!FirstLoad) return;
        if(connection === undefined) return;

        connection.invoke('EnterEnuCanvas', {
            ENUCANVAS_SYSTEMID: BASE_DATA.canvasId,
            MEMBER_SYSTEMID: BASE_DATA.userId,
            NICKNAME: BASE_DATA.userNickname,
        });

        setFirstLoad(false);
    }, [BASE_DATA, connection, FirstLoad]);

    window.Re_Canvas_LoadCanvas = (json) => {
        const Json = JSON.parse(json);
        const index = BASE_DATA.index;
        let ActivateViewPath = '';
        if (Json[index] !== null && Json[index] !== undefined)
            ActivateViewPath = Json[index].PICTURE;
        else
            ActivateViewPath = Json[0].PICTURE;

        const width = document.getElementById('Canvas_box').offsetWidth;
        const height = document.getElementById('Canvas_box').offsetHeight;
        enuSpace.CreateView('Canvas', width, height);

        if (ActivateViewPath !== '' && ActivateViewPath !== undefined) {
            setACTIVE_PICTURE_URL(ActivateViewPath);
            enuSpace.SetView('Canvas', ActivateViewPath, false, "Canvas");
        }
        enuSpace.CanvasRequest("SetSelectNode", "");
    }

    window.Re_Canvas_SaveThumbnail = (sysID, imgData, creatorID) => {
        SaveCanvasThumbnail(creatorID, BASE_DATA.canvasId, sysID, imgData);
    }

    return(
        <> 
            <LoadingLayer marginTop={"30px"} isLoadingPage={isLoadingPage}/>
            <EnuCanvasPage enuSpace={enuSpace} BASE_DATA={BASE_DATA}/>
        </>
    )
}