import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

export default function DocPdf({BASE_DATA, path, mode = 'read'}){
    const location = useLocation()
    const{ userId, projectId } = BASE_DATA;
    
    useEffect(() => {
        if(location.pathname === '/Publish/DocumentViewer'){
            // userId 가 없으면 runtime 모드에서 실핸하는 것으로 간주
            window.location.href = `/Repository/publish/${projectId}/${path}`;
        }else 
            window.location.href = `/Repository/${userId}/${projectId}/${path}`;
      }, [userId, projectId, path, location]);

    return(
        <div></div>
    )
}