import { MdVerticalAlignBottom, MdVerticalAlignCenter, MdVerticalAlignTop, MdFormatAlignLeft, MdFormatAlignCenter, MdFormatAlignRight } from "react-icons/md";

import styled from "styled-components";

const FORMAT_ALIGN = {
    left: {
        type: "horizontal-align",
        id: "AlignLeft",
        label: <MdFormatAlignLeft />,
    },
    center: {
        type: "horizontal-align",
        id: "AlignCenter",
        label: <MdFormatAlignCenter />,
    },
    right: {
        type: "horizontal-align",
        id: "AlignRight",
        label: <MdFormatAlignRight />,
    }
};

const VERTICAL_ALIGN = {
    top: {
        type: "vertical-align",
        id: "AlignTop",
        label: <MdVerticalAlignTop />,
    },
    middle: {
        type: "vertical-align",
        id: "AlignMiddle",
        label: <MdVerticalAlignCenter />,
    },
    bottom: {
        type: "vertical-align",
        id: "AlignBottom",
        label: <MdVerticalAlignBottom />,
    }
}

export default function TextAlignRadioContent({attributeName, alignType = 'format', selectValue, changeEvent, disabled}){
    const RenderRadio = (type) => {
        return Object.entries(type).map(([key, value]) => {
            const { id: AlignId, label: AlignLabel } = value;
            const LabelId = `${attributeName}_${AlignId}`;

            return (
                <span key={AlignId}>
                    <input
                        type="radio"
                        id={LabelId}
                        name={LabelId}
                        checked={key === selectValue}
                        disabled={disabled}
                        onChange={() => null}
                    />
                    <label
                        htmlFor={LabelId}
                        disabled={disabled}
                        onClick={(e) => {
                            if (disabled || key === selectValue) return;
                            changeEvent && changeEvent([{ attributeName, attributeValue: key }])
                        }}
                    >
                        {AlignLabel}
                    </label>
                </span>
            )
        })
    }

    return(
        <RadioContent title={attributeName}>
            { alignType === 'format' ? RenderRadio(FORMAT_ALIGN) : RenderRadio(VERTICAL_ALIGN)}
        </RadioContent>
    )
}

// 스타일--------------------------
const RadioContent = styled.div`
    input {
        display: none;
    }
    
   input:checked + label {
        background-color: #FFF;
        
        svg{
            opacity: 1;
        }

        :hover {
            background-color: ${({theme}) => theme.base.background_color_LightBlue};
        }
    }

   input[disabled]:checked + label {
        border-color: transparent;
        :hover {
            background-color: ${({ theme }) => theme.base.border_color_LightGray};
        }
    }
    
    input[disabled]:not(:checked) + label {
        border-color: transparent;
        opacity: 0.3;
    }

    span:nth-child(1) label{
        border-radius: 2px 0 0 2px;
    }
    span:last-child label{
        border-radius: 0 2px 2px 0;
        border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    }
    
    label {
        display: inline-block;
        width: 22px;
        height: 22px;
        padding: 2px;
        border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
        border-right: 0 none;
        overflow: hidden;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        background-color: ${({theme}) => theme.base.background_color_LightGray};
        
        svg{
            font-size: 1rem;
            fill: ${({ theme }) => theme.base.font_color_DarkGray};
            opacity: .6;
        }

        &:hover, &:hover:last-child {
            border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
            background-color: ${({ theme }) => theme.base.background_color_LightBlue};

            svg{
                fill: ${({ theme }) => theme.base.font_color_Blue};
            }
        }

        &[disabled] {
            cursor: default;

            &:hover, &:hover:last-child  {
                svg{
                    fill: ${({ theme }) => theme.base.font_color_DarkGray};
                }
            }
        }
    }
`;