import { useDispatch, useSelector } from 'react-redux';
import { setTreeData, changeCanvasViewName, updateCurrentView, setCanvasView, toggleActiveBtn, updateActivePictureURL, toggleModeType, changeActiveCanvasOrder} from 'reducers/projectDataReducer';
import { changeNodeData, deleteNodeData, setNodeData } from 'reducers/selectNodeDataReducer';

export default function useProjectData() {
    const dispatch = useDispatch();
    const CurrentCanvasList = useSelector(state => state.projectData.CANVAS_VIEW);
    const {userId, projectId, projectName} = useSelector(state => state.projectData.BASE_DATA);

    /**
    석택된 노드 데이터를 변경하는 함수
    @param {string} _value - attributeName : attributeValue   ex) 'translate_x : 50'
    */
    const ChangeNodeData = (_value) => {
        dispatch(changeNodeData(_value));
    }

    /**
    노드 데이터를 모두 삭제하는 함수
    */
    const DeleteNodeData = () =>{
        dispatch(deleteNodeData());
    }

    /**
     노드 데이터를 설정하는 함수
     @param {JSON} _value - 노드 데이터
    */
    const SetNodeData = (_value) =>{
        dispatch(setNodeData(_value));
    }

    /**
    프로젝트 트리 데이터를 설정하는 함수
    @param {JSON} _treeData - 트리 데이터
    */
    const SetTreeData = (_treeData) => {
        dispatch(setTreeData(_treeData));
    }

    /**
    프로젝트 트리 파일명을 변경
    @param {string} path - 파일 경로
    @param {string} originalName - 원본 이름
    @param {string} changeName - 변경 이름
    */
    const ChangeFileName = (path, originalName, changeName) => {
        dispatch(changeCanvasViewName({
            path,
            originalName,
            changeName,
        }));
    }

    /**
    활성화 된 뷰를 변경하는 함수
    @param {string} _canvasId - 뷰 아이디
    */
    const UpdateCurrentView = (_canvasId) => {
        dispatch(updateCurrentView(_canvasId));
    }

    /**
    활성화 된 뷰를 Array로 변환 하여 저장
    @param {string} _Path - 뷰 path
    */
    const UpdateArrayActivePictureURL = (_Path) => {
        dispatch(updateActivePictureURL(_Path));
    }

    /**
    캔버스 뷰를 설정하는 함수 현재 및 활성화 된 캔버스 뷰를 설정
    @param {JSON} _JsonData
    */
    const SetCanvasView = (_JsonData) => {
        
        dispatch(setCanvasView(_JsonData));
    }

    /**
    캔버스 draw 객체 활성화 함수
    @param {string} _DrawName - 활성화 할 draw 객체 이름
    */
    const ActiveDrawMenu = (_DrawName) => {
        dispatch(toggleActiveBtn(_DrawName));
    }

    /**
    raw 모드를 변경한다.
    @param {boolean} _mode -  editMode: false, runtimeMode: true
    */
    const SetMode = (_mode) => {
        dispatch(toggleModeType(_mode));
    }

    /**
     * 해당 캔버스 탭을 활성화 시키는 함수
     * @param {string} canvasId             - 캔버스 아이디
     */
    const ChangeTab = (canvasId) =>{
        const {canvasMode, activeCanvas} = CurrentCanvasList;
        UpdateCurrentView({canvasId, mode: canvasMode[canvasId]});
        UpdateArrayActivePictureURL(activeCanvas[canvasId]);
        DeleteNodeData();
    }

    /**
     * tab 순서 변경
     * @param {string} targetId     - 드래그 타겟 canvasId
     * @param {string} toCanvas     - 드랍할 canvasId
     */
    const ChangeActiveCanvasOrder = (targetId, toCanvas) =>{
        dispatch(changeActiveCanvasOrder({targetId, toCanvas}));
    }

    /**
     * 프로젝트 저장
     * @param {Object} enuSpace 
     * @param {string} ActivateViewPath 
     * @param {string} _param 
     */
    const ProjectSave = (enuSpace, ActivateViewPath, TreeData, _param = null) => {
        enuSpace.SaveThumbnail('picture', ActivateViewPath, '.jpeg', 'project_thumbnail');
        
        const AllSave = (FolderName, ChildData) => {
            if (ChildData === null || ChildData === undefined) return;

            for (let _target of ChildData) {
                const { title: TargetTitle, path: TargetPath } = _target;

                if (TargetTitle.includes(".lua"))
                    enuSpace.SaveGlobalLua(userId, projectId, `${TargetPath}\\${TargetTitle}`)
                else
                {
                    enuSpace.SaveSvg(userId, projectId, FolderName, _target['title']);
                    const fileType = TargetPath.includes('library') ? TargetPath.split('\\')[1] : TargetPath;
                    enuSpace.SaveThumbnail(fileType, `${TargetPath}\\${TargetTitle}`, '.png', '');
                }
            }
        }

        for (let { title: FolderName, child: fistChileList } of TreeData) {
            if (FolderName === 'library') {
                for (let { title, child } of fistChileList)
                    AllSave(title, child);
            } else
                AllSave(FolderName, fistChileList);
        }

        //enup 저장
        enuSpace.UpdateEnup(userId, projectId, 'enup', `${projectName}.enup`, _param);
    };

    return {
        ChangeNodeData, 
        SetTreeData, 
        ChangeFileName, 
        DeleteNodeData, 
        SetNodeData, 
        UpdateCurrentView, 
        SetCanvasView, 
        ActiveDrawMenu, 
        UpdateArrayActivePictureURL, 
        SetMode, 
        ChangeTab,
        ChangeActiveCanvasOrder,
        ProjectSave,
    };
}