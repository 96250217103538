import PropertiesContent from "components/atom/PropertiesContent";
import LabelAndInput from "babylon/components/atom/LabelAndInput";
import useDataController from "babylon/hooks/useDataController";
import { GroupName, XYZInput } from 'components/css/common';

export default function Transformation({selectBabylonData, SelectedMesh}){
    const { position, scaling, rotation } = selectBabylonData;
    const {UploadDataByMesh} = useDataController();
    
    return(
        <PropertiesContent _Title="Transformation">
            <XYZInput>
                <GroupName>Position</GroupName>
                <div>
                    {['x', 'y', 'z'].map((axis) => {
                        const KeyValue = `position.${axis}`;
                        return (
                            <LabelAndInput
                                key={KeyValue}
                                id={KeyValue} 
                                label={axis.toUpperCase()}
                                inputType='number'
                                value={position[axis] || '0'}
                                changeEvent={(e) => {
                                    const targetValue = Number(e.target.value);
                                    SelectedMesh.position[axis] = targetValue;
                                    UploadDataByMesh(SelectedMesh)
                                }}
                            />
                        )
                    })}
                </div>
            </XYZInput>
            <XYZInput>
                <GroupName>Scaling</GroupName>
                <div>
                    {['x', 'y', 'z'].map((axis) => {
                        const KeyValue = `scaling.${axis}`;
                        return (
                            <LabelAndInput
                                key={KeyValue}
                                id={KeyValue}
                                label={axis.toUpperCase()}
                                inputType='number'
                                value={scaling[axis] || '0'}
                                changeEvent={(e) => {
                                    const targetValue = Number(e.target.value);
                                    SelectedMesh.scaling[axis] = targetValue;
                                    UploadDataByMesh(SelectedMesh)
                                }}
                            />
                        )
                    })}
                </div>
            </XYZInput>
            <XYZInput>
                <GroupName>Rotation</GroupName>
                <div>
                    {['x', 'y', 'z'].map((axis) => {
                        const KeyValue = `rotation.${axis}`;
                        return (
                            <LabelAndInput
                                key={KeyValue}
                                id={KeyValue}
                                label={axis.toUpperCase()}
                                inputType='number'
                                value={rotation[axis] * (180 / Math.PI) || '0'}
                                changeEvent={(e) => {
                                    const degreeValue = Number(e.target.value);
                                    const radianValue = degreeValue * (Math.PI / 180);
                                    SelectedMesh.rotation[axis] = radianValue;
                                    UploadDataByMesh(SelectedMesh)
                                }}
                            />
                        )
                    })}
                </div>
            </XYZInput>
        </PropertiesContent>
    )
}