import { useState, useReducer, useEffect } from "react"

import { BtnPrimary, BtnSecondary, BtnSubDanger } from "components/atom/Button";
import { ModalBottomBox, Popup, BgPopup, GroupName, InputContent, TextareaContent, WarningMsg } from 'components/css/common';
import { GetTableDetail, CreateTable, UpdateTable, DeleteTable, } from 'api/DeviceApi'

import styled from "styled-components"
import { MdStar } from "react-icons/md";

function reducer(state, action) {
    switch (action.type) {
        case 'init': {
            return { ...state, ...action }
        }
        case 'changeTableName': {
            return { ...state, DB_TABLE_NAME: action.tableName }
        }
        case 'changeDBType': {
            return { ...state, DB_TYPE: action.dbType }
        }
        case 'changeDescription': {
            return { ...state, DESCRIPTION: action.description }
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

const Init = {
    DB_TABLE_NAME: '',
    DB_TYPE: 'PostgreSQL',
    DESCRIPTION: '',
}

export default function NewDatabase({ type, tableInfo, reloadEvent, closeEvent }) {
    const systemId = localStorage.getItem('systemId');
    const [DatabaseInfo, setDatabaseInfo] = useReducer(reducer, Init);
    const [Msg, setMsg] = useState();

    useEffect(() => {
        setMsg();
    }, [DatabaseInfo]);

    useEffect(() => {
        if (!tableInfo) return;
        if (tableInfo.type === 'create') return;

        GetTableDetail(systemId, tableInfo.tableId)
            .then(res => {
                const { MSG, DATA, RESULT } = res.data;
                if (RESULT === "Ok") {
                    setDatabaseInfo({ type: 'init', ...DATA })
                } else {
                    setMsg(MSG);
                }
            })
    }, [systemId, tableInfo]);

    const ValidateInputsEvent = () => {
        const Regex = /^[a-zA-Z_][a-zA-Z0-9_]*/;

        if (DatabaseInfo.DB_TABLE_NAME === '') {
            setMsg('DB Name을 입력해주세요.');
            return false;
        } else if (!Regex.test(DatabaseInfo.DB_TABLE_NAME)) {
            setMsg('DB Name은 특수문자와 숫자로 시작할 수 없습니다.');
            return false;
        }
        
        if (DatabaseInfo.ACQUISITION_TYPE === 'Master') {
            if (DatabaseInfo.ACQUISITION_INTERVAL === '') {
                setMsg('Interval를 입력해주세요.');
                return false;
            }
            if (DatabaseInfo.GET_URL === '') {
                setMsg('Get Url를 입력해주세요.');
                return false;
            }
        }
        return true;
    }

    return (
        <ModalContent>
            <CustomBgPopup onClick={(e) => closeEvent && closeEvent()} />
            <div className="PopupWrap">
                <div className="InputList">
                    <div>
                        <GroupName width={130}>
                            <MdStar className="required" />DB Name
                        </GroupName>
                        <InputContent
                            name="DB_TABLE_NAME"
                            value={DatabaseInfo.DB_TABLE_NAME}
                            onChange={(e) => setDatabaseInfo({ type: 'changeTableName', tableName: e.target.value })}
                        />
                    </div>
                    <div className="textareaBox">
                        <GroupName width={130}>
                            Description
                        </GroupName>
                        <TextareaContent
                            name="DESCRIPTION"
                            value={DatabaseInfo.DESCRIPTION}
                            onChange={(e) => setDatabaseInfo({ type: 'changeDescription', description: e.target.value })}
                        />
                    </div>
                </div>
                <MsgContent>
                    <WarningMsg>{Msg}</WarningMsg>
                </MsgContent>
                <CustomModalBottomBox>
                    <div>
                        {(type === 'edit' && tableInfo.lever === 'Master') &&
                            <BtnSubDanger
                                label="Delete"
                                onClick={(e) => {
                                    if (!window.confirm(`"${DatabaseInfo.DB_TABLE_NAME}" 을 삭제하시겠습니까?`)) return;

                                    DeleteTable(systemId, tableInfo.tableId)
                                        .then(res => {
                                            const { RESULT, MSG } = res;
                                            if (RESULT === "Ok") {
                                                reloadEvent && reloadEvent();
                                                closeEvent && closeEvent();
                                            } else
                                                setMsg(MSG);
                                        })
                                    }
                                }
                            />
                        }
                    </div>
                    <div>
                        <BtnSecondary
                            label="Cancel"
                            onClick={(e) => closeEvent && closeEvent()}
                        />
                        {type === 'create' &&
                            <BtnPrimary
                                label="Create"
                                onClick={(e) => {
                                    if (!ValidateInputsEvent()) return;

                                    CreateTable({ MEMBER_SYSTEMID: systemId, ...DatabaseInfo })
                                        .then(res => {
                                            const { RESULT, MSG } = res;
                                            if (RESULT === "Ok") {
                                                reloadEvent && reloadEvent();
                                                closeEvent && closeEvent();
                                            } else {
                                                setMsg(MSG);
                                            }
                                        })
                                    }
                                }
                            />}
                        {type === 'edit' &&
                            <BtnPrimary
                                label="Edit"
                                onClick={(e) => {
                                    if (!ValidateInputsEvent()) return;

                                    UpdateTable(systemId, tableInfo.tableId, DatabaseInfo)
                                        .then(res => {
                                            const { RESULT, MSG } = res;
                                            if (RESULT === "Ok") {
                                                reloadEvent && reloadEvent();
                                                closeEvent && closeEvent();
                                            } else {
                                                setMsg(MSG);
                                            }
                                        })
                                    }
                                }
                            />}
                    </div>
                </CustomModalBottomBox>
            </div>
        </ModalContent>
    )
}

const ModalContent = styled(Popup)`
    position: absolute;
    top: 72px;
    height: calc(100% - 72px);
    z-index: 15;

    .InputList{
        div{
            width: 100%;
            margin-bottom: 16px;
            input, select{
                width: calc(100% - 130px);
            }
        }

        .textareaBox{
            display: flex;
            align-items: flex-start;

            textarea{
                width: 100%;
                height: 100px;
                resize: none;
            }
        }
    }
`;

const CustomBgPopup = styled(BgPopup)`
    top: 72px;
    height: calc(100% - 72px);
`;

const MsgContent = styled.div`
    height: 24px;
`;

const CustomModalBottomBox = styled(ModalBottomBox)`
    justify-content: space-between;
    margin-top: 0!important;
`;