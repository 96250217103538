import { useState, useRef, useEffect} from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import Checkbox from "components/atom/Checkbox";
import TextAlignRadioContent from "components/atom/TextAlignRadioContent";
import SelectList from "components/atom/SelectList";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import FontFamily from 'components/common/FontFamily';
import {FontSizeIcon} from 'components/common/EnuSpaceIcon'
import { ToggleRect } from 'components/css/common';

import styled from "styled-components";
import { MdFormatItalic } from "react-icons/md";

export default function EditboxInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        font_family: NodeFontFamily,
        text: NodeText,
        font_style: NodeFontStyle,
        font_weight: NodeFontWeight,
        font_size: NodeFontSize,
        horizontal_align: NodeHorizontalAlign,
        vertical_align: NodeVerticalAlign,
    } = _data;

    const [fontClass] = useState(new FontFamily());

    const taRef = useRef();      
    const [cursor, setCursor] = useState(0); //탭 입력 후 커서의 위치
    
    //탭 입력 후 커서의 위치
    useEffect(() => {
        if (taRef.current !== undefined)
            taRef.current.setSelectionRange(cursor, cursor);
    }, [cursor]);

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title="Edit Box">
            <div>
                <SelectList
                    attributeName="font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <SelectList
                    attributeName="font-weight"
                    dataList={fontClass.GetFontWeight(NodeFontFamily)}
                    selectedValue={NodeFontWeight}
                    {...commonProps}
                />
                <InputComponent
                    label={<FontSizeIcon />}
                    attributeName="font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeFontSize}
                    {...commonProps}
                />
            </div>
            <div>
                <ColorComponent
                    label="text"
                    attributeName="text-color"
                    isGradient={false}
                    isVisibility={false}
                    isOpacity={false}
                    {...commonProps}
                />
            </div>

            <AlignDiv>
                <TextAlignRadioContent attributeName='horizontal-align' selectValue={NodeHorizontalAlign} {...commonProps}/>
                <TextAlignRadioContent attributeName='vertical-align' alignType='vertical' selectValue={NodeVerticalAlign} {...commonProps}/>
                <ToggleRect 
                    disabled={_disabled}
                    onClick={(e)=>{
                        if (_disabled) return;
                        const attributeValue = Array.isArray(NodeFontStyle) ? 'italic' : ['normal'].includes(NodeFontStyle) ? 'italic' : 'normal';
                        _ChangeEvent([{attributeName: 'font-style', attributeValue}]);
                    }}
                >
                    <MdFormatItalic className={Array.isArray(NodeFontStyle) ? '' :  ['normal'].includes(NodeFontStyle) ? '' : 'active'}/>
                </ToggleRect>
            </AlignDiv>

            <CheckBoxList>
                {['password', 'multiline', 'lowercase', 'uppercase'].map(item => (
                    <Checkbox
                        key={item}
                        _id={item}
                        checked={Array.isArray(_data[item]) ? false : _data[item] !== '0'}
                        _disabled={_disabled}
                        onChange={(e) => {
                            const isVisible = _data[item] === '1';
                            _ChangeEvent([{ attributeName: item, attributeValue: !isVisible }]);
                        }}
                    >
                        {item}
                    </Checkbox>
                ))}
            </CheckBoxList>

            <TextArea>
                <textarea 
                    ref={taRef} 
                    name="text" 
                    value={Array.isArray(NodeText) ? 'Mixed' : NodeText === 'Input the Text.' ? '' : NodeText}
                    onClick={(e)=>{
                        if(e.target.value === 'Mixed') e.target.select();
                    }}
                    onChange={(e) => {
                        _ChangeEvent([{attributeName: 'text', attributeValue: e.target.value}])
                    }}
                    onKeyDown={(e)=>{
                        if(e.key === 'Tab'){
                            e.preventDefault();
                            const {value, selectionStart, selectionEnd} = e.target

                            const attributeValue = value.substring(0, selectionStart) + '\t' + value.substring(selectionEnd);
                            _ChangeEvent([{attributeName: 'text', attributeValue}])
                            setCursor(selectionStart + 1);
                        }

                        if (e.nativeEvent.isComposing) 
                            return;

                        if( e.key === 'Enter' && e.shiftKey){
                            return;
                        }else if(e.key === 'Enter'){
                            e.preventDefault();
                            e.target.blur();
                        }else if (e.ctrlKey && e.key === 'a'){
                            e.preventDefault();
                            e.target.select();
                        }
                    }}
                    disabled={_disabled}
                />
            </TextArea>
        </PropertiesContent>
    )
}

//스타일-------------------------
const TextArea = styled.div`
    height: auto;

    textarea{
        margin-left: 8px;
        width: 100%;
        height: 56px;
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 2px;
        overflow:auto;
        resize: none;
        line-height:20px;

        &:focus, &:hover {
            border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
        }

        &:defined {
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            outline: none;
        }
    }    
`;

const CheckBoxList = styled.div`
    flex-wrap: wrap;
`;

const AlignDiv = styled.div`
    margin-left: 8px;
    gap: 8px;
`;