
import { useId } from "react";
import styled from "styled-components";

export default function SelectList({attributeName, dataList, selectedValue, changeEvent, disabled}){
    const SelectId = useId();

    const RenderOption = () => {
        if (!dataList) return null;
    
        const createOptions = (items) => {
            return items.map(item => (
                <option key={item} value={item}>{item}</option>
            ));
        };
    
        return Array.isArray(dataList) ? createOptions(dataList) : createOptions(Object.keys(dataList));
    };

    return(
        <Content
            name={SelectId}
            title={attributeName} 
            disabled={disabled}
            value={Array.isArray(selectedValue) ? "Mixed" : selectedValue || ''}
            onChange={(e)=>{
                changeEvent && changeEvent([{attributeName, attributeValue: e.target.value}])
            }}
        >
            {Array.isArray(selectedValue) &&  <option value='Mixed'> - </option>}
            {RenderOption()}
        </Content>
    )
}

const Content = styled.select`
    position: relative;
    width: auto;
    height: 28px;
    font-size: .9rem;
    box-sizing: border-box;
    border: 0 none;
    border-bottom: 1px solid  ${({theme}) => theme.base.border_color_LightGray};
    user-select: none;
    flex-grow: 1;

    &:disabled{
        border-color: transparent;
        background-color: rgba(239, 239, 239, .3);
        opacity: 1;
    }
`;