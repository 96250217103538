
import { useState, useEffect } from 'react';

import Pagination from 'components/atom/Pagination';
import {BtnGhost} from "components/atom/Button";
import { ChartTitle, TableContent } from 'components/css/common';
import {SkeletonTable} from 'components/Utility'
import { GetSelectedTag } from 'api/DeviceApi'
import useModalPopup from "hooks/useModalPopup"

import { useSelector } from 'react-redux';
import styled from "styled-components";
import { MdTableChart, MdInsertChartOutlined   } from 'react-icons/md'

function ShowGlobalVariableDB ({enuSpace, _Modal}){
    const [sendMsg, setSendMsg]= useState('');
    const {userId} = useSelector(state => state.projectData.BASE_DATA);
    const {AddModal} = useModalPopup();
    //페이지링 관련 
    const [posts, setPosts] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPosts, setCurrentPosts] = useState();
    
    useEffect(()=>{
        try {
            const [ type, svgFile, StructName] = _Modal.data.targetPath.split('\\');
            enuSpace.GetVariableTree(`${type}\\${svgFile}`, `attribute\\${StructName}`, 'global', '', 'ShowGlobalVariableDB');
        } catch (error) {
            console.error(`Interface DB Error : ${error}`);
        }
    }, [_Modal, enuSpace]);

    const GetTagInfo = (TABLE_AND_TAGS) => {
        GetSelectedTag(userId, TABLE_AND_TAGS)
            .then(res => {
                const {MSG, DATA, RESULT} = res;
                if(RESULT === 'Ok'){
                    setPosts(DATA);
                }else{
                    window.AddAlertMsg({type:'error', msg:`Interface DB GetSelectedTag 오류 : ${MSG}`})
                }
            })
            .catch(err => {
                window.AddAlertMsg({type:'error', msg:`${err}`})
                setPosts([]);
            })
    };

    const addShowModal = async (e,items,type) => {
        if(items.length === 0){
            window.AddAlertMsg({type:'error', msg:'태그를 선택해주세요'});
            return;
        }
        e.stopPropagation();
        const data = [];
        var Id = "";

        items.forEach((item) => {
            const [ DB_TABLE_ID, , , TAG,  ] = item.split('\\')
            Id = Id + TAG;

            const currentTime = new Date();
            const start_time = new Date(currentTime.getTime() - 60 * 60 * 1000);
            
            var result = {};
            result.INTERVAL = '36000';
            result.MEMBER_SYSTEMID = userId;
            result.DB_TABLE_ID = DB_TABLE_ID;
            result.TAG = TAG;
            result.START_TIME = start_time.toISOString();
            result.END_TIME = currentTime.toISOString();
            data.push(result);
        })

        if (type === 'chart') {
            AddModal(Id + "chart",`Chart`,"ChartModal",data);
        } else if (type === 'table') {
            AddModal(Id + "table",`Table`,"TableModal",data);
        }
        
    }

    window.Re_GetVariableInfoList_show = (_VariableInfoList) =>{
        const {type: Result, data} = _VariableInfoList;

        if(Result === 'success'){
            const sendMsg = data[0]['child'].flatMap(_table => {
                return _table['child'].map(_variable => {
                    const [, tag] = _variable['title'].split(' ')[1].split('.');
                    const {table_ID: TABLE_ID}= _Modal.data;
                    return { TABLE_ID, tag };
                });
            });

            setSendMsg(sendMsg);
            GetTagInfo(sendMsg);
        }else{
            window.AddAlertMsg({type:'error', msg:`Interface DB 응답 failed : ${data}`})
        }
    }

    return(
        <div style={{'height' : '100%', 'margin': '0'}}>
            <PopupHeader>
                <ChartTitle className='title'>Table Value_{posts?.length || 0}개</ChartTitle>
                <BtnGhost label="Refresh" type='sm' onClick={(e)=>GetTagInfo(sendMsg)}/>
            </PopupHeader>
            <ShowGlobalTable>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className='ioType'>ioType</th>
                                <th className='type'>Type</th>
                                <th className='variableName'>Variable Name</th>
                                <th className='value'>Value</th>
                                <th className='db_table'>DB Table</th>
                                <th className='tag'>Name</th>
                                <th className='description'>Desc</th>
                                <th className='chart'>chart</th>
                                <th className='table'>table</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts &&
                                currentPosts.map((_valueIndex, index) =>{
                                    const {DB_TABLE_NAME, TAG, DESCRIPTION, VALUE, DATA_TYPE, IO_TYPE, VARIABLE_NAME} = _valueIndex;
                                    const item = [
                                        _valueIndex.TABLE_ID + '\\' +
                                        DB_TABLE_NAME + '\\' +
                                        DATA_TYPE + '\\' +
                                        TAG + '\\' +
                                        DESCRIPTION + '\\'
                                    ];
                                    return (
                                        <tr key={index}>
                                            <td className='ioType' title={IO_TYPE}>{IO_TYPE}</td>
                                            <td className='type' title={DATA_TYPE}>{DATA_TYPE}</td>
                                            <td className='variableName' title={VARIABLE_NAME}>{VARIABLE_NAME}</td>
                                            <td className='value' title={VALUE}>{VALUE}</td>
                                            <td className='table' title={DB_TABLE_NAME}>{DB_TABLE_NAME}</td>
                                            <td className='tag' title={TAG}>{TAG}</td>
                                            <td className='description' title={DESCRIPTION}>{DESCRIPTION}</td>
                                            <td className='chart' title="차트 보기"><MdInsertChartOutlined onClick={(e)=> addShowModal(e,item,'chart')}/></td>
                                            <td className='table' title="테이블 보기"><MdTableChart onClick={(e)=> addShowModal(e,item,'table')}/></td>
                                        </tr>
                                    )
                                })
                            }

                            {!posts &&
                                <SkeletonTable col={9} row={100} />
                            }
                        </tbody>
                    </table>
                </div>    
            </ShowGlobalTable>
            <Pagination _dataList={posts} _currentPage={[currentPage, setCurrentPage]} _setCurrentPosts={setCurrentPosts}/>
        </div>
    )
}

export default ShowGlobalVariableDB;

//스타일-----------------------------------------------
const PopupHeader = styled.div`
    display: flex;
    justify-content: space-between;
    height: 38px;
    align-items: flex-end;
    
    &>button {
        margin-bottom: 8px;
    }
`;

const ShowGlobalTable = styled(TableContent)`
    height: calc(100% - 74px);
    
    tr{
        cursor: default !important;
    }

    td{
        user-select: text;
    }

    .selectTable{
        background-color:${({theme}) => theme.base.background_color_LightBlue};
    }

    .type, .ioType {
        width: 64px;
        text-align: center;
    }

    .db_table{
        width: 12%;
        text-align: center;
    }

    .variableName {
        width: 20%;
        text-align: center;
    }

    .tag {
        width: 18%;
        text-align: center;
    }

    .value {
        width: 8%;
    }

    .description {
        width:10%;
    }

    .chart{
        width:52px;
        text-align:center;
    }

    .chart * {
        font-size:1.5rem;
        
    }

    .table{
        width:52px;
        text-align:center;
    }

    .table * {
        font-size:1.5rem;
        
    }
    
    tbody > tr > .chart, .table > * {
        color: ${({ theme }) => theme.base.font_color_Blue};
        cursor:pointer;
    }
`;