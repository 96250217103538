
import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";
import { GroupName } from 'components/css/common';

import PropTypes from 'prop-types'
import styled from "styled-components";

function TransformInputList({_data, _ChangeEvent, _disabled}){
    const {
        translate_x: NodeTranslateX, translate_y: NodeTranslateY,
        scale_x: NodeScaleX, scale_y: NodeScaleY,
        center_x: NodeCenterX, center_y: NodeCenterY,
        perspective_x: NodePerspectiveX, perspective_y: NodePerspectiveY,
        rotate: NodeRotate
    } = _data;

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };
    
    return(
        <PropertiesContent _Title={"Transform"}>
            <div>
                <GroupName width='74'>translate</GroupName>
                <InputComponent label='X' attributeName='translate-x' labelWidth='24px' valueType='number' value={NodeTranslateX} {...commonProps}/>
                <InputComponent label='Y' attributeName='translate-y' labelWidth='24px' valueType='number' value={NodeTranslateY} {...commonProps}/>
            </div>
            <div>
                <GroupName width='74'>scale</GroupName>
                <InputComponent label='X' attributeName='scale-x' labelWidth='24px' valueType='number' value={NodeScaleX} {...commonProps}/>
                <InputComponent label='Y' attributeName='scale-y' labelWidth='24px' valueType='number' value={NodeScaleY} {...commonProps}/>
            </div>
            <div>
                <GroupName width='74'>center</GroupName>
                <InputComponent label='X' attributeName='center-x' labelWidth='24px' valueType='number' value={NodeCenterX} {...commonProps}/>
                <InputComponent label='Y' attributeName='center-y' labelWidth='24px' valueType='number' value={NodeCenterY} {...commonProps}/>
            </div>
            {(NodeRotate!== undefined ) &&
                <div>
                    <GroupName width='74'>rotate</GroupName>
                    <InputComponent label='°' attributeName='rotate' labelWidth='24px' valueType='number' value={NodeRotate} {...commonProps}/>
                    <BtnContent> </BtnContent> 
                </div>
            }
            <div>
                <GroupName width='74'>perspective</GroupName>
                <InputComponent label='X' attributeName='perspective-x' labelWidth='24px' valueType='number' value={NodePerspectiveX} {...commonProps}/>
                <InputComponent label='Y' attributeName='perspective-y' labelWidth='24px' valueType='number' value={NodePerspectiveY} {...commonProps}/>
            </div>
        </PropertiesContent>
    )
}

export default TransformInputList;

TransformInputList.propTypes = {
    _data: PropTypes.object,
    _KeyDownEvent: PropTypes.func
}

// 스타일-----------------------------------------------------
const BtnContent = styled.div`
    width: 184px;
`;