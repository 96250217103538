
import {useState, useEffect} from "react";

import TabBar from "components/molecule/TabBar";
import PropertiesListContent from "components/organism/PropertiesListContent";
import EventInfoContent from "components/organism/EventInfoContent";
import VariableContent from "components/organism/VariableContent";
import ResizeDiv from "components/atom/ResizeDiv";

import { useSelector } from 'react-redux';

const MENU = {
    PROPERTIES: 'Properties',
    EVENT: 'Event',
    VARIABLE: 'Variable',
}

export default function NodeInfoContent({enuSpace}){
    const {
        type: NodeType, path: NodePath, object_name: NodeObjectName, isMultiNode
    } = useSelector(state => state.selectNodeData);     
    const [showTab, setShowTab] = useState();
    const [tabMenuList, setTabMenuList] = useState([MENU.PROPERTIES, MENU.EVENT, MENU.VARIABLE]);

    useEffect(()=>{
        setShowTab(MENU.PROPERTIES)
    }, []);

    useEffect(()=>{
        if(isMultiNode){
            setShowTab(MENU.PROPERTIES);
            setTabMenuList([MENU.PROPERTIES]);
            return;
        }

        if(!(['svg', 'symbol', 'use'].includes(NodeObjectName) || ['struct', 'attribute', 'symbol'].includes(NodeType))){
            setShowTab(prv => prv === MENU.VARIABLE ? MENU.PROPERTIES : prv)
            setTabMenuList([MENU.PROPERTIES, MENU.EVENT]);
        }else{
            setTabMenuList([MENU.PROPERTIES, MENU.EVENT, MENU.VARIABLE]);
        }

        if (NodePath?.[0] === 'global' && ['struct', 'attribute', 'a.d'].includes(NodeType)) {
            setShowTab(MENU.VARIABLE)
        }
    }, [NodeType, NodeObjectName, NodePath, isMultiNode]);

    return(
        <ResizeDiv enuSpace={enuSpace} _pos={'left'} _width={250}>
            <TabBar 
                _selectedMenu={showTab} 
                _MenuList={tabMenuList} 
                onClick={(e)=>setShowTab(e.target.textContent)}
            />

            {showTab === MENU.PROPERTIES && <PropertiesListContent enuSpace={enuSpace} />}
            {showTab === MENU.EVENT      && <EventInfoContent enuSpace={enuSpace} />}
            {showTab === MENU.VARIABLE   && <VariableContent enuSpace={enuSpace}/>}
        </ResizeDiv>
    )
}