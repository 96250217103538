import Axis from "babylon/class/Axis"
// import EnuGUIRectangle from "./EnuGUIRectangle";
import { SUPPORT_FUNCTION } from 'babylon/common/Enu3dEnum'
import Material from "babylon/class/Material";
import { Mesh } from "@babylonjs/core";

export default class Base {
    //TODO: Base -> Camera, Light Inheritance
    constructor(EnuScene, object, requiresNewMaterial = true) {
        // scene에 있는 object 정보를 가져와서 저장
        // object.metadata = {};

        this._originMesh = object;
        this.uniqueId = object.uniqueId;
        this.onTaskId = null; //requestAnimationFrame 제거를 위한 id

        // 객체 개별 Material 필요 표시 flag: requiresNewMaterial
        if (requiresNewMaterial) {
            const materialName = object.name + "_" + object.uniqueId;
            const newMaterial = new Material(EnuScene, materialName);
            this.material = newMaterial;
        } else {
            this._material = "";
        }

           

        this._id = object.id;
        this._name = object.name;
        this._position = new Axis(object, object.position, "position");
        this._scaling = new Axis(object, object.scaling, "scaling");
        this._rotation = new Axis(object, object.rotation, "rotation");
        this._billboardMode = Mesh.BILLBOARDMODE_NONE
        this._parent = null;
        this.event = {};
        this.visibility = 1;

        //TODO: 다수의 Mesh가 포함된 경우 고려해 수정할 것.
        // if (typeof object.subMeshes !== 'undefined') {
        //     console.log("Undefined. ", object.subMeshes);
        //     object.subMeshes.forEach((subMesh) => {new Custom(EnuScene, subMesh);})
        // }
        if (!object.parent) {
            EnuScene.addChildToRootNode(object);
            this.parent = "Root"
        } else {
            this._originMesh.parent = EnuScene.getObjectById(object.parent);
            this.parent = object.parent;
        }
        EnuScene.addObjectToMap(object.uniqueId, this);
    }

    // addGUIPanel() {
    //     const rect = new EnuGUIRectangle(EnuScene);
    //     EnuGUIRectangle.linkToMesh(this.originMesh);

    // }

    set parent(parent) { this._parent = parent; }
    get parent() { return this._parent;  }

    set material(newMaterial) {
        if (newMaterial instanceof Material) {
            this._material = newMaterial.id;
            this._originMesh.material = newMaterial.material;
        }
        // else {
        //     throw new Error("Invalid material provided");
        // }
    }
    get material() { return this._material; }

    set visibility(_visibility) {
        if (_visibility > 1) {
            _visibility = 1;
        }
        this._visibility = _visibility;
        this.originMesh.visibility = _visibility;
    }
    get visibility() { return this._visibility; }

    set billboardMode(billboardMode) {
        this._billboardMode = billboardMode;
        this.originMesh.billboardMode = billboardMode;
    }
    get billboardMode() { return this._billboardMode; }

    set originMesh(originMesh) { this._originMesh = originMesh; }
    get originMesh() { return this._originMesh; }

    set id(_id) {
        this._id = _id;
        this.originMesh.id = _id;
    }
    get id() { return this._id; }

    set name(_name) {
        this._name = _name;
        this.originMesh.name = _name;
    }
    get name() { return this._name;}

    set onTaskId(_onTaskId) { this._onTaskId = _onTaskId; }
    get onTaskId() { return this._onTaskId; }

    get position() { return this._position;}
    set position(position) {
        const { x, y, z } = position;
        this.position.x = x;
        this.position.y = y;
        this.position.z = z;
    }

    get scaling() { return this._scaling; }
    set scaling(scaling) {
        const { x, y, z } = scaling;
        this.scaling.x = x;
        this.scaling.y = y;
        this.scaling.z = z;
    }

    get rotation() { return this._rotation; }
    set rotation(rotation) {
        const { x, y, z } = rotation;
        this.rotation.x = x;
        this.rotation.y = y;
        this.rotation.z = z;
    }

    getOriginMesh() { return this.originMesh; }

    switchWireframeMode(EnuScene) {
        EnuScene.getMaterialById(this.material).switchWireframeMode();
    }

    getPropertyMap() {
        return {
            id: this.id,
            name: this.name,
            position: {
                x: this.position.x,
                y: this.position.y,
                z: this.position.z,
            },
            scaling: {
                x: this.scaling.x,
                y: this.scaling.y,
                z: this.scaling.z,
            },
            rotation: {
                x: this.rotation.x,
                y: this.rotation.y,
                z: this.rotation.z,
            },
            event: this.event,
            material: this.material,
            visibility: this.visibility,
            billboardMode: this.billboardMode,
            parent: this.parent,
            //TODO: DataController Error
            // material: {
            //     diffuseColor: this.material.diffuseColor,
            // //     alpha: this.material.alpha
            // },
        };
    }

    addEvent(eventName, eventScript, callback) {
        if (!eventName || !eventScript) {
            if (callback) {
                callback("eventName or eventScript is not defined");
            } else {
                throw new Error("eventName or eventScript is not defined");
            }
        }

        if (!Object.values(SUPPORT_FUNCTION).includes(eventName)) {
            if (callback) {
                callback("eventName is not supported");
            } else {
                throw new Error("eventName is not supported");
            }
        }

        try {
            // FIXME: 보안상 문제가 있음 -> 다른 바람직한 방법을 찾아야함
            // eslint-disable-next-line
            const _onclick = new Function("self", eventScript);
            _onclick(this.originMesh);
        } catch (e) {
            if (callback) {
                callback(e);
            } else {
                throw new Error(e);
            }
        }

        this.event = { ...this.event, [eventName]: eventScript };
    }

    removedEvent(eventName, callback) {
        if (!eventName) {
            if (callback) {
                callback("eventName is not defined");
            } else {
                throw new Error("eventName is not defined");
            }
        }

        this.event = { ...this.event };
        delete this.event[eventName];
    }

    RunEvent(scene, eventScript) {
        // function 내부의 코드만 추출
        const scriptInside = eventScript.slice(
            eventScript.indexOf("{") + 1,
            eventScript.lastIndexOf("}")
        );

        // FIXME: 보안상 문제가 있음 -> 다른 바람직한 방법을 찾아야함
        // eslint-disable-next-line
        const onEvent = new Function("scene", "self", scriptInside);
        onEvent(scene, this);
    }

    CallOnClick(EnuScene) {
        const onEvent = this.event?._onclick;

        if (!onEvent) {
            throw new Error("_onclick is not defined");
        }
        // 런타임 모드가 아닐때는 실행하지 않음
        if (!EnuScene.isRunTime) return;

        this.RunEvent(EnuScene, onEvent);
    }

    CallOnLoad(EnuScene) {
        const onEvent = this.event?._onload;
        if (!onEvent) {
            throw new Error("_onload is not defined");
        }

        this.RunEvent(EnuScene, onEvent);
    }

    CallOnMouseDown(EnuScene) {
        const onEvent = this.event?._onmousedown;

        if (!onEvent) {
            throw new Error("_onmousedown is not defined");
        }
        // 런타임 모드가 아닐때는 실행하지 않음
        if (!EnuScene.isRunTime) return;

        this.RunEvent(EnuScene, onEvent);
    }

    CallOnTaskview(EnuScene) {
        const onEvent = this.event?._ontaskview;
        if (!onEvent) {
            throw new Error("_ontaskview is not defined");
        }

        const render = () => {
            // 런타임 모드가 아닐때는 실행하지 않음
            if (EnuScene.isRunTime) {
                this.RunEvent(EnuScene, onEvent);
            }

            this.onTaskId = requestAnimationFrame(render);
            // this.onTaskId = setInterval(render, 5000)
        };
        requestAnimationFrame(render);
        // setInterval(render, 5000);
    }
}
