import axios from 'axios';
import { useNavigate} from 'react-router-dom';

import {Axios, AccessAxios} from 'components/Axios';

export default function useMember(){
    const navigate = useNavigate();
        /**
     * 로그인 처리
     * @param {string} _email 이메일정보
     * @param {string} _pw  비밀번호
     * @returns 토큰 및 사용자 정보
     */
    const Login = async (_email, _pw) => {
        const MSG = {
            email: _email,
            pw: _pw
        }
        try {
            const res = await Axios.post('/api/Auth/Login', MSG);
            const responseData = res['data'];
            
            if(responseData['result'] === 'Fail'){
                return res;
            }

            return res;
        } catch (err) {
            throw err;
        }
    }

    /**
     * 로그아웃 처리
     */
    const Logout = async() => {
        try{
            await AccessAxios.post('/api/Auth/Logout'); 
        }catch(err){
            throw err;
        }finally{
            localStorage.clear();
            delete axios.defaults.headers.common['Authorization']
            navigate('/');
        }
    }

    return { Login, Logout};
}

/**
 * 토큰 재발급
 * @returns 
 */
export const refreshAccessToken = async () => {
    const RefreshToken = localStorage.getItem('refreshtoken') || sessionStorage.getItem('refreshtoken');
    try {
        // TODO: 추후 추가 CLIENT_KEY
        const res = await Axios.post('/api/Auth/Reissue', {CLIENT_KEY:''}, { 
            headers: { 'Content-Type': 'application/json', RefreshToken } 
        });
        const token = res.headers.authorization;
        localStorage.setItem('Authorization', token);
        localStorage.setItem('refreshtoken', res.headers.refreshtoken); 
        axios.defaults.headers.common['Authorization'] = token;
        return res;
    } catch (err) {
        throw err;
    }
};

export const CheckAccessToken = async (Authorization) => {
    try {
        const res = await Axios.post('/api/Auth/CheckAccessToken', null, {
            headers: { 'Content-Type': 'application/json', Authorization } 
        });
        return res;
    } catch (err) {
        throw err;
    }
};

/**
 * 회원 가입
 * @param {string} membername   - 회원명
 * @param {string} nickname     - 닉네임    
 * @param {string} email        - 이메일
 * @param {string} pw           - 비밀번호    
 * @returns 
 */
export const CreateAccount = async (membername, nickname, email, pw) => {
    const MSG = {
        membername,
        nickname,
        email,
        pw
    }

    try {
        const res = await Axios.post('/api/Auth/CreateAccount', MSG);
        return res;
    } catch (err) {
        throw err;
    }
};

/**
 * 회원 가입 (No Verify)
 * @param {string} membername   - 회원명
 * @param {string} nickname     - 닉네임    
 * @param {string} email        - 이메일
 * @param {string} pw           - 비밀번호    
 * @returns 
 */
export const CreateAccountNoVerify = async (membername, nickname, email, pw) => {
    const MSG = {
        membername,
        nickname,
        email,
        pw
    }

    try {
        const res = await Axios.post('/api/Auth/CreateAccountNoVerify', MSG);
        return res;
    } catch (err) {
        throw err;
    }
};

/**
 * 회원 정보 수정
 * @param {string} MEMBER_SYSTEMID  - 회원ID
 * @param {string} MEMBERNAME       - 회원명
 * @param {string} NICKNAME         - 닉네임 
 * @returns 
 */
export const UpdateAccount = async (MEMBER_SYSTEMID, MEMBERNAME, NICKNAME) => {
    const MSG = {MEMBER_SYSTEMID,MEMBERNAME,NICKNAME}
    try {
        const res = await AccessAxios.post('/api/Auth/UpdateAccount', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 사용가능한 이메일 확인
 * @param {string} EMAIL 
 * @returns 인증 코드
 */
export const CheckEmail = async (EMAIL) => {
    const MSG = { EMAIL }
    try {
        const res = await Axios.post('/api/Auth/CheckEmail', MSG);
        return res;
    } catch (err) {
        throw err;
    }
};

/**
 * 이메일 인증 확인
 * @param {string} EMAIL 
 * @param {string} CODE 
 * @returns 
 */
export const CheckValidationEmail = async (EMAIL, CODE) => {
    const MSG = { EMAIL, CODE }
    try {
        const res = await Axios.post('/api/Auth/CheckValidationEmail', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 비밀번호를 reset 한다.
 * @param {string} EMAIL 
 * @returns 
 */
export const ResetPassword = async (EMAIL) => {
    const MSG = { EMAIL }
    try {
        const res = await Axios.post('/api/Auth/ResetPassword', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 비밀번호를 변경한다.
 * @param {string} MEMBER_SYSTEMID  - 회원ID
 * @param {string} BEFORE_PW        - 이전 비밀번호
 * @param {string} AFTER_PW         - 변경할 비밀번호
 * @returns 
 */
export const UpdatePassword = async (MEMBER_SYSTEMID, BEFORE_PW, AFTER_PW) => {
    const MSG = { MEMBER_SYSTEMID, BEFORE_PW, AFTER_PW }
    try {
        const res = await AccessAxios.post('/api/Auth/UpdatePassword', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 입력한 이메일로 인증 코드를 발송한다.
 * @param {string} EMAIL 
 * @returns  인증 코드
 */
export const SendValidationCode = async (EMAIL) => {
    const MSG = { EMAIL }
    try {
        const res = await Axios.post('/api/Auth/SendValidationCode', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 비밀번호 확인
 * @param {string} MEMBER_SYSTEMID 
 * @param {string} PW 
 */
export const CheckPW = async (MEMBER_SYSTEMID, PW) => {
    const MSG = { MEMBER_SYSTEMID, PW }
    try {
        const res = await AccessAxios.post('/api/Auth/CheckPW', MSG);
        return res;
    } catch (err) {
        throw err;
    }
};

/**
 * 회원 탈퇴
 * @param {string} MEMBER_SYSTEMID  - 회원명
 * @param {string} pw               - 비밀번호
 */
export const Withdrawal = async (MEMBER_SYSTEMID, pw) => {
    const MSG = { MEMBER_SYSTEMID, pw }
    try {
        const res = await AccessAxios.post('/api/Auth/Withdrawal', MSG);
        return res;
    } catch (err) {
        throw err;
    }
};

/**
 * 회원 정보를 가져온다.
 * @param {string} MEMBER_SYSTEMID  - 회원명
 */
export const GetMyInfo = async (MEMBER_SYSTEMID) => {
    const MSG = { MEMBER_SYSTEMID }
    try {
        const res = await AccessAxios.post('/api/Auth/GetMyInfo', MSG);
        return res;
    } catch (err) {
        throw err;
    }
};


/**
 * 회원 profile 이미지 업로드
 * @param {string} MEMBER_SYSTEMID 
 * @param {*} profilE_IMAGE 
 */
export const UploadProfileImage = async (MEMBER_SYSTEMID, profilE_IMAGE) => {
    if(profilE_IMAGE === '')
        return;

    let formData = new FormData();
    Array.from(profilE_IMAGE).forEach(file => {
        formData.append("profilE_IMAGE", file);
    })
    formData.append("MEMBER_SYSTEMID",  MEMBER_SYSTEMID);
    
    try {
        const res = await AccessAxios.post('/api/Auth/UploadProfileImage', formData);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 회원 유무를 확인한다.
 * @param {string} EMAIL - 사용자 이메일
 */
export const CheckUser = async (EMAIL) => {
    const MSG = { EMAIL }
    try {
        const res = await AccessAxios.post('/api/Auth/CheckUser', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 구글 클라이언트 아이디를 가져온다.
 */
export const GetClientId = async () => {
    try {
        const res = await Axios.post('/api/Google/GetClientId');
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 구글 로그인
 * @param {string} CREDENTIAL - 구글 로그인 정보
 * @param {string} CLIENTID   - 구글 클라이언트 아이디
 * @param {string} SELECT_BY  - 구글 로그인 유형
 */
export const LoginWidthGoogle = async (CREDENTIAL, CLIENTID, SELECT_BY) => {
    const MSG = { CREDENTIAL, CLIENTID, SELECT_BY }
    try {
        const res = await Axios.post('/api/Google/Login', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 구글 회원 가입
 * @param {string} MEMBERNAME - 회원명
 * @param {string} NICKNAME   - 닉네임
 * @param {string} EMAIL      - 이메일
 * @param {string} PW         - 비밀번호
 * @param {string} CREDENTIAL - 구글 로그인 정보
 * @param {string} CLIENTID   - 구글 클라이언트 아이디
 * @param {string} SELECT_BY  - 구글 로그인 유형
 */
export const CreateAccountWidthGoogle = async (MEMBERNAME, NICKNAME, EMAIL, PW, CREDENTIAL, CLIENTID, SELECT_BY) => {
    const MSG = { MEMBERNAME, NICKNAME, EMAIL, PW, CREDENTIAL, CLIENTID, SELECT_BY}
    try {
        const res = await Axios.post('/api/Google/CreateAccount', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}

/**
 * 게스트 로그인, 게스트용 토큰 발행
 * @param {string} NICKNAME 
 * @returns 
 */
export const GuestLogin = async (NICKNAME) => {
    const MSG = { NICKNAME }
    try {
        const res = await Axios.post('/api/Auth/GuestLogin', MSG);
        return res;
    } catch (err) {
        throw err;
    }
}