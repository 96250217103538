import { useState ,useEffect} from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';

export default function BtnToggle({_toggleID, _isToggleValue, _disabled, _IsToggle, _ToggleEventHandler}){
    const [isToggle, setIsToggle] = useState(false);

    useEffect(() => {
        const updateToggle = async () => {
            await setIsToggle(_isToggleValue === '1' || _isToggleValue === true || _isToggleValue === 'true');
        };
        if (_isToggleValue !== undefined) 
            updateToggle();
    }, [_isToggleValue]);

    const IsValue = async(e) => {
        e.stopPropagation();
        // TODO: 240314 - LABEL 내용 확인 필요 문제 없을 시 제거
        // const targetId = e.target.nodeName === 'LABEL' ? e.target.id : e.target.parentNode.id;
        const targetId = _toggleID;

        if (_disabled) return;

        if (_IsToggle) {
            setIsToggle(prevToggle => {
                const newToggle = !prevToggle;
                _IsToggle(targetId, newToggle ? 'true' : 'false');
                return newToggle;
            });
        }
        
        if (_ToggleEventHandler) {
            const Result = await _ToggleEventHandler();
            setIsToggle(Result ? !isToggle : isToggle);
        }
    }

    return(
        <ToggleContent>
            <input type="checkbox" id={_toggleID} name={_toggleID} checked={isToggle} disabled={_disabled} onChange={(e)=>{}} hidden></input>
            <label htmlFor={_toggleID} title={_toggleID} onClick={IsValue}>
                <span></span>
            </label>
        </ToggleContent>
    )
}

BtnToggle.propTypes = {
    _toggleID: PropTypes.string                    //input 클릭시 연결을 위한 ID
}

// 스타일-----------------------------------------------------
const ToggleContent = styled.span`
    &>input:disabled ~ label{
        cursor: default;
        opacity: .3;
    } 

    &>label {
        width: 28px;
        height: 16px;
        display: block;
        position: relative;
        border-radius: 2rem;
        background-color: ${({theme}) => theme.base.background_color_Gray};
        cursor: pointer;

        &>span{
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            left: .2rem;
            transform: translateY(-50%);
            border-radius: 50%;
            background: #fff;
        }
    }

    // 체크박스가 체크되면 변경 이벤트
    input:checked ~ label {
        background: #64dd17;
    }
      
    input:checked ~ label span {
        left: calc(100% - 15px);
        background: #fff;
    }
      
    label, span {
        transition: all 0.1s ease-in;
    }
`;