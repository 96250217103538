import {useState, useEffect, useCallback, useRef}from 'react';

//모듈 로드
export default function useEnuSpaceModule(){
    const [enuSpace, setEnuSpace] = useState('');
    const timeoutIdRef = useRef(null);
    const [isFlagSet, setIsFlagSet] = useState(false);

    const getEnuSpaceModule = useCallback(async (Count = 0) => {
        await wasmCheck();
        if (window.g_enuSpace === undefined) {
            timeoutIdRef.current = setTimeout(() => {
                if (Count === 2) {
                    window.ReModalInit();
                }

                if (Count > 4) {
                    alert(`(모듈 로드 실패) 새로고침해 주세요.`);
                    return;
                }

                if (window.g_enuSpace === undefined) {
                    getEnuSpaceModule(++Count);
                } else {
                    setEnuSpace(window.g_enuSpace);
                    window.g_enuSpace.StartLoop();
                }
            }, 500);

            return () => clearTimeout(timeoutIdRef.current); // 반복 종료시 clearTimeout 호출
        } else {
            setEnuSpace(window.g_enuSpace);
            window.g_enuSpace.StartLoop();
        }

    }, []);

    useEffect(()=>{
        getEnuSpaceModule();
    }, [getEnuSpaceModule]);

    useEffect(()=>{
        return () =>{
            clearTimeout(timeoutIdRef.current);
        }
    }, [timeoutIdRef]);

    const StartLoop = () =>{
        if(enuSpace === '')
            return;
            
        enuSpace.StartLoop();
    }

    return [enuSpace, StartLoop]
}

function wasmCheck() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        console.log("Checking window.g_wasm...");
        if (window.g_wasm) {
          clearInterval(interval); // 조건이 충족되면 interval을 정리하여 반복을 멈춤
          console.log("g_wasm is true, resolving...");
          resolve(true); // 조건이 충족되었을 때 true를 반환
        }
      }, 1000); // 1초마다 확인
    });
  }