import { useState, useEffect } from 'react';

import BoardContent from 'components/organism/BoardContent'
import BoardDetail from 'components/organism/BoardDetail'
import {GetArchiveProjectInfo, GetArchiveMemberAccess} from "api/ArchiveApi"
import Notification from 'components/atom/Notification'
import GuestNameInput from "components/organism/GuestNameInput";
import {AUTHORITY_TYPE } from "components/common/EnuSpaceEnum";

import { useLocation } from 'react-router-dom'
import styled from 'styled-components';

export default function LinkBoard(){
    const [MyAccess, setMyAccess] = useState();
    const [BaseInfo, setBaseInfo] = useState({});

    // 닉네임 입력 박스
    const [isInputBox, setIsInputBox] = useState({ visibility: false, userNickname: '', });

    useEffect(()=>{
        const systemId = localStorage.getItem('systemId') || sessionStorage.getItem('systemId');

        if(systemId === null || systemId === undefined){
            setIsInputBox(prevIsInputBox => ({ ...prevIsInputBox, visibility: true }));
        }else{
            setBaseInfo({userId: systemId})
        }
    }, []);

    useEffect(()=>{
        return ()=>{
            if(isInputBox.userNickname !== ''){
                sessionStorage.removeItem('Authorization');
                sessionStorage.removeItem('refreshtoken');
                sessionStorage.removeItem('systemId');
                sessionStorage.removeItem('nickname');
            }
        }
    }, [isInputBox]);
    
    const location = useLocation();
    useEffect(() => {
        if(!BaseInfo?.userId)
            return;

        const Search = location.search;
        const searchParams = new URLSearchParams(Search);

        const pageId = searchParams.get('border');
        const postIndex= searchParams.get('num');
        const keyword= searchParams.get('keyword');
        const page = searchParams.get('page');

        GetArchiveProjectInfo(pageId)
            .then((res) => {
                const {result, data, msg} = res.data;
                if(result === 'Success'){
                    setBaseInfo(prev=>({
                        ...prev,
                        email: localStorage.getItem('email'),
                        pageId: pageId,
                        pageName: data.ARCHIVE_NAME,
                        postIndex: postIndex,
                        publishType: data.PUBLISH_MODE,
                        description: data.DESCRIPTION,
                        keyword,
                        page,
                    }))
                }else{
                    window.AddAlertMsg({type:'error', msg:`${msg}`});
                }
            })
    }, [ BaseInfo.userId, location]);

    const LoadGrantedFriends = (pageId, userId) =>{
        GetArchiveMemberAccess(pageId, userId)
            .then((res) => {
                const { result, data } = res.data;
                if (result === 'Success') {
                    // 접속자의 권한정보를 setMyAccessData 저장
                    const MyInfo = data.find((e)=>e.MEMBER_SYSTEMID === userId);
                    let authority = AUTHORITY_TYPE.NONE;
                    if(MyInfo){
                        authority = MyInfo?.AUTHORITY;
                    }
                    
                    setMyAccess(authority);
                }
            })
    }

    useEffect(()=>{
        if(BaseInfo.pageId == null || BaseInfo.pageId === undefined) return;
        if(BaseInfo.userId == null || BaseInfo.userId === undefined) return;
        LoadGrantedFriends(BaseInfo.pageId, BaseInfo.userId);
    }, [BaseInfo.pageId, BaseInfo.userId]);

    const ActiveContent = () =>{
        if(BaseInfo.postIndex ){
            return <BoardDetail BaseInfo={BaseInfo} MyAccess={MyAccess}/>
        }else if(BaseInfo !== 'fail'){
            return <BoardContent BaseInfo={BaseInfo} MyAccess={MyAccess} IsLinkPage={true}/>
        }else{
            return(
                <ErrorPage>
                    잘못된 접근입니다.
                </ErrorPage>
            )
        }
    }

    return (
        <>
            <Notification />
            <ViewContent>
                {ActiveContent()}
            </ViewContent>

            {isInputBox.visibility &&
                <GuestNameInput 
                    value={isInputBox.userNickname} 
                    onClickEvent={(guestName)=>{
                        setIsInputBox({userNickname: guestName,  visibility: false})
                        setBaseInfo({userId: guestName})
                    }}
                />
            }
        </>
    )
}

const ViewContent = styled.article`
    position: relative;
    flex: 1;
`;

const ErrorPage = styled.div`
    position: relative;
    width: 100%;
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.base.font_color_Black};
`;
