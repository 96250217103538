import React, { useState, useEffect, useCallback, useContext } from 'react';

import {EnuLogo, EnuSymbol} from 'components/common/EnuSpaceIcon'
import { BtnText} from "components/atom/Button";
import useMember from 'hooks/useMember';
import {GetFriendAlarmList, AcceptFriendRequest, RejectFriendRequest, CheckFriendResponse} from 'api/SnsApi';
import {UserInfoContext} from "context/UserInfoContext"

import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled from "styled-components";
import { MdOutlineLogout, MdManageAccounts, MdMenu, MdClose, MdNotifications, MdGroup} from "react-icons/md";
import { useResizeDetector } from 'react-resize-detector';

const NavItems = [
    { path: '/DB', label: 'Database' },
    { path: '/Canvas', label: 'Canvas' },
    { path: '/Archive', label: 'Archive' },
    { path: '/Board', label: 'Board' },
    { path: '/MyRepository', label: 'Repository' },
];

export default function MainHeader({_isFixed = false}){
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const {Logout} = useMember();

    const { width, ref } = useResizeDetector();
    const [isFixedHeader, setIsFixedHeader] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [AlarmList, setAlarmList] = useState([]);
    const [MobileMenu, setMobileMenu] = useState('');

    const { USER_INFO } = useContext(UserInfoContext);
    const {Authorization: token, systemId, nickname, email:userMail } = USER_INFO;
    const profileId = localStorage.getItem('profileId');

    useEffect(()=>{
        setIsMobile(width < 940 ? true : false);
    }, [width]);

    // 윈도우 스크롤 이벤트 등록
    useEffect(() => {
        const handleScroll = () =>{
            const scrollTop = document.getElementById('root')?.scrollTop;
            setIsFixedHeader(scrollTop > 0 ? true : false);
        }
        
        window.addEventListener('scroll', handleScroll, { capture: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // 모바일 메뉴 보이기
    const ShowMobileMenu = (MenuName) => setMobileMenu(MobileMenu === MenuName ? '' : MenuName);

    const GetAlarmList = useCallback(() => {
        if(systemId === null) return;

        GetFriendAlarmList(systemId)
            .then(res => {
                const {result, data} = res.data;
                if(result === 'Success'){
                    setAlarmList(data);
                }
            })
    }, [systemId]);

    // 알람 목록 가져오기
    useEffect(()=>{
        GetAlarmList();
        const intervalId = setInterval(() => {
            GetAlarmList();
        }, 60000);
    
        return () => {
            clearInterval(intervalId);
        };
    }, [currentUrl, GetAlarmList]);

    const DeleteAlarmList = (TargetIndex) => {
        if (TargetIndex === undefined) return;
        setAlarmList(prevAlarmList => prevAlarmList.filter((item, index) => index !== TargetIndex));
    }

    // 알람 타입에 따른 버튼 렌더링
    const RenderAlarmBtn = (BtnType, AlarmIndex, Req_UserMail) => {
        //MSG_TYPE
        //Request Friend: 0
        //Accept Friend: 1
        //Reject Friend: 2

        if(BtnType === 0){
            return (
                <>
                    <BtnText 
                        label='거절' 
                        style={{ color: 'red' }} 
                        onClick={(e) => {
                            RejectFriendRequest(Req_UserMail, userMail)
                                .then(res => {
                                    const {result} = res.data;
                                    if (result === 'Success') {
                                        DeleteAlarmList(AlarmIndex);
                                    }
                                })
                            }
                        } 
                    />
                    <BtnText 
                        label='수락' 
                        onClick={(e) => {
                            AcceptFriendRequest(Req_UserMail, userMail)
                                .then(res => {
                                    const {result} = res.data;
                                    if (result === 'Success') {
                                        DeleteAlarmList(AlarmIndex);
                                    }
                                })
                            }
                        } 
                    />
                </>
            )
        }else{
            return(
                <BtnText 
                    label='확인' 
                    style={{width:'100%'}} 
                    onClick={(e) => {
                        CheckFriendResponse(BtnType, systemId)
                            .then(res => {
                                const { result } = res.data;
                                if (result === 'Success') {
                                    DeleteAlarmList(AlarmIndex);
                                }
                            })
                        }
                    } 
                />
            )
        }
    }

    const NotLoginMenu = () => (
        <>
            <div>
                <Link to='/login'> Sign in </Link>
            </div>
            <button className="btn_login">
                <Link to='/signUp' > Sign up </Link>
            </button>
        </>
    )

    if(isMobile === false){
        return(
            <HeaderContent ref={ref} isFixedHeader={isFixedHeader || _isFixed}>
                <HeaderLayout>
                    <HeaderNavContent isFixedHeader={isFixedHeader || _isFixed}>
                        <span className="Nav_logo">
                            <Link to='/'> ENUSPACEMETA </Link>
                            <Link to='/' className='partner_logo'> <EnuLogo /> </Link>
                        </span>
                        <NavContent isFixedHeader={isFixedHeader || _isFixed}>
                            {/* token 여부로 로그인 상태 판단 */}
                            {token === null
                                ?
                                    <NotLoginMenu />
                                :
                                <>
                                    {/* 상단 Nav */}
                                    {NavItems.map((item) =>{
                                        const {label, path} = item;
                                        const ClassName = currentUrl === path ? 'SelectedLink' : '';
                                        return (
                                            <div className='Nav_normal' key={label}>
                                                <Link to={path} className={ClassName}>{label}</Link>
                                            </div>
                                        )
                                    })}

                                    {/* My menu */}
                                    <MyMenu isFixedHeader={isFixedHeader || _isFixed}>
                                        <span>{nickname}님</span>
                                        <img id='HeaderPreview' src={`/profile/${profileId}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                                        <div id='myMenu_normal'>
                                            <ul>
                                                <li onClick={(e) => navigate('/MyPage')}>
                                                    <MdManageAccounts style={{ 'color': 'steelblue' }} />My Page
                                                </li>
                                                <li onClick={(e) => navigate('/Groups')}>
                                                    <MdGroup style={{ 'color': 'steelblue' }} />Friend
                                                </li>
                                                <li onClick={Logout}>
                                                    <MdOutlineLogout style={{ 'color': 'steelblue' }} />Logout
                                                </li>
                                            </ul>
                                        </div>
                                    </MyMenu>

                                    {/* 알람 */}
                                    <AlarmBtn isFixedHeader={isFixedHeader || _isFixed}>
                                        <MdNotifications  onClick={(e)=>GetAlarmList()} />
                                        {AlarmList.length > 0 &&
                                            <div id='AlarmCount'>
                                                {AlarmList.length}
                                            </div>
                                        }
                                        {AlarmList.length > 0 &&
                                            <AlarmContent id='AlarmContent'>
                                                <ul>
                                                    {AlarmList.map((item, index) => {
                                                        const { REQUESTER_EMAIL, REQUEST_STAMP, MSG, MSG_TYPE } = item;
                                                        let AlarmMsg = MSG.replace('{sender}', `"${REQUESTER_EMAIL}" `);
                                                        const KeyValue = `${REQUESTER_EMAIL}_${REQUEST_STAMP}`;

                                                        return (
                                                            <li key={KeyValue}>
                                                                <div className='MsgInfo'>
                                                                    <p className='Msg'>{AlarmMsg}</p>
                                                                    <p className='Stamp'>{REQUEST_STAMP}</p>
                                                                </div>
                                                                <div className='btnSpace'>
                                                                    {RenderAlarmBtn(MSG_TYPE, index, REQUESTER_EMAIL)}
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </AlarmContent>
                                        }
                                    </AlarmBtn>
                                </>
                            }
                        </NavContent>
                    </HeaderNavContent>
                </HeaderLayout>
            </HeaderContent>
        )
    }else{
        // 모바일 화면
        return(
            <>
                <HeaderContent ref={ref} isFixedHeader={isFixedHeader || _isFixed}>
                    <HeaderLayout>
                        <HeaderNavContent isFixedHeader={isFixedHeader || _isFixed}>
                            <span className="Nav_logo Mobile">
                                <Link to='/' className='partner_logo'> <EnuSymbol /> </Link>
                            </span>
                            <NavContent isFixedHeader={isFixedHeader || _isFixed}>
                                {/* token 여부로 로그인 상태 판단 */}
                                {token === null
                                    ?
                                        <NotLoginMenu />
                                    :
                                    <>
                                        {/* My menu */}
                                        <MyMenu isFixedHeader={isFixedHeader || _isFixed} onClick={(e) => { ShowMobileMenu('myMenu'); e.stopPropagation() }}>
                                            <span>{nickname}님</span>
                                            <img src={`/profile/${profileId}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                                        </MyMenu>

                                        {/* 알람 */}
                                        <AlarmBtn isFixedHeader={isFixedHeader || _isFixed} onClick={(e) => { ShowMobileMenu('Alarm'); e.stopPropagation() }}>
                                            <MdNotifications onClick={(e) => GetAlarmList()} />
                                            {AlarmList.length > 0 &&
                                                <div id='AlarmCount'>
                                                    {AlarmList.length}
                                                </div>
                                            }
                                        </AlarmBtn>

                                        <button className="btn_menu" onClick={(e) => { ShowMobileMenu('NavMenu'); e.stopPropagation() }}>
                                            {MobileMenu === 'NavMenu' ? <MdClose /> : <MdMenu />}
                                        </button>
                                    </>
                                }
                            </NavContent>
                        </HeaderNavContent>
                    </HeaderLayout>
                </HeaderContent>

                {/* 모바일 메뉴 */}
                {MobileMenu === 'myMenu' &&
                    <MobileMenuSpace isFixedHeader={isFixedHeader || _isFixed}>
                        <ul onClick={(e) => setMobileMenu("")}>
                            <li onClick={(e) => navigate('/MyPage')}>My Page</li>
                            <li onClick={(e) => navigate('/Groups')}>Friend</li>
                            <li onClick={Logout}>Logout</li>
                        </ul>
                    </MobileMenuSpace>
                }

                {MobileMenu === 'NavMenu' &&
                    <MobileMenuSpace isFixedHeader={isFixedHeader || _isFixed}>
                        <ul onClick={(e) => setMobileMenu("")}>
                            {NavItems.map((item, index) => (
                                <li key={index} onClick={(e) => navigate(item.path)}>{item.label}</li>
                            ))}
                        </ul>
                    </MobileMenuSpace>
                }

                {MobileMenu !== '' &&
                    <BgLayer onClick={(e) => setMobileMenu("")} />
                }
            </>
        )
    }
}

//스타일-------------------------------------
const HeaderContent = styled.header`
    position: sticky;
    top: 0;
    height: 72px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    z-index: 12;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
    box-sizing: border-box;
    user-select: none;
    background-color: ${({isFixedHeader}) => isFixedHeader ? '#FFF' : 'transparent'};
    font-size: 1rem;

    a{
        color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.font_color_Black : '#FFF'};
        text-decoration: none;
    }
`;

const HeaderLayout = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;

    @media screen and (min-width:1024px) {
        max-width: 1200px;
    }

    @media screen and (min-width:940px) and (max-width:1023px) {
        padding-left: 16px;
        padding-right: 16px;
    }

    @media screen and (max-width: 940px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

const HeaderNavContent = styled.div`
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Nav_logo{
        display: flex;
        align-items: center;

        a{
            font-size: 1.5rem;
            font-weight: bold;

            &:first-child{ 
                padding-right: 16px;
            }
            &:last-child{ 
                padding-left: 16px; 
            }
        }

        .partner_logo{
            position: relative;
            display: inline-block;
            height: 34px;
            width: 172px;
            overflow: hidden;

            svg{
                padding-block: 6px;

                path{
                    fill: ${({isFixedHeader}) => isFixedHeader ? null : '#FFF'};
                }
            }

            &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 2px;
                opacity: 0.5;
                background-color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.background_color_Gray : '#FFF'};
            }
        }

        &.Mobile {
            a:first-child {
                padding-right: 0;
            }
            a:last-child {
                padding-left: 0;
            }

            .partner_logo{
                width: auto;
                &::after{
                    display: none;
                }
            }
        }
    }
`;

const NavContent = styled.nav`
    display: flex;
    align-items: center;
    gap: 24px;

    .SelectedLink{
        position: relative;
        width: 100%;
        
        &::after{
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: ${({theme}) => theme.base.background_color_Blue};
        }
    }

    &>button{
        font-size: 1rem;
        border-style: solid;
        border-width: 1px;
        cursor: pointer;
    }

    .btn_login {
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0;
        border-color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.border_color_Blue : 'RGBA(255, 255, 255, 0.5)'};
        background-color: ${({isFixedHeader, theme}) => isFixedHeader ? 'transparent' : 'transparent'};

        &:hover{
            background-color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.background_color_LightBlue : 'transparent'};
            border-color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.border_color_Blue : 'RGBA(255, 255, 255, 1)'};
        }

        &>a {
            display: block;
            padding: 8px 16px;
        }
    }

    .btn_logout {
        border-radius: 4px;
        box-sizing: border-box;
        display: block;
        padding: 8px 16px;
    }
    
    .btn_menu{
        display: flex;
        height: 40px;
        width: 40px;
        border-radius: 4px;
        margin-left: 24px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        border-color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.border_color_Blue : 'RGBA(255, 255, 255, 0.5)'};
        background-color: transparent;
    
        svg{
            font-size: 1.5rem;
            color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.font_color_Black : '#fff'};
        }
    }
`;

const MyMenu = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.font_color_Black : '#FFF'};

    >span{
        padding-right: 8px;
        display: inline-block;
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.6;
    }

    &:hover>#myMenu_normal{ display: block; }

    #myMenu_normal{
        display: none;
        position: absolute;
        top: 40px;
        width: 110px;
        height: 100px;

        ul{
            position: absolute;
            top: 18px;
            left: 0;
            width: 110px;
            padding: 4px;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
            overflow: hidden;
            z-index:9;
            box-sizing: border-box;

            &>li{
                display: flex;
                width: 100%;
                padding: 8px;
                color: ${({theme}) => theme.base.font_color_Black};
                box-sizing: border-box;

                &:hover{
                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                }

                &>svg{
                    font-size: 1.2rem;
                    padding-right: 4px;
                }
            }
        }
    }

    &>img{
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        box-sizing: border-box;
        overflow: hidden;
        object-fit: cover;
        background-color: #fff;
        border-color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.border_color_Gray : '#FFF'};
    }
`;

const AlarmBtn = styled.div`
    position: relative;
    cursor: pointer;

    &:hover #AlarmContent{
        display: block;
    }

    &>svg{
        color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.font_color_Black : '#FFF'};
        font-size: 2rem;
    }

    #AlarmCount{
        font-size: 0.85rem;
        position: absolute;
        top: 0;
        right: 0;
        min-width: 24px;
        padding-bottom: 4px;
        text-align: center;
        transform: translate(50%, -25%);
        background-color: red;
        border-width: 2px;
        border-style: solid;
        border-color: ${({isFixedHeader, theme}) => isFixedHeader ? 'black' : '#FFF'};
        border-radius: 10px;
        color: #fff;
    }
`;

const AlarmContent = styled.div`
    display: none;
    position: absolute;
    top: 30px;
    width: 444px;
    right: 0px;
    cursor: default;
    height: 50px;

    ul{
        position: absolute;
        top: 28px;
        left: 0;
        width: 100%;
        max-height: 446px;
        min-height: 100px;
        padding: 8px 16px;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
        overflow: hidden;
        z-index:9;
        box-sizing: border-box;

        &>li{
            display: flex;
            width: 100%;
            padding: 16px 0;
            color: ${({theme}) => theme.base.font_color_Black};
            box-sizing: border-box;
            border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};

            .MsgInfo{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: calc(100% - 126px);
                gap: 8px;

                .Msg{
                    font-size: 0.95rem;
                    line-height: 1.4rem;
                    color: ${({theme}) => theme.base.font_color_Black};
                }

                .Stamp{
                    font-size: 0.9rem;
                    color: ${({theme}) => theme.base.font_color_DarkGray};
                }
            }

            .btnSpace{
                display: flex;
                align-items: center;
                width: 126px;
                z-index: 10;
            }
        }
    }
`;

const MobileMenuSpace = styled.div`
    position: sticky;
    width: 100%;
    height: 0;
    z-index: 12;
    top: ${({isFixedHeader}) => isFixedHeader ? '72px' : null};

    &>ul{
        padding-inline: 16px;
        padding-bottom: 8px;
        background-color: ${({isFixedHeader}) => isFixedHeader ? '#FFF' : '#090a0f'};
        height: ${({isFixedHeader}) => isFixedHeader ? 'calc(100vh - 72px)' : 'calc(100vh - 80px)'};
        box-sizing: border-box;

        li{
            font-size: 1rem;
            text-align: center;
            padding: 24px;
            cursor: pointer;
            color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.font_color_Black : '#fff'};
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.border_color_LightGray : 'rgba(255, 255, 255, 0.3)'};

            a{
                color: ${({isFixedHeader, theme}) => isFixedHeader ? theme.base.font_color_Black : '#fff'};
                text-decoration: none;
            }
        }
    }
`;

const BgLayer = styled.div`
    position: absolute;
    top: 72px;
    left: 0;
    width: 100%;
    height: calc(100vh - 72px);
    background-color: transparent;
    z-index: 10;
`;