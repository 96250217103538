import { useState, useEffect } from "react";
// import {LoadProject} from 'hooks/useLoadProject';
import { useSelector } from 'react-redux';
import styled from "styled-components";

export default function EnuBoard({enuSpace, _Modal}){
    const {userId, projectId, projectName} = useSelector(state => state.projectData.BASE_DATA);
    // const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [newEnuSpace, setNewEnuSpace] = useState(null);

    const newModule = () => window.NewModule();

    useEffect(()=>{
        const module = newModule();
        setNewEnuSpace(module);
    }, []);

    // const [newEnuSpace, setNewEnuSpace] = useState(null);

    useEffect(()=>{
        
        if(newEnuSpace === null || userId === undefined || projectId === undefined || projectName === undefined)
            return;

        // const loadProjectURL = `/Repository/${userId}/${projectId}/${projectName}.enup`;
        // LoadProject(newEnuSpace, loadProjectURL, setIsLoadingPage, null, `${_Modal.id}_box`);
    }, [newEnuSpace, userId, projectId, projectName, _Modal.id]);

    window.Re_SetView_RunTime = () =>{
        // const DrawHandler = enuSpace.GetDrawHandler("Canvas");
        // DrawHandler.SetWheelButton(false);

        // const MetaverseFlag = enuSpace.GetMetaverseFlag();
        // const autoFit = enuSpace.GetMetaverseViewFixedFlag();
        // const isMyRepositoryRuntimeMode = _BASE_DATA.currentURL === '/MyRepository/runtimeMode';
        // const isUserNicknameUndefined = _BASE_DATA.userNickname === undefined;
        // // const isMobileToggle = isMobile ? false : true;

        // // if(!MetaverseFlag || !isMyRepositoryRuntimeMode) return;
        // if(isMyRepositoryRuntimeMode || !MetaverseFlag){
        //     DAQ_connectionStart(enuSpace);
        //     return;
        // }

        // if(MetaverseFlag)
        //     setHeaderMenu({...headerMenu, useChatting: true, isChatting: true, isAutoFix: autoFit});
        
        // if(isUserNicknameUndefined)
        //     setIsInputBox(prevIsInputBox => ({ ...prevIsInputBox, visibility: true }));
        // else{
        //     INIT_ENTER_WORLD();
        //     DAQ_connectionStart(enuSpace);
        // }
    }

    // loadProjectURL = isInfoCheck ? `/Repository/${userId}/${projectId}/${projectName}.enup` : null;
    // LoadProject(enuSpace, loadProjectURL, setIsLoadingPage);

    return(
        <>
        <button
            onClick={(e)=>{
                console.log(newEnuSpace)
            }}
        >s1</button>
        <button
            onClick={(e)=>{
                // const loadProjectURL = `/Repository/${userId}/${projectId}/${projectName}.enup`;
                // LoadProject(newEnuSpace, loadProjectURL, setIsLoadingPage, null, `${_Modal.id}_box`);
                enuSpace.LoadProject();
            }}
        >s2</button>
        <BorderContent id={`${_Modal.id}_box`}>
        </BorderContent>
        </>
        
    )
}

const BorderContent = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;