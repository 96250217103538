import SpaceBackground from "components/atom/SpaceBackground";

import { Link } from 'react-router-dom';
import styled from "styled-components";

const ErrorPage = () =>{
    return(
        <main>
            <Msg>
                잘못된 접근입니다. <br />
                <Link to='/'>{`< 메인으로 가기 >`}</Link>
            </Msg>
            <SpaceBackground />
        </main>
    )
}

export default ErrorPage;

//스타일-------------------------------------------------
const Msg = styled.div`
    position: absolute;
    top: 47%;
    left: 50%;
    font-size: 3rem;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    z-index:9;

    a{
        color: #fff;
        font-size: 1.3rem;
        text-decoration: none;
        opacity: 0.6;

        &:hover{
            opacity: 1;
        }
    }
`;