import { configureStore } from '@reduxjs/toolkit';
import selectNodeDataReducer from 'reducers/selectNodeDataReducer'
import projectDataReducer from 'reducers/projectDataReducer'
import modulePopupReducer from 'reducers/modulePopupReducer'
import rightMenuSlice from 'reducers/rightMenuReducer'
import selectBabylonDataReducer from 'reducers/selectBabylonDataReducer'

const store = configureStore({
    reducer:{
        selectNodeData: selectNodeDataReducer,
        projectData: projectDataReducer,
        modalPopup: modulePopupReducer,
        rightMenu: rightMenuSlice,
        selectBabylonData: selectBabylonDataReducer,
    }
})

export default store;