import { useState, useCallback, useEffect } from "react";
import { useSelector } from 'react-redux';
import {AccessAxios} from 'components/Axios';
import { useLocation } from 'react-router-dom';

function useSQLite(){
    const {userId: MEMBER_SYSTEMID, projectId: PROJECT_SYSTEMID} = useSelector(state => state.projectData.BASE_DATA);
    const [SQLiteFileList, setSQLiteFileList] = useState([]);
    const location = useLocation();
    
    const GetSQLiteFileList = useCallback(() => {
        let MSG = {};
        if(location.pathname === '/Publish/runtimeMode' || location.pathname === '/Publish/link')
        {
            MSG={
                MEMBER_SYSTEMID:'Publish',
                PROJECT_SYSTEMID,
                path: 'sqlite',
                filE_EXT: ['db','sqlite','sqlitedb','sqlite3','db3'],
            }
        }
        else
        {
            MSG={
                MEMBER_SYSTEMID,
                PROJECT_SYSTEMID,
                path: 'sqlite',
                filE_EXT: ['db','sqlite','sqlitedb','sqlite3','db3'],
            }
        }

        AccessAxios.post('/api/SQLite/GetSQLiteFileList', MSG)
            .then(res => {
                setSQLiteFileList(res['data']['Data'][0]);
            }).catch(err => {
                throw err;
            })
    }, [MEMBER_SYSTEMID, PROJECT_SYSTEMID, location.pathname]);

    useEffect(() => {
        if(location.pathname === '/Publish/runtimeMode' || location.pathname === '/Publish/link' || location.pathname === '/MyRepository/editMode'){GetSQLiteFileList();}
    }, [GetSQLiteFileList]);

    /**
     * 
     * @param {string} FILE_NAME -sqlite 파일명
     * @param {string} QUERY    -쿼리문
     */
    const SelectSQLite = async (FILE_NAME, QUERY) => {
        let MSG = {};
        if(location.pathname === '/Publish/runtimeMode' || location.pathname === '/Publish/link')
        {
            MSG={
                MEMBER_SYSTEMID:'Publish',
                PROJECT_SYSTEMID,
                FILE_NAME,
                QUERY
            }
        }
        else
        {
            MSG={
                MEMBER_SYSTEMID,
                PROJECT_SYSTEMID,
                FILE_NAME,
                QUERY
            }
        }
        return await AccessAxios.post('/api/SQLite/SelectSQLite', MSG)
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }

    /**
     * 
     * @param {string} FILE_NAME -sqlite 파일명
     * @param {string} QUERY    -쿼리문
     */
    const InsertSQLite = async (FILE_NAME, QUERY) => {
        let MSG = {};
        if(location.pathname === '/Publish/runtimeMode' || location.pathname === '/Publish/link')
        {
            MSG={
                MEMBER_SYSTEMID:'Publish',
                PROJECT_SYSTEMID,
                FILE_NAME,
                QUERY
            }
        }
        else
        {
            MSG={
                MEMBER_SYSTEMID,
                PROJECT_SYSTEMID,
                FILE_NAME,
                QUERY
            }
        }
        return await AccessAxios.post('/api/SQLite/InsertSQLite', MSG)
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }


    /**
     * @param {string} FILE_NAME - 파일 경로(파일명 포함) 
     */
    const GetSQLiteTableList = async (FILE_NAME) => {        
        var MSG={ };
        if(location.pathname === '/Publish/runtimeMode' || location.pathname === '/Publish/link')
        {
            MSG={
                MEMBER_SYSTEMID:'Publish',
                PROJECT_SYSTEMID,
                FILE_NAME
            }
        }
        else
        {
            MSG={
                MEMBER_SYSTEMID,
                PROJECT_SYSTEMID,
                FILE_NAME
            }
        }
        return await AccessAxios.post('/api/SQLite/GetSQLiteTableList', MSG)
            .then(res => {

                const resData = res.data;
                if(resData.Result === "OK"){
                    const clonedSQLiteFileList = JSON.parse(JSON.stringify(SQLiteFileList));
                    const DbFileName = FILE_NAME.replace('sqlite\\', '');
                    
                    for(const index in clonedSQLiteFileList.child){
                        let {title} = clonedSQLiteFileList.child[index];

                        if(title === DbFileName){
                            clonedSQLiteFileList.child[index].child = resData.Data;
                            break;
                        }
                    }

                    setSQLiteFileList(clonedSQLiteFileList);
                }else
                    return resData.Msg;
                    
            }).catch(err => {
                throw err;
            })
    }

    /**
    @param {string} path - 파일 경로
    @param {string} olD_NAME - old파일명
    @param {string} neW_NAME - new파일명
    */
    const RenameDocument = async (path, olD_NAME, neW_NAME) => {
        const MSG = {
            MEMBER_SYSTEMID,
            PROJECT_SYSTEMID,
            path,
            olD_NAME,
            neW_NAME
        }
        try {
            const res = await AccessAxios.post('/api/Document/RenameDocument', MSG);
            return res['data'];
        } catch (err) {
            throw err;
        }
    }

    /**
    파일 삭제
    @param {object[string]} filenames - 파일명 (path 포함)
    */
    const DeleteDocuments = async (filenames) => {
        const MSG = {
            MEMBER_SYSTEMID,
            PROJECT_SYSTEMID,
            filenames,
        }
        try {
            const res = await AccessAxios.post('/api/Document/DeleteDocuments', MSG);
            return res['data'];
        } catch (err) {
            throw err;
        }
    }

    /**
     * 
     * @param {string} _FileData - 파일 데이터
     * @param {string} _path    - 파일 경로
     * @returns 
     */
    const UploadSQLiteFile = async(_FileData, _path) => {
        if(_FileData === '')
            return;
        
        let formData = new FormData();
        Array.from(_FileData).forEach(file => {
            formData.append("FILEDATA", file);
        })
        formData.append("MEMBER_SYSTEMID",  MEMBER_SYSTEMID);
        formData.append("PROJECT_SYSTEMID",PROJECT_SYSTEMID);
        formData.append("UPLOADPATH", _path);

        try{
            const res = await AccessAxios.post('/api/Document/UploadDocuments', formData);  
            return res['data'];
        }catch(err){
            throw err;
        }
    }
    
    return {SQLiteFileList, GetSQLiteFileList, SelectSQLite, GetSQLiteTableList, RenameDocument, DeleteDocuments, UploadSQLiteFile, InsertSQLite };
}

export default useSQLite;