import PropertiesContent from "components/atom/PropertiesContent";
import { GroupName } from 'components/css/common';
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";

import styled from "styled-components";

export default function HistogramSeriesInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        name: NodeName,
        data: NodeData,
        y_label: NodeYLabel,
        stroke_width: NodeStroke,
    } = _data;

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return (
        <PropertiesContent _Title="Series">
            <div>
                <CustomInputComponent label='name' labelWidth='50px' value={NodeName} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='data' labelWidth='50px' value={NodeData} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='y-label' labelWidth='50px' value={NodeYLabel} {...commonProps}/>
            </div>
            <div>
                <ColorComponent label="fill" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
            <div>
                <ColorComponent label="stroke" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
            <div>
                <CustomGroupName >stroke-width</CustomGroupName>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='stroke-width' 
                    valueType='number' 
                    labelWidth='24px' 
                    value={NodeStroke} 
                    {...commonProps} 
                />
            </div>
        </PropertiesContent>
    );
}

//스타일-------------------------
const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;

const CustomGroupName = styled(GroupName)`
    width: 100%;
    max-width: 208px;
    min-width: 120px;
`;