import PropertiesContent from "components/atom/PropertiesContent";
import {RadioComponent} from "components/atom/RadioContent";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import { GroupName } from 'components/css/common';

import styled from "styled-components";
import PropTypes from 'prop-types'
import { MdCropSquare, MdOutlineCircle, MdSouthEast, MdNorthEast } from "react-icons/md";
export default function ContourList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        draw_shape: NodeDrawShape,
        draw_type: NodeDrawType,
        draw_direction: NodeDrawDirection,
        grid_stroke_width: NodeGridStrokeWidth,
        subdivision_x: NodeSubdivisionX,
        subdivision_y: NodeSubdivisionY,
    } = _data;

    const ChangeSelectedEvent = (attributeName, attributeValue) => _ChangeEvent( [{attributeName,attributeValue}])

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title="Contour">
            <div>
                <GroupName width={76}>shape</GroupName>
                <RadioComponent
                    attributeName='draw-shape'
                    dataList={{rect: <MdCropSquare />, circle: <MdOutlineCircle />,}} 
                    selectedValue={NodeDrawShape}
                    disabled={_disabled}
                    changeEvent={ChangeSelectedEvent}
                />
            </div>
            <div>
                <GroupName width={76}>type</GroupName>
                <RadioComponent
                    attributeName='draw-type'
                    dataList={{contour: "contour",solid: "solid",}} 
                    selectedValue={NodeDrawType}
                    disabled={_disabled}
                    changeEvent={ChangeSelectedEvent}
                />
            </div>
            <div>
            <GroupName width={76}>direction</GroupName>
                <RadioComponent
                    attributeName='draw-direction'
                    dataList={{"top bottom": <MdSouthEast />,"bottom top": <MdNorthEast />,}} 
                    selectedValue={NodeDrawDirection}
                    disabled={_disabled}
                    changeEvent={ChangeSelectedEvent}
                />
            </div>
            <div>
                <ColorComponent label="grid" attributeName='grid-stroke' isGradient={false} isVisibility={false} {...commonProps}/>
            </div>
            <div>
                <CustomGroupName >stroke-width</CustomGroupName>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='grid-stroke-width' 
                    valueType='number' 
                    labelWidth='24px' 
                    value={NodeGridStrokeWidth} 
                    {...commonProps} 
                />
            </div>
            <div>
            <GroupName width={60}>subDiv</GroupName>
                <InputComponent label='X' attributeName='subdivision-x' labelWidth='24px' valueType='number' value={NodeSubdivisionX} {...commonProps}/>
                <InputComponent label='Y' attributeName='subdivision-y' labelWidth='24px' valueType='number' value={NodeSubdivisionY} {...commonProps}/>
            </div>
        </PropertiesContent>
    )
}

ContourList.propTypes = {
    _data: PropTypes.object,
    _KeyDownEvent: PropTypes.func
}

const CustomGroupName = styled(GroupName)`
    width: 100%;
    max-width: 208px;
    min-width: 120px;
`;