import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import SelectList from "components/atom/SelectList"
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import { GroupName } from 'components/css/common';

import styled from "styled-components";

export default function RealtimeSeriesInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        series_visible: NodeSeriesVisible,
        type: NodeType,
        name: NodeName,
        y_axis: NodeYAxis,
        variable: NodeVariable,
        data: NodeData,
        x_data: NodeXData,
        x_data_type: NodeXDataType,
        x_data_format: NodeXDataFormat,
        bar_gap: NodeBarGap,
        max_size: NodeMaxSize,
        stroke_width: NodeStrokeWidth,
    } = _data;

    const RealtimeXDataFormatList = ["auto", "number", "yyyy-MM-dd", "yyyy-MM-dd hh:mm", "yy-MM-dd", "yy-MM-dd hh:mm", "yyyy/MM/dd", "yy/MM/dd", "MM/dd", "hh:mm", "hh:mm:ss", "mm:ss", "ss.fff"]

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return (
        <PropertiesContent _Title="Series" 
            _Toggle={ 
                <BtnToggle 
                    _toggleID="series-visible" 
                    _isToggleValue={NodeSeriesVisible} 
                    _disabled={_disabled} 
                    _IsToggle={(attributeName, attributeValue)=>_ChangeEvent([{attributeName, attributeValue}])}
                /> 
            } 
            _ToggleValue={!['0', 'false', undefined].includes(NodeSeriesVisible)}
        >
            <div>
                <GroupName width={50}>type</GroupName>
                <SelectList 
                    attributeName='type' 
                    dataList={["series-realtime-line","series-realtime-bar","series-realtime-stem","series-realtime-stair"]} 
                    selectedValue={NodeType} 
                    {...commonProps}
                />
            </div>
            <div>
                <CustomInputComponent label='name' labelWidth='60px' value={NodeName} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='y-axis' labelWidth='60px' value={NodeYAxis} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='variable' labelWidth='60px' value={NodeVariable} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='data' labelWidth='60px' value={NodeData} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='x-data' labelWidth='60px' value={NodeXData} {...commonProps}/>
            </div>
            <div>
                <GroupNamePadding width={56}>type</GroupNamePadding>
                <SelectList 
                    attributeName='x-data-type' 
                    dataList={["number", "timeseries"]} 
                    selectedValue={NodeXDataType} 
                    {...commonProps}
                />
            </div>
            {NodeXDataType === "timeseries" &&
                <div>
                    <GroupNamePadding width={56}>format</GroupNamePadding>
                    <SelectList 
                        attributeName='x-data-format' 
                        dataList={RealtimeXDataFormatList} 
                        selectedValue={NodeXDataFormat} 
                        {...commonProps}
                    />
                </div>
            }
            {NodeType === 'series-realtime-bar' &&
                <div>
                    <CustomInputComponent label='bar-gap' labelWidth='80px' valueType='number' value={NodeBarGap} {...commonProps}/>
                </div>                                  
            }
            <div>
                    <CustomInputComponent label='max-size' labelWidth='80px' valueType='number' value={NodeMaxSize} {...commonProps}/>
            </div>
            {NodeType !== 'series-realtime-stem' &&
                <div>
                        <ColorComponent label="fill" isGradient={false} isVisibility={false} {...commonProps}/>
                </div>
            }
            <div>
                    <ColorComponent label="stroke" isGradient={false} isVisibility={false} {...commonProps}/>
            </div>
            <div>
                <CustomGroupName >stroke-width</CustomGroupName>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='stroke-width' 
                    valueType='number' 
                    labelWidth='24px' 
                    value={NodeStrokeWidth} 
                    {...commonProps} 
                />
            </div>
        </PropertiesContent>
    );
}

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;

const GroupNamePadding = styled(GroupName)`
    padding-left: 8px;
`;

const CustomGroupName = styled(GroupName)`
    width: 100%;
    max-width: 208px;
    min-width: 120px;
`;