import { useState, useEffect } from 'react';

import LuaSyntax from 'components/atom/LuaSyntax';
import CodeMirror from '@uiw/react-codemirror';
import {EditorState} from "@codemirror/state"
import {vscodeDark} from '@uiw/codemirror-theme-vscode';
import {tooltips} from "@codemirror/view"
import { javascript } from '@codemirror/lang-javascript';
import { EditorView } from '@codemirror/view';
import {autocompletion} from "@codemirror/autocomplete";

function JavascriptCodeEditor({ClassName ,_CodeData, _tempScript, _heigh, _isReadOnly}){
    const [codeData, setCodeData] = useState('');
    useEffect(()=>{
        setCodeData(_CodeData);
    },[_CodeData]);

    const CodeChange =(e) =>{
        setCodeData(e);
        _tempScript && _tempScript(e);
    }
    
    const StopEventBubbling = (e) =>{
        e.stopPropagation();
    }

    return(
        <CodeMirror
            className={ClassName}
            value={codeData} 
            height={_heigh !== undefined ? `${_heigh}px` : '100%'} 
            theme={vscodeDark}
            extensions={[
                javascript({ jsx: true }),
                EditorState.tabSize.of(2),
                [EditorView['lineWrapping']],
                tooltips({
                    position:'absolute',
                    parent: document.body,
                }),
                autocompletion({
                    override: [LuaSyntax],
                }),
            ]} 
            readOnly={_isReadOnly} 
            onChange={CodeChange} 
            onMouseDown={StopEventBubbling}
            basicSetup={{ 
                lineNumbers: !_isReadOnly, 
                foldGutter: !_isReadOnly,
                highlightActiveLine: !_isReadOnly,
                autocompletion: false
            }}
        />
    )
}

export default JavascriptCodeEditor;

JavascriptCodeEditor.defaultProps = {
    _isReadOnly : false,
}