import * as BABYLON from "@babylonjs/core";

const squareIndices = [0, 1, 2, 3, 4, 5];
const squareColors =
    [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, ];

const box1 = {
    vertex: [
        // Box centered at [0, 0, 1]
        // -> Bottom centered at [0, 0, 0]
        [-1, 1, 0],
        [1, 1, 0],
        [1, -1, 0],
        [-1, -1, 0],
        [-1, 1, 2],
        [1, 1, 2],
        [1, -1, 2],
        [-1, -1, 2],

        // Box centered at [0, 0, 0]
        // [-1, 1, -1],
        // [1, 1, -1],
        // [1, -1, -1],
        // [-1, -1, -1],
        // [-1, 1, 1],
        // [1, 1, 1],
        // [1, -1, 1],
        // [-1, -1, 1],
    ],
    face: [
        [0, 1, 2, 3],
        [4, 7, 6, 5],
        [1, 5, 6, 2],
        [0, 3, 7, 4],
        [0, 4, 5, 1],
        [3, 2, 6, 7],
    ],
};

export function createBoxVertex(width, height, depth) {
     const positions = [];
     const indices = [];
     const normals = [];
     let uvs = [];
     const face_uvs = [
        [0, 0],
        [1, 0],
        [1, 1],
        [0, 1],
     ];

     // positions
     for (let i = 0; i < box1.vertex.length; i++) {
        positions.push(
            box1.vertex[i][0] * width,
            box1.vertex[i][1] * depth,
            box1.vertex[i][2] * height
        );
     }

     // indices from faces
     for (let f = 0; f < box1.face.length; f++) {
        for (let j = 0; j < box1.face[f].length; j++) {
            uvs = uvs.concat(face_uvs[j]);
        }
        for (let i = 0; i < box1.face[f].length - 2; i++) {
            indices.push(
                box1.face[f][0],
                box1.face[f][i + 2],
                box1.face[f][i + 1]
            );
         }
    }

    BABYLON.VertexData.ComputeNormals(positions, indices, normals);
    //  BABYLON.VertexData._ComputeSides(
    //      BABYLON.Mesh.FRONTSIDE,
    //      positions,
    //      indices,
    //      normals,
    //      uvs
    //  );

     const vertexData = new BABYLON.VertexData();
     vertexData.positions = positions;
     vertexData.indices = indices;
     vertexData.colors = new Array(144).fill(1);
    //  vertexData.uvs = uvs;

     return vertexData;
 };

export function UpdateBoxVertexPoint(width, height, depth) {
    const positionsArray = new Array(24)

    for (let i = 0; i < box1.vertex.length; i++) {
        positionsArray[i * 3] = box1.vertex[i][0] * width;
        positionsArray[(i * 3) + 1] = box1.vertex[i][1] * depth;
        positionsArray[(i * 3) + 2] = box1.vertex[i][2] * height;
    }
    return positionsArray;
};

export function generate3DContourVertexData(
    visibilityBitmap,
    subdivision
) {
    const vertexDataCombined = createEmptyVertexData();

    const vertexDataList = [];

    for (let z = 0; z < subdivision.z; z++) {
        for (let y = 0; y < subdivision.y; y++) {
            for (let x = 0; x < subdivision.x; x++) {                    
                for (let dir = 0; dir < 6; dir++) {
                    if (
                        isVisibleFace(
                            visibilityBitmap,
                            subdivision,
                            x,
                            y,
                            z,
                            dir
                        )
                    ) {
                        vertexDataList.push(
                            createSquareFaceVertexData(
                                x,
                                y,
                                z,
                                dir,
                                subdivision
                            )
                        );
                    };
                }                
            }
        }
    }
    vertexDataCombined.merge(vertexDataList);
    return vertexDataCombined;
}

const createEmptyVertexData = function () {
    const vertexData = new BABYLON.VertexData();
    // BABYLON.VertexData.ComputeNormals([], [], []);

    vertexData.positions = [];
    vertexData.indices = [];
    vertexData.colors = [];
    vertexData.normals = [];

    return vertexData;
};

const createSquareFaceVertexData = function (
    x = 0,
    y = 0,
    z = 0,
    direction = 0,
    subdivision
) {
    const normals = new Array(18);
    const vertexData = new BABYLON.VertexData();

    vertexData.positions = getCoordinatesOfPlanarArray(x, y, z, direction, subdivision);
    vertexData.indices = squareIndices;

    BABYLON.VertexData.ComputeNormals(
        vertexData.positions,
        vertexData.indices,
        normals
    );
    vertexData.colors = squareColors;
    vertexData.normals = normals;
    return vertexData;
};

const getCoordinatesOfPlanarArray = function (
    xOffset = 0,
    yOffset = 0,
    zOffset = 0,
    direction = 0,
    subdivision
) {
    const positions = new Float32Array(18);
    switch (direction) {
        case 0: //  XY-plane -Z direction
            positions.set([
                0, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 1, 0,
            ]);
            break;
        case 1: // YZ-plane -X direction
            positions.set([
                0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 1,
            ]);
            break;
        case 2: // XZ-plane -Y direction
            positions.set([
                0, 0, 0, 1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 1,
            ]);
            break;
        case 3: //  XY-plane +Z direction
            positions.set([
                0, 0, 1, 1, 1, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 1, 1, 1,
            ]);
            break;
        case 4: // YZ-plane +X direction
            positions.set([
                1, 0, 0, 1, 0, 1, 1, 1, 0, 1, 0, 1, 1, 1, 1, 1, 1, 0,
            ]);
            break;
        case 5: // XZ-plane +Y direction
            positions.set([
                0, 1, 0, 1, 1, 0, 1, 1, 1, 0, 1, 0, 1, 1, 1, 0, 1, 1,
            ]);
            break;
        default:
        // do nothing
    }

    // offset + contour position to (subX/2, subY/2, 0) 
    // Apply offsets directly to positions
    for (let i = 0; i < 18; i += 3) {
        positions[i] += (xOffset - (subdivision.x / 2));
        positions[i + 1] += (yOffset - (subdivision.y / 2));
        positions[i + 2] += zOffset;
    }

    return positions;
};

//TODO: Change get1DIndex into a more intuitive name
export function get1DIndex(x, y, z, subdivisions) {
        const idx = x + (subdivisions.x * y) + (subdivisions.x * subdivisions.y * z);
        return idx;
    }

////////////////////////////////////////////////////
//// Visibility Check
////////////////////////////////////////////////////
function isVisibleFace(visibilityMap, subdivisions, posX, posY, posZ, direction) {
    const idx = get1DIndex(posX, posY, posZ, subdivisions);
    // const coordinateKey = `${posX},${posY},${posZ}`;
    if (
        // Check the coordinate is a visible cube
        visibilityMap[idx] !== 0 &&
        (isOuterBoundary(subdivisions, posX, posY, posZ, direction) ||
            isNotBlocked(
                visibilityMap,
                subdivisions,
                posX,
                posY,
                posZ,
                direction
            ))
    ) {
        return true;
    }
    return false;
}

function isOuterBoundary(subdivisions, posX, posY, posZ, direction) {
    switch (direction) {
        case 0: //  XY-plane -Z direction
            if (posZ === 0) {
                return true;
            }
            break;
        case 1: // YZ-plane -X direction
            if (posX === 0) {
                return true;
            }
            break;
        case 2: // XZ-plane -Y direction
            if (posY === 0) {
                return true;
            }
            break;
        case 3: //  XY-plane +Z direction
            if (posZ + 1 === subdivisions.z) {
                return true;
            }
            break;
        case 4: // YZ-plane +X direction
            if (posX + 1 === subdivisions.x) {
                return true;
            }
            break;
        case 5: // XZ-plane +Y direction
            if (posY + 1 === subdivisions.y) {
                return true;
            }
            break;
        default:
        // do nothing
    }
    return false;
}

function isNotBlocked(
    visibilityMap,
    subdivisions,
    posX,
    posY,
    posZ,
    direction
) {
    let idx = get1DIndex(posX, posY, posZ, subdivisions);
    switch (direction) {
        case 0: //  XY-plane -Z direction
            idx = get1DIndex(posX, posY, posZ - 1, subdivisions);
            if (posZ - 1 >= 0 && visibilityMap[idx] === 0) {
                return true;
            }
            break;
        case 1: // YZ-plane -X direction
            idx = get1DIndex(posX - 1, posY, posZ, subdivisions);
            if (posX - 1 >= 0 && visibilityMap[idx] === 0) {
                return true;
            }
            break;
        case 2: // XZ-plane -Y direction
            idx = get1DIndex(posX, posY - 1, posZ, subdivisions);
            if (posY - 1 >= 0 && visibilityMap[idx] === 0) {
                return true;
            }
            break;
        case 3: //  XY-plane +Z direction
            idx = get1DIndex(posX, posY, posZ + 1, subdivisions);
            if (
                posZ + 1 < subdivisions.z &&
                visibilityMap[idx] === 0
            ) {
                return true;
            }
            break;
        case 4: // YZ-plane +X direction
            idx = get1DIndex(posX + 1, posY, posZ, subdivisions);
            if (
                posX + 1 < subdivisions.x &&
                visibilityMap[idx] === 0
            ) {
                return true;
            }
            break;
        case 5: // XZ-plane +Y direction
            idx = get1DIndex(posX, posY + 1, posZ, subdivisions);
            if (
                posY + 1 < subdivisions.y &&
                visibilityMap[idx] === 0
            ) {
                return true;
            }
            break;
        default:
        // do nothing
    }
    return false;
}

export function dataToColorMap(dataArray, min, max, colorElevation) {
    const partitionedArray = dataArray.map((data) =>
        assignRange(data, min, max)
    );

    const Color18StepArr = colorElevation;

    return partitionedArray.map(
        (data) => Color18StepArr[data]);
}

const assignRange = function (dataPoint, min, max) {
    const stride = (max - min) / 18;
    if (dataPoint >= max) {
        return 17;
    } else {
        const part = Math.floor((dataPoint - min) / stride);
        return part;
    }
};

//TODO: Data(Object Map) -> Babylon style, 1D array 
const addData = (x, y, z, data, matrix) => {
    const key = `${x},${y},${z}`;
    matrix[key] = data;
};

export function getMockupData(subdivisions, min, max, random = false) {
    let matrix = {};

    if (random) {
        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min)) + min;
        }
        for (let x = 0; x < subdivisions.x + 1; x++) {
            for (let y = 0; y < subdivisions.y + 1; y++) {
                for (let z = 0; z < subdivisions.z + 1; z++) {
                    addData(x, y, z, getRandomInt(min, max), matrix);
                }
            }
        }
    }
    else {
        const avg = parseInt((max - min) / 2);
        for (let x = 0; x < subdivisions.x + 1; x++) {
            for (let y = 0; y < subdivisions.y + 1; y++) {
                for (let z = 0; z < subdivisions.z + 1; z++) {
                    // addData(x, y, z, getRandomInt(min, max), matrix);
                    addData(x, y, z, avg, matrix);
                }
            }
        }        
    }
    
    return matrix;
}

export function getParsedData(dimensions, data) {
    let matrix = {};

    // subZ = 0 -> 2D, subZ != 0, 3D Dataset
    if (dimensions[2] !== 0) {
        for (let x = 0; x < dimensions[0]; x++) {
            for (let y = 0; y < dimensions[1]; y++) {
                for (let z = 0; z < dimensions[2]; z++) {
                    addData(x, y, z, data[x][y][z], matrix);
                }
            }
        }
    } else {
        for (let x = 0; x < dimensions[0]; x++) {
            for (let y = 0; y < dimensions[1]; y++) {
                // 2D data를 복사해 z= 0, 1에 입력.
                addData(x, y, 0, data[x][y], matrix);
                addData(x, y, 1, data[x][y], matrix);
            }
        }
    }
    return matrix;
}

export function parseCoordinates(subdivision, coord) {
    const coordinates = [];

    const rangeX = []
    if (coord[0] === 'x') {
        for (let x = 0; x < subdivision.x; x++){
            rangeX.push(x);
        }
    }    
    else {
        if (typeof (coord[0]) === 'string' && coord[0].includes(":")) {
            const [start, end] = coord[0].split(":");
            for (let x = Number(start); x < Number(end); x++) {
                rangeX.push(x);
            }
        }
        else {           
            rangeX.push(coord[0]);            
        }
    }

    const rangeY = [];
    if (coord[1] === "y") {
        for (let y = 0; y < subdivision.y; y++) {
            rangeY.push(y);
        }
    } else {
        if (typeof coord[1] === "string" && coord[1].includes(":")) {
            const [start, end] = coord[1].split(":");
            for (let y = Number(start); y < Number(end); y++) {
                rangeY.push(y);
            }
        } else {
            rangeY.push(coord[1]);
        }
    }

    const rangeZ = [];
    if (coord[2] === "z") {
        for (let z = 0; z < subdivision.z; z++) {
            rangeZ.push(z);
        }
    } else {
        if (typeof coord[2] === "string" && coord[2].includes(":")) {
            const [start, end] = coord[2].split(":");
            for (let z = Number(start); z < Number(end); z++) {
                rangeZ.push(z);
            }
        } else {
            rangeZ.push(coord[2]);
        }
    }

    for (let x = 0; x < rangeX.length; x++){
        for (let y = 0; y < rangeY.length; y++) {
            for (let z = 0; z < rangeZ.length; z++) {
                coordinates.push([
                    Number(rangeX[x]),
                    Number(rangeY[y]),
                    Number(rangeZ[z]),
                ]);
            }
        }
    }
    return coordinates;
}

export function updateMeshColor(mesh, visibilityBitmap, colors, subdivisions) {
    // Contour Mod: Each verrtex of cubes is a datapoint
    // colors: contour mod data object. dimension: (x+1)(y+1)(z+1) x,y,z being box dimensions.

    const colorKind = mesh.getVerticesData("color");

    const boxVertexToFacetVertices = {
        1: [0, 3, 6, 12, 15],
        2: [1, 14, 24],
        3: [2, 4, 26, 29, 31],
        4: [5, 7, 10, 30, 33],
        5: [8, 9, 16, 18, 21],
        6: [13, 17, 20, 25, 27],
        7: [19, 23, 28, 32, 34],
        8: [11, 22, 35],
    };
    const facetVerticesToBoxVertex = {
        0: 1,
        1: 2,
        2: 3,
        3: 1,
        4: 3,
        5: 4,
        6: 1,
        7: 4,
        8: 5,
        9: 5,
        10: 4,
        11: 8,
        12: 1,
        13: 6,
        14: 2,
        15: 1,
        16: 5,
        17: 6,
        18: 5,
        19: 7,
        20: 6,
        21: 5,
        22: 8,
        23: 7,
        24: 2,
        25: 6,
        26: 3,
        27: 6,
        28: 7,
        29: 3,
        30: 4,
        31: 3,
        32: 7,
        33: 4,
        34: 7,
        35: 8,
    };
    const facetVerticesToFaceNumber = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 1,
        7: 1,
        8: 1,
        9: 1,
        10: 1,
        11: 1,
        12: 2,
        13: 2,
        14: 2,
        15: 2,
        16: 2,
        17: 2,
        18: 3,
        19: 3,
        20: 3,
        21: 3,
        22: 3,
        23: 3,
        24: 4,
        25: 4,
        26: 4,
        27: 4,
        28: 4,
        29: 4,
        30: 5,
        31: 5,
        32: 5,
        33: 5,
        34: 5,
        35: 5,
    };
    const boxVertexShiftToDataCoordinates = {
        1: [0, 0, 0],
        2: [1, 0, 0],
        3: [1, 1, 0],
        4: [0, 1, 0],
        5: [0, 0, 1],
        6: [1, 0, 1],
        7: [1, 1, 1],
        8: [0, 1, 1],
    };
    //   const faceToBoxVertices = {
    //     0: [1, 2, 3, 4],
    //     1: [1, 4, 5, 8],
    //     2: [1, 2, 5, 6],
    //     3: [5, 6, 7, 8],
    //     4: [2, 3, 6, 7],
    //     5: [3, 4, 7, 8],
    //   };

    let newColorArr = new Array(colorKind.length).fill(0);
    // Looping thru each cube
    let currentCubeColorLength = 0;

    for (let z = 0; z < subdivisions.z; z++) {
        for (let y = 0; y < subdivisions.y; y++) {
            for (let x = 0; x < subdivisions.x; x++) {
                // FacetVertex -> List, sorted
                // Count++ as it progresses

                let visibleFacetVertexList = [];
                for (let boxVertexIdx = 1; boxVertexIdx < 9; boxVertexIdx++) {
                    let facetVertices = boxVertexToFacetVertices[boxVertexIdx];
                    for (let i = 0; i < facetVertices.length; i++) {
                        let dir = facetVerticesToFaceNumber[facetVertices[i]];
                        if (
                            isVisibleFace(
                                visibilityBitmap,
                                subdivisions,
                                x,
                                y,
                                z,
                                dir
                            )
                        ) {
                            visibleFacetVertexList.push(facetVertices[i]);
                        }
                    }
                }

                visibleFacetVertexList.sort(function (a, b) {
                    return a - b;
                });
                const boxVertexList = visibleFacetVertexList.map(
                    (facetVertices) => facetVerticesToBoxVertex[facetVertices]
                );
                const shiftList = [];
                boxVertexList.forEach((boxVertex) => {
                    shiftList.push(boxVertexShiftToDataCoordinates[boxVertex]);
                });
                /// add x,y,z to xyzshift
                const modifiedArray = shiftList.map((innerArray) => {
                    const newArray = [...innerArray];
                    newArray[0] += x;
                    newArray[1] += y;
                    newArray[2] += z;
                    return newArray;
                });

                const coordinatesArray = modifiedArray.map((innerArray) => {
                    const newArray = [...innerArray];
                    const coordinate = `${newArray[0]},${newArray[1]},${newArray[2]}`;
                    return coordinate;
                });
                const rgbaArray = coordinatesArray.map((coordinate) => {
                    return [
                        colors[coordinate]["r"],
                        colors[coordinate]["g"],
                        colors[coordinate]["b"],
                        0.3,
                    ];
                });

                const flattenedRgbaArray = rgbaArray.flat();
                newColorArr.splice(
                    currentCubeColorLength,
                    flattenedRgbaArray.length,
                    ...flattenedRgbaArray
                );

                currentCubeColorLength += flattenedRgbaArray.length;
            }
        }
    }

    mesh.updateVerticesData("color", newColorArr);
}