import {useState} from "react"

import SharePdfModalChild from "components/modal_new/SharePdfModalChild"

import styled, {css}  from "styled-components"
import { Rnd } from "react-rnd";
import { MdMinimize, MdFullscreen, MdFullscreenExit } from "react-icons/md";

export default function SharePdfModal({_BASE_DATA, SharePdf, connectUser, RequestMsg, FocusPdf, _width, _height, _minWidth = 540, _minHeight = 540, setIsShareUser}){
    const [isMinsize, setIsMinsize] = useState(false);
    const [isFullSize, setIsFullSize] = useState(false);
    const [defStyle, setDefStyle] = useState({ 
        x: window.innerWidth / 2 - _width/2, 
        y: window.innerHeight / 2 - _height/2, 
        width: _width, 
        height: _height, 
    });

    const [SelectedPdf, setSelectedPdf] = useState('');

    return(
        <Content>
            <Rnd className="ModalStyle"
                bounds="body"
                position={{
                    x: isMinsize ? 20 : isFullSize ? 0 : defStyle.x,
                    y: isMinsize ? window.innerHeight - 340 : isFullSize ? 0 : defStyle.y,
                }}
                size={{
                    width: isMinsize ? 260 : isFullSize ? document.getElementById('canvasSpace').clientWidth : defStyle.width,
                    height: isMinsize ? 260 : isFullSize ? "100%" : defStyle.height,
                }}
                minWidth={isMinsize ? 260 :_minWidth}
                minHeight={isMinsize ? 260 :  _minHeight}
                enableResizing={(!isMinsize && !isFullSize)}
                disableDragging={(isMinsize || isFullSize)}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setDefStyle(pevData => ({
                        ...pevData,
                        x: position.x,
                        y: position.y,
                        width: pevData.width + delta.width,
                        height: pevData.height + delta.height,
                    }));
                }}
                onDragStop={(e, d) => {
                    if(isMinsize || isFullSize) return;
                    
                    const newX = Math.max(0, d.x);
                    const newY = Math.max(0, d.y);
                    setDefStyle(pevData => ({
                        ...pevData,
                        x: newX,
                        y: newY,
                    }));
                }}
            >
                <ModalHeader>
                    <span>자료공유보드</span>
                    <div className="buttons">
                        {isMinsize &&
                            <MdFullscreen onClick={(e)=>setIsMinsize(false)}/>
                        }
                        {(!isMinsize && !isFullSize) &&
                            <MdMinimize onClick={(e)=>setIsMinsize(true)}/>
                        }
                        {(!isFullSize && !isMinsize) &&
                            <MdFullscreen onClick={(e) => setIsFullSize(true)}/>
                        }
                        {(isFullSize && !isMinsize) &&
                            <MdFullscreenExit onClick={(e) => setIsFullSize(false)}/>
                        }
                    </div>
                </ModalHeader>
                <ChildrenContent
                    className={(SelectedPdf === '' || isMinsize)? 'flex' : 'grid'}
                    pdfCount = {Object.keys(SharePdf).length}
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    {Object.entries(SharePdf).map(([key, value]) => {
                        const CheckMinSize = isMinsize || (SelectedPdf !== '' && value.SHARED_PDF_ID !== SelectedPdf);
                        return(
                            <SharePdfModalChild key={key} 
                                _BASE_DATA={_BASE_DATA} 
                                RequestMsg={RequestMsg} 
                                PdfInfo={value} 
                                connectUser={connectUser} 
                                FocusInfo={FocusPdf[key]} 
                                SelectedPdfState={[SelectedPdf, setSelectedPdf]}
                                setIsShareUser={setIsShareUser}
                                isMinsize={CheckMinSize} 
                            />
                        )
                    })}
                </ChildrenContent>
            </Rnd>
        </Content>
    )
}

const Content = styled.div`
    position: absolute;

    .ModalStyle{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.3rem;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
        /* overflow: hidden; */
        z-index:9;

        &>div:last-child{
            div:nth-child(2){
                z-index: 1;
            }
            div:nth-child(3){
                z-index: 1;
            }
            div:nth-child(7){
            }
            div:nth-child(8){
            }
        }
    }
`;

const ModalHeader = styled.header`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    border-radius: 0.3rem 0.3rem 0 0;
    padding: 8px 16px;
    font-weight: bold;
    box-sizing: border-box;
    color: ${({theme}) => theme.base.font_color_Black};
    background-color: ${({theme}) => theme.base.background_color_PaleGray};
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    user-select: none;

    &>span{
        width: 100%;
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: contents;
    }

    .buttons{
        display: flex;
        gap: 8px;

        svg{
            width: 30px;
            height: 24px;
            padding: 3px;
            box-sizing: border-box;
            font-size: 1.2rem;
            text-align: center;
            color: ${({theme}) => theme.base.font_color_DarkGray};
            background-color: transparent;
            outline: none;
            cursor: pointer;
            border: 0;

            &:hover{
                color: ${({theme}) => theme.base.font_color_DarkBlue};
            }
        }
    }
`;

const ChildrenContent = styled.div`
    height: calc(100% - 40px);
    background-color: #292E33;
    box-sizing: border-box;
    cursor: default;
    overflow: auto;
    user-select: none;

    &.flex{
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        
        &>div{
            box-sizing: border-box;

            ${(props) =>{
                switch(props.pdfCount){
                    case 1:
                        return css`
                            width: 100%;
                            height: 100%;
                        `;
                    case 2:
                        return css`
                            width: calc(50% - 2px);
                            height: 100%;
                        `;
                    case 3:
                    case 4:
                        return css`
                            width: calc(50% - 2px);
                            height: calc(50% - 2px);
                        `;
                    case 5:
                    case 6:
                        return css`
                            width: calc((100% / 3) - 3px);
                            height: calc(50% - 2px);
                        `;
                    case 7:
                    case 8:
                    case 9:
                        return css`
                            width: calc((100% / 3) - 3px);
                            height: calc((100% / 3) - 3px);
                        `;
                    default:
                        break;
                }
            }}
        }
    }

    &.grid{
        display: grid;
        gap: 4px;

        ${(props) =>{
            switch(props.pdfCount){
                case 1:
                    return css`
                        grid-template-columns: 100%;
                        grid-template-rows: 100%;
                    `;
                case 2:
                case 3:
                case 4:
                    return css`
                        grid-template-columns: calc(100% - 144px) 140px;
                        grid-template-rows: repeat(auto-fit, minmax(25%, auto));

                        &>.SelectedPdf{
                            grid-row-start: 1;
                            grid-row-end: 4;
                        }
                    `;
                case 5:
                    return css`
                        grid-template-columns: calc(100% - 288px) 140px 140px;
                        grid-template-rows: 1fr 1fr;

                        &>.SelectedPdf{
                            grid-row-start: 1;
                            grid-row-end: 3;
                        }
                    `;
                case 6:
                case 7:
                    return css`
                        grid-template-columns: calc(100% - 288px) 140px 140px;
                        grid-template-rows: repeat(auto-fit, minmax(32%, auto));

                        &>.SelectedPdf{
                            grid-row-start: 1;
                            grid-row-end: 4;
                        }
                    `;
                case 8:
                case 9:
                    return css`
                        grid-template-columns: calc(100% - 288px) 140px 140px;
                        grid-template-rows: repeat(auto-fit, minmax(24%, auto));

                        &>.SelectedPdf{
                            grid-row-start: 1;
                            grid-row-end: 5;
                        }
                    `;
                default:
                    break;
            }
        }}
    }

    .SelectedPdf {
        order: -1;
    }
`;