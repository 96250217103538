import { useState, useEffect } from "react";

import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {ModalBottomBox} from "components/css/common";
import Checkbox from "components/atom/Checkbox";

import {updateCurrentView} from 'reducers/projectDataReducer';
import { useDispatch } from 'react-redux';

import styled from "styled-components";
import CodeMirror from '@uiw/react-codemirror';
import {EditorState} from "@codemirror/state"
import { xml } from '@codemirror/lang-xml';
import {vscodeDark} from '@uiw/codemirror-theme-vscode';

import { EditorView } from '@codemirror/view';

function ViewCodeEdit({enuSpace, _ViewInfo, _CodeData, _SaveCode, _DeleteTab}){
    const dispatch = useDispatch();
    const [lineWrap, setLineWrap] = useState(false);
    const [codeData, setCodeData] = _CodeData;
    
    //현 활성화 중인 뷰의 아이디
    const CurrentViewId = _ViewInfo['currentView'];

    useEffect((e)=>{
        if(_ViewInfo['canvasMode'][CurrentViewId] !== 'code') return;

        if(codeData[CurrentViewId] === undefined){
            // //FIXME: hmi 부분 추가 시 확인 필요 path 구조에 따른
            const arrPath = _ViewInfo['activeCanvas'][CurrentViewId].split('\\');
            if(arrPath[1] === 'hmi'){
                enuSpace.ViewCode(arrPath[1], arrPath[2])
            }else
                enuSpace.ViewCode(arrPath[0], arrPath[1])
        }
    }, [enuSpace, _ViewInfo, CurrentViewId, codeData]);

    window.Re_ViewCode = (_data)=>{
        const resData = JSON.parse(_data);
        const newCodeData = {...codeData};
        newCodeData[CurrentViewId] = {origin: resData['data'], edit: resData['data']};
        setCodeData(newCodeData);
    }

    const CodeChange =(e) =>{    
        const newCodeData = {...codeData};
        if(newCodeData[CurrentViewId] === undefined)
            return;

        newCodeData[CurrentViewId]['edit'] = e;
        setCodeData(newCodeData);
    }

    const ChangeDesignMode = (e) =>{
        const newCodeData = {...codeData};
        delete newCodeData[CurrentViewId];
        setCodeData(newCodeData);

        const arrPath = _ViewInfo['activeCanvas'][CurrentViewId].split('\\');
        if(arrPath[1] === 'hmi' || arrPath[0] === 'global'){
            _DeleteTab(e, CurrentViewId);
        }else{
            if(codeData[CurrentViewId]['origin'] !== codeData[CurrentViewId]['edit']){
                if(window.confirm('변경된 내용이 있습니다. 저장하시겠습니까?')){
                    _SaveCode('btn_save');
                }
            }
            dispatch(updateCurrentView({canvasId:CurrentViewId, mode:'design'}));
        }
    }

    return(
        <ViewCodeEditContent>
            <CodeMirror value={codeData[CurrentViewId]?.edit} height={'100%'} theme={vscodeDark} 
                extensions={[
                    xml({ autoCloseTags: true }),
                    EditorState.tabSize.of(2),
                    ...(lineWrap ? [EditorView['lineWrapping']] : []),
                ]}
                onChange={CodeChange} onMouseDown={(e)=>e.stopPropagation()}
                basicSetup={{ }}/>

            <EditBottomBox justifyType={'space-between'}>
                <Options>
                    <Checkbox _id='lineWrapping' checked={lineWrap} onChange={(e)=>setLineWrap(!lineWrap)}>lineWrapping</Checkbox>
                </Options>
                <div>
                    <BtnSecondary label="Cancel" _margin={0} onClick={(e)=>ChangeDesignMode(e)}/>
                    <BtnPrimary label="Accept" _margin={16} onClick={(e)=>_SaveCode()}/>
                </div>
            </EditBottomBox>
        </ViewCodeEditContent>
    )
}

export default ViewCodeEdit;

// 스타일---------------------
const ViewCodeEditContent = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow: hidden;
    flex-grow: 1;
    width: 100%;
    border-top: 1px solid ${({theme}) => theme.base.border_color_Gray};
    background-color: #fff;
    font-size: 1rem;
    user-select: none;

    &>div{
        width: 100%;
    }

    &>div:first-child{
        flex-grow: 1;
        height: calc(100% - 78px);
    }
`;

const EditBottomBox = styled(ModalBottomBox)`
    margin-bottom: 16px;
    padding-inline: 16px;
    width: auto !important;
`;

const Options = styled.div`
    display: flex;
    align-items: center;
    font-size: .9rem;
    color: ${({theme}) => theme.base.font_color_DarkGray};
`;