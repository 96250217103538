
import { useState, useEffect } from 'react';

import Markdown from 'components/atom/Markdown'
import {BtnPrimary} from "components/atom/Button";
import useDocument, {ReadDocument} from 'hooks/useDocument';

import styled from 'styled-components';
import CodeMirror from '@uiw/react-codemirror';
import {EditorState} from "@codemirror/state"
import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { languages } from '@codemirror/language-data';
import {vscodeDark} from '@uiw/codemirror-theme-vscode';

export default function DocMd({BASE_DATA, path, mode = 'read'}){
    const{ userId, projectId } = BASE_DATA;
    const [MarkdownInfo, setMarkdownInfo] = useState('');
    const {SaveDoc} = useDocument();

    useEffect(()=>{
        ReadDocument(userId, projectId, path)
            .then((res) => {
                setMarkdownInfo(res);
            }).catch((err) => {

            });
    }, [userId, projectId, path]);

    const SaveEvent = async (e) => {
        const ArrayPath = path.split('\\');
        const FileName = ArrayPath.pop();

        await SaveDoc(userId, projectId, ArrayPath.join('\\'), FileName, MarkdownInfo)
            .then((res) => {
                if(res){window.alert("저장 되었습니다.")}
                else{window.alert("저장에 실패하였습니다.")}
                // window.AddToastMsg("저장 되었습니다.");
            }).catch((err) => {
                console.log(err)
                window.AddAlertMsg({type:'error', msg:err})
            });
    }

    const CodeChange = (e) => setMarkdownInfo(e);

    if(mode === 'read'){
        return(
            <Markdown _markdownInfo={MarkdownInfo} _rootPath={`Repository/${userId}/${projectId}/`}/>
        )
    }else if(mode === 'edit'){
        return(
            <EditModeContainer>
                <EditPageHeader>
                    <BtnPrimary label="Save" onClick={SaveEvent} />
                </EditPageHeader>

                <div className='EditPageContent'>
                    <Markdown _markdownInfo={MarkdownInfo} _rootPath={`Repository/${userId}/${projectId}/`} />

                    <div className='edit'>
                        <CodeMirror value={MarkdownInfo} height={'100%'} theme={vscodeDark}
                            extensions={[
                                markdown({ base: markdownLanguage, codeLanguages: languages }),
                                EditorState.tabSize.of(2)
                            ]}
                            onChange={CodeChange}
                            basicSetup={{}} />
                    </div>
                </div>
            </EditModeContainer>
        )
    }
}

const EditModeContainer = styled.div`
    &>.EditPageContent{
        display: flex;
        height: calc(100vh - 72px);

        &>div{
            width: 50%;
            height: 100%;
            overflow: auto;
            box-sizing: border-box;

            &:first-child{
                border-right: 1px solid ${({theme}) => theme.base.border_color_Gray};
            }

            &>.edit, .cm-theme{
                height: 100%;
                
            }
        }
    }
`;

const EditPageHeader = styled.div`
    display: flex;  
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 72px;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
    background-color: #FFF;
    padding: 0 16px;
`;