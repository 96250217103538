import { useState } from 'react';

import MouseRightClickMenuContent from "components/molecule/MouseRightClickMenuContent";
import TreeRowContent from 'components/atom/TreeRowContent';
import Modal from 'components/modal_new/Modal';
import {ModalBottomBox, InputContent, TreeUlContent, BgPopup, WarningMsg} from "components/css/common";
import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {GetRealTarget} from 'components/Utility';
import useDocument from 'hooks/useDocument.jsx';
import useRightMenu from "hooks/useRightMenu";
import {
    EnuIconFile,
    EnuIconFolderClose,
} from "components/common/EnuSpaceIcon"

import { useSelector } from 'react-redux';
import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import { MdDriveFileRenameOutline, MdCopyAll, MdCode, MdClear, MdOutlineFileUpload} from "react-icons/md";

export default function DocumentContent(){
    const location = useLocation();
    const {
        type:RightMenuType, 
        top:RightMenuTopPos, 
        left:RightMenuLeftPos, 
        data:{
            value:RightMenuValue,
            type:RightMenuDataType,
            path:RightMenuPath,
            extension: RightMenuExtension,
        } = {}
    } = useSelector(state => state.rightMenu);
    const {GetDocumentList, DocumentList, CreateFolder, CreateFile, DeleteDocuments, RenameDocument, UploadDoc} = useDocument();
    const {ShowRightMenu, CloseRightMenu} = useRightMenu();
    const [fileData, setFileData] = useState();

    // redex로 관리 하지 않고 현 컨포너트에서 모달 관리를 위한 state
    const [CreateModal, setCreateModal] = useState({ visibility: false, data: null });

    /**
     * new 윈도우 호출 함수
     * @param {string} _filename - 파일 이름
     * @param {string} _type     - 파일 타입 (document, folder)
     * @param {string} _path     - 파일 경로
     * @param {string} _mode     - 파일 모드 (read, edit) 기본 read  
     */
    const OpenWindow = (_filename, _type, _path, _mode='read') => {
        if(_type === 'folder')
            return;

        const extension = _filename.substring(_filename.lastIndexOf('.')+1, _filename.length);

        localStorage.setItem('projectId', location.state.projectId);
        window.open(
            `https://${window.location.host}/MyRepository/DocumentViewer?path=${_path}&extension=${extension}&mode=${_mode}`, 
            'DocumentViewer', 
            `width=${_mode ==='edit' ? 1400:700}, height=900, location=no, status=no, scrollbars=yes, menubar=no`);
    }
    
    const EventDoubleClick = (e) => {
        const {value, type, path} = GetRealTarget(e.target).dataset;
        OpenWindow(value, type, path);
    }

    const EditDocument = (e) => OpenWindow(RightMenuValue, RightMenuDataType, RightMenuPath, 'edit');

    const MouseRightClick = (e) => {
        e.stopPropagation();
        const {type, value, path} = GetRealTarget(e.target).dataset;
        let extension = null;

        if(type === undefined){
            CloseRightMenu();
            return;
        }else if(type === 'document'){
            extension = value.substring(value.lastIndexOf('.')+1, value.length);
        }
        
        ShowRightMenu({'type': 'doc', 'top': e.pageY, 'left': e.pageX, data:{type, value, path, extension}});
    }

    // ShowMarkdown("path정보/파일명.md") 형태로 클립보드에 복사
    const CopyPath = (e) => {
        let copyText = '';
        if(RightMenuExtension === 'md'){
            copyText = `ShowMarkdown("${RightMenuPath.replace(/\\/g, '/')}")`;
        }else{
            copyText = `ShowDocument("${RightMenuPath.replace(/\\/g, '/')}")`;
        }
        navigator.clipboard.writeText(copyText);
        window.AddToastMsg(`Copy Success : ${copyText}`);
    }

    const CreateFolderEvent = (e) =>    setCreateModal({...CreateModal, visibility: true, data:{type: 'Create Folder', name: '', path:RightMenuPath, msg: ''}});
    const CreateFileEvent = (e) =>      setCreateModal({...CreateModal, visibility: true, data:{type: 'Create File', name: '', path:RightMenuPath, msg: ''}});
    const UploadFile = (e) =>           {setCreateModal({...CreateModal, visibility: true, data:{type: 'Upload File', name: '', path:RightMenuPath, msg: ''}}); setFileData('');}
    const RenameEvent = (e) =>          setCreateModal({...CreateModal, visibility: true, data:{type: 'Rename', name: RightMenuValue, path:RightMenuPath,  extension: RightMenuExtension, oldName: RightMenuValue, msg: ''}});

    const DeleteDocumentsEvent = (e) => {
        if(window.confirm(`"${RightMenuValue}" 를 삭제하시겠습니까?`)){
            DeleteDocuments(RightMenuPath)
                .then((res)=>{ 
                    GetDocumentList();
                }).catch((err)=>{
                    window.AddAlertMsg({type:'error', msg:err});
                });
        }
    }

    const RenderTree = (menu, path ='document') => {
        return menu.map((item) => {
            const {title, type, id, child} = item;
            const _path = path+'\\'+title;
            return(
                <li key={_path}>
                    <TreeRowContent _id={id} _title={title} _type={type && type} _path={_path} _onDoubleClick={EventDoubleClick}>
                        {child&& 
                            <TreeUlContent>
                                {(RenderTree(child, _path))}
                            </TreeUlContent>
                        }
                    </TreeRowContent>
                </li>
            )
        })
    }

    const handleOnChange = (e) => {
        setCreateModal(prevState => ({ ...prevState, data: { ...prevState.data, msg: '' } }));
        setFileData(e.target.files);
    }

    const CloseModal = () => setCreateModal({ visibility: false, data: null });
    
    return(
        <div style={{ height:'100%', paddingLeft: '8px'}} 
            onClick={CloseRightMenu} 
            onContextMenu={(e)=>{
                e.preventDefault();
                MouseRightClick(e);}
            }>
            <TreeRowContent _title="document" _type="root" _path="document">
                <TreeUlContent>
                    {DocumentList['child']?.length > 0 && RenderTree(DocumentList['child'])}
                </TreeUlContent>
            </TreeRowContent>

            {/* 우클릭 매뉴 */}
            {(RightMenuType === 'doc') &&
                <MouseRightClickMenuContent _positionTop={RightMenuTopPos} _positionLeft={RightMenuLeftPos}>
                    {RightMenuDataType === 'document' &&
                        <>
                            <li onClick={CopyPath}><span><MdCopyAll style={{ 'color': 'royalblue' }} />copy</span> </li>
                            {RightMenuExtension === 'md' && <li onClick={EditDocument}><span><MdCode style={{ 'color': 'steelblue' }} />edit</span> </li>}
                        </>
                    }

                    {RightMenuDataType !== 'document' &&
                        <>
                            <li onClick={CreateFolderEvent}><span><EnuIconFolderClose />New Folder</span> </li>
                            <li onClick={CreateFileEvent}><span><EnuIconFile />New file (md)</span> </li>
                            <li onClick={UploadFile}><span><MdOutlineFileUpload style={{ color: 'royalblue' }} />upload file</span> </li>
                        </>
                    }

                    {RightMenuDataType !== 'root' &&
                        <>
                            <li onClick={RenameEvent}><span><MdDriveFileRenameOutline style={{ 'color': 'darkgoldenrod' }} />Rename</span> </li>
                            <span className="menuLine" />
                            <li onClick={DeleteDocumentsEvent}><span><MdClear style={{ 'color': 'mediumvioletred' }} />Delete</span> </li>
                        </>
                    }
                </MouseRightClickMenuContent>
            }

            {/* 모달 창 파일 또는 폴더 명을 입력 받음 */}
            {(CreateModal['visibility'] && CreateModal['data']['type']!=='Upload File')&& 
                <CreateModalContent>
                    <BgPopup onClick={CloseModal}/>
                    <Modal _title={CreateModal['data']['type']} _height={182} _enableResizing={false}  _closeModal={CloseModal}>
                        <FlexContent>
                            <InputContent 
                                value={
                                    CreateModal['data']['extension'] 
                                        ? CreateModal['data']['name'].replace(`.${CreateModal['data']['extension']}`, '') 
                                        : CreateModal['data']['name']
                                } 
                                onChange={(e)=>setCreateModal(prevState => ({...prevState, data:{...prevState.data, name:e.target.value, msg:''}}))}
                            />
                            {CreateModal['data']['extension'] &&
                                <span>.{CreateModal['data']['extension']}</span>
                            }
                        </FlexContent>

                        <ModalBottomBox>
                            <WarningMsg>{CreateModal['data']['msg']}</WarningMsg>
                            <BtnSecondary label="Cancel" onClick={CloseModal}/>

                            {/* 폴더 생성 */}
                            {CreateModal['data']['type']==='Create Folder' &&
                                <BtnPrimary label="Create" onClick={(e)=>{
                                    const {name, path} = CreateModal.data
                                    if(name === ''){
                                        setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:'입력해주세요.'}}))
                                        return;
                                    }

                                    CreateFolder(`${path}\\${name}`)
                                        .then((res)=>{
                                            if(res === false){
                                                setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:'이미 존재하는 폴더 입니다.'}}))
                                            }else{
                                                CloseModal();
                                                GetDocumentList();
                                            }
                                        }).catch((err)=>{
                                            setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:err}}))
                                        });
                                }}/>
                            }

                            {/* 파일 생성 */}
                            {CreateModal['data']['type']==='Create File' &&
                                <BtnPrimary label="Create" onClick={(e)=>{
                                    const {name, path} = CreateModal.data
                                    if(name === ''){
                                        setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:'입력해주세요.'}}))
                                        return;
                                    }
    
                                    CreateFile(`${path}\\${name}.md`)
                                        .then((res)=>{
                                            if(res === false){
                                                setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:'이미 존재하는 파일 입니다.'}}))
                                            }else{
                                                CloseModal();
                                                GetDocumentList();
                                            }
                                        }).catch((err)=>{
                                            setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:err}}))
                                        });
                                }}/>
                            }

                            {/* 이름 변경 */}
                            {CreateModal['data']['type']==='Rename' &&
                                <BtnPrimary label="Rename" onClick={(e)=>{
                                    const {name, path} = CreateModal.data
                                    if(name === ''){
                                        setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:'입력해주세요.'}}))
                                        return;
                                    }

                                    const ArrPath = path.split('\\');
                                    const oldName = ArrPath.pop();
                                    const Extension = CreateModal['data']['extension'];
                                    const newName = oldName.search(`.${Extension}`) === -1 ? name : `${name}.${Extension}`;

                                    RenameDocument(ArrPath.join('\\'), oldName, newName)
                                        .then((res)=>{
                                            if(res === 'success'){
                                                CloseModal()
                                                GetDocumentList();
                                            }else{
                                                setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:'이미 사용중인 이름 입니다.'}}))
                                            }
                                        }).catch((err)=>{
                                            setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:err}}))
                                        });
                                }}/>
                            }
                        </ModalBottomBox>
                    </Modal>
                </CreateModalContent>
            }

            {/* 모달 창 파일 업로드 */}
            {(CreateModal['visibility'] && CreateModal['data']['type']==='Upload File') &&
                <CreateModalContent>
                    <BgPopup onClick={CloseModal}/>
                    <Modal _title={CreateModal['data']['type']} _height={182} _width={200} _enableResizing={false} _closeModal={CloseModal}>
                        <FlexContent>
                            <InputContent style={{ width: '100%' }} type={"file"} onChange={handleOnChange}/>
                        </FlexContent>
                        <ModalBottomBox>
                            <WarningMsg>{CreateModal['data']['msg']}</WarningMsg>
                            <BtnSecondary label="Cancel" onClick={CloseModal} />
                            <BtnPrimary label="Upload" onClick={(e) => {
                                if(fileData === ''){
                                    setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:'입력해주세요.'}}))
                                    return;
                                }

                                UploadDoc(fileData, CreateModal['data']['path'])
                                    .then((res)=>{
                                        CloseModal();
                                        GetDocumentList();
                                    }).catch((err)=>{
                                        setCreateModal(prevState => ({...prevState, data:{...prevState.data, msg:err}}))
                                    });
                            }} />
                        </ModalBottomBox>
                    </Modal>
                </CreateModalContent>
            }
        </div>
    )
}

//스타일---------------------------------------------
const CreateModalContent = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 10;
`;

const FlexContent = styled.div`
    display: flex;
    align-items: baseline;

    &>span{
        margin-left: 8px;
    }
`;
