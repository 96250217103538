import React, { useState } from "react";

import styled, { keyframes } from "styled-components";

const useRippleEffect = () => {
    const [ripplePos, setRipplePos] = useState({ left: 0, top: 0 });
    const [isAnimating, setIsAnimating] = useState(false);

    const handleButtonClick = (event, onClick) => {
        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        setRipplePos({ left: x, top: y });
        setIsAnimating(true);
        onClick && onClick(event)
      };

    const BackSpanTag = (
        <span className='RippleEffect'
            style={{ left: ripplePos.left, top: ripplePos.top }}
            onAnimationEnd={() =>{
                 setIsAnimating(false)
            }}
        />
    )

    return { isAnimating, handleButtonClick, BackSpanTag };
};

export default function Button({ label, onClick, type ,...props }) {
    const { isAnimating, handleButtonClick, BackSpanTag} = useRippleEffect();
    return (
        <Secondary className={type} {...props} onClick={(e) => handleButtonClick(e, onClick)}>
            {label}
            {isAnimating && (BackSpanTag)}
        </Secondary>
    )
}

export function BtnPrimary({ label, onClick, type ,...props }) {
    const { isAnimating, handleButtonClick, BackSpanTag} = useRippleEffect();
    return (
        <Primary className={type} {...props}onClick={(e) => handleButtonClick(e, onClick)}>
            {label}
            {isAnimating && (BackSpanTag)}
        </Primary>
    );
}

export function BtnSecondary({ label, onClick, type ,...props }) {
    const { isAnimating, handleButtonClick, BackSpanTag} = useRippleEffect();
    return (
        <Secondary className={type} {...props} onClick={(e) => handleButtonClick(e, onClick)}>
            {label}
            {isAnimating && (BackSpanTag)}
        </Secondary>
    )
}

export function BtnGhost({ label, onClick, type ,...props }) {
    const { isAnimating, handleButtonClick, BackSpanTag} = useRippleEffect();
    return (
        <Ghost className={type} {...props} onClick={(e) => handleButtonClick(e, onClick)}>
            {label}
            {isAnimating && (BackSpanTag)}
        </Ghost>
    )
}

export function BtnText({ label, onClick, type ,...props }) {
    const { isAnimating, handleButtonClick, BackSpanTag} = useRippleEffect();
    return (
        <Text className={type} {...props} onClick={(e) => handleButtonClick(e, onClick)}>
            {label}
            {isAnimating && (BackSpanTag)}
        </Text>
    )
}

export function BtnSubDanger({ label, onClick, type ,...props }) {
    const { isAnimating, handleButtonClick, BackSpanTag} = useRippleEffect();
    return (
        <SubDanger className={type} {...props} onClick={(e) => handleButtonClick(e, onClick)}>
            {label}
            {isAnimating && (BackSpanTag)}
        </SubDanger>
    )
}


//스타일------------------------------
const ButtonBase = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    padding: 8px 16px;
    font-size: .9rem;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    cursor: pointer;
    color: ${({ theme }) => theme.base.font_color_Black};
    overflow: hidden;

    &.sm{
        height: 28px;
        padding: 2px 8px;
    }

    &>svg{
        font-size: 1.2rem;
    }

    &:disabled, &:disabled:hover {
        cursor: default;
        color: ${({ theme }) => theme.base.font_color_Gray};
        border-color: transparent;
        background-color: ${({ theme }) => theme.base.background_color_PaleGray};

        & > svg {
            opacity: .3;
        }
    }

    .RippleEffect{
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        animation: ${keyframes`
            to {
            width: 300px;
            height: 300px;
            opacity: 0;
            }
        `} 0.6s linear;
        pointer-events: none;
    }
`;

const Primary = styled(ButtonBase)`
    background-color: ${({ theme }) => theme.base.background_color_Blue};
    border-color: transparent;
    color: #fff;

    &:hover {
        background-color: ${({ theme }) => theme.base.background_color_DarkBlue};
    }

    .RippleEffect{
        background-color: ${({ theme }) => theme.base.background_color_Blue};
    }
`;

const Secondary = styled(ButtonBase)`
    background-color: #FFF;
    border-color: ${({ theme }) => theme.base.border_color_Gray};
    color: ${({ theme }) => theme.base.font_color_Black};

    &:hover{
        background-color: ${({ theme }) => theme.base.background_color_PaleGray};
    }

    .RippleEffect{
        background-color: ${({ theme }) => theme.base.background_color_Gray};
    }
`;

const Ghost = styled(ButtonBase)`
    background-color: #FFF;
    border-color: ${({ theme }) => theme.base.border_color_Blue};
    color: ${({ theme }) => theme.base.font_color_DarkBlue};

    &:hover{
        background-color: ${({ theme }) => theme.base.background_color_LightBlue};
    }

    .RippleEffect{
        background-color: ${({ theme }) => theme.base.background_color_Blue};
    }
`;

const Text = styled(ButtonBase)`
    background-color: transparent;
    border-color: transparent;
    color: ${({ theme }) => theme.base.font_color_DarkBlue};

    &:hover{
        /* background-color: ${({ theme }) => theme.base.background_color_LightBlue}; */
    }
    
    .RippleEffect{
        background-color: #fff;
    }
`;

const SubDanger = styled(ButtonBase)`
    background-color: #FFF;
    border-color: ${({theme}) => theme.base.border_color_Red};
    color: ${({ theme }) => theme.base.font_color_Red};

    svg{
        font-size: 1rem;
    }

    &:hover{
        background-color: ${({ theme }) => theme.base.background_color_LightRed};
    }
    
    .RippleEffect{
        background-color: ${({ theme }) => theme.base.font_color_Red};
    }
`;