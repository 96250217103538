import styled from "styled-components";
import PropTypes from 'prop-types';

export default function TabBar({_selectedMenu ,_MenuList, ...props}){
    return(
        <TabBarContent>
            <ul>
                {_MenuList.map((items)=> 
                    <TabBarItem 
                        className={items === _selectedMenu ? "selectTab" : ""} 
                        key={items} 
                        {...props}
                    >
                        {items}
                    </TabBarItem>
                )}
            </ul>
        </TabBarContent>
    )
}

TabBar.prototype = {
    _selectedMenu :PropTypes.string.isRequired,  //선택된 메뉴
    _MenuList:PropTypes.array,                  // 메뉴명 리스트
}

// 스타일-----------------------------------------------------
const TabBarContent = styled.div`
    &>ul{
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        padding:0 8px;
        height: 40px;
        border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
        cursor: default;
    }
`;

const TabBarItem = styled.li`
    color: ${({theme}) => theme.base.font_color_Black};
    padding: 4px 8px;
    opacity: .6;
    cursor: pointer;
    user-select: none;

    &.selectTab{
        opacity: 1;
        border-radius: 12px;
        font-weight: 500;
    }
    &:hover{
        opacity: 1;
    }
`;