import { useState, useEffect, useCallback } from "react";

import {BtnGhost, BtnPrimary, BtnSecondary} from "components/atom/Button"
import { InputContent, WarningMsg } from 'components/css/common';
import {GetSearchData, GetTemplateSubResource} from 'api/TemplateApi';
import {InputWithVerticalLabel} from "components/molecule/InputFieldLabel"
import {CreateProject} from 'api/RepositoryApi'
import { useNavigate } from 'react-router-dom'

import {PaginationBar} from 'components/css/common';

import styled from "styled-components"
import { MdOutlineFirstPage, MdOutlineLastPage, MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";

const PAGE_LIMIT = 100;
const SHOW_PAGING_NUMBER = 5;
const PAGE_OFFSET = 1;

export default function TemplateContent({_DataState}){
    const [Search, SetSearch] = useState('');
    const [TagList, ] = useState([]);
    const [SelectedTag, SetSelectedTag] = useState('ALL');
    const [SelectedTemplate, SetSelectedTemplate] = useState('');
    const [StepIndex, setStepIndex] = useState(0);
    const [newProjectForm, setNewProjectForm] = useState({
        projectName: '',
        projectTitle: '',
        description: '',
        IsOpenRuntime: 0,
    });
    const { projectName, projectTitle, description } = newProjectForm;
    const [warningMsg, setWarningMsg] = useState('');
    const navigate = useNavigate();
    const [TemplatePictureData, setTemplatePictureData] = useState();
    const [TemplateInfoData, setTemplateInfoData] = useState();

    const userId = localStorage.getItem('systemId');
;
    const [ContentsInfo, setContentsInfo] = useState({});
    const [BoardList, setBoardList] = useState();
    const [CurrentPage, setCurrentPage] = useState(0);
    const [PageNumber, setPageNumber] = useState([0]);

    const GetSearchResource = useCallback((keyword, offset, isTemplate=true, isSymbol=true, isPicture=true, isImage=true) => {
        const Keyword = keyword === '' ? '*' : keyword;
        GetSearchData(Keyword, PAGE_LIMIT, offset, isTemplate, isSymbol, isPicture, isImage, false, [])
            .then((res) => {
                const {result, msg} = res.data;
                if(result === 'Success'){
                    if(msg.length === 0){
                        setContentsInfo({});
                        setBoardList();
                    }else{
                        const {MAX_PAGE, MAX_NUM, SEARCH_LIST } = msg[0];
                        setContentsInfo({ MAX_NUM, MAX_PAGE });
                        setBoardList(SEARCH_LIST);
                    }
                }
            })
    }, []);

    useEffect(()=>{
        GetSearchResource('', 0, true, false, false, false)
    }, [GetSearchResource]);

    useEffect(()=>{
        const pagesArray = Array.from({ length: ContentsInfo.MAX_PAGE }, (_, index) => index);
        setPageNumber(pagesArray);
    }, [ContentsInfo]);

    const GetTemplateList = (TagName = 'ALL') =>{
        // Axios.post('/api/GetTemplatesList', {TagName})
        //     .then(res => {
        //         setPosts(res.data);
        //         SetTemplateList(res.data);
        //     }).catch(err => {
        //         console.error(err)
        //     })
    }

    useEffect(()=>{
        // Axios.post('/api/GetTemplatesTagList')
        //     .then(res => {
        //         SetTagList(res.data);
        //         GetTemplateList();
        //     }).catch(err => {
        //         console.error(err)
        //     })
    }, []);

    const SelectedTagHandler = (TagName) =>{
        SetSelectedTag(TagName);
        GetTemplateList(TagName)
    }

    const SelectedTemplateHandler = (e, TemplateID) =>{
        if(SelectedTemplate === TemplateID){
            SetSelectedTemplate('');
        }else
            SetSelectedTemplate(TemplateID);
    }

    const SearchEvent = (e) => async () =>{
        GetSearchResource(Search, 0, true, false, false, false);
    }

    const newProject = (e) =>{

        if(projectName === ''){
            setWarningMsg('프로젝트명을 입력해주세요.');
            return;
        }

        const projectNameRegex = /^[a-zA-Z0-9_\s-]*$/;
        if (!projectNameRegex.test(projectName)) {
            setWarningMsg('영문으로만 가능하며, 특수 문자는 사용할 수 없습니다.');
            return;
        }

        if(projectTitle === ''){
            setWarningMsg('프로젝트 타이틀을 입력해주세요.');
            return;
        }
        
        setWarningMsg('');
    };

    const GetTemplatePictureList = () => {
        const Target = BoardList.filter(item => item.SYS_ID === SelectedTemplate);

        GetTemplateSubResource(Target[0].TEMPLATE_SYSTEMID, String(Target[0].VERSION))
            .then(res => {
                const { result, msg, data } = res.data;
                if(result !== 'Success')
                    window.AddAlertMsg({ type: 'error', msg: msg })

                const picture = JSON.parse(data.picture).map(item => ({
                    sys_id: item.PICTURE_SYSTEMID,
                    title: item.TITLE
                }));

                const templateJson = {
                    picture,
                    tem_sys_id: Target[0].TEMPLATE_SYSTEMID,
                    tem_version: Target[0].VERSION,
                };

                setTemplatePictureData(templateJson);

                const {TEMPLATE_SYSTEMID, TITLE, DESCRIPTION, VERSION, DATE, NICKNAME} = Target[0];
                const templateInfo = {
                    templateInfo: {TEMPLATE_SYSTEMID, TITLE, DESCRIPTION, VERSION, DATE, NICKNAME},
                    templateImage: JSON.parse(data.image),
                    templateSymbol: JSON.parse(data.symbol),
                };
                
                setTemplateInfoData(JSON.stringify(templateInfo));
                setStepIndex(1);
            })
            .catch(err => {
                window.AddAlertMsg({ type: 'error', msg: err })
            });
    }

    const CreateTemplateProject = (e) => {
        CreateProject(userId, projectName, projectTitle, description)
            .then(res => {
                const { result, msg } = res.data;
                if (result === 'Success') {
                    localStorage.setItem('projectId', msg);
                    localStorage.removeItem('publishId');
                    navigate('/MyRepository/templateMode', { state: { userId, projectId: msg, projectName, templateJson:TemplatePictureData, tempImageInfo:TemplateInfoData } });
                } else {
                    setWarningMsg(`프로젝트 생성에 실패하였습니다. ${msg}`);
                    return;
                }
            })
            .catch(err => {
                setWarningMsg(`프로젝트 생성시 오류: ${err}`);
                return;
            });
    };

    const MovePage = (_type) =>{
        switch (_type) {
            case 'FirstPage':
                setCurrentPage(0);
                break;
            case 'PrevPage':
                if(CurrentPage >= 1){
                    setCurrentPage(CurrentPage-1);
                }
                break;
            case 'NextPage':
                if(CurrentPage < ContentsInfo.MAX_PAGE-1){
                    setCurrentPage(CurrentPage+1);
                }
                break;
            case 'LastPage':
                setCurrentPage(ContentsInfo.MAX_PAGE-1);
                break;
            default:
                break;
        }
    }

    const Pagination = () => {
        const maxPageNumber = PageNumber[PageNumber.length - 1];
        const flagNumber = Math.floor(SHOW_PAGING_NUMBER / 2);
        let startNumber = Math.max(CurrentPage - flagNumber, 0);
        let lastNumber = Math.min(CurrentPage + flagNumber, maxPageNumber);

        if(startNumber === 0){
            lastNumber = SHOW_PAGING_NUMBER - PAGE_OFFSET;
        }else if(lastNumber === maxPageNumber){
            startNumber = maxPageNumber - (SHOW_PAGING_NUMBER - PAGE_OFFSET);
        }

        return (
          <ul className="pagination">
            {PageNumber.map((pageNum) => {
                if(pageNum >= startNumber && pageNum <= lastNumber){
                    return (
                        <li
                            key={pageNum}
                            className={pageNum === CurrentPage ? 'selectPage' : ''}
                            onClick={() =>setCurrentPage(pageNum)}
                        >
                            {pageNum + PAGE_OFFSET}
                        </li>
                    )
                }
                return null;
            })}
          </ul>
        );
    };

    switch(StepIndex){
        case 1:
            const Target = BoardList.filter(item => item.SYS_ID === SelectedTemplate);
            const { TITLE, DESCRIPTION, THUMBNAIL, DATE} = Target[0];
            const imgSrc = `data:image/png;base64,${THUMBNAIL}`
            return(
                <NewProjectInfoStep>
                    <SelectedTemplateInfo>
                        <div className="TemplateInfo">
                            <div className="projectImgBox" >
                                <img src={imgSrc} alt="" onError={(e) => { e.target.style.display = 'none' }} />
                            </div>
                            <div className='infoBox'>
                                <div className="projectTitle">{`${TITLE}`}</div>
                                <div className="description" title={DESCRIPTION}>
                                    {DESCRIPTION}
                                </div>
                                <div className="projectDate">
                                    <span>{DATE}</span>
                                </div>
                            </div>
                        </div>
                    </SelectedTemplateInfo>
                    <NewProjectInfoBox>
                        <InputWithVerticalLabel id='projectName' label='Project Name' propsState={[newProjectForm, setNewProjectForm]} placeholder='Project Name' isRequired={true} />
                        <InputWithVerticalLabel id='projectTitle' label='Project Title' propsState={[newProjectForm, setNewProjectForm]} placeholder='Project Title' isRequired={true} onKeyDownEvent={newProject} />
                        <InputWithVerticalLabel id='description' label='Description' propsState={[newProjectForm, setNewProjectForm]} placeholder='Description' onKeyDownEvent={newProject} />
                    </NewProjectInfoBox>
                    {warningMsg && <WarningMsg>{warningMsg}</WarningMsg>}
                    <TemplateBottom>
                        <BtnSecondary label="Prev" onClick={(e) => setStepIndex(0)} />
                        <BtnPrimary label="Ok" onClick={CreateTemplateProject} />
                    </TemplateBottom>
                </NewProjectInfoStep>
            )
        case 0:
        default:
            return(
                <TemplateLayout>
                    <TemplateMenu>
                        <div id="TemplatePageTitle">Templates</div>
        
                        <div className="TagType">
                            <ul>
                                {TagList.length>0 && TagList.map((item, index) =>{
                                    const {TAG_NAME, COUNT} = item;
                                    const ClassName = SelectedTag === TAG_NAME ? "SelectedTag" : "";
        
                                    return( <li key={TAG_NAME} className={ClassName} onClick={(e)=>SelectedTagHandler(TAG_NAME)}>{TAG_NAME}<span>{COUNT}</span></li> )
                                })}
                            </ul>
                        </div>
                    </TemplateMenu>
        
                    <TemplateSearchBorder>
                        <InputContent type="text" id='Search' name='Search' value={Search} placeholder='Keyword....' onChange={(e) => SetSearch(e.target.value)} />
                        <BtnGhost label="Search" onClick={SearchEvent()}/>
                    </TemplateSearchBorder>
        
                    <TemplateListBox>
                        <section className="projectListBody">
                            {BoardList &&
                                BoardList.map((item, index) =>{
                                    const {THUMBNAIL, SYS_ID, TITLE, DESCRIPTION, DATE} = item;
                                    const imgSrc = `data:image/png;base64,${THUMBNAIL}`
                                    const ClassName = SelectedTemplate === SYS_ID ? "SelectedTemplate" : "";
                    
                                    return(
                                        <TemplateCard key={SYS_ID} className={ClassName} onClick={(e)=>SelectedTemplateHandler(e, SYS_ID)}>
                                            <div className="projectImgBox" >
                                                <img src={imgSrc} alt="" onError={(e) => { e.target.style.display = 'none' }} />
                                            </div>
                                            <div className='infoBox'>
                                                <img src={`/profile/${'PROFILE_SYSTEMID'}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                                                <div>
                                                    <div className='title'>
                                                        <div className="projectTitle">{`${TITLE}`}</div>
                                                        <div className="creator">{`${'관리자'}`}</div>
                                                    </div>
                                                    <div className="description" title={DESCRIPTION}>
                                                        {DESCRIPTION}
                                                    </div>
                                                    <div className='subInfo'>
                                                        <div className="projectDate">
                                                            <span>{DATE}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TemplateCard>
                                    )
                                })
                            }
                        </section>
                    </TemplateListBox>
        
                    <TemplateBottom>
                        <PaginationBar>
                            <span onClick={(e) => MovePage('FirstPage')}><MdOutlineFirstPage /></span>
                            <span onClick={(e) => MovePage('PrevPage')}><MdOutlineChevronLeft /></span>
                            <Pagination />
                            <span onClick={(e) => MovePage('NextPage')}><MdOutlineChevronRight /></span>
                            <span onClick={(e) => MovePage('LastPage')}><MdOutlineLastPage /></span>
                        </PaginationBar>
                        <div className="BtnContentBox">
                            <BtnPrimary label="Next" disabled={SelectedTemplate === ''} onClick={GetTemplatePictureList}/>
                        </div>
                    </TemplateBottom>
                </TemplateLayout>
            )
    }
}

const TemplateLayout = styled.div`
    display: grid;
    height: 100%;
    min-height: 600px;
    grid-template-columns: 220px 1fr;
    grid-template-rows: 60px 1fr 70px;
`;

const TemplateMenu = styled.div`
    grid-row-start: 1;
    grid-row-end: 4;

    border-right: 1px solid ${({theme}) => theme.base.border_color_Gray};
    box-sizing: border-box;

    #TemplatePageTitle{
        font-size: 1.2rem;
        color: ${({theme}) => theme.base.font_color_DarkGray};
        padding: 20px 24px;
        height: 60px;
        box-sizing: border-box;
    }

    .TagType{
        ul{
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 16px;
                height: auto;
                font-size: 1rem;
                color: ${({theme}) => theme.base.font_color_Black};
                box-sizing: border-box;
                cursor: pointer;

                span{
                    font-size: 1rem;
                    padding: 4px 12px;
                    color: ${({theme}) => theme.base.font_color_DarkBlue};
                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                    border-radius: 15px;
                }

                &:hover{
                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                }

                &.SelectedTag{
                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                    font-weight: bold;
                    color: ${({theme}) => theme.base.font_color_DarkBlue};

                    span{
                        color: #fff;
                        background-color: ${({theme}) => theme.base.font_color_DarkBlue};
                    }
                }
            }
        }
    }
`;

const TemplateSearchBorder = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 10px 24px;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
    box-sizing: border-box;
`;

const TemplateListBox = styled.div`
    position: relative;
    height: 100%;
    overflow: overlay;
    background-color: ${({theme}) => theme.base.background_color_PaleGray};
    
    .projectListBody{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 12px 16px;
        padding: 16px 24px;
        box-sizing: border-box;
    }
`;

const TemplateBottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${({theme}) => theme.base.border_color_Gray};
    padding: 10px 24px;
    box-sizing: border-box;

    &>div:not(.BtnContentBox){
        margin-top: 0;
    }
`;

const CredContent = styled.div`
    position: relative;
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    min-width: 240px;
    height: auto;
    
    
    @media screen and (min-width:1024px) {
        width: calc(100% / 4 - 12px);
    } 
    
    @media screen and (max-width:1090px) {
        width: calc(100% / 3 - 11px);
    } 

    @media screen and (max-width:850px) {
        width: calc(100% / 2 - 8px);
    }

    @media screen and (max-width:560px) {
        width: calc(100%);
    }
`;

const TemplateCard = styled(CredContent)`
    cursor: pointer;

    &.SelectedTemplate{
        border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
        box-sizing: border-box;
    }

    .projectImgBox{
        position: relative;
        width: 100%;
        height: 110px;
        background-color: ${({ theme }) => theme.base.background_color_Gray};
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 4px;
        overflow: hidden;

        &>img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        img:not([src]) {
            display: none;
        }
    }


    .description{
        margin-block: 8px;
        font-size: .85rem;
        width: 100%;
        height: 40px;
        line-height: 1.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical
    }

    .infoBox{
        display: flex;
        box-sizing: border-box;
        margin-top: 12px;
        gap: 8px;

        img{
            width: 38px;
            height: 38px;
            overflow: hidden;
            border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
            border-radius: 50%;
            box-sizing: border-box;
        }

        &>div{
            flex-grow: 1;
            width: calc(100% - 46px);
            color: ${({ theme }) => theme.base.font_color_Black};

            .title{
                width: 100%;

                .projectTitle{
                    font-size: 1rem;
                    font-weight: bold;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .creator{
                    margin-top: 8px;
                    font-size: .85rem;
                    opacity: .6;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .subInfo{
                width: 100%;
                min-height: 20px;
                margin-top: 4px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                

                .projectDate{
                    font-size: .8rem;
                    opacity: .6;

                    span:first-child{
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    .buttons{
        display: flex;
        z-index: 0;
        gap: 8px;
    }

    @media screen and (min-width:1024px) {
        width: calc(100% / 4 - 12px);
    } 
    
    @media screen and (max-width:1090px) {
        width: calc(100% / 3 - 11px);
    } 

    @media screen and (max-width:850px) {
        width: calc(100%);
    }

    @media screen and (max-width:560px) {
        width: calc(100%);
    }
`;

const NewProjectInfoStep = styled.div`
    display: grid;
    height: 100%;
    min-height: 600px;
    grid-template-columns: 1fr;
    grid-template-rows: 148px 1fr 70px;
`;

const SelectedTemplateInfo = styled.div`
    position: relative;
    

    .TemplateInfo{
        display: flex;
        max-width: 400px;
        height: auto;
        margin: 0 auto;
        gap: 16px;
        padding: 16px 0;
        box-sizing: border-box;
        border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};

        .projectImgBox{
            position: relative;
            width: 180px;
            height: auto;
            background-color: ${({ theme }) => theme.base.background_color_Gray};
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            border-radius: 4px;
            overflow: hidden;

            &>img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            img:not([src]) {
                display: none;
            }
        }

        .infoBox{
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            flex-grow: 1;
            margin-top: 12px;
            gap: 8px;

            &>div{
                flex-grow: 1;
                width: calc(100% - 46px);
                color: ${({ theme }) => theme.base.font_color_Black};
            }

            .projectTitle{
                font-size: 1rem;
                font-weight: bold;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .description{
                margin-block: 8px;
                font-size: .85rem;
                width: 100%;
                height: 40px;
                line-height: 1.3rem;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical
            }

            .projectDate{
                font-size: .8rem;
                opacity: .6;
            }
        }
    }
`;

const NewProjectInfoBox = styled.div`
    position: relative;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    width: 100%;
    margin-top: 16px;

    &>div{
        margin: 0 auto;
        max-width: 400px;
    }
`;