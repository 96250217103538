import { useEffect} from "react";

export default function useEnuCanvasFun({
        enuSpace,
        messages,
        RequestMsg,
        setMessages,
}){
    useEffect(() => {
        const firstMessage = messages[0];
        if (!firstMessage) return;

        const strFunc = Object.keys(firstMessage)[0];
        const strJson = JSON.stringify(Object.values(firstMessage)[0]);

        if (enuSpace === '' || strFunc === undefined || strJson === undefined) return;
        enuSpace.CanvasResponse(strFunc, strJson);

        setMessages((prevMessages) => prevMessages.slice(1))
    }, [enuSpace, messages, setMessages]);

    window.Re_Canvas_CreateNode = (PARENT_SYS_ID, NODE_SYS_ID, TAG, TYPE, SVG_SYS_ID) => {
        RequestMsg("CreateNode", {
            PARENT_SYS_ID,
            NODE_SYS_ID,
            TYPE,
            TAG,
            SVG_SYS_ID
        });
    }

    window.Re_Canvas_DeleteNode = (NODE_SYS_ID, SVG_SYS_ID) => {
        RequestMsg("DeleteNode", {
            NODE_SYS_ID,
            SVG_SYS_ID
        });
    }

    window.Re_Canvas_SelectNode = (nodeID, SVG_SYS_ID) => {
        RequestMsg("SelectNode", {
            NODE_SYS_ID_LIST: [nodeID],
            SVG_SYS_ID
        });
    }

    window.Re_Canvas_DeselectNode = (nodeID, SVG_SYS_ID) => {
        RequestMsg("DeselectNode", {
            NODE_SYS_ID_LIST: [nodeID],
            SVG_SYS_ID
        });
    }

    window.Re_Canvas_CreateTemp = (SVG_SYS_ID, NODE_ID, TAG) => {
        RequestMsg("CreateTemp", {
            SVG_SYS_ID,
            NODE_ID,
            TAG
        });
    }

    window.Re_Canvas_UpdateNode = (NODE_SYS_ID, TAG, ATTRIBUTE_JSON, SVG_SYS_ID, isTemp) => {
        if(!isTemp)
        {
            RequestMsg("UpdateNode", {
                NODE_SYS_ID,
                TAG,
                ATTRIBUTE_JSON,
                SVG_SYS_ID
            });
        }
        else
        {
            RequestMsg("UpdateTemp", {
                NODE_SYS_ID,
                ATTRIBUTE_JSON,
                SVG_SYS_ID
            });
        }
    }

    window.Re_Canvas_CursorPoint = (POS_X, POS_Y, SVG_SYS_ID) => {
        RequestMsg("CursorPoint", { POS_X, POS_Y, SVG_SYS_ID});
    }

    window.Re_Canvas_CreateSVG = (SVG_SYS_ID, SVG_TYPE, SVG_NAME, SVG_TAG) => {
        RequestMsg("CreateSVG", {
            SVG_SYS_ID,
            SVG_TYPE,
            SVG_NAME,
            SVG_TAG
        });
    }

    window.Re_Canvas_DeleteSVG = (SVG_SYS_ID) => {
        RequestMsg("DeleteSVG", {
            SVG_SYS_ID
        });
    }

    window.Re_Canvas_MoveSVG = (AFT_SVG_SYS_ID) => {
        RequestMsg("MoveSVG", {
            AFT_SVG_SYS_ID : AFT_SVG_SYS_ID
        });
    }

    return null;
}