import { useState, useEffect } from "react";

import styled from "styled-components"
import { MdSearch } from "react-icons/md";

export default function SearchBar({searchEvent, initKeyword = '' ,isReset=true ,placeholder='검색어를 입력하세요.', ...props}){
    const [searchText, setSearchText] = useState('');

    useEffect(()=>{
        if(initKeyword !== '')
            setSearchText(initKeyword);
    }, [initKeyword]);

    const handleSearch = (e) => {
        searchEvent && searchEvent(searchText);

        if(isReset)
            setSearchText('');
    }
    return(
        <SearchBarBase {...props}>
            <input id="chatbot" type="text" placeholder={placeholder} title="Search"
                value={searchText} 
                onChange={(e)=>setSearchText(e.target.value)}
                onKeyDown={(e)=>{
                    if(e.key === 'Enter')
                        handleSearch();
                }}
                />
            <MdSearch className="BtnSearch" title="Search" onClick={handleSearch}/>
        </SearchBarBase>
    )
}

export function SearchBarWithOption({optionList, searchEvent}){
    const [searchType, setSearchType] = useState();
    const [searchText, setSearchText] = useState('');

    useEffect(()=>{
        if(optionList.length < 0) return;

        setSearchType(optionList[0].value);
    }, [optionList]);

    return(
        <SearchBarBase>
            <select value={searchType} onChange={(e)=>setSearchType(e.target.value)}>
                {optionList &&
                    optionList.map((option)=>{
                        const {value, name} = option
                        return <option key={value} value={value}>{name}</option>
                    })
                }
            </select>
            <input type="text" placeholder="검색어를 입력하세요." 
                value={searchText} 
                onChange={(e)=>setSearchText(e.target.value)}
                onKeyDown={(e)=>{
                    if(e.key === 'Enter'){
                        searchEvent && searchEvent(searchType, searchText);
                    }
                }}
                />
            <MdSearch className="BtnSearch" onClick={(e)=> searchEvent && searchEvent(searchType, searchText)}/>
        </SearchBarBase>
    )
}

const SearchBarBase = styled.div`
    display: flex;
    height: 100%;
    min-width: 160px;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid ${({theme}) => theme.base.border_color_DarkGray};
    border-radius: 4px;

    &:focus-within {
        border-color: ${({theme}) => theme.base.border_color_Blue};
        background-color: #fff;
    }

    select{
        position: relative;
        width: auto;
        height: 100%;
        border: none;
        background-color: transparent;
        padding: 0 4px;
        cursor: pointer;
        margin-right: 6px;

        &:focus{
            outline: none;
        }
    }

    input{
        height: 100%;
        width: calc(100px - 30px);
        flex-grow: 1;
        box-sizing: border-box;
        border: none;
        background-color: transparent;
        padding: 0 4px;

        &:focus{
            outline: none;
        }

        &::placeholder{
            opacity: .6;
        }
    }

    .BtnSearch{
        height: 100%;
        width: 30px;
        padding: 2px;
        color: ${({theme}) => theme.base.border_color_DarkGray};
        box-sizing: border-box;
        cursor: pointer;

        &:hover{
            color: ${({theme}) => theme.base.font_color_Blue};
        }
    }
`;