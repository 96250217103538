import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import useModalPopup from 'hooks/useModalPopup';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart } from 'chart.js/auto'; 
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';
import 'moment-timezone';
import * as adapter from 'chartjs-adapter-date-fns';

import {BgPopup, Popup, ModalBottomBox, TableContent, GroupName} from 'components/css/common'
import { BtnSecondary} from "components/atom/Button";
import { GetTagHistory, GetTagValues } from 'api/DeviceApi'

import { MdCalendarMonth, MdDownload, MdOutlinePlayCircleFilled, MdPauseCircleFilled, MdAlignHorizontalLeft, MdSettings, MdClear, MdModeEditOutline, MdArrowLeft, MdArrowRight } from "react-icons/md";
import styled from "styled-components"

const marginBottom = 16;

export default function ChartModal({_Modal}){
    const durationValue = parseInt(_Modal.data[0].TYPE === 'DYNAMIC' && _Modal.data[0].TIME.slice(0, -1));
    const durationUnit = _Modal.data[0].TYPE === 'DYNAMIC' && _Modal.data[0].TIME.slice(-1).toLowerCase();
    const initialStartDate = moment(new Date()).subtract(durationValue,durationUnit).toDate();
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(new Date());
    const [chartData, setChartData] = useState([]);
    const [hiddenTag, setHiddenTag] = useState([]);
    const [chartMode, setChartMode] = useState();
    const [xScaleChange, setXScaleChange] = useState(false);
    const [realtimeUpdating, setRealtimeUpdating] = useState(false);
    const [horizontal, setHorizontal] = useState({
        visibility:false,
        ID: '',
        yMin:'',
        yMax:'',
        Color:'',
    });
    const [colorModal, setColorModal] = useState({
        visibility:false,
        label:'',
        color:''
    })
    const [dataset, setDataset] = useState();
    const [horizontalList, setHorizontalList] = useState([]);
    const { ref } = useResizeDetector();
    const legendContainerRef = useRef(null);
    const chartRef = useRef(null);
    const {EditModalData} = useModalPopup();
    const [chartConfig, setChartConfig] = useState({
        visibility:false,
        yMin: '',
        yMax: '',
        xScale: (() => {
            const lastChar = _Modal.data[0].TYPE === 'DYNAMIC' &&_Modal.data[0].TIME.slice(-1).toLowerCase();
            switch (lastChar) {
                case 's':
                    return 'second';
                case 'm':
                    return 'minute';
                case 'h':
                    return 'hour';
                case 'd':
                    return 'day';
                default:
                    return 'minute';
                }
            })(),
            xStep:1,
            TitleLocation: 'top',
            LegendLocation : 'right',
            xTitle: 'Date',
            yTitle: 'Value',
            chartTitle : 'Title'
        });
        
        const ChangeSelect = (attributeValue) =>{
            setXScaleChange(true);
            setChartConfig(prevState => ({
                ...prevState,
            xScale: attributeValue,
        }));
    }
    const [_chartConfig, _setChartConfig] = useState(chartConfig);
    const chartCanvas = document.getElementById(`chart-canvas-${_Modal.id}`);
    let existingChart = Chart.getChart(chartCanvas);

    const removeDataset = useCallback((label) => {
        let dataArray = { ..._Modal };
        let ChartID = dataArray.data[0].CHARTID;
        if (!Array.isArray(dataArray.data)) {
            dataArray.data = Object.values(dataArray.data);
        }
        const filteredData = dataArray.data.filter(item => item.TAG !== label);
        dataArray.data = filteredData;
        if(dataArray.data.length > 0)EditModalData(ChartID,filteredData)
    },[EditModalData,_Modal])
    
    const createLegendItem = useCallback((dataset) => {
        // 범례 생성
        
        const legendItem = document.createElement('div');
        legendItem.id = 'custom-legend-item';
        legendItem.style.display = 'flex';
        const legendItems = document.querySelectorAll('#custom-legend-item');
        if(existingChart !== undefined && existingChart.options.plugins.legend._proxy._scopes[0].position === 'top')
        {
            legendItem.style.transform = `translate(${chartRef.current.width * 0.5 - 40 }px, -${chartRef.current.height + 32}px)`;
            existingChart.options.layout.padding._proxy._scopes[0].top = (legendItems.length + 1) * 20
            
        }
        else if (existingChart !== undefined && existingChart.options.plugins.legend._proxy._scopes[0].position === 'left')
        {  
            legendItem.style.transform = `translate(${'0'}px, -${chartRef.current.height}px)`;
            legendItem.style.marginTop = '4px';
            existingChart.options.layout.padding._proxy._scopes[0].top = 0;
        }
        else if (existingChart !== undefined && existingChart.options.plugins.legend._proxy._scopes[0].position === 'right')
        {
            legendItem.style.transform = `translate(${chartRef.current.width}px, -${chartRef.current.height}px)`;
            legendItem.style.marginTop = '4px';
            existingChart.options.layout.padding._proxy._scopes[0].left = 0;
            existingChart.options.layout.padding._proxy._scopes[0].top = 0;
        }
        else if (existingChart !== undefined && existingChart.options.plugins.legend._proxy._scopes[0].position === 'bottom')
        {
            existingChart.options.layout.padding._proxy._scopes[0].top = 0;
            legendItem.style.transform = `translate(${chartRef.current.width * 0.5 - 40 }px, 20px)`;
        }
        
        legendItem.style.width = '150px';
        legendItem.title = dataset.label;
        const colorBox = document.createElement('div');
        colorBox.id = dataset.label;
        colorBox.style.width = '12px';
        colorBox.style.height = '12px';
        colorBox.style.marginTop = '4px';
        colorBox.style.marginRight = '4px';
        colorBox.style.backgroundColor = `${dataset['backgroundColor']}`;
        const labelSpan = document.createElement('span');
        labelSpan.innerText = dataset.label;
        labelSpan.style.cursor = 'pointer';
        labelSpan.style.textDecoration = `${hiddenTag.includes(dataset.label)?'line-through':'none'}`;
        labelSpan.style.overflow = 'hidden';
        labelSpan.style.whiteSpace = 'nowrap';
        labelSpan.style.textOverflow = 'ellipsis';
        labelSpan.addEventListener('click', () => {             
            setHiddenTag(hiddenTag => {
                if (hiddenTag.includes(dataset.label)) {
                    return hiddenTag.filter(label => label !== dataset.label);
                }
                return [...hiddenTag, dataset.label];
            });
        });
        labelSpan.addEventListener('mouseenter', () => {
            labelSpan.style.opacity = '0.7';
        });
        labelSpan.addEventListener('mouseleave', () => {
            labelSpan.style.opacity = '1';
        });
    
        const removeButton = document.createElement('button');
        removeButton.innerText = 'X';
        removeButton.addEventListener('click', () => removeDataset(dataset.label));
        removeButton.style.border = 'none';
        removeButton.style.cursor = 'pointer';
        removeButton.style.marginLeft = '8px';
        removeButton.style.backgroundColor = `${dataset['backgroundColor']}`;
        removeButton.addEventListener('mouseenter', () => {
            removeButton.style.opacity = '0.7';
        });
        removeButton.addEventListener('mouseleave', () => {
            removeButton.style.opacity = '1';
        });
    
        legendItem.appendChild(colorBox);
        legendItem.appendChild(labelSpan);
        legendItem.appendChild(removeButton);

        legendContainerRef.current.appendChild(legendItem);
    },[hiddenTag, removeDataset, setHiddenTag, existingChart]);
    
    const createChartLegend = useCallback((datasets) => {
        datasets.forEach((dataset) => {
            createLegendItem(dataset);
        });
    },[createLegendItem]);

    const positionLegendContainer = (chartCanvas) => {
        legendContainerRef.current.style.top = `${chartCanvas.offsetTop}px`;
        legendContainerRef.current.style.left = `${chartCanvas.offsetLeft + chartCanvas.offsetWidth}px`;
    };

    const createAnnotation = () => {
        const hasDuplicateID = horizontalList.some(item => item.id === horizontal.ID);
        if (hasDuplicateID) {
            alert('동일한 ID는 사용할 수 없습니다.');
            return;
        }
        
        if(horizontal.ID === "" || horizontal.Color === "" || horizontal.yMin === "" || horizontal.yMax === "")
        {
            alert('각 항목을 작성해주세요.')
            return;
        }
        
        let annotation = {
            id : horizontal.ID,
            type: 'line',
            yMin: horizontal.yMin, 
            yMax: horizontal.yMax, 
            borderColor: horizontal.Color,
            borderWidth: 2,
        }
        let annotationScope;
        for (const scope of existingChart.options.plugins.annotation._proxy._scopes) {
            if (scope.annotations) {
                annotationScope = scope;
                break;
            }
        }

        if (annotationScope) {
            annotationScope.annotations = {
                ...annotationScope.annotations,
                [horizontal.ID] : annotation
            };
        } else {
            console.error('Annotations scope not found!');
        }
        const annotationObject = existingChart.options.plugins.annotation._proxy._scopes[0].annotations;
        const annotationArray = Object.values(annotationObject);
        setHorizontalList(annotationArray);
        existingChart.update();
    }

    const deleteAnnotation = (id) => {

        const updatedHorizontalList = {};
        for (const item of horizontalList) {
            if (item.id !== id) {
                updatedHorizontalList[item.id] = item;
            }
        }
        const annotationArray = Object.values(updatedHorizontalList);
        setHorizontalList(annotationArray);

        let annotationScope;
        for (const scope of existingChart.options.plugins.annotation._proxy._scopes) {
            if (scope.annotations) {
                annotationScope = scope;
                break;
            }
        }

        if (annotationScope) {
            annotationScope.annotations = updatedHorizontalList;
        } else {
            console.error('Annotations scope not found!');
        }
    }

    const changeChartSetting = async (chartConfig) => {
        if (existingChart) {
            existingChart.options.plugins.title._proxy._scopes[0].text = chartConfig.chartTitle; 
            existingChart.options.plugins.title._proxy._scopes[0].position = chartConfig.TitleLocation; 
            if(chartConfig.TitleLocation === 'right' || chartConfig.TitleLocation === 'bottom')
            {
                existingChart.options.layout.padding._proxy._scopes[0].top = 40
            }
            else
            {
                existingChart.options.layout.padding._proxy._scopes[0].top = 0
            }
            existingChart.options.plugins.legend._proxy._scopes[0].position = chartConfig.LegendLocation; 
    
            existingChart.options.scales.x._proxy._scopes[0].title.text = chartConfig.xTitle; 
    
            existingChart.options.scales.y._proxy._scopes[0].title.text = chartConfig.yTitle; 

            if(chartConfig.LegendLocation === 'top')
            {
                existingChart.options.layout.padding._proxy._scopes[0].left = 0
                // legendContainerRef.current.style.display = 'flex';
                // console.log(legendContainerRef.current.style)
            }
            else if (chartConfig.LegendLocation === 'left')
            {
                existingChart.options.layout.padding._proxy._scopes[0].left = 150
            }
            else if (chartConfig.LegendLocation === 'right')
            {
                existingChart.options.layout.padding._proxy._scopes[0].left = 0
            }
            else if (chartConfig.LegendLocation === 'bottom')
            {
                existingChart.options.layout.padding._proxy._scopes[0].left = 0
            }

            if(dataset)
            {
                existingChart.data.datasets = dataset;
            }
            existingChart.update();
            createChartLegend(existingChart.data.datasets);
        }
        setChartConfig(prevState => ({
            ...prevState,
            visibility: false,
        }));
    }

    const handleChangeType = (label, newType) => {
        const updatedDataset = dataset.map(item => {
            if (item.label === label) {
                return { ...item, type: newType };
            } else {
                return item;
            }
        });
        setDataset(updatedDataset);
    };

    const chartMoveLeft = async () => {
        if (realtimeUpdating) return;
        setRealtimeUpdating(true);
        let props = _Modal.data;
        if (!Array.isArray(_Modal.data)) {
            props = Object.values(_Modal.data);
        }
        let start_time;
        let end_time;
        let newDataArray = [...chartData];
        
        if(_Modal.data[0].TYPE === 'STATIC')
        {
            const timeDiff = (new Date(end_time) - new Date(start_time)) / 2;
            if(existingChart.options.scales.x.min === undefined || existingChart.options.scales.x.max === undefined)
            {
                const startTime = moment(new Date(start_time));
                const endTime = moment(new Date(end_time));
                const newStartTime = moment(startTime).subtract(timeDiff / 2, 'milliseconds')
                const newEndTime = endTime.add(timeDiff, 'milliseconds');
                existingChart.options.scales.x.min = new Date(newStartTime);
                existingChart.options.scales.x.max = new Date(newEndTime);
            }
            else
            {
                const newStartTime = moment(new Date(existingChart.options.scales.x.min)).subtract(timeDiff / 2, 'milliseconds');
                const newEndTime = moment(new Date(existingChart.options.scales.x.max)).subtract(timeDiff / 2, 'milliseconds');
                existingChart.options.scales.x.min = new Date(newStartTime);
                existingChart.options.scales.x.max = new Date(newEndTime);
            }
        }
        else
        {
            const promises = props.map(async(prop) => {
                if(prop.TYPE === 'DYNAMIC')
                {
                    if(existingChart.options.scales.x.min === undefined)
                    {
                        if (chartConfig['xScale'] === 'second') 
                        {
                            start_time = moment(chartData[0][0].TIMESTAMP).subtract(10, 'seconds').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                        else if(chartConfig['xScale'] === 'minute') 
                        {
                            start_time = moment(chartData[0][0].TIMESTAMP).subtract(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                        else if(chartConfig['xScale'] === 'hour') 
                        {
                            start_time = moment(chartData[0][0].TIMESTAMP).subtract(3, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                        else if(chartConfig['xScale'] === 'day')
                        {
                            start_time = moment(chartData[0][0].TIMESTAMP).subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        } 
                        else
                        {
                            start_time = moment(chartData[0][0].TIMESTAMP).subtract(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                    }
                    else
                    {
                        if (chartConfig['xScale'] === 'second') 
                        {
                            start_time = moment(existingChart.options.scales.x.min).subtract(10, 'seconds').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                        else if(chartConfig['xScale'] === 'minute') 
                        {
                            start_time = moment(existingChart.options.scales.x.min).subtract(5, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                        else if(chartConfig['xScale'] === 'hour') 
                        {
                            start_time = moment(existingChart.options.scales.x.min).subtract(3, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                        else if(chartConfig['xScale'] === 'day')
                        {
                            start_time = moment(existingChart.options.scales.x.min).subtract(1, 'day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        } 
                        else
                        {
                            start_time = moment(existingChart.options.scales.x.min).subtract(5, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                    }
                    if(existingChart.options.scales.x.min === undefined || existingChart.options.scales.x.max === undefined)
                    {
                        end_time = moment(chartData[0][0].TIMESTAMP).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                    }
                    else
                    {
                        end_time = moment(existingChart.options.scales.x.max).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                    }
                    const tagDataIndex = newDataArray.findIndex(data => data[0].TAG === prop.TAG);
                    
                    if (tagDataIndex !== -1 && !newDataArray[tagDataIndex].some(item => item.TIMESTAMP === start_time)) {
                        let duration = 1000;
                        if(chartConfig['xScale'] === 'hour') 
                        {
                            duration = 360000;
                        }
                        else if(chartConfig['xScale'] === 'day')
                        {
                            duration = 360000 * 24;
                        }
                        return await GetTagHistory(prop.MEMBER_SYSTEMID, prop.DB_TABLE_ID, prop.TAG, start_time, end_time, duration, 'UTC');
                    }
                }
            })
            Promise.all(promises)
                .then((res) => {
                    if(res[0] !== undefined)
                    {
                        const dataArray = res.map((item) => item.data.DATA);
                        dataArray.forEach((data) => {
                            for (let i = 0; i < data.length; i++) {
                                const item = data[i];
                                const tagIndex = newDataArray.findIndex(tagData => tagData[0].TAG === item.TAG);
                                if (tagIndex !== -1) {
                                    const isDuplicate = newDataArray[tagIndex].some(existingItem => existingItem.TIMESTAMP === item.TIMESTAMP);
                                    if (!isDuplicate) {
                                        newDataArray[tagIndex].push(item);
                                        newDataArray[tagIndex].sort((a, b) => new Date(a.TIMESTAMP) - new Date(b.TIMESTAMP));
                                    }
                                } else {
                                    newDataArray.push([item]);
                                }
                            }
                        });
                        newDataArray.forEach(data => {
                            data.sort((a, b) => new Date(a.TIMESTAMP) - new Date(b.TIMESTAMP));
                        });
                        setChartData(newDataArray);
                        
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setRealtimeUpdating(false);
                });
                const timeDiff = (new Date(end_time) - new Date(start_time)) / 2;
                if(existingChart.options.scales.x.min === undefined || existingChart.options.scales.x.max === undefined)
                {
                    const startTime = moment(new Date(start_time));
                    const endTime = moment(new Date(end_time));
                    const newStartTime = moment(startTime).subtract(timeDiff / 2, 'milliseconds')
                    const newEndTime = endTime.add(timeDiff, 'milliseconds');
                    existingChart.options.scales.x.min = new Date(newStartTime);
                    existingChart.options.scales.x.max = new Date(newEndTime);
                }
                else
                {
                    const newStartTime = moment(new Date(existingChart.options.scales.x.min)).subtract(timeDiff / 2, 'milliseconds').utc();
                    const newEndTime = moment(new Date(existingChart.options.scales.x.max)).subtract(timeDiff / 2, 'milliseconds').utc();
                    existingChart.options.scales.x.min = newStartTime.toDate();
                    existingChart.options.scales.x.max = newEndTime.toDate();
                }
                setRealtimeUpdating(false);
        }
    }

    const chartMoveRight = () => {
        const start_time = moment(existingChart.options.scales.x.max);
        const min_time = moment(existingChart.options.scales.x.min);
        const max_time = moment(existingChart.options.scales.x.max);
        
        const timeDiff = max_time.diff(min_time);
        
        const newStartTime = moment(start_time);
        const newEndTime = moment(start_time).add(timeDiff, 'milliseconds');
        
        existingChart.options.scales.x.min = newStartTime.toDate();
        existingChart.options.scales.x.max = newEndTime.toDate();
        
        existingChart.update();
    }
    
    

    const createChart = useCallback((ctx, datasets, xScales) => {   
        Chart.register(zoomPlugin);
        Chart.register(annotationPlugin);
        Chart.register(adapter);
        return new Chart(ctx, {
            type: 'line',
            data: {
                datasets
            },
            options: {
                animation: false,
                plugins: {
                    annotation: {
                        annotations: {
                            
                        }
                    },
                    htmlLegend: {
                        containerID: 'legend-container'
                    },
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            drag: {
                                enabled: false,
                            },
                            mode: 'x',
                            onZoom: ({ chart }) => {
                                // const xMin = new Date(chart.options.scales.x.min);
                                // const xMax = new Date(chart.options.scales.x.max);
                                // setStartDate(xMin)
                                // setEndDate(xMax)
                                // const timeDifference = xMax - xMin; 
                                // const timeDifferenceMinutes = timeDifference / (1000 * 60);
                                // if (timeDifferenceMinutes <= 20) {
                                //     setChartConfig(prevState => ({
                                //         ...prevState,
                                //         xScale: 'minute',
                                //         xStep: 1
                                //     }));
                                // } else if (timeDifferenceMinutes <= 60) {
                                //     setChartConfig(prevState => ({
                                //         ...prevState,
                                //         xScale: 'minute',
                                //         xStep: 5
                                //     }));
                                // } else if (timeDifferenceMinutes <= (24 * 60)) {
                                //     setChartConfig(prevState => ({
                                //         ...prevState,
                                //         xScale: 'hour',
                                //         xStep: 1
                                //     }));
                                // } else {
                                //     setChartConfig(prevState => ({
                                //         ...prevState,
                                //         xScale: 'auto',
                                //         xStep: 'auto'
                                //     }));
                                // }
                            },
                        },
                        pan: {
                            enabled: true,
                            mode: 'xy',
                            onPanComplete: ({ chart }) => {
                                // const xMin = new Date(chart.options.scales.x.min);
                                // const xMax = new Date(chart.options.scales.x.max);
                                // setStartDate(xMin)
                                // setEndDate(xMax)
                            }
                        },
                        
                    },
               
                    legend: {
                        display: false,
                        position: chartConfig['LegendLocation'],
                    },
                    title: {
                        display: true,
                        position: chartConfig['TitleLocation'],
                        text: chartConfig['chartTitle'],
                        font: {
                            size: 14,
                        },
                    },
                },
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: xScales,
                            stepSize: chartConfig['xStep'] === 'auto' ? '' : chartConfig['xStep'] ,
                            parser: 'yyyy-MM-dd HH:mm:ss.SSS',
                            tooltipFormat: 'yyyy-MM-dd HH:mm:ss.SSS',
                        },
                        displayFormats: {
                            second: 'HH:mm:ss',
                        },
                        position: 'bottom',
                        title: {
                            display: true,
                            text: chartConfig['xTitle']
                        },
                        grid: {
                            color: 'lightgray',
                            display: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                        },
                        ticks:{
                            autoSkip:true,
                            maxTicksLimit:10
                        }
                    },
                    y: {
                        type: 'linear',
                        position: 'left',
                        title: {
                            display: true,
                            text: chartConfig['yTitle']
                        },
                        grid: {
                            color: 'lightgray',
                            display: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                        },
                        min: chartConfig.yMin.trim() !== '' ? parseInt(chartConfig.yMin) : undefined,
                        max: chartConfig.yMax.trim() !== '' ? parseInt(chartConfig.yMax) : undefined,
                    }
                },
            }
        });
    },[chartConfig]);
    
    const prepareDatasets = useCallback((chartData, hiddenTag) => {
        let datasets = [];
        const result = [];
        
        chartData.forEach((tagData) => {
            tagData.forEach((item) => {
                if (item.RESULT === 'Ok' && !isNaN(item.VALUE)) {
                    result.push({
                        label: item.TAG,
                        timestamp: item.TIMESTAMP,
                        value: parseFloat(item.VALUE),
                    });
                }
            });
        });
        
        const formattedResult = result.map((item) => ({
            label: item.label,
            timestamp: moment.utc(item.timestamp).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss.SSS'),
            value: item.value,
        }));
        const timestamps = formattedResult.map((item) => item.timestamp);
        const values = formattedResult.map((item) => item.value);
        const labels = formattedResult.map((item) => item.label);
        const sampledData = timestamps.map((timestamp, index) => ({
            x: timestamp,
            y: values[index],
            label: labels[index]
        }));
        const labelSet = new Set(sampledData.map(item => item.label));
    
        labelSet.forEach(label => {
            const dataForLabel = sampledData.filter(item => item.label === label).map(item => ({ x: item.x, y: item.y }));
            const existingIndex = datasets.findIndex(dataset => dataset.label === label);
            const ishidden = hiddenTag.includes(label);
            if (existingIndex === -1) {
                datasets.push({
                    label: label,
                    data: dataForLabel,
                    type: 'line',
                    hidden: ishidden
                });
            }
        });
    
        if (datasets.length === 0) {
            let dataArray = _Modal.data;
            if (!Array.isArray(_Modal.data)) {
                dataArray = Object.values(_Modal.data);
            }
            dataArray.forEach((item) => {
                datasets.push({
                    label: item.TAG,
                });
                
            });
        }
        return datasets;
    },[_Modal.data]);

    const createNewChart = useCallback(async (chartData, hiddenTag) => {
        const datasets = prepareDatasets(chartData, hiddenTag);
        let existingChart = Chart.getChart(chartCanvas);
        
        //let scale;
        // if(_Modal.data[0].TYPE === 'STATIC')
        // {
        //     console.log(chartData)
        //     // const firstTimestamp = moment(chartData[0][0].TIMESTAMP);
        //     // const lastTimestamp = moment(chartData[0][chartData[0].length - 1].TIMESTAMP);
        //     // const diffMinutes = lastTimestamp.diff(firstTimestamp, 'minutes');
        //     // const diffHours = lastTimestamp.diff(firstTimestamp, 'hours');

        //     // if (diffMinutes < 1) {
        //     //     scale = 'seconds';
        //     // } else if (diffMinutes < 10) {
        //     //     scale = 'minutes';
        //     // } else if (diffHours < 5) {
        //     //     scale = 'seconds';
        //     // } else {
        //     //     scale = 'auto';
        //     // }
        // }
        // if(scale !== "" || scale !== undefined)
        // {
        //     setChartConfig(prevConfig => ({
        //         ...prevConfig,
        //         xScale: scale
        //     }));
        // }
        if (!existingChart) 
        {
            if(existingChart !== undefined)
            {
                existingChart.destroy();
            }
            const ctx = chartCanvas.getContext('2d');
            existingChart = createChart(ctx, datasets, chartConfig['xScale']);
        } 
        else 
        {
            if(dataset){
                dataset.forEach((item) => {
                    datasets.forEach((data) => {
                        if(item.label === data.label)
                        {
                            data.type = item.type;
                            data.backgroundColor = item.backgroundColor;
                            data.borderColor = item.borderColor;
                        }
                    })
                })
                existingChart.data.datasets = datasets;
            }
            else
            {
                existingChart.data.datasets = datasets;
            }
            
            if(chartConfig['yMin'] !== '')
            {   
                existingChart.options.scales.y.min = parseFloat(chartConfig['yMin']);
            }
            if(chartConfig['yMax'] !== '')
            {
                existingChart.options.scales.y.max = parseFloat(chartConfig['yMax']);
            }

            existingChart.options.scales.x.time.unit = chartConfig['xScale'] === 'auto' ? '' : chartConfig['xScale']
            existingChart.options.scales.x.time.stepSize = chartConfig['xStep'] === 'auto' ? '' : chartConfig['xStep']
            existingChart.update();
            
        }
        
        if (legendContainerRef.current) {
            legendContainerRef.current.remove();
        }
        
        const legendContainer = document.createElement('div');
        legendContainer.classList.add('custom-legend-container');
        legendContainer.id = 'custom-legend-container';
        chartCanvas.parentNode.appendChild(legendContainer);
        legendContainerRef.current = legendContainer;
        
        createChartLegend(existingChart.data.datasets);
        positionLegendContainer(chartCanvas);
        return existingChart;
    }, [createChartLegend, prepareDatasets, createChart, chartConfig, chartCanvas, dataset]);
    
    const data_download = async () => {
        if (chartData !== undefined) {
            const csvData = [];
        
            const headerRow = ['idx', 'TIMESTAMP'];
            const timestamps = [];
            chartData.forEach((tagData, index) => {
                headerRow.push(tagData[0].TAG);
                timestamps.push(tagData.map(data => data.TIMESTAMP));
            });
            csvData.push(headerRow.join(','));
    
            const maxLength = Math.max(...chartData.map(data => data.length));
            for (let i = 0; i < maxLength; i++) {
                const rowData = [i + 1]; 
                rowData.push(timestamps[0][i]);
                chartData.forEach(tagData => {
                    if (tagData[i]) {
                        rowData.push(tagData[i].VALUE || '');
                    } else {
                        rowData.push('');
                        rowData.push('');
                    }
                });
                csvData.push(rowData.join(','));
            }
    
            const csvString = csvData.join('\n');
            
            const blob = new Blob([csvString], { type: 'text/csv' });
    
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'ChartData_CSVFile.csv';
    
            document.body.appendChild(link);
            link.click();
    
            document.body.removeChild(link);
        }
    }

    const ChangeEvent = (id, value) => {
        if (!isNaN(value) || /^-?\d*\.?\d*$/.test(value)) {
            if (id === 'yMax') {
                setChartConfig(prevConfig => ({
                    ...prevConfig,
                    yMax: value
                }));
            } else if (id === 'yMin') {
                setChartConfig(prevConfig => ({
                    ...prevConfig,
                    yMin: value
                }));
            }
        }
    };
    
    const ChartChangeEvent = (id, value) => {
        setChartConfig(prevConfig => ({
            ...prevConfig,
            [id]: value
        }))
    }

    const _ChartChangeEvent = (id, value) => {
        _setChartConfig(prevConfig => ({
            ...prevConfig,
            [id]: value
        }))
    }

    const func_GetTagHistory = useCallback((props, start, end) => {
        const getDuration = (start, end) => {
            const diffInDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24)); 
            if (diffInDays <= 1) 
            {
                return 1000; 
            } else if (diffInDays <= 7) 
            {
                return 20000; 
            } 
            else if (diffInDays <= 30) 
            {
                return 80000;
            }
            else if (diffInDays <= 90) 
            {
                return 240000;
            }
            else 
            {
                return 1200000;
            }
        };
        const promises = props.map(async(prop) => {
            if(prop.TYPE === 'DYNAMIC')
            {
                const duration = await getDuration(start, end);
                return GetTagHistory(prop.MEMBER_SYSTEMID, prop.DB_TABLE_ID, prop.TAG, start, end, duration, 'UTC');
            }
            else
            {
                return true;
            }
        })
        Promise.all(promises)
            .then((res) => {
                
                const dataArray = res.map((item) => item.data.DATA);
                setChartData(dataArray);
            })
            .catch((err) => {
                console.log(err);
            });
        return true;
    },[setChartData])

    const func_GetTagHistory_init = useCallback((props, scale) => {
        const promises = props.map((prop) => {
            if(scale !== undefined)
            {
                if(prop.TYPE === 'DYNAMIC')
                {
                    if(prop.TIME !== undefined || prop.DURATION !== undefined)
                    {
                        let duration;
                        switch (scale) {
                            case 'second':
                                duration = 1000;
                                break;
                            case 'minute':
                                duration = 1000 * 60;
                                break;
                            case 'hour':
                                duration = 1000 * 60 * 60;
                                break;
                            case 'day':
                                duration = 1000 * 60 * 60 * 24;
                                break;
                            default:
                                duration = 1000;
                                break;
                        }
                        const start_time = moment().subtract(duration * 10).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        const end_time = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        return GetTagHistory(prop.MEMBER_SYSTEMID, prop.DB_TABLE_ID, prop.TAG, start_time, end_time, duration, 'UTC');
                    }
                    else
                    {
                        const unit = prop.TIME.slice(-1);
                        const value = parseInt(prop.TIME);

                        let start_time;
                        if (!isNaN(value) && (unit === 's' || unit === 'm' || unit === 'h')) {
                            start_time = moment().subtract(value, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                        else{
                            return false;
                        }
                        const end_time = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        return GetTagHistory(prop.MEMBER_SYSTEMID, prop.DB_TABLE_ID, prop.TAG, start_time, end_time, prop.DURATION, 'UTC');
                    }
                }
                else if(prop.TYPE === 'STATIC')
                {
                    const unescapedData = prop.DATA.replace(/\\"/g, '"');
                    const unquotedData = unescapedData.slice(1, -1); 
                    const parsedData = JSON.parse(unquotedData);
                    setStartDate(new Date(parsedData[0].TIMESTAMP));
                    setEndDate(new Date(parsedData[parsedData.length - 1].TIMESTAMP))
                    const item = {
                        data: {
                            DATA: parsedData
                        }
                    };
                    return item;
                }
            }
            else
            {
                if(prop.TYPE === 'DYNAMIC')
                {
                    if(prop.TIME !== undefined || prop.DURATION !== undefined)
                    {
                        const unit = prop.TIME.slice(-1).toLowerCase();
                        const value = parseInt(prop.TIME.slice(0, -1));
                        let start_time;
                        if (!isNaN(value) && (unit === 's' || unit === 'm' || unit === 'h')) 
                        {
                            start_time = moment().subtract(value, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                        else
                        {
                            return false;
                        }
                        const end_time = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        return GetTagHistory(prop.MEMBER_SYSTEMID, prop.DB_TABLE_ID, prop.TAG, start_time, end_time, prop.DURATION, 'UTC');
                    }
                    else
                    {
                        const unit = 'm';
                        const value = 5;
                        let start_time;
                        if (!isNaN(value) && (unit === 's' || unit === 'm' || unit === 'h')) {
                            start_time = moment().subtract(value, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        }
                        else{
                            return false;
                        }
                        const end_time = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        return GetTagHistory(prop.MEMBER_SYSTEMID, prop.DB_TABLE_ID, prop.TAG, start_time, end_time, 1000, 'UTC');
                    }
                }
                else if(prop.TYPE === 'STATIC')
                {
                    const unescapedData = prop.DATA.replace(/\\"/g, '"');
                    const unquotedData = unescapedData.slice(1, -1); 
                    const parsedData = JSON.parse(unquotedData);
                    setStartDate(new Date(parsedData[0].TIMESTAMP));
                    setEndDate(new Date(parsedData[parsedData.length - 1].TIMESTAMP))
                    const item = {
                        data: {
                            DATA: parsedData
                        }
                    };
                    return item;
                }
            }
            return true;
        });
        
        Promise.all(promises)
            .then((res) => {
                
                const dataArray = res.map((item) => item.data.DATA);
                setChartData(dataArray);
                
            })
            .catch((err) => {
                console.log(err);
            });
        return true;
    },[]);

    const func_GetTagHistory_realtime = useCallback((props) => {
        if (realtimeUpdating) return;
        setRealtimeUpdating(true);
        const promises = props.map((prop) => {
            if(xScaleChange)
            {
                if(prop.TYPE === 'DYNAMIC')
                {
                    const TABLE_AND_TAGS = [{
                        TABLE_ID: prop.DB_TABLE_ID,
                        TAGS: [prop.TAG]
                    }];
                    return GetTagValues(prop.MEMBER_SYSTEMID, TABLE_AND_TAGS);
                }
                else if(prop.TYPE === 'STATIC')
                {
                    const unescapedData = prop.DATA.replace(/\\"/g, '"');
                    const unquotedData = unescapedData.slice(1, -1); 
                    const parsedData = JSON.parse(unquotedData);
                    const item = {
                        data: {
                            DATA: parsedData
                        }
                    };
                    return item;
                }
                else
                {
                    return true;
                }
            }
            else
            {
                if(prop.TYPE === 'DYNAMIC')
                {
                    const TABLE_AND_TAGS = [{
                        TABLE_ID: prop.DB_TABLE_ID,
                        TAGS: [prop.TAG]
                    }];
                    return GetTagValues(prop.MEMBER_SYSTEMID, TABLE_AND_TAGS);
                }
                else if(prop.TYPE === 'STATIC')
                {
                    const unescapedData = prop.DATA.replace(/\\"/g, '"');
                    const unquotedData = unescapedData.slice(1, -1); 
                    const parsedData = JSON.parse(unquotedData);
                    const item = {
                        data: {
                            DATA: parsedData
                        }
                    };
                    return item;
                }
                else
                {
                    return true;
                }
            }
        });
        Promise.all(promises)
            .then((res) => {
                const dataArray = res.map((item) => item.data.DATA);
                let newDataArray = [...chartData];

                dataArray.forEach((dataItem) => {
                    dataItem.forEach((newData) => {
                      newData.DATA.forEach((entry) => {
                        const tagIndex = newDataArray.findIndex(
                          (array) => array.length > 0 && array[0].TAG === entry.TAG
                        );
          
                        if (tagIndex !== -1) {
                            const lastEntry = newDataArray[tagIndex].slice().reverse().find(item => item.RESULT === 'Ok');
                            if (lastEntry === undefined || lastEntry.TIMESTAMP !== entry.TIMESTAMP) {
                                newDataArray[tagIndex].push(entry);
                                newDataArray[tagIndex].shift();
                            }
                        }
                        else {
                          newDataArray.push([entry]);
                        }
                      });
                    });
                  });
                
                setChartData(newDataArray);
                setRealtimeUpdating(false);

            })
            .catch((err) => {
                console.log(err);
                setRealtimeUpdating(false);
            });
        return true;
        
    },[chartData, xScaleChange, realtimeUpdating])

    const KeyDownEvent = (e) => {
        if (e.key === 'Enter')
        {
            const updatedDataset = dataset.map(item => {
                if (item.label === colorModal.label) 
                {
                    return { ...item, backgroundColor: colorModal.color, borderColor: colorModal.color };
                }
                else 
                {
                    return item;
                }
            });
            setDataset(updatedDataset);
            setColorModal({
                visibility:false,
                label:'',
                color:''
            })
        }
        
    };

    useEffect(() => {
        let dataArray = _Modal.data;
        if (!Array.isArray(_Modal.data)) {
            dataArray = Object.values(_Modal.data);
        }
        
        if(dataArray[0].TYPE === 'DYNAMIC')
        {
            setChartMode('START')
        }
        else
        {
            setChartMode('PAUSE')
        }
        
        func_GetTagHistory_init(dataArray);
    },[_Modal.data, func_GetTagHistory_init])
    
    useEffect(() => {
        let dataArray = _Modal.data;
        if (!Array.isArray(_Modal.data)) {
            dataArray = Object.values(_Modal.data);
        }
        if(chartData.length === 0){
            return;
        }
        
        const removeDuplicatesByTag = (dataArray) => {
            const uniqueMap = new Map();
            
            dataArray.forEach(item => {
                uniqueMap.set(item.TAG, item);
            });
            
            return Array.from(uniqueMap.values());
        };
        
        const uniqueDataArray = removeDuplicatesByTag(dataArray);

        // let isRealtime = false;
        // uniqueDataArray.forEach((data) => {
        //     if(data.TYPE === 'DYNAMIC'){
        //         isRealtime = true;
        //     }
        // })

        if(chartMode === 'START')
        {
            if(xScaleChange)
            {
                let duration;
                switch (chartConfig['xScale']) {
                    case 'second':
                        duration = 1000;
                        break;
                    case 'minute':
                        duration = 1000 * 60;
                        break;
                    case 'hour':
                        duration = 1000 * 60 * 60;
                        break;
                    case 'day':
                        duration = 1000 * 60 * 60 * 24;
                        break;
                    default:
                        duration = 1000;
                        break;
                    }
                const intervalId = setInterval(() => {
                    func_GetTagHistory_realtime(uniqueDataArray);
                }, duration);
    
                return () => clearInterval(intervalId);
            }
            else
            {
                const intervalId = setInterval(() => {  
                    func_GetTagHistory_realtime(uniqueDataArray);
                }, dataArray[0].DURATION);
    
                return () => clearInterval(intervalId);
            }
        }
        
    },[chartData, _Modal.data, func_GetTagHistory_realtime, chartMode, chartConfig, xScaleChange])
    
    useEffect(() => {
        if(chartConfig['xScale'] === '')
        {
            let timeInterval;
            switch (_Modal.data[0].TYPE === 'DYNAMIC' &&_Modal.data[0].TIME.slice(-1).toLowerCase()) {
                case 's':
                    timeInterval = 'second';
                    break;
                case 'm':
                    timeInterval = 'minute';
                    break;
                case 'h':
                    timeInterval = 'hour';
                    break;
                case 'd':
                    timeInterval = 'day';
                    break;
                default:
                    timeInterval = 'minute'; 
            }
            if (chartConfig['xScale'] !== timeInterval) {
                setChartConfig({
                    ...chartConfig,
                    xScale: timeInterval
                });
            }
        }

        const resizeObserver = new ResizeObserver(() => {
            createNewChart(chartData, hiddenTag);
        });
        
        if (chartRef.current) {
            resizeObserver.observe(chartRef.current);
        }
    
        return () => {
          resizeObserver.disconnect();
        };
    }, [_Modal, chartData, createNewChart, hiddenTag, chartConfig, setChartConfig]);

    return(
        <div ref={ref} style={{height:"100%", width:'100%' , maxWidth:'1800px'}} onClick={(e)=> {e.preventDefault();}}>
            <BtnBox>
                <div></div>
                <div style={{position:'absolute', zIndex: '8',  display:'flex', }}>
                    <MdCalendarMonth style={{fontSize:"1.3rem"}}/>
                    <DatePicker
                        selected={startDate}
                        onChange={(date)=>setStartDate(date)}
                        dateFormat="yyyy/MM/dd h:mm aa"
                        timeInputLabel="START TIME: "
                        timeFormat="HH:mm"
                        timeCaption="time"
                        timeIntervals={15}
                        showTimeSelect
                        wrapperClassName="time-picker-wrapper"
                    /> 

                    <DatePicker
                        selected={endDate}
                        onChange={(date)=>setEndDate(date)}
                        dateFormat="yyyy/MM/dd h:mm aa"
                        timeInputLabel="START TIME: "
                        timeFormat="HH:mm"
                        timeCaption="time"
                        timeIntervals={15}
                        showTimeSelect
                        wrapperClassName="time-picker-wrapper"
                    /> 
                    <BtnSecondary type='sm' label='조회' onClick={() => {
                        setChartMode('PAUSE')
                        let dataArray = _Modal.data;
                        if (!Array.isArray(_Modal.data)) {
                            dataArray = Object.values(_Modal.data);
                        }
                        func_GetTagHistory(dataArray, startDate, endDate);
                        // const timeDifference = endDate - startDate; 
                        // const timeDifferenceMinutes = timeDifference / (1000 * 60);
                        // if(timeDifferenceMinutes <= 10){
                        //     setChartConfig(prevState => ({
                        //         ...prevState,
                        //         xScale: 'second',
                        //         xStep: 1
                        //     }));
                        //     console.log('second')
                        // } else if (timeDifferenceMinutes <= 60) {
                        //     setChartConfig(prevState => ({
                        //         ...prevState,
                        //         xScale: 'minute',
                        //         xStep: 5
                        //     }));
                        //     console.log('minute')
                        // } else if (timeDifferenceMinutes <= (24 * 60 * 2)) {
                        //     setChartConfig(prevState => ({
                        //         ...prevState,
                        //         xScale: 'hour',
                        //         xStep: 1
                        //     }));
                        //     console.log('hour')
                        // } else if (timeDifferenceMinutes <= (24 * 60 * 7)) {
                        //     setChartConfig(prevState => ({
                        //         ...prevState,
                        //         xScale: 'day',
                        //         xStep: 1
                        //     }));
                        //     console.log('day')
                        // } else {
                        //     setChartConfig(prevState => ({
                        //         ...prevState,
                        //         xScale: 'auto',
                        //         xStep: 'auto'
                        //     }));
                        //     console.log('auto')
                        // }
                        }}/>
                        <div>
                            <select style={{height:'28px', marginLeft:'16px', border:'1px lightgray solid'}} name="xScaleSelect" id="xScaleSelect" 
                            onChange={(e) => {
                                let mode = chartMode;
                                setChartMode('PAUSE')
                                ChangeSelect(e.target.value);
                                setChartData([]);
                                let dataArray = _Modal.data;
                                if (!Array.isArray(_Modal.data)) {
                                    dataArray = Object.values(_Modal.data);
                                }
                                let duration;
                                switch (e.target.value) {
                                    case 'second':
                                        duration = 1000;
                                        break;
                                    case 'minute':
                                        duration = 1000 * 60;
                                        break;
                                    case 'hour':
                                        duration = 1000 * 60 * 60;
                                        break;
                                    case 'day':
                                        duration = 1000 * 60 * 60 * 24;
                                        break;
                                    default:
                                        duration = 1000;
                                        break;
                                }
                                const start_time = moment().subtract(duration * 10).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                                const end_time = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                                if(existingChart)
                                {
                                    existingChart.options.scales.x.min = new Date(start_time);
                                    existingChart.options.scales.x.max = new Date(end_time);
                                }
                                func_GetTagHistory_init(dataArray, e.target.value);
                                setChartMode(mode);
                            }} 
                            value={chartConfig['xScale']}>
                                <option value="second">second</option>
                                <option value="minute">minute</option>
                                <option value="hour">hour</option>
                                <option value="day">day</option>
                                <option value="auto">auto</option>
                            </select>
                        </div>
                </div>
                <div style={{display:'flex', gap:'4px'}}>
                    <BtnSecondary title='Annotation' type='sm' label={<MdAlignHorizontalLeft />} onClick={() => {
                        setHorizontal(({
                            ID:'',
                            Color:'',
                            yMin:'',
                            yMax:'',
                            visibility: true,
                        }));}}/>
                    <BtnSecondary title='Chart Setting' type='sm' label={<MdSettings />} onClick={() => {
                        setDataset(existingChart.data.datasets);
                        setChartConfig(prevState => ({
                            ...prevState,
                            visibility:true
                        }));
                    }}/>
                    <BtnSecondary title='csv Download' type='sm' label={<MdDownload />} onClick={() => {data_download();}}/>
                </div>

                {horizontal['visibility'] && 
                <Popup>
                    <BgPopup onClick={() => {
                        setHorizontal(prevState => ({
                            ...prevState,
                            visibility: false,
                        }));}}/>
                    <div className="PopupWrap">
                        <VariableTableList>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='ID'>ID</th>
                                            <th className='yMin'>yMin</th>
                                            <th className='yMax'>yMax</th>
                                            <th className='Color'>Color</th>
                                            <th className='Btn'>Del</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {horizontalList &&
                                            horizontalList.map((item, index)=>(
                                                <tr key={index}>
                                                    <td className='ID'>{item.id}</td>
                                                    <td className='yMin'>{item.yMin}</td>
                                                    <td className='yMax'>{item.yMax}</td>
                                                    <td className='Color'>{item.borderColor}</td>
                                                    <td className='Btn'><MdClear onClick={(e)=>{deleteAnnotation(item.id)}}/></td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            <td className='ID'><InputContent style={{ width: '100%', textAlign:'center' }} placeholder='ID' value={horizontal['ID']} onChange={(e) => {
                                                setHorizontal(prevState => ({
                                                    ...prevState,
                                                    ID: e.target.value,
                                                }));}}/></td>
                                            <td className='yMin'><InputContent style={{ width: '100%', textAlign:'center' }} placeholder='Y Min' value={horizontal['yMin']} onChange={(e) => {
                                                setHorizontal(prevState => ({
                                                    ...prevState,
                                                    yMin: e.target.value,
                                                }));}}/>
                                            </td>
                                            <td className='yMax'><InputContent style={{ width: '100%', textAlign:'center'  }} placeholder='Y Max' value={horizontal['yMax']} onChange={(e) => {
                                                setHorizontal(prevState => ({
                                                    ...prevState,
                                                    yMax: e.target.value,
                                                }));}}/>
                                            </td>
                                            <td className='Color'><InputContent style={{ width: '100%', textAlign:'center' }} placeholder='Color' value={horizontal['Color']} onChange={(e) => {
                                                setHorizontal(prevState => ({
                                                    ...prevState,
                                                    Color: e.target.value,
                                                }));}}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </VariableTableList>
                        <ModalBottomBox>
                            <BtnSecondary title='Cancle' type='sm' label='Cancel' onClick={(e) => {setHorizontal(prevState => ({
                                ...prevState,
                                visibility: false,
                                }));
                            }}/>
                            <BtnSecondary title='Create Annotation' type='sm' label='Create' onClick={(e) => {
                                createAnnotation();
                                setHorizontal(prevState => ({
                                ...prevState,
                                visibility: false,
                                }));
                            }}/>
                        </ModalBottomBox>
                    </div>
                </Popup>
                }
                
                {chartConfig['visibility'] && 
                <Popup>
                    <BgPopup onClick={() => {
                        setChartConfig(prevState => ({
                            ...prevState,
                            visibility: false,
                        }));
                        setColorModal({
                            visibility:false,
                            color:'',
                            label:''
                        });}}/>
                    <div className="PopupWrap">
                        <VariableTableList>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='Tag'>TAG</th>
                                            <th className='Color'>Color</th>
                                            <th className='Type'>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataset &&
                                            dataset.map((item, index)=>{
                                                return(
                                                    <tr key={index}>
                                                        <td className='Tag'>{item.label}</td>
                                                        <td className='Color'>
                                                            {colorModal.label !== item.label &&<div style={{display:'flex', justifyContent:'space-between'}}><span style={{color:item.backgroundColor}}>{item.backgroundColor}</span><span style={{color:'gray'}}><MdModeEditOutline onClick={(e)=> {setColorModal({visibility:true,label:item.label,color:item.backgroundColor})}}/></span></div>}
                                                            {colorModal.label === item.label && colorModal.visibility === true && 
                                                                <InputContent style={{ width: '100%', textAlign:'center', color:'black' }} value={colorModal.color} onChange={(e) => {
                                                                    setColorModal(prevState => ({
                                                                      ...prevState,
                                                                      color:e.target.value  
                                                                    }))
                                                                }}
                                                                onKeyDown={KeyDownEvent}
                                                                />
                                                            }
                                                            </td>
                                                        <td className='Type'>
                                                            <select style={{border:'1px lightgray solid'}} name="TagTypeSelect" id="TagTypeSelect" 
                                                            onChange={(e) => {
                                                                handleChangeType(item.label, e.target.value);
                                                            }} 
                                                            value={item.type}>
                                                                <option value="line">line</option>
                                                                <option value="bar">bar</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}
                                        <tr>
                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </VariableTableList>
                        <div className='InputList'>
                            <div>
                                <GroupName width={100} align={'right'} htmlFor='xtitle' className="Initial">X Axis Title</GroupName>
                                <InputContent id='xtitle' style={{ width: '40%' }} placeholder='X Axis Title' value={chartConfig['xTitle']} onChange={(e) => {
                                    ChartChangeEvent('xTitle', e.target.value)
                                }}/>
                            </div>
                            <div>
                                <GroupName width={100} align={'right'} htmlFor='ytitle' className="Initial">Y Axis Title</GroupName>
                                <InputContent id='ytitle' style={{ width: '40%' }} placeholder='Y Axis Title' value={chartConfig['yTitle']} onChange={(e) => {
                                    ChartChangeEvent('yTitle', e.target.value)
                                }}/>
                            </div>
                            <div>
                                <GroupName width={100} align={'right'} htmlFor='charttitle' className="Initial">Chart Title</GroupName>
                                <InputContent id='charttitle' style={{ width: '40%' }} placeholder='Chart Title' value={chartConfig['chartTitle']} onChange={(e) => {
                                    ChartChangeEvent('chartTitle', e.target.value)
                                }}/>
                            </div>
                            <div>
                                <GroupName width={100} align={'right'} htmlFor='titleloc' className="Initial">Title Location</GroupName>
                                <select style={{border:'1px lightgray solid', height:'28px'}} name="TagTypeSelect" id="TagTypeSelect" 
                                    onChange={(e) => {
                                        ChartChangeEvent('TitleLocation', e.target.value)
                                    }} 
                                    value={chartConfig['TitleLocation']}>
                                        <option value="top">top</option>
                                        <option value="left">left</option>
                                        <option value="right">right</option>
                                        <option value="bottom">bottom</option>
                                </select>
                            </div>
                            <div>
                                <GroupName width={100} align={'right'} htmlFor='legendloc' className="Initial">Legend Location</GroupName>
                                <select style={{border:'1px lightgray solid', height:'28px'}} name="TagTypeSelect" id="TagTypeSelect" 
                                    onChange={(e) => {
                                        _ChartChangeEvent('LegendLocation', e.target.value)
                                    }} 
                                    value={_chartConfig['LegendLocation']}>
                                        <option value="top">top</option>
                                        <option value="left">left</option>
                                        <option value="right">right</option>
                                        <option value="bottom">bottom</option>
                                </select>
                            </div>
                        </div>
                        <ModalBottomBox>
                            <BtnSecondary title='Cancle' type='sm' label='Cancel' onClick={(e) => {
                                setChartConfig(prevState => ({
                                    ...prevState,
                                    visibility: false,
                                }));
                                setColorModal({
                                    visibility:false,
                                    color:'',
                                    label:''
                                });
                                setDataset();
                            }}/>
                            <BtnSecondary title='Chart Setting' type='sm' label='OK' onClick={(e) => {
                                const newConfig = {
                                    ...chartConfig,
                                    LegendLocation:_chartConfig['LegendLocation']
                                }

                                setChartConfig(newConfig)
                                changeChartSetting(newConfig);
                                
                            }}/>
                        </ModalBottomBox>
                    </div>
                </Popup>
                }
            </BtnBox>
            <div style={{width: chartConfig['LegendLocation'] !== 'right' ? '100%' : 'calc(100% - 150px)', position:'relative'}}>
                <div style={{position:'absolute', top:'-10px', zIndex:'2', marginLeft:`${chartConfig['LegendLocation'] === 'left' ? '150px' : '0px'}`}}>
                    <InputContent id='yMax' placeholder='yMax' value={chartConfig['yMax']} onChange={(e) => ChangeEvent('yMax', e.target.value)} />
                </div>
                <canvas ref={chartRef} id={`chart-canvas-${_Modal.id}`}></canvas>
                <div style={{display:'flex', justifyContent:'end', transform:'translate(16px, -20px)', gap:'8px', marginRight:'32px', position:'relative'}}>
                    <BtnSecondary title={'Left'} style={{color:'gray'}} type='sm' label={<MdArrowLeft />} onClick={() => {
                        chartMoveLeft();
                    }}/>
                    <BtnSecondary title={'Right'} style={{color:'gray'}} type='sm' label={<MdArrowRight />} onClick={() => {
                        chartMoveRight();
                    }}/>
                    <BtnSecondary title={chartMode==='START' ? 'PAUSE REALTIME MODE' : 'START REALTIME MODE'} style={{color:'gray'}} type='sm' label={chartMode==="START" ? <MdPauseCircleFilled /> : <MdOutlinePlayCircleFilled />} onClick={() => {
                        let dataArray = _Modal.data;
                        if (!Array.isArray(_Modal.data)) 
                        {
                            dataArray = Object.values(_Modal.data);
                        }
                        if(chartMode === 'START')
                        {
                            setChartMode('PAUSE');
                        }
                        else if(chartMode === 'PAUSE')
                        {
                            if(dataArray[0].TYPE === 'STATIC')
                            {
                                alert('정적 데이터는 실시간 차트를 이용할 수 없습니다.');
                                return;
                            }
                            setChartData([]);
                            
                            if(existingChart!==undefined)
                            {
                                existingChart.options.scales.x.min = undefined;
                                existingChart.options.scales.x.max = undefined;
                            }
                            func_GetTagHistory_init(dataArray, chartConfig['xScale'])
                            setChartMode('START')
                        }
                    }}/>
                <div style={{position:'absolute', left:'0', marginLeft:`${chartConfig['LegendLocation'] === 'left' ? '150px' : '0px'}`}}>
                    <InputContent id='yMin' placeholder='yMin' value={chartConfig['yMin']} onChange={(e) => ChangeEvent('yMin', e.target.value)} />
                </div>
                </div>
            </div> 
        </div>
    )
}

const BtnBox = styled.div`
    margin-bottom:${marginBottom}px;
    display:flex;
    justify-content:space-between;
    
    .btn{
        display:flex;
    
    }

    .btn > * {
       
    }

    .date{
        width:250px;
    }
    
    .datebox{
        display:flex;
        gap:4px;
        padding:4px;
        cursor:pointer;
    }

    
    .interval {
        display:flex;
        gap:8px;
        margin-left:-20px;
        height:30px;
    }

    .interval InputContent{
        height:75%;
    }

    .react-datepicker{
        width:100%;
        display:flex;
    }

    .time-picker-wrapper{
        border:1px gray solid;
    }
    .react-datepicker__time-container {

    }
    .react-datepicker__time-container .react-datepicker__time {
        
    }
    
    .react-datepicker__time-container .react-datepicker__time-list {
        
    }
`

const InputContent = styled.input`
    margin-top: 8px;
    height: auto;
    width: 100px;
    border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    border-radius: 2px;
    padding: 4px 2px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    flex-grow: 1;

    &:focus{
        outline:none;
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
    }
    
    &:disabled{
        padding: 0;
        background-color: transparent;
        border: 0 none;
    }
`;

const VariableTableList = styled(TableContent)`
    height: calc(100% - 212px);

    .selectTable{
        background-color:${({theme}) => theme.base.background_color_LightBlue};
    }

    .table{
        width: 12%;
        text-align: center;
    }

    .ID {
        padding: 0;
        width: 60px;
        text-align: center;
    }
    
    .yMin, .yMax {
        width: 20%;
        text-align: center;
    }

    .Color {
        width:32%;
    }

    .Tag {
        width:25%;
    }

    .Type {
        width:12%;
        text-align:center;
    }

    .Edit {
        width:10%;
    }

    .Edit > * {
        margin-left:4px;
        margin-top:2px;
    }

    .Btn{
        padding:0 8px;

        width: 48px;
        text-align: center;

        svg{
            cursor: pointer;
            color: ${({theme}) => theme.base.border_color_Red};
            border: 1px solid ${({theme}) => theme.base.border_color_Red};
            padding: 4px;
            border-radius: 4px;
            margin-top:4px;
        }
    }
`;