import PropertiesContent from "components/atom/PropertiesContent";
import LabelAndInput from "babylon/components/atom/LabelAndInput";
import useDataController from "babylon/hooks/useDataController";

export default function PropertiesInfo({selectBabylonData, SelectedMesh}){
    const {
        id: MeshId, 
        name: MeshName,
        objectType
    } = selectBabylonData;

    const {UploadDataByMesh} = useDataController();

    const SetTitle = (ObjectType) => {
        if(ObjectType === undefined) 
            return 'Properties';
        else
            return `(${ObjectType})Properties`;
    };

    return(
        <PropertiesContent _Title={SetTitle(objectType)}>
            <LabelAndInput 
                id='id'
                label='id'
                value={MeshId}
                labelWidth="30%"
                changeEvent={(e)=>{
                    const targetValue = e.target.value;
                    SelectedMesh.id = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />

            <LabelAndInput 
                id='name'
                label='name'
                value={MeshName}
                labelWidth="30%"
                changeEvent={(e)=>{
                    const targetValue = e.target.value;
                    SelectedMesh.name = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />
        </PropertiesContent>
    )
}