import React, { useState, useEffect } from 'react';

import VideoContent from "components/atom/VideoContent";
import AudioContent from "components/atom/AudioContent";
import {EnuIconEnableMouseWheel, EnuIconDisableMouseWheel} from "components/common/EnuSpaceIcon"

import { MdClose, MdCloseFullscreen, MdOpenInFull, MdCenterFocusStrong, MdKeyboardArrowUp, MdKeyboardArrowDown, MdOutlineVolumeOff, MdOutlineVolumeUp, MdOutlineComment, MdOutlineCommentsDisabled, MdExplore, MdExploreOff } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components"
// import { Link } from 'react-router-dom';
// (headerMenu.useChatting && !headerMenu.isChatting)
export default function RuntimeHeader({enuSpace, headerMenu, setHeaderMenu, isIframe, viewType='normal'}){
    const navigate = useNavigate();
    const {isHeader, useChatting, isVolume, isAutoFix, isWheel, isMinimap, isChatting} = headerMenu;
    const [minimapFlag, setMinimapFlag] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(document.fullscreenElement !== null);
    
    // 전체화면 모드 변경 감지
    useEffect(() => {
        const handleFullScreenChange = () => setIsFullScreen(document.fullscreenElement !== null);
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        return () => document.removeEventListener('fullscreenchange', handleFullScreenChange);
    }, []);

    const toggleMinimap = (bMinimap) => {
        setMinimapFlag(bMinimap);
        enuSpace.GetMetaHandler().SetMinimap(bMinimap)
    };

    const handleFullScreenToggle = (e) => {
        if (isFullScreen) {
            document.exitFullscreen?.();
        } else {
            document.documentElement.requestFullscreen?.();
        }
        setIsFullScreen(!isFullScreen);
    };

    const ChangeMouseWheelValue = (isValue) =>{
        const DrawHandler = enuSpace.GetDrawHandler("Canvas");
        DrawHandler.SetWheelButton(isValue);
        setHeaderMenu({ ...headerMenu, isWheel: isValue });
    }
    
    const commonButtons = (
        <>
            {(!useChatting || isAutoFix) && <MdCenterFocusStrong className="btn btn_base" onClick={() => enuSpace.RunAutoScale("Canvas")} />}
            {(!useChatting || isAutoFix) && (isWheel
                ? <EnuIconEnableMouseWheel className="btn btn_base" onClick={() => ChangeMouseWheelValue(false)} />
                : <EnuIconDisableMouseWheel className="btn btn_base" onClick={() => ChangeMouseWheelValue(true)} />
            )}
        </>
    );

    const fullscreenButton = isFullScreen
        ? <MdCloseFullscreen className="btn btn_base" onClick={handleFullScreenToggle} />
        : <MdOpenInFull className="btn btn_base" onClick={handleFullScreenToggle} />;

    const commonHeader = (
        <>
            <div>
                {isChatting && <MdOutlineComment className="btn btn_base" onClick={() => setHeaderMenu({ ...headerMenu, isChatting: false })} />}
                {useChatting && !isChatting && <MdOutlineCommentsDisabled className="btn btn_base" onClick={() => setHeaderMenu({ ...headerMenu, isChatting: true })} />}
                {useChatting && <VideoContent enuSpace={enuSpace} />}
                {useChatting && <AudioContent enuSpace={enuSpace} />}
                {useChatting && isVolume
                    ? <MdOutlineVolumeUp className="btn btn_base" onClick={() => setHeaderMenu({ ...headerMenu, isVolume: false })} />
                    : <MdOutlineVolumeOff className="btn btn_base" onClick={() => setHeaderMenu({ ...headerMenu, isVolume: true })} />
                }
                {commonButtons}
                {isMinimap && (
                    useChatting && minimapFlag
                    ? <MdExplore className="btn btn_base" onClick={() => { toggleMinimap(false);}} />
                    : <MdExploreOff className="btn btn_base" onClick={() => { toggleMinimap(true);}} />
                )}
                {isHeader && <MdKeyboardArrowUp className="btn btn_base" onClick={() => { setHeaderMenu({ ...headerMenu, isHeader: false }); enuSpace.SetResize(); }} />}
            </div>
            <div>
                {!isIframe && 
                    <>
                        {fullscreenButton}
                        {!isFullScreen && <MdClose className="btn btn_close" onClick={() => {navigate(-1);}} />}
                    </>
                }
            </div>
        </>
    );

    if(isHeader){
        return (
            <Header>
                <div>
                    {/* <Link to='/'><img id="enuLogo" src="/img/enuLogo.png" alt="enu" /></Link>
                    <span>{_BASE_DATA.projectName || ''}</span> */}
                </div>
                {commonHeader}
            </Header>
        )
    }else if(viewType === 'link'){
        return(
            <Header>
                <div>
                    {/* <Link to='/'><img id="enuLogo" src="/img/enuLogo.png" alt="enu" /></Link>
                    <span>{_BASE_DATA.projectName || ''}</span> */}
                </div>
                {commonHeader}
            </Header>
            // <OptionMenu>
            //     <div>
            //         {useChatting && <AudioContent enuSpace={enuSpace} />}
            //         {commonButtons}
            //         {fullscreenButton}
            //     </div>
            // </OptionMenu>
        )
    }else{
        return (
            <BtnCloseHeader>
                <MdKeyboardArrowDown className="Btn_showHeader" onClick={(e) => { setHeaderMenu({ ...headerMenu, isHeader: true }); enuSpace.SetResize(); }} />
            </BtnCloseHeader>
        )
    }
}

const Header = styled.header`
    position: absolute;
    width: 100%;
    height: 46px;
    box-sizing: border-box;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    
    &>div{
        position: relative;
        z-index:9;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        &>span{
            font-size: 1.2rem;
            font-weight: 500;
            cursor: default;
        }
    }

    &>div .btn{
        height: 30px;
        width: 30px;
        background-color:white;
        color: ${({theme}) => theme.base.font_color_DarkGray};
        border: 1px solid ${({theme}) => theme.base.border_color_Gray};
        border-radius: 4px;
        box-sizing: border-box;

        &.btn_close{
            &:hover{
                color: ${({theme}) => theme.base.font_color_Red};
                border-color: ${({theme}) => theme.base.font_color_Red};
            } 
        }
        &.btn_base{
            padding: 3px;
            &:hover{
                color: ${({theme}) => theme.base.font_color_DarkBlue};
                border-color: ${({theme}) => theme.base.border_color_Blue};
            } 
        }
    }

`;

const BtnCloseHeader = styled.div`
    .Btn_showHeader{
        position: absolute;
        top: 12px;
        right: 0;
        
        height: 36px;
        width: 36px;
        margin-right: 8px;
        color: ${({theme}) => theme.base.font_color_DarkGray};
        border: 1px solid ${({theme}) => theme.base.border_color_Gray};
        border-radius: 4px;
        padding: 3px;
        box-sizing: border-box;
        background-color: #fff;
        cursor: pointer;
        z-index: 9;
    }
`;

// const OptionMenu = styled.div`
//     position: absolute;
//     top: 8px;
//     right: 8px;
//     z-index: 9;
//     padding: 8px;
//     background-color: ${({theme}) => theme.base.background_color_PaleGray};
//     border: 1px solid ${({theme}) => theme.base.border_color_Gray};
//     border-radius: 4px;

//     &>div{
//         display: flex;
//         align-items: center;
//         cursor: pointer;
//         gap: 8px;

//         &>span{
//             font-size: 1.3rem;
//             font-weight: 500;
//             cursor: default;
//         }

//         svg{
//             margin: 0!important;
//         }
//     }

//     &>div .btn{
//         height: 30px;
//         width: 30px;
//         color: ${({theme}) => theme.base.font_color_DarkGray};
//         border: 1px solid ${({theme}) => theme.base.border_color_Gray};
//         border-radius: 4px;
//         box-sizing: border-box;
//         padding: 3px;
//     }
// `;