export const PointerIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <g>
                <path fill={color}
                    d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4Zm8.94 3A8.994 8.994 0 0 0 13 3.06V1h-2v2.06A8.994 8.994 0 0 0 3.06 11H1v2h2.06A8.994 8.994 0 0 0 11 20.94V23h2v-2.06A8.994 8.994 0 0 0 20.94 13H23v-2h-2.06ZM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7Z" />
            </g>
        </svg>
    )
}

export const TextBoxIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <g>
                <path fill={color}
                    d="M23 7V1h-6v2H7V1H1v6h2v10H1v6h6v-2h10v2h6v-6h-2V7h2ZM3 3h2v2H3V3Zm2 18H3v-2h2v2Zm12-2H7v-2H5V7h2V5h10v2h2v10h-2v2Zm4 2h-2v-2h2v2ZM19 5V3h2v2h-2Zm-5.27 9h-3.49l-.73 2H7.89l3.4-9h1.4l3.41 9h-1.63l-.74-2Zm-3.04-1.26h2.61L12 8.91l-1.31 3.83Z" />
            </g>
        </svg>
    )
}

export const ImageIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color}
                d="M2 22V2h20v20H2Zm1-5.141L7.858 12l2.9 2.9 5.381-5.381L21 14.381V3H3v13.859ZM5 7.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z" />
        </svg>
    )
}

export const MemoIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color} fillRule="evenodd" d="M2 2h20v14l-6 6H2V2Zm13 19v-6h6V3H3v18h12Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const Selector = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <g>
                <path fill={color} d="M21 3 3 10.53v.98l6.84 2.65L12.48 21h.98L21 3Z" />
            </g>
        </svg>
    )
}

export const EraserIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <g>
                <path fill={color} fillRule="evenodd"
                    d="M14.021 5.515a1 1 0 0 1 1.414 0l4.243 4.242a1 1 0 0 1 0 1.415L11.85 19H18v2H5.465l-2.758-2.757a1 1 0 0 1 0-1.415L14.021 5.515Z"
                    clipRule="evenodd" />
            </g>
        </svg>
    )
}

export const DrawIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <g>
                <path fill={color}
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25ZM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83Z" />
            </g>
        </svg>
    )
}

export const RectIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <g>
                <path fill={color}
                    d="M19 5v14H5V5h14Zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z" />
            </g>
        </svg>
    )
}

export const RoundRect = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color} fillRule="evenodd"
                d="M16 5H8a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3ZM8 3a5 5 0 0 0-5 5v8a5 5 0 0 0 5 5h8a5 5 0 0 0 5-5V8a5 5 0 0 0-5-5H8Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const CircleIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color} fillRule="evenodd"
                d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const EllipseIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color} fillRule="evenodd"
                d="M18.602 16.04C20.217 14.864 21 13.411 21 12s-.783-2.865-2.398-4.04C16.988 6.788 14.66 6 12 6c-2.66 0-4.988.787-6.602 1.96C3.783 9.136 3 10.589 3 12s.783 2.865 2.398 4.04C7.012 17.212 9.34 18 12 18c2.66 0 4.988-.787 6.602-1.96ZM12 20c6.075 0 11-3.582 11-8s-4.925-8-11-8S1 7.582 1 12s4.925 8 11 8Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const DiamondIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color} fillRule="evenodd"
                d="M12 3.828 3.828 12 12 20.172 20.172 12 12 3.828Zm-9.586 6.758a2 2 0 0 0 0 2.828l8.172 8.172a2 2 0 0 0 2.828 0l8.172-8.172a2 2 0 0 0 0-2.828l-8.172-8.172a2 2 0 0 0-2.828 0l-8.172 8.172Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const RightArrowIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color} fillRule="evenodd"
                d="M16 9H5v6h11v2.028L20.354 12 16 6.972V9Zm-2-4.71c0-.926 1.15-1.355 1.756-.655l6.677 7.71a1 1 0 0 1 0 1.31l-6.677 7.71c-.606.7-1.756.27-1.756-.655V17H5a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h9V4.29Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const IsoscelesTriangleIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color} fillRule="evenodd"
                d="M12 6.415 5.733 18.75h12.534L12 6.415Zm.891-2.66a1 1 0 0 0-1.783 0L3.212 19.297a1 1 0 0 0 .891 1.453h15.793a1 1 0 0 0 .892-1.453L12.891 3.755Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const CardIcon = ({ color = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color} fillRule="evenodd"
                d="M9.828 5 5 9.828V19h14V5H9.828ZM3 9l6-6h10a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const FillColorIcon = ({ color = '#2B2B2B', selectedColor = '#B3b3B3' }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 20H22V24H2V20Z" fill={selectedColor} />
            <path fillRule="evenodd" clipRule="evenodd"
                d="M16.5601 8.94L7.62006 0L6.21006 1.41L8.59006 3.79L3.44006 8.94C2.85006 9.53 2.85006 10.48 3.44006 11.06L8.94006 16.56C9.23006 16.85 9.62006 17 10.0001 17C10.3801 17 10.7701 16.85 11.0601 16.56L16.5601 11.06C17.1501 10.48 17.1501 9.53 16.5601 8.94ZM19.0001 11.5C19.0001 11.5 17.0001 13.67 17.0001 15C17.0001 16.1 17.9001 17 19.0001 17C20.1001 17 21.0001 16.1 21.0001 15C21.0001 13.67 19.0001 11.5 19.0001 11.5ZM5.20996 9.99996L9.99996 5.20996L14.79 9.99996H5.20996Z"
                fill={color} />
        </svg>
    )
}

export const BorderColorIcon = ({ color = '#2B2B2B', selectedColor = '#B3b3B3' }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 24H2V20H22V24Z" fill={selectedColor} />
            <path d="M16.81 8.94006L13.06 5.19006L4 14.2501V18.0001H7.75L16.81 8.94006Z" fill={color} />
            <path
                d="M14.13 4.12006L17.88 7.87006L19.71 6.04006C20.1 5.65006 20.1 5.02006 19.71 4.63006L17.37 2.29006C16.98 1.90006 16.35 1.90006 15.96 2.29006L14.13 4.12006Z"
                fill={color}  />
        </svg>
    )
}

export const TextColorIcon = ({ color = '#2B2B2B', selectedColor = '#B3b3B3' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={selectedColor} d="M2 20h20v4H2v-4Z" />
            <path fill={color} fillRule="evenodd"
                d="M5.49 17h2.42l1.27-3.58h5.65L16.09 17h2.42L13.25 3h-2.5L5.49 17Zm4.42-5.61 2.03-5.79h.12l2.03 5.79H9.91Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const TextAlignLeftIcon = ({ color = '#2B2B2B' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color}
                    d="M15 15H3v2h12v-2Zm0-8H3v2h12V7ZM3 13h18v-2H3v2Zm0 8h18v-2H3v2ZM3 3v2h18V3H3Z" />
        </svg>
    )
}

export const TextAlignCenterIcon = ({ color = '#2B2B2B' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color}
                d="M7 15v2h10v-2H7Zm-4 6h18v-2H3v2Zm0-8h18v-2H3v2Zm4-6v2h10V7H7ZM3 3v2h18V3H3Z" />
        </svg>
    )
}

export const TextAlignRightIcon = ({ color = '#2B2B2B' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path fill={color}
                    d="M3 21h18v-2H3v2Zm6-4h12v-2H9v2Zm-6-4h18v-2H3v2Zm6-4h12V7H9v2ZM3 3v2h18V3H3Z" />
        </svg>
    )
}

export const VisibleIcon = ({ color = '#2B2B2B' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
            <path fill={color}
                d="M8 4a6.037 6.037 0 0 1 5.655 4 6 6 0 0 1-11.31 0A6.037 6.037 0 0 1 8 4Zm0-1a7 7 0 0 0-6.706 5 7 7 0 0 0 13.412 0A7 7 0 0 0 8 3Z" />
            <path fill={color} d="M10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
        </svg>
    )
}

export const HiddenIcon = ({ color = '#2B2B2B' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
            <path fill={color} fillRule="evenodd"
                d="M1.294 8a7 7 0 0 0 13.412 0h-1.051a6 6 0 0 1-11.31 0H1.294Z" clipRule="evenodd" />
        </svg>
    )
}

export const LockIcon = ({ color = '#2B2B2B' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
            <path fill={color} fillRule="evenodd"
                d="M9.353 2.45A1.88 1.88 0 0 0 8 2a1.88 1.88 0 0 0-2 1.722V7h4V3.722a1.879 1.879 0 0 0-.647-1.271ZM11 7V3.722a3.014 3.014 0 0 0-6 0V7H4v7h8V7h-1Z"
                clipRule="evenodd" />
        </svg>
    )
}

export const UnlockIcon = ({ color = '#2B2B2B' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
            <path fill={color} fillRule="evenodd"
                d="M9.353 2.45A1.88 1.88 0 0 0 8 2a1.88 1.88 0 0 0-2 1.722V7h6v7H4V7h1V3.722a3.014 3.014 0 0 1 6 0V4h-1v-.278a1.879 1.879 0 0 0-.647-1.271Z"
                clipRule="evenodd" />
        </svg>
    )
}