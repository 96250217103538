import * as BABYLON from "@babylonjs/core";
import Base from "babylon/class/Base";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";
import {
    generate3DContourVertexData,
    get1DIndex,
    getMockupData,
    getParsedData,
    dataToColorMap,
    parseCoordinates,
    updateMeshColor,
} from "babylon/common/VertexGenerator";

export default class EnuContour extends Base {
    constructor(
        EnuScene,
        name = "contour",
        subX = 3,
        subY = 3,
        subZ = 3,
        requiresNewMaterial
    ) {
        console.time("ENUContour Constructor");
        const contour = new BABYLON.Mesh(name, EnuScene.scene);
        const subdivision = { x: subX, y: subY, z: subZ };

        //Array for hidden state : 1: visible, 0: hidden
        const visibilityBitmap = new Array(
            subdivision.x * subdivision.y * subdivision.z
        ).fill(1);

        const vertexData = generate3DContourVertexData(
            visibilityBitmap,
            subdivision
        );
        vertexData.applyToMesh(contour, true);
        
        contour.metadata = {};
        contour.metadata.objectType = SUPPORT_OBJECT_TYPE.CONTOUR;

        super(EnuScene, contour, requiresNewMaterial);

        this.objectType = SUPPORT_OBJECT_TYPE.CONTOUR;

        this.subdivision = subdivision;
        this.colorElevation = [
            "rgb(10,50,120)",
            "rgb(15,75,165)",
            "rgb(30,110,200)",
            "rgb(60,160,240)",
            "rgb(80,180,250)",
            "rgb(130,210,255)",
            "rgb(160,240,255)",
            "rgb(200,250,255)",
            "rgb(230,255,255)",
            "rgb(255,250,220)",
            "rgb(255,232,120)",
            "rgb(255,192,60)",
            "rgb(255,160,160)",
            "rgb(255,96,0)",
            "rgb(255,50,0)",
            "rgb(225,20,0)",
            "rgb(192,0,0)",
            "rgb(165,0,0)",
        ];

        this.visibilityBitmap = visibilityBitmap;
        
        this.originMesh.metadata.currentStep = 1;
        this.originMesh.metadata.maxStep = 30;

        this.minElevation = 0;
        this.maxElevation = 10;
        this.data = getMockupData(
            this.subdivision,
            this.minElevation,
            this.maxElevation,
            true
        );

        this.hiddenPoints = [];

        console.timeEnd("ENUContour Constructor");
    }

    setSubdivision(subdivision) {
        const { x, y, z } = subdivision;
        this.subdivision.x = x || this.subdivision.x;
        this.subdivision.y = y || this.subdivision.y;
        this.subdivision.z = z || this.subdivision.z;

        //TODO: setSubdivision should not reset visibilityMap
        const dimension =
            this.subdivision.x * this.subdivision.y * this.subdivision.z;
        this.visibilityBitmap = new Array(dimension).fill(1);
        this.data = getMockupData(
            this.subdivision,
            this.minElevation,
            this.maxElevation,
            true
        );
        this.redrawMesh();
    }

    rgbColorElevationToObject(rgbString) {
        // Remove 'rgb(' and ')' and split the string into an array of numbers
        // rgb(243, 128, 0) -> {'r' : 0.92324, ...}
        const numbers = rgbString
            .substring(4, rgbString.length - 1)
            .split(",")
            .map(Number);

        // Create an object with r, g, and b properties
        return {
            r: numbers[0] / 255,
            g: numbers[1] / 255,
            b: numbers[2] / 255,
        };
    }

    get colorElevation() {return this._colorElevation;}
    set colorElevation(_colorElevation) {
        this._colorElevation = _colorElevation;
        this.colorElevationin0to1 = this._colorElevation.map((color) =>
            this.rgbColorElevationToObject(color)
        );
    }

    get hiddenPoints() { return this._hiddenPoints; }
    set hiddenPoints(_hiddenPoints) {
        this._hiddenPoints = _hiddenPoints;

        if (_hiddenPoints.length === 0) {
            this.visibilityBitmap = new Array(
                this.subdivision.x * this.subdivision.y * this.subdivision.z
            ).fill(1);
        } else {
            // (x, 0, 0) -> (0,0,0), (1,0,0), ...
            const CoordinateListToHide = this._hiddenPoints.map((coord) =>
                parseCoordinates(this.subdivision, coord)
            );
            const flatCoordinateListToHide = [].concat(...CoordinateListToHide);
            this.updateVisibilityBitmap(flatCoordinateListToHide);
        }
        this.redrawMesh();
    }

    get visibilityBitmap() {return this._visibilityBitmap; }
    set visibilityBitmap(_visibilityBitmap) {this._visibilityBitmap = _visibilityBitmap;}

    updateVisibilityBitmap(flatCoordinateListToHide) {
        // EX) flatCoordinateListToHide: [[0,0,0], [2,1,0], ...]

        // ERROR -> this.visibilityBitmap[idx] = 0
        const copyArr = [...this.visibilityBitmap];

        const idxList = flatCoordinateListToHide.map((coord) => [
            get1DIndex(coord[0], coord[1], coord[2], this.subdivision),
        ]);
        idxList.forEach((idx) => {
            copyArr[idx] = 0;
        });
        this.visibilityBitmap = copyArr;
    }

    setData(data, isParsed, dimensions) {
        //Example -> "data": { "0,0,0": 0, "0,0,1": 3.232 }
        // if (isParsed === 0 &&
        //     this.subdivision !== dimensions) {
        //     throw new Error("Data dimension doesn't match contour subdivision")
        // }
        if (isParsed === 1) {
            this.data = data;
        } else {
            this.data = getParsedData(
                dimensions,
                data,
                this.minElevation,
                this.maxElevation
            );
        }
        this.redrawMesh();
    }

    redrawMesh() {
        // On Visibility or Subdivision change
        const vertexData = generate3DContourVertexData(
            this.visibilityBitmap,
            this.subdivision
        );
        vertexData.applyToMesh(this.originMesh, true);

        // // On data change
        const colorMap = dataToColorMap(
            Object.values(this.data),
            this.minElevation,
            this.maxElevation,
            this.colorElevationin0to1
        );

        const convertedColorArray = {};
        Object.keys(this.data).forEach((key, index) => {
            convertedColorArray[key] = colorMap[index];
        });

        updateMeshColor(
            this.originMesh,
            this.visibilityBitmap,
            convertedColorArray,
            this.subdivision
        );
    }

    getPropertyMap() {
        const basePropertyMap = super.getPropertyMap();
        return {
            ...basePropertyMap,
            subdivision: {
                x: this.subdivision.x,
                y: this.subdivision.y,
                z: this.subdivision.z,
            },
            objectType: this.objectType,
            hiddenPoints: this.hiddenPoints,
            minElevation: this.minElevation,
            maxElevation: this.maxElevation,
            colorElevation: this.colorElevation,
        };
    }
}
