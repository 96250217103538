import { useState, useEffect } from "react";

import { AUTHORITY_TYPE, PUBLISH_TYPE } from "components/common/EnuSpaceEnum";
import { InputContent } from "components/css/common";

import styled from "styled-components"

export default function BoardAccessList({ PublishType, GrantedFriends }) {
    const [SearchKeyword, setSearchKeyword] = useState('');
    const [FilterFriend, setFilterFriend] = useState('');

    const publishType = Object.keys(PUBLISH_TYPE).find(key => PUBLISH_TYPE[key] === PublishType);

    useEffect(() => {
        if (GrantedFriends.length === 0) return;
        if (SearchKeyword === '') {
            setFilterFriend('');
            return;
        }

        const escapedKeyword = SearchKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        const filterFriend = GrantedFriends.reduce((rcc, friedInfo) => {
            const { EMAIL, NICKNAME } = friedInfo;

            if (EMAIL.search(escapedKeyword) !== -1 || NICKNAME.search(escapedKeyword) !== -1) {
                rcc.push(friedInfo)
            }
            return rcc;
        }, []);

        setFilterFriend(filterFriend);
    }, [GrantedFriends, SearchKeyword]);

    const AccessFriendRender = (friendInfo) => {
        const { NICKNAME, EMAIL, AUTHORITY } = friendInfo;
        const [AccessType] = Object.entries(AUTHORITY_TYPE).find(([key, value]) => value === AUTHORITY);
        return (
            <li key={EMAIL}>
                <div className="info">
                    <span>{NICKNAME}</span>
                    <span>{EMAIL}</span>
                </div>
                <div className={AccessType}>
                    {AccessType}
                </div>
            </li>
        )
    }

    return (
        <BaseContent>
            <ContentHeader>
                <InputContent
                    type="text"
                    id='MemberSearch'
                    name='Filter'
                    title="Search"
                    value={SearchKeyword}
                    placeholder='search...'
                    onChange={(e) => setSearchKeyword(e.target.value)}
                />
            </ContentHeader>

            <ContentBody>
                <li id="publishType">
                    <div>Publish Type</div>
                    <span className={`${publishType}`}>{publishType?.toLowerCase()}</span>
                </li>

                {FilterFriend !== ''
                    ? FilterFriend?.map((friendInfo) => AccessFriendRender(friendInfo))
                    : GrantedFriends?.map((friendInfo) => AccessFriendRender(friendInfo))
                }
            </ContentBody>
        </BaseContent>
    )
}

const BaseContent = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ContentHeader = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px 8px 16px 8px;
    border-bottom: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    gap: 16px;

    input{
        height: 32px;
        padding: 0 6px;
        border-color: ${({theme}) => theme.base.border_color_DarkGray};
    }
`;

const ContentBody = styled.ul`
    position: relative;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;

    #publishType{
        font-size: 1rem;
        padding: 16px 8px;

        &:first-child{
            font-weight: bold;
        }

        .PUBLIC{
            color: ${({ theme }) => theme.base.font_color_Orange};
        }

        .PRIVATE{
            color: ${({ theme }) => theme.base.font_color_DarkBlue};
        }
    }

    .info{
        display: flex;
        flex-direction: column;
        gap: 6px;
        font-size: 1rem;
        color: ${({ theme }) => theme.base.font_color_Black};

        span:nth-child(1){
            font-weight: bold;
        }
        span:nth-child(2){
            font-size: .9rem;
            opacity: 0.8;
        }
    }

    li{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;

        .MASTER{ color: ${({ theme }) => theme.AccessColor.Master}; }
        .MANAGER{ color: ${({ theme }) => theme.AccessColor.Manage}; }
        .NORMAL{ color: ${({ theme }) => theme.AccessColor.Write}; }
    }
`;