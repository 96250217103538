import { useState, useEffect } from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import SelectList from "components/atom/SelectList";
import BtnToggle from "components/atom/BtnToggle";
import { GroupName } from 'components/css/common';
import { GetEnuCanvasList } from "api/EnuCanvasApi";

import { useSelector } from 'react-redux';
import styled from "styled-components";

export default function CanvasLinkList({_data, _ChangeEvent, _disabled}){
    const {
        target_name: NodeTargetName,
        thumbnail: NodeThumbnail,
    } = _data;
    const {userId} = useSelector(state => state.projectData.BASE_DATA);
    const [SelectedCanvas, setSelectedCanvas] = useState(NodeTargetName || '-');
    const [CanvasList, setCanvasList] = useState({});

    useEffect(()=>{
        setSelectedCanvas(NodeTargetName);
    },[NodeTargetName])

    useEffect(()=>{
        GetEnuCanvasList(userId)
            .then(res => {
                const {result, data} = res.data;
                if(result === 'Success'){
                    const canvasList = Object.fromEntries(
                        data.map((canvasInfo) =>{
                            const {ENUCANVAS_NAME, MEMBER_SYSTEMID, ENUCANVAS_SYSTEMID} = canvasInfo;
                            return [ENUCANVAS_NAME, {'target-owner': MEMBER_SYSTEMID, 'xlink:href': ENUCANVAS_SYSTEMID, 'target-name': ENUCANVAS_NAME}]
                        })
                    )
                    setCanvasList({'-': {
                        'target-owner': '',
                        'xlink:href': '',
                        'target-name': '',
                    } ,...canvasList});
                }
            })
            .catch(err => console.log(err));
    }, [userId]);

    return(
        <PropertiesContent _Title="Link">
            <div> 
                <SelectList
                    attributeName="canvasList"
                    dataList={CanvasList}
                    selectedValue={SelectedCanvas}
                    changeEvent={(e)=>{
                        const ChangeData = Object.entries(CanvasList[e[0].attributeValue]).map(([attributeName, attributeValue]) =>({attributeName, attributeValue}))
                        _ChangeEvent(ChangeData);
                    }}
                />
            </div>

            <Thumbnail>
                <GroupName>thumbnail</GroupName>
                <BtnToggle 
                    _toggleID="thumbnail" 
                    _isToggleValue={NodeThumbnail} 
                    _disabled={_disabled} 
                    _IsToggle={(attributeName, attributeValue)=>{
                        _ChangeEvent([{ attributeName, attributeValue }])
                    }}
                />
            </Thumbnail>
        </PropertiesContent>
    )
}

const Thumbnail = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px!important;
`;