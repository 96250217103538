import {useState} from 'react'
import useProjectData from 'hooks/useProjectData'

import styled from "styled-components";
import { MdOutlineClose, MdCode } from "react-icons/md";

function CanvasTabListContent({_canvasViewList , _DeleteTab}){
    const {ChangeTab} = useProjectData();
    const {ChangeActiveCanvasOrder} = useProjectData();
    const {currentView, canvasMode, activeCanvas} = _canvasViewList;

    const [DragTarget, setDragTarget] = useState('');  //드래그 타겟 canvasId

    const TabBox = (CanvasId, children) => {
        const isActive = CanvasId === currentView;
        return (
            <li key={CanvasId}
                id={DragTarget === CanvasId ? 'dragTarget' : ''}
                className={isActive ? 'canvas_active' : ''}
                draggable="true"
                onClick={(e) => ChangeTab(CanvasId)}
                onDragStart={(e) => {
                    e.dataTransfer.setData('targetCanvasId', CanvasId)
                    setDragTarget(CanvasId)
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                    const targetId = e.dataTransfer.getData('targetCanvasId')
                    if(targetId=== '' || targetId === CanvasId) return;
                    ChangeActiveCanvasOrder(targetId, CanvasId);
                }}
                onDragEnd={(e) => setDragTarget('')}
            >
                {canvasMode[CanvasId] === 'code' && <MdCode style={{ paddingRight: '4px', color: 'steelblue' }} />}
                {children}
                {<MdOutlineClose className="btn_close" onClick={(e)=>_DeleteTab(e, CanvasId)}/>}
            </li>
        )
    }

    if( _canvasViewList !== '' && Object.keys(activeCanvas).length > 0){
        return(
            <div>
                <UlContent>
                {
                    Object.keys(activeCanvas).map((CanvasId)=>{
                        const arrayPath = activeCanvas[CanvasId]?.split('\\');
                        let tabName;
                        if(arrayPath[0] === 'library' && arrayPath[1] === 'hmi'){   //hmi 일경우 hmi(symbolName)
                            tabName = canvasMode[CanvasId] === 'code' ? arrayPath[2] : arrayPath[2] + "(" + arrayPath[3] +")"
                        }else
                            tabName = arrayPath[1];
                        
                        return TabBox(CanvasId, tabName);
                    })
                }
                </UlContent>
            </div>
        )
    }else{
        return <></>;
    }
}

export default CanvasTabListContent;

//스타일-------------------------------------
const UlContent = styled.ul`
    display: flex;
    height: 40px;
    box-sizing: border-box;
    background-color: #D8D8D8;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};

    #dragTarget{
        border: 2px dashed ${({theme}) => theme.base.border_color_DarkBlue};
        padding-left: 6px;
    }

    &>li{
        position: relative;
        display: flex;
        padding-left: 8px;
        border-right:  1px solid ${({theme}) => theme.base.border_color_Gray};
        align-items: center;
        cursor: pointer;
        user-select: none;

        &:hover{ background-color: #fff; }
        
        &.canvas_active{
            background-color: ${({theme}) => theme.base.background_color_PaleGray};
            &:hover{ background-color: #fff; }

            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: ${({theme}) => theme.base.background_color_PaleGray};
            }
        }

        .btn_close{
            cursor: pointer;
            padding: 8px;
            opacity: .3;
            &:hover{
                color: red;
                opacity: 1;  
            }
        }
    }
`;