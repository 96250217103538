import axios from 'axios';
import {refreshAccessToken} from 'hooks/useMember';

export const Axios = axios.create({
    headers: { 'Content-Type': 'application/json' }
});

export const AccessAxios = axios.create({
    headers: {'Content-Type': 'application/json'}
});

// 요청시 전달되기 전 인터셉트
AccessAxios.interceptors.request.use(
    config =>{
        // console.log("요청전-----------------------");
        // console.log(config)

        const token = localStorage.getItem('Authorization') || sessionStorage.getItem('Authorization');
        const systemId = localStorage.getItem('systemId') || sessionStorage.getItem('systemId');
        const Project = localStorage.getItem('projectId') || sessionStorage.getItem('projectId');
        const Publish = localStorage.getItem('publishId') || sessionStorage.getItem('publishId');

        if(token)config.headers.Authorization = token;
        if(systemId)config.headers.User = systemId;
        if(Project)config.headers.Project = Project;
        if(Publish)config.headers.Publish = Publish;

        return config;
    },
    error =>{
        // console.log("요청전-오류----------------------");
        // console.log(error)
        return Promise.reject(error);
    }
);

// 요청시
AccessAxios.interceptors.response.use(
    response => {
        // console.log("요청후-----------------------");
        // console.log(response)
        return response;
    },
    error => {
        // console.log("요청후-오류----------------------");
        // console.log(error)

        // if(process.env.NODE_ENV === 'development'){
        //     window.AddAlertMsg({ type: 'error', msg: `${error}` });
        // }

        const {config:originalRequest, response:{status: resStatus, data: resData } } = error
        const removeLocalStorage = () =>{
            localStorage.removeItem('Authorization');
            localStorage.removeItem('refreshtoken');
            localStorage.removeItem('systemId');
            localStorage.removeItem('nickname');
            localStorage.removeItem('name');
            localStorage.removeItem('profileId');
            localStorage.removeItem('email');
        }

        if(error.response && resStatus === 401){
            if (resData === '[Access Token has expired]' && !originalRequest._retry) {
                originalRequest._retry = true;
                return refreshAccessToken()
                    .then(() => {
                        return AccessAxios(originalRequest);
                    })
                    .catch(err => {
                        if (typeof window.SessionLoginPopup === 'function') {
                            window.SessionLoginPopup();
                        }else{
                            alert("로그인 정보가 만료되었습니다. 재인증이 필요합니다. 다시 로그인해주세요.");
                            // 토큰 갱신 실패 및 다른 에러 처리
                            removeLocalStorage();
                            window.location.href = "/login";
                        }
                        throw err;
                    });
            } else if(resData === '[Invalid Token SystemID]' || resData === `[Invalid MEMBER_SYSTEMID's Token]`){
                if(typeof window.SessionLoginPopup === 'function'){
                    window.SessionLoginPopup();
                }else{
                    alert("로그인 정보가 만료되었습니다. 재인증이 필요합니다. 다시 로그인해주세요.");
                    
                    removeLocalStorage();
                    window.location.href = "/login";
                }
            }
        }
        
        return Promise.reject(error);
    }
);
