import { useState, useEffect, useRef, useContext } from "react";

import { BtnPrimary, BtnSecondary } from "components/atom/Button";
import { RadioComponent, HighlightedRadio } from "components/atom/RadioContent";
import Checkbox from "components/atom/Checkbox";
import { PUBLISH_TYPE } from "components/common/EnuSpaceEnum";
import { ModalBottomBox, GroupName, InputContent, SelectContent, SubTitle } from 'components/css/common';
import { CheckUser } from "hooks/useMember";
import { PublishProject, GetPublicProjectList } from 'api/RepositoryApi'
import { SaveFile } from 'api/Project'
import { TransientModalContext } from "context/TransientModalContext"

import styled from "styled-components";
import { MdClear } from "react-icons/md";
import { useSelector } from 'react-redux';

export default function PublishMenu({ enuSpace, treeData, saveEvent, publishReducer }) {
    const { setTransientModalInfo } = useContext(TransientModalContext);
    const [pictureList, setPictureList] = useState([]);
    // const [Publish, setPublish] = PublishData;
    const [PublishInfo, setPublishInfo] = publishReducer;
    const { userList, mode, activateViewPath, isMetaverse, useAvatar, autoFit, enterNodeID } = PublishInfo;

    const UserListRef = useRef();
    const BASE_DATA = useSelector(state => state.projectData.BASE_DATA);

    useEffect(() => {
        if (UserListRef.current)
            UserListRef.current.scrollTop = UserListRef.current.scrollHeight;
    }, [userList]);

    useEffect(() => {
        let pathList = [];
        function extractPath(data, basePath = '') {
            if (data && Array.isArray(data.child)) {
                data.child.forEach((item) => {
                    const itemPath = basePath ? `${basePath}\\${item.title}` : item.title;
                    if (item.type === "file")
                        pathList.push(itemPath);
                    else
                        extractPath(item, itemPath);
                });
            }
        }

        treeData.filter((Target) => Target.title === 'picture').forEach((Target) => extractPath(Target, "picture"));
        setPictureList(pathList);
    }, [enuSpace, treeData]);

    window.Re_ChangeLuaCode = (_msg) => {
        var json = JSON.parse(_msg);
        GetPublicProjectList(BASE_DATA.userId)
            .then((res) => {
                const resJson = res.data;
                if (resJson.result === "Success") {
                    const data = resJson.data;
                    for (const element of data) {
                        if (element.PROJECT_NAME === BASE_DATA.projectName) {
                            var publishID = element.PUBLISH_SYSTEMID;
                            for (var newElement of json) {
                                newElement.PROJECT_SYSTEMID = publishID;
                            }
                        }
                    }

                    for (const element of json) {
                        SaveFile(
                            element.MEMBER_SYSTEMID,
                            element.PROJECT_SYSTEMID,
                            element.FILE_TYPE,
                            element.FILE_NAME,
                            element.DATA
                        )
                            .then((res) => {
                                const { result } = res.data;
                                if (result === 'Fail') {
                                    SaveFile(
                                        element.MEMBER_SYSTEMID,
                                        element.PROJECT_SYSTEMID,
                                        element.FILE_TYPE,
                                        element.FILE_NAME,
                                        element.DATA
                                    )
                                }
                            })
                    }
                    window.AddToastMsg('Publish Success');
                }
            })
    }

    return (
        <PublishSpace onMouseDown={(e) => e.stopPropagation()}>
            <div className="setPosition">
                {mode === PUBLISH_TYPE.PRIVATE &&
                    <PublishBox>
                        <ShareUserTitle>
                            공유 대상 사용자 <span>{userList.length}명</span>
                        </ShareUserTitle>
                        <TagsInput ref={UserListRef}>
                            <ul id="tags">
                                {userList.map((Email, index) => (
                                    <li key={index} className="tag">
                                        <span className="tag-title">{Email}</span>
                                        <i className="tag-close-icon">
                                            <MdClear onClick={(e) => setPublishInfo({ type: 'DeleteUser', index })} />
                                        </i>
                                    </li>
                                ))}
                            </ul>
                            <input
                                name="tag"
                                type="text"
                                placeholder="Press enter to add User Email"
                                onKeyUp={(e) => {
                                    const userEmail = e.target.value;
                                    if (e.key === "Enter" && userEmail !== "") {
                                        CheckUser(userEmail)
                                            .then((res) => {
                                                const { result, msg } = res.data;
                                                if (result === 'Success') {
                                                    setPublishInfo({ type: 'AddUser', userEmail })
                                                    e.target.value = "";
                                                } else {
                                                    alert(msg);
                                                }
                                            }
                                        )
                                    }
                                }}
                            />
                        </TagsInput>
                    </PublishBox>
                }

                <PublishBox>
                    <div className="InputSpace">
                        <HighlightedRadio
                            isUseKeyLabel={true}
                            attributeName='mode'
                            height='36px'
                            dataList={PUBLISH_TYPE}
                            titleList={{ PUBLIC: 'EnuSpace 모든 사용자에게 공개됩니다.', PRIVATE: '비공개 프로젝트입니다.' }}
                            selectedValue={mode}
                            changeEvent={(name, value) => setPublishInfo({ type: 'ChangeMode', value })}
                        />

                        <div className="flexBox">
                            <GroupName width={98} htmlFor='ActivateView'>ActivateView</GroupName>
                            <SelectContent
                                name='ActivateView'
                                value={activateViewPath || ''}
                                onChange={(e) => setPublishInfo({ type: 'ChangeViewPath', viewPath: e.target.value })}
                            >
                                {pictureList &&
                                    pictureList.map((_path) => (<option key={_path} value={_path}>{_path}</option>))
                                }
                            </SelectContent>
                        </div>

                        <RadioComponent
                            height='36px'
                            attributeName='publishType'
                            dataList={{ Metaverse: 'Metaverse Mode', Graphic: 'Graphic Mode' }}
                            titleList={{ Metaverse: '메타버스 설정 모드입니다.', Graphic: '그래픽 가시화 설정 모드 입니다.' }}
                            selectedValue={isMetaverse ? 'Metaverse' : 'Graphic'}
                            changeEvent={(name, value) => setPublishInfo({ type: 'ChangeMetaverseMode', isMetaverse: value === 'Metaverse' })}
                        />

                        <div id="PublishAvatar" className={`${!isMetaverse && "disabled"}`} >
                            <Checkbox
                                _id='Avatar'
                                checked={useAvatar}
                                _disabled={!isMetaverse}
                                onChange={(e) => setPublishInfo({ type: 'ChangeUseAvatar', isMetaverse: e.target.checked })}
                            >
                                Use Avatar
                            </Checkbox>

                            <span style={{ marginRight: "16px" }}>{useAvatar ? `(아바타 사용)` : `(아바타 사용안함 )`}</span>

                            {useAvatar &&
                                <Checkbox
                                    _id='FullScreen'
                                    checked={autoFit}
                                    _disabled={!isMetaverse}
                                    onChange={(e) => setPublishInfo({ type: 'ChangeAutoFit', isAutoFit: e.target.checked })}
                                >
                                    Full Screen
                                </Checkbox>
                            }
                        </div>

                        <div className="flexBox">
                            <GroupName
                                className={`labelTitle ${!isMetaverse && "disabled"}`}
                                width={90}
                                htmlFor='EnterID'
                            >EnterID</GroupName>
                            <InputContent
                                type="text"
                                id='EnterID'
                                name='EnterID'
                                value={enterNodeID}
                                placeholder='Avatar start node ID'
                                disabled={!isMetaverse}
                                onChange={(e) => setPublishInfo({ type: 'ChangeEnterId', enterNodeID: e.target.value })}
                            />
                        </div>
                    </div>
                    <ModalBottomBox>
                        <BtnSecondary label="Cancel" onClick={(e) => setTransientModalInfo()} />
                        <BtnPrimary
                            label="Publish"
                            onClick={(e) => {
                                const Mode = isMetaverse ? 'Metaverse Mode' : 'Graphic Mode';
                                if (!window.confirm(`"${Mode}"로 Publish 하시겠습니까? ${(useAvatar) ? '(아바타 사용)' : ''}`))
                                    return;

                                const { projectId, userId } = BASE_DATA;
                                enuSpace.SetActivateView(activateViewPath);
                                enuSpace.SetMetaverseFlag(isMetaverse);
                                enuSpace.SetMetaverseAvatarFlag(useAvatar);
                                enuSpace.SetMetaverseViewFixedFlag(autoFit);
                                enuSpace.SetEnterNodeID(enterNodeID);

                                PublishProject(
                                    userId,
                                    projectId,
                                    mode,
                                    isMetaverse ? 1 : 0,
                                    useAvatar ? 1 : 0,
                                    mode === PUBLISH_TYPE.PRIVATE ? userList : []
                                ).then((res) => {
                                    const { result, msg } = res.data;
                                    if (result === 'Success') {
                                        saveEvent && saveEvent(e, "PublishEvent");
                                        enuSpace.ChangeLuaCode();
                                        enuSpace.SaveThumbnail('picture', activateViewPath, '.jpeg', 'project_thumbnail');
                                        // window.AddToastMsg('Publish Success');
                                    } else {
                                        window.AddAlertMsg({ type: 'error', msg: `Publish Fail: ${msg}` });
                                    }
                                })
                            }}
                        />
                    </ModalBottomBox>
                </PublishBox>
            </div>
        </PublishSpace>
    )
}

const PublishBox = styled.div`
    position: relative;
    width: 400px;
    border-radius: 0.3rem;
    padding: 16px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
    box-sizing: border-box;

    &>span{
        display: block;
        margin-bottom: 8px;
    }
`;

const ShareUserTitle = styled(SubTitle)`
    span{
        margin-left: 8px;
        color: ${({ theme }) => theme.base.font_color_DarkGray};
    }
`;

const PublishSpace = styled.div`
    position: absolute;
    top: 0;
    right: 0;

    .disabled{
        opacity: 0.6;
    }

    .setPosition{
        position: absolute;
        display: flex;
        top: 80px;
        right: 30px;
        z-index: 15;
        gap: 16px;
    }

    .InputSpace{
        position: relative;
        width: 100%;
        height: calc(100% - 62px);

        &>div{
            margin-bottom: 8px;
        }

    }

    .flexBox{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 16px;
        border-top: 1px solid ${({ theme }) => theme.base.background_color_Gray};
        margin-top: 16px;
        margin-bottom: 16px!important;
    }

    input[type="radio"] + label{
        color: ${({ theme }) => theme.base.font_color_Black};
    }

    input[type="radio"]:checked + label{
        position: relative;
        border-color: ${({ theme }) => theme.base.border_color_Blue};
    }

    input[type="radio"]:not(:checked) + label{
        opacity: 0.6;
        &:hover{
            opacity: 1;
        }
    }

    #PublishAvatar{
        display: flex;
        align-items: baseline;
        height: 34px;
        width: 100%;

        &>div{
            height: 100%;
        }

        label{
            color: ${({ theme }) => theme.base.font_color_Black};
        }
        
        span{
            margin-left: 8px;
            font-size: 0.9rem;
            color: ${({ theme }) => theme.base.font_color_DarkGray};
        }
    }
`;

const TagsInput = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-height: calc(348px - 68px);
    overflow: auto;
    padding: 0 8px;
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    border-radius: 4px;
    box-sizing: border-box;

    &:focus-within {
        border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
    }

    input {
        width: 100%;
        display: block;
        border: none;
        height: 46px;
        font-size: .9rem;
        padding: 4px 0 0 0;

        &:focus {
            outline: transparent;
        }
    }

    #tags {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 8px;
        gap: 8px;
    }

    .tag {
        width: auto;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 8px;
        padding-left: 16px;
        font-size: .9rem;
        list-style: none;
        border-radius: 16px;
        background: ${({ theme }) => theme.base.background_color_PaleGray};
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};;

        .tag-title {
            /* margin-top: 3px; */
        }

        .tag-close-icon {
            svg{
                display: block;
                width: 16px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                font-size: 1rem;
                margin-left: 8px;
                color: ${({ theme }) => theme.base.font_color_Black};
                border-radius: 50%;
                background-color: #FFF;
                cursor: pointer;
            }
        }
    }
`;
