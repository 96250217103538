import PropertiesContent from "components/atom/PropertiesContent";
import LabelAndInput from "babylon/components/atom/LabelAndInput";
import useDataController from "babylon/hooks/useDataController";
import SelectListContentWithLabel from "babylon/components/atom/SelectListContentWithLabel"

const BILLBOARD_MODE = {
    NONE: 0,
    X: 1,
    Y: 2,
    Z: 4,
    ALL: 7,
}

export default function Display({ selectBabylonData, SelectedMesh }) {
    const {
        visibility,
        billboardMode
    } = selectBabylonData;

    const LABEL_WIDTH = `124px`;

    const { UploadDataByMesh } = useDataController();

    if(visibility === undefined && billboardMode === undefined) return null;

    return (
        <PropertiesContent
            _Title="Display"
        >
            {visibility !== undefined && <LabelAndInput
                id='visibility'
                label='visibility'
                inputType='number'
                value={visibility * 100}
                labelWidth={LABEL_WIDTH}
                min={0}
                changeEvent={(e) => {
                    const targetValue = Number(e.target.value);
                    SelectedMesh.visibility = targetValue / 100;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}

            {billboardMode !== undefined && 
                <SelectListContentWithLabel
                    id='billboardMode'
                    label='billboard Mode'
                    labelWidth={LABEL_WIDTH}
                    selectedValue={billboardMode}
                    selectListData={BILLBOARD_MODE}
                    callbackChangeEvent={(value)=>{
                        const targetValue = Number(value);
                        SelectedMesh.billboardMode = targetValue;
                        UploadDataByMesh(SelectedMesh);
                    }}
                />
            }

        </PropertiesContent>
    )
}