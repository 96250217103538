import {
    EnuIconAlignBottom,
    EnuIconAlignTop,
    EnuIconAlignRight,
    EnuIconAlignMiddle,
    EnuIconAlignLeft,
    EnuIconAlignCenter,
    EnuIconDistributeVertically,
    EnuIconDistributeHorizontally,
    EnuIconSendToBack,
    EnuIconSendBackward,
    EnuIconBringToFront,
    EnuIconBringForward,
} from "components/common/EnuSpaceIcon"

import styled from "styled-components"
import { useSelector } from 'react-redux';

export default function AlignContent({enuSpace}){
    const ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ACTIVE_PICTURE_URL);  //활성화 된 picture URL

    const alignList = [
        { name: 'align_left', svg: <EnuIconAlignLeft/>, hotKey:"alt + a"},
        { name: 'align_center', svg: <EnuIconAlignCenter/>, hotKey:"alt + h"},
        { name: 'align_right', svg: <EnuIconAlignRight/>, hotKey:"alt + d"},
        { name: 'align_horizontally', svg: <EnuIconDistributeHorizontally/>, hotKey:"alt + shift + h"},
        { name: 'align_top', svg: <EnuIconAlignTop/>, hotKey:"alt + w"},
        { name: 'align_middle', svg: <EnuIconAlignMiddle/>, hotKey:"alt + v"},
        { name: 'align_bottom', svg: <EnuIconAlignBottom />, hotKey:"alt + s"},
        { name: 'align_vertically', svg: <EnuIconDistributeVertically/>, hotKey:"alt + shift + v"},
    ];

    const orderList = [
        { name: 'sendToBack', svg: <EnuIconSendToBack />, hotKey:"ctrl + shift + ["},
        { name: 'bringToFront', svg: <EnuIconBringToFront />, hotKey:"ctrl + shift + ]"},
        { name: 'sendBackward', svg: <EnuIconSendBackward />, hotKey:"ctrl + ["},
        { name: 'bringForward', svg: <EnuIconBringForward />, hotKey:"ctrl + }"},
    ];

    const handleImgListClick  = (EventType, type) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, EventType, type, "");
    
    return(
        <AlignDiv>
            <ul>
                {alignList.map((item) => {
                    const { name, svg, hotKey } = item;
                    return (
                        <li key={name} title={`${name}\n${hotKey}`} onClick={(e) => handleImgListClick("ALIGN", name)}>
                            {svg}
                        </li>
                    )
                })}
            </ul>
            <ul>
                {orderList.map((item) =>{
                    const {name, svg, hotKey} = item;
                    return(
                        <li key={name} title={`${name}\n${hotKey}`} onClick={(e)=>handleImgListClick ("ORDER", name)}>
                            {svg}
                        </li>
                    )
                })}
            </ul>
        </AlignDiv>
    )
}

// 스타일-----------------------------------------------------
const AlignDiv = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};

    ul{
        display: flex;
        padding: 0 8px;
        
        li{
            svg{
                cursor: pointer;
                padding: 6px;
            }

            &:hover{
                background-color: ${({theme}) => theme.base.background_color_LightBlue};
            }
        }
    }
`;