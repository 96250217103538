import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";

//TODO: 추후 url로 이미지 추가 기능후 사용안함
export default function ImageInfoContent({_data, _ChangeEvent, _disabled}){
    const {xlink_href: NodeLinkPath} = _data;

    return(
        <PropertiesContent _Title="href">
            {!Array.isArray(NodeLinkPath) &&
                <InputComponent attributeName='href' value={NodeLinkPath} changeEvent={_ChangeEvent} disabled={_disabled}/>
            }
        </PropertiesContent>
    )
}