import { useState, useEffect, useCallback } from 'react';

import {GetArchiveProjectInfo, GetRepoTree, GetArchiveMemberAccess} from "api/ArchiveApi"
import Notification from 'components/atom/Notification'
import ArchiveContent from 'components/organism/ArchiveContent'
import { BaseInfoContext } from 'context/ArchiveContext'
import GuestNameInput from "components/organism/GuestNameInput";
import {AUTHORITY_TYPE } from "components/common/EnuSpaceEnum";

import { useLocation } from 'react-router-dom'
import styled from 'styled-components';

export default function LinkArchive(){
    const [MyAccess, setMyAccess] = useState();
    const [BaseInfo, setBaseInfo] = useState({});
    const [ArchivePathInfo, setArchivePathInfo] = useState({
        TreeData: [],
        CurrentData: [],
        CurrentIDX: null,
        Path: [],
    });

    // 닉네임 입력 박스
    const [isInputBox, setIsInputBox] = useState({ visibility: false, userNickname: '', });

    useEffect(()=>{
        return ()=>{
            if(isInputBox.userNickname !== ''){
                sessionStorage.removeItem('Authorization');
                sessionStorage.removeItem('refreshtoken');
                sessionStorage.removeItem('systemId');
                sessionStorage.removeItem('nickname');
            }
        }
    }, [isInputBox]);

    useEffect(()=>{
        const systemId = localStorage.getItem('systemId') || sessionStorage.getItem('systemId');

        if(systemId === null || systemId === undefined){
            setIsInputBox(prevIsInputBox => ({ ...prevIsInputBox, visibility: true }));
        }else{
            setBaseInfo({userId: systemId})
        }
    }, []);

    const location = useLocation();
    useEffect(() => {
        if(!BaseInfo?.userId)
            return;

        const Search = location.search;
        const searchParams = new URLSearchParams(Search);
        const pageId = searchParams.get('archive');
        const path = searchParams.get('path');

        GetArchiveProjectInfo(pageId)
            .then((res) => {
                const { result, data, msg } = res.data;
                if (result === 'Success') {
                    setBaseInfo(prev=>({
                        ...prev,
                        email: localStorage.getItem('email'),
                        pageId: pageId,
                        pageName: data.ARCHIVE_NAME,
                        publishType: data.PUBLISH_MODE,
                        description: data.DESCRIPTION,
                        usedStorage: data.ARCHIVE_SIZE,
                        maxStorageSize: data.ARCHIVE_SIZE_LIMIT,
                        path,
                    }))
                } else {
                    window.AddAlertMsg({ type: 'error', msg: `${msg}` });
                }
            })
    }, [location, BaseInfo.userId]);

    const GetArchiveTree = useCallback(async (path = null) => {
        if (!BaseInfo.pageId || !BaseInfo.userId) return;

        const resultData = await GetRepoTree(BaseInfo.pageId, BaseInfo.userId, path);

        let { result, data } = resultData.data;

        if (result === "Success") {
            return { parentId: path, data: data || null };
        } else {
            return { parentId: path, data: null };
        }
    }, [BaseInfo.pageId, BaseInfo.userId]);

    const SetArchivePathInfo = useCallback(async (pathArray) => {
        const root = await GetArchiveTree();
        let TreeData = root.data;
        let CurrentData = root.data;
        let CurrentIDX = null;

        let index = 0;

        function findNodeByName(nodes, pathName) {
            for (const node of nodes) {
                if (node._NAME === pathName) {
                    return node;
                }
                if (node.children && node.children.length > 0) {
                    const foundInChildren = findNodeByName(node.children, pathName);
                    if (foundInChildren) {
                        return foundInChildren;
                    }
                }
            }
            return null;
        }

        while (pathArray.length > index) {
            const pathName = pathArray[index]
            const targetInfo = findNodeByName(TreeData, pathName);
            CurrentIDX = targetInfo.IDX;
            const targetData = await GetArchiveTree(CurrentIDX);
            if (targetData.data !== null)
                targetInfo.children = targetData.data;
            CurrentData = targetData.data;
            index++;
        }

        setArchivePathInfo(prev => ({
            ...prev,
            TreeData,
            CurrentData,
            CurrentIDX,
            IdxPath: [],
            Path: pathArray,
        }))

    }, [GetArchiveTree]);

    useEffect(() => {
        if (BaseInfo.path === undefined) return;

        const pathArray = BaseInfo.path?.split('/').filter(Boolean) || [];
        SetArchivePathInfo(pathArray);
    }, [BaseInfo.path, SetArchivePathInfo]);

    const LoadGrantedFriends = (pageId, userId) =>{
        GetArchiveMemberAccess(pageId, userId)
            .then((res) => {
                const { result, data } = res.data;
                if (result === 'Success') {
                    // 접속자의 권한정보를 setMyAccessData 저장
                    const MyInfo = data.find((e)=>e.MEMBER_SYSTEMID === userId);
                    let authority = AUTHORITY_TYPE.NONE;
                    if(MyInfo){
                        authority = MyInfo?.AUTHORITY;
                    }
                    
                    setMyAccess(authority);
                }
            })
    }

    useEffect(()=>{
        if(BaseInfo.pageId == null || BaseInfo.pageId === undefined) return;
        if(BaseInfo.userId == null || BaseInfo.userId === undefined) return;
        LoadGrantedFriends(BaseInfo.pageId, BaseInfo.userId);
    }, [BaseInfo.pageId, BaseInfo.userId]);

    return (
        <>
            <Notification />
            <ViewContent>
                <BaseInfoContext.Provider value={{ BaseInfo }}>
                    <ArchiveContent ArchivePathInfo={ArchivePathInfo} SetArchivePathInfoEvent={SetArchivePathInfo} IsLinkPage={true} MyAccess={MyAccess}/>
                </BaseInfoContext.Provider>
            </ViewContent>

            {isInputBox.visibility &&
                <GuestNameInput 
                    value={isInputBox.userNickname} 
                    onClickEvent={(guestName)=>{
                        setIsInputBox({userNickname: guestName,  visibility: false})
                        setBaseInfo({userId: guestName})
                    }}
                />
            }
        </>
    )
}

const ViewContent = styled.article`
    position: relative;
    height: 100%;
    flex: 1;
`;