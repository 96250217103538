import { useState, useEffect } from 'react';

import BoardContent from 'components/organism/BoardContent'
import BoardAccessList from 'components/organism/BoardAccessList'
import AccessSetting from 'components/organism/AccessSetting'
import ArchiveBoardHeader from 'components/organism/ArchiveBoardHeader'
import Notification from 'components/atom/Notification'
import BoardDetail from 'components/organism/BoardDetail'
import { AUTHORITY_TYPE } from "components/common/EnuSpaceEnum";
import {GetArchiveMemberAccess, GetArchiveProjectInfo} from "api/ArchiveApi"

import styled from "styled-components"
import { useLocation } from "react-router-dom";

export default function BoardSettingPage(){
    const [isAccessSetting, setIsAccessSetting] = useState(false);
    const [GrantedFriends, setGrantedFriends] = useState([]);
    const [BaseInfo, setBaseInfo] = useState({ });
    const [MyAccess, setMyAccess] = useState();

    const systemId = localStorage.getItem('systemId');
    const userMail = localStorage.getItem('email');

    const location = useLocation();
    useEffect(() => {
        const Search = location.search;
        const searchParams = new URLSearchParams(Search);
        
        const pageId = searchParams.get('border');
        const postIndex= searchParams.get('num');
        const keyword= searchParams.get('keyword');
        const page = searchParams.get('page');

        GetArchiveProjectInfo(pageId)
            .then((res) => {
                const {result, data, msg} = res.data;
                if(result === 'Success'){
                    setBaseInfo({
                        userId: systemId,
                        email: userMail,
                        pageId: pageId,
                        pageName: data.ARCHIVE_NAME,
                        postIndex: postIndex,
                        publishType: data.PUBLISH_MODE,
                        description: data.DESCRIPTION,
                        keyword,
                        page,
                    })
                }else{
                    window.AddAlertMsg({type:'error', msg:`${msg}`});
                }
            })
    }, [location, systemId, userMail]);

    const LoadGrantedFriends = (pageId, userId) =>{
        GetArchiveMemberAccess(pageId, userId)
            .then((res) => {
                const { result, data } = res.data;
                if (result === 'Success') {
                    // 접속자의 권한정보를 setMyAccessData 저장
                    const MyInfo = data.find((e)=>e.MEMBER_SYSTEMID === userId);
                    setMyAccess(MyInfo.AUTHORITY);
                    setGrantedFriends(data);
                }
            })
    }

    useEffect(()=>{
        if(!BaseInfo.pageId && !BaseInfo.userId) return;
        LoadGrantedFriends(BaseInfo.pageId, BaseInfo.userId);
    }, [BaseInfo.pageId, BaseInfo.userId]);

    const ActiveContent = () =>{
        if(isAccessSetting){
            return <AccessSetting
                BaseInfoState={[BaseInfo, setBaseInfo]}
                GrantedFriends={GrantedFriends}
                ReloadGrantedFriends={(e)=>{
                    LoadGrantedFriends(BaseInfo.pageId, BaseInfo.userId)
                }}
                CloseEvent={(e)=>setIsAccessSetting(false)}
            />
        }else if(BaseInfo.postIndex ){
            return <BoardDetail BaseInfo={BaseInfo} MyAccess={MyAccess}/>
        }else {
            return <BoardContent BaseInfo={BaseInfo} MyAccess={MyAccess}/>
        }
    }

    return(
        <>
            <Notification />
            <ArchiveBoardHeader BaseInfo={BaseInfo} MyAccess={MyAccess} IsAccessPageState={[isAccessSetting, setIsAccessSetting]} closeUrl='/Board'/>
            <Main>
                <ViewContent>
                    {ActiveContent()}
                </ViewContent>
                
                { MyAccess !== AUTHORITY_TYPE.NORMAL &&
                    <AccessContent>
                        <BoardAccessList
                            PublishType={BaseInfo.publishType}
                            GrantedFriends={GrantedFriends}
                        />
                    </AccessContent>
                }
            </Main>
        </>
    )
}

const Main = styled.main`
    position: relative;
    width: 100%;
    height: calc(100vh - 46px);
    display: flex;
`;

const ViewContent = styled.article`
    position: relative;
    flex: 1;
    overflow: auto;
`;

const AccessContent = styled.aside`
    position: relative;
    width: 300px;
    border-left: 1px solid ${({theme}) => theme.base.border_color_Gray};
`;