
import { useContext } from "react";
import ArchiveTreeRowContent from 'components/atom/ArchiveTreeRowContent'
import { TreeUlContent } from "components/css/common";
import { ARCHIVE_TREE_TYPE } from 'components/common/EnuSpaceEnum'
import { BaseInfoContext } from 'context/ArchiveContext'
import { FormatFileSize } from 'components/Utility'

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'

export default function ArchiveTree({ ArchiveTreeData, BaseUrl }) {
    const navigate = useNavigate();
    const { BaseInfo } = useContext(BaseInfoContext);
    const { pageName: ArchiveName, usedStorage, maxStorageSize } = BaseInfo;

    const RenderTree = (treeData) => {
        return treeData.map((rowInfo, index) => {
            const { _NAME, _TYPE, children, PATH } = rowInfo;

            if (Number(_TYPE) === ARCHIVE_TREE_TYPE.FILE) return null;
            const path = PATH.replace(/\\/g, '/');

            return (
                <li key={index}>
                    <ArchiveTreeRowContent
                        title={_NAME}
                        type={Number(_TYPE)}
                        path={`/${path}`}
                        onClickEvent={(e) => navigate(`${BaseUrl}&path=/${path}`)}
                    >
                        {children &&
                            <TreeUlContent>
                                {RenderTree(children)}
                            </TreeUlContent>
                        }
                    </ArchiveTreeRowContent>
                </li>
            )
        })
    }

    return (
        <TreeContent>
            <StorageSizeContent>
                <progress title={`${FormatFileSize(usedStorage)} / ${FormatFileSize(maxStorageSize)}`} max={maxStorageSize} value={usedStorage} />
            </StorageSizeContent>
            <div className='treeBoy'>
                <ArchiveTreeRowContent title={ArchiveName} type={ARCHIVE_TREE_TYPE.FOLDER} onClickEvent={(e) => navigate(`${BaseUrl}`)}>
                    <TreeUlContent>
                        {ArchiveTreeData?.length > 0 && RenderTree(ArchiveTreeData)}
                    </TreeUlContent>
                </ArchiveTreeRowContent>
            </div>
        </TreeContent>
    )

}

const TreeContent = styled.aside`
    position: relative;
    width: 250px;
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    box-sizing: border-box;

    .treeBoy{
        position: relative;
        height: calc(100% - 40px);
        padding-left: 8px;
        overflow: auto;
    }
`;

const StorageSizeContent = styled.div`
    position: sticky;
    display: flex;
    align-items: center;
    gap: 4px;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    padding:0 8px;
    height: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    background-color: #FFF;
    user-select: none;

    progress{
        width: 100%;
    }
`;