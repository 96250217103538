import { useState, useEffect } from 'react';

import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {ModalBottomBox, WarningMsg} from "components/css/common";
import useImageList from "hooks/useImageList";
import useModalPopup from "hooks/useModalPopup";

import styled from "styled-components";

function ChangeImageModalFileName({enuSpace, _Modal}){
    const {ChangeName} = useImageList();
    const {DeleteModal} = useModalPopup();
    const [value, setValue]= useState('');
    const [msg, setMsg]= useState('');

    const CloseModal = (e) => DeleteModal(_Modal.id);

    useEffect(()=>{
        setValue(_Modal.data['originalName']);
    }, [_Modal]);

    const CheckRegular = (_fileName) => {
        // eslint-disable-next-line
        const special_pattern = /[`~!@#$%^&*|\\\'\";:\/?","]/gi;

        if(!isNaN(_fileName[0])){
            setMsg("파일명은 숫자로 시작할수 없습니다.");
            return true;
        }else if(special_pattern.test(_fileName)){
            setMsg("특수 문자를 사용할수 없습니다.");
            return true;
        }
    }

    const RenameEvent = (e) =>{
        if(value === ''){
            setMsg("입력해주세요.")
            return;
        }

        if(CheckRegular(value)){
            return;
        };

        if(_Modal.data['originalName'] === value){
            CloseModal();
        }

        const PATH = _Modal.data['path'].join("\\");
        const oldNamePath = PATH + '\\' +_Modal.data['originalName'];
        const newNamePath = PATH + '\\' + value;

        //TODO: 성공 여부 처리 수정 필요
        ChangeName(oldNamePath, newNamePath)
            .then((res)=>{
                const resData = res['data'];
                if(resData['result'] !== 'Fail'){
                    window.ReloadImageList();
                }else{
                    setMsg('이미 사용중인 이름입니다.');
                }
            }).catch((err)=>{
                setMsg(err);
            })
    }

    const ChangeEvent = (e) => {
        setMsg('');
        setValue(e.target.value);
    }

    const StopEventBubbling = (e) =>{
        e.stopPropagation();
    }

    return(
        <div>
            <FlexContent>
                <InputContent value={value || ''} onChange={ChangeEvent} onMouseDown={StopEventBubbling} onFocus={(e)=>e.target.select()} />
            </FlexContent>
            <WarningMsg>{msg}</WarningMsg>
            <ModalBottomBox>
                <BtnSecondary label="Cancel" onClick={CloseModal} />
                <BtnPrimary label="Rename" onClick={RenameEvent}/>
            </ModalBottomBox>
        </div>
    )
}

export default ChangeImageModalFileName;

//스타일--------------------------------------------
const FlexContent = styled.div`
    display: flex;
    align-items: baseline;

    &>span{
        margin-left: 8px;
    }
`;

const InputContent = styled.input`
    margin-top: 8px;
    height: auto;
    width: 100%;
    border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    border-radius: 2px;
    padding: 4px 2px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    flex-grow: 1;

    &:focus{
        outline:none;
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
    }
    
    &:disabled{
        padding: 0;
        background-color: transparent;
        border: 0 none;
    }
`;