
const base = {
    border_color_LightGray: '#E6E6E6',
    border_color_Gray: '#b3b3b3',
    border_color_DarkGray: '#888',
    border_color_Blue: '#2196F3',
    border_color_DarkBlue: '#1565C0',
    border_color_Red: '#F44336',
    
    background_color_PaleGray: '#F2F2F2',
    background_color_LightGray: '#E6E6E6',
    background_color_Gray: '#b3b3B3',
    background_color_Black: '#2B2B2B',
    background_color_LightBlue: '#E3F2FD',
    background_color_Blue: '#2196F3',
    background_color_DarkBlue: '#1565C0',
    background_color_Red: '#F44336',
    background_color_LightRed: '#FFCDD2',

    font_color_Black: '#2B2B2B',
    font_color_DarkGray: '#888',
    font_color_Gray: '#B3B3B3',
    font_color_Blue: '#2196F3',
    font_color_DarkBlue: '#1565C0',
    font_color_Red: '#F44336',
    font_color_Orange: '#FF9800',

    success_color: '#64DD17',
};

const AccessColor = {
    Master: '#EF6C00',
    Manage: '#64DD17',
    Edit: '#1565C0',
    Write: '#2B2B2B',
    Read: '#888',
};

const theme = {
    base,
    AccessColor,
};
  
export default theme;