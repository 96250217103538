
import {useState, useEffect} from 'react';

import TabBar from "components/molecule/TabBar";
import EventContent from "babylon/components/organism/EventContent";
import PropertiesContent from "babylon/components/organism/PropertiesContent";

import ResizeDiv from "components/atom/ResizeDiv";
import { useSelector } from 'react-redux';

const MENU = {
    PROPERTIES: 'Properties',
    EVENT: 'Event',
}

export default function RightMenuContent(){
    const selectBabylonData = useSelector(state => state.selectBabylonData);
    const [showTab, setShowTab] = useState();
    const [tabMenuList] = useState([MENU.PROPERTIES, MENU.EVENT]);

    useEffect(()=>{
        setShowTab(MENU.PROPERTIES)
    }, []);

    return(
        <ResizeDiv _pos={'left'} _width={250}>
            <TabBar 
                _selectedMenu={showTab} 
                _MenuList={tabMenuList} 
                onClick={(e)=>setShowTab(e.target.textContent)}
            />

            {showTab === MENU.PROPERTIES && <PropertiesContent selectBabylonData={selectBabylonData}/>}
            {showTab === MENU.EVENT && <EventContent selectBabylonData={selectBabylonData}/>}
        </ResizeDiv>
    )
}