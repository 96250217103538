import { useState, useEffect } from "react";

import {RepositoryRadio} from "components/atom/RadioContent";
import {BtnPrimary, BtnSecondary} from "components/atom/Button"
import Pagination from 'components/atom/Pagination';
import {TEMPLATE_RESOURCE_TYPE} from 'components/common/EnuSpaceEnum';
import { ModalBottomBox } from "components/css/common";

import styled from "styled-components"

export default function GlobalResourceTemplate({enuSpace, SelectedTemplateState}) {
    const [SelectedTemplate, setSelectedTemplate] = SelectedTemplateState;
    const {TargetTemplateInfo, TemplateSubResource} = SelectedTemplate;

    const [CurrentTab, setCurrentTab] = useState(TEMPLATE_RESOURCE_TYPE.SYMBOL);
    const [SelectedResource, setSelectedResource] = useState(''); //선택된 리소스 [Symbol, Image, Picture, Template

    //페이지링 관련 
    const [posts, setPosts] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPosts, setCurrentPosts] = useState();

    useEffect(()=>{
        setPosts(JSON.parse(TemplateSubResource[CurrentTab.toLowerCase()]))
    }, [CurrentTab, TemplateSubResource]);

    const handleItemClick = (sys_id) => {
        setSelectedResource((prevSelected) => (prevSelected === sys_id ? '' : sys_id));
    };

    const GetTemplateSystemId = (itemInfos) => {
        switch(CurrentTab){
            case TEMPLATE_RESOURCE_TYPE.SYMBOL:
                return itemInfos.SYMBOL_SYSTEMID;
            case TEMPLATE_RESOURCE_TYPE.IMAGE:
                return itemInfos.IMAGE_SYSTEMID;
            case TEMPLATE_RESOURCE_TYPE.PICTURE:
                return itemInfos.PICTURE_SYSTEMID;
            default:
                return console.error('Invalid Type');
        }
    }

    const BtnActionHandler = (e) => {
        const Target = currentPosts.filter(item => {
            const SYS_ID = GetTemplateSystemId(item);
            return SYS_ID === SelectedResource;
        });

        const { TITLE } = Target[0];
        const {TEMPLATE_SYSTEMID, VERSION, } = TargetTemplateInfo;
        const SYS_ID = GetTemplateSystemId(Target[0]);

        switch(CurrentTab){
            case TEMPLATE_RESOURCE_TYPE.SYMBOL:
                enuSpace.CreateTemplateSymbol("Canvas", TEMPLATE_SYSTEMID, VERSION.toString(), SYS_ID, TITLE);
                return;
            case TEMPLATE_RESOURCE_TYPE.IMAGE:
                enuSpace.CreateTemplateImage("Canvas", TEMPLATE_SYSTEMID, VERSION.toString(), SYS_ID, TITLE);
                return;
            case TEMPLATE_RESOURCE_TYPE.PICTURE:
                enuSpace.CreateTemplatePicture(TEMPLATE_SYSTEMID, VERSION.toString(), SYS_ID, TITLE);
                return;
            default:
                return console.error('Invalid Type');
        }
    }

    return (
        <GlobalResourceLayout>
            <TemplateHeader>
                <div className="TemplateName">
                    {TargetTemplateInfo?.TITLE} <span>{TargetTemplateInfo?.DATE}</span>
                </div>
                <div className="TemplateDesc" title={TargetTemplateInfo?.DESCRIPTION}>
                    {TargetTemplateInfo?.DESCRIPTION}
                </div>
            </TemplateHeader>

            {/* 탭 */}
            <CustomRepositoryRadio
                isUseKeyLabel={true}
                attributeName='TemplateTeb'
                dataList={TEMPLATE_RESOURCE_TYPE}
                selectedValue={CurrentTab}
                changeEvent={(name, value) => {
                    setCurrentTab(value);
                    setSelectedResource('');
                }}
            />

            <SearchList>
                {currentPosts?.map((item, index) => {
                    const { THUMBNAIL, TITLE} = item;
                    const SYS_ID = GetTemplateSystemId(item);
                    const ClassName = SelectedResource === SYS_ID ? 'selected' : '';
                    const imgSrc = `data:image/png;base64,${THUMBNAIL}`

                    if(!SYS_ID) return null;
                    
                    return(
                        <ResourceCard key={`Template_${SYS_ID}`} title={TITLE} className={ClassName} onClick={() => handleItemClick(SYS_ID)}>
                            <img src={imgSrc} alt="" onError={(e) => { }} />
                            <span>{TITLE}</span>
                        </ResourceCard>
                    )
                })}
            </SearchList>

            <SearchBottomBox justifyType='space-between'>
                <Pagination _dataList={posts} _currentPage={[currentPage, setCurrentPage]} _setCurrentPosts={setCurrentPosts} style={{gap: '0'}}/>
                <div>
                    <BtnSecondary label="Prev" onClick={(e) => setSelectedTemplate('')} />
                    <BtnPrimary
                        style={{width: '70px'}}
                        label={CurrentTab === TEMPLATE_RESOURCE_TYPE.PICTURE ? 'Add' : CurrentTab === TEMPLATE_RESOURCE_TYPE.TEMPLATE ? 'Ok' : 'Draw'}
                        disabled={SelectedResource === ''}
                        onClick={BtnActionHandler}
                    />
                </div>
            </SearchBottomBox>
        </GlobalResourceLayout>
    )
}

const GlobalResourceLayout = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const TemplateHeader = styled.div`
    margin-bottom: 16px;
    font-size: 1rem;
    color: ${({ theme }) => theme.base.font_color_Black};

    .TemplateName{
        font-weight: bold;

        span{
            margin-left: 16px;
            opacity: .6;
            font-size: .9rem;
            font-weight: normal;
        }
    }

    .TemplateDesc{
        width: 100%;
        padding-top: 14px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const SearchList = styled.ul`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 160px);
    border-bottom: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    padding: 8px 0;
    box-sizing: border-box;
    overflow-y: overlay;
    align-content: flex-start;
    gap: 8px;

    li{
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100px;
        height: 126px;
        padding: 8px;
        box-sizing: border-box;
        cursor: pointer;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-radius: 4px;
        gap: 4px;


        img{
            width: 94px;
            height: 80px;
            padding: 0 4px;
            object-fit: contain;
        }

        span{
            font-size: .9rem;
            text-align: center;
            color: ${({theme}) => theme.base.font_color_Black};
            width: 100px;
            line-height: 1.4;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &.selected, &:hover{
            border-color: ${({ theme }) => theme.base.border_color_Blue};
        }
    }
`;

const SearchBottomBox = styled(ModalBottomBox)`
    &>div{
        margin-top: auto;
    }
`;

const ResourceCard = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100px;
    height: 126px;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 4px;
    gap: 4px;

    img{
        width: 94px;
        height: 80px;
        padding: 0 4px;
        object-fit: contain;
    }

    span{
        font-size: .9rem;
        text-align: center;
        color: ${({theme}) => theme.base.font_color_Black};
        width: 100px;
        line-height: 1.4;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.selected, &:hover{
        border-color: ${({ theme }) => theme.base.border_color_Blue};
    }
`;

const CustomRepositoryRadio = styled(RepositoryRadio)`
    height: 36px;
    /* margin-bottom: 16px; */
    border-radius: 4px;

    &>div{
        input + label{
            border-radius: 2px;
        }
    }
`;