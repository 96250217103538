import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import RuntimeModeContent from "components/template/RuntimeModeContent";
import useEnuSpaceModule from 'hooks/useEnuSpaceModule';
import useProjectData from "hooks/useProjectData";
import useWindowFun from 'hooks/useWindowFun';

import { setBaseData, InitProjectData } from 'reducers/projectDataReducer'
import { AllClearModalPopup } from 'reducers/modulePopupReducer';

function RuntimeModePage({userId, userNickname, projectId, projectName}) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [enuSpace] = useEnuSpaceModule();
    const {DeleteNodeData} = useProjectData();
    
    const BASE_DATA = {
        userId,
        userNickname,
        projectId,
        projectName,
        currentURL: '/Publish/runtimeMode',
    };

    useEffect(()=>{
        DeleteNodeData();
    }, [DeleteNodeData]);

    dispatch(setBaseData(BASE_DATA));

    // 프로젝트 정보를 받아서 Explorer 창 업데이트 및 첫 픽처 페이지 오픈
    window.Re_UpdateProjectCtrl = (_msg) => {
        const resData = JSON.parse(_msg);
        if (resData['type'] === 'success') {
    
        } else if (resData['type'] === 'failed') {
            console.log('프로젝트 로드 실패');
        }
    }

    useEffect(() => {
        dispatch(InitProjectData());

        return () => {
            dispatch(AllClearModalPopup());
        };
    }, [dispatch]);

    //이전 페이지로 이동
    const closeRunTime = () => navigate(-1);

    const {SetGlobalValues, RefreshAccessToken, RuntimeModeShowMarkdown, GetNodeData, ShowDocumentModal, cursorType} = useWindowFun(enuSpace);
    window.Re_SetGlobalValues = SetGlobalValues;
    window.Re_RefreshAccessToken = RefreshAccessToken;
    window.Re_ShowMarkdown =  RuntimeModeShowMarkdown;
    window.Re_GetNodeData = GetNodeData;
    window.Re_ShowDocument = ShowDocumentModal;

    return (
        <RuntimeModeContent enuSpace={enuSpace} _BASE_DATA={BASE_DATA} _closeEvent={closeRunTime} style={{cursor: cursorType}} />
    )
}

export default RuntimeModePage;