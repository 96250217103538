import {useState, useRef, useEffect } from "react";

import MouseRightClickMenuContent from "components/molecule/MouseRightClickMenuContent";
import {SetTimeFormat} from 'components/Utility';
import useRightMenu from "hooks/useRightMenu";

import { MdClear, MdOutlineFileDownload } from "react-icons/md";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSelector, shallowEqual } from 'react-redux';

function LogWindow({enuSpace, _visibility}){
    const LogWindowBox = useRef();
    const listRef = useRef();
    const [isReSize,   setIsReSize] = useState(false);
    const [initialPos,   setInitialPos] = useState('');
    const [initialSize, setInitialSize] = useState('');
    const [logData, setLogData] = useState('');

    const RightMenuInfo = useSelector(state => {
        if (state.rightMenu.type === 'Log')
            return state.rightMenu;
        return null;
    }, shallowEqual);
    const {ShowRightMenu, CloseRightMenu} = useRightMenu();

    useEffect(() => {
        if(_visibility)
            listRef?.current?.scrollToItem(logData.length);
        else
            LogWindowBox.current.removeAttribute('style');
    },[_visibility, logData.length]);

    useEffect(() => {
        if(logData?.length > 0)
            listRef?.current?.scrollToItem(logData.length);
    }, [logData]);

    window.Re_AddLog = (_logMsg) => {
        const {type:logType, data: Data, time: logTime} = JSON.parse(_logMsg);
        const [settingType, settingData, settingTime] = [logType || '',  Data || '', logTime || SetTimeFormat()];

        setLogData((prev) => [...prev, {type:settingType, data:settingData.replace(/\n/g, ' '), time:settingTime}]);
    };

    const MouseRightClick = (e) =>{
        e.preventDefault();
        ShowRightMenu({'type':'Log', 'top':e.pageY, 'left':e.pageX});
    }

    const DeleteLog = (e) =>{
        e.preventDefault();
        CloseRightMenu();
        setLogData('');
    }

    const initial = (e) => {
        setInitialPos(e.clientY);
        setInitialSize(LogWindowBox.current.offsetHeight);
        setIsReSize(true);
        LogWindowBox.current.style.userSelect = 'none';
    }

    const ChangeHeight = (e) =>{
        LogWindowBox.current.style.height = `${parseInt(initialSize) - parseInt(e.clientY - initialPos)}px`;
    }
    
    const resize = (e) => {
        if(isReSize){
            ChangeHeight(e);
            enuSpace.SetResize();
        }
    }

    const onDragEnd = (e) => {
        ChangeHeight(e);
        enuSpace.SetResize();
        setIsReSize(false);
        LogWindowBox.current.style.userSelect = 'auto';
    }

    const LogRow = ({ data, index, style }) =>{
        const log = data[index];
        return(
            <div style={{ ...style }} className={`${log.type}`}>
                <div className="logType" title={log.type}>{log.type}</div>
                <div className="logInfo" title={log.data}>{log.data}</div>
                <div className="logTime" title={log.time}>{log.time}</div>
            </div>
        )
    }
    
    return(
        <LogWindowOuter ref={LogWindowBox} _visibility={_visibility} onContextMenu={MouseRightClick}>
            <ResizeBar style={{height: '10px'}} draggable='true' onDragStart={initial} onDrag={resize} onDragEnd={onDragEnd}/>

            <LogWindowContent>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            className="logRow"
                            ref={listRef}
                            height={height}
                            itemCount={logData.length}
                            itemSize={32}
                            width={width}
                            itemData={logData}
                        >
                            {LogRow}
                        </List>
                    )}
                </AutoSizer>
            </LogWindowContent>

            {( RightMenuInfo?.type === 'Log') &&
                <MouseRightClickMenuContent _positionTop={RightMenuInfo['top']} _positionLeft={RightMenuInfo['left']}>
                    <ul>
                        <li>
                            <CSVLink
                                  data={logData || []}
                                  headers={[
                                    { label: "type", key: "type" },
                                    { label: "log", key: "data" },
                                    { label: "time", key: "time" }
                                  ]}
                                  filename="log.csv"
                                  target="_blank"
                            > 
                                <span><MdOutlineFileDownload style={{color:'royalblue' }} /> Download CSV</span>
                            </CSVLink>
                        </li>
                        <li onClick={DeleteLog}><span><MdClear style={{'color':'mediumvioletred'}}/>Delete Log</span></li>
                    </ul>
                </MouseRightClickMenuContent>
            }
        </LogWindowOuter>
    )
}

export default LogWindow;

// 스타일------------------------------------
const LogWindowOuter = styled.div`
    position: relative;
    height: ${(props) => props._visibility ? '170px' : '0'};
    visibility: ${(props) => props._visibility ? 'visible' : 'hidden'};
    min-height: ${(props) => props._visibility ? '170px' : '0'};
    max-height: 700px;
    background-color: #FFF;
    border-top: 1px solid ${({theme}) => theme.base.border_color_Gray};
    box-sizing: border-box;
`;

const ResizeBar = styled.div`
    position: absolute;
    width: 100%;
    z-index: 8;
    cursor: n-resize;
    top: -5px;
    left: 0;
`;

const LogWindowContent = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 32px;

    .logRow > div > div{
        color:${({theme}) => theme.base.font_color_Black};
        font-size: .8rem;
        padding: 2px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};
        line-height: normal;
        box-sizing: border-box;

        &.warning{
            color: ${({theme}) => theme.base.font_color_Orange};
        }
        
        &.error{
            color: ${({theme}) => theme.base.font_color_Red};
        }

        &.info{
            color: ${({theme}) => theme.base.success_color};
            word-break: break-word;
        }

        &>div{
            padding: 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &>.logType{
            width: 70px;
        }

        &>.logInfo{
            flex-grow: 1;
            width: 100px;
            padding: 4px 0 4px 16px;
            border-left: 1px solid ${({theme}) => theme.base.border_color_LightGray};
            border-right: 1px solid ${({theme}) => theme.base.border_color_LightGray};
            white-space: pre-wrap;
        }

        &>.logTime{
            color:${({theme}) => theme.base.font_color_Gray};
            font-size:.75rem;
            width: 130px;
            box-sizing: border-box;
            text-align: end;
        }
    }
`;