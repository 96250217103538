import Base from "babylon/class/Base";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";

export default class Custom extends Base {
    constructor(EnuScene, object, asset_root) {

        super(EnuScene, object);

        this.objectType = SUPPORT_OBJECT_TYPE.CUSTOM;
        this.parentId = asset_root.uniqueId;
    }

    setParentNode(scene, parentId) {
        this.parent = scene.getMeshByKey(parentId);
    }

    getPropertyMap() {
        const basePropertyMap = super.getPropertyMap();
        return {
            ...basePropertyMap,
            objectType: this.objectType,
            parentId: this.parentId,
        };
    }
}
