import { useState, useCallback, useEffect } from 'react';

import {BtnSecondary, BtnGhost, BtnSubDanger} from "components/atom/Button";
import {DatabaseTag, UploadCSV} from 'components/modal_new/DatabaseModals'
import Pagination from 'components/atom/Pagination';
import { TableContent, ModalBottomBox,SubTitle} from 'components/css/common';
import useModalPopup from "hooks/useModalPopup"
import {SkeletonTable} from 'components/Utility';
import { GetTagList, AddTag, DeleteTag, GetTagHistory} from 'api/DeviceApi'
import moment from 'moment';
import 'moment-timezone';

import styled from "styled-components"
import { MdAdd, MdClear, MdModeEdit, MdOutlineFileDownload, MdOutlineFileUpload, MdTableChart, MdInsertChartOutlined} from "react-icons/md";
import { CSVLink } from "react-csv";

export default function DBTagPageTagList({userId, deviceInfo, lever, height}) {
    const { DB_TABLE_NAME, TABLE_ID } = deviceInfo;
    const [isShowModal, setIsShowModal] = useState({visibility: false});
    const {AddModal} = useModalPopup();
    const supportCSVHeaders = [
        { label: "DB Name", key: "DB_TABLE_NAME" },
        { label: "IOType", key: "IO_TYPE" },
        { label: "Tag", key: "TAG" },
        { label: "Type", key: "DATA_TYPE" },
        { label: "Value", key: "VALUE" },
        { label: "Description", key: "DESCRIPTION" },
    ]

    //페이지링 관련 
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPosts, setCurrentPosts] = useState();

    const [isLoadingPage, setIsLoadingPage] = useState(false);

    const GetTagListEvent = useCallback(()=>{
        setIsLoadingPage(true);

        GetTagList(userId, TABLE_ID)
            .then((res) => {
                const { DATA, RESULT, MSG } = res;
                if(RESULT === 'Ok'){
                    setPosts(DATA);
                    setCurrentPage(1);
                }else{
                    window.AddAlertMsg({type:'error', msg:MSG});
                }
            })
            .catch((err) => {
                window.AddAlertMsg({type:'error', msg:err});
            }).finally(()=>{
                setIsLoadingPage(false);
            });
    }, [userId, TABLE_ID])

    useEffect(()=>{
        GetTagListEvent();
    }, [GetTagListEvent]);

    const addShowModal = async (e, items, type) => {
        if(items.length === 0){
            window.AddAlertMsg({type:'error', msg:'태그를 선택해주세요'});
            return;
        }
        e.stopPropagation();
        const data = [];
        var Id = "";
        if (type === 'chart') {
            items.forEach((item) => {
                const [ DB_TABLE_ID, , , TAG,  ] = item.split('\\')
                Id = Id + TAG;
    
                var result = {};
                result.MEMBER_SYSTEMID = userId;
                result.DB_TABLE_ID = DB_TABLE_ID;
                result.TAG = TAG;
                result.START_TIME = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                result.TIME = '5m';
                result.DURATION = '1000';
                result.TYPE = 'DYNAMIC';
                data.push(result);
            })
    
            AddModal(Id + "chart",`Chart`,"Chart", data);
        } else if (type === 'table') {
            const promises = items.map(async (item) => {
                const [DB_TABLE_ID, , , TAG] = item.split('\\');
                Id = Id + TAG;
    
                const START_TIME = moment(new Date()).subtract(5, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                const END_TIME = moment(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                const res = await GetTagHistory(userId, DB_TABLE_ID, TAG, START_TIME, END_TIME, '1000', false);
                return {
                    MEMBER_SYSTEMID: userId,
                    DATA: res.data.DATA,
                    TYPE: 'func'
                };
            });
    
            const results = await Promise.all(promises);
            data.push(...results);
            AddModal(Id + "table",`Table`,"Table", data);
        }
    }

    const ReplaceEvent = () =>{
        const tagList = posts.map(item => item.TAG);
        DeleteTag(userId, TABLE_ID, tagList)
            .then(res => {
            })
    }

    const AddTagEvent = (result) => {
        AddTag(userId, TABLE_ID, result)
            .then(res => {
                if (res['RESULT'] === "Ok") {
                    GetTagListEvent();
                }
            })
    }

    return(
        <TagListContent height={height}>
            <TableHeader marginTop={'8px'}>
                <SubTitle>
                    {`TagList_ ${posts ? posts.length : 0}개`}
                </SubTitle>
                <div className="BtnListBox">
                    {['Edit', 'Manage', 'Master'].includes(lever) &&
                        <BtnSecondary
                            label={<MdAdd style={{ fontSize: '1.5rem', color: 'royalblue' }} />}
                            onClick={(e) => setIsShowModal({ mode: 'create' })}
                        />
                    }

                    <CSVLink
                        data={posts || ''}
                        headers={supportCSVHeaders}
                        filename={`${DB_TABLE_NAME || 'table'}.csv`}
                        target="_blank"
                    >
                        <BtnSecondary label={<MdOutlineFileDownload style={{ fontSize: '1.5rem', color: 'royalblue' }} />} />
                    </CSVLink>

                    {['Edit', 'Manage', 'Master'].includes(lever) &&
                        <BtnSecondary
                            label={<MdOutlineFileUpload style={{ fontSize: '1.5rem', color: 'royalblue' }} />}
                            onClick={(e) => setIsShowModal({ mode: 'upload' })}
                        />
                    }
                </div>
            </TableHeader>
            <DeviceInfoTable className="InformationTable" >
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className='IOType'>IOType</th>
                                <th className='tagName'>Tag</th>
                                <th className='type'>Type</th>
                                <th className='value'>Value</th>
                                <th className='description'>Description</th>
                                <th className='chart'>chart</th>
                                <th className='table'>table</th>
                                {['Edit', 'Manage', 'Master'].includes(lever) &&
                                    <>
                                        <th className='Btn'>Edit</th>
                                        <th className='Btn'>Del</th>
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {(!isLoadingPage && currentPosts) && currentPosts.map((item, index) => {
                                const { Tag, IO_TYPE, TAG, DATA_TYPE, VALUE, DESCRIPTION } = item;
                                const tag_item = [
                                    TABLE_ID + '\\' +
                                    DB_TABLE_NAME + '\\' +
                                    DATA_TYPE + '\\' +
                                    TAG + '\\' +
                                    DESCRIPTION + '\\'
                                ];
                                return (
                                    <tr key={`${Tag}_${index}`}>
                                        <td className='IOType'>{IO_TYPE}</td>
                                        <td className='tagName'>{TAG}</td>
                                        <td className='type'>{DATA_TYPE}</td>
                                        <td className='value'>{VALUE}</td>
                                        <td className='description'>{DESCRIPTION}</td>
                                        <td className='chart' title="차트 보기"><MdInsertChartOutlined onClick={(e) => addShowModal(e, tag_item, 'chart')} /></td>
                                        <td className='table' title="테이블 보기"><MdTableChart onClick={(e) => addShowModal(e, tag_item, 'table')} /></td>
                                        {['Edit', 'Manage', 'Master'].includes(lever) &&
                                            <>
                                                <td className='Btn'>
                                                    <BtnGhost
                                                        label={<MdModeEdit />}
                                                        type='sm'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setIsShowModal({ mode: "edit", tagInfo: { OriginTag: item.TAG, ...item } });
                                                        }}
                                                    />
                                                </td>
                                                <td className='Btn'>
                                                    <BtnSubDanger
                                                        label={<MdClear />}
                                                        type='sm'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (!window.confirm(`"${TAG}" 을 삭제하시겠습니까?`)) return;

                                                            DeleteTag(userId, TABLE_ID, [TAG])
                                                                .then(res => {
                                                                    const { RESULT, MSG } = res;
                                                                    RESULT === 'Ok' ? GetTagListEvent() : window.AddAlertMsg({ type: 'error', msg: MSG });
                                                                })
                                                        }}
                                                    />
                                                </td>
                                            </>
                                        }
                                    </tr>
                                )
                            })}

                            {isLoadingPage && <SkeletonTable col={9} row={30} />}
                        </tbody>
                    </table>
                </div>
            </DeviceInfoTable>
            <Pagination _dataList={posts} _currentPage={[currentPage, setCurrentPage]} _setCurrentPosts={setCurrentPosts} />
            {(isShowModal?.mode === 'create' || isShowModal?.mode === 'edit') &&
                <DatabaseTag mode={isShowModal?.mode} tagInfo={isShowModal?.tagInfo} userId={userId} tableId={TABLE_ID} addTagEvent={AddTagEvent} closeEvent={(e) => setIsShowModal()} />
            }

            {isShowModal?.mode === 'upload' &&
                <UploadCSV addTagEvent={AddTagEvent} replaceEvent={ReplaceEvent} closeEvent={(e) => setIsShowModal()} />
            }
        </TagListContent>
    )
}

const TagListContent = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    margin-top: 16px;
    height: ${({height}) => height ? height : '100%'};
`;

const TableHeader = styled(ModalBottomBox)`
    justify-content: space-between;
    
    &>.BtnListBox{
        display: flex;
        gap: 8px;

        .tableType{
            width: 100px;
        }
    }
`;

const DeviceInfoTable = styled(TableContent)`
    background: #FFF;
    z-index:1;
    &.InformationTable{
        height: calc(100% - 110px);
        overflow: auto;
    }

    .checkbox{
        width: 38px;
        text-align: center;
    }

    .Access, .IOType{
        width: 70px;
        text-align: center;
    }

    .No{
        width: 50px;
    }

    .DBType, .ACQType, .type{
        width: 100px;
        text-align: center;
    }

    .Ip{
        width: 90px;
        text-align: center;
    }

    .Interval{
        width: 70px;
        text-align: center;
    }

    .Btn{
        width: 48px;

        &>button{
            padding: 2px 6px;

            svg{
                font-size: 1rem;
            }
        }
    }

    .chart{
        width:60px;
        text-align:center;
    }

    .chart * {
        font-size:1.5rem;
        
    }

    .table{
        width:60px;
        text-align:center;
    }

    .table * {
        font-size:1.5rem;
        
    }
    
    tbody > tr > .chart, .table > * {
        color: ${({ theme }) => theme.base.font_color_Blue};
        cursor:pointer;
    }
`;