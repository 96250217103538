import { useRef, useEffect, useCallback } from 'react';

function ShowWebpage({enuSpace, _Modal}){
    const iframeRef = useRef(null);
    const sendMessage = useCallback(() => {
      if (iframeRef.current) {
          iframeRef.current.contentWindow.postMessage(_Modal?.data?.data, window.location.origin);
      }
  }, [_Modal?.data?.data]);

  useEffect(() => {
      sendMessage();
  }, [sendMessage]);

    return(
        <iframe ref={iframeRef} src={_Modal?.data?.url} title={_Modal?.data?.url} style={{width:'100%', height:"100%"}} onLoad={sendMessage}></iframe>
    )
}

export default ShowWebpage;