import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

import { BtnSecondary} from "components/atom/Button";
import TrendInput from "components/atom/TrendInput";
import SelectList from "components/atom/SelectList";
import ModalColorPickerContent from "components/atom/ModalColorPickerContent";
import FontFamily from 'components/common/FontFamily';
import { TableContent, ChartTitle } from 'components/css/common';
import Checkbox from "components/atom/Checkbox";
import useProjectData from 'hooks/useProjectData'

import styled from "styled-components";
import {MdAdd, MdClear} from "react-icons/md";

function TrendAddData({enuSpace, _Modal}){
    const {
        id: ModalId,
        series_list: ModalSeriesList,
    } = _Modal.data;
    const {ChangeNodeData} = useProjectData();
    const ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ACTIVE_PICTURE_URL);  //활성화 된 picture URL

    const [fontClass] = useState(new FontFamily());
    const [SeriesList, setSeriesList] = useState([]);
    const [SeriesData, setSeriesData] = useState();
    const [SelectSeries, setSelectSeries] = useState();

    useEffect(()=>{
        setSeriesList(ModalSeriesList !== '' ? ModalSeriesList.split(';') : []);
    }, [ModalSeriesList]);

    window.Re_GetNodeData_TrendSeries = (_nodeData) =>{
        if(_nodeData.type === 'success'){
            const _seriesList = _nodeData.data.series_list.split(';');
            setSeriesList(_seriesList);
        }else{
            window.AddAlertMsg({type:'error', msg:'Re_GetNodeData_TrendSeries Error'});
        }
    }
    
    window.Re_GetNodeData_SeriesData = (_nodeData) =>{
        if(_nodeData.type === 'success'){
            setSeriesData(_nodeData.data);
        }else{
            window.AddAlertMsg({type:'error', msg:'Re_GetNodeData_SeriesData Error'});
        }
    }

    const ChangeEvent = (JsonData) => {
        for(let index in JsonData)
            JsonData[index].id = `${ModalId.split('.')[0]}.${SelectSeries}`;

        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"SETNODEDATA", JSON.stringify(JsonData), "");
        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"GETNODEDATA_BY_ID", JSON.stringify({id: `${ModalId.split('.')[0]}.${SelectSeries}`}), "SeriesData");
    }

    const DispatchUpdateEvent = (JsonData) =>{
        for(let item of JsonData){
            const {attributeName, attributeValue} = item;
            ChangeNodeData(attributeName?.replaceAll("-", "_") + ':' + attributeValue);
        }
    }

    const ChangeSelect = (attributeName, attributeValue) =>{
        const JsonData = [ { attributeName, attributeValue } ]
        ChangeEvent(JsonData);
        DispatchUpdateEvent(JsonData);
    }

    const ToggleLineWrapping = (e) =>{
        const JsonData = [{
                'id': `${ModalId.split('.')[0]}.${SelectSeries}`,
                'attributeName' : e.target.name,
                'attributeValue' : e.target.checked ? 'true' : 'false',
            }]
        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"SETNODEDATA", JSON.stringify(JsonData), "");
        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"GETNODEDATA_BY_ID", JSON.stringify({id: `${ModalId.split('.')[0]}.${SelectSeries}`}), "SeriesData");
    }

    const commonProps = {
        _ChangeEvent: ChangeEvent,
    };

    return(
        <TrendComponent>
            <div>
                <Title className='title'>Series</Title>
                <Table>
                    <div>
                        <table className="SeriesTable">
                            <thead>
                                <tr>
                                    <th>Series ID</th>
                                    <th className="Btn">
                                        <BtnSecondary 
                                            type='sm' 
                                            title='Add' 
                                            label={<MdAdd style={{fontSize:'1.2rem', color:'royalblue'}}/>} 
                                            onClick={(e)=>{
                                                const jsonData = [{
                                                    id: ModalId.split('.')[0],
                                                    type: "series",
                                                }];
                                                enuSpace.EventHandler(ACTIVE_PICTURE_URL,"CREATE_OBJECT", JSON.stringify(jsonData), "TrendSeries");
                                            }}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {SeriesList.map((item) => {
                                    const jsonData = { id: `${ModalId.split('.')[0]}.${item}`, };
                                    return (
                                        <tr
                                            key={item}
                                            className={SelectSeries === item ? 'active' : ''}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectSeries(item);
                                                enuSpace.EventHandler(ACTIVE_PICTURE_URL, "GETNODEDATA_BY_ID", JSON.stringify(jsonData), "SeriesData");
                                            }}
                                        >
                                            <td>{item}</td>
                                            <td className="Btn BtnDelete">
                                                <BtnSecondary
                                                    type='sm'
                                                    title='Delete'
                                                    label={<MdClear style={{ fontSize: '1.2rem' }} />}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        enuSpace.EventHandler(ACTIVE_PICTURE_URL, "DELETE_OBJECT", JSON.stringify([jsonData]), "TrendSeries");
                                                        //FIXME: WASM 응답 여부와 상관없이 리스트에서 제외, 삭제가 실패해도 리스트에서 제외
                                                        setSeriesList(SeriesList.filter((listInfo) => listInfo !== item));
                                                        setSeriesData();
                                                        setSelectSeries();
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Table>
            </div>
            <div>
                <Title className='title'>Series Properties</Title>
                <Table>
                    <div>
                        <table className="PropertiesTable">
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                            {SeriesData &&
                                <tbody>
                                    <tr title="name">
                                        <td>name</td>
                                        <td><TrendInput _id="name" _value={SeriesData.name} {...commonProps}/></td>
                                    </tr>
                                    {SeriesData.variable &&
                                        <tr title="variable">
                                            <td>variable</td>
                                            <td><TrendInput _id="variable" _value={SeriesData.variable} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.data &&
                                        <tr title="data">
                                            <td>data</td>
                                            <td><TrendInput _id="data" _value={SeriesData.data} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.x_data &&
                                        <tr title="x_data">
                                            <td>x-data</td>
                                            <td><TrendInput _id="x-data" _value={SeriesData.x_data} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.x_data_type &&
                                        <tr title="x_data_type">
                                            <td>x-data-type</td>
                                            <td>
                                                <SelectList
                                                    attributeName="x-data-type"
                                                    dataList={["number","timeseries"]}
                                                    selectedValue={SeriesData.x_data_type}
                                                    changeEvent={ChangeSelect}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    <tr title="fill">
                                        <td>fill</td>
                                        <td><ModalColorPickerContent _colorId="fill" _color={{ color: SeriesData.fill}} {...commonProps}/></td>
                                    </tr>
                                    <tr title="fill_opacity">
                                        <td>fill-opacity</td>
                                        <td><TrendInput _id="fill-opacity" _valueType="number" _value={SeriesData.fill_opacity} {...commonProps} /></td>
                                    </tr>
                                    <tr title="stroke">
                                        <td>stroke</td>
                                        <td><ModalColorPickerContent _colorId="stroke" _color={{ color: SeriesData.stroke, opacity: SeriesData.stroke_opacity }} {...commonProps}/></td>
                                    </tr>
                                    <tr title="stroke_opacity">
                                        <td>stroke-opacity</td>
                                        <td><TrendInput _id="stroke-opacity" _valueType="number" _value={SeriesData.stroke_opacity} {...commonProps} /></td>
                                    </tr>
                                    <tr title="stroke_width">
                                        <td>stroke-width</td>
                                        <td><TrendInput _id="stroke-width" _valueType="number" min="0" _value={SeriesData.stroke_width} {...commonProps} /></td>
                                    </tr>

                                    {SeriesData.y_label &&
                                        <tr title="y_label">
                                            <td>y-label</td>
                                            <td><TrendInput _id="y-label" _value={SeriesData.y_label} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.label_format &&
                                        <tr title="label_format">
                                            <td>label-format</td>
                                            <td><TrendInput _id="label-format" _value={SeriesData.label_format} {...commonProps} /></td>
                                        </tr>

                                    }
                                    {SeriesData.label_font_family &&
                                        <tr title="label_font_family">
                                            <td>label-font-family</td>
                                            <td>
                                                <SelectList
                                                    attributeName="label-font-family"
                                                    dataList={fontClass.GetFontFamilyList()}
                                                    selectedValue={SeriesData.label_font_family}
                                                    changeEvent={ChangeSelect}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    {SeriesData.label_font_color &&
                                        <tr title="label_font_color">
                                            <td>label-font-color</td>
                                            <td><ModalColorPickerContent _colorId="label-font-color" _color={{ color: SeriesData.label_font_color }} {...commonProps} _isGradient={true} _isOpacity={false} /></td>
                                        </tr>
                                    }
                                    {SeriesData.label_font_size &&
                                        <tr title="label_font_size">
                                            <td>label-font-size</td>
                                            <td><TrendInput _id="label-font-size" _value={SeriesData.label_font_size} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.min_label_visible &&
                                        <tr title="min_label_visible">
                                            <td>min-label-visible</td>
                                            <td><Checkbox _id='min-label-visible' checked={SeriesData.min_label_visible === '1'} onChange={ToggleLineWrapping}>{SeriesData.min_label_visible === '1' ? 'on' : 'off'}</Checkbox></td>
                                        </tr>
                                    }
                                    {SeriesData.max_label_visible &&
                                        <tr title="max_label_visible">
                                            <td>max-label-visible</td>
                                            <td><Checkbox _id='max-label-visible' checked={SeriesData.max_label_visible === '1'} onChange={ToggleLineWrapping}>{SeriesData.max_label_visible === '1' ? 'on' : 'off'}</Checkbox></td>
                                        </tr>
                                    }
                                    {SeriesData.max_size &&
                                        <tr title="max_size">
                                            <td>max-size</td>
                                            <td><TrendInput _id="max-size" _value={SeriesData.max_size} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.values &&
                                        <tr title="values">
                                            <td>values</td>
                                            <td><TrendInput _id="values" _value={SeriesData.values} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.symbol_width &&
                                        <tr title="symbol_width">
                                            <td>symbol-width</td>
                                            <td><TrendInput _id="symbol-width" _value={SeriesData.symbol_width} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.symbol_height &&
                                        <tr title="symbol_height">
                                            <td>symbol-height</td>
                                            <td><TrendInput _id="symbol-height" _value={SeriesData.symbol_height} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.symbol_type &&
                                        <tr title="symbol_type">
                                            <td>symbol-type</td>
                                            <td>
                                                <SelectList
                                                    attributeName="symbol-type"
                                                    dataList={["none", "circle", "rect", "triangle"]}
                                                    selectedValue={SeriesData.symbol_type}
                                                    changeEvent={ChangeSelect}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    {(SeriesData.symbol_fill && SeriesData.symbol_fill_opacity) &&
                                        <tr title="symbol_fill">
                                            <td>symbol-fill</td>
                                            <td><ModalColorPickerContent _colorId="symbol-fill" _color={{ color: SeriesData.symbol_fill, opacity: SeriesData.symbol_fill_opacity }} {...commonProps} _isGradient={true} _isOpacity={true} /></td>
                                        </tr>
                                    }
                                    {SeriesData.symbol_fill_opacity &&
                                        <tr title="symbol_fill_opacity">
                                            <td>symbol-fill-opacity</td>
                                            <td><TrendInput _id="symbol-fill-opacity" _value={SeriesData.symbol_fill_opacity} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {(SeriesData.symbol_stroke && SeriesData.symbol_stroke_opacity) &&
                                        <tr title="symbol_stroke">
                                            <td>symbol-stroke</td>
                                            <td><ModalColorPickerContent _colorId="symbol-stroke" _color={{ color: SeriesData.symbol_stroke, opacity: SeriesData.symbol_stroke_opacity }} {...commonProps} _isGradient={true} _isOpacity={true} /></td>
                                        </tr>
                                    }
                                    {SeriesData.symbol_stroke_opacity &&
                                        <tr title="symbol_stroke_opacity">
                                            <td>symbol-stroke-opacity</td>
                                            <td><TrendInput _id="symbol-stroke-opacity" _value={SeriesData.symbol_stroke_opacity} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.symbol_stroke_width &&
                                        <tr title="symbol_stroke_width">
                                            <td>symbol-stroke-width</td>
                                            <td><TrendInput _id="symbol-stroke-width" _value={SeriesData.symbol_stroke_width} {...commonProps} /></td>
                                        </tr>
                                    }
                                    {SeriesData.symbol_step &&
                                        <tr title="symbol_step">
                                            <td>symbol-step</td>
                                            <td><TrendInput _id="symbol-step" _value={SeriesData.symbol_step} {...commonProps} /></td>
                                        </tr>
                                    }
                                </tbody>
                            }
                        </table>
                    </div>
                </Table>
            </div>
        </TrendComponent>
    )
}

export default TrendAddData;

//스타일----------------------------------------
const TrendComponent = styled.div`
    display: flex;
    flex-direction: row;
    height: calc(100% - 8px);

    &>div{
        position: relative;
        width: 55%;
        box-sizing: border-box;
        
        &:first-child{
            width: 45%;
            padding-right: 8px;
        }
    }
`;

const Table = styled(TableContent)`
    height: calc(100% - 28px);

    .SeriesTable{
        tr:hover .BtnDelete > button{
            color: ${({theme}) => theme.base.font_color_Red};
            border-color: ${({theme}) => theme.base.font_color_Red};

            &:hover{
                color: #fff;
                background-color: ${({theme}) => theme.base.font_color_Red};
            }
        }
        th, td{
            border: 0 none;
            border-bottom: 1px solid ${({theme}) => theme.base.border_color_LightGray};
        }
        .BtnDelete > button{
            display: flex;
            color: ${({theme}) => theme.base.font_color_Gray};
            background-color: transparent;
            border-color: transparent;
        }
    }

    .PropertiesTable{
        tr{
            cursor: default;
        }
    }

    .active{
        background-color: ${({ theme }) => theme.base.background_color_LightBlue};
        position: relative;
        
        td:first-child{
            &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: ${({ theme }) => theme.base.background_color_Blue};
            }
        }
    }

    .Btn{
        padding: 0;
        width: 38px;

        &>button{height: 31px;}
    }

    tbody td >div{
        height: 100%;
        padding: 0;
        margin: 0 !important;
        border-radius: 0;
    }
`;

const Title = styled(ChartTitle)`
    margin-top: 0;
`;