import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import Checkbox from "components/atom/Checkbox";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import { GroupName } from 'components/css/common';

import styled from "styled-components";

export default function TrendBaseLineInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        baseline_visible: NodeBaselineVisible,
        baseline_value: NodeBaselineValue,
        baseline_stroke_width: NodeBaselineStrokeWidth,
        baseline_tick_visible: NodeBaselineTickVisible,
    } = _data;

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return (
        <PropertiesContent _Title="baseline" 
            _Toggle={
                <BtnToggle _toggleID="baseline-visible" _isToggleValue={NodeBaselineVisible} _disabled={_disabled} _IsToggle={(attributeName, attributeValue) =>_ChangeEvent([{attributeName, attributeValue }])}/>
            }
            _ToggleValue={!['0', 'false', undefined].includes(NodeBaselineVisible)} 
        >
            <div>
                <CustomInputComponent label='value' attributeName='baseline-value' valueType='number' labelWidth='60px' value={NodeBaselineValue} {...commonProps}/>
            </div>
            <div>
                <ColorComponent label="stroke" attributeName='baseline-stroke' isGradient={false} isVisibility={false} {...commonProps}/>
            </div>
            <div>
                <CustomGroupName >stroke-width</CustomGroupName>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='baseline-stroke-width' 
                    valueType='number' 
                    labelWidth='24px' 
                    value={NodeBaselineStrokeWidth} 
                    {...commonProps} 
                />
            </div>
            <div style={{'justifyContent': 'space-between'}}>
                <Checkbox _id='baseline-tick-visible' checked={NodeBaselineTickVisible === '1'? true : false} onChange={(e)=> _ChangeEvent([{attributeName:'baseline-tick-visible', attributeValue: e.target.checked }])}>
                    baseline-tick-visible
                </Checkbox>
            </div>
        </PropertiesContent>
    );
}

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;

const CustomGroupName = styled(GroupName)`
    width: 100%;
    max-width: 208px;
    min-width: 128px;
`;