
import { useState ,useReducer, useRef, useEffect} from "react";

import {BtnPrimary, BtnSecondary, BtnGhost} from "components/atom/Button";
import { RadioComponent} from "components/atom/RadioContent";
import {InputContent, TextareaContent, GroupName, ModalBottomBox, WarningMsg, SubTitle} from "components/css/common";
import {ACCESS_LEVEL} from "components/common/EnuSpaceEnum";
import {CheckUser} from "hooks/useMember";
import useModalPopup from "hooks/useModalPopup"
import {GetDescription} from "api/Project";
import {MakeTemplate, GetTemplateVerInfo} from "api/TemplateApi";

import { useSelector } from 'react-redux';
import styled from "styled-components";
import {MdClear, MdStar} from "react-icons/md";

const ActionTypes = {
    CHANGE_MODE: 'ChangeMode',
    CHANGE_TITLE: 'ChangeTitle',
    CHANGE_DESCRIPTION: 'ChangeDescription',
    ADD_CATEGORY: 'AddCategory',
    DELETE_CATEGORY: 'DeleteCategory',
    ADD_SHARED_USERS: 'AddSharedUsers',
    REMOVE_SHARED_USERS: 'RemoveSharedUsers',
    CHANGE_TYPE: 'ChangeType',
    UPDATE_TEMPLATE_ID: 'UpdateTemplateId',
    UPDATE_TEMPLATE_VERSION: 'UpdateTemplateVersion'
};

function reducer(state, action) {
    switch (action.type) {
        case ActionTypes.CHANGE_MODE:
            return {...state, MODE: action.value};
        case ActionTypes.CHANGE_TITLE:
            return {...state, TITLE: action.TITLE};
        case ActionTypes.CHANGE_DESCRIPTION:
            return {...state, DESC: action.DESC};
        case ActionTypes.ADD_CATEGORY:
            {
                const {CATEGORY} = state;
                if(!CATEGORY.includes(action.CATEGORY))
                    CATEGORY.push(action.CATEGORY);
                return {...state, CATEGORY};
            }
        case ActionTypes.DELETE_CATEGORY:
            {
                const {CATEGORY} = state;
                if(CATEGORY.includes(action.CATEGORY))
                    CATEGORY.splice(CATEGORY.indexOf(action.CATEGORY), 1);
                return {...state, CATEGORY};
            }
        case ActionTypes.ADD_SHARED_USERS:
            {
                const {SHAREDUSERS} = state;
                if(!SHAREDUSERS.includes(action.SHAREDUSERS))
                    SHAREDUSERS.push(action.SHAREDUSERS);
                return {...state, SHAREDUSERS};
            }
        case ActionTypes.REMOVE_SHARED_USERS:
            {
                const {SHAREDUSERS} = state;
                if(SHAREDUSERS.includes(action.SHAREDUSERS))
                    SHAREDUSERS.splice(SHAREDUSERS.indexOf(action.SHAREDUSERS), 1);
                return {...state, SHAREDUSERS};
            }
        case ActionTypes.CHANGE_TYPE:
            return {...state, TYPE: action.TYPE};
        case ActionTypes.UPDATE_TEMPLATE_ID:
            return {...state, TEMPLATE_SYSTEMID: action.TEMPLATE_SYSTEMID};
        case ActionTypes.UPDATE_TEMPLATE_VERSION:
            return {...state, TEMPLATE_VERSION: action.TEMPLATE_VERSION};
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

export default function AddTemplates({enuSpace, _Modal}){
    const {
        id: ModalId,
    } = _Modal;

    const initTemplateInfo = {
        TYPE: 'new', // overwrite, new
        TEMPLATE_SYSTEMID: '',
        TEMPLATE_VERSION: '',
        MODE: ACCESS_LEVEL.PUBLIC,
        TITLE: '',
        DESC: '',
        CATEGORY: [],
        SHAREDUSERS: [],
    };

    const [TemplateInfo, setTemplateInfo] = useReducer(reducer, initTemplateInfo);
    const {DeleteModal} = useModalPopup();
    const UserListRef = useRef();
    const TagListRef = useRef();
    const {userId: MEMBER_SYSTEMID, projectId: PROJECT_SYSTEMID} = useSelector(state => state.projectData.BASE_DATA);
    const [TemplateVersion, setTemplateVersion] = useState([]);
    const CloseModal = (e) =>DeleteModal(ModalId);

    useEffect(() => {
        const scrollToBottom = (ref) => {
            if (ref && ref.current)
                ref.current.scrollTop = ref.current.scrollHeight;
        };
    
        scrollToBottom(UserListRef);
        scrollToBottom(TagListRef);
    }, [TemplateInfo]);

    useEffect(() => {
        const fetchTemplateVersion = async () => {
            if (!MEMBER_SYSTEMID || !PROJECT_SYSTEMID) return;
    
            try {
                const response = await GetTemplateVerInfo(MEMBER_SYSTEMID, PROJECT_SYSTEMID);
                const { result, data, msg } = response.data;
    
                if (result === 'Success') {
                    setTemplateVersion(data);
                } else if (msg === 'There Is No Template') {
                    
                }
            } catch (error) {
                window.AddAlertMsg({ type: 'error', msg: error.message });
            }
        };
    
        fetchTemplateVersion();
    }, [MEMBER_SYSTEMID, PROJECT_SYSTEMID]);
    

    useEffect(()=>{
        if(TemplateVersion.length === 0)
            return;

        const {TITLE, DESCRIPTION, CATEGORY, TEMPLATE_SYSTEMID} = TemplateVersion[TemplateVersion.length - 1];
        setTemplateInfo({type:ActionTypes.CHANGE_TITLE, TITLE})
        setTemplateInfo({type:ActionTypes.CHANGE_DESCRIPTION, DESC:DESCRIPTION})

        CATEGORY.forEach((category) => {
            setTemplateInfo({type: ActionTypes.ADD_CATEGORY, CATEGORY:category})
        })

        setTemplateInfo({type:ActionTypes.UPDATE_TEMPLATE_ID, TEMPLATE_SYSTEMID})
    }, [TemplateVersion]);
    
    const addUser = e => {
        const SHAREDUSERS = e.target.value;
        if (e.key === "Enter" && SHAREDUSERS !== "") {
            CheckUser(SHAREDUSERS)
                .then((res) => {
                    const{result, msg} = res.data;
                    if(result === 'Success'){
                        setTemplateInfo({type: ActionTypes.ADD_SHARED_USERS, SHAREDUSERS});
                        e.target.value = "";
                    }else{
                        window.AddAlertMsg({type:'error', msg});
                    }
                }).catch((err) => {
                    window.AddAlertMsg({type:'error', msg:err});
                });
        }
    };

    const addCategory = (e) => {
        const Category = e.target.value.trim().replace(/\s+/g, ' '); // 앞뒤 공백 제거 및 중복 공백 단일 공백으로 변경
        if (e.key === "Enter" && Category !== "") {
            const CATEGORY = Category.toUpperCase();
            setTemplateInfo({type: 'AddCategory', CATEGORY});
            e.target.value = "";
        }
    };

    const removeUser = (SHAREDUSERS) => setTemplateInfo({type: ActionTypes.REMOVE_SHARED_USERS, SHAREDUSERS});

    const AddTemplateEvent = (AddType = 'new') => {
        GetDescription(MEMBER_SYSTEMID, PROJECT_SYSTEMID)
            .then((res) => {
                const { result, msg, data } = res.data;
                if (result === 'Success') {
                    const image = JSON.parse(data);
                    let tempTemplateInfo = {...TemplateInfo};

                    if(TemplateVersion.length > 0)
                        tempTemplateInfo.TEMPLATE_VERSION = String(TemplateVersion[TemplateVersion.length - 1].TEMPLATE_VERSION);
                    if(AddType === "overwrite")
                        tempTemplateInfo.TYPE = "overwrite";
                    
                    const jsonObj = {
                        'template': tempTemplateInfo,
                        image,
                      };

                    const jsonString = JSON.stringify(jsonObj);
                    enuSpace.AddTemplate(jsonString);
                } else {
                    window.AddAlertMsg({ type: 'error', msg });
                }
            })
            .catch((err) => {
                window.AddAlertMsg({ type: 'error', msg: err });
            });
    }

    window.Re_AddTemplate = (templateData) => {
        MakeTemplate(templateData)
            .then((res) => {
                const { result, msg } = res.data;
                if(result === 'Success'){
                    window.AddToastMsg(msg);
                    CloseModal();
                }
            }).catch((err) => {
                window.AddAlertMsg({type:'error', msg:err.message});
            });
    }

    return(
        <div>
            <FlexContent>
                <GroupName width={100} htmlFor='TemplateTitle'>Version</GroupName>
                <SubTitle>
                    {TemplateVersion[TemplateVersion.length - 1]?.TEMPLATE_VERSION || 'New'}
                </SubTitle>
            </FlexContent>
            <FlexContent>
                <GroupName width={100} htmlFor='TemplateTitle'>Access Level</GroupName>
                <RadioComponent
                    isUseKeyLabel={true}
                    attributeName='TemplateMode'
                    height='36px'
                    dataList={ACCESS_LEVEL} 
                    titleList={{ PUBLIC: 'EnuSpace 모든 사용자에게 공개됩니다.', PRIVATE: '비공개 프로젝트입니다.' }}
                    selectedValue={TemplateInfo.MODE}
                    changeEvent={(name, value) => setTemplateInfo({type: ActionTypes.CHANGE_MODE, value})}
                />

            </FlexContent>
            <FlexContent>
                <GroupName width={100} htmlFor='TemplateTitle'>
                    <MdStar className="required" />Title
                </GroupName>
                <InputContent 
                    name="TemplateTitle" type='text' placeholder="Template Title" 
                    value={TemplateInfo.TITLE} 
                    onChange={(e)=>setTemplateInfo({type:ActionTypes.CHANGE_TITLE, TITLE:e.target.value})}
                />
            </FlexContent>
            <FlexContent>
                <GroupName width={100} htmlFor='TemplateDescription'>
                    Description
                </GroupName>
                <Textarea 
                    name="TemplateDescription" type='text' placeholder="Template Description" 
                    value={TemplateInfo.DESC} 
                    onChange={(e)=>setTemplateInfo({type:ActionTypes.CHANGE_DESCRIPTION, DESC:e.target.value})}
                />
            </FlexContent>

            {/* TODO: 서버를 통해 등록된 category 자동 완성  */}
            <FlexContent style={{marginBottom: '8px'}}>
                <GroupName width={100} htmlFor='TemplateCategory'>Category</GroupName>
                <TemplateTagsInput ref={TagListRef}>
                    <ul className="tags">
                        {TemplateInfo.CATEGORY.map((CATEGORY, index) => (
                            <li key={CATEGORY} className="tag">
                                <span className="tag-title">{CATEGORY}</span>
                                <i className="tag-close-icon"><MdClear onClick={(e) => setTemplateInfo({ type: ActionTypes.DELETE_CATEGORY, CATEGORY})} /></i>
                            </li>
                        ))}
                    </ul>
                    <input type="text" name="Tag" placeholder="Press Enter to add a category" onKeyUp={e => addCategory(e)} />
                </TemplateTagsInput>
            </FlexContent>
            <WarningMsg></WarningMsg>
            <ModalBottomBox>
                <BtnSecondary label="Cancel" onClick={CloseModal} />
                {!TemplateVersion[TemplateVersion.length - 1]
                    ? <BtnPrimary label="Add Template" onClick={(e)=>AddTemplateEvent()}  disabled={TemplateInfo.TITLE === ''}/>
                    : (
                        <>
                            <BtnGhost label="Overwrite" onClick={(e)=>AddTemplateEvent("overwrite")}  disabled={TemplateInfo.TITLE === ''}/>
                            <BtnPrimary label="New Version" onClick={(e)=>AddTemplateEvent()}  disabled={TemplateInfo.TITLE === ''}/>
                        </>
                    )
                }
            </ModalBottomBox>

            {/* private 시 유저추가 모달 */}
            {TemplateInfo.MODE === "private" &&
                <UserAddDox>
                    <SubTitle>Shared Users<span style={{marginLeft:'8px', color:'#888'}}>{TemplateInfo.SHAREDUSERS.length}명</span></SubTitle>
                    <TagsInput ref={UserListRef}>
                        <ul className="tags">
                            {TemplateInfo.SHAREDUSERS.map((Email, index) => (
                                <li key={Email} className="tag">
                                    <span className="tag-title">{Email}</span>
                                    <i className="tag-close-icon"><MdClear onClick={(e)=>removeUser(Email)}/></i>
                                </li>
                            ))}
                        </ul>
                        <input type="text" placeholder="Press Enter to add User Email"  onKeyUp={e => addUser(e)} />
                    </TagsInput>
                </UserAddDox>
            }
        </div>
    )
}

const FlexContent = styled.div`
    display: flex;
    align-items: start;
    margin-bottom: 16px;
`;

const Textarea = styled(TextareaContent)`
    width: 100%;
    height: 60px;
    resize: none;
`;

const UserAddDox = styled.div`
    position: absolute;
    top: 0;
    right: -306px;
    height: 100%;
    width: 290px;
    background-color: #fff;
    border-radius: 0.3rem;
    box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
    padding: 16px;
    box-sizing: border-box;
`;

const TagsInput = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    /* height: 100%; */
    max-height: calc(100% - 30px);
    overflow: auto;
    padding: 0 8px;
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    border-radius: 4px;
    box-sizing: border-box;

    &:focus-within {
        border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
    }

    input {
        width: 100%;
        display: block;
        border: none;
        height: 46px;
        font-size: .9rem;
        padding: 4px 0 0 0;

        &:focus {
            outline: transparent;
        }

        &::placeholder {
            color: ${({ theme }) => theme.base.font_color_Gray};
        }
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 8px;
        gap: 8px;
    }

    .tag {
        width: auto;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 8px;
        padding-left: 16px;
        font-size: .9rem;
        list-style: none;
        border-radius: 16px;
        background: ${({ theme }) => theme.base.background_color_PaleGray};
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};;

        .tag-title {
            /* margin-top: 3px; */
        }

        .tag-close-icon {
            svg{
                display: block;
                width: 16px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                font-size: 1rem;
                margin-left: 8px;
                color: ${({ theme }) => theme.base.font_color_Black};
                border-radius: 50%;
                background-color: #FFF;
                cursor: pointer;
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                    color: ${({ theme }) => theme.base.font_color_Red};
                }
            }
        }
    }
`;

const TemplateTagsInput = styled(TagsInput)`
    max-height: 200px;
`;