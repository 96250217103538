import Base from "babylon/class/Base";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";
import { MeshBuilder } from "@babylonjs/core";
import * as earcut from "earcut";
import { Droid_Sans_Regular } from 'babylon/fonts/Droid Sans_Regular'
// import { Droid_Sans_Bold } from "babylon/fonts/Droid Sans_Bold";
// import { Kenney_Future_Regular } from "babylon/fonts/Kenney Future Regular";


//TODO: 화면고정 textblock과 구분이 용이한 이름?
export default class EnuTextBox extends Base {
    constructor(
        EnuScene,
        name = "textbox",
        options = {
            text: "enuSpace Meta",
            size: 10,
            resolution: 64,
            depth: 3,
            updatable: true,
        }
    ) {
        const textbox = MeshBuilder.CreateText(
            name,
            options.text,
            Droid_Sans_Regular,
            options,
            EnuScene.scene,
            earcut
        );
        
        textbox.metadata = {};
        textbox.metadata.objectType = SUPPORT_OBJECT_TYPE.TEXTBOX;        

        super(EnuScene, textbox);

        this._text = options.text;
        this._size = options.size;
        this._resolution = options.resolution;
        this._depth = options.depth;
        
        this.scene = EnuScene
        this.objectType = SUPPORT_OBJECT_TYPE.TEXTBOX;
    }

    replaceOriginMesh() {
        const options = {
            size: this.size,
            resolution: this.resolution,
            depth: this.depth
        };

        const updatedText = MeshBuilder.CreateText(
            this.name,
            this.text,
            Droid_Sans_Regular,
            options,
            this.scene.scene,
            earcut
        );

        //TODO: move this functionality to EnuScene?
        const uniqueId = this.originMesh.uniqueId;
        this.originMesh.dispose()
        this.originMesh = updatedText;
        this.scene.addObjectToMap(uniqueId, this);        
    }

    get text() { return this._text; }
    set text(_text) {
        this._text = _text;
        this.replaceOriginMesh();
    }

    get size() { return this._size; }
    set size(_size) {
        this._size = _size;
        this.replaceOriginMesh();
    }

    get resolution() { return this._resolution; }
    set resolution(_resolution) {
        this._resolution = _resolution;
        this.replaceOriginMesh();
    }

    get depth() { return this._depth; }
    set depth(_depth) {
        this._depth = _depth;
        this.replaceOriginMesh();
    }

    getPropertyMap() {
        const basePropertyMap = super.getPropertyMap();
        return {
            ...basePropertyMap,
            text: this.text,
            size: this.size,
            resolution: this.resolution,
            depth: this.depth,
            objectType: this.objectType,
        };
    }
}
