import { useState } from 'react';

import { GroupName, TextareaContent} from 'components/css/common';
import { BtnPrimary, BtnSecondary, BtnText } from "components/atom/Button";
import { AUTHORITY_TYPE} from "components/common/EnuSpaceEnum";
import { CreateBoardReply, DeleteBoardReply, UpdateBoardReply } from "api/ArchiveApi";

import styled from 'styled-components';
import { MdClose, MdMoreHoriz } from "react-icons/md";

export default function BoardReplay({MyAccess, BaseInfo, BoardData, ReLoadEvent}){
    const {pageId: ArchiveId, userId: UserId} = BaseInfo;
    const {POST_IDX: PostId, REPLY_LIST: ReplayList } = BoardData;
    const [ReplyData, setReplyData] = useState('');
    const [EditReplay, setEditReplay] = useState({REPLY_IDX:'', REPLY:''});

    return (
        <ReplyContent>
            {ReplayList?.map((replayInfo)=>{

                const {REPLY_IDX, PROFILE_SYSTEMID, REPLY, NICKNAME, CREATED_STAMP, IS_SELF} = replayInfo;
                const createdStamp = CREATED_STAMP.replace(/-/g, '.').replace(/T/g, '  ');

                return(
                    <UserReplay key={REPLY_IDX}>
                        <div className='profile'>
                            <img src={`/profile/${PROFILE_SYSTEMID}.jpg`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                        </div>
                        <div className='Replay'>
                            <div className='Info'>
                                <div>
                                    {NICKNAME}
                                    <span>{createdStamp}</span>
                                </div>
                                <div className='option'>
                                    {IS_SELF &&
                                        <BtnText
                                            type='sm'
                                            label={<MdMoreHoriz />}
                                            onClick={(e)=>{ setEditReplay({REPLY_IDX, REPLY})}}
                                        />
                                    }

                                    { (IS_SELF || [AUTHORITY_TYPE.MANAGER, AUTHORITY_TYPE.MASTER].includes(MyAccess)) &&
                                        <BtnText
                                            type='sm btn_del'
                                            label={<MdClose />}
                                            onClick={(e)=>{
                                                DeleteBoardReply( UserId, PostId, REPLY_IDX)
                                                    .then(res =>{
                                                        const { result, msg } = res.data;
                                                        if (result === "Success") {
                                                            setEditReplay({REPLY_IDX:'', REPLY:''})
                                                            ReLoadEvent && ReLoadEvent()
                                                        } else {
                                                            window.AddAlertMsg({ type: 'error', msg });
                                                        }

                                                    }).catch(err=>{
                                                        window.AddAlertMsg({ type: 'error', msg: `${err}` });
                                                    })
                                            }}
                                        />
                                    }
                                </div>
                            </div>

                            {EditReplay.REPLY_IDX !== REPLY_IDX 
                                ? <div className='ReplayInfo'> {REPLY} </div>
                                : <div className='ReplayInfo'>
                                    <TextareaContent
                                        name='Reply'
                                        placeholder='Reply...'
                                        value={EditReplay.REPLY}
                                        onChange={(e) => setEditReplay(prev => ({...prev,  REPLY: e.target.value}))}
                                    />

                                    <div>
                                        <BtnSecondary
                                            type='sm'
                                            label='Cancel'
                                            onClick={(e) => setEditReplay({REPLY_IDX:'', REPLY:''})}
                                        />
                                        <BtnPrimary
                                            type='sm'
                                            label='Edit'
                                            onClick={(e) => {
                                                if (EditReplay.REPLY === '') return;

                                                UpdateBoardReply( UserId, PostId, REPLY_IDX, EditReplay.REPLY)
                                                    .then(res => {
                                                        const { result, msg } = res.data;
                                                        if (result === "Success") {
                                                            setEditReplay({REPLY_IDX:'', REPLY:''})
                                                            ReLoadEvent && ReLoadEvent()
                                                        } else {
                                                            window.AddAlertMsg({ type: 'error', msg });
                                                        }
                                                    }).catch(err => {
                                                        window.AddAlertMsg({ type: 'error', msg: `${err}` });
                                                    })
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </UserReplay>
                )
            })}

            <li className='replayTextarea'>
                <GroupName width={80}>댓글</GroupName>
                <div>
                    <TextareaContent
                        name='Reply'
                        placeholder='Reply...'
                        value={ReplyData}
                        onChange={(e) => { setReplyData(e.target.value) }}
                    />

                    <BtnSecondary
                        type='sm'
                        label='Reply'
                        onClick={(e) => {
                            if (ReplyData === '') return;

                            CreateBoardReply(ArchiveId, UserId, PostId, ReplyData)
                                .then(res => {
                                    const { result, msg } = res.data;
                                    if (result === "Success") {
                                        setReplyData('')
                                        ReLoadEvent && ReLoadEvent()
                                    } else {
                                        window.AddAlertMsg({ type: 'error', msg });
                                    }
                                }).catch(err => {
                                    window.AddAlertMsg({ type: 'error', msg: `${err}` });
                                })
                        }}
                    />
                </div>
            </li>
        </ReplyContent>
    )
}

const ReplyContent = styled.ul`
    position: relative;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;

    border-top: 1px solid ${({ theme }) => theme.base.border_color_Gray};;

    .replayTextarea{
        &>div{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 8px;

            textarea{
                font-size: 1rem;
                resize: none;
                width: 100%;
                height: 60px;
            }
        }
    }

    li {
        display: flex;
        width: 100%;
    }
`;

const UserReplay = styled.li`
    .profile{
        position: relative;
        width: 80px;
        text-align: end;
        padding: 6px 8px;
        box-sizing: border-box;

        img{
            width: 38px;
            height: 38px;
            overflow: hidden;
            border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
            border-radius: 50%;
            box-sizing: border-box;
        }
    }

    .Replay{
        display: flex;
        flex-direction: column;
        width: calc(100% - 80px);
        font-size: 1rem;
        color: ${({ theme }) => theme.base.font_color_Black};

        &:hover{
            .Info .option{
                display: flex;
            }
        }

        .Info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            height: 28px;

            span{
                font-size: .9rem;
                font-weight: normal;
                margin-left: 16px;
                opacity: .6;
            }

            .option{
                display: none;
                
                .btn_del{
                    color: ${({ theme }) => theme.base.border_color_Red};
                }
            }
        }

        .ReplayInfo{
            line-height: 1.8rem;

            &>div{
                display: flex;
                justify-content: end;
                gap: 8px;
            }
        }

        textarea{
            font-size: 1rem;
            resize: none;
            width: 100%;
            height: auto;
        }
    }
`;