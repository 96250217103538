import { useEffect } from 'react';
import useProjectData from "hooks/useProjectData";
import {SetDescription} from 'api/Project'

import { insertCanvasView} from 'reducers/projectDataReducer'
import { useDispatch } from 'react-redux';

export default function useLoadProject(enuSpace, _URL, isLoadingPage = true, isTemplate=false, templateData=null, tempImageInfo=null){
    const dispatch = useDispatch()
    const {DeleteNodeData} = useProjectData();

    useEffect(()=>{
        if(_URL === null){
            window.alert("잘못된 접근입니다.");
            window.location.href = '/';
            return;
        }
    
        if(enuSpace !== "" && enuSpace !== undefined && isLoadingPage){
            if(isTemplate){
                enuSpace.LoadTemplate(JSON.stringify(templateData));
                // template에 포함된 이미지 정보를 저장 하기 위한 처리
                SetDescription(localStorage.getItem('systemId'), localStorage.getItem('projectId'), tempImageInfo, 'temp')
            }else
                enuSpace.LoadProject(_URL, "base");
        }
    }, [enuSpace, _URL, isLoadingPage, isTemplate, templateData, tempImageInfo]);

    
    window.Re_BaseLoadProject = () => {
        DeleteNodeData();
        enuSpace.GetProjectTree();
        const ActivateViewPath = enuSpace.GetActivateView();
        if(ActivateViewPath)
            dispatch(insertCanvasView(ActivateViewPath));
    }

    window.Re_BaseLoadTemplateProject = () => {
        enuSpace.GetProjectTree();
        dispatch(insertCanvasView(`picture\\${templateData['picture'][0].title}.svg`));
    }
}

export function LoadProject(enuSpace, _URL, _setIsLoadingPage, callbackFunc, canvasId = 'Canvas_box'){
    if(_URL === null){
        window.alert("잘못된 접근입니다.");
        window.location.href = '/';
        return;
    }

    if(enuSpace !== "" && enuSpace !== undefined){
        enuSpace.LoadProject(_URL, "callbackLoad");
    }

    window.Re_callbackLoadProject = () => {
        const ActivateViewPath = enuSpace.GetActivateView();
        const width = document.getElementById(canvasId).offsetWidth;
        const height = document.getElementById(canvasId).offsetHeight;
        enuSpace.CreateView('Canvas', width, height);

        if(ActivateViewPath !== '' && ActivateViewPath !== undefined)
            enuSpace.SetView('Canvas', ActivateViewPath, true, "Runtime");
            
        _setIsLoadingPage && _setIsLoadingPage(false)
        callbackFunc && callbackFunc()
    }
}