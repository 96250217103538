import {useState, useEffect, useCallback} from 'react'
import { useSelector } from 'react-redux';
import {AccessAxios} from 'components/Axios';

export default function useDocument(isGetDocumentList = true) {
    const BASE_DATA = useSelector(state => state.projectData.BASE_DATA);
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [DocumentList, setDocumentList] = useState([]);

    /**
     * document 리스트 가져오기
     */
    const GetDocumentList = useCallback(async () => {
        setIsLoadingPage(true);
        try {
            const MSG = {
                MEMBER_SYSTEMID: BASE_DATA.userId,
                PROJECT_SYSTEMID: BASE_DATA.projectId,
                path: 'document',
            }

            const res = await AccessAxios.post('/api/Document/GetDocumentList', MSG);
            setDocumentList(res['data']['Data'][0]);
            return res;
        } catch (err) {
            throw err;
        } finally {
            setIsLoadingPage(false);
        }
    },[BASE_DATA]);

    useEffect(() => {
        if(isGetDocumentList)
            GetDocumentList();
    }, [isGetDocumentList, GetDocumentList]);

    /**
    document 폴더 생성
    @param {string} _foldername - 폴더 이름
    */
    const CreateFolder = async (_foldername) => {
        const MSG = {
            MEMBER_SYSTEMID: BASE_DATA.userId,
            PROJECT_SYSTEMID: BASE_DATA.projectId,
            foldername: _foldername,
        }
        try {
            const res = await AccessAxios.post('/api/Project/CreateFolder', MSG);
            return res['data'];
        } catch (err) {
            throw err;
        }
    }
    
    /**
    md 파일 생성
    @param {string} _foldername - 파일 이름
    */
    const CreateFile = async (_foldername) => {
        const MSG = {
            MEMBER_SYSTEMID: BASE_DATA.userId,
            PROJECT_SYSTEMID: BASE_DATA.projectId,
            filename: _foldername,
        }
        try {
            const res = await AccessAxios.post('/api/Document/CreateDocument', MSG);
            return res['data'];
        } catch (err) {
            throw err;
        }
    }

    /**
    파일 삭제
    @param {Array} _foldername - 파일명
    */
    const DeleteDocuments = async (_filenames) => {
        const MSG = {
            MEMBER_SYSTEMID: BASE_DATA.userId,
            PROJECT_SYSTEMID: BASE_DATA.projectId,
            filenames: [_filenames],
        }
        try {
            const res = await AccessAxios.post('/api/Document/DeleteDocuments', MSG);
            return res['data'];
        } catch (err) {
            throw err;
        }
    }

    /**
    이름을 변경한다.
    @param {string} _path - 파일 경로
    @param {string} _oldName - 파일명
    @param {string} _newName - 파일명
    */
    const RenameDocument = async (_path, _oldName, _newName) => {
        const MSG = {
            MEMBER_SYSTEMID: BASE_DATA.userId,
            PROJECT_SYSTEMID: BASE_DATA.projectId,
            path: _path,
            olD_NAME: _oldName,
            neW_NAME: _newName
        }
        try {
            const res = await AccessAxios.post('/api/Document/RenameDocument', MSG);
            return res['data'];
        } catch (err) {
            throw err;
        }
    }

    /**
    document 파일 저장
    @param {string} MEMBER_SYSTEMID - 유저 아이디
    @param {string} PROJECT_SYSTEMID - 프로젝트 아이디
    @param {string} path - 파일 경로
    @param {string} filename - 파일 이름
    @param {string} data - 파일 내용
    */
    const SaveDoc = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, path, filename , data) => {
        try{
            const MSG = {
                MEMBER_SYSTEMID,
                PROJECT_SYSTEMID,
                path,
                filename,
                data,
            }

            const res = await AccessAxios.post('/api/Document/SaveDocument', MSG);  
            return res['data'];
        }catch(err){
            throw err;
        }
    }

    /**
    파일을 업로드 한다
    @param {Array} _filedata - 파일 데이터
    @param {string} _uploadpath -파일 Path
    */
    const UploadDoc = async (_filedata, _uploadpath) => {
        if(_filedata === '')
            return;

        let formData = new FormData();
        Array.from(_filedata).forEach(file => {
            formData.append("filedata", file);
        })
        formData.append("MEMBER_SYSTEMID",  BASE_DATA.userId);
        formData.append("PROJECT_SYSTEMID", BASE_DATA.projectId);
        formData.append("uploadpath", _uploadpath);

        try{
            const res = await AccessAxios.post('/api/Document/UploadDocuments', formData);  
            return res['data'];
        }catch(err){
            throw err;
        }
    }

    return { DocumentList, isLoadingPage, GetDocumentList, SaveDoc, CreateFolder, CreateFile, DeleteDocuments, RenameDocument, UploadDoc};
};

/**
Publish document 파일 읽기
@param {string} PUBLISH_SYSTEMID Publish 아이디
@param {string} PATH - 파일 경로
@returns {string} - 파일 내용
*/
export const PublishReadDocument = async (PUBLISH_SYSTEMID, PATH) => {
    try {
        const MSG = { PUBLISH_SYSTEMID, PATH, }

        const res = await AccessAxios.post('/api/Document/Publish/ReadDocument', MSG);
        return res['data']['Data'];
    } catch (err) {
        throw err;
    }
}

/**
document 파일 읽기
@param {string} _useId - 유저아이디
@param {string} _projectId - 프로젝트 아이디
@param {string} _path - 파일 경로
@returns {string} - 파일 내용
*/
export const ReadDocument = async (_useId, _projectId, _path) => {
    try {
        const MSG = {
            MEMBER_SYSTEMID: _useId,
            PROJECT_SYSTEMID: _projectId,
            path: _path,
        }

        const res = await AccessAxios.post('/api/Document/ReadDocument', MSG);
        return res['data']['Data'];
    } catch (err) {
        throw err;
    }
}