import styled from "styled-components";

function Checkbox({ _id, _disabled, checked, onChange, children, ...props }) {
    return (
    <CheckboxDiv className={_disabled ? 'disabled' : null} {...props}>
        <label>
            <input type="checkbox" name={_id} id={_id} disabled={_disabled} checked={checked} onChange={onChange}/>
            {children}
        </label>
    </CheckboxDiv>
    );
}

export default Checkbox;

// 스타일-----------------------------------------------------
const CheckboxDiv = styled.div`
    height: 24px;
    padding: 2px;
    margin: 2px !important;
    border: 1px solid transparent;
    border-radius: 2px;
    box-sizing: border-box;
    color: ${({theme}) => theme.base.font_color_DarkGray};
    
    &.disabled, &.disabled:hover , &.disabled label {
        cursor: default;
        border-color: transparent;
        background-color: transparent;
    }

    &:hover {
        border-color: ${({theme}) => theme.base.border_color_Blue};
        background-color: ${({theme}) => theme.base.background_color_LightBlue};
    }

    label {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        user-select: none;

        >input {
            margin-right: 4px;
        }
    }
`;