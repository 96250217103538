import { useState, useEffect } from "react";

import ResizeDiv from "components/atom/ResizeDiv";
import Markdown from 'components/atom/Markdown'
import TreeRowContent from 'components/atom/TreeRowContent';
import {GetRealTarget} from 'components/Utility';
import {TreeUlContent} from "components/css/common";
import {Axios} from 'components/Axios';

import styled from "styled-components";
import { useNavigate, useLocation } from 'react-router-dom';

/**
manual 포덜의 파일 리스트 가져온다.
*/
const GetManualList = async () => {
    try {
        const res = await Axios.post('/api/Document/GetManualList', { headers: { 'Content-Type': 'application/json' } });
        return res['data']['Data'][0];
    } catch (err) {
        return err;
    }
}

/**
manual 정보를 가져온다.
* @param {string} path - 파일 경로
*/
const ReadManual = async (path) => {
    const MSG = { path }

    try {
        const res = await Axios.post('/api/Document/ReadManual', MSG );
        return res['data']['Data'];
    } catch (err) {
        return err;
    }
}

function ManualPage(){
    const [isOpenTree, setIsOpenTree] = useState('');
    const navigate = useNavigate();
    const [ManualList, setManualList] = useState([]);
    const [markdownInfo, setMarkdownInfo] = useState(`# Manual Page`);
    const [SelectedManual, setSelectedManual] = useState('');

    const location = useLocation();
    
    useEffect(()=>{
        GetManualList()
            .then((res)=>{
                setManualList(res);
            })
            .catch((err)=>{
                console.log('err', err);
            });
    }, []);

    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);
        const PathInfo = searchParams.get('path');
    
        if(PathInfo === null){
            setMarkdownInfo(`# Manual Page`);
            return;
        }

        //파일 읽기 manual 폴더 안 메뉴 path정보를 입력한다.
        ReadManual(`manual\\${PathInfo}`)
            .then((res)=>{
                setSelectedManual(`manual\\${PathInfo}`);
                setMarkdownInfo(res);
            }).catch((err)=>{
                console.log('err', err);
            });
    }, [location]);

    const EventDoubleClick = (e) =>{
        e.preventDefault();
        const Target = GetRealTarget(e.target, 'DIV');
        
        const isMarkdown = Target.dataset.type === 'document';
        if (!isMarkdown) return;
        
        //url 이동
        const path = Target.dataset.path.substring(Target.dataset.path.indexOf('\\') + 1, Target.dataset.path.length).replace(/\s/g, '%20');
        navigate(`/UserGuide?path=${path}`);
    }

    const RenderTree = (menu, path ='manual') => {
        return menu.map((item) => {
            if(item.title === 'assets') return null;

            const _path = `${path}\\${item.title}`;
            const isSelected = _path === SelectedManual;

            return(
                <ManualRow key={_path} className={isSelected ? 'selectedManual' : ''}>
                    <TreeRowContent 
                    _checkOpenTree={[isOpenTree, setIsOpenTree]} 
                    _id={item.id} 
                    _title={item.title.replace('.md', '')} 
                    _type={item.type && item.type} 
                    _path={_path}
                    _onDoubleClick={EventDoubleClick}
                    _isShow={false}
                    >
                        {item.child && 
                            <TreeUlContent>
                                {(RenderTree(item.child, _path))}
                            </TreeUlContent>
                        }
                    </TreeRowContent>
                </ManualRow>
            )
        })
    }

    return(
        <ManualContent>
            <ResizeDiv _pos={'right'} _width={250}>
                <div style={{ height:'100%', paddingLeft: '8px'}}>
                    <TreeRowContent _title="manual" _type="ProjectName" >
                        <TreeUlContent>
                            {ManualList.child && ManualList.child.length > 0 && RenderTree(ManualList.child)}
                        </TreeUlContent>
                    </TreeRowContent>
                </div>
            </ResizeDiv>

            <div className="manualInfo">
                <Markdown _markdownInfo={markdownInfo} _rootPath={'manual/'}/>
            </div>
        </ManualContent>
    )
}

export default ManualPage;

//스타일 ------------------------------------
const ManualContent = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    aside{
        flex-shrink: 0;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &>.manualInfo{
        position: relative;
        height: 100%;
        width: 100%;
        flex-grow: 1;
        overflow: auto;

        img{
        }
    }
`;

const ManualRow = styled.li`
    &.selectedManual > div{
        &::before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 6px;
            height: 100%;
            background-color: ${({theme}) => theme.base.background_color_Blue};
        }
    }
`;