import { useState } from "react";

import { TernsOfServiceData } from "components/common/TernsOfService"
import { PrivacyPolicy } from "components/common/PrivacyPolicy"
import Checkbox from "components/atom/Checkbox";
import {BtnPrimary} from "components/atom/Button";

import styled from "styled-components"

export default function CheckTernsOfService({setNext}){
    const [isCheck, setCheck] = useState({
        TernsOfService: false,
        PrivacyPolicy: false,
    });

    const CheckHeader = (Key) => setCheck({...isCheck, [Key]:!isCheck[Key]});

    return(
        <Content>
            <Title>이용약관</Title>
            <InfoBox>
                {TernsOfServiceData?.map((list, index) => {
                    const { article, content, subItems } = list;
                    return (
                        <div key={index} className="section">
                            <div className="sectionTitle">{article}</div>
                            {content && <div className="content">{content}</div>}
                            {subItems &&
                                <ol className="subContents">
                                    {subItems.map((subItems, index) => (
                                        <li key={index}>
                                            {subItems?.term}
                                            {subItems.subDefinitions &&
                                                <ul className="definitions">
                                                    {subItems.subDefinitions.map((Definitions, index) => (
                                                        <li key={index}>{Definitions}</li>
                                                    ))}
                                                </ul>
                                            }
                                        </li>
                                    ))}
                                </ol>
                            }
                        </div>
                    )
                })}
            </InfoBox>
            <div className="CheckBox">
                <Checkbox _id='TernsOfService' checked={isCheck.TernsOfService} onChange={(e)=>CheckHeader("TernsOfService")}>이용약관 동의</Checkbox>
            </div>

            <Title style={{ marginTop: '16px' }}>개인정보 처리방침</Title>
            <InfoBox>
                {PrivacyPolicy?.map((list, index) => {
                    const { article, content, subItems, warring, note } = list;
                    return (
                        <div key={index} className="section">
                            <div className="sectionTitle">{article}</div>
                            {content && <div className="content">{content}</div>}
                            {subItems &&
                                <ol className="subContents">
                                    {subItems.map((subItems, index) => (
                                        <li key={index}>
                                            {subItems?.term}
                                            {subItems.subMote &&
                                                <p>{subItems.subMote}</p>
                                            }
                                            {subItems.subDefinitions &&
                                                <ul className="definitions">
                                                    {subItems.subDefinitions.map((Definitions, index) => (
                                                        <li key={index}>{Definitions}</li>
                                                    ))}
                                                </ul>
                                            }
                                        </li>
                                    ))}
                                </ol>
                            }
                            {note &&
                                note.map((item, index) => (
                                    <p key={index} className="note">{item}</p>
                                ))
                            }
                            {warring &&
                                <p className="warring">{warring}</p>
                            }
                        </div>
                    )
                })}
            </InfoBox>
            <div className="CheckBox">
                <Checkbox _id='PrivacyPolicy' checked={isCheck.PrivacyPolicy} onChange={(e)=>CheckHeader("PrivacyPolicy")}>개인정보 처리방침 동의</Checkbox>
            </div>
            <BtnBox>
                <BtnPrimary label="확인" style={{paddingInline: '30px'}} onClick={(e)=>setNext(2)} disabled={!(isCheck.TernsOfService && isCheck.PrivacyPolicy)}/>
            </BtnBox>
        </Content>
    )
}

// 스타일 ------------------------------------
const Content = styled.div`
    position: relative;
    height: calc(100% - 174px);
    padding: 2rem;
    margin: 50px auto;
    border-radius: 0.3rem;
    z-index:1;
    box-sizing: border-box;
    background-color: #fff;
    user-select: none;

    @media screen and (min-width:1200px) {
        width: 1200px;
    }
    @media screen and (min-width:768px) and (max-width:1200px) {
        width: 92%;
    }
    @media screen and (max-width:767px) {
        width: 92%;
    }

    .CheckBox{
        position: relative;
        width: 100%;
        height: 40px;

        &>div{
            position: absolute;
            height: auto;
            right: 0;

            &>label{
                padding: 8px;
            }
        }
    }
`;

const Title = styled.div`
    font-size: 1.4rem;
    font-weight: bold;
`;

const InfoBox = styled.div`
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    height: calc(50% - 100px);
    padding: 16px;
    margin-top: 8px;
    border-radius: 0.2rem;
    line-height: 1.4;
    overflow: auto;
    box-sizing: border-box;
    
    .sectionTitle{
        font-weight: bold;
        margin-bottom: 8px;
    }

    .content{
        margin-bottom: 8px;
    }

    .section:not(:first-child){
        margin-top: 24px;
    }

    .subContents{
        padding-inline-start: 1rem;
    }

    .definitions {
        padding-inline-start: 1.6rem;

        li{
            list-style: upper-roman;
        }
    }

    .warring{
        margin-top: 8px;
        font-size: .9rem;
        color: ${({ theme }) => theme.base.font_color_Red};
    }

    .note{
        font-size: .9rem;
        font-weight: bold;
    }

    li{
        font-size: .9rem;
        list-style: auto;
        margin-top: 4px;
        color: ${({ theme }) => theme.base.font_color_DarkGray};
    }
`;

const BtnBox = styled.div`
    display: flex;
    justify-content: center;
`;