import { useState } from 'react';

import Notification from 'components/atom/Notification';
import {
    PointerIcon,
    MemoIcon,
    Selector,
    EraserIcon,
    DrawIcon,
    RectIcon,
} from "components/common/EnuCanvasIcon"
import EnuCanvasHeader from "components/organism/EnuCanvasHeader";
import EnuCanvasToolBar from "components/organism/EnuCanvasToolBar";
import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import { BgPopup, ModalBottomBox } from 'components/css/common'

import styled from "styled-components"

const SupportColor = {
    Fill:{
        White: '#FFFFFF',
        Black: '#C4C4C4',
        Blue: '#B8CDE5',
        Red: '#E5B8B8',
        Yellow: '#FFE0B2',
        Green: '#B8E5D6',
    },
    Border:{
        White: '#FFFFFF',
        Black: '#2B2B2B',
        Blue: '#1565C0',
        Red: '#C62828',
        Yellow: '#FF9800',
        Green: '#00C986',
    }
}

export default function EnuCanvasPage({enuSpace, BASE_DATA, ...props}){
    const [ActiveTool, setActiveTool] = useState({
        ToolName: 'Selector',
        isOption: false,
        Border: SupportColor.Border.Black,
        Fill: SupportColor.Fill.White,
        TextAlign: '',
        Type: '',
    });
    
    const [isSubOptionName, setIsSubOptionName] = useState('');

    const [MemoInfo, setMemoInfo] = useState({
        systemID: '',
        text: '',
        color: '#2B2B2B',
        backgroundColor: SupportColor.Border.White,
    });

    const [EnuCanvasTools, SetEnuCanvasTools] = useState([
        {ToolName: 'Selector', icon: <Selector />, isOption: false, Border: SupportColor.Border.Black},
        {ToolName: 'Pen', icon: <DrawIcon />, isOption: true, Border: SupportColor.Border.Black},
        {ToolName: 'Eraser', icon: <EraserIcon/>, isOption: false, Border: SupportColor.Border.Black},
        {ToolName: 'Memo', icon: <MemoIcon />, isOption: false, Border: SupportColor.Border.Black},
        {ToolName: 'Draw', icon: <RectIcon />, isOption: true, Border: SupportColor.Border.Black, Fill: SupportColor.Fill.White, Type: 'Rect'},
        {ToolName: 'Pointer', icon: <PointerIcon />, isOption: false, Border: SupportColor.Border.Black},
    ]);

    const handlerColorClick = (ToolName, colorValue, colorType ='Border') => {
        const selectedColor = SupportColor[colorType][colorValue];
        SetEnuCanvasTools(prev => prev.map(item => {
            return item.ToolName === ToolName ? { ...item, [colorType]: selectedColor } : item;
        }));
        setActiveTool(prev => ({ ...prev, [colorType]: selectedColor }));
        setIsSubOptionName('');
        handlerActiveTool(colorType, {changeValue: selectedColor})
    };

    const handlerActiveTool = (Key, option) => {
        if(Key === 'ToolName'){
            const {ToolName} = option;
            HandlerEnuCanvas(ToolName, EnuCanvasTools.find(item => item.ToolName === ToolName));
        } else {
            const ActiveInfo = {...ActiveTool};
            ActiveInfo[Key] = option.changeValue;
            HandlerEnuCanvas(ActiveInfo.ToolName, ActiveInfo);
        }
    };

    const HandlerEnuCanvas = (TOOLNAME, TargetInfo) =>{
        switch(TOOLNAME){
            case 'Pen':
            case 'Memo':
                {
                    const  BORDER = TargetInfo.Border;
                    enuSpace.CanvasRequest("CreateNode", JSON.stringify({TOOLNAME, BORDER}));
                    break;
                }
            case 'Draw':
                {
                    const {Fill: FILL, Border: BORDER, Type: TYPE} = TargetInfo;
                    enuSpace.CanvasRequest("CreateNode", JSON.stringify({TOOLNAME, TYPE, FILL, BORDER}));
                    break;
                }
            default:
                {
                    enuSpace.CanvasRequest("CreateNode", JSON.stringify({TOOLNAME}));
                    break;
                }
        }
    }

    window.Re_Canvas_SetSelector = () => {
        setIsSubOptionName('Selector');
        setActiveTool(prev => ({ ...prev, ToolName: 'Selector', isOption: false}));
    }

    // Memo 호출
    window.Re_Canvas_EditMemo = (systemID, text, fill, textColor) =>{
        setIsSubOptionName('Memo');
        setMemoInfo({
            systemID,
            text,
            color: textColor,
            backgroundColor: fill,
        })
    }

    return(
        <EnuCanvasContent 
            onClick={(e)=>{
                setActiveTool(prev => ({...prev, isOption: false}))
                setIsSubOptionName('');
            }}
        >
            <Notification />
            <EnuCanvasHeader
                enuSpace={enuSpace}
                BASE_DATA={BASE_DATA}
                StateSubOption={[isSubOptionName, setIsSubOptionName]}
                StateActiveTool={[ActiveTool, setActiveTool]}
                SupportColor = {SupportColor}
                SetEnuCanvasTools={SetEnuCanvasTools}
                handleColorClick={handlerColorClick}
                handlerActiveTool={handlerActiveTool}
            />

            <CanvasBody>
                <EnuCanvasToolBar
                    EnuCanvasTools = {EnuCanvasTools}
                    SupportColor = {SupportColor}
                    StateActiveTool={[ActiveTool, setActiveTool]}
                    setIsSubOptionName={setIsSubOptionName}
                    SetEnuCanvasTools={SetEnuCanvasTools}
                    handleColorClick={handlerColorClick}
                    handlerActiveTool={handlerActiveTool}
                    setMemoInfo={setMemoInfo}
                />

                <div 
                    id="Canvas_box"
                    onDragOver={(e)=>e.preventDefault()}
                    onDrop={(e)=>{
                        e.preventDefault();

                        const file = e.dataTransfer.files[0];
                        if(!['png', 'jpg'].includes(file.name.split('.').pop().toLowerCase())){
                            alert('이미지 파일만 업로드 가능합니다.');
                            return;
                        }

                        const reader = new FileReader();
                        reader.onload = (event) => {
                            const base64Image = event.target.result;
                            console.log('CHAN- 이미지 이미지@@  ');
                            console.log('Base64 Image:', base64Image);
                        };

                        reader.readAsDataURL(file);
                    }}
                >
                </div>
            </CanvasBody>

            {isSubOptionName === 'Memo' &&
                <BgPopup>
                    <MemoInputModal 
                        onClick={(e)=>e.stopPropagation()}
                        MemoInfo={MemoInfo}
                    >
                        <div className='MemoHeader'>
                            <span>Memo</span>
                            <div className='ColorChips'>
                                {Object.entries(SupportColor.Border).map(([key, value]) => {
                                    return(
                                        <div 
                                            key={key} 
                                            className={MemoInfo.backgroundColor === value ?'selectedColor' : ''} 
                                            style={{backgroundColor: `${value}`}}
                                            onClick={(e)=> {
                                                const isWhiteColor = ['Black', 'Blue', 'Red'].includes(key);
                                                setMemoInfo(prev => ({
                                                    ...prev, 
                                                    backgroundColor: value,
                                                    color: isWhiteColor ? "#FFFFFF" : "#2B2B2B"
                                                }))
                                            }}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                        <textarea
                            value={MemoInfo.text}
                            style={{color: MemoInfo.color}}
                            onChange={(e)=> setMemoInfo(prev => ({...prev, text: e.target.value}))}
                        />
                        <ModalBottomBox>
                            <BtnSecondary label='취소' onClick={(e)=>setIsSubOptionName('')}/>
                            <BtnPrimary label='저장' onClick={(e)=>{
                                enuSpace.CanvasRequest("EditText", JSON.stringify(MemoInfo));
                                
                                setIsSubOptionName('');
                                setMemoInfo({
                                    systemID: '',
                                    text: '',
                                    color: '#2B2B2B',
                                    backgroundColor: SupportColor.Border.White,
                                })
                            }}/>
                        </ModalBottomBox>
                    </MemoInputModal>
                </BgPopup>
            }
        </EnuCanvasContent>
    )
}

const EnuCanvasContent = styled.div`
    position: relative;
    height: 100vh;
`;

const CanvasBody = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 90px);
    
    canvas:focus-visible{
        outline: none;
    }

    #Canvas_box{
        position:relative;
        width: calc(100% - 56px);
        height: 100%;
        background-color: ${({theme}) => theme.base.background_color_PaleGray};
        overflow: hidden;
    }
`;

const MemoInputModal = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    height: auto;
    padding: 16px;
    border-radius: 0.3rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;

    .MemoHeader{
        display: flex;
        justify-content: space-between;
        align-items: bottom;

        span{
            font-size: 1.3rem;
            color: ${({theme}) => theme.base.text_color_Dark};
        }

        .ColorChips{
            display: flex;
            gap: 8px;
            
            div{
                position: relative;
                width: 24px;
                height: 24px;
                border-radius: 0.2rem;
                border: 1px solid ${({theme}) => theme.base.border_color_Gray};
                box-sizing: border-box;
                cursor: pointer;

                &.selectedColor{
                    outline: 4px solid ${({theme}) => theme.base.border_color_Gray};
                }

                &:hover{
                    outline: 4px solid ${({theme}) => theme.base.border_color_LightGray};
                }
            }
        }
    }

    textarea {
        margin-top: 8px;
        height: 120px;
        width: 100%;
        font-size: 1rem;
        line-height: 1.8rem;
        resize: none;
        border-radius: 6px;
        border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
        background-color: ${({MemoInfo}) => MemoInfo.backgroundColor};

        &:focus {
            outline: none;
            border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
        }

        &::placeholder{
            color: ${({ theme }) => theme.base.font_color_Gray};
        }

        &:disabled {
            border-color: ${({ theme }) => theme.base.border_color_LightGray};
            background-color:  ${({ theme }) => theme.base.background_color_PaleGray};
        }
    }
`;