import { useState } from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import {RadioComponent} from "components/atom/RadioContent";
import InputComponent from "components/atom/InputComponent";
import SelectList from "components/atom/SelectList";
import ColorComponent from "components/molecule/ColorComponent";
import FontFamily from 'components/common/FontFamily';
import { GroupName, ToggleRect } from 'components/css/common';

import styled from "styled-components";
import { MdStart, MdFormatItalic } from "react-icons/md";

export default function TrendTickInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        tick_font_family: NodeTickFontFamily,
        tick_font_style: NodeTickFontStyle,
        tick_visible: NodeTickVisible,
        tick_rotation: NodeTickRotation,
        tick_length: NodeTickLength,
        tick_count: NodeTickCount,
        tick_axis_gap: NodeTickAxisGap,
        tick_text_gap: NodeTickTextGap,
        type: NodeType,
        tick_type: NodeTickType,
        tick_format: NodeTickFormat,
        tick_position: NodeTickPosition,
        tick_font_weight: NodeTickFontWeight,
        tick_font_size: NodeTickFontSize,
    } = _data;

    const [fontClass] = useState(new FontFamily());

    const XTickPositionData = { 'tick_position_up': <MdStart style={{"transform":"rotate(-90deg)"}}/>, 'tick_position_down': <MdStart style={{"transform":"rotate(90deg)"}}/> }
    const YTickPositionData = { 'tick_position_left': <MdStart style={{ "transform": "rotate(180deg)" }} />, 'tick_position_right': <MdStart style={{ "transform": "rotate(0deg)" }} /> }
    const TickFormatList = ["number", "yyyy-MM-dd", "yyyy-MM-dd hh:mm", "yy-MM-dd", "yy-MM-dd hh:mm", "yyyy/MM/dd", "yy/MM/dd","MM/dd", "hh:mm", "hh:mm:ss", "mm:ss", "ss.fff"]

    const ChangeEvent = (attributeName, _attributeValue) =>{
        const attributeValue = _attributeValue.replace('tick_position_', '');
        _ChangeEvent([{attributeName, attributeValue}])
    }

    const commonProps2 = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return (
        <PropertiesContent 
            _Title="tick" 
            _Toggle={
                <BtnToggle _toggleID="tick-visible" _isToggleValue={NodeTickVisible} _disabled={_disabled} _IsToggle={ChangeEvent}/>
            } 
            _ToggleValue={!['0', 'false', undefined].includes(NodeTickVisible)}
        >
            <div>
                <CustomInputComponent label='rotate' attributeName='tick-rotation' valueType='number' labelWidth='74px' value={NodeTickRotation} {...commonProps2}/>
            </div>
            <div>
                <CustomInputComponent label='length' attributeName='tick-length' valueType='number' labelWidth='74px' value={NodeTickLength} {...commonProps2}/>
            </div>
            <div>
                <CustomInputComponent label='count' attributeName='tick-count' valueType='number' labelWidth='74px' value={NodeTickCount} {...commonProps2}/>
            </div>
            <div>
                <CustomInputComponent label='axis-gap' attributeName='tick-axis-gap' valueType='number' labelWidth='74px' value={NodeTickAxisGap} {...commonProps2}/>
            </div>
            <div>
                <CustomInputComponent label='text-gap' attributeName='tick-text-gap' valueType='number' labelWidth='74px' value={NodeTickTextGap} {...commonProps2}/>
            </div>
            <div>
                <GroupName width={60}>type</GroupName>
                <SelectList attributeName='tick-type' dataList={["number", "timeseries"]} selectedValue={NodeTickType} {...commonProps2} />
            </div>
            {( ['axis-realtime-y', 'axis-realtime-x'].includes(NodeType) && NodeTickType === 'number') &&
                <div>
                    <GroupName width={60}>format</GroupName>
                    <RadioComponent
                        attributeName='tick-format'
                        dataList={{ number: "number", "engineering unit": "eng unit", }} 
                        selectedValue={NodeTickFormat}
                        disabled={_disabled}
                        changeEvent={ChangeEvent}
                    />
                </div>
            }
            {(NodeType === 'axis-realtime-x' && NodeTickType === 'timeseries') &&
                <div>
                    <GroupName width={60}>format</GroupName>
                    <SelectList attributeName='tick-format' dataList={TickFormatList} selectedValue={NodeTickFormat} {...commonProps2} />
                </div>
            }
            {['text'].includes(NodeTickType) &&
                <div>
                    <CustomInputComponent label='format' attributeName='tick-format' labelWidth='74px' value={NodeTickFormat} {...commonProps2}/>
                </div>
            }
            {['axis-histogram-x', 'axis-realtime-x'].includes(NodeType) &&
                <div>
                    <GroupName width={76}>position</GroupName>
                    <RadioComponent
                        attributeName='tick-position'
                        dataList={XTickPositionData} 
                        selectedValue={NodeTickPosition === 'up' ? 'tick_position_up' : 'tick_position_down'}
                        disabled={_disabled}
                        changeEvent={ChangeEvent}
                    />
                </div>
            }
            {['axis-histogram-y', 'axis-realtime-y'].includes(NodeType)&&
                <div>
                    <GroupName width={76}>position</GroupName>
                    <RadioComponent
                        attributeName='tick-position'
                        dataList={YTickPositionData}
                        selectedValue={NodeTickPosition === 'left' ? 'tick_position_left' : 'tick_position_right'}
                        disabled={_disabled}
                        changeEvent={ChangeEvent}
                    />
                </div>
            }
            <div className="margin-top-div">
                <SelectList
                    attributeName="tick-font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeTickFontFamily}
                    {...commonProps2}
                />
            </div>
            <div>
                <SelectList
                    attributeName="tick-font-weight"
                    dataList={fontClass.GetFontWeight(NodeTickFontFamily)}
                    selectedValue={fontClass.CheckFontWeight(NodeTickFontFamily, NodeTickFontWeight)}
                    {...commonProps2}
                />
                <InputComponent
                    label={<img src="/img/properties/icon_rotate.png" alt="rotate" />}
                    attributeName="tick-font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeTickFontSize}
                    {...commonProps2}
                />
                <ToggleRect 
                    disabled={_disabled}
                    onClick={(e)=>{
                        if (_disabled) return;
                        const attributeValue = Array.isArray(NodeTickFontStyle) ? 'italic' : ['normal','0'].includes(NodeTickFontStyle) ? 'italic' : 'normal';
                        _ChangeEvent([{attributeName: 'tick_font_style', attributeValue}]);
                    }}
                >
                    <MdFormatItalic className={Array.isArray(NodeTickFontStyle) ? '' :  ['normal', '0'].includes(NodeTickFontStyle) ? '' : 'active'}/>
                </ToggleRect>
            </div>
            <div>
                <ColorComponent label="font" attributeName="tick-font-color" isGradient={false} isVisibility={false} {...commonProps2} />
            </div>
        </PropertiesContent>
    );
}

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;