import {useState, useEffect} from "react";

import {
    EnuIconPicture,
    EnuIconPictureFail,
    EnuIconStruct,
    EnuIconStructFail,
    EnuIconLibrary,
    EnuIconLibraryFail,
    EnuIconGlobal,
    EnuIconGlobalFail,
    EnuIconSymbol,
    EnuIconSymbolFail,
    EnuIconDB,
    EnuIconDBEmpty,
    EnuIconDBFail,
    EnuIconFile,
    EnuIconFileFail,
    EnuIconFolderOpen,
    EnuIconFolderClose,
    EnuIconLua,
    EnuIconLuaFail,
} from "components/common/EnuSpaceIcon"

import { MdCalendarViewMonth, MdClear, MdCategory } from "react-icons/md";
import PropTypes from 'prop-types';
import styled from "styled-components";

function TreeRowContent({_checkOpenTree, _id, _title, _type, _path, children, _onClickRow, _onDoubleClick, _status = true , _isShow = true, ...props}){
    const [initInput, setInitInput] = useState({originalValue:_title, value: _title});
    const [isShow, setIsShow] = useState(_isShow);

    useEffect(()=>{
        if( _checkOpenTree !== undefined && _checkOpenTree[0] !== '' && _checkOpenTree[0]=== _title){
            if(isShow === false){
                setIsShow(true);
            }
            _checkOpenTree[1]('');
        }
    }, [_checkOpenTree, _title, isShow]);

    useEffect(() => {
        if (_type === 'sqlite') {
          setIsShow(false);
        }
    }, [_type]);

    useEffect(()=>{
        setInitInput({originalValue:_title ,value: _title});
    }, [_title]);

    const isShowTree = (e) => {
        const {type, path, value, status} = e.currentTarget.dataset;
        if (e.defaultPrevented) return;

        const typeActions = {
            file: () => props._ShowCanvas(path, value, status === "true"),
            symbol: () => props._ShowCanvas(path, value, status === "true"),
            'global-lua': () => props._ShowGlobalLua(path, value),
            default: () => setIsShow(!isShow)
        };

        (typeActions[type] || typeActions.default)();
    }

    const ClickRow = (e) =>_onClickRow && _onClickRow(e);

    const FrontArrowIcon = () => {
        return (
            <FrontIconContent onClick={isShowTree}>
                {isShow ? <EnuIconFolderOpen /> : <EnuIconFolderClose />}
            </FrontIconContent>
        )
    };
    
    const SetFrontIcon = () =>{
        const iconMap = {
            "DB":  _status ? <EnuIconDB />: <EnuIconDBFail />,
            "sqlite" :  _status ? <EnuIconDB /> : <EnuIconDBFail />,
            "DB_empty" :  _status ? <EnuIconDBEmpty /> : <EnuIconDBFail />,
            "DATASET" :  _status ? <EnuIconDB /> : <EnuIconDBFail />,
            "struct" :  _status ? <EnuIconStruct/> : <EnuIconStructFail />,
            "struct-DB" :  _status ? <EnuIconStruct/> : <EnuIconStructFail />,
            "attribute" :  _status ? <EnuIconFile /> : <EnuIconFileFail />,
            "attribute-DB" :  _status ? <EnuIconFile />  : <EnuIconFileFail />,
            "Table" :  _status ? <MdCalendarViewMonth style={{ color: 'royalblue'}}/> : <MdClear style={{'color':'mediumvioletred'}}/>,
            "symbol" :  _status ? <EnuIconSymbol />: <EnuIconSymbolFail />,
            "document" :  _status ?<EnuIconFile />  : <EnuIconFileFail />,
            "a.d": {
                "global":  _status ? <EnuIconGlobal />: <EnuIconGlobalFail />,
                "library\\hmi":  _status ? <EnuIconLibrary />: <EnuIconLibraryFail />,
            }, 
            "file": {
                "picture": _status ? <EnuIconPicture />: <EnuIconPictureFail />,
            },
            "global-lua":  _status ? <EnuIconLua/>: <EnuIconLuaFail/>,
            "enu-canvas":  _status ? <MdCategory style={{ 'color': 'steelblue' }}/>: <MdCategory style={{ 'color': 'mediumvioletred'}}/>,
        };

        const icon = iconMap[_type];

        if (icon) {
            let displayIcon = icon;

            if (['a.d', 'file'].includes(_type))
                displayIcon = icon[_path] || null;
    
            return (
                <>
                    {["DB", "sqlite", "a.d"].includes(_type) ? FrontArrowIcon() : null}
                    <FrontIconContent>
                        {displayIcon}
                    </FrontIconContent>
                </>
            );
        }
        
        return (
            <>{FrontArrowIcon()}</>
        )
    }

    const commonProps = {
        'data-type': _type,
        'data-path': _path,
        'data-value': initInput['value'],
    };

    return(
        <>
            <TreeRow id={_id && _id} className={_type} data-status={_status} onClick={ClickRow} tabIndex="0" {...commonProps}
                onDoubleClick={(e) => {
                    isShowTree(e);
                    _onDoubleClick && _onDoubleClick(e)
                }} >
                {SetFrontIcon()}
                <TitleText title={initInput['value']} {...commonProps}>{initInput['value']}</TitleText>
            </TreeRow>
            {children && isShow ? children : null}
        </>
    )
}
export default TreeRowContent;

TreeRowContent.propTypes = {
    _title: PropTypes.string,   // TreeRow Title 
    _type : PropTypes.string,   // 데이터 타입 BackIconContent버튼 활성화에 영향을줌
    _path : PropTypes.string,   // canvas 출력시 이용하는 path 정보
}

// 스타일-----------------------------------------------------
const TreeRow = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
    box-sizing: border-box;

    &.ProjectName >input{
        font-weight: 500;
    }

    &:hover, &:focus{
        background-color: ${({theme}) => theme.base.background_color_LightBlue};
        span{ display: block; }
    }
`;

const TitleText = styled.span`
    height: auto;
    padding: 4px 2px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
`;

const FrontIconContent = styled.span`
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding-right: 0;
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    img, svg{
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
    }
`;