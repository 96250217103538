import PropertiesContent from "components/atom/PropertiesContent";
import Checkbox from "components/atom/Checkbox";

function RadarGridInputList({_data, ...props}){
    const ChangeVisible = (e) => {
        const attributeName = e.target.name;
        const isVisible = _data[attributeName.replace(/-/g, '_')] === '1';
        props._ChangeEvent([{ attributeName, attributeValue: isVisible ? 'hidden' : 'visible' }]);
    };
    
    return(
        <PropertiesContent _Title="Grid Gui">
            <div style={{flexWrap: 'wrap'}}>
                <Checkbox _id='axis-visible' checked={_data.axis_visible === '1' ? true : false} onChange={ChangeVisible} _disabled={props._disabled}>axis</Checkbox>
                <Checkbox _id='level-visible' checked={_data.level_visible === '1' ? true : false} onChange={ChangeVisible} _disabled={props._disabled}>level</Checkbox>
            </div>
        </PropertiesContent>
    )
}

export default RadarGridInputList;

//스타일-------------------------