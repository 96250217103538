import { useState } from 'react'

import {BtnPrimary} from "components/atom/Button";
import {GuestLogin} from 'hooks/useMember'
import Modal from 'components/modal_new/Modal'
import {BgPopup, ModalBottomBox, InputContent, WarningMsg} from 'components/css/common'

import styled from "styled-components"
import { useNavigate } from 'react-router-dom';

export default function GuestNameInput({value, onClickEvent}){
    const [msg, setMsg] = useState('');
    const [guestName, setGuestName] = useState(value);
    const navigate = useNavigate();

    const subMitEvent = (e) => {
        if (guestName === '') {
            setMsg('Guest Name을 입력해주세요.');
            return;
        }

        // eslint-disable-next-line
        const Regex = /^[a-zA-Z0-9가-힣ㄱ-ㅎㅏ-ㅣ]{2,6}$/;
        if (!Regex.test(guestName)) {
            setMsg('Guest Name은 2~6자리의 영문, 숫자, 한글만 입력 가능합니다. (공백 불가)');
            return;
        }

        GuestLogin(guestName)
            .then(res => {
                const { result, msg } = res.data;
                if(result === 'Success'){
                    const {
                        Authorization,
                        MEMBER_SYSTEMID,
                        NICKNAME,
                        RefreshToken
                    } = JSON.parse(msg);

                    sessionStorage.setItem('systemId', MEMBER_SYSTEMID);
                    sessionStorage.setItem('Authorization', Authorization);
                    sessionStorage.setItem('refreshtoken', RefreshToken);
                    sessionStorage.setItem('nickname', NICKNAME);

                    onClickEvent && onClickEvent(NICKNAME);
                }else{
                    setMsg('Guest Name 인증에 실패 하였습니다.');
                }
            })
    }

    return (
        <InputModal>
            <BgPopup onClick={(e) => navigate(-1)} />
            <Modal _x={document.getElementById('root').offsetWidth / 2 - 218} _y={document.getElementById('root').offsetHeight / 2 - 300} _height={180} _title={'Guest Name'} _enableResizing={false} _onClick={null} _closeModal={(e) => navigate(-1)} >
                <FlexContent>
                    <InputContent
                        name='GuestName'
                        value={guestName}
                        onChange={(e) => { setMsg(''); setGuestName(e.target.value) }}
                        onKeyDown={(e) => {if (e.key === 'Enter') subMitEvent(e);}}
                        placeholder={'Guest Name'} />
                </FlexContent>
                <ModalBottomBox>
                    {
                        msg && <WarningMsg>{msg}</WarningMsg>
                    }
                    <BtnPrimary label="Ok" onClick={subMitEvent} />
                </ModalBottomBox>
            </Modal>
        </InputModal>
    )
}

const InputModal = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 12;
`;

const FlexContent = styled.div`
    display: flex;
    align-items: baseline;

    &>span{
        margin-left: 8px;
    }
`;
