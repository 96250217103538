import { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import TreeRowContent from 'components/atom/TreeRowContent';
import {BtnText} from "components/atom/Button";
import ExplorerTreeMenu from 'components/molecule/ExplorerTreeMenu';
import {TreeUlContent} from "components/css/common";
import {GetRealTarget} from 'components/Utility'
import useModalPopup from 'hooks/useModalPopup';
import useProjectData from 'hooks/useProjectData';
import useRightMenu from "hooks/useRightMenu";
import {
    EnuIconPicture,
    EnuIconLibrary,
    EnuIconGlobal,
} from "components/common/EnuSpaceIcon"
import { insertCanvasView, viewCodeEditor} from 'reducers/projectDataReducer';

import styled from "styled-components";
import { MdSearch, MdClose } from "react-icons/md";

const GLOBAL_LUA_RES_TYPE = {
    RUN: 1,
    SAVE: 2,
    INFO: 3,
    DELETE: 4,
    ADD: 5,
    CHANGE_NAME: 6,
}

export default function ProjectExplorerTree ({enuSpace, TreeFilter, ChangeFilter, SearchState}){
    const dispatch = useDispatch();
    const {AddModal, UpdateModalData} = useModalPopup();
    const {DeleteNodeData, SetTreeData, UpdateCurrentView, UpdateArrayActivePictureURL, SetNodeData} = useProjectData();
    const {userId, projectId, projectName} = useSelector(state => state.projectData.BASE_DATA);
    const TreeData = useSelector(state => state.projectData.TREE_DATA);
    const CanvasViewData = useSelector(state => state.projectData.CANVAS_VIEW);
    const FirstModalData = useSelector((state) => state.modalPopup[state.modalPopup.length - 1]);

    const RightMenuInfo = useSelector(state => {
        if (state.rightMenu.type === 'explorerTree')
            return state.rightMenu;
        return null;
    }, shallowEqual);
    const {ShowRightMenu, CloseRightMenu} = useRightMenu();
    const [isOpenTree, setIsOpenTree] = useState('');
    const [Search, setSearch] = SearchState;

    window.OpenTreeAfterCreate = (_OpenTarget) => setIsOpenTree(_OpenTarget);

    const ShowCanvas = (_path, _fileName, _status = true) =>{
        const insertValue = _path+'\\'+ _fileName;
        const temp = JSON.parse(JSON.stringify(CanvasViewData));

        //Node정보(화면 right 영역)를 비운다.
        DeleteNodeData();

        for(let canvasId of Object.keys(temp['activeCanvas'])){
            if(temp['activeCanvas'][canvasId] === insertValue){
                const mode = _status ? 'design' : 'code';
                temp['currentView'] = canvasId;
                UpdateCurrentView({canvasId, mode});
                UpdateArrayActivePictureURL(CanvasViewData['activeCanvas'][canvasId]);
                return;
            }
        }
        _status ? dispatch(insertCanvasView(insertValue)) : dispatch(viewCodeEditor(insertValue));
    }

    const ShowGlobalLua = (_path, _fileName) =>{
        enuSpace.GetGlobalLua(`${_path}\\${_fileName}`);
    }

    //FIXME: Rename 처리 방향성 결정후 사용여부 결정
    const KeyDownEvent = (_Rename, _FileType, _OriginalName) => enuSpace.RenameSvg(_Rename, _OriginalName, _FileType);

    const HandleResponse = (_msg) =>{
        const resData = JSON.parse(_msg);
        if(resData['type'] === 'success'){
            SetTreeData(JSON.parse(enuSpace.GetProjectTree()));
        }else if(resData['type'] === 'failed'){
            
        }
    }

    window.Re_AddSymbol = HandleResponse;
    window.Re_DeleteSymbol = HandleResponse;
    window.Re_CreateSvg = HandleResponse;
    window.Re_DeleteSvg = HandleResponse;

    window.Re_GlobalLua = (_type, _msg) => {
        switch(_type){
            case GLOBAL_LUA_RES_TYPE.RUN:{
                const { type: resType, data: resData } = JSON.parse(_msg);
                const { id } = FirstModalData;
                const newData = { msg: resData || resType };
                UpdateModalData(id, newData);
                break;
            }
            case GLOBAL_LUA_RES_TYPE.SAVE:{
                HandleResponse(_msg);
                enuSpace.UpdateEnup(userId, projectId, 'enup', `${projectName}.enup`, '');
                break;
            }
            case GLOBAL_LUA_RES_TYPE.INFO:{
                const { data: script, path } = JSON.parse(_msg).data;
                const pathSegments = path.split('\\');
                AddModal(`${path}`, `${pathSegments[pathSegments.length - 1]}`, 'LuaEdit', { path, script });
                break;
            }
            case GLOBAL_LUA_RES_TYPE.DELETE:
            case GLOBAL_LUA_RES_TYPE.ADD:
            case GLOBAL_LUA_RES_TYPE.CHANGE_NAME: {
                HandleResponse(_msg);
                break;
            }
            default:
                throw new Error(`Unhandled GlobalLua action type: ${_type}`);
        }
    }

    window.Re_DeleteVariable_tree = (_msg) => {
        const resData = JSON.parse(_msg);
        if(resData['type'] === 'success'){
            SetTreeData(JSON.parse(enuSpace.GetProjectTree()));
        }else if(resData['type'] === 'failed'){
        }
    }

    const EventDoubleClick = (e) =>{
        const Target = GetRealTarget(e.target, 'DIV');
        if(Target.dataset.type === 'attribute-DB')
            ShowModalGlobalVariableDB(Target);

        ClickTreeRow(e);
    }

    const ShowModalGlobalVariableDB = (_Target) => {
        const {path, value } = _Target.dataset;
        const table_ID = _Target.id;
        const targetPath = `${path}\\${value}`;
        AddModal(`Show\\${targetPath}`, `Show Global Variable (${value})`, 'ShowGlobalVariableDB', { targetPath, table_ID});
    }

    const GetTargetRowElementData = (e) =>{
        const targetElement = GetRealTarget(e.target, 'DIV');
        if(targetElement.nodeName !== 'DIV')
            return;

        let id = targetElement.id;
        let path = '';
        const {path: TargetPath, value: TargetValue, type: TargetType} = targetElement.dataset;
        if(TargetPath !== undefined){
            path =  TargetPath.split('\\');
            path.push(TargetValue);

            if(id === '')
                id = TargetValue;
        }

        return {
            id,
            object_name: id !== '' ? 'svg' : '',
            from : 'ExplorerTree',
            path,
            type : TargetType
        }
    }

    const ClickTreeRow = (e) =>{
        DeleteNodeData();
        const {id, from, object_name, path, type}  = GetTargetRowElementData(e);
        if( ['DB', 'add'].includes(type))
            return;

        if(['file', 'symbol'].includes(type)){
            const URL = path.join('\\')
            UpdateArrayActivePictureURL(`${URL}`);
            enuSpace.EventHandler(URL,"GETNODEDATA_BY_ID", JSON.stringify({id}), "TreeClick");
        }else{
            SetNodeData({id, from, object_name, path, type});
        }
    }

    const MouseRightClick = (e) =>{
        e.preventDefault();
        const target = GetRealTarget(e.target, 'DIV');
        const rowId = target.id;
        const rowType = target.dataset.type;
        const rowPath = target.dataset.path;
        const rowValue = target.value !== undefined ? target.value : target.dataset.value;
        const targetRowElementInfo = GetTargetRowElementData(e);

        // 타입이 없을시 메뉴를 숨긴다.
        if(rowType === undefined || rowValue === 'library' || rowPath === 'library\\logic'){
            CloseRightMenu();
            return;
        }
        
        ShowRightMenu({'type':'explorerTree', 'top':e.pageY, 'left':e.pageX, 'data':{rowId, rowType, rowPath, rowValue, targetRowElementInfo}});
    }

    const RenderTree = (menu) => {
        return menu.map((item) => {
            const {title, type, child, path, id, status} = item;

            // FIXME: 구현 안된 기능들은 아래와 같이 처리
            if(['externalFunction', 'style', 'task', 'logic'].includes(title))
                return null;
            if(['struct-DB'].includes(type))
                return null;

            if(!Search.IsUse)
                if(TreeFilter !== 'all' && path === undefined && !TreeFilter.includes(title)) return null;

            // 검색어 필터링
            if (Search.Keyword && !title.toLowerCase().includes(Search.Keyword.toLowerCase())) {
                if (child) {
                    const filteredChild = RenderTree(child, Search.Keyword);
                    if (!filteredChild.some(Boolean)) return null; // 자식 항목이 없으면 해당 항목도 숨김
                } else {
                    return null;
                }
            }
            
            let TreeRowType = type;
            if(type === 'DB' && !child )
                TreeRowType = 'DB_empty';

            const key = path !== undefined ? `${path}\\${title}` : title;

            return(
                <li key={key}>
                    <TreeRowContent 
                        _id={id} 
                        _title={title} 
                        _type={type && TreeRowType} 
                        _path={path} 
                        _checkOpenTree={[isOpenTree, setIsOpenTree]} 
                        _onClickRow={ClickTreeRow} 
                        _ShowCanvas={ShowCanvas} 
                        _ShowGlobalLua={ShowGlobalLua} 
                        _KeyDownEvent={KeyDownEvent}
                        _onDoubleClick={EventDoubleClick} 
                        _status={status === undefined || status === "success"}
                    >
                        {child && <TreeUlContent>{(RenderTree(child))}</TreeUlContent>}
                    </TreeRowContent>
                </li>
            )
        })
    }

    const FilterItem = ({ title, value, icon }) => (
        <div title={title} onClick={() => ChangeFilter(value)}>
          <input
            type="radio"
            name="treeFilter"
            value={value}
            id={`treeFilter_${value}`}
            onChange={() => ''}
            checked={value === TreeFilter}
          />
          <label htmlFor={`treeFilter_${value}`}>{icon}</label>
        </div>
      );

    return(
        <TreeContent onContextMenu={MouseRightClick} onClick={(e) => { e.preventDefault(); }}>
            <FilterContent>
                {Search.IsUse
                    ?
                    <div>
                        <MdSearch className='Icons SearchIcon' />
                        <input 
                            className='SearchInput'
                            name='searchKeyword'
                            type='text' 
                            placeholder='Keyword...' 
                            value={Search.Keyword}
                            onChange={(e)=>setSearch(perv=>({...perv, Keyword: e.target.value}))}
                        />
                        <MdClose className='Icons CloseIcon' title="Close" onClick={(e=>setSearch({IsUse: false, Keyword:''}))}/>
                    </div>
                    :
                    <>
                        <div>
                            <FilterItem title='All' value='all' icon='A' />
                            <FilterItem title='Global' value='global' icon={<EnuIconGlobal />} />
                            <FilterItem title='Library' value='library' icon={<EnuIconLibrary />} />
                            <FilterItem title='Picture' value='picture' icon={<EnuIconPicture />} />
                        </div>
                        <BtnText label={<MdSearch />} type='sm' title='Search' onClick={() => setSearch(prev => ({ ...prev, IsUse: true }))} />
                    </>
                }
            </FilterContent>
            <div className='treeBoy'>
                <TreeRowContent _title={projectName || "project"} _type="ProjectName" _onClickRow={(e) => {
                    e.stopPropagation();
                    DeleteNodeData();
                }}>
                    <TreeUlContent>
                        {TreeData.length > 0 && RenderTree(TreeData)}
                    </TreeUlContent>
                </TreeRowContent>

                {RightMenuInfo?.type === 'explorerTree' &&
                    <ExplorerTreeMenu
                        enuSpace={enuSpace}
                        setIsOpenTree={setIsOpenTree}
                        rightMenuInfo={RightMenuInfo}
                    />
                }
            </div>
        </TreeContent>
    )
}

// 스타일-----------------------------------------------------
const TreeContent = styled.div`
    height: 100%;
    overflow-y: auto;

    .treeBoy{
        padding-left: 8px;
    }
`

const FilterContent = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    gap: 4px;
    padding:0 8px;
    height: 40px;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
    background-color: #FFF;
    z-index: 1;
    user-select: none;

    &>div{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;

        &>div{
            label{
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                height: 24px;
                width: 28px;
                font-size: .9rem;
                color: ${({theme}) => theme.base.font_color_DarkGray};
                border-radius: 4px;
                box-sizing: border-box;
                cursor: pointer;
            }

            input{
                display: none;

                &:hover + label{
                    color: ${({theme}) => theme.base.font_color_DarkBlue};
                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                }
                
                &:checked + label{
                    font-weight: bold;
                    color: ${({theme}) => theme.base.font_color_DarkBlue};
                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                    border: 1px solid ${({theme}) => theme.base.border_color_Blue};
                }
            }
        }
    }

    .Icons{
        padding: 4px;
        height: 24px;
        width: 24px;
        box-sizing: border-box;
    }

    .SearchIcon{
        color: ${({theme}) => theme.base.font_color_DarkBlue};
    }

    .CloseIcon{
        cursor: pointer;

        &:hover{
            color: ${({theme}) => theme.base.font_color_Red};
        }
    }

    .SearchInput{
        position: relative;
        flex-grow: 1;
        height: 100%;
        border: none;
        box-sizing: border-box;

        &:focus{
            outline: none;
        } 
    }
`;