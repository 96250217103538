import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import { GroupName } from 'components/css/common';

import styled from "styled-components";

export default function RadarSeriesInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        name: NodeName,
        values: NodeValues,
        stroke_width: NodeStrokeWidth,
    } =_data;

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title="Series">
            <div>
                <CustomInputComponent label='name' labelWidth='50px' value={NodeName} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='values' labelWidth='50px' value={NodeValues} {...commonProps}/>
            </div>
            <div>
                <ColorComponent label="fill" isGradient={false} isVisibility={false} {...commonProps}/>
            </div>
            <div>
                <ColorComponent label="stroke" isGradient={false} isVisibility={false} {...commonProps}/>
            </div>
            <div>
                <CustomGroupName >stroke-width</CustomGroupName>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='stroke-width' 
                    valueType='number' 
                    labelWidth='24px' 
                    value={NodeStrokeWidth} 
                    {...commonProps} 
                />
            </div>
        </PropertiesContent>
    )
}

//스타일-------------------------
const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;

const CustomGroupName = styled(GroupName)`
    width: 100%;
    max-width: 208px;
    min-width: 120px;
`;