import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";
import BtnToggle from "components/atom/BtnToggle";
import Checkbox from "components/atom/Checkbox";
import ColorComponent from "components/molecule/ColorComponent";
import { GroupName } from 'components/css/common';

export default function GridInputList({_data, _disabled, _ChangeEvent}){
    const {
        id: NodeId,
        grid: NodeGrid,
        x_offset: NodeXOffset,
        y_offset: NodeYOffset,
        grid_dash: NodeGridDash,
    } = _data;
    
    const commonProps = {
        changeEvent: _ChangeEvent,
        disabled: _disabled,
    };

    const offsetCommonProps = {
        valueType: 'number',
        labelWidth: '24px',
        ...commonProps
    }

    return(
        <PropertiesContent _Title="Grid" 
            _Toggle={
                <BtnToggle 
                    _toggleID="grid" 
                    _isToggleValue={NodeGrid} 
                    _disabled={(!NodeId || _disabled )&& true} 
                    _IsToggle={(attributeName, attributeValue) => _ChangeEvent([{ attributeName, attributeValue }])}
            />} 
            _ToggleValue={!['0', 'false', undefined].includes(NodeGrid)}
        >
            <div>
                <GroupName>offset</GroupName>
                <InputComponent label='X' attributeName='x-offset' value={NodeXOffset} {...offsetCommonProps}/>
                <InputComponent label='Y' attributeName='y-offset' value={NodeYOffset} {...offsetCommonProps}/>
            </div>
            <div>
                <ColorComponent label="stroke" attributeName="grid-color" isGradient={false} isVisibility={false} {...commonProps}/>
            </div>
            <div>
                <Checkbox 
                    _id='grid-dash' 
                    checked={NodeGridDash !== '0'}
                    _disabled={_disabled}
                    onChange={(e)=>{
                        _ChangeEvent([{ attributeName: 'grid-dash', attributeValue: NodeGridDash === '0' ? 'true' : 'false' }])
                    }}
                >
                    <img src="/img/properties/icon_stroke_dasharray.png" alt="stroke_dasharray" /> 
                    <span style={{paddingLeft:'8px'}}>grid-dash</span>
                </Checkbox>
            </div>
        </PropertiesContent>
    )
}