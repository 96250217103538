import * as BABYLON from "@babylonjs/core";
import Base from 'babylon/class/Base';
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";
import { createBoxVertex, UpdateBoxVertexPoint } from "babylon/common/VertexGenerator";

export default class EnuBox extends Base {
    constructor(
        EnuScene,
        name = "box",
        requiresNewMaterial,
        options = {
            width: 2,
            height: 2,
            depth: 2,
            faceColors: [1, 1, 1, 1],
            updatable: true,
        }
    ) {
        const vertexData = createBoxVertex(
            options.width,
            options.height,
            options.depth
        );
        const box = new BABYLON.Mesh(name, EnuScene.scene);

        // box.setPivotPoint(new BABYLON.Vector3(0.5, 0.5, 0));

        vertexData.applyToMesh(box, true);

        box.metadata = {};
        box.metadata.objectType = SUPPORT_OBJECT_TYPE.BOX;

        super(EnuScene, box, requiresNewMaterial);

        this._width = options.width;
        this._height = options.height;
        this._depth = options.depth;
        this.objectType = SUPPORT_OBJECT_TYPE.BOX;

        this._faceColors = options.faceColors;
    }

    UpdateVertexPoint(width, height, depth) {
        //TODO: mesh.setPivotPoint(new BABYLON.Vector3(x, y, z));
        const positions = UpdateBoxVertexPoint(width, height, depth);
        this.originMesh.updateVerticesData("position", positions);
    }

    get width() { return this._width; }
    set width(_width) {
        this._width = _width;
        this.UpdateVertexPoint(_width, this.height, this.depth);
    }

    get height() { return this._height; }
    set height(_height) {
        this._height = _height;
        this.UpdateVertexPoint(this.width, _height, this.depth);
    }

    get depth() {return this._depth; }
    set depth(_depth) {
        this._depth = _depth;
        this.UpdateVertexPoint(this.width, this.height, _depth);
    }

    get faceColors() {return this._faceColors;}
    set faceColors(faceColors) {
        this._faceColors = faceColors;

        const length = 144;
        const values = faceColors; // 원하는 값들

        const colorArr = Array.from(
            { length },
            (_, index) => values[index % values.length]
        );
        this.originMesh.updateVerticesData("color", colorArr);
    }

    getPropertyMap() {
        const basePropertyMap = super.getPropertyMap();
        return {
            ...basePropertyMap,
            height: this.height,
            width: this.width,
            depth: this.depth,
            objectType: this.objectType,
            faceColors: this.faceColors,
        };
    }
}