import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import { GroupName } from 'components/css/common';

import styled from "styled-components";

export default function TrendYAxisInputList({_data, _ChangeEvent, _disabled}){
    const {
        axis_position: NodeAxisPosition,
        decimal_point: NodeDecimalPoint,
        autoscale: NodeAutoscale,
        max: NodeMax,
        min: NodeMin,
        max_padding: NodeMaxPadding,
        min_padding: NodeMinPadding,
        axis_stroke_width: NodeAxisStrokeWidth,
    } = _data;

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return (
        <PropertiesContent _Title="y-axis">
            <div>
                <CustomInputComponent label='position' attributeName='axis-position' valueType='number' labelWidth='140px' value={NodeAxisPosition} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='decimal-point' valueType='number' labelWidth='140px' value={NodeDecimalPoint} {...commonProps}/>
            </div>
            <Div>
                <GroupName width={76}>autoscale</GroupName>
                <BtnToggle 
                    _toggleID="autoscale" 
                    _isToggleValue={NodeAutoscale} 
                    _disabled={_disabled}
                    _IsToggle={(attributeName, attributeValue)=> _ChangeEvent([{attributeName, attributeValue }])} 
                />
            </Div>
            {!['0', 'false'].includes(NodeAutoscale) &&
                <>
                    <div>
                        <CustomInputComponent label='max-padding' valueType='number' labelWidth='140px' value={NodeMaxPadding} {...commonProps}/>
                    </div>
                    <div>
                        <CustomInputComponent label='min-padding' valueType='number' labelWidth='140px' value={NodeMinPadding} {...commonProps}/>
                    </div>
                </>
            }
            <div>
                <CustomInputComponent label='max' valueType='number' labelWidth='140px' value={NodeMax} {...commonProps} />
            </div>
            <div>
                <CustomInputComponent label='min' valueType='number' labelWidth='140px' value={NodeMin} {...commonProps} />
            </div>
            <div>
                <ColorComponent label="stroke" attributeName="axis-stroke"  isGradient={false} isVisibility={false} {...commonProps}/>
            </div>
            <div>
                <CustomGroupName >stroke-width</CustomGroupName>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='axis-stroke-width' 
                    valueType='number' 
                    labelWidth='24px' 
                    value={NodeAxisStrokeWidth} 
                    {...commonProps} 
                />
            </div>
        </PropertiesContent>
    );
}

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;

const Div = styled.div`
    justify-content: space-between;
`;

const CustomGroupName = styled(GroupName)`
    width: 100%;
    max-width: 208px;
    min-width: 120px;
`;