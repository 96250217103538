import { useState, useEffect, useCallback, useContext, useReducer } from 'react'

import { BtnSecondary, BtnPrimary, BtnText } from "components/atom/Button";
import SearchBar from "components/atom/SearchBar"
import ArchiveTree from 'components/molecule/ArchiveTree'
import { RowTypeTableContent, InputContent, ModalBottomBox } from "components/css/common";
import { ARCHIVE_TREE_TYPE, AUTHORITY_TYPE } from 'components/common/EnuSpaceEnum'
import { CreateRepoFolder, DeleteRepoFolderOrFile } from "api/ArchiveApi"
import { useWebSocketFunction } from 'hooks/useWebSocketControl'
import { BaseInfoContext } from 'context/ArchiveContext'
import {
    EnuIconFolderClose,
    EnuIconFile,
} from "components/common/EnuSpaceIcon"

import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { MdHome, MdUpload, MdClose, MdMoreHoriz } from "react-icons/md";

function fileReducer(state, action){
    switch(action.type){
        case 'clear':{
            return {Temp: null, Pool:[]}
        }
        case 'uploadReady':{
            return {...state, Temp: {...action.temp}, Pool:[...action.pool]}
        }
        case 'removePool':{
            const Pool = state.Pool.filter(item => item.name !== action.fileName);
            return {...state, Pool}
        }
        case 'checkedFail':{
            for (const key in state.Temp) {
                if (state.Temp[key].name === action.fileName) {
                    state.Temp[key].state = 'fail';
                    break;
                }
            }
            return {...state}
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`); 
    }
}

const MODAL_TYPE = {
    NEW_FOLDER: 'newFolder',
    UPLOAD: 'upload',
}

export default function ArchiveContent({ ArchivePathInfo, SetArchivePathInfoEvent, MyAccess, IsLinkPage = false }) {
    const navigate = useNavigate();
    const { BaseInfo } = useContext(BaseInfoContext);
    const { ws, send, onMessage, ConnectSock, CloseSock } = useWebSocketFunction(`wss://${document.location.hostname}:${document.location.port}/RepositoryFiles`);

    const [InputData, ] = useState('');
    const [ActiveModalType, setActiveModalType] = useState();
    const [InputInfo, setInputInfo] = useState('');
    const [FileData, setFileData] = useReducer(fileReducer, {Temp: null, Pool:[],})
    const [IsFileProcessModal, setIsFileProcessModal] = useState(false);

    const [BaseUrl, setBaseUrl] = useState('');
    const [TempBlobData, setTempBlobData] = useState([]);         // 서버로부터 전달받은 바이러리 데이터를 다운로드 처리전 임시로 보관하는 State

    useEffect(() => {
        setBaseUrl(`/archive/${IsLinkPage ? 'link' : 'Settings'}?archive=${BaseInfo.pageId}`)
    }, [IsLinkPage, BaseInfo.pageId]);

    const SocketUpload = useCallback((fileInfo) => {
        const { name: FILE_NAME, size: FILE_SIZE } = fileInfo;
        send({
            MESSAGE: 'UploadRepoFile',
            DATA: {
                ARCHIVE_SYSTEMID: BaseInfo.pageId,
                MEMBER_SYSTEMID: BaseInfo.userId,
                FILE_NAME,
                FILE_SIZE,
                DESCRIPTION: '',
                PARENT_REPO_ID: ArchivePathInfo.CurrentIDX,
            }
        });

        const reader = new FileReader();
        reader.onload = function (event) {
            const data = event.target.result;
            ws?.send(data);
        };
        reader.readAsArrayBuffer(fileInfo);
    }, [BaseInfo.pageId, BaseInfo.userId, ArchivePathInfo.CurrentIDX, ws, send]);

    useEffect(() => {
        if (ws === null || ws?.readyState !== WebSocket.OPEN) return;
        onMessage(msg => {
            const { data } = msg;

            if (typeof (data) === "string") {
                const { TYPE, Percent, Result, File_Name, Msg } = JSON.parse(data);

                if (TYPE === 'Res_UploadRepoFile') {
                    // 파일 업로드
                    if (Result === "Processing") {
                        document.getElementById(File_Name).value = Percent;
                        return;
                    }

                    setFileData({type:'removePool', fileName:File_Name})

                    if (Result === 'Fail') {
                        setFileData({type:'checkedFail', fileName:File_Name})
                        const targetElement = document.getElementById(File_Name);
                        const parentElement = targetElement.parentNode;
                        targetElement.remove();
                        const spanElement = document.createElement('span');
                        spanElement.textContent = Msg;
                        parentElement.appendChild(spanElement);
                    }

                } else if (TYPE === 'Res_DownloadRepoFile') {
                    // 파일다운로드
                    if (Result === "Success") {
                        setTempBlobData(prev => {
                            return prev.map(item => {
                                const { FILE_NAME } = item;
                                if (`${FILE_NAME}` === File_Name) {
                                    return { ...item, STATE: 'Completed' };
                                } else
                                    return item;
                            })
                        })
                    } else if (Result === "File") {
                        // TODO: 실패시 로직 추후 진행
                    }
                }
            } else {
                // 문자가 아닌 것은 데이터 정보
                setTempBlobData(prev => {
                    return prev.map(item => {
                        if (item.STATE === 'Downloading') {
                            return { ...item, DATA: [...item.DATA, data] };
                        }
                        return item;
                    });
                });
            }
        })
    }, [ws, onMessage]);

    useEffect(()=>{
        if (FileData.Pool[0]) {
            SocketUpload(FileData.Pool[0]);
        }
    }, [FileData.Pool, SocketUpload]);


    const DownLoadFile = useCallback((TargetTempBlobData) => {
        const { STATE, FILE_NAME, REPO_IDX, DATA } = TargetTempBlobData;
        if (STATE === 'Downloading') return;

        if (STATE === "Pending") {
            ConnectSock({
                MESSAGE: 'DownloadRepoFile',
                DATA: {
                    ARCHIVE_SYSTEMID: BaseInfo.pageId,
                    MEMBER_SYSTEMID: BaseInfo.userId,
                    REPO_IDX,
                }
            })

            setTempBlobData(prev => {
                return prev.map(item => {
                    if (item.REPO_IDX === REPO_IDX) {
                        return { ...item, STATE: 'Downloading' };
                    }
                    return item;
                });
            });
        } else if (STATE === "Completed") {
            const targetFileName = `${FILE_NAME}`;
            const url = window.URL.createObjectURL(new Blob(DATA));
            const a = document.createElement('a');
            a.href = url;
            a.download = targetFileName;
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            CloseSock();

            setTempBlobData(prev => {
                return prev.filter(item => `${item.FILE_NAME}` !== targetFileName)
            })
        }

    }, [BaseInfo.pageId, BaseInfo.userId, ConnectSock, CloseSock]);

    useEffect(() => {
        if (TempBlobData[0]) {
            DownLoadFile(TempBlobData[0]);
        }
    }, [TempBlobData, DownLoadFile, CloseSock]);

    return (
        <BaseContent
            onClick={(e) => {
                setActiveModalType();
                setInputInfo();
            }}
        >
            <ArchiveTree ArchiveTreeData={ArchivePathInfo.TreeData} BaseUrl={BaseUrl} />

            <MainArchive>
                <ContentHeader>
                    <div id="ActionContent" onClick={(e) => e.stopPropagation()}>
                    {MyAccess !== AUTHORITY_TYPE.NONE && 
                        <>
                            <BtnSecondary
                                label={<EnuIconFolderClose />}
                                type="sm"
                                title="New Folder"
                                onClick={(e) => setActiveModalType(MODAL_TYPE.NEW_FOLDER)}
                            />
                            <BtnSecondary
                                label={<MdUpload />}
                                type="sm"
                                title="File Upload"
                                onClick={(e) => setActiveModalType(MODAL_TYPE.UPLOAD)}
                            />
                        </>
                    }
                    </div>
                    <div id="SearchContent">
                        <SearchBar 
                            initKeyword={InputData}
                            placeholder="search..."
                            isReset={false}
                            searchEvent={(keyword)=>{}}
                        />
                    </div>
                </ContentHeader>

                <ArchiveList>
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <MdHome id="Btn_Home" onClick={(e) => { navigate(`${BaseUrl}`) }} />
                                        {ArchivePathInfo?.Path?.map((path, index) => (
                                            <span key={index}>
                                                /
                                                <Link to={`${BaseUrl}&path=${'/' + ArchivePathInfo.Path.slice(0, index + 1).join('/')}`}>{path}</Link>
                                            </span>
                                        ))}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {ArchivePathInfo.CurrentData?.map(item => {
                                    const { IDX, _NAME, PATH, _TYPE, CREATED_STAMP, NICKNAME, DESCRIPTION } = item;
                                    const icon = Number(_TYPE) === ARCHIVE_TREE_TYPE.FOLDER ? <EnuIconFolderClose className='icon' /> : <EnuIconFile className='icon' />
                                    const stamp = CREATED_STAMP.replace(/-/g, '.').replace(/T/g, ' ');
                                    return (
                                        <tr key={IDX}
                                        >
                                            <td>
                                                {icon}
                                                <div className='colInfo'>
                                                    <div className='title'>
                                                        <span
                                                            title={_NAME}
                                                            onClick={(e) => {
                                                                if (Number(_TYPE) === ARCHIVE_TREE_TYPE.FOLDER) {
                                                                    navigate(`${BaseUrl}&path=/${PATH.replace(/\\/g, '/')}`);
                                                                } else {
                                                                    setTempBlobData(prev => ([...prev, { FILE_NAME: _NAME, REPO_IDX: IDX, STATE: 'Pending', DATA: [] }]));
                                                                }
                                                            }}
                                                        >
                                                            {_NAME}
                                                        </span>

                                                        <div className='option'>
                                                            <BtnText
                                                                label={<MdMoreHoriz />}
                                                                type='sm'
                                                                title='Option'
                                                                onClick={(e) => { }}
                                                            />
                                                            <BtnText
                                                                label={<MdClose className='btnDel' />}
                                                                type='sm'
                                                                title='Delete'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (window.confirm(`"${_NAME}" 를 삭제하시겠습니까?`)) {
                                                                        DeleteRepoFolderOrFile(BaseInfo.pageId, BaseInfo.userId, IDX)
                                                                            .then(res => {
                                                                                const { result } = res.data;
                                                                                if (result === "Success") {
                                                                                    SetArchivePathInfoEvent && SetArchivePathInfoEvent(ArchivePathInfo.Path);
                                                                                }
                                                                            })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='etc'>
                                                        <div className='createdInfo'>
                                                            <div className='name'>
                                                                {NICKNAME}
                                                            </div>
                                                            <span className='date'>
                                                                {stamp}
                                                            </span>
                                                        </div>
                                                        <div className='desc'>
                                                            {DESCRIPTION || "No Description"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </ArchiveList>
            </MainArchive>

            {ActiveModalType === MODAL_TYPE.NEW_FOLDER &&
                <NewFolderModel onClick={(e) => e.stopPropagation()}>
                    <FlexContent>
                        <InputContent
                            value={InputInfo || ''}
                            placeholder='Folder Name...'
                            onChange={(e) => setInputInfo(e.target.value)}
                            onFocus={(e) => e.target.select()}
                        />
                    </FlexContent>
                    <ModalBottomBox marginTop='16px'>
                        <BtnSecondary
                            label="Cancel"
                            onClick={(e) => {
                                setActiveModalType();
                                setInputInfo();
                            }}
                        />
                        <BtnPrimary
                            label="Create"
                            onClick={(e) => {
                                CreateRepoFolder(BaseInfo.pageId, BaseInfo.userId, InputInfo, ArchivePathInfo.CurrentIDX)
                                    .then(res => {
                                        const { result, msg } = res.data;
                                        if (result === 'Success') {
                                            window.AddToastMsg('Success');
                                            setActiveModalType();
                                            setInputInfo();
                                            SetArchivePathInfoEvent && SetArchivePathInfoEvent(ArchivePathInfo.Path);
                                        } else {
                                            window.AddAlertMsg({ type: 'error', msg });
                                        }
                                    })
                            }}
                        />
                    </ModalBottomBox>
                </NewFolderModel>
            }

            {ActiveModalType === MODAL_TYPE.UPLOAD &&
                <NewFolderModel onClick={(e) => e.stopPropagation()}>
                    <FlexContent>
                        <InputContent
                            type='file'
                            accept="*"
                            multiple
                            onChange={(e) => setInputInfo(e.target.files)}
                        />
                    </FlexContent>
                    <ModalBottomBox marginTop='16px'>
                        <BtnSecondary label="Cancel" onClick={(e) => setActiveModalType()} />
                        <BtnPrimary
                            label="Upload"
                            onClick={(e) => {
                                const currentFileNames = ArchivePathInfo?.CurrentData
                                                ?.filter(({ _TYPE }) => Number(_TYPE) === ARCHIVE_TREE_TYPE.FILE)
                                                ?.map(({ _NAME }) => _NAME);

                                const UploadOrder = Object.values(InputInfo).reduce((acc, item) => {
                                    if (currentFileNames?.includes(item.name)) {
                                        window.AddAlertMsg({ type: 'error', msg: `"${item.name}" 이미 등록된 파일입니다.` });
                                        return acc;
                                    }
                                    acc.push(item);
                                    return acc;
                                }, []);
                            
                                if(UploadOrder.length === 0) return;

                                ConnectSock();
                                setIsFileProcessModal(true);
                                setFileData({type:'uploadReady', temp:InputInfo, pool:UploadOrder});
                                setActiveModalType();
                            }}
                        />
                    </ModalBottomBox>
                </NewFolderModel>
            }

            {IsFileProcessModal &&
                <UploadTempProgressModal>
                    <div id='progressContent'>
                        <div className='UploadTempList'>
                            <ul>
                                {Object.entries(FileData.Temp).map(([fileIndex, fileInfo], index) => {
                                    const { name } = fileInfo;
                                    return (
                                        <li key={name} title={name}>
                                            <span className='fileName'>{name}</span>
                                            <span className='progress'>
                                                <progress id={name} className='progressBar' max='100' value='0' />
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <ModalBottomBox>
                            <BtnPrimary
                                label='Ok'
                                onClick={(e) => {
                                    CloseSock();
                                    setIsFileProcessModal(false)
                                    setFileData({type:'clear'})
                                    SetArchivePathInfoEvent && SetArchivePathInfoEvent(ArchivePathInfo.Path);
                                }}
                            />
                        </ModalBottomBox>
                    </div>
                </UploadTempProgressModal>
            }
        </BaseContent>
    )
}

const BaseContent = styled.section`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
`;

const MainArchive = styled.div`
    position: relative;
    width: calc(100% - 250px);
    height: 100%;
    padding: 32px 24px 24px 24px;
    box-sizing: border-box;
`;

const ContentHeader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 16px;

    #ActionContent{
        display: flex;
        gap: 8px;
    }

    #SearchContent{
        display: flex;
        gap: 8px;

        select {
            width: 100px;
        }

        input{
            width: 260px;
        }
    }
`;

const ArchiveList = styled(RowTypeTableContent)`
    height: calc(100% - 54px);

    tr{
        position: relative;
        width: 100%;
    }

    th{
        position: relative;
        width: 100%;
        height: 40px!important;
        display: flex;
        gap: 4px;
        align-items: center;

        #Btn_Home{
            height: 28px;
            width: 30px;
            padding: 4px;
            border-radius: 4px;
            color: ${({ theme }) => theme.base.font_color_Black};
            box-sizing: border-box;
            cursor: pointer;
            opacity: .8;

            &:hover{
                background-color: #FFF;
            }
        }

        span{
            font-size: 1rem;
            color: ${({ theme }) => theme.base.font_color_DarkGray};

            a{
                font-size: 1.1rem;
                padding-inline: 4px;
                color: ${({ theme }) => theme.base.font_color_Black};
                text-decoration: none;

                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }

    tbody{
        tr{
            cursor: default !important;
            
            &:hover{
                td .colInfo .title .option{
                    visibility: visible;
                }
            }

            td{
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                height: 98px;
                gap: 8px;

                .icon{
                    height: 24px;
                    width: 24px;
                    padding: 24px;
                }

                .colInfo{
                    position: relative;
                    height: 100%;
                    flex-grow: 1;
                    width: calc(100% - 88px);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-block: 12px;
                    box-sizing: border-box;
                    gap: 8px;

                    .title{
                        position: relative;
                        height: 28px;
                        width: 100%;
                        line-height: 1.4rem;
                        font-size: 1rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: ${({ theme }) => theme.base.font_color_Black};
                        font-weight: bold;

                        span{
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            cursor: pointer;
                            color: ${({ theme }) => theme.base.font_color_DarkBlue};

                            &:hover{
                                text-decoration: underline;
                            }
                        }

                        .option{
                            visibility: hidden;
                            display: flex;
                            gap: 4px;
                            
                            button{
                                padding: 2px;
                            }
                        }

                        .btnDel{
                            color: ${({ theme }) => theme.base.font_color_Red};
                        }
                    }
                    .etc{
                        
                        .createdInfo{
                            position: relative;
                            display: flex;
                            justify-content: space-between;

                            .name{
                                color: ${({ theme }) => theme.base.font_color_Black};

                                span{
                                    /* padding-left: 4px; */
                                    opacity: .6;
                                }
                            }
                        }

                        .desc, .date{
                            font-size: .8rem;
                            opacity: .6;
                        }
                    }
                }
            }
        }
    }
`;

const NewFolderModel = styled.div`
    position: absolute;
    top: 48%;
    left: 50%;
    width: 340px;
    /* height: 360px; */
    transform: translate(-50%, -50%);
    padding: 16px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0.3rem;
    box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
    z-index:9;
`;

const FlexContent = styled.div`
    display: flex;
    align-items: baseline;

    &>span{
        margin-left: 8px;
    }
`;

const UploadTempProgressModal = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(43, 43, 43, .3);
    backdrop-filter: blur(5px);

    #progressContent{
        position: absolute;
        top: 48%;
        left: 50%;
        width: 400px;
        height: 360px;
        transform: translate(-50%, -50%);
        padding: 16px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 8px;


        .UploadTempList{
            overflow: auto;
            height: calc(100% - 62px);
            border: 1px solid ${({ theme }) => theme.base.border_color_Gray};

            ul{
                position: relative;
                width: 100%;
                height: 100%;

                li{
                    position: relative;
                    display: flex;
                    padding: 8px;
                    box-sizing: border-box;
                    border-bottom: 1px solid ${({ theme }) => theme.base.border_color_Gray};
                    gap: 8px;

                    .fileName{
                        width: calc(100% - 120px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    
                    &>.progress{
                        width: 120px;

                        &>.progressBar{
                            width: 100%;
                        }

                        &>span{
                            font-size: .85rem;
                            color: ${({theme}) => theme.base.font_color_Red};
                        }
                    }
                }
            }
        }
    }
`;