import { StandardMaterial, Color3 } from "@babylonjs/core";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";

export default class Material {
    constructor(
        EnuScene,
        objectId,
        diffuseColor = new Color3(1, 1, 1),
        alpha = 1
    ) {
        const material = new StandardMaterial(
            `mat_${objectId}`,
            EnuScene.scene
        );

        this._originMaterial = material;
        material.diffuseColor = diffuseColor;
        material.alpha = alpha;
        this._uniqueId = material.uniqueId;
        this._id = material.id;
        this._name = material.name;
        this._diffuseColor = diffuseColor.asArray();
        this._alpha = alpha;
        this.objectType = SUPPORT_OBJECT_TYPE.MATERIAL;

        EnuScene.addMaterialToMap(this.uniqueId, this);
    }

    set uniqueId(_uniqueId) {this._uniqueId = _uniqueId;}
    get uniqueId() {return this._uniqueId;}

    set id(_id) {
        this._id = _id;
        this._originMaterial.id = _id;
    }
    get id() {return this._id;}

    set name(_name) {
        this._name = _name;
        this._originMaterial.name = _name;
    }
    get name() {return this._name;}

    set material(_material) {this._originMaterial = _material;}
    get material() {return this._originMaterial;}

    set diffuseColor(_diffuseColor) {
        this._diffuseColor = _diffuseColor;
        this._originMaterial.diffuseColor = new Color3(..._diffuseColor);
    }
    get diffuseColor() {return this._diffuseColor;}

    set alpha(_alpha) {
        this._alpha = _alpha;
        this._originMaterial.alpha = _alpha;
    }

    get alpha() {
        return this._alpha;
    }

    switchWireframeMode() {
        if (this.material.wireframe) {
            this.material.wireframe = false;
        } else {
            this.material.wireframe = true;
        }
    }

    getPropertyMap() {
        return {
            id: this.id,
            name: this.name,
            diffuseColor: this.diffuseColor,
            alpha: this.alpha,
            objectType: this.objectType,
        };
    }
}
