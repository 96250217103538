import {AccessAxios} from 'components/Axios';
const BaseURL = '/api/Project3d';

/**
 * 프로젝트 저장
 * @param {string} MEMBER_SYSTEMID  사용자 아이디
 * @param {string} PROJECT_SYSTEMID  프로젝트 아이디
 * @param {string} FILE_NAME  파일명
 * @param {string} DATA  데이터
 */
export const SaveFile = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, FILE_NAME, DATA) => {
    const MSG = { MEMBER_SYSTEMID, PROJECT_SYSTEMID, FILE_NAME, DATA }
    try {
        const res = await AccessAxios.post(`${BaseURL}/SaveFile`, MSG);
        return res;
    } catch (err) {
        throw new Error(`3D프로젝트 저장에 실패했습니다. : ${err}`);
    }
}

/**
 * root 폴더의 tree정보를 가져온다.
 * @param {string} MEMBER_SYSTEMID       - 사용자 아이디
 * @param {string} PROJECT_SYSTEMID      - 프로젝트 아이디
 * @param {string} PATH                  - 해당 path 기준으로 tree 정보를 가져온다. (기본 root)
 * @returns 
 */
export const GetFileList = async (MEMBER_SYSTEMID, PROJECT_SYSTEMID, PATH = "") => {
    // const FILE_EXT = ["*"];
    // const FILE_TYPE = "obj"
    // const MSG = { MEMBER_SYSTEMID, PROJECT_SYSTEMID, PATH, FILE_EXT, FILE_TYPE}
    const MSG = { MEMBER_SYSTEMID, PROJECT_SYSTEMID, PATH}
    try {
        const res = await AccessAxios.post(`${BaseURL}/GetFileList`, MSG);
        return res;
    } catch (err) {
        throw new Error(err);
    }
}
