import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";

export default function LayoutInputList({_data, _ChangeEvent, _disabled}){
    const {
        points: NodePoints,
        width: NodeWidth, height: NodeHeight,
        x: NodeX, y: NodeY,
        x1: NodeX1, y1: NodeY1,
        x2: NodeX2, y2: NodeY2,
        cx: NodeCx, cy: NodeCy,
        dx: NodeDx, dy: NodeDy,
        rx: NodeRx, ry: NodeRy,
        r: NodeR,
        d: NodeD,
    } = _data;

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title={"Layout"}>
            {NodePoints && !Array.isArray(NodePoints) &&
                <div>
                    <InputComponent label='points' value={NodePoints} {...commonProps}/>
                </div>
            }
            {NodeWidth && NodeHeight &&
                <div>
                    <InputComponent label='W' attributeName='width' valueType='number' labelWidth='24px' value={NodeWidth} {...commonProps}/>
                    <InputComponent label='H' attributeName='height' valueType='number'  labelWidth='24px' value={NodeHeight} {...commonProps}/>
                </div>
            }
            {NodeX && NodeY &&
                <div>
                    <InputComponent label='X' attributeName='x' valueType='number' labelWidth='24px' value={NodeX} {...commonProps}/>
                    <InputComponent label='Y' attributeName='y' valueType='number'  labelWidth='24px' value={NodeY} {...commonProps}/>
                </div>
            }
            {(NodeX1 && NodeY1 ) &&
                <div>
                    <InputComponent label='x1' valueType='number' labelWidth='24px' value={NodeX1} {...commonProps}/>
                    <InputComponent label='y1' valueType='number'  labelWidth='24px' value={NodeY1} {...commonProps}/>
                </div>
            }
            {NodeX2 && NodeY2 &&
                <div>
                    <InputComponent label='x2' valueType='number' labelWidth='24px' value={NodeX2} {...commonProps}/>
                    <InputComponent label='y2' valueType='number'  labelWidth='24px' value={NodeY2} {...commonProps}/>
                </div>
            }
            {NodeCx && NodeCy &&
                <div>
                    <InputComponent label='cx' valueType='number' labelWidth='24px' value={NodeCx} {...commonProps}/>
                    <InputComponent label='cy' valueType='number'  labelWidth='24px' value={NodeCy} {...commonProps}/>
                </div>
            }
            {NodeDx && NodeDy &&
                <div>
                    <InputComponent label='dx' valueType='number' labelWidth='24px' value={NodeDx} {...commonProps}/>
                    <InputComponent label='dy' valueType='number'  labelWidth='24px' value={NodeDy} {...commonProps}/>
                </div>
            }
            {NodeRx && NodeRy &&
                <div>
                    <InputComponent label='rx' valueType='number' labelWidth='24px' value={NodeRx} {...commonProps}/>
                    <InputComponent label='ry' valueType='number'  labelWidth='24px' value={NodeRy} {...commonProps}/>
                </div>
            }
            {NodeR &&
                <div>
                    <InputComponent label='r' valueType='number' value={NodeR} {...commonProps}/>
                </div>
            }
            {NodeD && !Array.isArray(NodeD) &&
                <div>
                    <InputComponent label='d' value={NodeD} {...commonProps}/>
                </div>
            }
        </PropertiesContent>
    );
}