
import {useState, useMemo, useEffect} from "react";

import {LoadingLayer} from "components/organism/LoadingPage";
import Project3DPageContent from "components/template/Project3DPageContent";
import { setBaseData, deleteTreeData, InitProjectData, AllClearCanvasView} from 'reducers/projectDataReducer'
import { AllClearModalPopup } from 'reducers/modulePopupReducer';
import { showRightMenu } from 'reducers/rightMenuReducer';

import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function Project3D() {
    const dispatch = useDispatch()
    const [isLoadingPage, ] = useState(false);

    const location = useLocation();
    const BASE_DATA = useMemo(() => {
        return {
            userId: location?.state?.userId,
            projectId: location?.state?.projectId,
            projectName: location?.state?.projectName,
        }
    }, [location?.state?.userId, location?.state?.projectId, location?.state?.projectName]);

    useEffect(()=>{
        localStorage.setItem('projectId', BASE_DATA.projectId);  
        localStorage.removeItem('publishId');
        
        dispatch(setBaseData(BASE_DATA));
    }, [BASE_DATA, dispatch]);

    useEffect(() => {
        dispatch(InitProjectData());
        dispatch(deleteTreeData());

        return () => {
            dispatch(AllClearModalPopup());
            dispatch(showRightMenu({
                type: null, top: null, left: null, data: {}
            }));
            dispatch(AllClearCanvasView());
        };
    }, [dispatch]);

    return (
        <>
            <LoadingLayer marginTop={"30px"} isLoadingPage={isLoadingPage}/>
            <Project3DPageContent BASE_DATA={BASE_DATA} />
        </>
    )
}
