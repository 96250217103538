import { useState, useEffect } from "react";

import useModalPopup from "hooks/useModalPopup"
import {BtnPrimary, BtnSecondary, BtnGhost, } from "components/atom/Button";
import ModalColorPickerContent from "components/atom/ModalColorPickerContent";
import { ChartTitle, ModalBottomBox, TableContent} from 'components/css/common';

import styled from "styled-components";
import { MdAdd, MdClear, MdDragHandle } from "react-icons/md";

export default function ColorListModal({enuSpace, _Modal}){
    const {
        id: ModalId,
        data:{
            ACTIVE_PICTURE_URL,
            NodeID,
            attributeName,
            attributeValue,
        }
    } = _Modal;

    const [SelectedRow, setSelectedRow] = useState('');
    const [DragTarget, setDragTarget] = useState(''); 
    const [ColorListData, setColorListData] = useState([]);
    const {DeleteModal} = useModalPopup();

    useEffect(()=>{
        setColorListData(attributeValue?.split(';'))
    }, [attributeValue]);

    const ChangeEvent = (JsonData) => {
        const {attributeName, attributeValue} = JsonData[0];
        const index = Number(attributeName.replace(`${ModalId}_`, ''));

        setColorListData((prevColorListData) => {
            const newColorListData = [...prevColorListData];
            newColorListData[index] = attributeValue;
            return newColorListData;
        });
    }

    const DeleteColor = (e, index) => {
        if(ColorListData.length === 1) return;
        e.stopPropagation();
        setColorListData((prevColorListData) => {
            const newColorListData = [...prevColorListData];
            newColorListData.splice(index, 1);
            return newColorListData;
        });
    }

    return (
        <ListContent>
            <PopupHeader>
                <ChartTitle className='title'>
                    Color_{ColorListData.length || 0}개
                </ChartTitle>
                <BtnGhost
                    type='sm'
                    label={<MdAdd style={{ fontSize: '1.2rem'}} />} 
                    onClick={(e) => {
                        if(SelectedRow ===  ""){
                            setColorListData(prev => [...prev, 'rgb(255, 255, 255)']);
                        }else{
                            const index = Number(SelectedRow.replace(`${ModalId}_`, ''));
                            setColorListData((prev) => {
                                const newColorListData = [...prev];
                                newColorListData.splice(index + 1, 0, 'rgb(255, 255, 255)');
                                return newColorListData;
                            });
                        }
                    }}
                />
            </PopupHeader>

            <ColorListTable>
                <div>
                    <table>
                        <thead>
                            <tr >
                                <th className="No">No</th>
                                <th className="Color">Color</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ColorListData.map((color, idx) => {
                                const RowId = `${ModalId}_${idx}`;
                                // console.log(ModalId, ' - ', color)
                                return(
                                    <tr 
                                        key={RowId} 
                                        id={DragTarget === RowId ? 'dragTarget' : ''}
                                        draggable="true" 
                                        className={RowId === SelectedRow ? 'selectedRow': null}
                                        onClick={(e)=>setSelectedRow(prv => RowId === prv ? '' : RowId)}
                                        onDragStart={(e) =>{
                                            e.dataTransfer.setData('TargetRow', RowId)
                                            setDragTarget(RowId)
                                        }}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => {
                                            const targetId = e.dataTransfer.getData('TargetRow')
                                            if(targetId=== '' || targetId === RowId) return;
                                            const targetIndex = Number(targetId.replace(`${ModalId}_`, ''));
                                            const dropIndex = Number(RowId.replace(`${ModalId}_`, ''));
                                            setColorListData((prevColorListData) => {
                                                const newColorListData = [...prevColorListData];
                                                const target = newColorListData[targetIndex];
                                                newColorListData[targetIndex] = newColorListData[dropIndex];
                                                newColorListData[dropIndex] = target;
                                                return newColorListData;
                                            });
                                        }}
                                        onDragEnd={(e) => setDragTarget('')}
                                    >
                                        <td className="No">{idx + 1}</td>
                                        <td className="Color">
                                            <ModalColorPickerContent _colorId={RowId} _color={{ color}} _ChangeEvent={ChangeEvent}/>
                                            <MdClear className="BtnDelete" onClick={(e)=>DeleteColor(e, idx)}/>
                                            <MdDragHandle className="BtnDrag" />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </ColorListTable>

            <ModalBottomBox>
                <BtnSecondary 
                    label="Cancel" 
                    onClick={(e) => DeleteModal(ModalId)}
                />
                <BtnPrimary 
                    label="Accept" 
                    onClick={(e) => {
                        const attributeValue = ColorListData.join(';');
                        const JsonData = [{ attributeName, attributeValue, id: NodeID}];
                        enuSpace.EventHandler(ACTIVE_PICTURE_URL,"SETNODEDATA", JSON.stringify(JsonData), "");
                    }}
                />
            </ModalBottomBox>
        </ListContent>
    )
}

const ListContent = styled.div`
    height: 100%;
    margin: 0!important;
`;


const PopupHeader = styled.div`
    display: flex;
    justify-content: space-between;
    height: 38px;
    align-items: flex-end;
`;

const ColorListTable = styled(TableContent)`
    margin-top: 8px;
    height: calc(100% - 104px);

    .No{
        width: 46px;
        text-align: center;
    }

    thead, tbody {
        user-select: none;
    }

    tbody tr td.Color{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 0;
        /* gap: 0px; */

        &>div{
            width: calc(100% - 60px);
        }
    }

    tbody tr{
        .BtnDelete{
            width: 30px;
            height: 34px;
            padding: 4px;
            color: ${({theme}) => theme.base.font_color_Red};
            border: 1px solid transparent;
            border-radius: 4px;
            display:none;
            box-sizing: border-box;
            cursor: pointer;

            &:hover{
                border-color: ${({theme}) => theme.base.font_color_Red};
                background-color: ${({theme}) => theme.base.background_color_LightRed};
            }
        }
        &.selectedRow, &:hover{
            .BtnDelete{
                display: block;
            }
        }

        .BtnDrag{
            width: 30px;
            height: 34px;
            padding: 4px;
            box-sizing: border-box;
            color: ${({theme}) => theme.base.font_color_Gray};
            cursor: pointer;

            &:hover{
                color: ${({theme}) => theme.base.font_color_Black};
            }
        }
    }

    #dragTarget{
        border: 2px dashed ${({theme}) => theme.base.border_color_DarkBlue};
        padding-left: 6px;
    }
`;