import { useState } from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import Checkbox from "components/atom/Checkbox";
import InputComponent from "components/atom/InputComponent";
import {RadioComponent} from "components/atom/RadioContent";
import ColorComponent from "components/molecule/ColorComponent";
import { GroupName, SubTitle} from 'components/css/common';

import styled from "styled-components";

export default function TrendGridInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {stroke_width: NodeStrokeWidth,} = _data;
    const [showTab, setShowTab] = useState('x-grid');  //활성화 된 메뉴 index
    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };
    
    return(
        <PropertiesContent _Title="Grid">
            <div style={{ flexWrap: 'wrap' }}>
                {['x-axis-visible', 'y-axis-visible', 'x-grid-visible', 'y-grid-visible'].map(item => {
                    const id = item.replace(/-/g, '_');
                    const isChecked = _data[`${id}`] === '1';

                    return (
                        <Checkbox
                            key={item}
                            _id={item}
                            checked={isChecked}
                            disabled={_disabled}
                            onChange={(e) => {
                                _ChangeEvent([{ attributeName: item, attributeValue: isChecked ? 'false' : 'true' }]);
                            }}
                        >
                            {item.replace('-visible', '')}
                        </Checkbox>
                    )
                })}
            </div>
            <div>
                <ColorComponent label="fill" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
            <div>
                <ColorComponent label="stroke" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
            <div>
                <CustomGroupName >stroke-width</CustomGroupName>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='stroke-width' 
                    valueType='number' 
                    labelWidth='24px' 
                    value={NodeStrokeWidth} 
                    {...commonProps} 
                />
            </div>
            <div>
                <SubTitle>{showTab}</SubTitle>
            </div>
            <div>
                <RadioComponent
                    attributeName='gridTab'
                    dataList={{ "x-grid": "x-grid", "y-grid": "y-grid" }}
                    selectedValue={showTab}
                    disabled={_disabled}
                    changeEvent={(_name, _selectTabName) => setShowTab(_selectTabName)}
                />
            </div>
            <div>
                <ColorComponent label="stroke" attributeName={`${showTab}-stroke`} isGradient={false} isVisibility={false} {...commonProps} />
            </div>
            <div>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName={`${showTab}-stroke-width`}
                    valueType='number' 
                    labelWidth='24px' 
                    value={_data[`${showTab.replace(/-/g, '_')}_stroke_width`]} 
                    {...commonProps} 
                />
                <InputComponent 
                    label='count'
                    attributeName={`${showTab}-count`}
                    valueType='number' 
                    labelWidth='40px' 
                    value={_data[`${showTab.replace(/-/g, '_')}_count`]} 
                    {...commonProps} 
                />
            </div>
        </PropertiesContent>
    )
}

const CustomGroupName = styled(GroupName)`
    width: 100%;
    max-width: 208px;
    min-width: 128px;
`;