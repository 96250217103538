import { useState} from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import {RadioComponent} from "components/atom/RadioContent";
import InputComponent from "components/atom/InputComponent";
import SelectList from "components/atom/SelectList";
import ColorComponent from "components/molecule/ColorComponent";
import FontFamily from 'components/common/FontFamily';
import {FontSizeIcon} from 'components/common/EnuSpaceIcon';
import { GroupName, ToggleRect } from 'components/css/common';

import styled from "styled-components";
import { MdFormatItalic } from "react-icons/md";

export default function RealtimeValueInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        value_font_family: NodeValueFontFamily,
        value_visible: NodeValueVisible,
        value_font_style: NodeValueFontStyle,
        value_decimal_point: NodeValueDecimalPoint,
        value_format: NodeValueFormat,
        value_font_weight: NodeValueFontWeight,
        value_font_size: NodeValueFontSize,
    } = _data;

    const [fontClass] = useState(new FontFamily());

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title="Value" 
            _Toggle={ 
                <BtnToggle 
                    _toggleID="value-visible" 
                    _isToggleValue={NodeValueVisible} 
                    _disabled={_disabled}
                    _IsToggle={(attributeName, attributeValue) => _ChangeEvent([{attributeName, attributeValue}])} 
                /> 
            } 
            _ToggleValue={!['0', 'false', undefined].includes(NodeValueVisible)}
        >
            <div>
                <CustomInputComponent label='decimal-point' attributeName='value-decimal-point' valueType='number' labelWidth='140px' value={NodeValueDecimalPoint} {...commonProps}/>
            </div>
            <div>
                <GroupName width={76}>format</GroupName>
                <RadioComponent
                    attributeName='value-format'
                    dataList={{number: "number", "engineering unit": "eng unit",}}
                    selectedValue={NodeValueFormat}
                    disabled={_disabled}
                    changeEvent={(attributeName, attributeValue) => _ChangeEvent([{attributeName, attributeValue}])}
                />
            </div>
            <div>
                <SelectList
                    attributeName="value-font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeValueFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <SelectList
                    attributeName="value-font-weight"
                    dataList={fontClass.GetFontWeight(NodeValueFontFamily)}
                    selectedValue={fontClass.CheckFontWeight(NodeValueFontFamily, NodeValueFontWeight)}
                    {...commonProps}
                />
                <InputComponent
                    label={<FontSizeIcon />}
                    attributeName="value-font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeValueFontSize}
                    {...commonProps}
                />
                <ToggleRect 
                    disabled={_disabled}
                    onClick={(e)=>{
                        if (_disabled) return;
                        const attributeValue = Array.isArray(NodeValueFontStyle) ? 'italic' : ['normal','0'].includes(NodeValueFontStyle) ? 'italic' : 'normal';
                        _ChangeEvent([{attributeName: 'value_font_style', attributeValue}]);
                    }}
                >
                    <MdFormatItalic className={Array.isArray(NodeValueFontStyle) ? '' :  ['normal', '0'].includes(NodeValueFontStyle) ? '' : 'active'}/>
                </ToggleRect>
            </div>
            <div>
                <ColorComponent label="font" attributeName="value-font-color" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
        </PropertiesContent>
    )
}

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;