export const Droid_Sans_Regular = {
    glyphs: {
        0: {
            ha: 765,
            x_min: 66,
            x_max: 700,
            o: "m 700 497 q 683 282 700 377 q 627 121 665 187 q 528 21 589 56 q 382 -14 468 -14 q 242 21 302 -14 q 144 121 183 56 q 86 282 105 187 q 66 497 66 377 q 84 712 66 617 q 139 872 101 807 q 236 972 176 938 q 382 1007 296 1007 q 522 973 463 1007 q 621 873 582 939 q 680 713 661 808 q 700 497 700 618 m 192 497 q 202 319 192 395 q 235 193 212 243 q 293 117 257 142 q 382 92 330 92 q 471 117 434 92 q 531 192 507 142 q 565 318 554 242 q 575 497 575 395 q 565 675 575 599 q 531 801 554 751 q 471 876 507 851 q 382 901 434 901 q 293 876 330 901 q 235 801 257 851 q 202 675 212 751 q 192 497 192 599 z ",
        },
        1: {
            ha: 765,
            x_min: 121,
            x_max: 482,
            o: "m 482 0 l 363 0 l 363 619 q 363 682 363 648 q 365 748 363 715 q 367 811 366 781 q 368 864 368 841 q 348 843 357 852 q 330 826 339 834 q 311 809 321 818 q 286 787 300 800 l 186 705 l 121 789 l 380 991 l 482 991 l 482 0 z ",
        },
        2: {
            ha: 765,
            x_min: 65,
            x_max: 684,
            o: "m 684 0 l 65 0 l 65 106 l 302 364 q 393 465 353 419 q 461 553 433 510 q 505 641 490 597 q 519 736 519 684 q 508 806 519 776 q 475 858 496 837 q 425 890 454 880 q 359 901 395 901 q 243 875 295 901 q 144 808 191 849 l 75 889 q 134 935 102 913 q 201 972 165 956 q 277 997 237 987 q 361 1006 317 1006 q 478 987 426 1006 q 568 935 531 969 q 624 851 604 901 q 644 741 644 802 q 626 631 644 683 q 577 528 608 578 q 502 427 545 477 q 407 321 458 376 l 216 118 l 216 113 l 684 113 l 684 0 z ",
        },
        3: {
            ha: 765,
            x_min: 56,
            x_max: 682,
            o: "m 652 760 q 636 669 652 710 q 592 599 621 629 q 525 550 564 569 q 437 522 486 530 l 437 518 q 620 442 557 503 q 682 282 682 380 q 660 163 682 217 q 593 69 638 109 q 480 8 549 30 q 318 -14 412 -14 q 179 -2 244 -14 q 56 40 113 9 l 56 155 q 183 107 115 124 q 315 90 252 90 q 423 104 378 90 q 498 142 469 117 q 542 204 528 168 q 555 286 555 241 q 537 365 555 332 q 486 419 519 398 q 405 451 453 441 q 297 461 357 461 l 207 461 l 207 564 l 297 564 q 395 577 352 564 q 466 616 437 591 q 510 675 495 641 q 525 751 525 710 q 513 814 525 787 q 478 862 500 842 q 425 891 456 881 q 355 901 393 901 q 228 879 283 901 q 122 819 172 856 l 60 904 q 117 943 85 925 q 186 976 149 962 q 266 998 223 989 q 355 1006 308 1006 q 484 987 429 1006 q 576 936 539 969 q 633 858 614 903 q 652 760 652 813 z ",
        },
        4: {
            ha: 765,
            x_min: 16,
            x_max: 737,
            o: "m 737 222 l 593 222 l 593 0 l 473 0 l 473 222 l 16 222 l 16 330 l 465 997 l 593 997 l 593 335 l 737 335 l 737 222 m 473 335 l 473 576 q 474 656 473 614 q 477 738 475 698 q 480 812 478 777 q 482 869 481 846 l 476 869 q 464 839 471 856 q 449 806 457 823 q 431 774 440 789 q 415 747 422 758 l 136 335 l 473 335 z ",
        },
        5: {
            ha: 765,
            x_min: 89,
            x_max: 688,
            o: "m 370 608 q 495 590 437 608 q 596 534 553 571 q 663 444 639 498 q 688 318 688 389 q 665 179 688 241 q 596 75 642 117 q 484 9 551 32 q 330 -14 417 -14 q 262 -10 296 -14 q 198 0 229 -7 q 139 16 167 6 q 89 40 111 26 l 89 157 q 141 129 111 141 q 205 109 172 117 q 272 96 238 100 q 334 92 305 92 q 429 104 387 92 q 500 143 471 117 q 545 210 530 170 q 561 304 561 250 q 501 453 561 401 q 328 504 441 504 q 288 502 309 504 q 245 498 267 500 q 204 492 224 495 q 171 486 185 489 l 110 525 l 147 991 l 615 991 l 615 879 l 254 879 l 228 595 q 285 604 250 599 q 370 608 321 608 z ",
        },
        6: {
            ha: 765,
            x_min: 77,
            x_max: 701,
            o: "m 77 424 q 84 566 77 495 q 109 701 91 636 q 158 821 127 766 q 237 918 189 877 q 353 982 286 959 q 510 1006 420 1006 q 538 1005 523 1006 q 570 1003 554 1004 q 601 999 586 1001 q 627 993 615 996 l 627 888 q 573 900 603 896 q 514 904 543 904 q 407 889 453 904 q 326 848 360 875 q 270 785 292 822 q 233 704 247 749 q 212 610 218 660 q 203 505 205 559 l 211 505 q 244 551 224 530 q 289 588 263 572 q 347 612 315 603 q 419 621 379 621 q 536 601 484 621 q 625 542 588 581 q 681 447 661 503 q 701 319 701 391 q 680 180 701 242 q 620 76 659 119 q 525 9 581 33 q 400 -14 469 -14 q 273 13 332 -14 q 170 94 214 39 q 102 230 127 148 q 77 424 77 312 m 399 90 q 473 104 439 90 q 531 146 507 118 q 569 217 555 174 q 582 319 582 260 q 571 405 582 367 q 537 469 559 442 q 481 509 515 495 q 403 524 448 524 q 320 508 357 524 q 256 466 282 492 q 215 408 229 441 q 200 343 200 376 q 213 253 200 298 q 250 172 225 208 q 312 113 275 136 q 399 90 349 90 z ",
        },
        7: {
            ha: 765,
            x_min: 61,
            x_max: 699,
            o: "m 191 0 l 572 879 l 61 879 l 61 991 l 699 991 l 699 893 l 322 0 l 191 0 z ",
        },
        8: {
            ha: 765,
            x_min: 72,
            x_max: 694,
            o: "m 383 1007 q 491 992 440 1007 q 579 947 541 977 q 640 873 618 918 q 663 768 663 828 q 649 686 663 723 q 612 620 635 650 q 555 567 588 591 q 485 525 523 544 q 562 478 524 504 q 629 420 600 452 q 676 348 659 388 q 694 258 694 308 q 672 145 694 196 q 608 60 649 95 q 510 5 567 24 q 383 -14 452 -14 q 251 5 309 -14 q 153 57 193 23 q 93 141 113 92 q 72 253 72 191 q 87 345 72 304 q 128 418 102 385 q 189 475 154 450 q 264 519 224 500 q 201 565 231 541 q 151 621 172 590 q 117 688 130 651 q 104 770 104 725 q 127 873 104 829 q 189 947 150 918 q 278 992 227 977 q 383 1007 328 1007 m 193 252 q 204 185 193 216 q 238 134 215 155 q 296 101 261 113 q 380 89 332 89 q 464 101 428 89 q 525 135 500 113 q 562 188 549 157 q 574 257 574 219 q 561 321 574 292 q 523 374 547 349 q 463 420 498 398 q 386 463 429 441 l 366 472 q 237 379 280 431 q 193 252 193 326 m 382 904 q 268 868 310 904 q 225 764 225 832 q 237 697 225 725 q 270 648 249 669 q 320 609 291 626 q 385 575 350 591 q 446 608 417 590 q 496 648 475 625 q 530 699 517 670 q 542 764 542 727 q 499 868 542 832 q 382 904 456 904 z ",
        },
        9: {
            ha: 765,
            x_min: 72,
            x_max: 697,
            o: "m 697 568 q 690 426 697 497 q 665 291 683 355 q 616 171 647 227 q 536 74 585 115 q 421 10 488 33 q 264 -14 354 -14 q 235 -13 251 -14 q 204 -11 220 -12 q 174 -7 189 -9 q 147 -1 159 -5 l 147 104 q 201 92 171 96 q 260 87 231 87 q 410 119 351 87 q 504 206 469 151 q 553 333 538 260 q 571 486 568 406 l 562 486 q 530 441 549 462 q 485 404 511 420 q 427 380 459 389 q 354 371 394 371 q 237 391 290 371 q 149 450 185 411 q 92 544 112 488 q 72 672 72 600 q 93 811 72 749 q 154 917 115 873 q 249 983 193 960 q 374 1006 304 1006 q 501 979 442 1006 q 604 899 560 953 q 672 762 647 844 q 697 568 697 680 m 375 901 q 301 888 334 901 q 243 846 267 874 q 205 774 218 817 q 192 672 192 731 q 203 587 192 625 q 237 523 214 549 q 293 482 259 496 q 371 468 326 468 q 454 484 417 468 q 518 525 492 500 q 559 583 545 551 q 574 648 574 616 q 561 739 574 694 q 524 820 549 784 q 462 879 499 857 q 375 901 425 901 z ",
        },
        " ": { ha: 361, x_min: 0, x_max: 0, o: "" },
        "!": {
            ha: 374,
            x_min: 100,
            x_max: 272,
            o: "m 228 281 l 146 281 l 111 991 l 262 991 l 228 281 m 100 75 q 106 119 100 101 q 125 148 113 137 q 152 164 136 159 q 186 170 168 170 q 219 164 203 170 q 247 148 235 159 q 265 119 258 137 q 272 75 272 101 q 265 32 272 50 q 247 3 258 14 q 219 -14 235 -9 q 186 -20 203 -20 q 152 -14 168 -20 q 125 3 136 -9 q 106 32 113 14 q 100 75 100 50 z ",
        },
        '"': {
            ha: 558,
            x_min: 90,
            x_max: 468,
            o: "m 224 991 l 196 633 l 118 633 l 90 991 l 224 991 m 468 991 l 440 633 l 363 633 l 335 991 l 468 991 z ",
        },
        "#": {
            ha: 897,
            x_min: 35,
            x_max: 863,
            o: "m 667 606 l 624 383 l 814 383 l 814 290 l 606 290 l 550 0 l 450 0 l 507 290 l 310 290 l 254 0 l 157 0 l 210 290 l 35 290 l 35 383 l 228 383 l 272 606 l 86 606 l 86 699 l 289 699 l 345 991 l 444 991 l 389 699 l 587 699 l 644 991 l 742 991 l 685 699 l 863 699 l 863 606 l 667 606 m 328 383 l 525 383 l 568 606 l 371 606 l 328 383 z ",
        },
        $: {
            ha: 765,
            x_min: 83,
            x_max: 668,
            o: "m 668 302 q 651 216 668 255 q 603 149 634 178 q 526 102 571 121 q 424 75 481 83 l 424 -81 l 330 -81 l 330 69 q 262 72 296 69 q 195 81 227 75 q 134 96 162 87 q 83 117 105 105 l 83 233 q 135 211 106 222 q 197 191 165 200 q 264 177 230 182 q 330 171 298 171 l 330 465 q 225 508 270 486 q 152 559 181 530 q 109 625 123 588 q 94 713 94 663 q 111 796 94 758 q 158 862 127 833 q 233 908 189 890 q 330 932 277 926 l 330 1054 l 424 1054 l 424 935 q 549 917 492 931 q 652 882 606 903 l 607 783 q 522 814 569 800 q 424 832 474 828 l 424 546 q 529 503 484 525 q 606 452 575 480 q 652 388 637 424 q 668 302 668 351 m 549 302 q 542 343 549 325 q 520 377 535 362 q 482 406 506 393 q 424 431 458 418 l 424 178 q 518 220 487 187 q 549 302 549 254 m 214 711 q 219 668 214 687 q 239 632 225 648 q 275 604 252 616 q 330 579 297 591 l 330 829 q 242 788 270 819 q 214 711 214 758 z ",
        },
        "%": {
            ha: 1146,
            x_min: 69,
            x_max: 1076,
            o: "m 170 696 q 194 528 170 584 q 272 472 218 472 q 378 696 378 472 q 272 918 378 918 q 194 863 218 918 q 170 696 170 808 m 478 696 q 466 564 478 623 q 429 466 454 506 q 365 405 404 426 q 272 383 326 383 q 184 405 222 383 q 121 466 146 426 q 82 564 95 506 q 69 696 69 623 q 81 827 69 769 q 118 924 93 884 q 181 985 142 964 q 272 1006 219 1006 q 361 985 323 1006 q 426 924 399 964 q 465 827 452 884 q 478 696 478 769 m 768 298 q 792 131 768 186 q 871 75 817 75 q 977 298 977 75 q 871 521 977 521 q 792 466 817 521 q 768 298 768 410 m 1076 298 q 1064 167 1076 225 q 1027 69 1053 109 q 963 8 1002 29 q 871 -14 924 -14 q 783 8 821 -14 q 719 69 745 29 q 681 167 694 109 q 668 298 668 225 q 680 429 668 372 q 716 527 692 487 q 780 587 741 566 q 871 608 818 608 q 960 587 922 608 q 1024 527 998 566 q 1063 429 1050 487 q 1076 298 1076 372 m 903 991 l 353 0 l 245 0 l 796 991 l 903 991 z ",
        },
        "&": {
            ha: 975,
            x_min: 74,
            x_max: 953,
            o: "m 286 790 q 292 745 286 767 q 308 701 297 723 q 337 656 319 679 q 379 608 355 633 q 448 653 419 632 q 496 696 477 674 q 525 741 515 718 q 534 793 534 765 q 526 838 534 817 q 502 873 517 859 q 463 897 486 888 q 412 905 441 905 q 320 875 354 905 q 286 790 286 845 m 378 96 q 450 103 417 96 q 512 125 484 111 q 565 157 541 138 q 611 196 589 175 l 349 472 q 286 428 313 450 q 240 382 259 407 q 212 329 222 358 q 203 262 203 300 q 215 195 203 226 q 249 142 227 164 q 304 108 271 120 q 378 96 336 96 m 74 258 q 87 353 74 311 q 127 428 101 394 q 191 491 153 462 q 277 549 229 520 q 235 599 256 573 q 200 655 215 625 q 175 718 184 684 q 165 790 165 751 q 182 881 165 841 q 231 950 199 922 q 310 992 263 978 q 416 1007 357 1007 q 517 992 473 1007 q 592 950 562 978 q 640 881 623 922 q 656 790 656 841 q 639 710 656 747 q 593 641 622 673 q 528 583 565 610 q 450 532 491 556 l 689 279 q 722 319 707 299 q 748 363 736 340 q 769 412 760 386 q 785 471 778 439 l 909 471 q 885 388 899 426 q 853 317 871 351 q 813 255 836 284 q 765 199 791 227 l 953 0 l 800 0 l 686 117 q 621 62 653 86 q 551 21 588 38 q 473 -4 515 5 q 378 -14 431 -14 q 250 4 307 -14 q 154 57 193 22 q 95 143 115 92 q 74 258 74 193 z ",
        },
        "'": {
            ha: 314,
            x_min: 90,
            x_max: 224,
            o: "m 224 991 l 196 633 l 118 633 l 90 991 l 224 991 z ",
        },
        "(": {
            ha: 418,
            x_min: 56,
            x_max: 376,
            o: "m 56 380 q 68 548 56 465 q 105 708 80 630 q 169 857 130 785 q 260 991 207 928 l 376 991 q 232 704 281 861 q 183 382 183 547 q 196 221 183 301 q 232 64 208 141 q 292 -84 256 -12 q 375 -220 328 -156 l 260 -220 q 169 -89 207 -159 q 105 57 130 -19 q 68 215 80 134 q 56 380 56 296 z ",
        },
        ")": {
            ha: 418,
            x_min: 41,
            x_max: 363,
            o: "m 363 380 q 351 215 363 296 q 313 57 338 134 q 249 -89 288 -19 q 158 -220 211 -159 l 43 -220 q 125 -84 90 -156 q 186 64 161 -12 q 222 221 210 141 q 235 382 235 301 q 186 704 235 547 q 41 991 137 861 l 158 991 q 249 857 211 928 q 313 708 288 785 q 351 548 338 630 q 363 380 363 465 z ",
        },
        "*": {
            ha: 765,
            x_min: 56,
            x_max: 708,
            o: "m 450 1055 l 421 789 l 690 864 l 708 733 l 452 714 l 618 493 l 497 428 l 378 671 l 271 428 l 146 493 l 310 714 l 56 733 l 75 864 l 340 789 l 311 1055 l 450 1055 z ",
        },
        "+": {
            ha: 765,
            x_min: 69,
            x_max: 696,
            o: "m 332 439 l 69 439 l 69 541 l 332 541 l 332 804 l 433 804 l 433 541 l 696 541 l 696 439 l 433 439 l 433 178 l 332 178 l 332 439 z ",
        },
        ",": {
            ha: 347,
            x_min: 43,
            x_max: 256,
            o: "m 245 161 l 256 146 q 233 68 246 109 q 204 -16 220 26 q 171 -99 188 -58 q 136 -179 153 -141 l 43 -179 q 63 -93 53 -138 q 82 -4 73 -48 q 98 83 91 41 q 111 161 106 125 l 245 161 z ",
        },
        "-": {
            ha: 447,
            x_min: 56,
            x_max: 392,
            o: "m 56 315 l 56 429 l 392 429 l 392 315 l 56 315 z ",
        },
        ".": {
            ha: 372,
            x_min: 100,
            x_max: 272,
            o: "m 100 75 q 106 119 100 101 q 125 148 113 137 q 152 164 136 159 q 186 170 168 170 q 219 164 203 170 q 247 148 235 159 q 265 119 258 137 q 272 75 272 101 q 265 32 272 50 q 247 3 258 14 q 219 -14 235 -9 q 186 -20 203 -20 q 152 -14 168 -20 q 125 3 136 -9 q 106 32 113 14 q 100 75 100 50 z ",
        },
        "/": {
            ha: 518,
            x_min: 14,
            x_max: 504,
            o: "m 504 991 l 135 0 l 14 0 l 383 991 l 504 991 z ",
        },
        ":": {
            ha: 372,
            x_min: 100,
            x_max: 272,
            o: "m 100 75 q 106 119 100 101 q 125 148 113 137 q 152 164 136 159 q 186 170 168 170 q 219 164 203 170 q 247 148 235 159 q 265 119 258 137 q 272 75 272 101 q 265 32 272 50 q 247 3 258 14 q 219 -14 235 -9 q 186 -20 203 -20 q 152 -14 168 -20 q 125 3 136 -9 q 106 32 113 14 q 100 75 100 50 m 100 669 q 106 714 100 696 q 125 743 113 732 q 152 759 136 754 q 186 764 168 764 q 219 759 203 764 q 247 743 235 754 q 265 714 258 732 q 272 669 272 696 q 265 627 272 644 q 247 597 258 609 q 219 581 235 586 q 186 575 203 575 q 152 581 168 575 q 125 597 136 586 q 106 627 113 609 q 100 669 100 644 z ",
        },
        ";": {
            ha: 372,
            x_min: 43,
            x_max: 272,
            o: "m 245 161 l 256 146 q 233 68 246 109 q 204 -16 220 26 q 171 -99 188 -58 q 136 -179 153 -141 l 43 -179 q 63 -93 53 -138 q 82 -4 73 -48 q 98 83 91 41 q 111 161 106 125 l 245 161 m 100 669 q 106 714 100 696 q 125 743 113 732 q 152 759 136 754 q 186 764 168 764 q 219 759 203 764 q 247 743 235 754 q 265 714 258 732 q 272 669 272 696 q 265 627 272 644 q 247 597 258 609 q 219 581 235 586 q 186 575 203 575 q 152 581 168 575 q 125 597 136 586 q 106 627 113 609 q 100 669 100 644 z ",
        },
        "<": {
            ha: 765,
            x_min: 69,
            x_max: 696,
            o: "m 696 161 l 69 449 l 69 518 l 696 844 l 696 736 l 197 489 l 696 271 l 696 161 z ",
        },
        "=": {
            ha: 765,
            x_min: 69,
            x_max: 696,
            o: "m 69 578 l 69 679 l 696 679 l 696 578 l 69 578 m 69 300 l 69 401 l 696 401 l 696 300 l 69 300 z ",
        },
        ">": {
            ha: 765,
            x_min: 69,
            x_max: 696,
            o: "m 69 271 l 568 489 l 69 736 l 69 844 l 696 518 l 696 449 l 69 161 l 69 271 z ",
        },
        "?": {
            ha: 591,
            x_min: 25,
            x_max: 546,
            o: "m 191 281 l 191 306 q 196 376 191 345 q 214 434 201 407 q 250 487 227 461 q 306 542 272 513 q 361 592 338 570 q 399 638 385 615 q 421 689 414 662 q 428 751 428 715 q 418 811 428 784 q 388 857 408 838 q 340 887 369 876 q 273 897 311 897 q 166 880 217 897 q 68 839 115 862 l 25 937 q 144 986 80 966 q 273 1006 208 1006 q 387 988 336 1006 q 473 939 437 971 q 527 859 508 906 q 546 753 546 812 q 537 672 546 707 q 509 606 528 636 q 464 547 491 575 q 400 486 437 518 q 342 433 365 456 q 307 389 320 410 q 290 345 294 368 q 285 293 285 323 l 285 281 l 191 281 m 158 75 q 165 119 158 101 q 183 148 172 137 q 210 164 195 159 q 244 170 226 170 q 278 164 262 170 q 305 148 294 159 q 323 119 317 137 q 330 75 330 101 q 323 32 330 50 q 305 3 317 14 q 278 -14 294 -9 q 244 -20 262 -20 q 210 -14 226 -20 q 183 3 195 -9 q 165 32 172 14 q 158 75 158 50 z ",
        },
        "@": {
            ha: 1203,
            x_min: 74,
            x_max: 1129,
            o: "m 1129 496 q 1123 411 1129 454 q 1104 331 1116 369 q 1072 259 1091 292 q 1027 202 1053 227 q 968 164 1001 178 q 896 150 935 150 q 841 160 865 150 q 798 187 816 170 q 769 226 781 204 q 754 272 758 248 l 751 272 q 721 224 739 247 q 679 185 703 202 q 624 159 654 169 q 558 150 594 150 q 466 169 506 150 q 397 222 425 188 q 355 304 370 256 q 340 411 340 353 q 360 536 340 479 q 418 635 380 593 q 509 699 456 676 q 629 722 563 722 q 690 720 660 722 q 749 712 721 717 q 800 702 777 707 q 839 690 823 696 l 823 408 q 822 384 823 393 q 822 370 822 375 q 822 361 822 364 q 822 357 822 359 q 829 298 822 321 q 848 261 836 275 q 874 242 859 248 q 904 237 888 237 q 959 258 935 237 q 999 314 983 279 q 1023 397 1015 349 q 1031 497 1031 444 q 1002 668 1031 593 q 921 795 972 743 q 799 873 869 846 q 644 900 728 900 q 442 862 530 900 q 295 757 355 825 q 205 597 236 690 q 175 395 175 505 q 202 213 175 292 q 280 79 229 134 q 409 -3 332 25 q 586 -31 486 -31 q 668 -26 627 -31 q 746 -13 708 -21 q 817 6 783 -5 q 880 28 851 16 l 880 -68 q 749 -109 822 -94 q 587 -123 675 -123 q 371 -89 466 -123 q 209 12 275 -54 q 109 173 143 78 q 74 390 74 269 q 114 628 74 517 q 228 819 154 738 q 408 945 302 899 q 644 991 513 991 q 839 957 750 991 q 992 859 928 923 q 1093 703 1057 795 q 1129 496 1129 611 m 449 408 q 483 278 449 318 q 575 237 517 237 q 639 253 612 237 q 682 296 665 268 q 708 363 699 324 q 720 449 717 402 l 729 621 q 684 630 710 626 q 629 635 658 635 q 545 615 579 635 q 489 564 511 595 q 458 491 468 532 q 449 408 449 450 z ",
        },
        A: {
            ha: 844,
            x_min: 0,
            x_max: 844,
            o: "m 715 0 l 607 307 l 237 307 l 127 0 l 0 0 l 364 996 l 479 996 l 844 0 l 715 0 m 567 419 l 467 707 q 457 736 463 719 q 445 774 451 754 q 433 817 439 795 q 421 860 427 839 q 410 817 416 839 q 398 773 404 794 q 386 735 392 753 q 376 707 380 718 l 278 419 l 567 419 z ",
        },
        B: {
            ha: 863,
            x_min: 135,
            x_max: 786,
            o: "m 135 991 l 406 991 q 558 978 492 991 q 669 936 625 965 q 736 858 713 906 q 758 741 758 810 q 745 662 758 699 q 707 597 732 625 q 645 551 682 570 q 561 526 608 532 l 561 519 q 649 496 608 511 q 720 454 690 481 q 768 387 751 427 q 786 288 786 346 q 764 166 786 220 q 700 76 742 113 q 599 20 659 39 q 464 0 539 0 l 135 0 l 135 991 m 261 571 l 427 571 q 522 581 484 571 q 585 611 561 591 q 619 662 608 631 q 629 732 629 692 q 577 848 629 813 q 412 884 525 884 l 261 884 l 261 571 m 261 465 l 261 107 l 441 107 q 542 121 501 107 q 607 159 582 134 q 642 217 631 183 q 653 293 653 252 q 642 363 653 331 q 605 417 631 395 q 538 453 580 440 q 433 465 496 465 l 261 465 z ",
        },
        C: {
            ha: 838,
            x_min: 85,
            x_max: 798,
            o: "m 538 895 q 406 867 465 895 q 306 788 347 840 q 241 663 264 736 q 218 496 218 589 q 238 326 218 400 q 298 201 258 252 q 398 123 338 150 q 538 97 458 97 q 652 109 598 97 q 760 136 707 121 l 760 26 q 707 8 733 16 q 651 -4 680 1 q 590 -11 623 -9 q 517 -14 557 -14 q 324 23 406 -14 q 190 127 243 60 q 111 288 136 194 q 85 497 85 382 q 114 703 85 610 q 201 864 144 797 q 343 968 258 931 q 538 1006 428 1006 q 677 991 611 1006 q 798 947 744 976 l 745 842 q 652 879 702 863 q 538 895 602 895 z ",
        },
        D: {
            ha: 950,
            x_min: 135,
            x_max: 865,
            o: "m 865 505 q 833 285 865 380 q 739 127 800 191 q 587 32 677 64 q 383 0 497 0 l 135 0 l 135 991 l 411 991 q 599 960 515 991 q 742 868 683 929 q 833 715 801 806 q 865 505 865 625 m 732 501 q 709 672 732 600 q 644 791 687 745 q 539 861 602 838 q 397 884 477 884 l 261 884 l 261 109 l 371 109 q 641 208 550 109 q 732 501 732 307 z ",
        },
        E: {
            ha: 733,
            x_min: 135,
            x_max: 650,
            o: "m 650 0 l 135 0 l 135 991 l 650 991 l 650 880 l 261 880 l 261 574 l 624 574 l 624 464 l 261 464 l 261 111 l 650 111 l 650 0 z ",
        },
        F: {
            ha: 682,
            x_min: 135,
            x_max: 650,
            o: "m 261 0 l 135 0 l 135 991 l 650 991 l 650 880 l 261 880 l 261 530 l 624 530 l 624 419 l 261 419 l 261 0 z ",
        },
        G: {
            ha: 958,
            x_min: 85,
            x_max: 859,
            o: "m 530 524 l 859 524 l 859 37 q 783 16 821 25 q 705 0 745 6 q 621 -10 665 -7 q 526 -14 577 -14 q 338 22 420 -14 q 199 124 256 58 q 114 284 143 190 q 85 497 85 379 q 117 708 85 614 q 211 868 149 802 q 363 970 273 935 q 570 1006 454 1006 q 714 991 645 1006 q 843 947 783 976 l 794 838 q 742 859 770 849 q 684 877 713 869 q 622 890 654 885 q 559 895 591 895 q 413 867 477 895 q 306 788 349 840 q 241 663 263 736 q 218 496 218 589 q 237 334 218 408 q 296 208 256 261 q 401 126 337 155 q 557 97 466 97 q 611 99 587 97 q 657 103 636 100 q 697 110 678 106 q 733 117 715 113 l 733 412 l 530 412 l 530 524 z ",
        },
        H: {
            ha: 974,
            x_min: 135,
            x_max: 839,
            o: "m 839 0 l 713 0 l 713 463 l 261 463 l 261 0 l 135 0 l 135 991 l 261 991 l 261 574 l 713 574 l 713 991 l 839 991 l 839 0 z ",
        },
        I: {
            ha: 471,
            x_min: 56,
            x_max: 415,
            o: "m 415 0 l 56 0 l 56 69 l 172 97 l 172 895 l 56 922 l 56 991 l 415 991 l 415 922 l 298 895 l 298 97 l 415 69 l 415 0 z ",
        },
        J: {
            ha: 376,
            x_min: -125,
            x_max: 252,
            o: "m -20 -264 q -80 -259 -54 -264 q -125 -248 -106 -255 l -125 -139 q -75 -149 -102 -145 q -18 -153 -49 -153 q 32 -146 7 -153 q 78 -123 58 -140 q 112 -76 99 -106 q 125 0 125 -46 l 125 991 l 252 991 l 252 14 q 232 -110 252 -58 q 176 -196 212 -162 q 91 -247 140 -231 q -20 -264 41 -264 z ",
        },
        K: {
            ha: 804,
            x_min: 135,
            x_max: 804,
            o: "m 804 0 l 661 0 l 355 473 l 261 396 l 261 0 l 135 0 l 135 991 l 261 991 l 261 496 l 343 610 l 650 991 l 791 991 l 439 559 l 804 0 z ",
        },
        L: {
            ha: 682,
            x_min: 135,
            x_max: 650,
            o: "m 135 0 l 135 991 l 261 991 l 261 113 l 650 113 l 650 0 l 135 0 z ",
        },
        M: {
            ha: 1208,
            x_min: 135,
            x_max: 1074,
            o: "m 545 0 l 244 868 l 239 868 q 245 768 243 818 q 250 679 248 726 q 252 593 252 631 l 252 0 l 135 0 l 135 991 l 322 991 l 602 183 l 606 183 l 886 991 l 1074 991 l 1074 0 l 947 0 l 947 602 q 949 682 947 637 q 952 769 950 728 q 957 867 955 817 l 951 867 l 648 0 l 545 0 z ",
        },
        N: {
            ha: 1013,
            x_min: 135,
            x_max: 878,
            o: "m 878 0 l 732 0 l 244 821 l 239 821 q 245 717 243 768 q 250 625 248 673 q 252 538 252 576 l 252 0 l 135 0 l 135 991 l 279 991 l 765 175 l 770 175 q 765 277 768 227 q 763 321 764 298 q 761 367 762 344 q 760 410 760 389 q 760 449 760 431 l 760 991 l 878 991 l 878 0 z ",
        },
        O: {
            ha: 1031,
            x_min: 85,
            x_max: 945,
            o: "m 945 497 q 917 288 945 382 q 836 127 890 194 q 701 23 781 60 q 515 -14 621 -14 q 324 23 405 -14 q 190 127 243 60 q 111 289 136 194 q 85 498 85 383 q 111 708 85 614 q 190 868 136 802 q 325 971 243 935 q 517 1007 406 1007 q 702 971 622 1007 q 836 868 781 935 q 917 707 890 801 q 945 497 945 613 m 218 497 q 236 330 218 404 q 290 204 254 256 q 382 124 326 152 q 515 97 438 97 q 649 124 593 97 q 741 204 705 152 q 794 330 777 256 q 811 497 811 404 q 794 665 811 591 q 741 790 777 739 q 650 868 705 841 q 517 896 594 896 q 383 868 439 896 q 290 790 327 841 q 236 665 254 739 q 218 497 218 591 z ",
        },
        P: {
            ha: 800,
            x_min: 135,
            x_max: 729,
            o: "m 729 701 q 710 583 729 640 q 649 482 692 526 q 536 412 606 438 q 363 386 466 386 l 261 386 l 261 0 l 135 0 l 135 991 l 380 991 q 537 972 471 991 q 646 916 603 953 q 709 825 688 879 q 729 701 729 770 m 261 493 l 349 493 q 459 504 412 493 q 537 539 506 515 q 583 601 568 563 q 599 694 599 640 q 543 837 599 791 q 370 884 488 884 l 261 884 l 261 493 z ",
        },
        Q: {
            ha: 1029,
            x_min: 85,
            x_max: 945,
            o: "m 945 497 q 928 332 945 408 q 879 194 911 255 q 799 87 847 132 q 688 17 751 43 q 763 -95 717 -47 q 871 -184 810 -144 l 789 -281 q 660 -164 719 -231 q 567 -11 602 -97 q 542 -13 555 -12 q 515 -14 528 -14 q 324 23 405 -14 q 190 127 243 60 q 111 289 136 194 q 85 498 85 383 q 111 708 85 614 q 190 868 136 802 q 325 971 243 935 q 517 1007 406 1007 q 702 971 622 1007 q 836 868 781 935 q 917 707 890 801 q 945 497 945 613 m 218 497 q 236 330 218 404 q 290 204 254 256 q 382 124 326 152 q 515 97 438 97 q 649 124 593 97 q 741 204 705 152 q 794 330 777 256 q 811 497 811 404 q 794 665 811 591 q 741 790 777 739 q 650 868 705 841 q 517 896 594 896 q 383 868 439 896 q 290 790 327 841 q 236 665 254 739 q 218 497 218 591 z ",
        },
        R: {
            ha: 819,
            x_min: 135,
            x_max: 803,
            o: "m 261 410 l 261 0 l 135 0 l 135 991 l 376 991 q 643 922 557 991 q 729 710 729 852 q 712 607 729 651 q 668 531 696 563 q 606 479 641 500 q 533 444 570 458 l 803 0 l 654 0 l 416 410 l 261 410 m 261 517 l 372 517 q 476 529 433 517 q 546 564 519 541 q 586 622 574 587 q 599 704 599 657 q 585 787 599 753 q 543 842 571 821 q 472 874 515 864 q 370 884 429 884 l 261 884 l 261 517 z ",
        },
        S: {
            ha: 721,
            x_min: 71,
            x_max: 657,
            o: "m 657 265 q 634 148 657 199 q 567 60 610 96 q 461 5 524 24 q 321 -14 399 -14 q 180 -2 245 -14 q 71 33 115 10 l 71 154 q 123 133 94 143 q 185 114 153 122 q 252 101 218 106 q 321 96 287 96 q 481 136 429 96 q 533 253 533 177 q 523 317 533 290 q 489 367 513 344 q 422 411 464 389 q 318 457 381 432 q 213 509 258 481 q 140 572 169 537 q 97 652 111 608 q 83 754 83 697 q 105 861 83 814 q 166 940 127 907 q 260 989 205 972 q 380 1006 315 1006 q 526 990 461 1006 q 640 951 590 975 l 595 846 q 495 880 551 865 q 379 895 439 895 q 251 856 296 895 q 205 753 205 818 q 216 686 205 714 q 250 635 226 658 q 312 592 273 612 q 408 550 351 572 q 516 500 470 526 q 594 441 563 474 q 641 366 625 409 q 657 265 657 323 z ",
        },
        T: {
            ha: 721,
            x_min: 14,
            x_max: 707,
            o: "m 424 0 l 297 0 l 297 880 l 14 880 l 14 991 l 707 991 l 707 880 l 424 880 l 424 0 z ",
        },
        U: {
            ha: 970,
            x_min: 125,
            x_max: 844,
            o: "m 844 993 l 844 350 q 822 206 844 273 q 754 91 800 140 q 641 14 709 42 q 481 -14 573 -14 q 328 13 394 -14 q 216 87 261 39 q 148 202 171 135 q 125 353 125 270 l 125 991 l 252 991 l 252 346 q 310 162 252 227 q 488 97 369 97 q 591 115 548 97 q 663 167 635 134 q 704 246 690 200 q 718 347 718 292 l 718 993 l 844 993 z ",
        },
        V: {
            ha: 789,
            x_min: 0,
            x_max: 789,
            o: "m 654 991 l 789 991 l 457 0 l 330 0 l 0 991 l 134 991 l 334 363 q 368 244 353 301 q 393 136 382 186 q 419 244 404 186 q 456 367 435 301 l 654 991 z ",
        },
        W: {
            ha: 1227,
            x_min: 14,
            x_max: 1214,
            o: "m 549 991 l 682 991 l 838 411 q 858 335 848 373 q 876 261 867 297 q 891 194 884 226 q 901 136 897 162 q 908 193 904 162 q 918 257 912 223 q 929 325 923 290 q 943 393 936 360 l 1079 991 l 1214 991 l 965 0 l 838 0 l 665 636 q 647 707 656 671 q 631 776 638 744 q 615 848 623 813 q 600 777 608 814 q 585 707 593 745 q 567 632 576 669 l 403 0 l 275 0 l 14 991 l 147 991 l 298 389 q 313 324 307 357 q 326 257 320 290 q 336 193 332 224 q 345 136 341 162 q 353 195 348 161 q 365 264 358 228 q 379 339 372 301 q 396 410 387 376 l 549 991 z ",
        },
        X: {
            ha: 760,
            x_min: 0,
            x_max: 760,
            o: "m 760 0 l 616 0 l 376 431 l 127 0 l 0 0 l 307 518 l 21 991 l 155 991 l 380 612 l 607 991 l 736 991 l 450 522 l 760 0 z ",
        },
        Y: {
            ha: 732,
            x_min: 0,
            x_max: 732,
            o: "m 366 490 l 596 991 l 732 991 l 429 386 l 429 0 l 302 0 l 302 379 l 0 991 l 138 991 l 366 490 z ",
        },
        Z: {
            ha: 749,
            x_min: 56,
            x_max: 693,
            o: "m 693 0 l 56 0 l 56 98 l 538 879 l 69 879 l 69 991 l 679 991 l 679 893 l 197 113 l 693 113 l 693 0 z ",
        },
        "[": {
            ha: 421,
            x_min: 111,
            x_max: 386,
            o: "m 386 -220 l 111 -220 l 111 991 l 386 991 l 386 890 l 235 890 l 235 -118 l 386 -118 l 386 -220 z ",
        },
        "\\": {
            ha: 518,
            x_min: 16,
            x_max: 505,
            o: "m 136 991 l 505 0 l 385 0 l 16 991 l 136 991 z ",
        },
        "]": {
            ha: 421,
            x_min: 35,
            x_max: 310,
            o: "m 35 -118 l 186 -118 l 186 890 l 35 890 l 35 991 l 310 991 l 310 -220 l 35 -220 l 35 -118 z ",
        },
        "^": {
            ha: 739,
            x_min: 28,
            x_max: 711,
            o: "m 28 372 l 339 999 l 408 999 l 711 372 l 602 372 l 374 871 l 137 372 l 28 372 z ",
        },
        _: {
            ha: 571,
            x_min: -3,
            x_max: 574,
            o: "m 574 -220 l -3 -220 l -3 -125 l 574 -125 l 574 -220 z ",
        },
        "`": {
            ha: 802,
            x_min: 267,
            x_max: 533,
            o: "m 533 842 l 452 842 q 400 889 428 861 q 346 947 372 917 q 299 1004 320 977 q 267 1050 277 1031 l 267 1064 l 415 1064 q 440 1014 426 1041 q 471 959 455 986 q 503 905 486 931 q 533 860 519 880 l 533 842 z ",
        },
        a: {
            ha: 737,
            x_min: 64,
            x_max: 627,
            o: "m 538 0 l 513 103 l 507 103 q 463 51 485 73 q 414 14 440 28 q 355 -7 387 0 q 279 -14 322 -14 q 193 0 233 -14 q 125 40 154 13 q 80 110 96 68 q 64 208 64 151 q 142 379 64 319 q 380 444 220 439 l 505 449 l 505 496 q 495 572 505 541 q 467 621 486 602 q 421 648 448 640 q 358 656 393 656 q 253 640 302 656 q 160 600 205 623 l 117 693 q 229 739 167 720 q 358 758 290 758 q 478 744 427 758 q 561 700 528 730 q 610 623 594 670 q 627 510 627 576 l 627 0 l 538 0 m 311 86 q 388 99 353 86 q 449 138 423 112 q 489 204 475 164 q 504 297 504 243 l 504 364 l 407 359 q 304 346 346 357 q 238 316 263 335 q 203 269 214 296 q 192 207 192 241 q 225 115 192 144 q 311 86 258 86 z ",
        },
        b: {
            ha: 814,
            x_min: 118,
            x_max: 737,
            o: "m 454 758 q 570 734 518 758 q 660 661 623 709 q 717 541 696 612 q 737 374 737 469 q 717 206 737 278 q 660 85 696 134 q 570 11 623 36 q 454 -14 518 -14 q 382 -5 414 -14 q 323 19 349 4 q 277 53 297 33 q 241 94 257 73 l 233 94 l 208 0 l 118 0 l 118 1055 l 241 1055 l 241 800 q 241 750 241 777 q 239 700 240 723 q 236 647 237 673 l 241 647 q 277 691 257 671 q 322 726 296 711 q 381 750 349 741 q 454 758 414 758 m 431 656 q 340 638 376 656 q 282 586 304 621 q 251 498 260 551 q 241 374 241 445 q 251 252 241 305 q 282 162 260 199 q 340 107 304 126 q 432 89 377 89 q 567 163 524 89 q 610 375 610 237 q 567 586 610 516 q 431 656 524 656 z ",
        },
        c: {
            ha: 643,
            x_min: 77,
            x_max: 596,
            o: "m 403 -14 q 275 8 334 -14 q 171 76 215 29 q 102 194 127 122 q 77 368 77 267 q 102 549 77 475 q 173 670 128 623 q 279 737 218 716 q 408 758 339 758 q 512 747 461 758 q 596 718 562 735 l 559 614 q 525 627 544 621 q 486 638 506 633 q 446 647 465 644 q 408 650 426 650 q 253 582 302 650 q 204 370 204 513 q 253 160 204 227 q 403 94 302 94 q 502 107 458 94 q 583 136 547 119 l 583 26 q 505 -3 547 7 q 403 -14 463 -14 z ",
        },
        d: {
            ha: 814,
            x_min: 77,
            x_max: 696,
            o: "m 578 100 l 572 100 q 537 56 557 77 q 491 20 517 35 q 433 -5 465 4 q 359 -14 400 -14 q 244 11 296 -14 q 155 84 192 35 q 97 204 117 132 q 77 371 77 276 q 97 539 77 467 q 155 660 117 611 q 244 733 192 709 q 359 758 296 758 q 432 749 399 758 q 491 726 465 741 q 537 691 517 711 q 572 650 557 671 l 581 650 q 576 694 578 673 q 574 730 575 711 q 572 760 572 749 l 572 1055 l 696 1055 l 696 0 l 596 0 l 578 100 m 383 89 q 470 104 435 89 q 528 152 506 120 q 561 232 550 184 q 572 343 571 279 l 572 371 q 563 493 572 439 q 532 582 554 546 q 473 637 510 618 q 382 656 437 656 q 247 582 290 656 q 204 370 204 508 q 247 158 204 228 q 383 89 290 89 z ",
        },
        e: {
            ha: 743,
            x_min: 77,
            x_max: 673,
            o: "m 412 -14 q 276 12 338 -14 q 170 85 214 37 q 101 204 125 133 q 77 367 77 275 q 99 532 77 459 q 162 655 121 604 q 259 732 203 705 q 385 758 316 758 q 505 734 452 758 q 596 666 559 710 q 653 561 633 623 q 673 425 673 500 l 673 349 l 204 349 q 259 156 208 218 q 414 94 311 94 q 478 98 448 94 q 535 107 507 101 q 588 124 562 114 q 640 146 614 134 l 640 36 q 587 14 613 23 q 533 -2 561 4 q 476 -11 506 -8 q 412 -14 447 -14 m 382 656 q 259 603 304 656 q 207 449 214 550 l 541 449 q 531 533 541 495 q 503 599 522 572 q 454 641 484 626 q 382 656 424 656 z ",
        },
        f: {
            ha: 457,
            x_min: 20,
            x_max: 510,
            o: "m 441 652 l 275 652 l 275 0 l 151 0 l 151 652 l 20 652 l 20 703 l 151 749 l 151 814 q 166 934 151 886 q 211 1010 182 982 q 281 1051 239 1039 q 376 1063 323 1063 q 450 1055 416 1063 q 510 1038 484 1047 l 478 942 q 432 955 457 949 q 380 961 408 961 q 334 955 353 961 q 301 932 314 948 q 282 887 288 916 q 275 815 275 859 l 275 745 l 441 745 l 441 652 z ",
        },
        g: {
            ha: 720,
            x_min: 25,
            x_max: 692,
            o: "m 692 745 l 692 668 l 558 650 q 590 589 577 626 q 603 505 603 552 q 587 410 603 454 q 539 334 571 366 q 459 284 507 302 q 349 267 412 267 q 319 267 334 267 q 294 269 304 267 q 272 254 283 262 q 251 235 260 245 q 236 210 242 224 q 231 180 231 197 q 239 151 231 162 q 261 134 247 140 q 294 125 275 127 q 334 123 313 123 l 453 123 q 559 107 516 123 q 630 64 603 92 q 670 0 658 37 q 682 -78 682 -37 q 660 -185 682 -138 q 593 -266 638 -233 q 478 -316 547 -298 q 314 -334 408 -334 q 187 -319 241 -334 q 97 -278 133 -305 q 43 -213 61 -251 q 25 -126 25 -174 q 38 -55 25 -85 q 74 -1 52 -24 q 126 35 96 21 q 187 57 155 50 q 140 98 159 70 q 122 161 122 125 q 144 233 122 203 q 212 292 165 263 q 159 325 183 304 q 118 374 135 347 q 93 434 102 401 q 83 501 83 467 q 100 609 83 562 q 151 690 117 656 q 233 741 184 723 q 349 758 283 758 q 400 754 374 758 q 446 745 427 751 l 692 745 m 142 -122 q 151 -169 142 -147 q 179 -206 159 -191 q 232 -231 199 -222 q 314 -240 265 -240 q 503 -201 440 -240 q 566 -89 566 -162 q 557 -38 566 -58 q 531 -7 549 -18 q 481 8 512 4 q 407 12 451 12 l 288 12 q 238 7 264 12 q 191 -14 212 1 q 156 -55 170 -28 q 142 -122 142 -81 m 204 505 q 241 389 204 427 q 345 351 277 351 q 447 389 412 351 q 482 507 482 427 q 447 630 482 591 q 343 669 411 669 q 240 629 276 669 q 204 505 204 588 z ",
        },
        h: {
            ha: 818,
            x_min: 118,
            x_max: 707,
            o: "m 583 0 l 583 479 q 548 612 583 568 q 436 656 512 656 q 344 638 381 656 q 284 586 307 621 q 251 501 261 551 q 241 386 241 451 l 241 0 l 118 0 l 118 1055 l 241 1055 l 241 742 l 236 644 l 243 644 q 283 695 260 673 q 334 730 307 716 q 393 751 362 745 q 457 758 425 758 q 644 693 581 758 q 707 486 707 629 l 707 0 l 583 0 z ",
        },
        i: {
            ha: 359,
            x_min: 109,
            x_max: 253,
            o: "m 241 0 l 118 0 l 118 745 l 241 745 l 241 0 m 109 946 q 129 1005 109 987 q 180 1023 150 1023 q 209 1019 195 1023 q 232 1005 222 1015 q 247 981 241 996 q 253 946 253 966 q 232 887 253 907 q 180 868 210 868 q 129 887 150 868 q 109 946 109 906 z ",
        },
        j: {
            ha: 359,
            x_min: -46,
            x_max: 253,
            o: "m 45 -334 q -9 -329 12 -334 q -46 -317 -31 -324 l -46 -216 q -10 -227 -28 -223 q 32 -231 8 -231 q 66 -225 50 -231 q 93 -207 81 -220 q 112 -172 105 -194 q 118 -115 118 -150 l 118 745 l 241 745 l 241 -107 q 230 -201 241 -159 q 194 -272 218 -243 q 133 -318 170 -302 q 45 -334 96 -334 m 109 946 q 129 1005 109 987 q 180 1023 150 1023 q 209 1019 195 1023 q 232 1005 222 1015 q 247 981 241 996 q 253 946 253 966 q 232 887 253 907 q 180 868 210 868 q 129 887 150 868 q 109 946 109 906 z ",
        },
        k: {
            ha: 689,
            x_min: 118,
            x_max: 684,
            o: "m 232 385 l 323 500 l 522 745 l 665 745 l 393 422 l 684 0 l 542 0 l 314 342 l 240 286 l 240 0 l 118 0 l 118 1055 l 240 1055 l 240 571 l 229 385 l 232 385 z ",
        },
        l: {
            ha: 359,
            x_min: 118,
            x_max: 241,
            o: "m 241 0 l 118 0 l 118 1055 l 241 1055 l 241 0 z ",
        },
        m: {
            ha: 1244,
            x_min: 118,
            x_max: 1133,
            o: "m 1010 0 l 1010 479 q 976 612 1010 568 q 871 656 942 656 q 787 640 821 656 q 730 593 752 624 q 698 516 708 562 q 688 411 688 471 l 688 0 l 564 0 l 564 479 q 530 612 564 568 q 425 656 496 656 q 338 638 373 656 q 281 586 302 621 q 251 501 260 551 q 241 386 241 451 l 241 0 l 118 0 l 118 745 l 218 745 l 236 644 l 243 644 q 281 695 259 673 q 331 730 304 716 q 388 751 357 745 q 449 758 418 758 q 584 728 530 758 q 664 635 638 699 l 669 635 q 712 690 687 667 q 766 728 736 713 q 828 751 795 743 q 895 758 861 758 q 1073 693 1013 758 q 1133 486 1133 629 l 1133 0 l 1010 0 z ",
        },
        n: {
            ha: 818,
            x_min: 118,
            x_max: 707,
            o: "m 583 0 l 583 479 q 548 612 583 568 q 436 656 512 656 q 344 638 381 656 q 284 586 307 621 q 251 501 261 551 q 241 386 241 451 l 241 0 l 118 0 l 118 745 l 218 745 l 236 644 l 243 644 q 283 695 260 673 q 334 730 307 716 q 393 751 362 745 q 457 758 425 758 q 644 693 581 758 q 707 486 707 629 l 707 0 l 583 0 z ",
        },
        o: {
            ha: 802,
            x_min: 77,
            x_max: 725,
            o: "m 725 374 q 702 209 725 281 q 637 87 680 136 q 534 12 595 38 q 399 -14 474 -14 q 270 12 329 -14 q 168 87 211 38 q 101 209 125 136 q 77 374 77 281 q 99 537 77 466 q 164 658 122 609 q 267 733 206 707 q 403 758 328 758 q 532 733 473 758 q 634 658 591 707 q 701 537 677 609 q 725 374 725 466 m 204 374 q 251 160 204 232 q 401 89 297 89 q 552 160 506 89 q 597 374 597 232 q 551 586 597 515 q 400 656 505 656 q 250 586 296 656 q 204 374 204 515 z ",
        },
        p: {
            ha: 814,
            x_min: 118,
            x_max: 737,
            o: "m 454 -14 q 382 -5 414 -14 q 323 19 349 4 q 277 53 297 33 q 241 94 257 73 l 233 94 q 237 50 235 71 q 240 14 239 33 q 241 -16 241 -5 l 241 -334 l 118 -334 l 118 745 l 218 745 l 236 644 l 241 644 q 277 689 257 668 q 322 725 296 709 q 381 749 349 740 q 454 758 414 758 q 570 734 518 758 q 660 661 623 709 q 717 541 696 612 q 737 374 737 469 q 717 206 737 278 q 660 85 696 134 q 570 11 623 36 q 454 -14 518 -14 m 431 656 q 343 640 379 656 q 286 593 308 625 q 253 513 264 561 q 241 401 243 466 l 241 374 q 251 252 241 305 q 282 162 260 199 q 340 107 304 126 q 432 89 377 89 q 567 163 524 89 q 610 375 610 237 q 567 586 610 516 q 431 656 524 656 z ",
        },
        q: {
            ha: 814,
            x_min: 77,
            x_max: 696,
            o: "m 383 89 q 470 104 435 89 q 528 152 506 120 q 561 232 550 184 q 572 343 571 279 l 572 371 q 563 493 572 439 q 532 582 554 546 q 473 637 510 618 q 382 656 437 656 q 247 582 290 656 q 204 370 204 508 q 247 158 204 228 q 383 89 290 89 m 359 -14 q 244 11 296 -14 q 155 84 192 35 q 97 204 117 132 q 77 371 77 276 q 97 539 77 467 q 155 660 117 611 q 244 733 192 709 q 359 758 296 758 q 432 749 399 758 q 490 725 465 740 q 536 689 516 709 q 572 644 557 668 l 578 644 l 596 745 l 696 745 l 696 -334 l 572 -334 l 572 -16 q 574 17 572 -3 q 576 55 575 37 q 581 100 578 77 l 572 100 q 537 56 557 77 q 491 20 517 35 q 433 -5 465 4 q 359 -14 400 -14 z ",
        },
        r: {
            ha: 554,
            x_min: 118,
            x_max: 526,
            o: "m 440 758 q 484 756 460 758 q 526 751 509 755 l 510 637 q 471 643 491 641 q 433 646 451 646 q 355 628 391 646 q 295 579 320 611 q 255 502 269 547 q 241 401 241 456 l 241 0 l 118 0 l 118 745 l 218 745 l 233 608 l 239 608 q 275 665 256 637 q 318 712 294 692 q 372 746 342 733 q 440 758 402 758 z ",
        },
        s: {
            ha: 627,
            x_min: 61,
            x_max: 564,
            o: "m 564 204 q 544 109 564 150 q 487 41 524 68 q 398 0 450 14 q 281 -14 346 -14 q 154 -2 207 -14 q 61 33 101 10 l 61 146 q 108 124 82 135 q 162 106 134 114 q 221 93 191 98 q 281 87 251 87 q 356 95 325 87 q 406 116 387 102 q 434 150 425 130 q 443 195 443 170 q 436 233 443 216 q 411 266 429 250 q 362 300 393 283 q 282 338 331 317 q 194 379 233 359 q 128 425 155 399 q 86 483 101 450 q 72 561 72 515 q 91 645 72 608 q 143 707 109 682 q 224 745 177 732 q 330 758 272 758 q 451 744 396 758 q 554 707 505 730 l 511 607 q 421 641 467 627 q 326 656 374 656 q 224 633 257 656 q 191 568 191 610 q 198 527 191 545 q 225 494 206 509 q 276 462 244 478 q 355 427 308 446 q 443 386 404 406 q 508 340 481 366 q 549 282 535 315 q 564 204 564 250 z ",
        },
        t: {
            ha: 471,
            x_min: 22,
            x_max: 444,
            o: "m 343 87 q 371 89 355 87 q 400 92 386 90 q 426 97 414 94 q 444 102 438 100 l 444 8 q 422 0 435 4 q 392 -7 408 -4 q 358 -12 376 -10 q 323 -14 340 -14 q 245 -3 281 -14 q 183 35 210 8 q 142 106 157 61 q 127 220 127 151 l 127 652 l 22 652 l 22 707 l 127 760 l 180 916 l 252 916 l 252 745 l 439 745 l 439 652 l 252 652 l 252 220 q 273 121 252 154 q 343 87 294 87 z ",
        },
        u: {
            ha: 818,
            x_min: 111,
            x_max: 700,
            o: "m 600 0 l 582 100 l 575 100 q 535 49 558 71 q 484 14 511 28 q 425 -7 456 0 q 361 -14 393 -14 q 252 2 299 -14 q 174 51 205 18 q 127 136 143 84 q 111 258 111 187 l 111 745 l 235 745 l 235 265 q 271 133 235 177 q 382 89 307 89 q 474 106 437 89 q 534 158 511 124 q 567 243 557 193 q 576 358 576 293 l 576 745 l 700 745 l 700 0 l 600 0 z ",
        },
        v: {
            ha: 665,
            x_min: 0,
            x_max: 665,
            o: "m 254 0 l 0 745 l 127 745 l 262 330 q 280 273 270 308 q 300 201 290 237 q 319 132 311 164 q 330 82 328 99 l 335 82 q 347 132 338 99 q 365 201 355 164 q 385 273 375 237 q 403 330 395 308 l 538 745 l 665 745 l 411 0 l 254 0 z ",
        },
        w: {
            ha: 1036,
            x_min: 14,
            x_max: 1022,
            o: "m 684 0 l 570 418 q 563 445 567 430 q 555 477 559 460 q 546 512 551 494 q 538 547 542 530 q 518 628 528 586 l 514 628 q 496 546 505 585 q 480 476 488 512 q 464 415 471 440 l 347 0 l 204 0 l 14 745 l 143 745 l 232 349 q 245 282 239 318 q 258 212 252 247 q 269 146 264 177 q 277 96 274 115 l 281 96 q 290 143 284 113 q 303 205 296 172 q 317 270 310 238 q 332 325 325 302 l 453 745 l 586 745 l 703 325 q 716 271 709 302 q 732 207 724 239 q 745 145 739 175 q 754 96 751 115 l 758 96 q 765 143 760 113 q 775 208 769 172 q 788 280 781 243 q 803 349 795 317 l 896 745 l 1022 745 l 829 0 l 684 0 z ",
        },
        x: {
            ha: 694,
            x_min: 24,
            x_max: 669,
            o: "m 277 382 l 37 745 l 178 745 l 347 467 l 517 745 l 657 745 l 416 382 l 669 0 l 529 0 l 347 296 l 164 0 l 24 0 l 277 382 z ",
        },
        y: {
            ha: 679,
            x_min: 7,
            x_max: 672,
            o: "m 7 745 l 135 745 l 281 329 q 300 272 290 302 q 318 212 310 242 q 333 155 327 182 q 342 103 339 127 l 346 103 q 357 149 349 120 q 373 211 364 178 q 392 276 382 244 q 410 330 402 308 l 545 745 l 672 745 l 378 -97 q 337 -196 359 -152 q 286 -270 315 -239 q 217 -317 256 -300 q 123 -334 178 -334 q 62 -330 88 -334 q 18 -322 37 -326 l 18 -224 q 54 -230 33 -227 q 100 -232 76 -232 q 156 -224 132 -232 q 197 -201 180 -216 q 228 -165 215 -186 q 250 -115 240 -143 l 289 -7 l 7 745 z ",
        },
        z: {
            ha: 612,
            x_min: 56,
            x_max: 557,
            o: "m 557 0 l 56 0 l 56 85 l 419 652 l 78 652 l 78 745 l 545 745 l 545 646 l 189 93 l 557 93 l 557 0 z ",
        },
        "{": {
            ha: 492,
            x_min: 41,
            x_max: 457,
            o: "m 339 -11 q 347 -64 339 -43 q 371 -97 355 -85 q 408 -113 386 -109 q 457 -118 430 -117 l 457 -220 q 360 -208 405 -219 q 284 -173 316 -197 q 233 -111 252 -149 q 215 -21 215 -74 l 215 205 q 171 304 215 275 q 41 334 126 334 l 41 439 q 171 468 126 439 q 215 567 215 497 l 215 793 q 233 883 215 846 q 284 944 252 920 q 360 980 316 968 q 457 991 405 991 l 457 890 q 408 885 430 890 q 371 869 386 881 q 347 837 355 857 q 339 783 339 816 l 339 558 q 299 445 339 487 q 180 390 258 404 l 180 382 q 299 327 258 368 q 339 214 339 286 l 339 -11 z ",
        },
        "|": {
            ha: 765,
            x_min: 332,
            x_max: 433,
            o: "m 332 1055 l 433 1055 l 433 -334 l 332 -334 l 332 1055 z ",
        },
        "}": {
            ha: 492,
            x_min: 35,
            x_max: 450,
            o: "m 153 214 q 193 327 153 286 q 311 382 233 368 l 311 390 q 193 445 233 404 q 153 558 153 487 l 153 783 q 144 837 153 816 q 121 869 136 857 q 84 885 106 881 q 35 890 62 890 l 35 991 q 131 980 87 991 q 208 944 176 968 q 258 883 240 920 q 277 793 277 846 l 277 567 q 288 507 277 532 q 321 468 299 483 q 376 446 343 452 q 450 439 408 439 l 450 334 q 321 304 366 334 q 277 205 277 275 l 277 -21 q 258 -111 277 -74 q 208 -173 240 -149 q 131 -208 176 -197 q 35 -220 87 -219 l 35 -118 q 84 -113 62 -117 q 121 -97 106 -109 q 144 -64 136 -85 q 153 -11 153 -43 l 153 214 z ",
        },
        "~": {
            ha: 765,
            x_min: 69,
            x_max: 696,
            o: "m 359 441 q 316 460 334 452 q 282 472 297 467 q 253 478 267 476 q 224 479 239 479 q 184 473 205 479 q 144 455 164 467 q 105 429 124 444 q 69 397 86 414 l 69 507 q 237 581 137 581 q 274 579 257 581 q 310 573 291 577 q 351 560 328 568 q 406 538 374 551 q 449 519 431 527 q 484 508 468 512 q 513 502 500 503 q 542 500 527 500 q 581 506 561 500 q 622 524 602 513 q 661 550 642 535 q 696 582 680 565 l 696 472 q 528 399 629 399 q 491 400 508 399 q 455 407 474 402 q 414 419 437 411 q 359 441 391 428 z ",
        },
        " ": { ha: 361, x_min: 0, x_max: 0, o: "" },
        "¡": {
            ha: 374,
            x_min: 100,
            x_max: 272,
            o: "m 144 458 l 227 458 l 261 -253 l 110 -253 l 144 458 m 272 664 q 266 620 272 638 q 247 591 259 602 q 220 575 235 580 q 186 570 204 570 q 153 575 168 570 q 125 591 137 580 q 106 620 113 602 q 100 664 100 638 q 106 707 100 689 q 125 736 113 724 q 153 753 137 747 q 186 758 168 758 q 220 753 204 758 q 247 736 235 747 q 266 707 259 724 q 272 664 272 689 z ",
        },
        "¢": {
            ha: 765,
            x_min: 127,
            x_max: 647,
            o: "m 635 163 q 564 134 601 144 q 475 122 526 123 l 475 -14 l 382 -14 l 382 126 q 276 160 323 135 q 196 231 229 186 q 145 343 163 276 q 127 504 127 411 q 145 668 127 600 q 196 783 163 737 q 276 855 229 829 q 382 889 323 880 l 382 1006 l 475 1006 l 475 895 q 570 881 524 892 q 647 854 616 870 l 611 750 q 576 763 595 757 q 537 775 557 770 q 497 783 517 780 q 460 786 477 786 q 304 718 353 786 q 256 505 256 649 q 305 297 256 363 q 454 231 354 231 q 554 243 509 231 q 635 272 598 256 l 635 163 z ",
        },
        "£": {
            ha: 765,
            x_min: 46,
            x_max: 718,
            o: "m 452 1004 q 583 989 524 1004 q 686 950 642 974 l 642 852 q 556 887 604 872 q 457 901 508 901 q 397 892 425 901 q 349 863 369 883 q 317 811 328 843 q 306 734 306 779 l 306 534 l 561 534 l 561 441 l 306 441 l 306 298 q 298 226 306 256 q 277 174 290 196 q 247 138 264 153 q 211 113 229 123 l 718 113 l 718 0 l 46 0 l 46 104 q 101 126 76 112 q 143 163 125 140 q 172 219 161 186 q 182 297 182 252 l 182 441 l 47 441 l 47 534 l 182 534 l 182 754 q 202 860 182 813 q 257 938 222 906 q 342 987 293 970 q 452 1004 392 1004 z ",
        },
        "¤": {
            ha: 765,
            x_min: 83,
            x_max: 681,
            o: "m 126 490 q 138 567 126 530 q 171 635 150 603 l 83 722 l 150 789 l 236 701 q 304 735 268 723 q 382 747 341 747 q 459 735 422 747 q 526 701 495 723 l 614 789 l 681 724 l 593 636 q 626 568 614 605 q 639 490 639 531 q 627 412 639 450 q 593 345 615 375 l 679 258 l 614 193 l 526 279 q 459 247 495 258 q 382 235 422 235 q 304 247 341 235 q 236 281 267 259 l 150 195 l 85 260 l 171 346 q 138 413 150 376 q 126 490 126 450 m 220 490 q 232 427 220 456 q 267 375 245 397 q 318 340 289 353 q 382 328 348 328 q 447 340 416 328 q 499 375 477 353 q 534 427 522 397 q 547 490 547 456 q 534 555 547 525 q 499 608 522 585 q 447 643 477 630 q 382 656 416 656 q 318 643 348 656 q 267 608 289 630 q 232 555 245 585 q 220 490 220 525 z ",
        },
        "¥": {
            ha: 765,
            x_min: 20,
            x_max: 746,
            o: "m 382 490 l 616 991 l 746 991 l 485 471 l 647 471 l 647 378 l 443 378 l 443 271 l 647 271 l 647 178 l 443 178 l 443 0 l 322 0 l 322 178 l 118 178 l 118 271 l 322 271 l 322 378 l 118 378 l 118 471 l 277 471 l 20 991 l 150 991 l 382 490 z ",
        },
        "¦": {
            ha: 765,
            x_min: 332,
            x_max: 433,
            o: "m 332 1055 l 433 1055 l 433 526 l 332 526 l 332 1055 m 332 196 l 433 196 l 433 -334 l 332 -334 l 332 196 z ",
        },
        "§": {
            ha: 675,
            x_min: 82,
            x_max: 585,
            o: "m 93 549 q 102 611 93 583 q 126 661 111 640 q 161 698 141 683 q 201 724 180 713 q 122 788 151 749 q 93 884 93 827 q 112 958 93 925 q 164 1015 130 991 q 245 1050 198 1038 q 351 1063 293 1063 q 470 1049 417 1063 q 572 1014 524 1036 l 534 920 q 443 952 490 939 q 347 965 395 965 q 245 945 280 965 q 211 886 211 925 q 219 852 211 868 q 247 822 227 837 q 298 791 266 807 q 378 755 330 775 q 465 714 427 736 q 530 666 503 692 q 570 607 556 640 q 585 532 585 574 q 576 468 585 496 q 554 416 568 439 q 523 376 541 393 q 486 349 505 359 q 559 286 533 323 q 585 196 585 249 q 565 110 585 148 q 508 46 545 73 q 419 7 471 20 q 301 -7 366 -7 q 175 4 228 -7 q 82 37 122 16 l 82 144 q 129 124 103 134 q 183 106 155 114 q 242 94 212 98 q 301 89 272 89 q 379 97 348 89 q 430 118 411 105 q 456 149 448 132 q 464 184 464 166 q 458 219 464 204 q 435 250 452 235 q 386 282 418 265 q 302 319 354 298 q 214 361 253 340 q 148 409 175 382 q 107 469 121 435 q 93 549 93 503 m 197 564 q 207 517 197 538 q 238 476 216 496 q 293 438 259 456 q 376 399 328 419 l 400 389 q 428 410 414 397 q 453 437 441 422 q 471 472 464 453 q 478 514 478 491 q 469 562 478 540 q 438 603 460 583 q 380 643 416 623 q 288 682 343 662 q 255 666 271 677 q 226 641 239 656 q 205 606 213 625 q 197 564 197 586 z ",
        },
        "¨": {
            ha: 802,
            x_min: 208,
            x_max: 593,
            o: "m 208 946 q 227 999 208 983 q 273 1015 246 1015 q 319 999 300 1015 q 339 946 339 982 q 319 893 339 910 q 273 876 300 876 q 227 893 246 876 q 208 946 208 910 m 463 946 q 481 999 463 983 q 528 1015 500 1015 q 552 1011 541 1015 q 573 999 564 1007 q 587 977 582 990 q 593 946 593 964 q 573 893 593 910 q 528 876 553 876 q 481 893 500 876 q 463 946 463 910 z ",
        },
        "©": {
            ha: 1156,
            x_min: 68,
            x_max: 1088,
            o: "m 604 714 q 531 699 563 714 q 477 656 499 684 q 445 587 456 628 q 433 496 433 547 q 443 402 433 443 q 474 334 453 361 q 527 291 494 306 q 604 277 559 277 q 638 279 620 277 q 676 286 656 281 q 714 295 695 290 q 750 307 733 301 l 750 218 q 717 205 734 211 q 683 194 701 199 q 644 187 665 189 q 600 184 624 184 q 479 207 531 184 q 394 271 428 229 q 342 370 359 312 q 325 497 325 427 q 343 622 325 566 q 397 720 361 679 q 485 783 433 760 q 604 806 536 806 q 692 794 647 806 q 777 764 736 783 l 734 677 q 666 705 699 695 q 604 714 633 714 m 68 496 q 86 631 68 566 q 137 753 104 696 q 217 857 170 810 q 320 936 264 903 q 442 987 377 969 q 578 1006 507 1006 q 713 987 648 1006 q 835 936 779 969 q 939 857 892 903 q 1018 753 985 810 q 1069 631 1051 696 q 1088 496 1088 566 q 1069 360 1088 425 q 1018 238 1051 295 q 939 135 985 182 q 835 56 892 89 q 713 4 779 22 q 578 -14 648 -14 q 442 4 507 -14 q 320 56 377 22 q 217 135 264 89 q 137 238 170 182 q 86 360 104 295 q 68 496 68 425 m 142 496 q 176 326 142 406 q 270 188 210 247 q 408 94 329 128 q 578 60 488 60 q 747 94 668 60 q 886 188 827 128 q 980 326 945 247 q 1014 496 1014 406 q 980 665 1014 586 q 886 804 945 745 q 747 898 827 863 q 578 932 668 932 q 408 898 488 932 q 270 804 329 863 q 176 665 210 745 q 142 496 142 586 z ",
        },
        ª: {
            ha: 460,
            x_min: 46,
            x_max: 392,
            o: "m 330 541 l 311 596 q 286 569 299 581 q 257 549 273 557 q 222 536 241 541 q 179 532 203 532 q 125 541 150 532 q 83 567 101 549 q 56 610 66 584 q 46 671 46 636 q 94 777 46 740 q 239 817 142 813 l 306 819 l 306 840 q 285 908 306 889 q 227 928 264 928 q 163 918 194 928 q 104 893 133 908 l 72 961 q 146 991 106 979 q 227 1003 186 1003 q 352 967 311 1003 q 392 848 392 931 l 392 541 l 330 541 m 256 753 q 198 745 221 751 q 161 728 175 739 q 141 704 147 718 q 135 669 135 689 q 152 620 135 635 q 197 604 169 604 q 240 611 220 604 q 274 633 260 618 q 297 670 289 648 q 306 722 306 692 l 306 755 l 256 753 z ",
        },
        "«": {
            ha: 676,
            x_min: 56,
            x_max: 621,
            o: "m 56 375 l 265 656 l 345 603 l 183 367 l 345 131 l 265 78 l 56 357 l 56 375 m 332 375 l 542 656 l 621 603 l 460 367 l 621 131 l 542 78 l 332 357 l 332 375 z ",
        },
        "¬": {
            ha: 765,
            x_min: 69,
            x_max: 696,
            o: "m 696 541 l 696 178 l 595 178 l 595 439 l 69 439 l 69 541 l 696 541 z ",
        },
        "­": {
            ha: 447,
            x_min: 56,
            x_max: 392,
            o: "m 56 315 l 56 429 l 392 429 l 392 315 l 56 315 z ",
        },
        "®": {
            ha: 1156,
            x_min: 68,
            x_max: 1088,
            o: "m 504 521 l 553 521 q 641 545 614 521 q 668 616 668 570 q 640 687 668 667 q 551 707 612 707 l 504 707 l 504 521 m 777 619 q 767 561 777 587 q 743 516 758 536 q 708 484 728 497 q 668 461 689 470 q 749 328 713 387 q 779 278 765 302 q 805 235 793 254 q 823 205 816 216 l 829 193 l 708 193 l 568 432 l 504 432 l 504 193 l 397 193 l 397 799 l 555 799 q 723 755 669 799 q 777 619 777 711 m 68 496 q 86 631 68 566 q 137 753 104 696 q 217 857 170 810 q 320 936 264 903 q 442 987 377 969 q 578 1006 507 1006 q 713 987 648 1006 q 835 936 779 969 q 939 857 892 903 q 1018 753 985 810 q 1069 631 1051 696 q 1088 496 1088 566 q 1069 360 1088 425 q 1018 238 1051 295 q 939 135 985 182 q 835 56 892 89 q 713 4 779 22 q 578 -14 648 -14 q 442 4 507 -14 q 320 56 377 22 q 217 135 264 89 q 137 238 170 182 q 86 360 104 295 q 68 496 68 425 m 142 496 q 176 326 142 406 q 270 188 210 247 q 408 94 329 128 q 578 60 488 60 q 747 94 668 60 q 886 188 827 128 q 980 326 945 247 q 1014 496 1014 406 q 980 665 1014 586 q 886 804 945 745 q 747 898 827 863 q 578 932 668 932 q 408 898 488 932 q 270 804 329 863 q 176 665 210 745 q 142 496 142 586 z ",
        },
        "¯": {
            ha: 694,
            x_min: -4,
            x_max: 699,
            o: "m 699 1055 l -4 1055 l -4 1150 l 699 1150 l 699 1055 z ",
        },
        "°": {
            ha: 595,
            x_min: 83,
            x_max: 511,
            o: "m 83 791 q 100 874 83 836 q 146 943 117 913 q 214 989 175 972 q 297 1006 253 1006 q 380 989 341 1006 q 448 943 419 972 q 494 874 477 913 q 511 791 511 836 q 494 709 511 747 q 448 641 477 670 q 380 596 419 612 q 297 579 341 579 q 214 596 253 579 q 146 641 175 612 q 100 709 117 670 q 83 791 83 747 m 167 791 q 177 742 167 765 q 205 701 187 718 q 246 673 222 683 q 297 663 270 663 q 348 673 324 663 q 390 701 372 683 q 418 742 408 718 q 428 791 428 765 q 418 843 428 819 q 390 885 408 867 q 348 913 372 903 q 297 924 324 924 q 246 913 270 924 q 205 885 222 903 q 177 843 187 867 q 167 791 167 819 z ",
        },
        "±": {
            ha: 765,
            x_min: 69,
            x_max: 696,
            o: "m 332 439 l 69 439 l 69 541 l 332 541 l 332 804 l 433 804 l 433 541 l 696 541 l 696 439 l 433 439 l 433 178 l 332 178 l 332 439 m 69 0 l 69 102 l 696 102 l 696 0 l 69 0 z ",
        },
        "²": {
            ha: 460,
            x_min: 33,
            x_max: 421,
            o: "m 421 397 l 33 397 l 33 473 l 175 628 q 238 699 214 670 q 276 750 262 728 q 295 793 290 773 q 300 836 300 813 q 277 902 300 880 q 218 924 255 924 q 152 907 184 924 q 90 864 121 890 l 37 928 q 119 982 74 960 q 220 1004 164 1004 q 293 993 260 1004 q 349 961 326 982 q 385 908 372 939 q 397 839 397 878 q 388 776 397 805 q 359 718 378 747 q 314 658 341 689 q 253 590 288 627 l 153 484 l 421 484 l 421 397 z ",
        },
        "³": {
            ha: 460,
            x_min: 21,
            x_max: 418,
            o: "m 400 851 q 373 762 400 798 q 298 710 345 726 q 388 659 358 694 q 418 570 418 623 q 404 495 418 529 q 362 437 391 461 q 292 399 334 413 q 191 386 249 386 q 101 394 143 386 q 21 424 60 403 l 21 514 q 109 478 64 491 q 189 465 153 465 q 286 493 256 465 q 315 571 315 522 q 279 644 315 621 q 178 667 243 667 l 111 667 l 111 743 l 178 743 q 269 770 240 743 q 297 840 297 797 q 290 878 297 862 q 272 904 283 894 q 244 919 260 914 q 210 924 228 924 q 137 910 170 924 q 69 871 104 896 l 22 936 q 63 964 42 951 q 106 985 83 977 q 155 999 130 994 q 211 1004 181 1004 q 293 993 258 1004 q 353 961 329 981 q 388 912 376 941 q 400 851 400 884 z ",
        },
        "´": {
            ha: 802,
            x_min: 267,
            x_max: 533,
            o: "m 267 860 q 297 905 281 880 q 329 959 313 931 q 359 1014 345 986 q 385 1064 374 1041 l 533 1064 l 533 1050 q 501 1004 522 1031 q 453 947 479 977 q 400 889 427 917 q 349 842 372 861 l 267 842 l 267 860 z ",
        },
        µ: {
            ha: 825,
            x_min: 118,
            x_max: 707,
            o: "m 241 265 q 277 133 241 177 q 389 89 313 89 q 481 106 444 89 q 542 158 519 124 q 574 243 564 193 q 583 358 583 293 l 583 745 l 707 745 l 707 0 l 607 0 l 589 100 l 582 100 q 500 15 549 43 q 382 -14 452 -14 q 297 2 333 -14 q 237 46 261 18 q 239 -7 238 20 q 241 -59 241 -31 q 241 -117 241 -88 l 241 -334 l 118 -334 l 118 745 l 241 745 l 241 265 z ",
        },
        "¶": {
            ha: 909,
            x_min: 77,
            x_max: 764,
            o: "m 764 -176 l 682 -176 l 682 947 l 542 947 l 542 -176 l 460 -176 l 460 379 q 361 367 418 367 q 244 384 296 367 q 154 441 191 401 q 97 547 117 481 q 77 707 77 612 q 99 873 77 806 q 161 981 121 940 q 258 1038 201 1021 q 382 1055 314 1055 l 764 1055 l 764 -176 z ",
        },
        "·": {
            ha: 372,
            x_min: 100,
            x_max: 272,
            o: "m 100 490 q 106 534 100 516 q 125 563 113 552 q 152 579 136 574 q 186 585 168 585 q 219 579 203 585 q 247 563 235 574 q 265 534 258 552 q 272 490 272 516 q 265 448 272 465 q 247 418 258 430 q 219 401 235 407 q 186 396 203 396 q 152 401 168 396 q 125 418 136 407 q 106 448 113 430 q 100 490 100 465 z ",
        },
        "¸": {
            ha: 285,
            x_min: 24,
            x_max: 277,
            o: "m 277 -195 q 229 -297 277 -260 q 79 -334 181 -334 q 49 -332 64 -334 q 24 -328 34 -330 l 24 -254 q 51 -257 34 -256 q 78 -258 67 -258 q 153 -244 126 -258 q 180 -196 180 -229 q 172 -168 180 -180 q 147 -147 163 -156 q 110 -132 132 -138 q 64 -119 89 -125 l 125 0 l 207 0 l 168 -78 q 211 -92 191 -83 q 245 -115 231 -101 q 268 -149 260 -129 q 277 -195 277 -168 z ",
        },
        "¹": {
            ha: 460,
            x_min: 43,
            x_max: 300,
            o: "m 208 991 l 300 991 l 300 397 l 201 397 l 201 747 q 202 785 201 764 q 203 825 202 805 q 205 865 204 846 q 207 899 206 884 q 184 873 197 887 q 157 848 172 860 l 93 799 l 43 864 l 208 991 z ",
        },
        º: {
            ha: 486,
            x_min: 45,
            x_max: 441,
            o: "m 441 768 q 428 668 441 712 q 388 594 414 624 q 325 548 361 564 q 241 532 288 532 q 163 548 199 532 q 100 594 127 564 q 59 668 74 624 q 45 768 45 712 q 59 868 45 825 q 98 942 73 912 q 161 987 124 972 q 244 1003 198 1003 q 322 987 286 1003 q 385 942 359 972 q 427 868 412 912 q 441 768 441 825 m 134 768 q 159 647 134 688 q 243 606 184 606 q 326 647 300 606 q 351 768 351 688 q 326 889 351 850 q 243 928 300 928 q 159 889 184 928 q 134 768 134 850 z ",
        },
        "»": {
            ha: 676,
            x_min: 57,
            x_max: 623,
            o: "m 623 357 l 412 78 l 334 131 l 494 367 l 334 603 l 412 656 l 623 375 l 623 357 m 346 357 l 136 78 l 57 131 l 218 367 l 57 603 l 136 656 l 346 375 l 346 357 z ",
        },
        "¼": {
            ha: 1023,
            x_min: 43,
            x_max: 962,
            o: "m 208 991 l 300 991 l 300 397 l 201 397 l 201 747 q 202 785 201 764 q 203 825 202 805 q 205 865 204 846 q 207 899 206 884 q 184 873 197 887 q 157 848 172 860 l 93 799 l 43 864 l 208 991 m 815 991 l 265 0 l 159 0 l 709 991 l 815 991 m 962 131 l 878 131 l 878 1 l 781 1 l 781 131 l 526 131 l 526 206 l 782 599 l 878 599 l 878 217 l 962 217 l 962 131 m 781 217 l 781 349 q 782 411 781 378 q 785 478 783 445 q 774 458 781 471 q 760 431 768 446 q 745 404 753 417 q 730 380 736 390 l 625 217 l 781 217 z ",
        },
        "½": {
            ha: 1023,
            x_min: 30,
            x_max: 977,
            o: "m 195 991 l 287 991 l 287 397 l 189 397 l 189 747 q 189 785 189 764 q 190 825 189 805 q 192 865 191 846 q 194 899 193 884 q 172 873 184 887 q 144 848 159 860 l 80 799 l 30 864 l 195 991 m 801 991 l 251 0 l 144 0 l 694 991 l 801 991 m 977 1 l 589 1 l 589 77 l 730 231 q 793 302 769 273 q 831 354 818 331 q 850 396 845 376 q 855 439 855 416 q 833 505 855 484 q 774 527 810 527 q 708 510 739 527 q 646 467 676 493 l 593 531 q 674 586 629 564 q 775 608 720 608 q 849 596 816 608 q 905 564 882 585 q 940 512 928 543 q 953 442 953 481 q 943 379 953 408 q 915 321 933 350 q 870 261 897 292 q 808 193 843 231 l 708 87 l 977 87 l 977 1 z ",
        },
        "¾": {
            ha: 1023,
            x_min: 21,
            x_max: 1008,
            o: "m 400 851 q 373 762 400 798 q 298 710 345 726 q 388 659 358 694 q 418 570 418 623 q 404 495 418 529 q 362 437 391 461 q 292 399 334 413 q 191 386 249 386 q 101 394 143 386 q 21 424 60 403 l 21 514 q 109 478 64 491 q 189 465 153 465 q 286 493 256 465 q 315 571 315 522 q 279 644 315 621 q 178 667 243 667 l 111 667 l 111 743 l 178 743 q 269 770 240 743 q 297 840 297 797 q 290 878 297 862 q 272 904 283 894 q 244 919 260 914 q 210 924 228 924 q 137 910 170 924 q 69 871 104 896 l 22 936 q 63 964 42 951 q 106 985 83 977 q 155 999 130 994 q 211 1004 181 1004 q 293 993 258 1004 q 353 961 329 981 q 388 912 376 941 q 400 851 400 884 m 879 991 l 329 0 l 222 0 l 772 991 l 879 991 m 1008 131 l 923 131 l 923 1 l 826 1 l 826 131 l 572 131 l 572 206 l 827 599 l 923 599 l 923 217 l 1008 217 l 1008 131 m 826 217 l 826 349 q 827 411 826 378 q 830 478 828 445 q 820 458 827 471 q 806 431 813 446 q 790 404 798 417 q 776 380 782 390 l 671 217 l 826 217 z ",
        },
        "¿": {
            ha: 591,
            x_min: 46,
            x_max: 567,
            o: "m 401 458 l 401 433 q 396 363 401 394 q 377 305 391 332 q 342 252 364 277 q 286 197 320 226 q 231 147 254 170 q 193 101 208 124 q 171 51 178 77 q 164 -12 164 24 q 174 -72 164 -45 q 204 -118 184 -98 q 252 -148 223 -137 q 318 -158 281 -158 q 426 -141 375 -158 q 524 -100 477 -123 l 567 -199 q 448 -247 511 -227 q 318 -267 384 -267 q 204 -249 255 -267 q 119 -199 154 -232 q 65 -120 84 -167 q 46 -14 46 -73 q 55 68 46 33 q 82 134 64 103 q 128 193 100 164 q 192 253 155 221 q 250 306 227 283 q 285 350 272 329 q 302 394 298 371 q 307 446 307 416 l 307 458 l 401 458 m 433 664 q 427 620 433 638 q 409 591 420 602 q 381 575 397 580 q 347 570 366 570 q 314 575 330 570 q 287 591 298 580 q 268 620 275 602 q 261 664 261 638 q 268 707 261 689 q 287 736 275 724 q 314 753 298 747 q 347 758 330 758 q 381 753 366 758 q 409 736 397 747 q 427 707 420 724 q 433 664 433 689 z ",
        },
        À: {
            ha: 844,
            x_min: 0,
            x_max: 844,
            o: "m 715 0 l 607 307 l 237 307 l 127 0 l 0 0 l 364 996 l 479 996 l 844 0 l 715 0 m 567 419 l 467 707 q 457 736 463 719 q 445 774 451 754 q 433 817 439 795 q 421 860 427 839 q 410 817 416 839 q 398 773 404 794 q 386 735 392 753 q 376 707 380 718 l 278 419 l 567 419 m 488 1071 l 406 1071 q 355 1118 382 1090 q 301 1176 327 1146 q 253 1233 275 1206 q 221 1279 232 1261 l 221 1293 l 370 1293 q 395 1243 380 1270 q 425 1188 410 1215 q 457 1135 441 1160 q 488 1089 473 1109 l 488 1071 z ",
        },
        Á: {
            ha: 844,
            x_min: 0,
            x_max: 844,
            o: "m 715 0 l 607 307 l 237 307 l 127 0 l 0 0 l 364 996 l 479 996 l 844 0 l 715 0 m 567 419 l 467 707 q 457 736 463 719 q 445 774 451 754 q 433 817 439 795 q 421 860 427 839 q 410 817 416 839 q 398 773 404 794 q 386 735 392 753 q 376 707 380 718 l 278 419 l 567 419 m 362 1089 q 393 1135 377 1109 q 425 1188 409 1160 q 455 1243 441 1215 q 480 1293 469 1270 l 629 1293 l 629 1279 q 596 1233 618 1261 q 549 1176 575 1206 q 495 1118 523 1146 q 444 1071 468 1090 l 362 1071 l 362 1089 z ",
        },
        Â: {
            ha: 844,
            x_min: 0,
            x_max: 844,
            o: "m 715 0 l 607 307 l 237 307 l 127 0 l 0 0 l 364 996 l 479 996 l 844 0 l 715 0 m 567 419 l 467 707 q 457 736 463 719 q 445 774 451 754 q 433 817 439 795 q 421 860 427 839 q 410 817 416 839 q 398 773 404 794 q 386 735 392 753 q 376 707 380 718 l 278 419 l 567 419 m 646 1071 l 564 1071 q 493 1127 530 1094 q 421 1197 456 1160 q 349 1127 385 1160 q 278 1071 313 1094 l 196 1071 l 196 1089 q 237 1135 214 1109 q 282 1188 260 1160 q 325 1243 305 1215 q 356 1293 345 1270 l 486 1293 q 517 1243 497 1270 q 560 1188 537 1215 q 606 1135 583 1160 q 646 1089 629 1109 l 646 1071 z ",
        },
        Ã: {
            ha: 844,
            x_min: 0,
            x_max: 844,
            o: "m 715 0 l 607 307 l 237 307 l 127 0 l 0 0 l 364 996 l 479 996 l 844 0 l 715 0 m 567 419 l 467 707 q 457 736 463 719 q 445 774 451 754 q 433 817 439 795 q 421 860 427 839 q 410 817 416 839 q 398 773 404 794 q 386 735 392 753 q 376 707 380 718 l 278 419 l 567 419 m 524 1072 q 470 1084 496 1072 q 417 1111 443 1096 q 368 1137 391 1125 q 322 1149 344 1149 q 275 1131 292 1149 q 250 1071 259 1113 l 179 1071 q 194 1145 182 1112 q 223 1201 205 1178 q 266 1237 241 1225 q 322 1250 291 1250 q 378 1238 351 1250 q 431 1212 406 1226 q 480 1186 457 1198 q 524 1174 504 1174 q 570 1192 554 1174 q 595 1251 585 1209 l 667 1251 q 652 1178 663 1211 q 623 1121 641 1145 q 580 1085 605 1098 q 524 1072 555 1072 z ",
        },
        Ä: {
            ha: 844,
            x_min: 0,
            x_max: 844,
            o: "m 715 0 l 607 307 l 237 307 l 127 0 l 0 0 l 364 996 l 479 996 l 844 0 l 715 0 m 567 419 l 467 707 q 457 736 463 719 q 445 774 451 754 q 433 817 439 795 q 421 860 427 839 q 410 817 416 839 q 398 773 404 794 q 386 735 392 753 q 376 707 380 718 l 278 419 l 567 419 m 231 1175 q 250 1228 231 1212 q 296 1244 269 1244 q 342 1228 322 1244 q 361 1175 361 1211 q 342 1122 361 1139 q 296 1105 322 1105 q 250 1122 269 1105 q 231 1175 231 1139 m 485 1175 q 504 1228 485 1212 q 550 1244 523 1244 q 575 1240 563 1244 q 595 1228 587 1236 q 610 1206 604 1219 q 615 1175 615 1194 q 595 1122 615 1139 q 550 1105 576 1105 q 504 1122 523 1105 q 485 1175 485 1139 z ",
        },
        Å: {
            ha: 844,
            x_min: 0,
            x_max: 844,
            o: "m 715 0 l 607 307 l 237 307 l 127 0 l 0 0 l 364 996 l 479 996 l 844 0 l 715 0 m 567 419 l 467 707 q 457 736 463 719 q 445 774 451 754 q 433 817 439 795 q 421 860 427 839 q 410 817 416 839 q 398 773 404 794 q 386 735 392 753 q 376 707 380 718 l 278 419 l 567 419 m 575 1074 q 563 1011 575 1039 q 531 965 551 984 q 481 936 510 946 q 420 926 453 926 q 358 936 386 926 q 310 965 330 946 q 279 1011 290 984 q 269 1072 269 1038 q 279 1134 269 1107 q 310 1179 290 1160 q 358 1208 330 1198 q 420 1218 386 1218 q 481 1208 452 1218 q 530 1180 509 1198 q 563 1134 551 1161 q 575 1074 575 1107 m 496 1072 q 474 1128 496 1108 q 420 1149 453 1149 q 365 1128 386 1149 q 343 1072 343 1108 q 362 1016 343 1036 q 420 996 382 996 q 474 1016 453 996 q 496 1072 496 1036 z ",
        },
        Æ: {
            ha: 1183,
            x_min: -1,
            x_max: 1100,
            o: "m 1100 0 l 585 0 l 585 307 l 262 307 l 125 0 l -1 0 l 443 991 l 1100 991 l 1100 880 l 711 880 l 711 574 l 1074 574 l 1074 464 l 711 464 l 711 111 l 1100 111 l 1100 0 m 311 419 l 585 419 l 585 880 l 511 880 l 311 419 z ",
        },
        Ç: {
            ha: 838,
            x_min: 85,
            x_max: 798,
            o: "m 538 895 q 406 867 465 895 q 306 788 347 840 q 241 663 264 736 q 218 496 218 589 q 238 326 218 400 q 298 201 258 252 q 398 123 338 150 q 538 97 458 97 q 652 109 598 97 q 760 136 707 121 l 760 26 q 707 8 733 16 q 651 -4 680 1 q 590 -11 623 -9 q 517 -14 557 -14 q 324 23 406 -14 q 190 127 243 60 q 111 288 136 194 q 85 497 85 382 q 114 703 85 610 q 201 864 144 797 q 343 968 258 931 q 538 1006 428 1006 q 677 991 611 1006 q 798 947 744 976 l 745 842 q 652 879 702 863 q 538 895 602 895 m 621 -195 q 573 -297 621 -260 q 424 -334 526 -334 q 394 -332 409 -334 q 368 -328 378 -330 l 368 -254 q 395 -257 378 -256 q 422 -258 412 -258 q 498 -244 471 -258 q 525 -196 525 -229 q 516 -168 525 -180 q 492 -147 507 -156 q 455 -132 476 -138 q 408 -119 433 -125 l 469 0 l 551 0 l 513 -78 q 555 -92 536 -83 q 590 -115 575 -101 q 613 -149 604 -129 q 621 -195 621 -168 z ",
        },
        È: {
            ha: 733,
            x_min: 135,
            x_max: 650,
            o: "m 650 0 l 135 0 l 135 991 l 650 991 l 650 880 l 261 880 l 261 574 l 624 574 l 624 464 l 261 464 l 261 111 l 650 111 l 650 0 m 484 1071 l 402 1071 q 351 1118 378 1090 q 297 1176 323 1146 q 249 1233 271 1206 q 217 1279 228 1261 l 217 1293 l 366 1293 q 391 1243 376 1270 q 421 1188 406 1215 q 453 1135 437 1160 q 484 1089 469 1109 l 484 1071 z ",
        },
        É: {
            ha: 733,
            x_min: 135,
            x_max: 650,
            o: "m 650 0 l 135 0 l 135 991 l 650 991 l 650 880 l 261 880 l 261 574 l 624 574 l 624 464 l 261 464 l 261 111 l 650 111 l 650 0 m 309 1089 q 340 1135 324 1109 q 372 1188 356 1160 q 402 1243 388 1215 q 427 1293 416 1270 l 576 1293 l 576 1279 q 544 1233 565 1261 q 496 1176 522 1206 q 443 1118 470 1146 q 391 1071 415 1090 l 309 1071 l 309 1089 z ",
        },
        Ê: {
            ha: 733,
            x_min: 135,
            x_max: 650,
            o: "m 650 0 l 135 0 l 135 991 l 650 991 l 650 880 l 261 880 l 261 574 l 624 574 l 624 464 l 261 464 l 261 111 l 650 111 l 650 0 m 615 1071 l 533 1071 q 462 1127 498 1094 q 390 1197 425 1160 q 317 1127 353 1160 q 247 1071 281 1094 l 165 1071 l 165 1089 q 205 1135 182 1109 q 251 1188 229 1160 q 294 1243 274 1215 q 325 1293 314 1270 l 455 1293 q 486 1243 466 1270 q 529 1188 506 1215 q 575 1135 551 1160 q 615 1089 598 1109 l 615 1071 z ",
        },
        Ë: {
            ha: 733,
            x_min: 135,
            x_max: 650,
            o: "m 650 0 l 135 0 l 135 991 l 650 991 l 650 880 l 261 880 l 261 574 l 624 574 l 624 464 l 261 464 l 261 111 l 650 111 l 650 0 m 201 1175 q 220 1228 201 1212 q 266 1244 239 1244 q 312 1228 292 1244 q 332 1175 332 1211 q 312 1122 332 1139 q 266 1105 292 1105 q 220 1122 239 1105 q 201 1175 201 1139 m 455 1175 q 474 1228 455 1212 q 520 1244 493 1244 q 545 1240 533 1244 q 566 1228 557 1236 q 580 1206 574 1219 q 585 1175 585 1194 q 566 1122 585 1139 q 520 1105 546 1105 q 474 1122 493 1105 q 455 1175 455 1139 z ",
        },
        Ì: {
            ha: 471,
            x_min: 42,
            x_max: 415,
            o: "m 415 0 l 56 0 l 56 69 l 172 97 l 172 895 l 56 922 l 56 991 l 415 991 l 415 922 l 298 895 l 298 97 l 415 69 l 415 0 m 309 1071 l 227 1071 q 176 1118 203 1090 q 122 1176 148 1146 q 74 1233 96 1206 q 42 1279 53 1261 l 42 1293 l 191 1293 q 216 1243 201 1270 q 246 1188 231 1215 q 278 1135 262 1160 q 309 1089 294 1109 l 309 1071 z ",
        },
        Í: {
            ha: 471,
            x_min: 56,
            x_max: 441,
            o: "m 415 0 l 56 0 l 56 69 l 172 97 l 172 895 l 56 922 l 56 991 l 415 991 l 415 922 l 298 895 l 298 97 l 415 69 l 415 0 m 174 1089 q 205 1135 189 1109 q 237 1188 221 1160 q 267 1243 253 1215 q 292 1293 281 1270 l 441 1293 l 441 1279 q 409 1233 430 1261 q 361 1176 387 1206 q 308 1118 335 1146 q 256 1071 280 1090 l 174 1071 l 174 1089 z ",
        },
        Î: {
            ha: 471,
            x_min: 12,
            x_max: 462,
            o: "m 415 0 l 56 0 l 56 69 l 172 97 l 172 895 l 56 922 l 56 991 l 415 991 l 415 922 l 298 895 l 298 97 l 415 69 l 415 0 m 462 1071 l 380 1071 q 309 1127 345 1094 q 237 1197 272 1160 q 164 1127 200 1160 q 94 1071 128 1094 l 12 1071 l 12 1089 q 52 1135 29 1109 q 98 1188 75 1160 q 141 1243 121 1215 q 172 1293 161 1270 l 302 1293 q 333 1243 313 1270 q 375 1188 353 1215 q 421 1135 398 1160 q 462 1089 445 1109 l 462 1071 z ",
        },
        Ï: {
            ha: 471,
            x_min: 43,
            x_max: 428,
            o: "m 415 0 l 56 0 l 56 69 l 172 97 l 172 895 l 56 922 l 56 991 l 415 991 l 415 922 l 298 895 l 298 97 l 415 69 l 415 0 m 43 1175 q 62 1228 43 1212 q 109 1244 81 1244 q 155 1228 135 1244 q 174 1175 174 1211 q 155 1122 174 1139 q 109 1105 135 1105 q 62 1122 81 1105 q 43 1175 43 1139 m 298 1175 q 317 1228 298 1212 q 363 1244 336 1244 q 388 1240 376 1244 q 408 1228 399 1236 q 422 1206 417 1219 q 428 1175 428 1194 q 408 1122 428 1139 q 363 1105 389 1105 q 317 1122 336 1105 q 298 1175 298 1139 z ",
        },
        Ð: {
            ha: 950,
            x_min: 32,
            x_max: 865,
            o: "m 32 546 l 135 546 l 135 991 l 411 991 q 599 960 515 991 q 742 868 683 929 q 833 715 801 806 q 865 505 865 625 q 833 285 865 380 q 739 127 800 191 q 587 32 677 64 q 383 0 497 0 l 135 0 l 135 436 l 32 436 l 32 546 m 732 501 q 709 672 732 600 q 644 791 687 745 q 539 861 602 838 q 397 884 477 884 l 261 884 l 261 546 l 489 546 l 489 436 l 261 436 l 261 109 l 371 109 q 641 208 550 109 q 732 501 732 307 z ",
        },
        Ñ: {
            ha: 1013,
            x_min: 135,
            x_max: 878,
            o: "m 878 0 l 732 0 l 244 821 l 239 821 q 245 717 243 768 q 250 625 248 673 q 252 538 252 576 l 252 0 l 135 0 l 135 991 l 279 991 l 765 175 l 770 175 q 765 277 768 227 q 763 321 764 298 q 761 367 762 344 q 760 410 760 389 q 760 449 760 431 l 760 991 l 878 991 l 878 0 m 614 1072 q 560 1084 587 1072 q 507 1111 533 1096 q 458 1137 481 1125 q 412 1149 434 1149 q 366 1131 382 1149 q 340 1071 349 1113 l 269 1071 q 284 1145 273 1112 q 313 1201 295 1178 q 356 1237 331 1225 q 412 1250 381 1250 q 468 1238 441 1250 q 522 1212 496 1226 q 571 1186 547 1198 q 614 1174 594 1174 q 660 1192 644 1174 q 686 1251 675 1209 l 757 1251 q 742 1178 753 1211 q 713 1121 731 1145 q 670 1085 695 1098 q 614 1072 645 1072 z ",
        },
        Ò: {
            ha: 1031,
            x_min: 85,
            x_max: 945,
            o: "m 945 497 q 917 288 945 382 q 836 127 890 194 q 701 23 781 60 q 515 -14 621 -14 q 324 23 405 -14 q 190 127 243 60 q 111 289 136 194 q 85 498 85 383 q 111 708 85 614 q 190 868 136 802 q 325 971 243 935 q 517 1007 406 1007 q 702 971 622 1007 q 836 868 781 935 q 917 707 890 801 q 945 497 945 613 m 218 497 q 236 330 218 404 q 290 204 254 256 q 382 124 326 152 q 515 97 438 97 q 649 124 593 97 q 741 204 705 152 q 794 330 777 256 q 811 497 811 404 q 794 665 811 591 q 741 790 777 739 q 650 868 705 841 q 517 896 594 896 q 383 868 439 896 q 290 790 327 841 q 236 665 254 739 q 218 497 218 591 m 590 1071 l 509 1071 q 457 1118 485 1090 q 403 1176 429 1146 q 356 1233 377 1206 q 323 1279 334 1261 l 323 1293 l 472 1293 q 497 1243 483 1270 q 528 1188 512 1215 q 559 1135 543 1160 q 590 1089 576 1109 l 590 1071 z ",
        },
        Ó: {
            ha: 1031,
            x_min: 85,
            x_max: 945,
            o: "m 945 497 q 917 288 945 382 q 836 127 890 194 q 701 23 781 60 q 515 -14 621 -14 q 324 23 405 -14 q 190 127 243 60 q 111 289 136 194 q 85 498 85 383 q 111 708 85 614 q 190 868 136 802 q 325 971 243 935 q 517 1007 406 1007 q 702 971 622 1007 q 836 868 781 935 q 917 707 890 801 q 945 497 945 613 m 218 497 q 236 330 218 404 q 290 204 254 256 q 382 124 326 152 q 515 97 438 97 q 649 124 593 97 q 741 204 705 152 q 794 330 777 256 q 811 497 811 404 q 794 665 811 591 q 741 790 777 739 q 650 868 705 841 q 517 896 594 896 q 383 868 439 896 q 290 790 327 841 q 236 665 254 739 q 218 497 218 591 m 441 1089 q 472 1135 456 1109 q 504 1188 488 1160 q 534 1243 520 1215 q 559 1293 549 1270 l 708 1293 l 708 1279 q 676 1233 697 1261 q 628 1176 654 1206 q 575 1118 602 1146 q 524 1071 547 1090 l 441 1071 l 441 1089 z ",
        },
        Ô: {
            ha: 1031,
            x_min: 85,
            x_max: 945,
            o: "m 945 497 q 917 288 945 382 q 836 127 890 194 q 701 23 781 60 q 515 -14 621 -14 q 324 23 405 -14 q 190 127 243 60 q 111 289 136 194 q 85 498 85 383 q 111 708 85 614 q 190 868 136 802 q 325 971 243 935 q 517 1007 406 1007 q 702 971 622 1007 q 836 868 781 935 q 917 707 890 801 q 945 497 945 613 m 218 497 q 236 330 218 404 q 290 204 254 256 q 382 124 326 152 q 515 97 438 97 q 649 124 593 97 q 741 204 705 152 q 794 330 777 256 q 811 497 811 404 q 794 665 811 591 q 741 790 777 739 q 650 868 705 841 q 517 896 594 896 q 383 868 439 896 q 290 790 327 841 q 236 665 254 739 q 218 497 218 591 m 743 1071 l 661 1071 q 590 1127 627 1094 q 518 1197 553 1160 q 446 1127 481 1160 q 375 1071 410 1094 l 293 1071 l 293 1089 q 334 1135 311 1109 q 379 1188 357 1160 q 422 1243 402 1215 q 453 1293 442 1270 l 583 1293 q 614 1243 594 1270 q 657 1188 634 1215 q 703 1135 680 1160 q 743 1089 726 1109 l 743 1071 z ",
        },
        Õ: {
            ha: 1031,
            x_min: 85,
            x_max: 945,
            o: "m 945 497 q 917 288 945 382 q 836 127 890 194 q 701 23 781 60 q 515 -14 621 -14 q 324 23 405 -14 q 190 127 243 60 q 111 289 136 194 q 85 498 85 383 q 111 708 85 614 q 190 868 136 802 q 325 971 243 935 q 517 1007 406 1007 q 702 971 622 1007 q 836 868 781 935 q 917 707 890 801 q 945 497 945 613 m 218 497 q 236 330 218 404 q 290 204 254 256 q 382 124 326 152 q 515 97 438 97 q 649 124 593 97 q 741 204 705 152 q 794 330 777 256 q 811 497 811 404 q 794 665 811 591 q 741 790 777 739 q 650 868 705 841 q 517 896 594 896 q 383 868 439 896 q 290 790 327 841 q 236 665 254 739 q 218 497 218 591 m 604 1072 q 550 1084 577 1072 q 498 1111 524 1096 q 448 1137 472 1125 q 403 1149 425 1149 q 356 1131 372 1149 q 330 1071 340 1113 l 260 1071 q 274 1145 263 1112 q 303 1201 286 1178 q 347 1237 321 1225 q 403 1250 372 1250 q 459 1238 431 1250 q 512 1212 486 1226 q 561 1186 538 1198 q 604 1174 585 1174 q 650 1192 635 1174 q 676 1251 666 1209 l 747 1251 q 733 1178 744 1211 q 704 1121 722 1145 q 661 1085 686 1098 q 604 1072 635 1072 z ",
        },
        Ö: {
            ha: 1031,
            x_min: 85,
            x_max: 945,
            o: "m 945 497 q 917 288 945 382 q 836 127 890 194 q 701 23 781 60 q 515 -14 621 -14 q 324 23 405 -14 q 190 127 243 60 q 111 289 136 194 q 85 498 85 383 q 111 708 85 614 q 190 868 136 802 q 325 971 243 935 q 517 1007 406 1007 q 702 971 622 1007 q 836 868 781 935 q 917 707 890 801 q 945 497 945 613 m 218 497 q 236 330 218 404 q 290 204 254 256 q 382 124 326 152 q 515 97 438 97 q 649 124 593 97 q 741 204 705 152 q 794 330 777 256 q 811 497 811 404 q 794 665 811 591 q 741 790 777 739 q 650 868 705 841 q 517 896 594 896 q 383 868 439 896 q 290 790 327 841 q 236 665 254 739 q 218 497 218 591 m 323 1175 q 342 1228 323 1212 q 389 1244 361 1244 q 435 1228 415 1244 q 454 1175 454 1211 q 435 1122 454 1139 q 389 1105 415 1105 q 342 1122 361 1105 q 323 1175 323 1139 m 578 1175 q 597 1228 578 1212 q 643 1244 616 1244 q 668 1240 656 1244 q 688 1228 680 1236 q 703 1206 697 1219 q 708 1175 708 1194 q 688 1122 708 1139 q 643 1105 669 1105 q 597 1122 616 1105 q 578 1175 578 1139 z ",
        },
        "×": {
            ha: 765,
            x_min: 96,
            x_max: 671,
            o: "m 311 490 l 96 707 l 167 778 l 382 562 l 600 778 l 671 708 l 453 490 l 669 273 l 600 204 l 382 419 l 167 205 l 97 275 l 311 490 z ",
        },
        Ø: {
            ha: 1031,
            x_min: 85,
            x_max: 945,
            o: "m 882 986 l 819 885 q 913 720 881 818 q 945 497 945 621 q 917 288 945 382 q 836 127 890 194 q 701 23 781 60 q 515 -14 621 -14 q 297 35 387 -14 l 244 -52 l 151 1 l 212 100 q 116 269 146 168 q 85 498 85 370 q 111 708 85 614 q 190 868 136 802 q 325 971 243 935 q 517 1007 406 1007 q 635 993 581 1007 q 734 953 690 979 l 789 1039 l 882 986 m 218 497 q 234 337 218 408 q 282 214 250 267 l 675 854 q 604 885 642 874 q 517 896 565 896 q 383 868 439 896 q 290 790 327 841 q 236 665 254 739 q 218 497 218 591 m 811 497 q 751 774 811 671 l 359 136 q 430 107 391 117 q 515 97 469 97 q 649 124 593 97 q 741 204 705 152 q 794 330 777 256 q 811 497 811 404 z ",
        },
        Ù: {
            ha: 970,
            x_min: 125,
            x_max: 844,
            o: "m 844 993 l 844 350 q 822 206 844 273 q 754 91 800 140 q 641 14 709 42 q 481 -14 573 -14 q 328 13 394 -14 q 216 87 261 39 q 148 202 171 135 q 125 353 125 270 l 125 991 l 252 991 l 252 346 q 310 162 252 227 q 488 97 369 97 q 591 115 548 97 q 663 167 635 134 q 704 246 690 200 q 718 347 718 292 l 718 993 l 844 993 m 574 1071 l 493 1071 q 441 1118 469 1090 q 388 1176 414 1146 q 340 1233 361 1206 q 308 1279 319 1261 l 308 1293 l 456 1293 q 482 1243 467 1270 q 512 1188 496 1215 q 544 1135 528 1160 q 574 1089 560 1109 l 574 1071 z ",
        },
        Ú: {
            ha: 970,
            x_min: 125,
            x_max: 844,
            o: "m 844 993 l 844 350 q 822 206 844 273 q 754 91 800 140 q 641 14 709 42 q 481 -14 573 -14 q 328 13 394 -14 q 216 87 261 39 q 148 202 171 135 q 125 353 125 270 l 125 991 l 252 991 l 252 346 q 310 162 252 227 q 488 97 369 97 q 591 115 548 97 q 663 167 635 134 q 704 246 690 200 q 718 347 718 292 l 718 993 l 844 993 m 400 1089 q 431 1135 415 1109 q 463 1188 447 1160 q 493 1243 479 1215 q 518 1293 507 1270 l 667 1293 l 667 1279 q 634 1233 656 1261 q 587 1176 613 1206 q 533 1118 561 1146 q 482 1071 506 1090 l 400 1071 l 400 1089 z ",
        },
        Û: {
            ha: 970,
            x_min: 125,
            x_max: 844,
            o: "m 844 993 l 844 350 q 822 206 844 273 q 754 91 800 140 q 641 14 709 42 q 481 -14 573 -14 q 328 13 394 -14 q 216 87 261 39 q 148 202 171 135 q 125 353 125 270 l 125 991 l 252 991 l 252 346 q 310 162 252 227 q 488 97 369 97 q 591 115 548 97 q 663 167 635 134 q 704 246 690 200 q 718 347 718 292 l 718 993 l 844 993 m 707 1071 l 625 1071 q 554 1127 591 1094 q 482 1197 517 1160 q 410 1127 446 1160 q 339 1071 374 1094 l 257 1071 l 257 1089 q 298 1135 275 1109 q 343 1188 321 1160 q 386 1243 366 1215 q 417 1293 406 1270 l 547 1293 q 578 1243 558 1270 q 621 1188 598 1215 q 667 1135 644 1160 q 707 1089 690 1109 l 707 1071 z ",
        },
        Ü: {
            ha: 970,
            x_min: 125,
            x_max: 844,
            o: "m 844 993 l 844 350 q 822 206 844 273 q 754 91 800 140 q 641 14 709 42 q 481 -14 573 -14 q 328 13 394 -14 q 216 87 261 39 q 148 202 171 135 q 125 353 125 270 l 125 991 l 252 991 l 252 346 q 310 162 252 227 q 488 97 369 97 q 591 115 548 97 q 663 167 635 134 q 704 246 690 200 q 718 347 718 292 l 718 993 l 844 993 m 293 1175 q 312 1228 293 1212 q 358 1244 331 1244 q 404 1228 385 1244 q 424 1175 424 1211 q 404 1122 424 1139 q 358 1105 385 1105 q 312 1122 331 1105 q 293 1175 293 1139 m 547 1175 q 566 1228 547 1212 q 612 1244 585 1244 q 637 1240 625 1244 q 658 1228 649 1236 q 672 1206 667 1219 q 677 1175 677 1194 q 658 1122 677 1139 q 612 1105 638 1105 q 566 1122 585 1105 q 547 1175 547 1139 z ",
        },
        Ý: {
            ha: 732,
            x_min: 0,
            x_max: 732,
            o: "m 366 490 l 596 991 l 732 991 l 429 386 l 429 0 l 302 0 l 302 379 l 0 991 l 138 991 l 366 490 m 300 1089 q 331 1135 315 1109 q 362 1188 347 1160 q 393 1243 378 1215 q 418 1293 407 1270 l 566 1293 l 566 1279 q 534 1233 555 1261 q 487 1176 513 1206 q 433 1118 460 1146 q 382 1071 406 1090 l 300 1071 l 300 1089 z ",
        },
        Þ: {
            ha: 800,
            x_min: 135,
            x_max: 729,
            o: "m 729 530 q 710 412 729 469 q 649 311 692 355 q 536 241 606 267 q 363 215 466 215 l 261 215 l 261 0 l 135 0 l 135 991 l 261 991 l 261 821 l 380 821 q 537 801 471 821 q 646 745 603 782 q 709 654 688 708 q 729 530 729 600 m 261 322 l 349 322 q 459 333 412 322 q 537 368 506 344 q 583 430 568 392 q 599 524 599 469 q 543 667 599 621 q 370 713 488 713 l 261 713 l 261 322 z ",
        },
        ß: {
            ha: 836,
            x_min: 118,
            x_max: 774,
            o: "m 685 854 q 670 786 685 816 q 634 731 656 755 q 588 686 613 706 q 541 647 562 665 q 505 610 519 628 q 490 571 490 591 q 495 546 490 557 q 513 521 500 534 q 550 491 526 508 q 612 449 574 475 q 680 399 650 424 q 731 347 710 375 q 763 287 751 319 q 774 212 774 254 q 755 111 774 153 q 702 40 736 68 q 620 0 667 13 q 514 -14 572 -14 q 401 -2 448 -14 q 318 33 354 10 l 318 146 q 358 124 336 135 q 404 106 380 114 q 454 93 429 98 q 505 87 480 87 q 571 96 543 87 q 616 120 598 104 q 642 157 633 135 q 650 205 650 178 q 644 250 650 230 q 624 289 638 270 q 585 328 609 308 q 522 371 560 347 q 451 421 479 398 q 404 467 422 444 q 378 513 386 490 q 371 566 371 537 q 385 629 371 602 q 420 677 399 656 q 465 717 441 699 q 511 755 490 735 q 546 797 532 774 q 559 850 559 820 q 548 899 559 878 q 517 934 537 920 q 468 955 496 948 q 406 961 440 961 q 342 955 372 961 q 290 932 313 948 q 255 887 268 916 q 241 815 241 859 l 241 0 l 118 0 l 118 814 q 139 932 118 884 q 199 1009 161 980 q 290 1050 237 1038 q 406 1063 343 1063 q 519 1050 468 1063 q 608 1011 571 1037 q 665 946 644 985 q 685 854 685 907 z ",
        },
        à: {
            ha: 737,
            x_min: 64,
            x_max: 627,
            o: "m 538 0 l 513 103 l 507 103 q 463 51 485 73 q 414 14 440 28 q 355 -7 387 0 q 279 -14 322 -14 q 193 0 233 -14 q 125 40 154 13 q 80 110 96 68 q 64 208 64 151 q 142 379 64 319 q 380 444 220 439 l 505 449 l 505 496 q 495 572 505 541 q 467 621 486 602 q 421 648 448 640 q 358 656 393 656 q 253 640 302 656 q 160 600 205 623 l 117 693 q 229 739 167 720 q 358 758 290 758 q 478 744 427 758 q 561 700 528 730 q 610 623 594 670 q 627 510 627 576 l 627 0 l 538 0 m 311 86 q 388 99 353 86 q 449 138 423 112 q 489 204 475 164 q 504 297 504 243 l 504 364 l 407 359 q 304 346 346 357 q 238 316 263 335 q 203 269 214 296 q 192 207 192 241 q 225 115 192 144 q 311 86 258 86 m 460 842 l 378 842 q 327 889 355 861 q 273 947 299 917 q 225 1004 247 977 q 193 1050 204 1031 l 193 1064 l 342 1064 q 367 1014 353 1041 q 397 959 382 986 q 429 905 413 931 q 460 860 446 880 l 460 842 z ",
        },
        á: {
            ha: 737,
            x_min: 64,
            x_max: 627,
            o: "m 538 0 l 513 103 l 507 103 q 463 51 485 73 q 414 14 440 28 q 355 -7 387 0 q 279 -14 322 -14 q 193 0 233 -14 q 125 40 154 13 q 80 110 96 68 q 64 208 64 151 q 142 379 64 319 q 380 444 220 439 l 505 449 l 505 496 q 495 572 505 541 q 467 621 486 602 q 421 648 448 640 q 358 656 393 656 q 253 640 302 656 q 160 600 205 623 l 117 693 q 229 739 167 720 q 358 758 290 758 q 478 744 427 758 q 561 700 528 730 q 610 623 594 670 q 627 510 627 576 l 627 0 l 538 0 m 311 86 q 388 99 353 86 q 449 138 423 112 q 489 204 475 164 q 504 297 504 243 l 504 364 l 407 359 q 304 346 346 357 q 238 316 263 335 q 203 269 214 296 q 192 207 192 241 q 225 115 192 144 q 311 86 258 86 m 302 860 q 333 905 317 880 q 365 959 349 931 q 395 1014 381 986 q 420 1064 410 1041 l 569 1064 l 569 1050 q 537 1004 558 1031 q 489 947 515 977 q 436 889 463 917 q 385 842 408 861 l 302 842 l 302 860 z ",
        },
        â: {
            ha: 737,
            x_min: 64,
            x_max: 627,
            o: "m 538 0 l 513 103 l 507 103 q 463 51 485 73 q 414 14 440 28 q 355 -7 387 0 q 279 -14 322 -14 q 193 0 233 -14 q 125 40 154 13 q 80 110 96 68 q 64 208 64 151 q 142 379 64 319 q 380 444 220 439 l 505 449 l 505 496 q 495 572 505 541 q 467 621 486 602 q 421 648 448 640 q 358 656 393 656 q 253 640 302 656 q 160 600 205 623 l 117 693 q 229 739 167 720 q 358 758 290 758 q 478 744 427 758 q 561 700 528 730 q 610 623 594 670 q 627 510 627 576 l 627 0 l 538 0 m 311 86 q 388 99 353 86 q 449 138 423 112 q 489 204 475 164 q 504 297 504 243 l 504 364 l 407 359 q 304 346 346 357 q 238 316 263 335 q 203 269 214 296 q 192 207 192 241 q 225 115 192 144 q 311 86 258 86 m 605 842 l 523 842 q 452 898 488 865 q 380 968 415 930 q 307 898 343 930 q 237 842 271 865 l 155 842 l 155 860 q 195 905 172 880 q 241 959 218 931 q 284 1014 264 986 q 315 1064 304 1041 l 445 1064 q 476 1014 456 1041 q 518 959 496 986 q 565 905 541 931 q 605 860 588 880 l 605 842 z ",
        },
        ã: {
            ha: 737,
            x_min: 64,
            x_max: 627,
            o: "m 538 0 l 513 103 l 507 103 q 463 51 485 73 q 414 14 440 28 q 355 -7 387 0 q 279 -14 322 -14 q 193 0 233 -14 q 125 40 154 13 q 80 110 96 68 q 64 208 64 151 q 142 379 64 319 q 380 444 220 439 l 505 449 l 505 496 q 495 572 505 541 q 467 621 486 602 q 421 648 448 640 q 358 656 393 656 q 253 640 302 656 q 160 600 205 623 l 117 693 q 229 739 167 720 q 358 758 290 758 q 478 744 427 758 q 561 700 528 730 q 610 623 594 670 q 627 510 627 576 l 627 0 l 538 0 m 311 86 q 388 99 353 86 q 449 138 423 112 q 489 204 475 164 q 504 297 504 243 l 504 364 l 407 359 q 304 346 346 357 q 238 316 263 335 q 203 269 214 296 q 192 207 192 241 q 225 115 192 144 q 311 86 258 86 m 474 843 q 420 855 447 843 q 368 881 393 867 q 318 908 342 896 q 273 920 294 920 q 226 902 242 920 q 200 842 210 884 l 130 842 q 144 916 133 882 q 173 972 155 949 q 216 1008 191 996 q 273 1021 241 1021 q 329 1009 301 1021 q 382 983 356 997 q 431 957 408 968 q 474 945 454 945 q 520 962 505 945 q 546 1022 536 980 l 617 1022 q 603 948 614 981 q 573 892 591 916 q 530 856 555 869 q 474 843 505 843 z ",
        },
        ä: {
            ha: 737,
            x_min: 64,
            x_max: 627,
            o: "m 538 0 l 513 103 l 507 103 q 463 51 485 73 q 414 14 440 28 q 355 -7 387 0 q 279 -14 322 -14 q 193 0 233 -14 q 125 40 154 13 q 80 110 96 68 q 64 208 64 151 q 142 379 64 319 q 380 444 220 439 l 505 449 l 505 496 q 495 572 505 541 q 467 621 486 602 q 421 648 448 640 q 358 656 393 656 q 253 640 302 656 q 160 600 205 623 l 117 693 q 229 739 167 720 q 358 758 290 758 q 478 744 427 758 q 561 700 528 730 q 610 623 594 670 q 627 510 627 576 l 627 0 l 538 0 m 311 86 q 388 99 353 86 q 449 138 423 112 q 489 204 475 164 q 504 297 504 243 l 504 364 l 407 359 q 304 346 346 357 q 238 316 263 335 q 203 269 214 296 q 192 207 192 241 q 225 115 192 144 q 311 86 258 86 m 185 946 q 204 999 185 983 q 250 1015 223 1015 q 296 999 277 1015 q 316 946 316 982 q 296 893 316 910 q 250 876 277 876 q 204 893 223 876 q 185 946 185 910 m 439 946 q 458 999 439 983 q 505 1015 477 1015 q 529 1011 517 1015 q 550 999 541 1007 q 564 977 559 990 q 570 946 570 964 q 550 893 570 910 q 505 876 530 876 q 458 893 477 876 q 439 946 439 910 z ",
        },
        å: {
            ha: 737,
            x_min: 64,
            x_max: 627,
            o: "m 538 0 l 513 103 l 507 103 q 463 51 485 73 q 414 14 440 28 q 355 -7 387 0 q 279 -14 322 -14 q 193 0 233 -14 q 125 40 154 13 q 80 110 96 68 q 64 208 64 151 q 142 379 64 319 q 380 444 220 439 l 505 449 l 505 496 q 495 572 505 541 q 467 621 486 602 q 421 648 448 640 q 358 656 393 656 q 253 640 302 656 q 160 600 205 623 l 117 693 q 229 739 167 720 q 358 758 290 758 q 478 744 427 758 q 561 700 528 730 q 610 623 594 670 q 627 510 627 576 l 627 0 l 538 0 m 311 86 q 388 99 353 86 q 449 138 423 112 q 489 204 475 164 q 504 297 504 243 l 504 364 l 407 359 q 304 346 346 357 q 238 316 263 335 q 203 269 214 296 q 192 207 192 241 q 225 115 192 144 q 311 86 258 86 m 531 989 q 519 927 531 954 q 487 880 507 899 q 437 851 466 861 q 376 842 409 842 q 314 851 342 842 q 266 880 286 861 q 235 926 246 899 q 224 987 224 953 q 235 1049 224 1022 q 266 1095 246 1076 q 314 1123 286 1114 q 376 1133 342 1133 q 437 1123 408 1133 q 486 1095 465 1114 q 519 1049 507 1076 q 531 989 531 1023 m 452 987 q 430 1044 452 1023 q 376 1064 409 1064 q 320 1044 342 1064 q 299 987 299 1023 q 318 931 299 951 q 376 911 338 911 q 430 931 409 911 q 452 987 452 951 z ",
        },
        æ: {
            ha: 1157,
            x_min: 64,
            x_max: 1088,
            o: "m 64 208 q 142 379 64 319 q 380 444 220 439 l 505 449 l 505 496 q 495 572 505 541 q 467 621 486 602 q 421 648 448 640 q 358 656 393 656 q 253 640 302 656 q 160 600 205 623 l 117 693 q 229 739 167 720 q 358 758 290 758 q 503 729 447 758 q 589 637 559 701 q 680 727 623 695 q 806 758 736 758 q 924 734 871 758 q 1013 666 976 710 q 1068 561 1049 623 q 1088 425 1088 500 l 1088 349 l 632 349 q 685 156 635 218 q 835 94 735 94 q 897 98 868 94 q 952 107 926 101 q 1004 124 979 114 q 1054 146 1029 134 l 1054 36 q 1002 14 1027 23 q 950 -2 977 4 q 894 -11 923 -8 q 832 -14 865 -14 q 670 25 739 -14 q 559 142 602 64 q 511 76 536 105 q 453 27 485 47 q 381 -3 420 7 q 292 -14 342 -14 q 202 0 243 -14 q 130 40 160 13 q 81 110 99 68 q 64 208 64 151 m 192 207 q 225 115 192 144 q 311 86 258 86 q 388 99 353 86 q 449 138 423 112 q 489 204 475 164 q 504 297 504 243 l 504 364 l 407 359 q 304 346 346 357 q 238 316 263 335 q 203 269 214 296 q 192 207 192 241 m 803 656 q 685 603 728 656 q 635 449 642 550 l 954 449 q 945 533 954 495 q 918 599 937 572 q 871 641 899 626 q 803 656 842 656 z ",
        },
        ç: {
            ha: 643,
            x_min: 77,
            x_max: 596,
            o: "m 403 -14 q 275 8 334 -14 q 171 76 215 29 q 102 194 127 122 q 77 368 77 267 q 102 549 77 475 q 173 670 128 623 q 279 737 218 716 q 408 758 339 758 q 512 747 461 758 q 596 718 562 735 l 559 614 q 525 627 544 621 q 486 638 506 633 q 446 647 465 644 q 408 650 426 650 q 253 582 302 650 q 204 370 204 513 q 253 160 204 227 q 403 94 302 94 q 502 107 458 94 q 583 136 547 119 l 583 26 q 505 -3 547 7 q 403 -14 463 -14 m 495 -195 q 447 -297 495 -260 q 298 -334 399 -334 q 268 -332 283 -334 q 242 -328 252 -330 l 242 -254 q 269 -257 252 -256 q 296 -258 286 -258 q 372 -244 345 -258 q 399 -196 399 -229 q 390 -168 399 -180 q 366 -147 381 -156 q 329 -132 350 -138 q 282 -119 307 -125 l 343 0 l 425 0 l 387 -78 q 429 -92 410 -83 q 464 -115 449 -101 q 487 -149 478 -129 q 495 -195 495 -168 z ",
        },
        è: {
            ha: 743,
            x_min: 77,
            x_max: 673,
            o: "m 412 -14 q 276 12 338 -14 q 170 85 214 37 q 101 204 125 133 q 77 367 77 275 q 99 532 77 459 q 162 655 121 604 q 259 732 203 705 q 385 758 316 758 q 505 734 452 758 q 596 666 559 710 q 653 561 633 623 q 673 425 673 500 l 673 349 l 204 349 q 259 156 208 218 q 414 94 311 94 q 478 98 448 94 q 535 107 507 101 q 588 124 562 114 q 640 146 614 134 l 640 36 q 587 14 613 23 q 533 -2 561 4 q 476 -11 506 -8 q 412 -14 447 -14 m 382 656 q 259 603 304 656 q 207 449 214 550 l 541 449 q 531 533 541 495 q 503 599 522 572 q 454 641 484 626 q 382 656 424 656 m 460 842 l 378 842 q 327 889 355 861 q 273 947 299 917 q 225 1004 247 977 q 193 1050 204 1031 l 193 1064 l 342 1064 q 367 1014 353 1041 q 397 959 382 986 q 429 905 413 931 q 460 860 446 880 l 460 842 z ",
        },
        é: {
            ha: 743,
            x_min: 77,
            x_max: 673,
            o: "m 412 -14 q 276 12 338 -14 q 170 85 214 37 q 101 204 125 133 q 77 367 77 275 q 99 532 77 459 q 162 655 121 604 q 259 732 203 705 q 385 758 316 758 q 505 734 452 758 q 596 666 559 710 q 653 561 633 623 q 673 425 673 500 l 673 349 l 204 349 q 259 156 208 218 q 414 94 311 94 q 478 98 448 94 q 535 107 507 101 q 588 124 562 114 q 640 146 614 134 l 640 36 q 587 14 613 23 q 533 -2 561 4 q 476 -11 506 -8 q 412 -14 447 -14 m 382 656 q 259 603 304 656 q 207 449 214 550 l 541 449 q 531 533 541 495 q 503 599 522 572 q 454 641 484 626 q 382 656 424 656 m 322 860 q 353 905 337 880 q 385 959 369 931 q 415 1014 401 986 q 440 1064 429 1041 l 589 1064 l 589 1050 q 556 1004 578 1031 q 509 947 535 977 q 455 889 483 917 q 404 842 428 861 l 322 842 l 322 860 z ",
        },
        ê: {
            ha: 743,
            x_min: 77,
            x_max: 673,
            o: "m 412 -14 q 276 12 338 -14 q 170 85 214 37 q 101 204 125 133 q 77 367 77 275 q 99 532 77 459 q 162 655 121 604 q 259 732 203 705 q 385 758 316 758 q 505 734 452 758 q 596 666 559 710 q 653 561 633 623 q 673 425 673 500 l 673 349 l 204 349 q 259 156 208 218 q 414 94 311 94 q 478 98 448 94 q 535 107 507 101 q 588 124 562 114 q 640 146 614 134 l 640 36 q 587 14 613 23 q 533 -2 561 4 q 476 -11 506 -8 q 412 -14 447 -14 m 382 656 q 259 603 304 656 q 207 449 214 550 l 541 449 q 531 533 541 495 q 503 599 522 572 q 454 641 484 626 q 382 656 424 656 m 602 842 l 520 842 q 449 898 486 865 q 377 968 412 930 q 304 898 340 930 q 234 842 269 865 l 152 842 l 152 860 q 193 905 170 880 q 238 959 216 931 q 281 1014 261 986 q 312 1064 301 1041 l 442 1064 q 473 1014 453 1041 q 516 959 493 986 q 562 905 538 931 q 602 860 585 880 l 602 842 z ",
        },
        ë: {
            ha: 743,
            x_min: 77,
            x_max: 673,
            o: "m 412 -14 q 276 12 338 -14 q 170 85 214 37 q 101 204 125 133 q 77 367 77 275 q 99 532 77 459 q 162 655 121 604 q 259 732 203 705 q 385 758 316 758 q 505 734 452 758 q 596 666 559 710 q 653 561 633 623 q 673 425 673 500 l 673 349 l 204 349 q 259 156 208 218 q 414 94 311 94 q 478 98 448 94 q 535 107 507 101 q 588 124 562 114 q 640 146 614 134 l 640 36 q 587 14 613 23 q 533 -2 561 4 q 476 -11 506 -8 q 412 -14 447 -14 m 382 656 q 259 603 304 656 q 207 449 214 550 l 541 449 q 531 533 541 495 q 503 599 522 572 q 454 641 484 626 q 382 656 424 656 m 182 946 q 201 999 182 983 q 248 1015 220 1015 q 294 999 274 1015 q 313 946 313 982 q 294 893 313 910 q 248 876 274 876 q 201 893 220 876 q 182 946 182 910 m 437 946 q 456 999 437 983 q 502 1015 475 1015 q 527 1011 515 1015 q 547 999 538 1007 q 562 977 556 990 q 567 946 567 964 q 547 893 567 910 q 502 876 528 876 q 456 893 475 876 q 437 946 437 910 z ",
        },
        ì: {
            ha: 359,
            x_min: -23,
            x_max: 243,
            o: "m 241 0 l 118 0 l 118 745 l 241 745 l 241 0 m 243 842 l 162 842 q 111 889 138 861 q 57 947 83 917 q 9 1004 31 977 q -23 1050 -12 1031 l -23 1064 l 125 1064 q 151 1014 136 1041 q 181 959 165 986 q 213 905 197 931 q 243 860 229 880 l 243 842 z ",
        },
        í: {
            ha: 359,
            x_min: 118,
            x_max: 392,
            o: "m 241 0 l 118 0 l 118 745 l 241 745 l 241 0 m 125 860 q 156 905 140 880 q 188 959 172 931 q 218 1014 204 986 q 243 1064 233 1041 l 392 1064 l 392 1050 q 360 1004 381 1031 q 312 947 338 977 q 259 889 286 917 q 208 842 231 861 l 125 842 l 125 860 z ",
        },
        î: {
            ha: 359,
            x_min: -45,
            x_max: 405,
            o: "m 241 0 l 118 0 l 118 745 l 241 745 l 241 0 m 405 842 l 323 842 q 252 898 288 865 q 180 968 215 930 q 107 898 143 930 q 37 842 71 865 l -45 842 l -45 860 q -5 905 -28 880 q 41 959 18 931 q 84 1014 64 986 q 115 1064 104 1041 l 245 1064 q 276 1014 256 1041 q 318 959 296 986 q 365 905 341 931 q 405 860 388 880 l 405 842 z ",
        },
        ï: {
            ha: 359,
            x_min: -12,
            x_max: 372,
            o: "m 241 0 l 118 0 l 118 745 l 241 745 l 241 0 m -12 946 q 7 999 -12 983 q 53 1015 26 1015 q 99 999 79 1015 q 119 946 119 982 q 99 893 119 910 q 53 876 79 876 q 7 893 26 876 q -12 946 -12 910 m 242 946 q 261 999 242 983 q 307 1015 280 1015 q 332 1011 320 1015 q 353 999 344 1007 q 367 977 361 990 q 372 946 372 964 q 353 893 372 910 q 307 876 333 876 q 261 893 280 876 q 242 946 242 910 z ",
        },
        ð: {
            ha: 802,
            x_min: 75,
            x_max: 725,
            o: "m 725 389 q 702 218 725 292 q 637 92 680 143 q 534 13 595 40 q 399 -14 474 -14 q 269 9 328 -14 q 166 74 210 31 q 99 180 123 117 q 75 323 75 243 q 97 467 75 404 q 158 572 118 529 q 255 636 198 614 q 383 659 312 659 q 505 638 452 659 q 586 578 557 618 l 591 581 q 530 726 570 661 q 428 846 489 791 l 254 742 l 204 819 l 351 908 q 295 946 324 927 q 235 982 267 964 l 282 1065 q 368 1019 326 1044 q 449 965 409 995 l 603 1059 l 653 982 l 521 903 q 604 806 566 859 q 668 689 642 753 q 710 551 695 626 q 725 389 725 476 m 597 357 q 586 431 597 395 q 551 495 574 467 q 490 540 527 523 q 401 557 452 557 q 312 541 349 557 q 252 496 275 526 q 217 421 228 466 q 205 319 205 377 q 217 225 205 268 q 252 153 228 183 q 313 105 276 122 q 401 89 350 89 q 552 156 506 89 q 597 357 597 224 z ",
        },
        ñ: {
            ha: 818,
            x_min: 118,
            x_max: 707,
            o: "m 583 0 l 583 479 q 548 612 583 568 q 436 656 512 656 q 344 638 381 656 q 284 586 307 621 q 251 501 261 551 q 241 386 241 451 l 241 0 l 118 0 l 118 745 l 218 745 l 236 644 l 243 644 q 283 695 260 673 q 334 730 307 716 q 393 751 362 745 q 457 758 425 758 q 644 693 581 758 q 707 486 707 629 l 707 0 l 583 0 m 515 843 q 461 855 488 843 q 408 881 434 867 q 359 908 382 896 q 313 920 335 920 q 267 902 283 920 q 241 842 250 884 l 170 842 q 185 916 174 882 q 214 972 196 949 q 257 1008 232 996 q 313 1021 282 1021 q 369 1009 342 1021 q 422 983 397 997 q 472 957 448 968 q 515 945 495 945 q 561 962 545 945 q 587 1022 576 980 l 658 1022 q 643 948 654 981 q 614 892 632 916 q 571 856 596 869 q 515 843 546 843 z ",
        },
        ò: {
            ha: 802,
            x_min: 77,
            x_max: 725,
            o: "m 725 374 q 702 209 725 281 q 637 87 680 136 q 534 12 595 38 q 399 -14 474 -14 q 270 12 329 -14 q 168 87 211 38 q 101 209 125 136 q 77 374 77 281 q 99 537 77 466 q 164 658 122 609 q 267 733 206 707 q 403 758 328 758 q 532 733 473 758 q 634 658 591 707 q 701 537 677 609 q 725 374 725 466 m 204 374 q 251 160 204 232 q 401 89 297 89 q 552 160 506 89 q 597 374 597 232 q 551 586 597 515 q 400 656 505 656 q 250 586 296 656 q 204 374 204 515 m 506 842 l 425 842 q 373 889 401 861 q 319 947 345 917 q 272 1004 293 977 q 239 1050 250 1031 l 239 1064 l 388 1064 q 413 1014 399 1041 q 444 959 428 986 q 475 905 459 931 q 506 860 492 880 l 506 842 z ",
        },
        ó: {
            ha: 802,
            x_min: 77,
            x_max: 725,
            o: "m 725 374 q 702 209 725 281 q 637 87 680 136 q 534 12 595 38 q 399 -14 474 -14 q 270 12 329 -14 q 168 87 211 38 q 101 209 125 136 q 77 374 77 281 q 99 537 77 466 q 164 658 122 609 q 267 733 206 707 q 403 758 328 758 q 532 733 473 758 q 634 658 591 707 q 701 537 677 609 q 725 374 725 466 m 204 374 q 251 160 204 232 q 401 89 297 89 q 552 160 506 89 q 597 374 597 232 q 551 586 597 515 q 400 656 505 656 q 250 586 296 656 q 204 374 204 515 m 321 860 q 352 905 336 880 q 384 959 368 931 q 414 1014 399 986 q 439 1064 428 1041 l 587 1064 l 587 1050 q 555 1004 576 1031 q 508 947 534 977 q 454 889 481 917 q 403 842 427 861 l 321 842 l 321 860 z ",
        },
        ô: {
            ha: 802,
            x_min: 77,
            x_max: 725,
            o: "m 725 374 q 702 209 725 281 q 637 87 680 136 q 534 12 595 38 q 399 -14 474 -14 q 270 12 329 -14 q 168 87 211 38 q 101 209 125 136 q 77 374 77 281 q 99 537 77 466 q 164 658 122 609 q 267 733 206 707 q 403 758 328 758 q 532 733 473 758 q 634 658 591 707 q 701 537 677 609 q 725 374 725 466 m 204 374 q 251 160 204 232 q 401 89 297 89 q 552 160 506 89 q 597 374 597 232 q 551 586 597 515 q 400 656 505 656 q 250 586 296 656 q 204 374 204 515 m 622 842 l 540 842 q 469 898 505 865 q 397 968 432 930 q 324 898 360 930 q 254 842 288 865 l 172 842 l 172 860 q 212 905 189 880 q 258 959 235 931 q 301 1014 281 986 q 332 1064 321 1041 l 462 1064 q 493 1014 473 1041 q 535 959 513 986 q 582 905 558 931 q 622 860 605 880 l 622 842 z ",
        },
        õ: {
            ha: 802,
            x_min: 77,
            x_max: 725,
            o: "m 725 374 q 702 209 725 281 q 637 87 680 136 q 534 12 595 38 q 399 -14 474 -14 q 270 12 329 -14 q 168 87 211 38 q 101 209 125 136 q 77 374 77 281 q 99 537 77 466 q 164 658 122 609 q 267 733 206 707 q 403 758 328 758 q 532 733 473 758 q 634 658 591 707 q 701 537 677 609 q 725 374 725 466 m 204 374 q 251 160 204 232 q 401 89 297 89 q 552 160 506 89 q 597 374 597 232 q 551 586 597 515 q 400 656 505 656 q 250 586 296 656 q 204 374 204 515 m 499 843 q 445 855 472 843 q 393 881 418 867 q 343 908 367 896 q 298 920 319 920 q 251 902 267 920 q 225 842 235 884 l 155 842 q 169 916 158 882 q 198 972 180 949 q 241 1008 216 996 q 298 1021 267 1021 q 354 1009 326 1021 q 407 983 381 997 q 456 957 433 968 q 499 945 479 945 q 545 962 530 945 q 571 1022 561 980 l 642 1022 q 628 948 639 981 q 598 892 616 916 q 555 856 581 869 q 499 843 530 843 z ",
        },
        ö: {
            ha: 802,
            x_min: 77,
            x_max: 725,
            o: "m 725 374 q 702 209 725 281 q 637 87 680 136 q 534 12 595 38 q 399 -14 474 -14 q 270 12 329 -14 q 168 87 211 38 q 101 209 125 136 q 77 374 77 281 q 99 537 77 466 q 164 658 122 609 q 267 733 206 707 q 403 758 328 758 q 532 733 473 758 q 634 658 591 707 q 701 537 677 609 q 725 374 725 466 m 204 374 q 251 160 204 232 q 401 89 297 89 q 552 160 506 89 q 597 374 597 232 q 551 586 597 515 q 400 656 505 656 q 250 586 296 656 q 204 374 204 515 m 203 946 q 222 999 203 983 q 269 1015 241 1015 q 315 999 295 1015 q 334 946 334 982 q 315 893 334 910 q 269 876 295 876 q 222 893 241 876 q 203 946 203 910 m 458 946 q 477 999 458 983 q 523 1015 496 1015 q 548 1011 536 1015 q 568 999 559 1007 q 583 977 577 990 q 588 946 588 964 q 568 893 588 910 q 523 876 549 876 q 477 893 496 876 q 458 946 458 910 z ",
        },
        "÷": {
            ha: 765,
            x_min: 69,
            x_max: 696,
            o: "m 69 439 l 69 541 l 696 541 l 696 439 l 69 439 m 304 253 q 311 293 304 277 q 327 319 317 309 q 352 333 338 329 q 382 338 366 338 q 412 333 397 338 q 437 319 426 329 q 454 293 448 309 q 460 253 460 277 q 454 215 460 231 q 437 188 448 199 q 412 173 426 178 q 382 168 397 168 q 352 173 366 168 q 327 188 338 178 q 311 215 317 199 q 304 253 304 231 m 304 726 q 311 766 304 750 q 327 792 317 782 q 352 807 338 802 q 382 811 366 811 q 412 807 397 811 q 437 792 426 802 q 454 766 448 782 q 460 726 460 750 q 454 688 460 704 q 437 662 448 672 q 412 646 426 651 q 382 642 397 642 q 352 646 366 642 q 327 662 338 651 q 311 688 317 672 q 304 726 304 704 z ",
        },
        ø: {
            ha: 802,
            x_min: 78,
            x_max: 726,
            o: "m 726 374 q 704 209 726 281 q 638 87 681 136 q 536 12 596 38 q 400 -14 475 -14 q 249 22 315 -14 l 203 -52 l 114 -1 l 168 87 q 102 209 125 136 q 78 374 78 281 q 101 537 78 466 q 165 658 123 609 q 268 733 208 707 q 404 758 329 758 q 485 748 447 758 q 557 720 524 739 l 603 793 l 692 743 l 637 656 q 703 535 680 606 q 726 374 726 465 m 205 374 q 212 272 205 317 q 233 193 218 228 l 503 632 q 457 650 483 644 q 401 656 432 656 q 251 586 297 656 q 205 374 205 515 m 599 374 q 572 549 599 481 l 302 111 q 348 94 323 100 q 403 89 372 89 q 553 160 507 89 q 599 374 599 232 z ",
        },
        ù: {
            ha: 818,
            x_min: 111,
            x_max: 700,
            o: "m 600 0 l 582 100 l 575 100 q 535 49 558 71 q 484 14 511 28 q 425 -7 456 0 q 361 -14 393 -14 q 252 2 299 -14 q 174 51 205 18 q 127 136 143 84 q 111 258 111 187 l 111 745 l 235 745 l 235 265 q 271 133 235 177 q 382 89 307 89 q 474 106 437 89 q 534 158 511 124 q 567 243 557 193 q 576 358 576 293 l 576 745 l 700 745 l 700 0 l 600 0 m 470 842 l 389 842 q 337 889 365 861 q 283 947 309 917 q 236 1004 257 977 q 203 1050 214 1031 l 203 1064 l 352 1064 q 377 1014 363 1041 q 408 959 392 986 q 439 905 423 931 q 470 860 456 880 l 470 842 z ",
        },
        ú: {
            ha: 818,
            x_min: 111,
            x_max: 700,
            o: "m 600 0 l 582 100 l 575 100 q 535 49 558 71 q 484 14 511 28 q 425 -7 456 0 q 361 -14 393 -14 q 252 2 299 -14 q 174 51 205 18 q 127 136 143 84 q 111 258 111 187 l 111 745 l 235 745 l 235 265 q 271 133 235 177 q 382 89 307 89 q 474 106 437 89 q 534 158 511 124 q 567 243 557 193 q 576 358 576 293 l 576 745 l 700 745 l 700 0 l 600 0 m 332 860 q 362 905 347 880 q 394 959 378 931 q 425 1014 410 986 q 450 1064 439 1041 l 598 1064 l 598 1050 q 566 1004 587 1031 q 518 947 545 977 q 465 889 492 917 q 414 842 437 861 l 332 842 l 332 860 z ",
        },
        û: {
            ha: 818,
            x_min: 111,
            x_max: 700,
            o: "m 600 0 l 582 100 l 575 100 q 535 49 558 71 q 484 14 511 28 q 425 -7 456 0 q 361 -14 393 -14 q 252 2 299 -14 q 174 51 205 18 q 127 136 143 84 q 111 258 111 187 l 111 745 l 235 745 l 235 265 q 271 133 235 177 q 382 89 307 89 q 474 106 437 89 q 534 158 511 124 q 567 243 557 193 q 576 358 576 293 l 576 745 l 700 745 l 700 0 l 600 0 m 631 842 l 549 842 q 477 898 514 865 q 406 968 441 930 q 333 898 369 930 q 262 842 297 865 l 180 842 l 180 860 q 221 905 198 880 q 267 959 244 931 q 310 1014 290 986 q 340 1064 330 1041 l 471 1064 q 502 1014 481 1041 q 544 959 522 986 q 590 905 567 931 q 631 860 614 880 l 631 842 z ",
        },
        ü: {
            ha: 818,
            x_min: 111,
            x_max: 700,
            o: "m 600 0 l 582 100 l 575 100 q 535 49 558 71 q 484 14 511 28 q 425 -7 456 0 q 361 -14 393 -14 q 252 2 299 -14 q 174 51 205 18 q 127 136 143 84 q 111 258 111 187 l 111 745 l 235 745 l 235 265 q 271 133 235 177 q 382 89 307 89 q 474 106 437 89 q 534 158 511 124 q 567 243 557 193 q 576 358 576 293 l 576 745 l 700 745 l 700 0 l 600 0 m 210 946 q 229 999 210 983 q 275 1015 248 1015 q 321 999 301 1015 q 340 946 340 982 q 321 893 340 910 q 275 876 301 876 q 229 893 248 876 q 210 946 210 910 m 464 946 q 483 999 464 983 q 529 1015 502 1015 q 554 1011 542 1015 q 574 999 566 1007 q 589 977 583 990 q 594 946 594 964 q 574 893 594 910 q 529 876 555 876 q 483 893 502 876 q 464 946 464 910 z ",
        },
        ý: {
            ha: 679,
            x_min: 7,
            x_max: 672,
            o: "m 7 745 l 135 745 l 281 329 q 300 272 290 302 q 318 212 310 242 q 333 155 327 182 q 342 103 339 127 l 346 103 q 357 149 349 120 q 373 211 364 178 q 392 276 382 244 q 410 330 402 308 l 545 745 l 672 745 l 378 -97 q 337 -196 359 -152 q 286 -270 315 -239 q 217 -317 256 -300 q 123 -334 178 -334 q 62 -330 88 -334 q 18 -322 37 -326 l 18 -224 q 54 -230 33 -227 q 100 -232 76 -232 q 156 -224 132 -232 q 197 -201 180 -216 q 228 -165 215 -186 q 250 -115 240 -143 l 289 -7 l 7 745 m 276 860 q 307 905 291 880 q 339 959 323 931 q 369 1014 355 986 q 394 1064 383 1041 l 543 1064 l 543 1050 q 510 1004 532 1031 q 463 947 489 977 q 409 889 437 917 q 358 842 382 861 l 276 842 l 276 860 z ",
        },
        þ: {
            ha: 814,
            x_min: 118,
            x_max: 737,
            o: "m 241 644 q 277 689 257 668 q 322 725 296 709 q 381 749 349 740 q 454 758 414 758 q 570 734 518 758 q 660 661 623 709 q 717 541 696 612 q 737 374 737 469 q 717 206 737 278 q 660 85 696 134 q 570 11 623 36 q 454 -14 518 -14 q 382 -5 414 -14 q 323 19 349 4 q 277 53 297 33 q 241 94 257 73 l 233 94 q 237 50 235 71 q 240 14 239 33 q 241 -16 241 -5 l 241 -334 l 118 -334 l 118 1055 l 241 1055 l 241 745 l 236 644 l 241 644 m 431 656 q 343 640 379 656 q 286 593 308 625 q 253 513 264 561 q 241 401 243 466 l 241 374 q 251 252 241 305 q 282 162 260 199 q 340 107 304 126 q 432 89 377 89 q 567 163 524 89 q 610 375 610 237 q 567 586 610 516 q 431 656 524 656 z ",
        },
        ÿ: {
            ha: 679,
            x_min: 7,
            x_max: 672,
            o: "m 7 745 l 135 745 l 281 329 q 300 272 290 302 q 318 212 310 242 q 333 155 327 182 q 342 103 339 127 l 346 103 q 357 149 349 120 q 373 211 364 178 q 392 276 382 244 q 410 330 402 308 l 545 745 l 672 745 l 378 -97 q 337 -196 359 -152 q 286 -270 315 -239 q 217 -317 256 -300 q 123 -334 178 -334 q 62 -330 88 -334 q 18 -322 37 -326 l 18 -224 q 54 -230 33 -227 q 100 -232 76 -232 q 156 -224 132 -232 q 197 -201 180 -216 q 228 -165 215 -186 q 250 -115 240 -143 l 289 -7 l 7 745 m 155 946 q 174 999 155 983 q 220 1015 193 1015 q 266 999 246 1015 q 286 946 286 982 q 266 893 286 910 q 220 876 246 876 q 174 893 193 876 q 155 946 155 910 m 409 946 q 428 999 409 983 q 474 1015 447 1015 q 499 1011 487 1015 q 519 999 511 1007 q 534 977 528 990 q 539 946 539 964 q 519 893 539 910 q 474 876 500 876 q 428 893 447 876 q 409 946 409 910 z ",
        },
        ı: {
            ha: 359,
            x_min: 118,
            x_max: 241,
            o: "m 241 0 l 118 0 l 118 745 l 241 745 l 241 0 z ",
        },
        ˆ: {
            ha: 802,
            x_min: 175,
            x_max: 625,
            o: "m 625 842 l 543 842 q 472 898 509 865 q 400 968 435 930 q 328 898 363 930 q 257 842 292 865 l 175 842 l 175 860 q 216 905 193 880 q 261 959 239 931 q 304 1014 284 986 q 335 1064 324 1041 l 465 1064 q 496 1014 476 1041 q 539 959 516 986 q 585 905 562 931 q 625 860 608 880 l 625 842 z ",
        },
        "˚": {
            ha: 802,
            x_min: 248,
            x_max: 554,
            o: "m 554 989 q 542 927 554 954 q 510 880 530 899 q 460 851 489 861 q 399 842 432 842 q 337 851 365 842 q 289 880 309 861 q 258 926 269 899 q 248 987 248 953 q 258 1049 248 1022 q 289 1095 269 1076 q 337 1123 309 1114 q 399 1133 365 1133 q 460 1123 431 1133 q 509 1095 488 1114 q 542 1049 530 1076 q 554 989 554 1023 m 475 987 q 453 1044 475 1023 q 399 1064 432 1064 q 343 1044 365 1064 q 322 987 322 1023 q 341 931 322 951 q 399 911 361 911 q 453 931 432 911 q 475 987 475 951 z ",
        },
        "˜": {
            ha: 802,
            x_min: 175,
            x_max: 663,
            o: "m 519 843 q 466 855 492 843 q 413 881 439 867 q 363 908 387 896 q 318 920 340 920 q 271 902 288 920 q 245 842 255 884 l 175 842 q 190 916 178 882 q 219 972 201 949 q 262 1008 237 996 q 318 1021 287 1021 q 374 1009 347 1021 q 427 983 401 997 q 476 957 453 968 q 519 945 500 945 q 566 962 550 945 q 591 1022 581 980 l 663 1022 q 648 948 659 981 q 619 892 637 916 q 576 856 601 869 q 519 843 551 843 z ",
        },
        "–": {
            ha: 694,
            x_min: 56,
            x_max: 639,
            o: "m 56 315 l 56 429 l 639 429 l 639 315 l 56 315 z ",
        },
        "—": {
            ha: 1389,
            x_min: 56,
            x_max: 1333,
            o: "m 56 315 l 56 429 l 1333 429 l 1333 315 l 56 315 z ",
        },
        "‘": {
            ha: 243,
            x_min: 16,
            x_max: 228,
            o: "m 25 652 l 16 667 q 38 745 25 703 q 67 828 52 786 q 100 912 83 870 q 135 991 118 954 l 228 991 q 208 905 218 950 q 189 816 198 861 q 173 730 180 772 q 160 652 165 688 l 25 652 z ",
        },
        "’": {
            ha: 243,
            x_min: 16,
            x_max: 228,
            o: "m 218 991 l 228 977 q 205 898 218 939 q 176 815 192 857 q 143 731 160 772 q 109 652 125 690 l 16 652 q 35 737 25 692 q 54 827 45 782 q 71 913 63 871 q 83 991 78 956 l 218 991 z ",
        },
        "‚": {
            ha: 347,
            x_min: 43,
            x_max: 256,
            o: "m 245 161 l 256 146 q 233 68 246 109 q 204 -16 220 26 q 171 -99 188 -58 q 136 -179 153 -141 l 43 -179 q 63 -93 53 -138 q 82 -4 73 -48 q 98 83 91 41 q 111 161 106 125 l 245 161 z ",
        },
        "“": {
            ha: 504,
            x_min: 16,
            x_max: 489,
            o: "m 286 652 l 277 667 q 299 745 286 703 q 328 828 313 786 q 361 912 344 870 q 396 991 379 954 l 489 991 q 469 905 479 950 q 450 816 459 861 q 434 730 441 772 q 421 652 427 688 l 286 652 m 25 652 l 16 667 q 38 745 25 703 q 67 828 52 786 q 100 912 83 870 q 135 991 118 954 l 228 991 q 208 905 218 950 q 189 816 198 861 q 173 730 180 772 q 160 652 165 688 l 25 652 z ",
        },
        "”": {
            ha: 504,
            x_min: 16,
            x_max: 489,
            o: "m 218 991 l 228 977 q 205 898 218 939 q 176 815 192 857 q 143 731 160 772 q 109 652 125 690 l 16 652 q 35 737 25 692 q 54 827 45 782 q 71 913 63 871 q 83 991 78 956 l 218 991 m 479 991 l 489 977 q 466 898 479 939 q 437 815 453 857 q 404 731 421 772 q 370 652 387 690 l 277 652 q 296 737 286 692 q 315 827 306 782 q 332 913 324 871 q 345 991 339 956 l 479 991 z ",
        },
        "„": {
            ha: 608,
            x_min: 43,
            x_max: 517,
            o: "m 245 161 l 256 146 q 233 68 246 109 q 204 -16 220 26 q 171 -99 188 -58 q 136 -179 153 -141 l 43 -179 q 63 -93 53 -138 q 82 -4 73 -48 q 98 83 91 41 q 111 161 106 125 l 245 161 m 507 161 l 517 146 q 494 68 507 109 q 465 -16 481 26 q 432 -99 449 -58 q 397 -179 414 -141 l 304 -179 q 324 -93 314 -138 q 343 -4 334 -48 q 359 83 352 41 q 372 161 367 125 l 507 161 z ",
        },
        "•": {
            ha: 522,
            x_min: 102,
            x_max: 421,
            o: "m 102 507 q 114 589 102 555 q 148 645 126 623 q 198 675 169 666 q 261 685 227 685 q 323 675 294 685 q 374 645 353 666 q 409 589 396 623 q 421 507 421 555 q 409 426 421 460 q 374 371 396 392 q 323 339 353 349 q 261 329 294 329 q 198 339 227 329 q 148 371 169 349 q 114 426 126 392 q 102 507 102 460 z ",
        },
        "‹": {
            ha: 400,
            x_min: 56,
            x_max: 345,
            o: "m 56 375 l 265 656 l 345 603 l 183 367 l 345 131 l 265 78 l 56 357 l 56 375 z ",
        },
        "›": {
            ha: 400,
            x_min: 56,
            x_max: 345,
            o: "m 345 357 l 135 78 l 56 131 l 216 367 l 56 603 l 135 656 l 345 375 l 345 357 z ",
        },
        "⁄": {
            ha: 180,
            x_min: -239,
            x_max: 418,
            o: "m 418 991 l -132 0 l -239 0 l 311 991 l 418 991 z ",
        },
    },
    familyName: "Droid Sans",
    ascender: 1293,
    descender: -334,
    underlinePosition: -104,
    underlineThickness: 69,
    boundingBox: { yMin: -334, xMin: -239, yMax: 1293, xMax: 1333 },
    resolution: 1000,
    original_font_information: {
        format: 0,
        copyright: "Digitized data copyright © 2007, Google Corporation.",
        fontFamily: "Droid Sans",
        fontSubfamily: "Regular",
        uniqueID: "Ascender - Droid Sans",
        fullName: "Droid Sans",
        version: "Version 1.00 build 113",
        postScriptName: "DroidSans",
        trademark:
            "Droid is a trademark of Google and may be registered in certain jurisdictions.",
        manufacturer: "Ascender Corporation",
        description:
            "Droid Sans is a humanist sans serif typeface designed for user interfaces and electronic communication.",
        manufacturerURL: "http://www.ascendercorp.com/",
        designerURL: "http://www.ascendercorp.com/typedesigners.html",
        licence: "Licensed under the Apache License, Version 2.0",
        licenceURL: "http://www.apache.org/licenses/LICENSE-2.0",
        compatibleFullName: "Droid Sans",
    },
    cssFontWeight: "normal",
    cssFontStyle: "normal",
};