import React, {useState, useEffect, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import EnuFileModule from "components/atom/EnuFileModule"
import ProjectPageContent from "components/template/ProjectPageContent";
import {LoadingLayer} from "components/organism/LoadingPage";
import useEnuSpaceModule from 'hooks/useEnuSpaceModule';
import useLoadProject from 'hooks/useLoadProject';
import useWindowFun from 'hooks/useWindowFun';
import useProjectData from 'hooks/useProjectData';
import useAudio from 'hooks/useAudio';
import GetSQLiteFileList from 'hooks/useSQLite';

import { useNavigate } from 'react-router-dom'
import { setBaseData, setTreeData, deleteTreeData, InitProjectData, AllClearCanvasView} from 'reducers/projectDataReducer'
import { AllClearModalPopup } from 'reducers/modulePopupReducer';
import { showRightMenu } from 'reducers/rightMenuReducer';

function EditModePage({isTemplate = false}){
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {ProjectSave} = useProjectData();
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [enuSpace] = useEnuSpaceModule();
    const [ProjectTreeData, setProjectTreeData] = useState(null); //프로젝트 트리 데이터

    useAudio(enuSpace); // 오디오 재생
    
    const location = useLocation();
    const BASE_DATA = useMemo(() => {
        return {
            userId: location?.state?.userId,
            projectId: location?.state?.projectId,
            projectName: location?.state?.projectName
        }
    }, [location?.state?.userId, location?.state?.projectId, location?.state?.projectName]);

    const URL = '/Repository/'+ BASE_DATA.userId +'/'+ BASE_DATA.projectId + '/'+BASE_DATA.projectName +'.enup';
    useLoadProject(enuSpace, location.state && URL, true, isTemplate, location.state?.templateJson, location.state?.tempImageInfo);
    
    useEffect(()=>{
        //FIXME: 231203 sessionStorage 에 저장하는 projectId 위치를 프로젝트 로딩하면서 저장하도록 변경 확인 필요 편집작업시 계속 login하는 문제가 해결되는게 검증되면 삭제처리 
        localStorage.setItem('projectId', BASE_DATA.projectId);  
        localStorage.removeItem('publishId');
        
        dispatch(setBaseData(BASE_DATA));
    }, [BASE_DATA, dispatch]);

    // 프로젝트 정보를 받아서 Explorer 창 업데이트 및 첫 픽처 페이지 오픈
    window.Re_UpdateProjectCtrl = (_msg) => {
        console.log(`%c Re_UpdateProjectCtrl---`, 'color: blue');
        const {type, data: projectTreeData} = JSON.parse(_msg);
        if(type === 'success'){
            dispatch(setTreeData(projectTreeData));
            
            if(!isTemplate)
                setIsLoadingPage(false);
            else
                setProjectTreeData(projectTreeData);
        }else if(type === 'failed'){
            
        }
    }

    window.Re_SetView_Edit = () =>{
        if(!isTemplate) return

        const ActivateViewPath = enuSpace.GetActivateView();
        ProjectSave(enuSpace, ActivateViewPath, ProjectTreeData, 'save');
        const {userId, projectId, projectName} = BASE_DATA;
        navigate('/MyRepository/editMode', { state: { userId, projectId , projectName }, replace: true});
    }

    useEffect(() => {
        dispatch(InitProjectData());
        dispatch(deleteTreeData());

        return () => {
            dispatch(AllClearModalPopup());
            dispatch(showRightMenu({
                type: null, top: null, left: null, data: {}
            }));
            dispatch(AllClearCanvasView());
        };
    }, [dispatch]);

    const {SetGlobalValues, RefreshAccessToken, ShowLink, EditModeShowMarkdown, ShowDocumentModal, cursorType} = useWindowFun(enuSpace, setIsLoadingPage);
    window.Re_SetGlobalValues = SetGlobalValues;
    window.Re_RefreshAccessToken = RefreshAccessToken;
    window.Re_ShowLink = ShowLink;
    window.Re_ShowMarkdown = EditModeShowMarkdown;
    window.Re_ShowDocument = ShowDocumentModal;

    return(
        <>
            <LoadingLayer marginTop={"30px"} isLoadingPage={isLoadingPage}/>
            <ProjectPageContent enuSpace={enuSpace} style={{cursor: cursorType}}/>
            <EnuFileModule enuSpace={enuSpace}/>
        </>
    )
}

export default EditModePage;