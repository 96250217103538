import { useState ,useEffect, useCallback } from "react";

import { ModalBottomBox, SubTitle, InputContent} from "components/css/common";
import {BtnSecondary, BtnPrimary, BtnText} from "components/atom/Button";
import { AUTHORITY_TYPE, PUBLISH_TYPE} from "components/common/EnuSpaceEnum";
import {RepositoryRadio} from "components/atom/RadioContent";
import {GetFriendList} from "api/SnsApi";
import {AddOrUpdateArchiveAccess, DeleteArchiveAccess, UpdateArchiveProject} from "api/ArchiveApi";

import styled from "styled-components"
import { MdClose } from "react-icons/md";

export default function AccessSetting({BaseInfoState, GrantedFriends, ReloadGrantedFriends, CloseEvent}){
    const [FriendList, setFriendList] = useState([]);
    // right에 있는 권한 리스트 갱신을 Set이후에 반영하기 위한 임시 저장소
    const [TempGrantedFriends, setTempGrantedFriends] = useState([]);
    // 삭제할 권한 리스트를 저장하기 위한 임시 저장소
    const [DeleteAccessFriendsEmail, setDeleteAccessFriendsEmail] = useState([]);
    const [TempProjectInfo, setTempProjectInfo] = useState([]);
    const [BaseInfo, setBaseInfo] = BaseInfoState;
    const {userId: UserId, pageId: PageId} = BaseInfo;

    useEffect(()=>{
        setTempProjectInfo({
            ARCHIVE_SYSTEMID: BaseInfo.pageId,
            ARCHIVE_NAME: BaseInfo.pageName,
            DESCRIPTION: BaseInfo.description,
            PUBLISH_MODE: BaseInfo.publishType,
        })
    }, [BaseInfo]);

    const LoadFriendList = useCallback(() => {
        GetFriendList(UserId)
            .then((res) => {
                const{result, msg, data} = res.data;
                if(result === 'Success'){
                    setFriendList(data);
                }else{
                    window.AddAlertMsg({type:'error', msg:`${msg}`});
                }
            })
            .catch((err) => {
                window.AddAlertMsg({type:'error', msg:`${err}`});
            })
    }, [UserId]);

    useEffect(() => {
        LoadFriendList();
    }, [LoadFriendList]);

    useEffect(() => {
        // master 권한은 생성자 본인만 가질 수 있며 권한 리스트에서 표시하지 않음
        const TempData = [...GrantedFriends];
        setTempGrantedFriends(TempData.filter((item) => item.AUTHORITY !== AUTHORITY_TYPE.MASTER));
    }, [GrantedFriends, BaseInfo]);

    const AccessSettingHandler = (e) => {
        // TODO: 추후 Setting 페이지에 프로젝트 정보 변경 component 추가시 변경 필요
        // 프로젝트 Publish Type 변경을 위한 처리
        const updateArchiveProject = BaseInfo.publishType !== TempProjectInfo.PUBLISH_MODE ?
            UpdateArchiveProject(TempProjectInfo.ARCHIVE_SYSTEMID, TempProjectInfo.ARCHIVE_NAME, TempProjectInfo.DESCRIPTION, TempProjectInfo.PUBLISH_MODE)
                .then((res) => {
                    const { result } = res.data;
                    if (result === 'Success') {
                        setBaseInfo({ ...BaseInfo, publishType: TempProjectInfo.PUBLISH_MODE });
                    }
                }).catch((err) => {
                    window.AddAlertMsg({ type: 'error', msg: `${err}` });
                }) : Promise.resolve();

        // 권한 추가를 위한 처리
        const addAccessPromise = TempGrantedFriends.length > 0 ?
            AddOrUpdateArchiveAccess(PageId, UserId, TempGrantedFriends)
                .then((res) => {
                    const { result, msg } = res.data;
                    if (result === 'Success') {
                        window.AddToastMsg(msg);
                    } else {
                        window.AddAlertMsg({ type: 'error', msg: `${msg}` });
                    }
                }).catch((err) => {
                    window.AddAlertMsg({ type: 'error', msg: `${err}` });
                }) : Promise.resolve();

        // 권한 제거를 위한 처리
        const deleteAccessPromise = DeleteAccessFriendsEmail.length > 0 ?
            DeleteArchiveAccess(PageId, UserId, DeleteAccessFriendsEmail)
                .then((res) => {
                    const { result, msg } = res.data;
                    if (result === 'Success') {
                        window.AddToastMsg(msg);
                    } else {
                        window.AddAlertMsg({ type: 'error', msg: `${msg}` });
                    }
                }).catch((err) => {
                    window.AddAlertMsg({ type: 'error', msg: `${err}` });
                }).finally(() => {
                    setDeleteAccessFriendsEmail([]);
                }) : Promise.resolve();

        // 모든 비동기 작업이 완료된 후 권한 리스트 재로딩
        Promise.all([updateArchiveProject, addAccessPromise, deleteAccessPromise])
            .then(() => {
                ReloadGrantedFriends && ReloadGrantedFriends();
            });

    };
    
    return(
        <BaseContent>
            <PublishContent>
                <SubTitle>
                    Publish Type
                </SubTitle>
                <CustomRepositoryRadio 
                    attributeName='PublishType'
                    isUseKeyLabel={true}
                    dataList={PUBLISH_TYPE}
                    selectedValue={TempProjectInfo.PUBLISH_MODE}
                    titleList={{PUBLIC: 'Public', PRIVATE: 'Private'}}
                    changeEvent={(name, value) => {
                        console.log(value)
                        setTempProjectInfo(perv => ({...perv, PUBLISH_MODE: value}));
                    }}
                />

            </PublishContent>

            <MainContent>
                <FriendContent>
                    <ListHeader>
                        <SubTitle>Friend List</SubTitle>
                        <InputContent type="text" id='FriendSearch' name='Filter' title="Search" value={''} placeholder='search...' onChange={(e) => {}} />
                    </ListHeader>
                    <FriendListInfo>
                        {FriendList && 
                            FriendList.map((friendInfo) => {
                                const {EMAIL, NICKNAME} = friendInfo

                                const isGrantedFriend = TempGrantedFriends.find((item) => item.EMAIL === EMAIL);
                                if(isGrantedFriend) return null;

                                return (
                                    <li key={EMAIL}>
                                        <div className="info">
                                            <span>{NICKNAME}</span>
                                            <span>{EMAIL}</span>
                                        </div>
                                        <BtnSecondary 
                                            label="Select" 
                                            onClick={(e) => {
                                                setTempGrantedFriends([...TempGrantedFriends, {EMAIL, NICKNAME, AUTHORITY:AUTHORITY_TYPE.NORMAL}]);
                                                setDeleteAccessFriendsEmail(pervMail => pervMail.filter((item) => item !== EMAIL));
                                            }}
                                        />
                                    </li>
                                )
                            }
                        )}
                    </FriendListInfo>
                </FriendContent>

                <AccessRoleContent>
                    <ListHeader>
                        <SubTitle>Set User Role</SubTitle>
                    </ListHeader>
                    <FriendListInfo id='AccessList'>
                        {TempGrantedFriends.map((friendInfo) => {
                            const {EMAIL, NICKNAME, AUTHORITY } = friendInfo
    
                            return (
                                <li key={EMAIL}>
                                    <div className="info">
                                        <span>{NICKNAME}</span>
                                        <span>{EMAIL}</span>
                                    </div>
                                    <div className="option">
                                        <SelectContent
                                            name='accessRole' 
                                            value={AUTHORITY} 
                                            onChange={(e)=>{
                                                const AUTHORITY = e.target.value;
                                                setTempGrantedFriends(perv => perv.map((item) => item.EMAIL === EMAIL ? {...item, AUTHORITY} : item))
                                            }} 
                                        >
                                            {Object.entries(AUTHORITY_TYPE).map((type) => {
                                                const [key, value] = type;

                                                // Master 권한은 생성자 본인만 가질 수 있음
                                                if(value === AUTHORITY_TYPE.MASTER || value === AUTHORITY_TYPE.NONE) return null;
                                                return <option key={key} value={value}>{key}</option>
                                            })}
                                        </SelectContent>
                                        
                                        {/* Access 제거 */}
                                        <BtnText 
                                            label={<MdClose />} 
                                            onClick={(e)=>{
                                                setTempGrantedFriends(TempGrantedFriends.filter((item) => item.EMAIL !== EMAIL));
                                                setDeleteAccessFriendsEmail(perv => [...perv, EMAIL])
                                            }} 
                                        />
                                    </div>
                                </li>
                            )
                        })}

                    </FriendListInfo>
                </AccessRoleContent>
            </MainContent>
            
            {/* Set  */}
            <BottomBox marginTop='16px'>
                <BtnSecondary 
                    label="Create" 
                    style={{'width':'80px'}} 
                    onClick={(e) => {
                        CloseEvent && CloseEvent();
                    }}
                />

                <BtnPrimary
                    label="Set"
                    style={{ 'width': '80px' }}
                    onClick={AccessSettingHandler}
                />
            </BottomBox>
        </BaseContent>
    )
}

const BaseContent = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 32px 24px 24px 24px;
    box-sizing: border-box;
`;

const CustomRepositoryRadio = styled(RepositoryRadio)`
    height: 100%;
`;

const PublishContent = styled.div`
    position: relative;
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 38px;
    gap: 8px;
    box-sizing: border-box;

    &>div{
        width: 400px;
        flex-grow: 0;
    }
`;

const MainContent = styled.div`
    position: relative;
    display: flex;
    height: calc(100% - 124px);
    gap: 40px;
    border-top: 1px solid ${({theme}) => theme.base.border_color_Gray};
    margin-top: 16px;
    padding-top: 16px;
`;

const FriendContent = styled.div`
    position: relative;
    width: 50%;
    height: 100%;
`;

const AccessRoleContent = styled.div`
    position: relative;
    width: 50%;
    height: 100%;
`;

const ListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    position: relative;
    width: 100%;
    height: 36px;
    margin-bottom: 8px;
    user-select: none;

    input{
        flex-grow: initial;
    }
`;

const BaseUl = styled.ul`
    position: relative;
    width: 100%;
    height: calc(100% - 44px);
    border: 1px solid ${({theme}) => theme.base.border_color_Gray};
    border-radius: 4px;
    box-sizing: border-box;

    li{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
    }
`;

const FriendListInfo = styled(BaseUl)`
    &#AccessList{
        li{
            padding: 8px 0 8px 16px;
        }
    }

    .info{
        display: flex;
        flex-direction: column;
        gap: 6px;
        font-size: 1rem;
        color: ${({theme}) => theme.base.font_color_Black};

        span:nth-child(1){
            font-weight: bold;
        }
        span:nth-child(2){
            font-size: .9rem;
            opacity: 0.8;
        }
    }

    .option{
        position: relative;
        display: flex;
        gap: 8px;

        button{
            color: ${({theme}) => theme.base.font_color_Red};
            padding: 8px;
        }
    }
`;

const BottomBox = styled(ModalBottomBox)`
    position: relative;
    width: 100%;
    /* justify-content: center; */

    &>div{
        margin-top: 0;
    }
`;

const SelectContent = styled.select`
    font-size: .9rem;
    height: 38px;
    width: 90px;
    box-sizing: border-box;
    border: 1px solid  ${({theme}) => theme.base.border_color_Gray};
    border-radius: 4px;
    cursor: pointer;

    &:disabled{
        cursor:default;
        background-color: ${({theme}) => theme.base.background_color_PaleGray};
    }

    option{
        &[value="2"]{
            color: ${({theme}) => theme.AccessColor.Master};
        }
        &[value="1"]{
            color: ${({theme}) => theme.AccessColor.Manage};
        }
        &[value="0"]{
            color: ${({theme}) => theme.AccessColor.Write};
        }
    }
`;