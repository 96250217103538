import {useState} from 'react';

import MyInfo from 'components/organism/MyInfo';
import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {ModalBottomBox, WarningMsg} from 'components/css/common'
import {CheckPW, GetMyInfo} from 'hooks/useMember';

import { useNavigate } from 'react-router-dom';
import styled from "styled-components";

export default function MyPage(){
    const navigate = useNavigate();
    const [hasAccess, setHasAccess] = useState({ isPass: false, pw: '', msg: '', });
    const [userInfo, setUserInfo] = useState({EMAIL:'', MEMBERNAME:'', NICKNAME:''});

    const systemId = localStorage.getItem('systemId');

    const checkSubmit = (e) => {
        e.preventDefault();
        if (hasAccess.pw === '') {
            setHasAccess({ ...hasAccess, msg: 'Password 입력해주세요.' })
            return;
        }

        CheckPW(systemId, hasAccess.pw)
            .then(res => {
                const resData = res['data'];
                if (resData.msg === 'Success'){
                    setHasAccess({ ...hasAccess, isPass:true, msg: '' })

                    GetMyInfo(systemId)
                        .then(res => {
                            const {result, msg} = res.data;
                            if(result === 'Success'){
                                const {EMAIL, MEMBERNAME, NICKNAME} = msg;
                                setUserInfo({EMAIL, MEMBERNAME, NICKNAME});
                            }else
                                setHasAccess({ ...hasAccess, msg })
                        })
                        .catch(err => {
                             setHasAccess({ ...hasAccess, msg: err })
                        });
                }else
                    setHasAccess({ ...hasAccess, msg: '다시 확인해 주세요.' })
            }).catch(err => { 
                setHasAccess({ ...hasAccess, msg: err })
            });
    }

    // 처음 접속시 비밀번호 확인
    if (hasAccess.isPass) {
        return (
            <MyPageContent>
                <MyInfo _UserInfo={userInfo} HasAccessPw={hasAccess.pw} />
            </MyPageContent>
        )
    } else {
        return (
            <PassWordCheck>
                <InputPW value={hasAccess.pw || ''} name="pw" onChange={(e)=>setHasAccess({...hasAccess, pw:e.target.value, msg:''})} onKeyDown={(e)=>{if(e.key === 'Enter') checkSubmit(e)}} type="password" placeholder='password를 입력해주세요.'/>
                <WarningMsg >{hasAccess.msg}</WarningMsg>
                <ModalBottomBox>
                    <BtnSecondary label="Cancel" onClick={(e)=>navigate(-1)} />
                    <BtnPrimary label="Accept" onClick={checkSubmit} />
                </ModalBottomBox>
            </PassWordCheck>
        )
    }
}

//스타일-------------------------------------
const MyPageContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - 72px);
    
    &>div{
        width: 370px;
    }
`;

const PassWordCheck = styled.div`
    margin: 40px auto;
    position: relative;
    width: 310px;
    padding: 16px 24px;
    border-radius: 0.3rem;
    box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
    background-color: #fff;
    z-index:9;
`;

const InputPW= styled.input`
    height: auto;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.base.border_color_Gray};
    border-radius: 4px;
    padding: 8px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    flex-grow: 1;

    &:focus{
        outline:none;
        border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
    }
    
    &:disabled{
        padding: 0;
        background-color: transparent;
        border: 0 none;
    }

    &::placeholder {
        color: ${({ theme }) => theme.base.font_color_DarkGray};
        opacity: 0.3;
    }
`;
