import { Vector3, MeshBuilder, Color3 } from "@babylonjs/core";
import "@babylonjs/loaders/OBJ";

export function CreateAxis(scene, size){
    const axisX = MeshBuilder.CreateLines(
        "support",
        { points: [new Vector3(-size, 0, 0), new Vector3(size, 0, 0),], },
        scene,
    );
    const axisY = MeshBuilder.CreateLines(
        "support",
        { points: [new Vector3(0, -size, 0), new Vector3(0, size, 0),], },
        scene,
    );
    const axisZ = MeshBuilder.CreateLines(
        "support", { points: [new Vector3(0, 0, -size), new Vector3(0, 0, size),], },
        scene,
    );

    axisX.color = new Color3(1, 0, 0);
    axisY.color = new Color3(0, 1, 0);
    axisZ.color = new Color3(0, 0, 1);

    const local_origin = MeshBuilder.CreateBox('support', { size: 0.01 }, scene);
    local_origin.isVisible = false;

    axisX.parent = local_origin;
    axisY.parent = local_origin;
    axisZ.parent = local_origin;

    return local_origin;
}