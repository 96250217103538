import { useState, useEffect } from "react";

import {BtnText} from "components/atom/Button";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFormatListBulleted, MdHorizontalRule, MdAdd} from "react-icons/md";
import styled from "styled-components";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function DocPdfModal({enuSpace, _Modal}){
    const [scale, setScale] = useState({
        value: 1.0,
        inputValue: 100,
    });
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isSideBar, setIsSideBar] = useState(false);
    const [, setIsLoading] = useState(true);
    const {
        path: ModalDataPath,
        pageState: ModalDataPageSate,
        baseData: ModalDataBaseData,
    } = _Modal.data;

    const ModalElement = document.getElementById(_Modal.id);

    const isMyRepository = ModalDataBaseData.currentURL.includes('/MyRepository');
    const repoType = isMyRepository ? `/${ModalDataBaseData.userId}` : '/publish';
    const PDF_URL = `/Repository${repoType}/${ModalDataBaseData.projectId}/${ModalDataPath}`

    useEffect(() => {
        let PageNum = ModalDataPageSate;
        if(PageNum <= 0){
            PageNum = 1;
        }else if(PageNum > numPages){
            PageNum = numPages;
        }
        setPageNumber(PageNum)
    }, [ModalDataPageSate, numPages]);


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setIsLoading(false);
    }
    
    return(
        <PdfContent>
            <div>
                <Header>
                    <div>
                        <BtnText label={<MdFormatListBulleted className="btn" />} onClick={(e)=>setIsSideBar(prv => !prv)}/>
                    </div>

                    <div>
                        <BtnText label={<MdKeyboardArrowLeft className="btn" />} onClick={(e)=>setPageNumber(prv => prv <=1  ? 1 : prv - 1)}/>
                        <span>
                            <input type="number" value={pageNumber || 1} onChange={(e)=>{
                                let ChangedPageNumber = Number(e.target.value);
                                if(ChangedPageNumber <= 1)
                                    ChangedPageNumber = 1;
                                else if(ChangedPageNumber >= numPages)
                                    ChangedPageNumber = numPages;
                                setPageNumber(ChangedPageNumber);
                            }}/>
                            &nbsp; / {numPages}
                        </span>
                        <BtnText label={<MdKeyboardArrowRight className="btn"/>} onClick={(e)=>setPageNumber(prv => prv >= numPages ? numPages : prv + 1)}/>
                    </div>

                    {/* FIXME: 스케일 관련 추가 여부 고려 */}
                    <div style={{gap: '0'}}>
                        <BtnText 
                            label={<MdHorizontalRule className="btn" />} 
                            onClick={(e) =>{
                                if(scale.inputValue <= 10){
                                    setScale({value: 0.1, inputValue: 10})
                                }else{
                                    setScale(prv => ({
                                        value: Number(prv.value) - 0.1,
                                        inputValue: Number(prv.inputValue) - 10,
                                    }));
                                } }} 
                        />
                        <input type="number" value={scale.inputValue} 
                            onChange={(e) => setScale({...scale, inputValue: e.target.value})}
                            onBlur={(e) => {
                                let value = e.target.value;
                                if(scale <= 10){
                                    value = 10;
                                }else{
                                    value /= 100;
                                }
                                setScale({...scale, value})
                            }}
                            onKeyDown={(e) => {
                                if(e.key === 'Enter'){
                                    e.target.blur();
                                }
                            }}
                        />
                        %
                        <BtnText label={<MdAdd className="btn" />} onClick={(e) => {
                            setScale(prv => ({
                                value: Number(prv.value) + 0.1,
                                inputValue: Number(prv.inputValue) + 10,
                            }));
                        }} />
                    </div>
                </Header>
                <div className="body">
                    {isSideBar &&
                        <SideBar>
                            <Document
                                className="SideDocument"
                                file={PDF_URL}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >

                                {Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            className={pageNumber === index + 1 ? "active" : ""}
                                            pageNumber={index + 1}
                                            scale={0.3}
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                            onClick={(e) => setPageNumber(index + 1)}
                                        />
                                    ),
                                )}
                            </Document>
                        </SideBar>
                    }
                    
                    <Document
                        className={`pdfDocument ${isSideBar && "sideBar"}`}
                        file={PDF_URL}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page
                            pageNumber={pageNumber}
                            scale={scale.value}
                            onLoadSuccess={(page) => {
                                const canvasElement = ModalElement.querySelector('.pdfDocument');
                                const hasHorizontalScrollbar = canvasElement.scrollWidth > canvasElement.clientWidth;
                                const hasVerticalScrollbar = canvasElement.scrollHeight > canvasElement.clientHeight;
    
                                if(hasHorizontalScrollbar || hasVerticalScrollbar){
                                    canvasElement.classList.add('onScroll');
                                }else{
                                    canvasElement.classList.remove('onScroll');
                                }
                            }}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                        />
                    </Document>
                </div>
            </div>
        </PdfContent>
    )
}

const PdfContent = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #343a40;
    margin-top: 0!important;

    &>div{
        height: 100%;

        &>.body{
            position: relative;
            display: flex;
            width: 100%;
            height: calc(100% - 60px);

            .pdfDocument{
                position: relative;
                height: 100%;
                width: 100%;
                overflow: overlay;
                margin: 0 auto;

                &:not(.onScroll){
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &>div{
                        height: auto;
                    }
                }

                &.sideBar{
                    width: calc(100% - 180px);
                }

                &>div{
                    height: 0;
                }

                canvas {
                }
            }
        }
    }
`;

const SideBar = styled.div`
    position: relative;
    width: 180px;
    height: 100%;
    border-right: 1px solid rgba(255, 255, 255, .3);
    overflow-y: overlay;
    
    &>.SideDocument{
        position: relative;
        width: 100%;
        height: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 8px;
        box-sizing: border-box;

        canvas {
            width: 100%!important;
            height: auto!important;
            cursor: pointer;

            &:hover{
                outline: 8px solid ${({theme})=>theme.base.border_color_Blue};
            }
        }
    }

    .active{
        outline: 8px solid ${({theme})=>theme.base.border_color_Blue};
    }
`;

const Header = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #292E33;
    z-index: 9;
    box-shadow: rgba(0, 0, 0, .5) 0px 0px 0px 1px, rgba(0, 0, 0, .5) 0px 2px 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &>div{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #fff;

        .btn{
            color: #fff;
            font-size: 1.6rem;
            opacity: .6;

            &:hover{
                opacity: 1;
            }
        }

        input{
            color: #fff;
            font-size: 1rem;
            width: 36px;
            text-align: center;
            background-color: #0B0D0E;
            outline: 0 none;
            border: 0 none;

            &[type="number"]::-webkit-outer-spin-button,
            &[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
`;