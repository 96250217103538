import { useState, useEffect } from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";
import SelectList from "components/atom/SelectList";
import ColorComponent from "components/molecule/ColorComponent";
import FontFamily from 'components/common/FontFamily';
import {FontSizeIcon} from 'components/common/EnuSpaceIcon';
import { GroupName, ToggleRect } from 'components/css/common';
import { floatToStr } from 'components/Utility';

import styled from "styled-components";
import { MdFormatItalic } from "react-icons/md";

export default function TrendLegendInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        font_family: NodeFontFamily, 
        font_style: NodeFontStyle, 
        font_weight: NodeFontWeight, 
        font_size: NodeFontSize, 
        row_num: NodeRowNum,
        column_num: NodeColumnNum,
        symbol_width: NodeSymbolWidth,
        symbol_height: NodeSymbolHeight,
        symbol_text_gap: NodeSymbolTextGap,
        stroke_width: NodeStrokeWidth,
        top_gap: NodeTopGap,
        left_gap: NodeLeftGap,
        right_gap: NodeRightGap,
        bottom_gap: NodeBottomGap,
    } = _data;

    const [fontClass] = useState(new FontFamily());
    const [GapPosition, setGapPosition] = useState({top:0, left:0, right:0, bottom:0});
    
    useEffect(()=>{
        setGapPosition({
            top: floatToStr(NodeTopGap),
            left: floatToStr(NodeLeftGap),
            right: floatToStr(NodeRightGap),
            bottom: floatToStr(NodeBottomGap)
        });
    }, [NodeTopGap, NodeLeftGap, NodeRightGap, NodeBottomGap]);

    const RenderGapInput = (type) =>{
        return(
            <input 
                className={type}
                type='number'
                value={GapPosition[type]}
                disabled={_disabled}
                onChange={(e)=> setGapPosition(prev => ({...prev, [type]: e.target.value}))}
                onKeyDown={(e)=>{
                    if(e.key === 'Enter') e.target.blur()
                }}
                onBlur={(e)=>{
                    if(e.target.value === ''){
                        setGapPosition(prev => ({...prev, [type]: floatToStr(_data[`${type}_gap`])}))
                        return;
                    }
                    _ChangeEvent([{attributeName: `${type}-gap` ,attributeValue: e.target.value}])
                }}

            />
        )
    }

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title="Legend">
            <GapContent>
                {RenderGapInput('top')}
                {RenderGapInput('left')}
                <div className="insideBox" />
                {RenderGapInput('right')}
                {RenderGapInput('bottom')}
            </GapContent>
            <div>
                <InputComponent label='row' attributeName='row-num' valueType='number' labelWidth='24px' value={NodeRowNum} {...commonProps}/>
                <InputComponent label='col' attributeName='column-num' valueType='number' labelWidth='24px' value={NodeColumnNum} {...commonProps}/>
            </div>
            <div className="margin-top-div">
                <GroupName width={76}>symbol</GroupName>
                <InputComponent label='W' attributeName='symbol-width' valueType='number' labelWidth='24px' value={NodeSymbolWidth} {...commonProps}/>
                <InputComponent label='H' attributeName='symbol-height' valueType='number' labelWidth='24px' value={NodeSymbolHeight} {...commonProps}/>
            </div>
            <div>
                <CustomInputComponent label='symbol-text-gap' valueType='number' labelWidth='156px' value={NodeSymbolTextGap} {...commonProps}/>
            </div>
            <div className="margin-top-div">
                <ColorComponent label="fill" isGradient={false} isVisibility={false}  {...commonProps}/>
            </div>
            <div>
                <ColorComponent label="stroke" isGradient={false} isVisibility={false}  {...commonProps}/>
            </div>
            <div>
                <CustomGroupName >stroke-width</CustomGroupName>
                <InputComponent 
                    label={<img src="/img/properties/icon_stroke_weight.png" alt="stroke-width" />} 
                    attributeName='stroke-width' 
                    valueType='number' 
                    labelWidth='24px' 
                    value={NodeStrokeWidth} 
                    {...commonProps} 
                />
            </div>
            <div className="margin-top-div">
                <SelectList
                    attributeName="font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <SelectList
                    attributeName="font-weight"
                    dataList={fontClass.GetFontWeight(NodeFontFamily)}
                    selectedValue={fontClass.CheckFontWeight(NodeFontFamily, NodeFontWeight)}
                    {...commonProps}
                />
                <InputComponent
                    label={<FontSizeIcon />}
                    attributeName="font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeFontSize}
                    {...commonProps}
                />
                <ToggleRect 
                    disabled={_disabled}
                    onClick={(e)=>{
                        if (_disabled) return;
                        const attributeValue = Array.isArray(NodeFontStyle) ? 'italic' : ['normal','0'].includes(NodeFontStyle) ? 'italic' : 'normal';
                        _ChangeEvent([{attributeName: 'font-style', attributeValue}]);
                    }}
                >
                    <MdFormatItalic className={Array.isArray(NodeFontStyle) ? '' :  ['normal', '0'].includes(NodeFontStyle) ? '' : 'active'}/>
                </ToggleRect>
            </div>
            <div>
                <ColorComponent label="font" attributeName="font-color" isGradient={false} isVisibility={false}  {...commonProps}/>
            </div>
        </PropertiesContent>
    )
}

//스타일-------------------------
const GapContent = styled.div`
    display: grid !important;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    grid-template-areas:
		"   .   top .   "
		"   left    insideBox   right"
		"   .   bottom    . ";

    grid-row-gap: 2px;
    grid-column-gap: 6px;
    padding: 2px;
    border: 1px solid ${({theme}) => theme.base.border_color_LightGray};
    border-radius: 2px;
    box-sizing: box-sizing;
    background-color: ${({theme}) => theme.base.background_color_PaleGray};

    &>div{
        margin-left: 0 !important;
    }

    .top{
        grid-area: top;
        justify-self: center;
    }
    .left{
        grid-area: left;
    }
    .insideBox{
        grid-area: insideBox;
        align-self: stretch;
    }
    .right{
        grid-area: right;
    }
    .bottom{
        grid-area: bottom;
        justify-self: center;
    }

    &>input{
        height: 28px;
        width: 44px;
        text-align: center;
        background-color: transparent;
        border: 1px solid ${({theme}) => theme.base.border_color_Gray};
        border-radius: 2px;
        box-sizing: border-box;

        &:hover{
            border-color: ${({theme}) => theme.base.border_color_Blue};
            background-color: #fff;
        }

        &:focus{
            outline:none;
            border-color:${({theme}) => theme.base.border_color_Blue};
            background-color: #fff;
        }
        &:selection{
            background-color: ${({theme}) => theme.base.background_color_lightBlue};
            border: 0 none;
            border-color:${({theme}) => theme.base.border_color_LightGray};
            background-color: #fff;
        }

        &:disabled{
            border: 0 none;
            background-color: transparent;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .insideBox{
        width: 100%;
        border: 1px dashed ${({theme}) => theme.base.border_color_Gray};
        border-radius: 2px;
        background-color: #fff;
        box-sizing: box-sizing;
        margin-left: 0;
    }
`;

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;

const CustomGroupName = styled(GroupName)`
    width: 100%;
    max-width: 208px;
    min-width: 128px;
`;