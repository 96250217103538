
import { BoxMeshIcon, ContourMeshIcon, CylinderMeshIcon } from "babylon/common/Enu3DIcon"
import EnuBox from "babylon/class/EnuBox";
import EnuCylinder from 'babylon/class/EnuCylinder';
import EnuContour from 'babylon/class/EnuContour';

import styled from "styled-components";

const SUPPORT_DRAW_MESH = [
    {MeshName:'box', Icon: <BoxMeshIcon/>, EventDraw:(EnuScene)=>new EnuBox(EnuScene)},
    {MeshName:'cylinder', Icon: <CylinderMeshIcon/>, EventDraw:(EnuScene)=>new EnuCylinder(EnuScene)},
    {MeshName:'contour', Icon: <ContourMeshIcon/>, EventDraw:(EnuScene)=>new EnuContour(EnuScene)},
]

export default function MeshTool({EnuScene}){
    
    return(
        <ToolUl>
            {SUPPORT_DRAW_MESH.map(toolInfo =>{
                const {MeshName, Icon, EventDraw} = toolInfo;
                return(
                    <li key={MeshName} title={MeshName} onClick={(e)=>EventDraw(EnuScene)}>
                        {Icon}
                    </li>
                )
            })}
        </ToolUl>
    )
}

const ToolUl = styled.ul`
    position: relative;
    height: 100%;
    display: flex;

    li{
        position: relative;
        padding: 12px;
        cursor: pointer;

        &:hover {
            &>svg{
                &>.bg{
                    fill: ${({theme}) => theme.base.background_color_LightBlue};
                }
                &>.border, &>.contourBg{
                    fill: ${({theme}) => theme.base.border_color_Blue};
                }
            }
        }
    }
`;