import {useState} from "react";

import FriendListContent from "components/organism/FriendListContent";
import GroupListContent from "components/organism/GroupListContent";

import styled from "styled-components"
import { RepositoryRadio} from "components/atom/RadioContent";

const TapLabel = {
    Friend : 'Friend',
    Grouped: 'Grouped',
};

export default function GroupsPage(){
    const [CurrentTab, setCurrentTab] = useState(TapLabel.Friend);

    return (
        <GroupContent>
            <GroupInfo>
                {/* 탭 */}
                <CustomRepositoryRadio 
                    attributeName='GroupTeb'
                    dataList={TapLabel}
                    titleList={TapLabel}
                    selectedValue={CurrentTab}
                    changeEvent={(name, value) => {
                        const {Friend, Grouped} = TapLabel;
                        setCurrentTab(value === Friend ? Friend : Grouped);
                    }}
                />

                {CurrentTab === TapLabel.Friend
                    ? <FriendListContent />
                    : <GroupListContent />
                }
            </GroupInfo>
        </GroupContent>
    );
}

const GroupContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - 72px);
    
    &>div{
        width: 370px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;

const GroupInfo = styled(Content)`
    padding-top: 24px;
    align-items: center;
    user-select: none;
    height: calc(100vh - 96px);
`;

const CustomRepositoryRadio = styled(RepositoryRadio)`
    width: 100%;
    flex-grow: 0;
    height: 40px;
`;