import { useState, useEffect, useCallback } from "react";

import { BtnSecondary, BtnGhost, BtnSubDanger} from "components/atom/Button";
import BtnToggle from "components/atom/BtnToggle";
import {DatabaseSchedule, DatabaseScheduleScript} from 'components/modal_new/DatabaseModals'
import { ModalBottomBox, SubTitle, TableContent} from 'components/css/common';
import { GetScheduleInfo, DeleteSchedule, SetScheduleActivation } from 'api/DeviceApi'

import styled from "styled-components"
import { MdAdd, MdClear, MdModeEdit, } from "react-icons/md";

export default function DBTagPageSchedule({ userId, lever, height, deviceInfo }) {
    const { TABLE_ID } = deviceInfo;
    const [ScheduleList, setScheduleList] = useState([]);
    const [isShowModal, setIsShowModal] = useState({ visibility: false });
    const [selectedSchedule, setSelctedSchedule] = useState({});
    const GetScheduleData = useCallback(() => {
        GetScheduleInfo(userId, TABLE_ID)
            .then(res => {
                const { DATA, RESULT, MSG } = res.data;
                RESULT === 'Ok' ? setScheduleList(DATA) : alert(MSG);
            })
    }, [userId, TABLE_ID]);

    useEffect(() => {
        GetScheduleData();
    }, [GetScheduleData]);

    const ToggleActivationEvent = async (TableId, value) =>{
        const ChangedValue = !value;
        const res = await SetScheduleActivation(userId, TableId, ChangedValue);
        const { MSG, RESULT } = res;
        if (RESULT === "Ok") {
            return true;
        } else {
            alert(MSG);
            return value;
        }
    }

    return (
        <ScheduleListContent height={height}>
            <TableHeader marginTop={'8px'}>
                <SubTitle> Schedule List </SubTitle>
                <div className="BtnListBox">
                    {['Edit', 'Manage', 'Master'].includes(lever) &&
                        <BtnSecondary
                            label={<MdAdd style={{ fontSize: '1.5rem', color: 'royalblue' }} />}
                            onClick={(e) => setIsShowModal({ mode: 'create' })}
                        />
                    }
                </div>
            </TableHeader>
            <ScheduleTable>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <td className='Activated'>Act</td>
                                <th className='State'>State</th>
                                <th className='Name'>Name</th>
                                <th className='Method'>Method</th>
                                <th className='URL'>URL</th>
                                <th className='Interval'>Interval</th>
                                <th className='Description'>Description</th>
                                <th className='Btn Body'>Body</th>
                                <th className='Btn Script'>Script</th>
                                <th className='Btn'>Edit</th>
                                <th className='Btn'>Del</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ScheduleList &&
                                ScheduleList.map((schedule, index) => {
                                    const { SCHEDULE_STATE, SCHEDULE_NAME, SCHEDULE_ID, METHOD, URL, BODY, PARSING_SCRIPT, SCHEDULE_INTERVAL, DESCRIPTION, SCHEDULE_ACTIVATION } = schedule;

                                    let ActivationValue = SCHEDULE_ACTIVATION === 'Activated';
                                    const IsActiveBtn = ['Manage', 'Master'].includes(lever);

                                    const EditBtn = ({ onClickEvent }) => <BtnGhost label={<MdModeEdit />} type='sm' onClick={(e) => onClickEvent && onClickEvent()} />

                                    return (
                                        <tr key={SCHEDULE_ID} onClick={()=> setSelctedSchedule(schedule)}>
                                            <td className='Activated'>
                                                {IsActiveBtn &&
                                                    <BtnToggle 
                                                        _toggleID={`${SCHEDULE_ID}`} 
                                                        _isToggleValue={ActivationValue} 
                                                        _ToggleEventHandler={() => ToggleActivationEvent(SCHEDULE_ID, ActivationValue)}
                                                    />
                                                }
                                            </td>
                                            <td className='State'>{SCHEDULE_STATE}</td>
                                            <td className='Name'>{SCHEDULE_NAME}</td>
                                            <td className='Method'>{METHOD}</td>
                                            <td className='URL'>{URL}</td>
                                            <td className='Interval'>{SCHEDULE_INTERVAL}</td>
                                            <td className='Description'>{DESCRIPTION}</td>
                                            <td className='Body'>
                                                <EditBtn onClickEvent={(e) => setIsShowModal({ mode: 'body', script: BODY })} />
                                            </td>
                                            <td className='Script'>
                                                <EditBtn onClickEvent={(e) => setIsShowModal({ mode: 'script', script: PARSING_SCRIPT || `function parse(input)\n    return input\nend` })} />
                                            </td>
                                            <td className='Btn'>
                                                <EditBtn onClickEvent={(e) => setIsShowModal({ mode: 'edit', data: schedule, })} />
                                            </td>
                                            <td className='Btn'>
                                                <BtnSubDanger
                                                    label={<MdClear />}
                                                    type='sm'
                                                    onClick={(e) => {
                                                        if (!window.confirm(`"${SCHEDULE_NAME}" 을 삭제하시겠습니까?`)) return;

                                                        DeleteSchedule(userId, SCHEDULE_ID)
                                                            .then(res => {
                                                                const { RESULT, MSG } = res.data;
                                                                RESULT === 'Ok' ? GetScheduleData() : alert(MSG);
                                                            })
                                                            .catch(err => {
                                                                alert(err);
                                                            })
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </ScheduleTable>
            {(isShowModal?.mode === 'create' || isShowModal?.mode === 'edit') &&
                <DatabaseSchedule userId={userId} tableId={TABLE_ID} mode={isShowModal?.mode} scheduleData={isShowModal?.data} reloadEvent={GetScheduleData} closeEvent={(e) => setIsShowModal()} />
            }
            {(isShowModal?.mode === 'script' || isShowModal?.mode === 'body') &&
                <DatabaseScheduleScript userId={userId} tableId={TABLE_ID} mode={isShowModal?.mode} scheduleData={selectedSchedule} GetScheduleData={GetScheduleData} closeEvent={(e) => setIsShowModal()} />
            }
        </ScheduleListContent>
    )
}

const ScheduleListContent = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    margin-top: 16px;
    height: ${({ height }) => height ? height : '100%'};
`;

const TableHeader = styled(ModalBottomBox)`
    justify-content: space-between;

    &>.BtnListBox{
        display: flex;
        gap: 8px;

        .tableType{
            width: 100px;
        }
    }
`;

const ScheduleTable = styled(TableContent)`
    height: 100%;
    background: #FFF;
    z-index:1;
    .Activated{
        width: 44px;
        text-align: center;

        &>span label{
            margin: 0 auto;
        }
    }

    .State{
        width: 90px;
        text-align: center;
    }

    .Name{
        width: 200px;
        text-align: center;
    }

    .Method{
        width: 74px;
        text-align: center;
    }
    
    .URL{

    }

    .Description{

    }

    .Interval{
        width: 70px;
        text-align: center;
    }

    .Btn{
        width: 48px;

        &>button{
            padding: 2px 6px;

            svg{
                font-size: 1rem;
            }
        }
    }

    .Body, .Script{
        width: 58px;
        
        &>button{
            margin: 0 auto;
            padding: 2px 6px;

            svg{
                font-size: 1rem;
            }
        }
    }
`;