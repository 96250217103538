import { useState, useCallback, useRef, useLayoutEffect, useEffect, useReducer } from 'react';

import Checkbox from "components/atom/Checkbox";
import {BtnPrimary, BtnSecondary, BtnSubDanger, BtnGhost, BtnText} from "components/atom/Button";
import BoardReplay from 'components/molecule/BoardReplay'
import {FormatFileSize} from 'components/Utility'
import {ARCHIVE_POST_TYPE, AUTHORITY_TYPE} from "components/common/EnuSpaceEnum";
import { GroupName, InputContent, ModalBottomBox, TextareaContent, SelectContent } from 'components/css/common';
import {useWebSocketFunction} from 'hooks/useWebSocketControl'
import {CreateBoardPost, GetBoardPost, DeleteBoardPost, UpdateBoardPost} from "api/ArchiveApi";

import styled from 'styled-components';
import { MdClose, MdAttachFile } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

function reducer(state, action){
    switch(action.type){
        case 'changePost':{
            return {...state, POST_TYPE: action.postType}
        }
        case 'changeIsOpen':{
            return {...state, IS_OPEN: action.isOpen}
        }
        case 'changeTitle':{
            return {...state, TITLE: action.title}
        }
        case 'changeContent':{
            return {...state, CONTENT: action.content}
        }
        case 'readInit':{
            return {...action.init, ORIGIN: {...action.init}}
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`); 
    }
}

function fileReducer(state, action){
    switch(action.type){
        case 'clear':{
            return {Temp: null, Pool:[], List:{}, Size:0}
        }
        case 'uploadReady':{
            return {...state, Temp: {...action.temp}, Pool:[...action.pool]}
        }
        case 'removePool':{
            const Pool = state.Pool.filter(item => item.name !== action.fileName);
            return {...state, Pool}
        }
        case 'initTemp':{
            return {...state, Temp: null}
        }
        case 'checkedFail':{
            for (const key in state.Temp) {
                if (state.Temp[key].name === action.fileName) {
                    state.Temp[key].state = 'fail';
                    break;
                }
            }
            return {...state}
        }
        case 'addList':{
            let Size = 0;
            const addUploadFile = {};
            let startIndx = Object.keys(state.List).length;

            Object.entries(state.Temp).forEach(([key, file]) => {
                if (file?.state === 'fail') {
                    return;
                }
                addUploadFile[startIndx++] = file;
                Size += file.size;
            });
        
            Object.values(state.List).forEach(file => {
                Size += file.size;
            });
        
            return { ...state, Temp: null, List: { ...state.List, ...addUploadFile }, Size };
        }
        case 'deleteTemp':{
            delete state.Temp[action.index]
            return { ...state };
        }
        case 'deleteList':{
            const Size = state.Size - state.List[action.index].size;
            delete state.List[action.index];
            return {...state,  Size}
        }
        case 'setSize':{
            return {...state,  Size: action.size}
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`); 
    }
}

const init = {
    IS_OPEN: true,
    POST_TYPE: ARCHIVE_POST_TYPE.NORMAL,
    TITLE: '',
    CONTENT: '',
    TEMP_SYSTEMID: uuidv4().replace(/-/g, ''),
    FILE_LIST: [],
}

const BOARD_STATE = {
    NEW: 'new',
    READ: 'read',
    EDIT: 'edit',
}

const BOARD_DATA_MAX_SIZE = 200 * 1024 * 1024;

export default function BoardDetail({BaseInfo, MyAccess}){
    const navigate = useNavigate();
    const InputFile = useRef(null);
    const {pageId, userId, postIndex} = BaseInfo;

    // const {ws, send, onMessage} = useWebSocketControl(`wss://${document.location.hostname}:${document.location.port}/BoardFiles`);
    const { ws, send, onMessage, ConnectSock, CloseSock } = useWebSocketFunction(`wss://${document.location.hostname}:${document.location.port}/BoardFiles`);
    const [FileData, setFileData] = useReducer(fileReducer, {Temp: null, Pool:[], List:{}, Size:0})
    const [BoardData, setBoardData] = useReducer(reducer, init)

    const [IsFileProcessModal, setIsFileProcessModal] = useState(false);

    const [DeleteFileIdex, setDeleteFileIdex] = useState([]);  // edit 시 제거할 파일 idex
    const [TempBlobData, setTempBlobData] = useState([]);         // 서버로부터 전달받은 바이러리 데이터를 다운로드 처리전 임시로 보관하는 State
    const [Mode, setMode] = useState();

    const GetBoardPostData = useCallback(() => {
        GetBoardPost(pageId, userId, postIndex)
            .then((res) => {
                const {result, data: boardData} = res.data;
                if(result === 'Success'){
                    setMode(BOARD_STATE.READ);
                    setBoardData({type:'readInit', init:boardData});
                }
            }).catch((err) => {
                window.AddAlertMsg({type:'error', msg:`${err}`})
            })
    }, [pageId, userId, postIndex]);

    useLayoutEffect(()=>{
        if(postIndex === 'Create'){
            setMode(BOARD_STATE.NEW)
        }else{
            GetBoardPostData();
        }
    }, [postIndex, GetBoardPostData]);

    const SocketUpload = useCallback((fileInfo) => {
        const { name: FILE_NAME, size: FILE_SIZE } = fileInfo;
        send({
            MESSAGE: 'UploadFileTemp',
            DATA: {
                ARCHIVE_SYSTEMID: pageId,
                MEMBER_SYSTEMID: userId,
                TEMP_SYSTEMID: BoardData.TEMP_SYSTEMID,
                FILE_NAME,
                FILE_SIZE,
            }
        });

        const reader = new FileReader();
        reader.onload = function (event) {
            const data = event.target.result;
            ws?.send(data);
        };
        reader.readAsArrayBuffer(fileInfo);
    }, [BoardData.TEMP_SYSTEMID, pageId, userId, send, ws]);

    useEffect(() => {
        onMessage(msg => {
            const { data } = msg;

            if (typeof (data) === "string") {
                const { TYPE, Percent, Result, File_Name, Msg } = JSON.parse(data);

                if (TYPE === 'Res_UploadFileTemp') {
                    // 파일 업로드
                    if (Result === "Processing") {
                        document.getElementById(File_Name).value = Percent;
                        return;
                    }

                    setFileData({type:'removePool', fileName:File_Name})

                    if (Result === 'Fail') {
                        setFileData({type:'checkedFail', fileName:File_Name})
                        const targetElement = document.getElementById(File_Name);
                        const parentElement = targetElement.parentNode;
                        targetElement.remove();
                        const spanElement = document.createElement('span');
                        spanElement.textContent = Msg;
                        parentElement.appendChild(spanElement);
                    }

                } else if (TYPE === 'Res_DownloadFile') {
                    // 파일다운로드
                    if (Result === "Success") {
                        setTempBlobData(prev => {
                            return prev.map(item => {
                                const { FILE_NAME, FILE_EXTENSION } = item;
                                if (`${FILE_NAME}${FILE_EXTENSION}` === File_Name) {
                                    return { ...item, STATE: 'Completed' };
                                } else
                                    return item;
                            })
                        })
                    } else if (Result === "File") {
                        // TODO: 실패시 로직 추후 진행
                    }
                }
            } else {
                // 문자가 아닌 것은 데이터 정보
                setTempBlobData(prev => {
                    return prev.map(item => {
                        if (item.STATE === 'Downloading') {
                            return { ...item, DATA: [...item.DATA, data] };
                        }
                        return item;
                    });
                });
            }
        })
    }, [onMessage]);

    useEffect(()=>{
        if (FileData.Pool[0]) {
            SocketUpload(FileData.Pool[0]);
        }
    }, [FileData.Pool, SocketUpload]);

    const DownLoadFile = useCallback((TargetTempBlobData)=>{
        const {STATE, FILE_IDX, DATA, FILE_NAME, FILE_EXTENSION} = TargetTempBlobData;
        if(STATE === 'Downloading') return;

        if(STATE === "Pending"){
            ConnectSock({
                MESSAGE: 'DownloadFile',
                DATA: {
                    ARCHIVE_SYSTEMID: pageId,
                    MEMBER_SYSTEMID: userId,
                    POST_IDX: Number(postIndex),
                    FILE_IDX,
                }
            });

            setTempBlobData(prev => {
                return prev.map(item => {
                    if (item.FILE_IDX === FILE_IDX) {
                        return { ...item, STATE: 'Downloading' };
                    }
                    return item;
                });
            });
        }else if(STATE === "Completed"){
            const targetFileName = `${FILE_NAME}${FILE_EXTENSION}`;
            const url = window.URL.createObjectURL(new Blob(DATA));
            const a = document.createElement('a');
            a.href = url;
            a.download = targetFileName;
            document.body.appendChild(a);
            a.click();
    
            window.URL.revokeObjectURL(url);
            CloseSock();

            setTempBlobData(prev =>{
                return prev.filter(item => `${item.FILE_NAME}${item.FILE_EXTENSION}` !== targetFileName)
            })
        }

    }, [pageId, userId, postIndex, ConnectSock, CloseSock]);

    useEffect(()=>{
        if(!TempBlobData[0]) return;
        DownLoadFile(TempBlobData[0]);
    }, [TempBlobData, DownLoadFile]);

    return(
        <>
            <BaseContent>
                {MyAccess !== AUTHORITY_TYPE.NORMAL && (Mode === BOARD_STATE.NEW || Mode === BOARD_STATE.EDIT ) &&
                    <div id='OptionRow' className='Row'>
                        <div id='postType'>
                            <GroupName width={80} htmlFor='POST_TYPE'>글 타입</GroupName>
                            <SelectContent
                                name="POST_TYPE"
                                height={36}
                                width={100}
                                value={BoardData.POST_TYPE}
                                onFocus={(e) => document.activeElement.blur()}
                                onChange={(e)=>setBoardData({type:'changePost', postType:e.target.value})}
                            >
                                <option value={ARCHIVE_POST_TYPE.NORMAL}>일반글</option>
                                <option value={ARCHIVE_POST_TYPE.STICKY}>고정글</option>
                                <option value={ARCHIVE_POST_TYPE.ANNOUNCEMENT}>공지글</option>
                            </SelectContent>
                        </div>

                        <div className='CheckBox'>
                            <GroupName width={80} htmlFor='IS_OPEN'>공개여부</GroupName>
                            <Checkbox
                                _id='IS_OPEN'
                                checked={BoardData.IS_OPEN === 0 ? false : true}
                                onChange={(e)=>{
                                    const isOpen = e.target.checked ? 1 : 0;
                                    setBoardData({type:'changeIsOpen', isOpen})
                                }}
                            />
                        </div>
                    </div>
                }

                <div id="TitleRow" className='Row'>
                    <div id='BorderTitle'>
                        <GroupName width={80} htmlFor='TITLE'>제목</GroupName>
                        <InputContent
                            id='TITLE'
                            type="text"
                            name='TITLE'
                            disabled={ Mode === BOARD_STATE.READ}
                            value={BoardData.TITLE || ''}
                            onChange={(e)=>setBoardData({type:'changeTitle', title:e.target.value})}
                        />
                    </div>
                </div>

                <div id="ContentRow" className='Row'>
                    <GroupName width={80} htmlFor='CONTENT'>내용</GroupName>
                    <BorderTextarea
                        name='CONTENT'
                        disabled={ Mode === BOARD_STATE.READ}
                        value={BoardData.CONTENT}
                        onChange={(e)=>setBoardData({type:'changeContent', content:e.target.value})}
                    />
                </div>

                <div id='AttachmentRow'>
                    <GroupName width={80} htmlFor='FILEDATA'>첨부파일</GroupName>
                    <div className={( Mode === BOARD_STATE.READ || "") ? 'disabled' : ''}>
                        {Mode !== BOARD_STATE.READ &&
                            <div id='fileInputBox'>
                                <div>
                                    <progress className='progressBar' max={BOARD_DATA_MAX_SIZE} value={FileData.Size} />
                                    <span>{`( ${FormatFileSize(BOARD_DATA_MAX_SIZE)} / ${FormatFileSize(FileData.Size)} )`}</span>
                                </div>

                                <BtnText 
                                    type='sm'
                                    label={<MdAttachFile/>}
                                    onClick={(e)=>document.getElementById('fileInput').click()}
                                />

                                <input 
                                    type="file" 
                                    id="fileInput" 
                                    ref={InputFile}
                                    accept="*" 
                                    multiple 
                                    onChange={(e) =>{
                                        const filesArray = Array.from(e.target.files);
                                        const fileListSize = filesArray.reduce((acc, item)=> acc + item.size, 0);

                                        if(BOARD_DATA_MAX_SIZE < fileListSize){
                                            window.AddAlertMsg({ type: 'error', msg: '최대 200 MB까지 첨부할수 있습니다.' })
                                            return ;
                                        }
                                        const isPass = filesArray.every(element => {
                                            const checkFileName = (fileMap, type) => {
                                                return Object.values(fileMap).some((info) => {
                                                    // { name: fileName }
                                                    const fileName = type === 'temp' ? info.name : `${info.FILE_NAME}${info.FILE_EXTENSION}`;
                                                    if (element.name === fileName) {
                                                        window.AddAlertMsg({ type: 'error', msg: `"${fileName}" 이미 등록된 파일입니다.` });
                                                        return true;
                                                    }
                                                    return false;
                                                });
                                            };
                                        
                                            const isFileDuplicate = checkFileName(FileData.List, 'temp');
                                            const isBoardDuplicate = checkFileName(BoardData.FILE_LIST || {}, 'board');
                                            return !isFileDuplicate && !isBoardDuplicate;
                                        });

                                        if (!isPass){
                                            InputFile.current.value = '';
                                            return;
                                        }

                                        ConnectSock();
                                        setIsFileProcessModal(true);
                                        setFileData({type:'uploadReady', temp:e.target.files, pool:filesArray});
                                    }}
                                />
                            </div>
                        }

                        {/* upload 파일 */}
                        <div className={`fileList ${Mode === BOARD_STATE.READ ? 'read' : null}`}>
                            {Object.entries(FileData.List).map(([fileIndex, fileInfo], index) => {
                                const {name: FILE_NAME, size: FILE_SIZE} = fileInfo;
                                return (
                                    <div key={index} className='file' >
                                        <div title={FILE_NAME}>
                                            <span className='fileName'>{FILE_NAME}</span>
                                            <span className='fileSize'>({FormatFileSize(FILE_SIZE)})</span>
                                        </div>

                                        <MdClose onClick={(e) => {
                                            send({
                                                MESSAGE: 'DeleteFileTemp',
                                                DATA: {
                                                    ARCHIVE_SYSTEMID: pageId,
                                                    MEMBER_SYSTEMID: userId,
                                                    TEMP_SYSTEMID: BoardData.TEMP_SYSTEMID,
                                                    FILE_NAME,
                                                }
                                            });
                                            setFileData({type:'deleteList', index: fileIndex});
                                        }} />
                                    </div>
                                )
                            })}

                            {/* 등록된 파일 */}
                            {BoardData.FILE_LIST &&
                                BoardData.FILE_LIST.map((fileInfo) => {
                                    const { FILE_NAME, FILE_EXTENSION, FILE_IDX, FILE_SIZE } = fileInfo;
                                    
                                    if(DeleteFileIdex.includes(FILE_IDX)) return null;

                                    return (
                                        <div
                                            key={FILE_IDX}
                                            className='file'
                                            onClick={(e) => {
                                                setTempBlobData(prev => ([...prev, {FILE_NAME, FILE_EXTENSION, FILE_SIZE, FILE_IDX, STATE:'Pending', DATA:[]}]));
                                            }}
                                        >
                                            <div title={FILE_NAME}>
                                                <span className='fileName'>{FILE_NAME + FILE_EXTENSION}</span>
                                                <span className='fileSize'>({FormatFileSize(FILE_SIZE)})</span>
                                            </div>

                                            {Mode === BOARD_STATE.EDIT &&
                                                <MdClose onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDeleteFileIdex(prev => ([...prev, FILE_IDX]))
                                                }} />
                                            }
                                        </div>
                                    )
                                })
                            }
                            {FileData.Temp &&
                                Object.entries(FileData.Temp).map(([FILE_IDX, value]) => {
                                    const { name , size: FILE_SIZE } = value;
                                    const [FILE_NAME, FILE_EXTENSION] = name.split('.');
                                    
                                    if(DeleteFileIdex.includes(FILE_IDX)) return null;

                                    return (
                                        <div
                                            key={FILE_IDX}
                                            className='file'
                                            onClick={(e) => {
                                                setTempBlobData(prev => ([...prev, {FILE_NAME, FILE_EXTENSION, FILE_SIZE, FILE_IDX, STATE:'Pending', DATA:[]}]));
                                            }}
                                        >
                                            <div title={FILE_NAME}>
                                                <span className='fileName'>{FILE_NAME + FILE_EXTENSION}</span>
                                                <span className='fileSize'>({FormatFileSize(FILE_SIZE)})</span>
                                            </div>

                                            {Mode === BOARD_STATE.EDIT &&
                                                <MdClose onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDeleteFileIdex(prev => ([...prev, FILE_IDX]))
                                                    setFileData({type:'deleteTemp', index: FILE_IDX});
                                                }} />
                                            }
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>

                <BottomBox marginTop='0' justifyType='space-between'>
                    <div>
                        {Mode === BOARD_STATE.EDIT &&
                            <BtnSubDanger
                                label='Delete'
                                onClick={(e) => {
                                    if (!window.confirm(`해당 게시물을 정말 삭제하시 겠습니까?`)) return;
                                    DeleteBoardPost(pageId, userId, postIndex)
                                        .then((res) => {
                                            const { result, msg } = res.data;
                                            if (result === 'Success') {
                                                window.AddToastMsg('게시물이 삭제되었습니다.');
                                                navigate(-1);
                                            } else {
                                                window.AddAlertMsg({ type: 'error', msg });
                                            }
                                        })
                                }}
                            />
                        }
                    </div>
                    <div>
                        <BtnSecondary 
                            label='Cancel' 
                            onClick={(e) =>{
                                if(Mode !== BOARD_STATE.EDIT){
                                    navigate(-1);
                                    return;
                                }

                                setDeleteFileIdex([]);
                                setMode(BOARD_STATE.READ);
                                setBoardData({type:'readInit', init:BoardData.ORIGIN});

                                Object.entries(FileData.Temp).forEach(([FILE_IDX, value])=>{
                                    const {name: FILE_NAME} = value;
                                    send({
                                        MESSAGE: 'DeleteFileTemp',
                                        DATA: {
                                            ARCHIVE_SYSTEMID: pageId,
                                            MEMBER_SYSTEMID: userId,
                                            TEMP_SYSTEMID: BoardData.TEMP_SYSTEMID,
                                            FILE_NAME,
                                        }
                                    });
                                    setFileData({type:'deleteTemp', index: FILE_IDX});
                                })
                                CloseSock();
                            }} 
                        />

                        {Mode === BOARD_STATE.NEW &&
                            <BtnPrimary
                                label='Create'
                                disabled={BoardData.TITLE === '' || BoardData.CONTENT === ''}
                                onClick={(e) => {
                                    CreateBoardPost(
                                        pageId,
                                        userId,
                                        BoardData.TITLE,
                                        BoardData.CONTENT,
                                        BoardData.IS_OPEN ? 1 : 0,
                                        Number(BoardData.POST_TYPE),
                                        BoardData.TEMP_SYSTEMID,
                                    ).then((res) => {
                                        const { result, msg } = res.data;
                                        if (result === 'Success') {
                                            window.AddToastMsg('게시물이 등록되었습니다.');
                                            navigate(-1);
                                        } else {
                                            window.AddAlertMsg({ type: 'error', msg });
                                        }
                                    })
                                }}
                            />
                        }

                        {(Mode === BOARD_STATE.READ && (BoardData.IS_SELF || !(MyAccess === AUTHORITY_TYPE.NORMAL || MyAccess === AUTHORITY_TYPE.NONE))) &&
                            <BtnGhost label='Edit' 
                                onClick={(e)=>{
                                    let fileSize = 0;
                                    if (BoardData?.FILE_LIST) {
                                        fileSize += BoardData?.FILE_LIST?.reduce((acc, fileInfo) => {
                                            acc += fileInfo.FILE_SIZE
                                            return acc
                                        }, 0);
                                    }

                                    setFileData({type:'setSize', size: fileSize});
                                    setMode(BOARD_STATE.EDIT);
                                }}
                            />
                        }

                        {Mode === BOARD_STATE.EDIT &&
                            <BtnPrimary 
                                label='Edit'
                                onClick={(e) => {
                                    const { POST_IDX, TEMP_SYSTEMID } = BoardData;
                                    
                                    // 변경된 요소 만 처리 하기 위한 로직
                                    const ChangeData = Object.entries(BoardData.ORIGIN).reduce((acc, [key, value]) => {
                                        if (BoardData[key] !== value) {
                                            acc[key] = BoardData[key];
                                        }
                                        return acc;
                                    }, {});
                                    const {TITLE, CONTENT, POST_TYPE, IS_OPEN} = ChangeData;
                                    
                                    UpdateBoardPost(
                                        pageId,
                                        userId,
                                        POST_IDX,
                                        TITLE,
                                        CONTENT,
                                        IS_OPEN,
                                        POST_TYPE,
                                        TEMP_SYSTEMID,
                                        DeleteFileIdex,
                                    ).then(res => {
                                        const { result, msg } = res.data;
                                        if (result === 'Success') {
                                            window.AddToastMsg(msg);
                                            setDeleteFileIdex([]);
                                            InputFile.current.value = '';
                                            setFileData({type:'clear'})
                                            GetBoardPostData();
                                        }
                                    }).catch(err => {
                                        window.AddAlertMsg({ type: 'error', msg: `${err}` });
                                    })
                                }}
                            />
                        }
                    </div>
                </BottomBox>
            </BaseContent>

            {Mode === BOARD_STATE.READ &&
                <BoardReplay
                    MyAccess={MyAccess}
                    BaseInfo={BaseInfo}
                    BoardData={BoardData}
                    ReLoadEvent = {GetBoardPostData}
                />
            }

            {IsFileProcessModal &&
                <UploadTempProgressModal>
                    <div id='progressContent'>
                        <div className='UploadTempList'>
                            <ul>
                                {Object.entries(FileData.Temp).map(([fileIndex, fileInfo], index)=>{
                                    const {name} = fileInfo;
                                    return (
                                        <li key={name} title={name}>
                                            <span className='fileName'>{name}</span>
                                            <span className='progress'>
                                                <progress id={name} className='progressBar' max='100' value='0' />
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <ModalBottomBox>
                            <BtnPrimary
                                label='Ok'
                                onClick={(e) => {
                                    // setFileData({type:'addList'});
                                    setIsFileProcessModal(false);
                                    InputFile.current.value = '';
                                }}
                            />
                        </ModalBottomBox>
                    </div>
                </UploadTempProgressModal>
            }
        </>
    )
}

const BaseContent = styled.div`
    position: relative;
    width: 100%;
    height: 898px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #fff;
    box-sizing: border-box;

    .Row{
        height: 36px;

        &>div{
            min-height: 36px;
            box-sizing: border-box;
        }
    }

    #OptionRow, #TitleRow{
        display: flex;
        gap: 40px;
    }

    .CheckBox{
        display: flex;
        align-items: center;

        &>div{
            width: 36px;
            height: 36px;
            margin: 0 !important;
            padding: 0;

            &>label{
                justify-content: center;
            }
        }
    }

    #postType{
        display: flex;
        align-items: center;
    }

    #BorderTitle{
        flex-grow: 1;

        &>input{
            width: calc(100% - 80px);

            &:disabled{
                background-color: #FFF;
            }
        }
    }

    #SecretNum{
        width: 200px;

        &>input{
            width: calc(100% - 116px);

            &[type="number"]::-webkit-outer-spin-button,
            &[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    #ContentRow{
        display: flex;
        height: calc(100% - 36px);
    }

    #AttachmentRow{
        display: flex;
        align-items: start;
        height: 180px;
        min-height: 180px;

        &>div{
            flex-grow: 1;
            padding: 6px;
            border: 1px solid ${({theme}) => theme.base.border_color_Gray};
            border-radius: 4px;
            height: 100%;
            box-sizing: border-box;

            &.disabled{
                background-color: #FFF;
                border-color: ${({theme}) => theme.base.border_color_LightGray};
            }

            #fileInputBox{
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
                padding-bottom: 8px;
                gap: 8px;

                &>div{
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    &>span{
                        font-size: .9rem;
                        color: ${({theme}) => theme.base.font_color_DarkGray};
                    }
                }

                &>input{
                    display: none;
                }
            }

            .fileList{
                position: relative;
                height: calc(100% - 38px);
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                /* gap: 4px; */

                &.read{
                    height: 100%;
                }
            }

            .file{
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 4px 0;
                gap: 8px;
                height: 28px;
                cursor: pointer;

                &>div{
                    display: flex;
                    align-items: center;
                    height: 100%;
                    gap: 16px;
                    font-size: 1rem;
                    color: ${({theme}) => theme.base.font_color_Black};

                    .fileName{
                        line-height: 24px;
                        max-width: 300px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .fileSize{
                        font-size: .8rem;
                        opacity: .6;
                    }
                }

                svg{
                    cursor: pointer;
                    font-size: 1.2rem;
                    color: ${({theme}) => theme.base.font_color_Red};
                    height: 100%;
                    padding-inline: 8px;
                }
            }
        }
    }
`;

const BorderTextarea = styled(TextareaContent)`
    resize: none;
    width: calc(100% - 80px);

    &:disabled{
        background-color: #FFF;
    }
`;

const BottomBox = styled(ModalBottomBox)`
    margin-left: 80px;
`;

const UploadTempProgressModal = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(43, 43, 43, .3);
    backdrop-filter: blur(5px);

    #progressContent{
        position: absolute;
        top: 48%;
        left: 50%;
        width: 400px;
        height: 360px;
        transform: translate(-50%, -50%);
        padding: 16px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 8px;


        .UploadTempList{
            overflow: auto;
            height: calc(100% - 62px);
            border: 1px solid ${({theme}) => theme.base.border_color_Gray};

            ul{
                position: relative;
                width: 100%;
                height: 100%;

                li{
                    position: relative;
                    display: flex;
                    padding: 8px;
                    box-sizing: border-box;
                    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
                    gap: 8px;

                    .fileName{
                        width: calc(100% - 120px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    
                    &>.progress{
                        width: 120px;

                        &>.progressBar{
                            width: 100%;
                        }

                        &>span{
                            font-size: .85rem;
                            color: ${({theme}) => theme.base.font_color_Red};
                        }
                    }
                }
            }
        }
    }
`;