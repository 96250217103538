import {SUPPORT_FUNCTION} from 'babylon/common/Enu3dEnum'
import {
    ActionManager,
    Color4,
    ExecuteCodeAction,
    TransformNode,    
} from "@babylonjs/core";

export default class EnuScene {
    constructor(scene, canvas) {
        this.isRunTime = false;
        // scene.clearColor = new Color4(0.5, 0.5, 0.5, 0.8);
        // console.log(scene.clearColor)
        this.scene = scene;
        this.objectMap = {};
        this.materialMap = {};
        this.GUIMap = {};
        this.lightMap = {};
        this.cameraMap = {};
        this.tree = [];
        this.canvas = canvas;

        this.root = new TransformNode("Root");
        // Z축이 상하방향이 되도록 World X축 기준 90도 회전
        this.root.rotation.x = -Math.PI / 2;
        this.root.metadata = { objectType: "TransformNode" };

        this.scene.clearColor = new Color4(0.4, 0.4, 0.4, 0.8);
    }

    get scene() { return this._scene; }
    set scene(scene) { this._scene = scene; }

    getEnuScene() { return this; }

    getAllMaps() {
        const objectMap = Object.fromEntries(
            Object.entries(this.objectMap).map(([uniqueId, object]) => [
                uniqueId,
                object.getPropertyMap(),
            ])
        );

        const materialMap = Object.fromEntries(
            Object.entries(this.materialMap).map(([uniqueId, object]) => [
                uniqueId,
                object.getPropertyMap(),
            ])
        );

        const GUIMap = Object.fromEntries(
            Object.entries(this.GUIMap).map(([uniqueId, object]) => [
                uniqueId,
                object.getPropertyMap(),
            ])
        );

        const lightMap = Object.fromEntries(
            Object.entries(this.lightMap).map(([uniqueId, object]) => [
                uniqueId,
                object.getPropertyMap(),
            ])
        );

        const cameraMap = Object.fromEntries(
            Object.entries(this.cameraMap).map(([uniqueId, object]) => [
                uniqueId,
                object.getPropertyMap(),
            ])
        );

        return { objectMap, materialMap, GUIMap, lightMap, cameraMap };
    }
    
    updateTree() {
        this.tree = [];
        function traverseNode(node) {
            const nodeRepresentation = {
                uniqueId: node.uniqueId,
                id: node.id,
                title: node.name,
                type: node.metadata.objectType,
                child: null,
            };

            const children = node.getChildren();
            const validChildren = [];


            for (let child of children) {
                if (!["support"].includes(child.id)) {
                    validChildren.push(traverseNode(child));
                } // Recursive call                
            }

             if (validChildren.length > 0) {
                nodeRepresentation.child = validChildren;
            }
            
            return nodeRepresentation;
        }

        for (let rootNode of this.scene.transformNodes) {
            this.tree.push(traverseNode(rootNode));
        }        
    }
    getTree() { return this.tree }
    getTreeCopy() { 
        const copiedArr = Array.from(this.tree)
        return copiedArr
    }
    getTreeInJSON() { 
        const jsonData = JSON.stringify(this.tree, null, 2); // Convert to pretty JSON format

        const blob = new Blob([jsonData], { type: "application/json" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = 'filetree.json';

        link.click();
        URL.revokeObjectURL(link.href);
    }

    addObjectToMap(key, object) {
        this.objectMap[key] = object;
        this.updateTree();
    }

    addMaterialToMap(key, object) { this.materialMap[key] = object; }

    addGUIToMap(key, object) { this.GUIMap[key] = object; }

    addCameraToMap(key, object) { this.cameraMap[key] = object; }

    addLightToMap(key, object) { this.lightMap[key] = object; }

    addChildToRootNode(object) { object.parent = this.root; }

    getMeshByKey(key) {
        let foundMesh = null;
        this.scene.meshes.forEach((mesh) => {
            if (mesh.uniqueId === key) {
                foundMesh = mesh;
            }
        });
        return foundMesh;
    }

    getMeshById(Id) {
        let foundMesh = null;
        this.scene.meshes.forEach((mesh) => {
            console.log("each", mesh.id);
            if (mesh.id === Id) {
                foundMesh = mesh;
            }
        });
        return foundMesh;
    }

    getObjectByKey(key) { return this.objectMap[key]; }

    getObjectById(targetId) {
        for (const object in this.objectMap) {
            const objectItem = this.objectMap[object];
            if (Object.hasOwn(objectItem, "_id")) {
                if (objectItem.id === targetId) {
                    return objectItem;
                }
            }
        }
        return null;
    }

    getObjectByName(targetName) {
        for (const object in this.objectMap) {
            const objectItem = this.objectMap[object];
            if (Object.hasOwn(objectItem, "_name")) {
                if (objectItem.name === targetName) {
                    return objectItem;
                }
            }
        }
        return null;
    }

    getMaterialByKey(key) { return this.materialMap[key]; }

    getMaterialById(targetId) {
        for (const material in this.materialMap) {
            const materialItem = this.materialMap[material];
            //TODO: Material Class Getter 'id' doesn't work
            if (Object.hasOwn(materialItem, "_id")) {
                if (materialItem.id === targetId) {
                    return materialItem;
                }
            }
        }

        return null;
    }

    getADTinGUIMap() { return this.GUIMap["ADT"]; }

    getGUIMap() { return this.GUIMap; }

    getGUIByKey(key) { return this.GUIMap[key]; }

    getObjectMap() { return this.objectMap; }

    setRunTimeMode(isRunTime) {
        if (typeof isRunTime !== "boolean") {
            throw new Error("isRunTime is not boolean");
        }
        this.isRunTime = isRunTime;
    }

    deleteObjectByKey(key) {
        if (!this.objectMap[key]) {
            throw new Error("objectMap is not defined");
        }
        delete this.objectMap[key];
        this.updateTree();
    }
    addEventToScene(targetObject, eventName, scriptInside, callback) {
        if (!targetObject) {
            throw new Error("targetObject is not defined");
        }

        targetObject.originMesh.actionManager = new ActionManager(this.scene);
        targetObject.addEvent(eventName, scriptInside);

        let babyEvent, windowEventType;
        switch (eventName) {
            case SUPPORT_FUNCTION.ONCLICK:
                babyEvent = ActionManager.OnPickTrigger;
                windowEventType = () =>
                    targetObject.CallOnClick(this, targetObject);
                break;
            case SUPPORT_FUNCTION.ONLOAD:
                // onLoad는 babylon에서 지원하지 않음
                // babylon에 등록하지 않고 프로젝트 로드시 한번 실행하도록 함
                targetObject.CallOnLoad(this, targetObject);
                return;
            case SUPPORT_FUNCTION.ONMOUSEDOWN:
                babyEvent = ActionManager.OnPickDownTrigger;
                windowEventType = () =>
                    targetObject.CallOnMouseDown(this, targetObject);
                break;
            case SUPPORT_FUNCTION.ONTASKVIEW:
                // ontaskview requestAnimationFrame 사용하여 처리
                targetObject.CallOnTaskview(this, targetObject);
                return;
            default:
                throw new Error("eventName is not supported");
        }

        targetObject.originMesh.actionManager.registerAction(
            new ExecuteCodeAction(babyEvent, function () {
                windowEventType();
            })
        );

        callback && callback();
    }

    removedEventFromScene(targetObject, eventName) {
        if (!targetObject) {
            throw new Error("targetObject is not defined");
        }

        const originMesh = targetObject.originMesh;
        if (!originMesh.actionManager) {
            throw new Error("actionManager is not defined");
        }

        targetObject.removedEvent(eventName);

        let babyEvent;
        switch (eventName) {
            case SUPPORT_FUNCTION.ONCLICK:
                babyEvent = ActionManager.OnPickTrigger;
                break;
            case SUPPORT_FUNCTION.ONLOAD:
                // onLoad는 babylon에서 지원하지 않음 Class 에서만 제거
                return;
            case SUPPORT_FUNCTION.ONMOUSEDOWN:
                babyEvent = ActionManager.OnPickDownTrigger;
                break;
            case SUPPORT_FUNCTION.ONTASKVIEW:
                // ontaskview requestAnimationFrame 사용하여 처리
                cancelAnimationFrame(targetObject.onTaskId);
                targetObject.onTaskId = null;
                return;
            default:
                throw new Error("eventName is not supported");
        }

        originMesh.actionManager.actions =
            originMesh.actionManager.actions.filter(
                (action) => action.trigger !== babyEvent
            );
    }
}