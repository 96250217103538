import {useId, useContext} from 'react';
import { TransientModalContext } from "context/TransientModalContext";
import { ToHexString } from 'components/Utility';

import styled from 'styled-components';
import ColorPicker from 'react-best-gradient-color-picker'

export default function ColorChip({attributeName, rgba, isOpacity=true, isGradient=true, setColorValue, changeEvent, changePickerEvent, disabled}){
    const ColorChipId = useId();
    const {TransientModalInfo, setTransientModalInfo} = useContext(TransientModalContext);

    const RenderColorChip = (colorInfo) =>{
        if(colorInfo === 'mixed' || colorInfo === 'Gradient'){
            return <span className='mixed'></span>
        }else if(colorInfo?.includes('gradient')){
            return <span style={{ background: `${colorInfo}`, opacity:1}} />
        }else if(colorInfo?.includes('rgba')){
            const [R, G, B, A] = colorInfo.replace('rgba(', '').replace(')', '').split(',');
            const color = `${ToHexString(R)}${ToHexString(G)}${ToHexString(B)}`;
            const opacity = A;
            return(
                <>
                    <span style={{ background: `#${color}`}} />
                    <span style={{ background: `#${color}`, opacity}} />
                </>
            )
        }
    }

    return(
        <>
            <Content
                isMixed={rgba==='Gradient'}
                disabled={disabled}
                onClick={(e) => {
                    if (disabled || rgba==='Gradient') return;
                    e.stopPropagation();
                    const AlignPos = {
                        Y: window.innerHeight < 380 + e.pageY ? 'bottom' : e.pageY - 20,
                        X: window.innerWidth - e.pageX + 40
                    };
                    setTransientModalInfo({ Id: ColorChipId, AlignPos })
                }}
            >
                {RenderColorChip(rgba)}
            </Content>
            {TransientModalInfo?.Id === ColorChipId && 
                <ColorPickerModal
                    alignPos={TransientModalInfo?.AlignPos} 
                    onClick={(e)=>e.preventDefault()}
                >
                    <div className='ColorPicker' onMouseDown={(e) => e.stopPropagation()} >
                        <ColorPicker
                            value={rgba}
                            hidePresets={true}
                            hideAdvancedSliders={true}
                            hideColorGuide={true}
                            hideOpacity={!isOpacity}
                            hideControls={!isGradient}
                            width={270}
                            height={200}
                            onChange={(e) => {
                                if (rgba?.includes('gradient')) {
                                    if (e.includes('gradient')) {
                                        // console.log('1번--------------------------')
                                        changePickerEvent && changePickerEvent(['changeGradientColor', e]);
                                    } else {
                                        // console.log('2번--------------------------')
                                        changePickerEvent && changePickerEvent(['changeType', 'solid']);
                                    }
                                } else if (rgba?.includes('rgba') || rgba === 'Mixed') {
                                    if (e.includes('gradient')) {
                                        // console.log('3번--------------------------')
                                        changePickerEvent && changePickerEvent(['changeType', 'gradient']);
                                    } else {
                                        // console.log('4번--------------------------')
                                        const [R, G, B, A] = e.substring(e.indexOf('(')+1, e.length-1).split(',');
                                        const JsonData = [
                                            { attributeName : attributeName, attributeValue :`rgb(${R},${G},${B})`},
                                            { attributeName : `${attributeName}-opacity`, attributeValue : A%100}
                                        ];
                                        changeEvent && changeEvent(JsonData);
                                    }
                                }

                                setColorValue && setColorValue(e);
                            }}
                        />
                    </div>
                </ColorPickerModal>
            }
        </>
    )
}

const Content = styled.div`
    position: relative;
    width: 26px;
    height: 18px;
    border-width: ${({isMixed})=> isMixed ? 0 : '1px'};
    border-style: solid;
    border-color: ${({theme}) => theme.base.border_color_LightGray};

    cursor: ${({disabled, isMixed}) => disabled ? 'default' : isMixed ? 'default' : 'pointer'};
    background-image: 
        linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc),
        linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
    background-size: 8px 8px;
    background-position: 0 0, 4px 4px;
    box-sizing: border-box;

    display: flex;

    span{
        flex-grow: 1;
        width: auto;
    }

    .mixed{
        background-color: #fff;
    }
`;

const ColorPickerModal = styled.div`
    position: fixed;
    right: ${props => `${props.alignPos.X}px;` };
    z-index: 9;
    ${props => props.alignPos.Y === 'bottom' ? 'bottom: 10px;' : `top: ${props.alignPos.Y}px;` }

    &>.ColorPicker{
        padding: 8px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
        z-index: 10;
    }
`;