import { useState } from "react";

import useModalPopup from 'hooks/useModalPopup';
import useProjectData from 'hooks/useProjectData';

import styled from "styled-components";
import { useSelector } from 'react-redux';

// 툴바에 버튼 이미지 정보 리스트
const toolBarList = [
    // { toolName : 'select', src : '/img/toolbar/btn_select.png' , isComplet: false },
    { toolName: 'polygon', src: '/img/toolbar/btn_polygon.png' },
    { toolName: 'rect', src: '/img/toolbar/btn_rect.png' },
    { toolName: 'circle', src: '/img/toolbar/btn_circle.png' },
    { toolName: 'ellipse', src: '/img/toolbar/btn_ellipse.png' },
    { toolName: 'line', src: '/img/toolbar/btn_line.png' },
    { toolName: 'polyline', src: '/img/toolbar/btn_Polyline.png' },
    { toolName: 'text', src: '/img/toolbar/btn_text.png' },
    { toolName: 'editBox', src: '/img/toolbar/btn_editBox.png' },
    { toolName: 'image', src: '/img/toolbar/btn_image.png' },
    { toolName: 'table', src: '/img/toolbar/btn_table.png' },
    {
        toolName: 'histogram', src: '/img/toolbar/btn_histogram.png', groupName: 'chart',
        child: [
            { toolName: 'contourRect', src: '/img/toolbar/btn_contourRect.png' },
            { toolName: 'contourCircle', src: '/img/toolbar/btn_contourCircle.png' },
            { toolName: 'histogram', src: '/img/toolbar/btn_histogram.png' },
            { toolName: 'radar', src: '/img/toolbar/btn_radar.png' },
            { toolName: 'lineChart', src: '/img/toolbar/btn_linechat.png' }
        ]
    },
    {
        toolName: '4Point Star', src: '/img/toolbar/btn_4Point Star.png', groupName: 'path',
        child: [
            { toolName: '4Point Star', src: '/img/toolbar/btn_4Point Star.png' },
            { toolName: '5Point Star', src: '/img/toolbar/btn_5Point Star.png' },
            { toolName: '6Point Star', src: '/img/toolbar/btn_6Point Star.png' },
            { toolName: '7Point Star', src: '/img/toolbar/btn_7Point Star.png' },
            { toolName: '8Point Star', src: '/img/toolbar/btn_8Point Star.png' },
            { toolName: '10Point Star', src: '/img/toolbar/btn_10Point Star.png' },
            { toolName: '12Point Star', src: '/img/toolbar/btn_12Point Star.png' },
            { toolName: '16Point Star', src: '/img/toolbar/btn_16Point Star.png' },
            { toolName: '24Point Star', src: '/img/toolbar/btn_24Point Star.png' },
            { toolName: '32Point Star', src: '/img/toolbar/btn_32Point Star.png' },

            { toolName: 'arc', src: '/img/toolbar/btn_arc.png' },
            { toolName: 'Arrow', src: '/img/toolbar/btn_Arrow.png' },
            { toolName: 'Bevel', src: '/img/toolbar/btn_Bevel.png' },
            { toolName: 'Block Arc', src: '/img/toolbar/btn_Block Arc.png' },
            { toolName: 'Can', src: '/img/toolbar/btn_Can.png' },
            { toolName: 'Chevron', src: '/img/toolbar/btn_Chevron.png' },
            { toolName: 'chord', src: '/img/toolbar/btn_chord.png' },
            { toolName: 'Cloud Callouts 1', src: '/img/toolbar/btn_Cloud Callouts 1.png' },
            { toolName: 'Cloud Callouts 2', src: '/img/toolbar/btn_Cloud Callouts 2.png' },

            { toolName: 'Cloud', src: '/img/toolbar/btn_Cloud.png' },
            { toolName: 'Cross', src: '/img/toolbar/btn_Cross.png' },
            { toolName: 'Cube', src: '/img/toolbar/btn_Cube.png' },
            { toolName: 'Decagon', src: '/img/toolbar/btn_Decagon.png' },
            { toolName: 'Diagonal Stripe', src: '/img/toolbar/btn_Diagonal Stripe.png' },
            { toolName: 'Diamond', src: '/img/toolbar/btn_Diamond.png' },
            { toolName: 'Division', src: '/img/toolbar/btn_Division.png' },
            { toolName: 'Dodecagon', src: '/img/toolbar/btn_Dodecagon.png' },
            { toolName: 'Donut', src: '/img/toolbar/btn_Donut.png' },
            { toolName: 'Double Brace', src: '/img/toolbar/btn_Double Brace.png' },

            { toolName: 'Double Bracket', src: '/img/toolbar/btn_Double Bracket.png' },
            { toolName: 'Down Arrow', src: '/img/toolbar/btn_Down Arrow.png' },
            { toolName: 'Explosion 1', src: '/img/toolbar/btn_Explosion 1.png' },
            { toolName: 'Explosion 2', src: '/img/toolbar/btn_Explosion 2.png' },
            { toolName: 'Flowchart::Alternate Process', src: '/img/toolbar/btn_FlowchartAlternate Process.png' },
            { toolName: 'Flowchart::Card', src: '/img/toolbar/btn_FlowchartCard.png' },
            { toolName: 'Flowchart::Collate', src: '/img/toolbar/btn_FlowchartCollate.png' },
            { toolName: 'Flowchart::Connector', src: '/img/toolbar/btn_FlowchartConnector.png' },
            { toolName: 'Flowchart::Data', src: '/img/toolbar/btn_FlowchartData.png' },
            { toolName: 'Flowchart::Delay', src: '/img/toolbar/btn_FlowchartDelay.png' },

            { toolName: 'Flowchart::Dicision', src: '/img/toolbar/btn_FlowchartDicision.png' },
            { toolName: 'Flowchart::Direct Access Storage', src: '/img/toolbar/btn_FlowchartDirect Access Storage.png' },
            { toolName: 'Flowchart::Display', src: '/img/toolbar/btn_FlowchartDisplay.png' },
            { toolName: 'Flowchart::Document', src: '/img/toolbar/btn_FlowchartDocument.png' },
            { toolName: 'Flowchart::Internal Storage', src: '/img/toolbar/btn_FlowchartInternal Storage.png' },
            { toolName: 'Flowchart::Magnetic Disk', src: '/img/toolbar/btn_FlowchartMagnetic Disk.png' },
            { toolName: 'Flowchart::Manual Input', src: '/img/toolbar/btn_FlowchartManual Input.png' },
            { toolName: 'Flowchart::Manual Operation', src: '/img/toolbar/btn_FlowchartManual Operation.png' },
            { toolName: 'Flowchart::Merge', src: '/img/toolbar/btn_FlowchartMerge.png' },
            { toolName: 'Flowchart::Multidocument', src: '/img/toolbar/btn_FlowchartMultidocument.png' },

            { toolName: 'Flowchart::Off Page Connector', src: '/img/toolbar/btn_FlowchartOff Page Connector.png' },
            { toolName: 'Flowchart::Or', src: '/img/toolbar/btn_FlowchartOr.png' },
            { toolName: 'Flowchart::Predefined Process', src: '/img/toolbar/btn_FlowchartPredefined Process.png' },
            { toolName: 'Flowchart::Preparation', src: '/img/toolbar/btn_FlowchartPreparation.png' },
            { toolName: 'Flowchart::Process', src: '/img/toolbar/btn_FlowchartProcess.png' },
            { toolName: 'Flowchart::Punched Tape', src: '/img/toolbar/btn_FlowchartPunched Tape.png' },
            { toolName: 'Flowchart::Sequential Access Storage', src: '/img/toolbar/btn_FlowchartSequential Access Storage.png' },
            { toolName: 'Flowchart::Sort', src: '/img/toolbar/btn_FlowchartSort.png' },
            { toolName: 'Flowchart::Stored Data', src: '/img/toolbar/btn_FlowchartStored Data.png' },
            { toolName: 'Flowchart::Summing Junction', src: '/img/toolbar/btn_FlowchartSumming Junction.png' },

            { toolName: 'Flowchart::Terminator', src: '/img/toolbar/btn_FlowchartTerminator.png' },
            { toolName: 'Folded Corner', src: '/img/toolbar/btn_Folded Corner.png' },
            { toolName: 'Frame', src: '/img/toolbar/btn_Frame.png' },
            { toolName: 'Half Frame', src: '/img/toolbar/btn_Half Frame.png' },
            { toolName: 'Heart', src: '/img/toolbar/btn_Heart.png' },
            { toolName: 'Heptagon', src: '/img/toolbar/btn_Heptagon.png' },
            { toolName: 'Hexagon', src: '/img/toolbar/btn_Hexagon.png' },
            { toolName: 'Isosceles Triangle', src: '/img/toolbar/btn_Isosceles Triangle.png' },
            { toolName: 'Left Arrow', src: '/img/toolbar/btn_Left Arrow.png' },
            { toolName: 'left Brace', src: '/img/toolbar/btn_left Brace.png' },

            { toolName: 'Left Bracket', src: '/img/toolbar/btn_Left Bracket.png' },
            { toolName: 'Left Right Arrow', src: '/img/toolbar/btn_Left Right Arrow.png' },
            { toolName: 'Lightning Bolt', src: '/img/toolbar/btn_Lightning Bolt.png' },
            { toolName: 'L-Shape', src: '/img/toolbar/btn_L-Shape.png' },
            { toolName: 'Minus', src: '/img/toolbar/btn_Minus.png' },
            { toolName: 'Moon', src: '/img/toolbar/btn_Moon.png' },
            { toolName: 'Multiply', src: '/img/toolbar/btn_Multiply.png' },
            { toolName: 'Not Equal', src: '/img/toolbar/btn_Not Equal.png' },
            { toolName: 'Notched Right Arrow', src: '/img/toolbar/btn_Notched Right Arrow.png' },
            { toolName: 'Octagon', src: '/img/toolbar/btn_Octagon.png' },

            { toolName: 'Oval Callouts', src: '/img/toolbar/btn_Oval Callouts.png' },
            { toolName: 'Parallelogram', src: '/img/toolbar/btn_Parallelogram.png' },
            { toolName: 'Pentagon', src: '/img/toolbar/btn_Pentagon.png' },
            { toolName: 'Pie', src: '/img/toolbar/btn_pie.png' },
            { toolName: 'Plaque', src: '/img/toolbar/btn_Plaque.png' },
            { toolName: 'Plus', src: '/img/toolbar/btn_Plus.png' },
            { toolName: 'Rectangular Callouts', src: '/img/toolbar/btn_Rectangular Callouts.png' },
            { toolName: 'Regular Pentagon', src: '/img/toolbar/btn_Regular Pentagon.png' },
            { toolName: 'Right Arrow', src: '/img/toolbar/btn_Right Arrow.png' },
            { toolName: 'Right Brace', src: '/img/toolbar/btn_Right Brace.png' },

            { toolName: 'Right Bracket', src: '/img/toolbar/btn_Right Bracket.png' },
            { toolName: 'Right Triangle', src: '/img/toolbar/btn_Right Triangle.png' },
            { toolName: 'Round Diagonal Corner Rectangle', src: '/img/toolbar/btn_Round Diagonal Corner Rectangle.png' },
            { toolName: 'Round Same Side Corner Rectangle', src: '/img/toolbar/btn_Round Same Side Corner Rectangle.png' },
            { toolName: 'Round Single Corner Rectangle', src: '/img/toolbar/btn_Round Single Corner Rectangle.png' },
            { toolName: 'Rounded Rectangular Callouts', src: '/img/toolbar/btn_Rounded Rectangular Callouts.png' },
            { toolName: 'Smiley Face', src: '/img/toolbar/btn_Smiley Face.png' },
            { toolName: 'Snip and Round Single Corner Rectangle', src: '/img/toolbar/btn_Snip and Round Single Corner Rectangle.png' },
            { toolName: 'Snip Diagonal Corner Rectangle', src: '/img/toolbar/btn_Snip Diagonal Corner Rectangle.png' },
            { toolName: 'Snip Same Side Corner Rectangle', src: '/img/toolbar/btn_Snip Same Side Corner Rectangle.png' },

            { toolName: 'Snip Single Corner Rectangle', src: '/img/toolbar/btn_Snip Single Corner Rectangle.png' },
            { toolName: 'Striped Right Arrow', src: '/img/toolbar/btn_Striped Right Arrow.png' },
            { toolName: 'Sun', src: '/img/toolbar/btn_Sun.png' },
            { toolName: 'Symbol', src: '/img/toolbar/btn_Symbol.png' },
            { toolName: 'Teardrop', src: '/img/toolbar/btn_Teardrop.png' },
            { toolName: 'Trapezoid', src: '/img/toolbar/btn_Trapezoid.png' },
            { toolName: 'Up Arrow', src: '/img/toolbar/btn_Up Arrow.png' },
            { toolName: 'Up Down Arrow', src: '/img/toolbar/btn_Up Down Arrow.png' },
            { toolName: 'Flowchart::Extract', src: '/img/toolbar/btn_FlowchartExtract.png' },
        ]
    },
    { toolName: 'external-link', src: '/img/toolbar/external_link.png' },
]

export default function ToolBarBtnList({ enuSpace, disabled }) {
    const MODE_TYPE = useSelector(state => state.projectData.MODE_TYPE);
    const ACTIVE_DRAW_OBJECT_NAME = useSelector(state => state.projectData.ACTIVE_BTN);
    const ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ACTIVE_PICTURE_URL);
    const { AddModal } = useModalPopup();
    const { ActiveDrawMenu } = useProjectData();

    // 자식메뉴 출력 여부
    const [ShowChildName, setShowChildName] = useState();

    // 자식 메뉴중 최근에 사용한 자식이 toolbox에 출력
    const [childSelect, setChildSelect] = useState({
        'chart': { toolName: 'histogram', src: '/img/toolbar/btn_histogram.png' },
        'path': { toolName: '4Point Star', src: '/img/toolbar/btn_4Point Star.png' }
    });

    const ActiveDraw = (activeName) => {
        enuSpace.EventHandler(ACTIVE_PICTURE_URL, 'CANCEL_DRAWACTIVE', 'cancel', "");

        if (activeName === 'image') {
            AddModal('ImageModal', 'Image', 'ImageModal', { null: null });
        } else {
            if (ACTIVE_DRAW_OBJECT_NAME === activeName) {
                ActiveDrawMenu('');
            } else {
                ActiveDrawMenu(activeName);
                enuSpace.EventHandler(ACTIVE_PICTURE_URL, 'DRAW', activeName, "");
            }
        }
    }

    if (MODE_TYPE)
        return <RunTimeModeText>RunTime Mode</RunTimeModeText>

    return (
        <UlContent>
            {toolBarList.map((tool, toolIndex) => {
                const { toolName, child, groupName, src } = tool;
                const targetSrc = child ? childSelect[groupName].src : src;
                const targetName = child ? childSelect[groupName].toolName : toolName;

                return (
                    <Tool
                        key={toolName}
                        title={targetName}
                        disabled={ACTIVE_PICTURE_URL === undefined}
                        onClick={(e) => ActiveDraw(targetName)}
                    >
                        <span>
                            <img src={targetSrc} alt={targetName} data-active={ACTIVE_DRAW_OBJECT_NAME === targetName} draggable="false" />
                        </span>

                        {child &&
                            <>
                                <BtnExpand
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowChildName(groupName);
                                    }}
                                >
                                    <img src="/img/ExplorerTree/btn_documentOpen.png" alt="/" />
                                </BtnExpand>

                                {/* 자식 메뉴 리스트 */}
                                {ShowChildName === groupName &&
                                    <>
                                        <UlChild group={groupName} onClick={(e) => e.stopPropagation()}>
                                            {
                                                child.map((childInfo, childIndex) => {
                                                    const { toolName: childToolName, src: childSrc } = childInfo;

                                                    return (
                                                        <BtnChildTool
                                                            key={childToolName + childIndex}
                                                            title={childToolName}
                                                            group={groupName}
                                                            onClick={() => {
                                                                const { toolName } = toolBarList[toolIndex].child[childIndex];
                                                                setChildSelect({ ...childSelect, [groupName]: toolBarList[toolIndex].child[childIndex] });
                                                                setShowChildName();
                                                                ActiveDraw(toolName)
                                                            }}
                                                        >
                                                            <span>
                                                                <img src={childSrc} alt={childToolName} draggable="false" />
                                                            </span>
                                                        </BtnChildTool>
                                                    )
                                                })
                                            }
                                        </UlChild>
                                        {/* 자식 메뉴 box 배경 */}
                                        <ChildMenu
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowChildName();
                                            }}
                                        />
                                    </>
                                }
                            </>
                        }
                    </Tool>
                )
            })}
        </UlContent>
    )
}

// 스타일-----------------------------------------------------
const UlContent = styled.ul`
    display: flex;
    align-items: baseline;
    justify-content: center;
    align-items: center;
`;

const Tool = styled.li`
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px;
    cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
    box-sizing: border-box;

    span{
        display: block;
        overflow: hidden;
        width: 24px;
        height: 24px;
        position: relative;
        user-select: none;

        &>img{
            position: absolute;
            top: 0;
            &[data-active="true"]{
                right: 0;
            }
        }
    }

    &:hover >span >img{ 
        right: ${({ disabled }) => disabled ? null : "0"};
    }
`;

//자식 요소 스타일
const UlChild = styled.ul`
    position: fixed;
    top: 76px;
    background-color: #fff;
    z-index: 9;
    display: flex;
    border-radius: 4px;
    padding: 4px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
    flex-wrap: wrap;
    cursor: default;
    // 자식 box 크기 
    width: ${({ group }) => group === 'chart' ? '240px' : '320px'};
`;

const BtnChildTool = styled.li`
    cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
    padding: ${({ group }) => group === 'chart' ? '12px' : '4px'};;

    &:hover >span >img{ 
        right: ${({ disabled }) => disabled ? null : "0"};
    }

    &>span{
        display: block;
        overflow: hidden;
        width: 24px;
        height: 24px;
        position: relative;
        box-sizing: border-box;
        user-select: none;

        &>img{
            position: absolute;
            top: 0;
            &[data-name="true"]{
                right: 0;
            }
        }
    }

    // 개발 완료 여부 체크
    &.ing >span {
        opacity: .4;
        &::after{
            content: '';
            position: absolute;
            display: block;
            top: 50%;
            left: 9px;
            transform: translateY(-50%);
            height: 6px;
            width: 6px;
            background-color: red;
            border-radius: 3px;
        }
    }
`;

// 자식 메뉴 열기 버튼
const BtnExpand = styled.div`
    position: absolute;
    right: 0;
    cursor: pointer;
    overflow: hidden;
    width: 16px;
    height: 16px;
    user-select: none;

    &:hover{
        outline:1px solid ${({ theme }) => theme.base.border_color_Blue};
        background-color: ${({ theme }) => theme.base.background_color_LightBlue};
    }
`;

const ChildMenu = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    cursor: default;
    z-index: 8;
`;

const RunTimeModeText = styled.div`
    font-weight: bold;
    font-size: 1rem;
    color: ${({ theme }) => theme.base.font_color_Black};
`;