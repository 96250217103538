import {useState, useEffect } from "react";

import {displayHighestAuth} from 'components/Utility';
import {BtnPrimary, BtnSecondary, BtnGhost, BtnSubDanger} from "components/atom/Button";
import { ModalBottomBox, Popup, BgPopup, GroupName, InputContent, SelectContent, TextareaContent, WarningMsg, TableContent } from 'components/css/common';
import {GetAccessibleMemberList, GetMemberAuthorization, SetTargetMemberAuthorization, CreateSchedule, UpdateSchedule, AddTag, UpdateTag } from 'api/DeviceApi'
import {CheckUser} from "hooks/useMember";
import CodeEditor from "components/atom/CodeEditor";

import styled from "styled-components"
import { MdClear, MdStar} from "react-icons/md";
import { Rnd } from "react-rnd";

export function DatabaseAuthorization({userInfo, tableId, closeEvent}){
    const [Msg, setMsg] = useState();
    const [AccessibleMembers, SetAccessibleMembers] = useState([]);
    const [Input, setInput] = useState('');

    useEffect(()=>{
        const {userId} = userInfo;
        if(!userId || !tableId)
            throw new Error("User ID and Table ID are required.");

        GetAccessibleMemberList(userId, tableId)
            .then(res => {
                const {RESULT, MSG, DATA} = res.data;
                console.log(res.data)
                RESULT === 'Ok' ? SetAccessibleMembers(DATA) : setMsg(MSG);
            })
    }, [userInfo, tableId]);

    const ChangeMemberAccessible = (e, index) => {
        const Level = e.target.value;
    
        SetAccessibleMembers(prev => {
            return prev.map((item, idx) => {
                if (idx === index) {
                    const updatedItem = {
                        ...item,
                        READ: 0,
                        WRITE: 0,
                        EDIT: 0,
                        MANAGE: 0,
                        MASTER: 0,
                        [Level.toUpperCase()]: 1
                    };
                    return updatedItem;
                }
                return item;
            });
        });
    }

    const SetMemberAuthorization = async() => {
        const {userId, email} = userInfo;
        try {
            const {MSG, DATA, RESULT} = (await GetMemberAuthorization(userId, tableId)).data;
            if(RESULT !== 'Ok'){
                setMsg(MSG);
                return;
            }
            DATA['EMAIL'] = email;
            let newAccessibleMembers = AccessibleMembers;
            newAccessibleMembers.push(DATA);

            SetTargetMemberAuthorization(userId, tableId, newAccessibleMembers)
                .then(res => {
                    const {RESULT, MSG} = res.data;
                    if(RESULT === 'Ok'){
                        closeEvent && closeEvent()
                    }else{
                        setMsg(MSG);
                    }
                })
        } catch (error) {
            setMsg(`SetMemberAuthorization Error: ${error}`);
        }
    }

    return(
        <ModalContent>
            <CustomBgPopup onClick={(e) => closeEvent && closeEvent()} />
            <div className="PopupWrap">
                <div className="inputList">
                    <div id="memberAddBox">
                        <InputContent 
                            value={Input} 
                            placeholder="Member Email"
                            onChange={(e)=>{setMsg(''); setInput(e.target.value)}}
                            onKeyDown={(e) => {
                                if(e.key !== 'Enter') return;
                                if(Input === '') return;

                                const isMember = AccessibleMembers.some(MemberInfo => MemberInfo.EMAIL === Input);
                                if(isMember){
                                    setMsg("이미 추가된 멤버입니다.");
                                    return;
                                }

                                CheckUser(Input)
                                    .then((res) => {
                                        const { result, msg } = res.data;
                                        if (result === 'Success') {
                                            const AddData = {
                                                MEMBER_SYSTEMID: '',
                                                EMAIL: Input,
                                                READ: 1,
                                                WRITE: 0,
                                                EDIT: 0,
                                                MANAGE: 0,
                                                MASTER: 0,
                                                NICKNAME: '',
                                            }
                                            SetAccessibleMembers(Prev => [...Prev, AddData]);
                                            setInput('');
                                        } else
                                            setMsg(msg);
                                    })
                            }}
                        />
                    </div>
                    <AuthorizationTable>
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='ID'>ID</th>
                                        <th className='Access'>Access</th>
                                        <th className='Btn'>Del</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {AccessibleMembers &&
                                        AccessibleMembers.map((item, index) => {
                                            const { EMAIL, MEMBER_SYSTEMID, READ, WRITE, EDIT, MANAGE, MASTER } = item;
                                            const Level = displayHighestAuth({ READ, WRITE, EDIT, MANAGE, MASTER });

                                            // 마스터 사용자는 권한 변경 불가
                                            return (
                                                <tr key={MEMBER_SYSTEMID}>
                                                    <td className='ID'>{EMAIL}</td>
                                                    {Level !== "Master" &&
                                                        <td className='Access'>
                                                            <select value={Level} onChange={(e) => ChangeMemberAccessible(e, index)}>
                                                                <option value="Read">Read</option>
                                                                <option value="Write">Write</option>
                                                                <option value="Edit">Edit</option>
                                                                <option value="Manage">Manage</option>
                                                            </select>
                                                        </td>
                                                    }
                                                    {Level === "Master" && <td className='AccessMaster'>{Level}</td>}
                                                    <td className='Btn'>
                                                        {Level !== "Master" &&
                                                            <BtnSubDanger label={<MdClear />} type='sm'
                                                                onClick={(e) => SetAccessibleMembers(Prev => Prev.filter((item, idx) => idx !== index))}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </AuthorizationTable>
                </div>
                <MsgContent>
                    <WarningMsg>{Msg}</WarningMsg>
                </MsgContent>
                <CustomModalBottomBox>
                    <BtnSecondary label="Cancel" onClick={(e) => closeEvent && closeEvent()} />
                    <BtnPrimary label='Compile' onClick={SetMemberAuthorization} />
                </CustomModalBottomBox>
            </div>
        </ModalContent>
    )
}

export function DatabaseSchedule({mode, scheduleData, userId, tableId, reloadEvent, closeEvent}){
    const [Msg, setMsg] = useState();
    const [ScheduleInfo, setScheduleInfo] = useState({
                                                    SCHEDULE_NAME: '',
                                                    METHOD: 'GET',
                                                    URL: '',
                                                    SCHEDULE_INTERVAL: 1,
                                                    BODY: '',
                                                    PARSING_SCRIPT: '',
                                                    DESCRIPTION: '',
                                                });

    const ChangeInputData = (e) => {
        setMsg('');
        setScheduleInfo({...ScheduleInfo, [e.target.name]: e.target.value});  
    }

    useEffect(()=>{
        if(mode !== 'edit') return;
        setScheduleInfo(scheduleData)
    }, [mode, scheduleData]);

    const ValidateInputsEvent = () => {
        const { SCHEDULE_NAME, URL, SCHEDULE_INTERVAL} = ScheduleInfo;

        if (SCHEDULE_NAME === '') {
            setMsg('Name을 입력해주세요.');
            return;
        } else if (URL === '') {
            setMsg('URL을 입력해주세요.');
            return;
        } else if (SCHEDULE_INTERVAL === '') {
            setMsg('Interval을 입력해주세요.');
            return;
        }
        return true;
    }

    return (
        <ModalContent>
            <CustomBgPopup onClick={(e) => closeEvent && closeEvent()}/>
            <div className="PopupWrap">
                <div className="inputList">
                    <div>
                        <GroupName width={130}>
                            <MdStar className="required" />Name
                        </GroupName>
                        <InputContent 
                            name="SCHEDULE_NAME" 
                            value={ScheduleInfo.SCHEDULE_NAME || ""} 
                            onChange={ChangeInputData} 
                        />
                    </div>

                    <div>
                        <GroupName width={130}>
                            <MdStar className="required" />Method
                        </GroupName>
                        <SelectContent 
                            name="METHOD" 
                            value={ScheduleInfo.METHOD || "GET"}  
                            onChange={ChangeInputData} 
                        >
                            <option value="GET">GET</option>
                            <option value="POST">POST</option>
                        </SelectContent>
                    </div>

                    <div>
                        <GroupName width={130}>
                            <MdStar className="required" />URL
                        </GroupName>
                        <InputContent 
                            name="URL" 
                            value={ScheduleInfo.URL || ""}  
                            onChange={ChangeInputData} 
                        />
                    </div>

                    <div>
                        <GroupName width={130}>
                            <MdStar className="required" />Interval
                        </GroupName>
                        <InputContent 
                            name="SCHEDULE_INTERVAL" 
                            type="number" 
                            min={0} 
                            value={parseInt(ScheduleInfo.SCHEDULE_INTERVAL) || 0}  
                            onChange={ChangeInputData} 
                        />
                    </div>

                    <div className="textareaBox">
                        <GroupName width={130}>
                            Description
                        </GroupName>
                        <TextareaContent 
                            name="DESCRIPTION" 
                            value={ScheduleInfo.DESCRIPTION || ""}
                            onChange={ChangeInputData}
                        />
                    </div>

                </div>
                <MsgContent>
                    <WarningMsg>{Msg}</WarningMsg>
                </MsgContent>
                <CustomModalBottomBox>
                    <BtnSecondary label="Cancel" onClick={(e) => closeEvent && closeEvent()}/>
                    {mode=== 'create' && 
                        <BtnPrimary label="Create" 
                            onClick={(e)=>{
                                if (!ValidateInputsEvent()) return;
                                const { SCHEDULE_NAME, METHOD, URL, SCHEDULE_INTERVAL, BODY, PARSING_SCRIPT, DESCRIPTION } = ScheduleInfo;
                                
                                CreateSchedule(userId, tableId, SCHEDULE_NAME, METHOD, URL, BODY, parseInt(SCHEDULE_INTERVAL), DESCRIPTION, PARSING_SCRIPT)
                                    .then(res => {
                                        const { RESULT, MSG } = res.data;
                                        if (RESULT === 'Ok') {
                                            reloadEvent && reloadEvent();
                                            closeEvent && closeEvent();
                                        } else {
                                            setMsg(MSG);
                                        }
                                    })
                            }} 
                        />
                    }
                    {mode === 'edit' && 
                        <BtnPrimary 
                            label="Edit" 
                            onClick={(e)=>{
                                var msg = ScheduleInfo
                                msg.SCHEDULE_INTERVAL = parseInt(msg.SCHEDULE_INTERVAL)
                                UpdateSchedule(userId, ScheduleInfo.SCHEDULE_ID, tableId, msg)
                                    .then(res => {
                                        const { RESULT, MSG } = res.data;
                                        if (RESULT === 'Ok') {
                                            reloadEvent && reloadEvent();
                                            closeEvent && closeEvent();
                                        } else {
                                            setMsg(MSG);
                                        }
                                    })
                            }}
                        />
                    }
                </CustomModalBottomBox>
            </div>
        </ModalContent>
    )
}

export function DatabaseScheduleScript({userId, tableId, mode, scheduleData, GetScheduleData, closeEvent}){
    // const [Msg, setMsg] = useState();
    const [tempScript, setTempScript] = useState('');
    const schedule = scheduleData;
    const [defStyle, setDefStyle] = useState({
        x: window.innerWidth / 2 - 360, 
        y: window.innerHeight / 2 - 300, 
        width: 500, 
        height: 500, 
    });
    
    useEffect(()=>{
        if(mode === 'script')
        {
            setTempScript(schedule.PARSING_SCRIPT);
        }
        else
        {
            setTempScript(schedule.BODY);
        }
    }, [scheduleData, mode, schedule.BODY, schedule.PARSING_SCRIPT]);

    return(
        <ModalContent>
            <CustomBgPopup onClick={(e) => closeEvent && closeEvent()}/>
            <Rnd 
                className="PopupWrap" 
                bounds="body"
                minWidth={300}
                minHeight={300}
                position={{
                    x: defStyle.x,
                    y: defStyle.y,
                }}
                size={{
                    width: defStyle.width,
                    height: defStyle.height,
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setDefStyle(pevData => ({
                            ...pevData,
                            x: position.x,
                            y: position.y,
                            width: pevData.width + delta.width,
                            height: pevData.height + delta.height,
                        }));
                }}
                onDragStop={(e, d) => {
                    setDefStyle(pevData => ({
                        ...pevData,
                        x: d.x,
                        y: d.y,
                    }));
                }}
            >
                <div id="DbModalBox">
                    <CodeEditor _CodeData={tempScript} _tempScript={setTempScript}/>
                    <MsgContent>
                        {/* <WarningMsg>{Msg}</WarningMsg> */}
                    </MsgContent>
                    <CustomModalBottomBox>
                        <BtnSecondary label="Cancel" onClick={(e) => closeEvent && closeEvent()}/>
                        <BtnPrimary label="Edit" onClick={async(e)=>{
                                var msg = {};
                                if(mode === 'body')
                                {
                                    msg = {
                                        SCHEDULE_NAME:schedule.SCHEDULE_NAME,
                                        METHOD:schedule.METHOD,
                                        URL:schedule.URL,
                                        BODY:tempScript,
                                        SCHEDULE_INTERVAL:schedule.SCHEDULE_INTERVAL,
                                        DESCRIPTION:schedule.DESCRIPTION,
                                        PARSING_SCRIPT:schedule.PARSING_SCRIPT
                                    }
                                }
                                else
                                {
                                    msg = {
                                        SCHEDULE_NAME:schedule.SCHEDULE_NAME,
                                        METHOD:schedule.METHOD,
                                        URL:schedule.URL,
                                        BODY:schedule.BODY,
                                        SCHEDULE_INTERVAL:schedule.SCHEDULE_INTERVAL,
                                        DESCRIPTION:schedule.DESCRIPTION,
                                        PARSING_SCRIPT:tempScript
                                    }
                                }
                                await UpdateSchedule(userId, scheduleData.SCHEDULE_ID, tableId, msg)
                                GetScheduleData();
                                closeEvent();
                            }} />
                    </CustomModalBottomBox>
                </div>
            </Rnd>
        </ModalContent>
    )
}

export function DatabaseTag({mode, tagInfo, userId, tableId, addTagEvent, reloadEvent, closeEvent}){
    const [Msg, setMsg] = useState();
    const [TagInfo, setTagInfo] = useState({
        TAG: '',
        IO_TYPE: 'Input',
        DATA_TYPE: 'int',
        VALUE: '',
        DESCRIPTION: ''
    });

    useEffect(()=>{
        if(mode !== 'edit') return;
        setTagInfo(tagInfo);
    }, [mode, tagInfo]);

    const ChangeInputData = (e) => {
        setMsg('');
        setTagInfo({...TagInfo, [e.target.name]: e.target.value});  
    }

    const ValidateInputsEvent = () => {
        if(TagInfo.TAG === ''){
            setMsg('Tag 명을 입력해주세요.');
            return;
        }
        return true;
    }

    return(
        <ModalContent>
            <CustomBgPopup onClick={(e) => closeEvent && closeEvent()}/>
            <div className="PopupWrap">
                <div className="inputList">
                    <div>
                        <GroupName width={130}>
                            <MdStar className="required" />Tag
                        </GroupName>
                        <InputContent 
                            name="TAG" 
                            value={TagInfo.TAG} 
                            onChange={ChangeInputData} 
                        />
                    </div>
                    <div>
                        <GroupName width={130}>
                            IO TYPE
                        </GroupName>
                        <SelectContent 
                            name="IO_TYPE" 
                            value={TagInfo.IO_TYPE} 
                            onChange={ChangeInputData}
                        >
                            <option value="Input">Input</option>
                            <option value="Output">Output</option>
                        </SelectContent>
                    </div>
                    <div>
                        <GroupName width={130}>
                            Type
                        </GroupName>
                        <SelectContent 
                            name="DATA_TYPE" 
                            value={TagInfo.DATA_TYPE} 
                            onChange={ChangeInputData}
                        >
                            <option value="int">int</option>
                            <option value="double">double</option>
                            <option value="string">string</option>
                        </SelectContent>
                    </div>
                    <div>
                        <GroupName width={130}
                            >Value
                        </GroupName>
                        <InputContent 
                            name="VALUE" 
                            value={TagInfo.VALUE} 
                            onChange={ChangeInputData}
                        />
                    </div>
                    <div className="textareaBox">
                        <GroupName width={130}>
                            Description
                        </GroupName>
                        <TextareaContent 
                            name="DESCRIPTION" 
                            value={TagInfo.DESCRIPTION} 
                            onChange={ChangeInputData}
                        />
                    </div>
                </div>
                <MsgContent>
                    <WarningMsg>{Msg}</WarningMsg>
                </MsgContent>
                <CustomModalBottomBox>
                    <BtnSecondary label="Cancel" onClick={(e) => closeEvent && closeEvent()} />
                    {mode === 'create' &&
                        <BtnPrimary
                            label="Create"
                            onClick={(e) => {
                                if (!ValidateInputsEvent()) return;

                                AddTag(userId, tableId, [TagInfo])
                                    .then(res => {
                                        const { RESULT, MSG } = res;
                                        if (RESULT === 'Ok') {
                                            reloadEvent && reloadEvent();
                                            closeEvent && closeEvent();
                                        } else
                                            setMsg(MSG);
                                    })
                            }}
                        />
                    }

                    {mode === 'edit' &&
                        <BtnPrimary
                            label="Edit"
                            onClick={(e) => {
                                if (!ValidateInputsEvent()) return;

                                UpdateTag(userId, tableId, TagInfo.OriginTag, TagInfo)
                                    .then(res => {
                                        const { RESULT, MSG } = res;
                                        if (RESULT === 'Ok') {
                                            reloadEvent && reloadEvent();
                                            closeEvent && closeEvent();
                                        } else
                                            setMsg(MSG);
                                    })
                            }}
                        />
                    }
                </CustomModalBottomBox>
            </div>
        </ModalContent>
    )
}

export function UploadCSV({ addTagEvent, replaceEvent, closeEvent}){
    const [Msg, setMsg] = useState();
    const [File, setFile] = useState();

    const supportCSVHeaders = [
        { label: "DB Name", key: "DB_TABLE_NAME" },
        { label: "IOType", key: "IO_TYPE" },
        { label: "Tag", key: "TAG" },
        { label: "Type", key: "DATA_TYPE" },
        { label: "Value", key: "VALUE" },
        { label: "Description", key: "DESCRIPTION" },
    ]

    const handleOnSubmit = (e, _type) => {
        e.preventDefault();

        if (File === null) {
            setFile(null);
            return;
        }

        const fileReader = new FileReader();

        if (File) {
            fileReader.onload = function (event) {
                const csvOutput = event.target.result;
                const csvData = csvOutput.replace(/"/g, "").split('\n').map(row => row.split(','))
                const header = csvData[0].map((item) => item.trim());

                const isHeaderValid = supportCSVHeaders.every((supportHeader) => {
                    return header.includes(supportHeader['label']);
                });

                if (isHeaderValid === false) {
                    setMsg("CSV 파일의 헤더가 올바르지 않습니다.");
                    return;
                }

                const result = csvData.slice(1).map(row => {
                    const obj = {};
                    if (supportCSVHeaders.length === row.length) {
                        header.forEach((key, index) => {
                            const KeyName = supportCSVHeaders.filter(item => item['label'] === key)[0]['key'];
                            obj[KeyName] = row[index].trim();
                        });
                        return obj;
                    } else {
                        return null;
                    }
                }).filter(item => item !== null);

                if (_type === 'append') {

                } else if (_type === 'replace') {
                    replaceEvent && replaceEvent();
                }
                addTagEvent && addTagEvent(result);
            };

            fileReader.readAsText(File);
        }
    };

    return(
        <ModalContent>
            <CustomBgPopup onClick={(e) => closeEvent && closeEvent()}/>
            <div className="PopupWrap">
                <div className="InputList">
                    <div>
                        <InputContent 
                            style={{ width: '100%' }} 
                            type="file" 
                            accept=".csv" 
                            onChange={(e)=>{
                                setMsg('');
                                setFile(e.target.files[0]);
                            }}
                        />
                    </div>
                </div>
                <MsgContent>
                    <WarningMsg>{Msg}</WarningMsg>
                </MsgContent>
                <CustomModalBottomBox>
                    <BtnSecondary label="Cancel" onClick={(e) => closeEvent && closeEvent()} />
                    <div>
                        <BtnGhost label="Append Data"  onClick={(e) => handleOnSubmit(e, 'append')} />
                        <BtnGhost label="Replace All" onClick={(e) => handleOnSubmit(e, 'replace')} />
                    </div>
                </CustomModalBottomBox>
            </div>
        </ModalContent>
    )
}

const ModalContent = styled(Popup)`
    position: absolute;
    /* top: 72px; */
    /* height: calc(100% - 72px); */
    z-index: 15;

    .inputList{
        div{
            width: 100%;
            margin-bottom: 16px;
            input, select{
                width: calc(100% - 130px);
            }
        }

        #memberAddBox{
            display: flex;
            gap: 8px;
        }

        .textareaBox{
            display: flex;
            align-items: flex-start;
            margin-bottom: 4px!important;

            textarea{
                width: 100%;
                height: 100px;
                resize: none;
            }
        }
    }

    .PopupWrap{
        #DbModalBox{
            position: relative;
            height: 100%;
            width: 100%;
            cursor: default;

            &>div:first-child{
                height: calc(100% - 64px);
            }
        }
    }
`;


const CustomBgPopup = styled(BgPopup)`
    top: 72px;
    height: calc(100% - 72px);
`;

const AuthorizationTable = styled(TableContent)`
    height: 300px;
    margin-bottom: 4px!important;

    div{
        margin-bottom: 0!important;
    }

    .Access{
        padding: 0;
        width: 100px;
        
        select{
            width: 100% !important;
        }
    }

    .AccessMaster{
        width: 100px;
    }

    .Btn{
        width: 48px;

        &>button{
            padding: 2px 6px;

            svg{
                font-size: 1rem;
            }
        }
    }
`;

const MsgContent = styled.div`
    height: 24px;
`;

const CustomModalBottomBox = styled(ModalBottomBox)`
    justify-content: space-between;
    margin-top: 0!important;
`;