import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    id: undefined
}

const selectNodeDataSlice = createSlice({
    name: 'selectNodeData',
    initialState,
    reducers:{
        setNodeData(state, action){
            return { ...state, ...action.payload };
        },
        addNodeData(state, action){
            return { ...state, ...action.payload };
        },
        changeNodeData(state, action){
            const strValue = action.payload;
            const [key, value] = Object.entries(strValue)[0];
            if(key.includes('gradient.')){
                state['gradient'][key] = value;
            }else{
                state[key] = value;
            }
        },
        deleteSelectNodeData(state, action){
            delete state[action.payload];
        },
        deleteNodeData(state){
            for(let data in state)
                delete state[data];
        }
    }
})

export const {setNodeData, changeNodeData, deleteNodeData, deleteSelectNodeData, addNodeData} = selectNodeDataSlice.actions;

export default selectNodeDataSlice.reducer;