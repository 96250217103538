import Base from "babylon/class/Base";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";
// import { Color4, Vector3 } from "@babylonjs/core";
import { MeshBuilder, VertexData } from "@babylonjs/core";

export default class EnuCylinder extends Base {
    constructor(
        EnuScene,
        name = "cylinder",
        requiresNewMaterial,
        options = {
            height: 5,
            diameterTop: 3,
            diameterBottom: 3,
            updatable: true,
        }
    ) {
        const cylinder = MeshBuilder.CreateCylinder(
            name,
            options,
            EnuScene.scene
        );
        cylinder.rotation.x = Math.PI / 2;
        // cylinder.setPivotPoint(new Vector3(0, -options.height/2, 0));

        cylinder.metadata = {};
        cylinder.metadata.objectType = SUPPORT_OBJECT_TYPE.CYLINDER;

        super(EnuScene, cylinder, requiresNewMaterial);

        this._height = options.height;
        this._diameterTop = options.diameterTop;
        this._diameterBottom = options.diameterBottom;
        this.objectType = SUPPORT_OBJECT_TYPE.CYLINDER;       
        // this._faceColors = options.faceColors || new Color4(1, 1, 1, 1);
    }

    UpdateVertexPoint() {
        const options = {
            height: this.height,
            diameterTop: this.diameterTop,
            diameterBottom: this.diameterBottom,
        };

        const positions = VertexData.CreateCylinder(options).positions;
        

        positions.forEach((element, idx) => {
            if (idx % 3 === 1) {
                positions[idx] += this.height / 2;
            }
        });

        this.originMesh.updateVerticesData("position", positions);
    }

    get height() {return this._height;}
    set height(_height) {
        this._height = _height;
        this.UpdateVertexPoint();
    }

    get diameterTop() { return this._diameterTop; }
    set diameterTop(_diameterTop) {
        this._diameterTop = _diameterTop;
        this.UpdateVertexPoint();
    }

    get diameterBottom() {return this._diameterBottom; }
    set diameterBottom(_diameterBottom) {
        this._diameterBottom = _diameterBottom;
        this.UpdateVertexPoint();
    }

    getPropertyMap() {
        const basePropertyMap = super.getPropertyMap();
        return {
            ...basePropertyMap,
            height: this.height,
            diameter: this.diameter,
            diameterTop: this.diameterTop,
            diameterBottom: this.diameterBottom,
            objectType: this.objectType,
            // faceColors: this.faceColors,
        };
    }
}
