
import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';

function PropertiesContentBtn({_Title, _layoutType, children, _btn}){
    return(
        <Content>
            <Title>
                <span title={_Title}> {_Title} </span>
                {_btn &&  <BtnContent> {_btn} </BtnContent> }
            </Title>
            <ChildrenDiv _layoutType={_layoutType}>
                {children}
            </ChildrenDiv>
        </Content>
    )
}

export default PropertiesContentBtn;

PropertiesContentBtn.propTypes = {
    _Title: PropTypes.string.isRequired,       // 타이틀명(필수)
}

PropertiesContentBtn.defaultProps ={
    _layoutType : "input_2",
}


// 스타일-----------------------------------------------------
const ChildrenDiv = styled.div`
    display: flex;
    flex-direction: column;

    >div{
        display: flex;
        height: 28px;
        align-items: center;
        margin-top: 8px;

        >div:nth-child(n+2){
            margin-left: 8px;
        }
    }
`;

const Content = styled.div`
    position: relative;
    padding: 8px;
    font-size: .9rem;
    color: ${({theme}) => theme.base.font_color_Black};
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
    user-select: none;
`;

const Title = styled.div`
    font-weight: 500;
    padding-left: 8px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;

    > span{
        padding: 4px 0;
        flex-grow: 1;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;

        >span{
            color: ${({theme}) => theme.base.font_color_DarkGray};
            font-size: large;
            padding-left: 8px;
            display: inline-block;
            width: 16px;
            height: 16px;
            overflow: hidden;
        }
    }
`;

const BtnContent = styled.div`
    cursor: pointer;
    :hover{
        background-color: ${({theme}) => theme.base.background_color_LightBlue};
    } 
`;