import { useState, useEffect } from 'react';

import { BtnPrimary, BtnText } from "components/atom/Button";
import { ModalBottomBox, SubTitle } from 'components/css/common';

import styled from 'styled-components';

/**
 * PDF 공유 보드에 사용자 추가를 위해 사용자 리스트를 보여주는 컴포넌트
 * @param {Object} connectUser 현 Runtime에 접속한 사용자리스트 정보
 * {
    "Local": [],
    "World": [],
    "info": [],
    "profileList": {}
}
 * @param {Object} shareUser 공유중인 유저
 * @param {string} myInfo 사용자의 Nickname 
 * @param {Function} cancelCallback 취소 버튼 클릭시 콜백
 * @param {Function} shareCallback 공유 버튼 클릭시 콜백
 * @returns {JSX.Element}
 */
export default function ShareUserList({ connectUser, shareUser, myInfo, cancelCallback, shareCallback }) {
    const [ShareUser, setShareUser] = useState(shareUser);

    useEffect(()=>{
        if(shareUser.length > 0) setShareUser(shareUser);
    }, [shareUser]);
    return (
        <ShareList className="PopupWrap">
            <header>
                <SubTitle>{ShareUser.length} 명</SubTitle>
            </header>
            <ul className="UserList">
                {connectUser?.Local.map((user, idx) => {
                    if (user === myInfo) return null;

                    const [userInfo] = connectUser.info.filter(info => info.NICKNAME === user);

                    const handleCheckboxChange = (isChecked) => {
                        setShareUser((prev) => {
                            const updatedSharedUser = isChecked
                                ? [...prev, userInfo.MEMBER_SYSTEMID]
                                : prev.filter((prevUser) => prevUser !== userInfo.MEMBER_SYSTEMID);
                            return updatedSharedUser;
                        });
                    };

                    return (
                        <li key={`${user}_${idx}`}
                            onClick={(e) => {
                                e.stopPropagation()
                                const checkbox = e.currentTarget.querySelector('input[type="checkbox"]');
                                if (checkbox) checkbox.click();
                            }}
                        >
                            <input type="checkbox"
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => handleCheckboxChange(e.target.checked)}
                                checked={ShareUser.includes(userInfo.MEMBER_SYSTEMID)}
                            />
                            <img src={`${userInfo.PROFILE}`} alt="" onError={(e) => { e.target.src = '/img/basicProfileImg.jpg' }} />
                            <span>{user}</span>
                        </li>
                    )
                })
                }
            </ul>
            <ModalBottomBox>
                <BtnText label="Cancel" onClick={(e) => cancelCallback && cancelCallback()} />
                <BtnPrimary label="Share" onClick={(e) => shareCallback && shareCallback(ShareUser)} />
            </ModalBottomBox>
        </ShareList>
    )
}

const ShareList = styled.div`
    header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .UserList{
        position: relative;
        height: 300px;
        border-block: 1px solid ${({ theme }) => theme.base.border_color_Gray};
        overflow: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        li{
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            padding-block: 8px;
            border-radius: 4px;
            box-sizing: border-box;
            border-style: solid;
            border-width: 1px;
            border-color: transparent;

            &:hover{
                background-color: ${({ theme }) => theme.base.background_color_LightBlue};
                border-color: ${({ theme }) => theme.base.border_color_Blue};
            }

            input{
                cursor: pointer;
            }

            img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            span{
                font-size: 1rem;
            }
        }
    }
`;