import {useState, useEffect} from "react";

import {
    EnuIconFolderOpen,
    EnuIconFolderClose,
    EnuIconFile,
} from "components/common/EnuSpaceIcon"

// import { MdCalendarViewMonth, MdClear, MdCategory } from "react-icons/md";
import PropTypes from 'prop-types';
import styled from "styled-components";

const SUPPORT_TYPE = {
    OBJ: 'obj',
    FOLDER: 'folder',
    NODE: 'TransformNode'
}

function TreeRowContent({id, type, title, path, onClickRow, onDoubleClick, children, openTreeState ,isShowChildren = true}){
    const [isShow, setIsShow] = useState(isShowChildren);

    useEffect(()=>{
        if(!openTreeState) return;
        if(openTreeState[0] === ''&& openTreeState[0] !== title) return;

        if(isShow === false){
            setIsShow(true);
        }
        openTreeState[1]('');

    }, [openTreeState, title, isShow]);

    const isShowTree = (e) => {
        if (e.defaultPrevented) return;

        const typeActions = {
            default: () => setIsShow(!isShow)
        };

        typeActions.default();
    }

    const FrontArrowIcon = () => {
        return (
            <FrontIconContent onClick={isShowTree}>
                {isShow ? <EnuIconFolderOpen /> : <EnuIconFolderClose />}
            </FrontIconContent>
        )
    };

    const FrontIconRender = (type) => {
        switch(type){
            case SUPPORT_TYPE.FOLDER:{
                return FrontArrowIcon()
            }
            case SUPPORT_TYPE.NODE: {
                return FrontArrowIcon()
            }
            default:
                return <FrontIconContent><EnuIconFile/></FrontIconContent>
        }
    }

    return(
        <>
            <TreeRow 
                id={id && id} 
                className={type}
                onClick={(e)=> onClickRow && onClickRow(e)}
                onDoubleClick={(e)=> {
                    isShowTree(e);
                    onDoubleClick && onDoubleClick(e);
                }}
            >
                {FrontIconRender(type)}
                <TitleText title={title}>{title}</TitleText>
            </TreeRow>
            {children && isShow ? children : null}
        </>
    )
}
export default TreeRowContent;

TreeRowContent.propTypes = {
    _title: PropTypes.string,   // TreeRow Title 
    _type : PropTypes.string,   // 데이터 타입 BackIconContent버튼 활성화에 영향을줌
    _path : PropTypes.string,   // canvas 출력시 이용하는 path 정보
}

// 스타일-----------------------------------------------------
const TreeRow = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
    box-sizing: border-box;

    &.ProjectName >input{
        font-weight: 500;
    }

    &:hover, &:focus{
        background-color: ${({theme}) => theme.base.background_color_LightBlue};
        span{ display: block; }
    }
`;

const TitleText = styled.span`
    height: auto;
    padding: 4px 2px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
`;

const FrontIconContent = styled.span`
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding-right: 0;
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    img, svg{
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
    }
`;