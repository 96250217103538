import { Button3D } from "@babylonjs/gui/3D"
import { TextBlock } from "@babylonjs/gui";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";

export default class ENU3DButton {
    constructor(EnuScene, name = "Button3D", text = 'ButtonText') {
        const object = new Button3D(name);
        this._originGUI = object;

        ///
        this._originGUI.onPointerDownObservable.add(function (iuyi) {
            console.log("onPointerDown@@@@@@@@@@@@@@@@@@@@@@@@@@", iuyi);
            
        } )

        const manager = EnuScene.getGUIByKey("GUI3DManager");
        manager.addControl(this.originGUI);

        this._name = object.name;
        this._billboardMode = 0;

        //TODO: Methods to change text, color, font, ... by accessing textblock
        const textContent = new TextBlock();
        textContent.text = text;
        textContent.color = "white";
        textContent.fontSize = 24;

        this.content = textContent;  

        this.objectType = SUPPORT_OBJECT_TYPE.GUI_3DBUTTON;
        EnuScene.addGUIToMap(name, this);
    }

    set name(_name) {
        this._name = _name;
        this._originGUI.name = _name;
    }
    get name() {
        return this._name;
    }

    set originGUI(originGUI) {
        this._originGUI = originGUI;
    }
    get originGUI() { return this._originGUI }
    
    set billboardMode(_billboardMode) {
        this._billboardMode = _billboardMode;
        this._originGUI.billboardMode = _billboardMode;
    }
    get billboardMode() {
        return this._billboardMode;
    }

    set content(_content) {
        this._content = _content;
        this._originGUI.content = _content;
    }
    get content() {
        return this._content;
    }
    
    getPropertyMap() {
        return {
            name: this.name,
            billboardMode: this.billboardMode,
            objectType: this.objectType,
            // visibility
            // linkToMesh
        };
    }
}
