import { Link } from 'react-router-dom';
import styled from "styled-components";

function Footer({theme}){
    return(
        <FooterContent>
            <LinkList>
                <Link to='/'>이엔유 주식회사</Link>
                <Link to='/terms-of-service'>이용약관</Link>
                <Link to='/'
                    onClick={(e)=>{
                        e.preventDefault();
                        window.open(`https://expnuni.github.io/enuspaceMeta_doc/`,
                            'EnuspaceManual',
                            'width=1000,height=660,location=yes,status=no,scrollbars=yes,menubar=yes,titlebar=yes,toolbar=yes');
                    }}
                >Docs</Link>
            </LinkList>

            <div id='EnuInfo'>
                (34013)대전광역시 유성구 테크노4로 17 대덕비즈센터 A606 <br/>
                T_(070) 4244-2268	M_master@enu-tech.co.kr <br/>
                Copyright © Expansion & Universal. All rights reserved.
            </div>
      </FooterContent>
    )
}
export default Footer;

//스타일------------------------
const FooterContent = styled.footer`
    position: relative;
    width: 100%;
    background-color:${({theme}) => theme.base.background_color_Black};
    padding-top: 24px;
    padding-bottom: 32px;
    user-select: none;

    &>div {
        font-size: .9rem;
        color: white;
        opacity: .6;
        white-space: pre-line;
        word-break: break-word;
        line-height: 1.4rem;
        box-sizing: border-box;
        margin: 0 auto;
        width: 100%;

        @media all and (min-width:1024px) {
            max-width: 1200px;
        }
        @media all and (min-width:768px) and (max-width:1023px) {
            padding-left: 16px;
            padding-right: 16px;
        }
        @media all and (max-width:767px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    #EnuInfo{
        z-index: -99;
    }
`;

const LinkList = styled.div`
    font-size: 1rem;
    padding-bottom: 16px;
    display: flex;
    gap: 24px;

    a{
        position: relative;
        color: white;
        text-decoration: none;

        &:hover{
            text-decoration: underline;
        }

        &:not(:first-child){
            &::after{
                content: '|';
                position: absolute;
                top: 0;
                left: -14px;
            }
        } 
    }
`;