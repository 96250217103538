import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import InputComponent from "components/atom/InputComponent";
import { GroupName } from 'components/css/common';
import useModalPopup from "hooks/useModalPopup";
import {BtnSecondary} from "components/atom/Button"

import styled from "styled-components";
import { useSelector } from 'react-redux';
import { MdFormatListBulleted } from "react-icons/md";


export default function ElevationList({_data, _ChangeEvent, _disabled, ...props}){
    const ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ACTIVE_PICTURE_URL);  //활성화 된 picture URL
    const {AddModal} = useModalPopup();
    const {
        id: NodeID,
        min_transparent: NodeMinTransparent,
        min_elevation: NodeMinElevation,
        max_elevation: NodeMaxElevation,
        color_elevation: NodeColorElevation,
        transparent_points: NodeTransparentPoints,
    } = _data;

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title="Elevation">
            <Div>
                <GroupName width={130}>min-transparent</GroupName>
                <BtnToggle 
                    _toggleID="interface" 
                    _isToggleValue={NodeMinTransparent || false} 
                    _disabled={_disabled}
                    _IsToggle={()=>[
                        _ChangeEvent([{ attributeName : `min-transparent`, attributeValue : NodeMinTransparent === '1' ? 'hidden' : 'visible' }])
                    ]} 
                />
            </Div>
            <div>
                <CustomInputComponent label='transparent-points' labelWidth='150px'  value={NodeTransparentPoints} {...commonProps}/>
            </div>
            <div>
                <InputComponent label='min' attributeName='min-elevation' labelWidth='36px' valueType='number' value={NodeMinElevation} {...commonProps}/>
                <InputComponent label='max' attributeName='max-elevation' labelWidth='36px' valueType='number' value={NodeMaxElevation} {...commonProps}/>
            </div>
            <div>
                <GroupName>color</GroupName>
                <CustomBtnSecondary
                    label={<MdFormatListBulleted/>} 
                    type='sm'
                    onClick={(e)=>{
                        AddModal(`ColorListModal_${NodeID}`, 'Color List', 'ColorListModal', {attributeName: 'color-elevation', attributeValue: NodeColorElevation, NodeID, ACTIVE_PICTURE_URL});
                    }}
                />
            </div>
            <div style={{marginTop:'0'}}>
                <InputComponent attributeName='color-elevation' value={NodeColorElevation} {...commonProps}/> 
            </div>
        </PropertiesContent>
    )
}

const Div = styled.div`
    justify-content: space-between;
`;

const CustomInputComponent = styled(InputComponent)`
    label{
        text-align: left;
    }
`;

const CustomBtnSecondary = styled(BtnSecondary)`
    height: 24px;
    width: 24px;
    padding: 0;
`;