import {useState, useEffect, useCallback} from "react";

import LogWindow from "components/atom/LogWindow";
import CanvasTabListContent from "components/molecule/CanvasTabListContent";
import ViewCodeEdit from "components/molecule/ViewCodeEdit";
import CanvasMenu from "components/molecule/CanvasMenu";
import useRightMenu from "hooks/useRightMenu";
import useModalPopup from 'hooks/useModalPopup';
import useProjectData from 'hooks/useProjectData'
import Notification from "components/atom/Notification";

import { AllClearModalPopup } from 'reducers/modulePopupReducer';

import styled from "styled-components";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

function CanvasContent({enuSpace, isLogWindow, isOnlyCanvas}){
    const dispatch = useDispatch()
    const {AddModal} = useModalPopup();
    const {DeleteNodeData, ActiveDrawMenu, SetCanvasView, UpdateCurrentView, SetNodeData } = useProjectData();
    const MODE_TYPE = useSelector(state => state.projectData.MODE_TYPE);
    const CurrentCanvasList = useSelector(state => state.projectData.CANVAS_VIEW);
    const {activeCanvas, currentView: CurrentViewId, canvasMode} =CurrentCanvasList;
    
    const RightMenuInfo = useSelector(state => {
        if (state.rightMenu.type === 'canvas')
            return state.rightMenu;
        return null;
    }, shallowEqual);
    const {ShowRightMenu, CloseRightMenu} = useRightMenu();
    const [codeData, setCodeData] = useState({});
    const CANVAS_NAME = "Canvas";

    useEffect(() => {
        if (enuSpace !== '') {
            enuSpace.SetResize();
        }
        
        if (MODE_TYPE !== false) {
            dispatch(AllClearModalPopup());
        }
    }, [enuSpace, MODE_TYPE, dispatch]);

    useEffect(() => {
        if (enuSpace !== '') {
            enuSpace.SetResize();
        }
    }, [enuSpace, isLogWindow, isOnlyCanvas]);

    const SaveCodeEvent = useCallback((_type ='deleteTab') =>{
        const arrPath = activeCanvas[CurrentViewId].split('\\');
        const fileType = arrPath[0] === 'library' ? arrPath[1] : arrPath[0];
        const currentCodeData = codeData[CurrentViewId];

        enuSpace.AcceptCode(arrPath.join('\\'), fileType, currentCodeData?.edit);

        if (_type !== 'deleteTab') {
            const newCodeData = { ...codeData };
            delete newCodeData[CurrentViewId];
            setCodeData(newCodeData);
            UpdateCurrentView({ canvasId: CurrentViewId, mode: 'design' });
        }else{
            setCodeData({...codeData, [CurrentViewId]:{...currentCodeData, origin:currentCodeData?.edit}})
        }
    }, [enuSpace, codeData, CurrentViewId, activeCanvas, UpdateCurrentView]);

    useEffect(()=>{
        if(CurrentViewId === undefined || canvasMode[CurrentViewId] !== 'design' || codeData[CurrentViewId] === undefined)
            return;
        
        const {origin, edit} = codeData[CurrentViewId];
        if(origin !== edit)
            if(window.confirm('변경된 내용이 있습니다. 저장하시겠습니까?')){
                SaveCodeEvent();
                return;
            }

        enuSpace.SetResize();
        const newCodeData = {...codeData};
        delete newCodeData[CurrentViewId];
        setCodeData(newCodeData);
    },[enuSpace, CurrentViewId, canvasMode, codeData, SaveCodeEvent]);

    useEffect(()=>{
        if(CurrentViewId === '' || CurrentViewId=== undefined || enuSpace === '' || canvasMode[CurrentViewId] === 'code' ) return;

        const drawDataPath = activeCanvas[CurrentViewId];
        const canvasWidth = document.getElementById("canvasSpace").offsetWidth;
        const canvasHeight = document.getElementById("canvasSpace").offsetHeight;
        enuSpace.CreateView(CANVAS_NAME, canvasWidth, canvasHeight);

        //TODO: 왜 drawDataPath가 undefined으로 들어 오는지 확인 필요
        if(drawDataPath === undefined )
            return;

        // library\hmi\file.svg\SD => ['library', 'hmi', 'file.svg', 'SD']
        let ArrayDrawPath = [];
        for(let _path of drawDataPath.split('\\')){
            ArrayDrawPath.push(_path);
        }

        if(ArrayDrawPath[0] === 'library'){
            const symbolURL = `${ArrayDrawPath[0]}\\${ArrayDrawPath[1]}\\${ArrayDrawPath[2]}`;                                      // symbolURL : library\hmi\file.svg
            const symbolName = "#"+ArrayDrawPath[3];                                                                                // symbolName : #SD
            enuSpace.SymbolView(CANVAS_NAME, symbolURL, symbolName, MODE_TYPE);
        }else{
            //일반적인 뷰
            enuSpace.SetView(CANVAS_NAME, drawDataPath, MODE_TYPE ,"Edit");
        }
    },[enuSpace, CurrentViewId, activeCanvas, canvasMode, MODE_TYPE]);
    
    const GetNodeInfo = (e) =>{
        if(RightMenuInfo?.type === 'canvas')
            CloseRightMenu ();
        enuSpace.GetNodeData(CANVAS_NAME);
    };

    const DeleteTab = (e, canvasId) => {
        CloseRightMenu ();
        e.stopPropagation()
        const CodeData = codeData[canvasId];
        if(CodeData !== undefined && CodeData.origin !== CodeData.edit)
            if(window.confirm('변경된 내용이 있습니다. 코드에 반영되었습니다.'))
                SaveCodeEvent('deleteTab');

        const temp = JSON.parse(JSON.stringify(CurrentCanvasList));
        let {activeCanvas: TempActiveCanvas, canvasMode:TempCanvasMode, currentView: TempCurrentView} = temp;
        const Keys = Object.keys(TempActiveCanvas);
        const targetIndex = Keys.indexOf(canvasId);
        
        delete TempActiveCanvas[canvasId];
        delete TempCanvasMode[canvasId];

        if(TempCurrentView === canvasId)
            TempCurrentView = '';

        if(Object.keys(TempActiveCanvas).length === 0 && document.getElementById(CANVAS_NAME) !== null){
            //캔버스가 하나도 없을때 처리
            enuSpace.DeleteView(CANVAS_NAME);
            document.getElementById(CANVAS_NAME).remove();
        }else{
            TempCurrentView = Keys[targetIndex+1] === undefined ? Keys[targetIndex-1] : Keys[targetIndex+1];
        }

        if(CodeData !== undefined){
            const newCodeData = {...codeData};
            delete newCodeData[canvasId];
            setCodeData(newCodeData);
        }

        SetCanvasView({activeCanvas: TempActiveCanvas, canvasMode:TempCanvasMode, currentView: TempCurrentView});
        DeleteNodeData();
    }

    const MouseRightClick = (e) =>{e.preventDefault(); ShowRightMenu({'type':'canvas', 'top':e.pageY, 'left':e.pageX});}
    
    const DrawImage = (e, StringData) =>{
        if(StringData === '' || StringData.indexOf('ImageSrc') === -1 || StringData.indexOf('type') === -1) return;

        const canvasSpace = document.getElementById("canvasSpace");
        const x = e.clientX - canvasSpace.offsetParent.offsetLeft;
        const y = e.clientY - canvasSpace.offsetParent.offsetParent.offsetTop - 40;

        const {ImageSrc, type} = JSON.parse(StringData);
        enuSpace.CreateObject(CANVAS_NAME, type, JSON.stringify(ImageSrc), x, y);
    };

    const ProcessArrayData = (data) => {
        if (data.length === 0 || data === 'cannot find view') return {};
        if (data.length === 1) return data[0];

        const result = {};
        const keys = Object.keys(data[0]);
        keys.forEach(key => {
            if (data?.every(obj => obj.hasOwnProperty(key))) {
                const values = data.map(obj => obj[key]);
                const uniqueValues = [...new Set(values)];
                result[key] = uniqueValues.length === 1 ? uniqueValues[0] : uniqueValues;
            }
        });

        // 다중 선택된 객체 데이터를 의미하는 값
        result['isMultiNode'] = true;
        return result;
    }

    window.Re_GetNodeData = (resData) =>{
        DeleteNodeData();
        const {type, data} = resData;
        const targetData = ProcessArrayData(data);
        const { id, url: ACTIVE_PICTURE_URL } = targetData;
        if (type === 'success') {
            if (MODE_TYPE && targetData.object_name === 'pg-editbox') {
                AddModal('InputBox', `InputBox (${id})`, 'InputBox', { targetData, ACTIVE_PICTURE_URL });
            }
            SetNodeData(targetData);
        }
    }

    window.Re_AcceptCode = (_msg) =>{
        const {type, data} = JSON.parse(_msg);
        if(type === 'success'){
            enuSpace.GetProjectTree();
            window.AddToastMsg("코드에 반영되었습니다.");
        }else{
            window.AddAlertMsg({type:'error', msg:data});
        }
    }

    return(
        <Content>
            <Notification />
            {!isOnlyCanvas && !MODE_TYPE &&
                <CanvasTabListContent _canvasViewList={CurrentCanvasList} _DeleteTab={DeleteTab}/>
            }

            <CanvasBody id="canvasSpace" className={canvasMode[CurrentViewId] === 'code' ? 'hidden' : 'show'}>
                <div id="Canvas_box"
                    onDrop={(e)=>DrawImage(e, e.dataTransfer.getData('text/plain'))}
                    onClick={(e)=>{
                        ActiveDrawMenu();
                        GetNodeInfo(e);
                    }} 
                    onDoubleClick={(e)=>{
                        ActiveDrawMenu(); 
                        GetNodeInfo(e)
                    }} 
                    onContextMenu={MouseRightClick} 
                    onKeyDown={(e)=>{
                        if(e.key === 'Delete'){
                            DeleteNodeData();
                        }else if( e.ctrlKey && e.key === 'a'){
                            e.preventDefault();
                        }else if( e.ctrlKey && e.key.toUpperCase() === 'G'){
                            
                        }
                }}>
                </div>
            </CanvasBody>
            
            {canvasMode[CurrentViewId] === 'code' &&
                <ViewCodeEdit enuSpace={enuSpace} _ViewInfo={CurrentCanvasList} _CodeData={[codeData, setCodeData]} _SaveCode={SaveCodeEvent} _DeleteTab={DeleteTab}/>
            }

            {/* FIXME: logWindow 높이 고정으로 처리 되어있음 flex-grow: 1; 처리시 설정한 높이로 출력이 안됨. */}
            <LogWindow enuSpace={enuSpace} _visibility={isLogWindow}/>

            {(RightMenuInfo?.type === 'canvas') &&
                <CanvasMenu enuSpace={enuSpace} rightMenuInfo={RightMenuInfo}/>
            }
        </Content>
    )
}
export default CanvasContent;


//스타일--------------------------------------
const Content = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.base.background_color_PaleGray};
    width: 0;
`;

const CanvasBody = styled.div`
    position: relative;

    &.hidden{
        display: none;
    }

    &.show{
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    
        &>div{  
            background-color: ${({theme}) => theme.base.background_color_PaleGray}; 
            width: 100%;
            height: 100%;
        }
    }

    canvas:focus-visible{
        outline: none;
    }
`;