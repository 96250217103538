import React, {useState, useEffect, useCallback} from "react";

import ProjectHeader from "components/organism/ProjectHeader";
import NodeInfoContent from "components/organism/NodeInfoContent";
import NavTreeContent from "components/organism/NavTreeContent";
import CanvasContent from "components/organism/CanvasContent";
import ModalSpace from "components/modal_new/ModalSpace";
import SessionExpired from 'components/organism/SessionExpired';
import useRightMenu from "hooks/useRightMenu";
import { TransientModalContext } from "context/TransientModalContext";
import useProjectData from 'hooks/useProjectData';

import { useSelector } from 'react-redux';
import styled from "styled-components";

function ProjectPageContent({enuSpace, ...props}){
    const MODE_TYPE = useSelector(state => state.projectData.MODE_TYPE);
    const {userId, projectId, projectName} = useSelector(state => state.projectData.BASE_DATA);
    const ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ACTIVE_PICTURE_URL);  //활성화 된 picture URL
    const [TransientModalInfo, setTransientModalInfo] = useState(null);
    const [IsOnlyCanvas, setIsOnlyCanvas] = useState(false);
    const {ActiveDrawMenu} = useProjectData();

    const {CloseRightMenu} = useRightMenu();
    const [isLog, setIsLog] = useState(false); //로그창 활성화 여부

    const handleSave = useCallback((url) => {
        if (url === undefined) return;

        let fileType, fileName;
        const ArrayUrl = url.split('\\');   // url을 \ 기준으로 나눔 2개면 picture 4개면 hmi로 판단하여 처리
        if (ArrayUrl.length === 2) {
            [fileType, fileName] = ArrayUrl;
        } else {
            [ , fileType, fileName] = ArrayUrl;
        }

        enuSpace.SaveSvg(userId, projectId, fileType, fileName);
        enuSpace.SaveThumbnail(fileType, url, '.png', '');
        enuSpace.UpdateEnup(userId, projectId, 'enup', `${projectName}.enup`, '');
    }, [enuSpace, userId, projectId, projectName]);

    useEffect(() => {
        const ALignEvent = (type) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, 'ALIGN', type, "");
        const OrderEvent = (type) => enuSpace.EventHandler(ACTIVE_PICTURE_URL, 'ORDER', type, "");

        const handleKeyDown = (e) => {
            const {ctrlKey, altKey, shiftKey, key} = e;
            
            if(!key) return;

            switch (key.toLowerCase()) {
                case 'a':
                    if(altKey){
                        e.preventDefault();
                        ALignEvent('align_left')
                    }
                    break;
                case 'd':
                    if(altKey){
                        e.preventDefault();
                        ALignEvent( 'align_right');
                    }
                    break;
                case 'h':
                    if(shiftKey && altKey){
                        e.preventDefault();
                        ALignEvent( 'align_horizontally');
                    }else if(altKey){
                        e.preventDefault();
                        ALignEvent( 'align_center');
                    }
                    break;
                case 'l':
                    if (ctrlKey){
                        e.preventDefault();
                        setIsLog(prev => !prev);
                    }
                    break;
                case 's':
                    if (ctrlKey){
                        e.preventDefault();
                        handleSave(ACTIVE_PICTURE_URL);
                    }else if(altKey){
                        e.preventDefault();
                        ALignEvent( 'align_bottom');
                    }
                    break;
                case 'v':
                    if(shiftKey && altKey){
                        e.preventDefault();
                        ALignEvent( 'align_vertically');
                    }else if(altKey){
                        e.preventDefault();
                        ALignEvent( 'align_middle');
                    }
                    break;
                case 'w':
                    if(altKey){
                        e.preventDefault();
                        ALignEvent( 'align_top');
                    }
                    break;
                case '\\':
                    if (ctrlKey){
                        e.preventDefault();
                        setIsOnlyCanvas(prev => !prev);
                    }
                    break;
                case '[':
                    if (ctrlKey){
                        e.preventDefault();
                        OrderEvent('sendBackward');
                    }
                    break;
                case ']':
                    if (ctrlKey){
                        e.preventDefault();
                        OrderEvent('bringForward');
                    }
                    break;
                case '{':
                    if (ctrlKey){
                        e.preventDefault();
                        OrderEvent('sendToBack');
                    }
                    break;
                case '}':
                    if (ctrlKey){
                        e.preventDefault();
                        OrderEvent('bringToFront');
                    }
                    break;
                case 'escape':
                    enuSpace.EventHandler(ACTIVE_PICTURE_URL, 'CANCEL_DRAWACTIVE', 'cancel', "");
                    ActiveDrawMenu('');
                    break;
                default:
                    break;
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [enuSpace, ACTIVE_PICTURE_URL, handleSave, ActiveDrawMenu]);

    return(
        <TransientModalContext.Provider value={{TransientModalInfo, setTransientModalInfo}}>
            <Outside 
                onMouseDown={(e)=>{
                    if(TransientModalInfo?.Id)
                        setTransientModalInfo();
                }}
                onClick={CloseRightMenu} 
                {...props}
            >
                <SessionExpired />
                <ModalSpace enuSpace={enuSpace}/>

                {!IsOnlyCanvas && <ProjectHeader enuSpace={enuSpace}/>}
                
                <MainContent isOnlyCanvas={IsOnlyCanvas}>
                    {!IsOnlyCanvas && !MODE_TYPE &&
                        <NavTreeContent enuSpace={enuSpace} _isLogWindow={[isLog, setIsLog]}/>
                    }
                    <CanvasContent enuSpace={enuSpace} isLogWindow={isLog} isOnlyCanvas={IsOnlyCanvas}/>
                    {!IsOnlyCanvas && !MODE_TYPE &&
                        <NodeInfoContent enuSpace={enuSpace}/>
                    }
                </MainContent>
            </Outside>
        </TransientModalContext.Provider>
    );
}

export default ProjectPageContent;

// 스타일------------------------------------
const MainContent = styled.main`
    position: relative;
    display: flex;
    flex-direction: row;
    height: ${({isOnlyCanvas}) => isOnlyCanvas ? '100vh' : `calc(100vh - 76px)`};
`;

const Outside = styled.div`
    user-select: none;
    min-width: 900px;
`;