import { useState } from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import SelectList from "components/atom/SelectList";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import FontFamily from 'components/common/FontFamily';
import {FontSizeIcon} from 'components/common/EnuSpaceIcon';
import { ToggleRect } from 'components/css/common';

import styled from "styled-components";
import { MdFormatItalic } from "react-icons/md";

export default function TrendTitleInputList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        font_family: NodeFontFamily, 
        font_style: NodeFontStyle, 
        font_weight: NodeFontWeight, 
        font_size: NodeFontSize, 
        text: NodeText,
    } = _data;

    const [fontClass] = useState(new FontFamily());

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };
    
    return(
        <PropertiesContent _Title="Title">
            <div>
                <SelectList
                    attributeName="font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <SelectList
                    attributeName="font-weight"
                    dataList={fontClass.GetFontWeight(NodeFontFamily)}
                    selectedValue={NodeFontWeight}
                    {...commonProps}
                />
                <InputComponent
                    label={<FontSizeIcon />}
                    attributeName="font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeFontSize}
                    {...commonProps}
                />
                <ToggleRect 
                    disabled={_disabled}
                    onClick={(e)=>{
                        if (_disabled) return;
                        const attributeValue = Array.isArray(NodeFontStyle) ? 'italic' : ['normal','0'].includes(NodeFontStyle) ? 'italic' : 'normal';
                        _ChangeEvent([{attributeName: 'font-style', attributeValue}]);
                    }}
                >
                    <MdFormatItalic className={Array.isArray(NodeFontStyle) ? '' :  ['normal', '0'].includes(NodeFontStyle) ? '' : 'active'}/>
                </ToggleRect>
            </div>
            <div>
                <ColorComponent label="title" attributeName="font-color" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
            <TextArea style={{ 'height': 'auto' }}>
                <textarea 
                    name="text" 
                    value={NodeText || ''} 
                    onChange={(e) => _ChangeEvent([{ attributeName: 'text', attributeValue: e.target.value }])} 
                />
            </TextArea>
        </PropertiesContent>
    )
}

//스타일-------------------------
const TextArea = styled.div`
    textarea{
        width: 100%;
        height: 80px;
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 2px;
        overflow:auto;
        resize: none;
        line-height:20px;

        &:focus, &:hover {
            border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
        }

        &:defined {
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            outline: none;
        }
    }
`;