import {
    Rectangle,
    TextBlock,
    Ellipse,
    Line,
} from "@babylonjs/gui/2D/controls";
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";
import EnuScene from "./EnuScene";

export default class EnuGUIRectangle {
    constructor(EnuScene, name, targetMesh) {
        const rect1 = new Rectangle(name);

        rect1.width = 0.1;
        rect1.height = "40px";
        rect1.cornerRadius = 20;
        rect1.color = "Orange";
        rect1.thickness = 4;
        rect1.background = "green";

        rect1.linkWithMesh(targetMesh);
        rect1.linkOffsetY = -1500;

        const label = new TextBlock();
        label.text = "Sphere";
        rect1.addControl(label);

        const target = new Ellipse();
        target.width = "40px";
        target.height = "40px";
        target.color = "Orange";
        target.thickness = 4;
        target.background = "green";        
        target.linkWithMesh(targetMesh);

        const line = new Line();
        line.lineWidth = 4;
        line.color = "Orange";
        line.y2 = 5;
        line.linkOffsetY = -20;
        
        line.linkWithMesh(targetMesh);
        line.connectedControl = rect1;

        this._originGUI = rect1;

        this._name = rect1.name;
        this._link = "";
        this.objectType = SUPPORT_OBJECT_TYPE.GUI_RECT;

        const adt = EnuScene.getADTinGUIMap().originADT;
        adt.addControl(rect1);
        adt.addControl(target);
        adt.addControl(line);

        EnuScene.addGUIToMap(rect1.uniqueId, this);
    }

    set name(_name) {
        this._name = _name;
        this._originGUI.name = _name;
    }
    get name() { return this._name; }

    set link(_link) {
        this._link = _link;
        this.originGUI.linkWithMesh(EnuScene.getMeshByKey(_link));
    }
    get link() { return this._link; }

    set originGUI(_originGUI) { this._originMesh = _originGUI; }
    get originGUI() {
        return this._originGUI;
    }

    linkToMesh(mesh) {
        this.originGUI.linkWithMesh(mesh);
        this.originGUI.linkOffsetY = -150;
    }

    getPropertyMap() {
        return {
            name: this.name,
            link: this.link,
            objectType: this.objectType,
        };
    }
}
