import {useState, useEffect, useCallback, useRef } from "react";
import {CheckUseAudio} from 'hooks/useEnterWorld';

import styled from "styled-components";
import { MdOutlineMicNone, MdOutlineMicOff} from "react-icons/md";

export default function AudioContent({enuSpace}) {
    const IntervalRef = useRef(null); 
    const [AudioInfo, setAudioInfo] = useState({
        isUseAudio: false,
        isInterval: false,
        intervalId: null,
    });

    const InitAudio = useCallback((e) => {
        navigator.mediaDevices.getUserMedia({audio: true })
            .then((mediaStream) => {
                const mediaRecorder = new MediaRecorder(mediaStream);
                mediaRecorder.ondataavailable = function (e) {
                    const reader = new FileReader();
                    reader.onload = function () {
                        const base64Data = reader.result.split(',')[1]; // 가져온 데이터에서 base64 부분을 추출합니다.
                        window.Re_Meta_UpdateAudio(base64Data);
                    };
                    reader.readAsDataURL(e.data);
                };
    
                mediaRecorder.start();
                IntervalRef.current = setInterval(() => {
                    mediaRecorder.stop();
                    mediaRecorder.start();
                }, 300);
            })
            .catch(err => {
                console.error("Audio 오류-   ", err)
            })
    }, []);

    const StopAudio = (e) => {
        setAudioInfo({ isUseAudio: false, isInterval: false });
        clearInterval(IntervalRef.current)
    }

    useEffect(()=>{
        if(AudioInfo.isUseAudio && AudioInfo.isInterval){
            InitAudio();
        }

        return () => {
            if(IntervalRef.current !== null) clearInterval(IntervalRef.current);
        }
    }, [enuSpace, AudioInfo, InitAudio]);

    return (
        <AudioBtn>
            <div>
                {AudioInfo.isUseAudio
                    ? <MdOutlineMicNone className="btn btn_Audio" 
                        onClick={StopAudio}
                    />
                    : <MdOutlineMicOff className="btn btn_Audio" 
                        onClick={(e)=>{
                            CheckUseAudio()
                                .then((res) => {
                                    if(res){
                                        setAudioInfo({ isUseAudio: true, isInterval: true });
                                    }
                                }).catch((err) => {
                                    setAudioInfo({ isUseAudio: false, isInterval: false });
                                    alert('사용가능한 Audio가 없습니다.');
                                });
                        }}
                    />
                }
            </div>
        </AudioBtn>
    )
}

const AudioBtn = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &&>div .btn{
        height: 30px;
        width: 30px;
        color: ${({theme}) => theme.base.font_color_DarkGray};
        border: 1px solid ${({theme}) => theme.base.border_color_Gray};
        border-radius: 4px;
        box-sizing: border-box;

        &.btn_Audio{
            padding: 3px;
            &:hover{
                color: ${({theme}) => theme.base.font_color_DarkBlue};
                border-color: ${({theme}) => theme.base.border_color_Blue};
            }
        }
    }
`;