import {useState, useEffect, useRef} from "react";

import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import {VerticalLabelInput} from 'components/molecule/InputFieldLabel';
import CheckTernsOfService from 'components/organism/CheckTernsOfService';
import {isEmail } from 'components/Utility';
import {WarningMsg, ModalBottomBox} from "components/css/common"
import {CreateAccount, CheckEmail, CheckValidationEmail} from 'hooks/useMember';

import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

export default function SignUpPage(){
    const navigate = useNavigate();
    const [isNext, setNext] = useState(1);
    const [Msg, setMsg] = useState();
    // ---------- for focusing Ref
    const inputRef = useRef({EMAIL: null, Code: null, MEMBERNAME:null, PW:null});

    const [VerifyData, setVerifyData] = useState({ EMAIL: '', Code: '', disabled: false });
    const [seconds, setSeconds] = useState(300);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const [isActive, setIsActive] = useState(false);

    const [signUpFormData, setSignUpFormData] = useState({
        EMAIL: '',
        MEMBERNAME: '',
        PW: '',
        confirmPW: '',
        NICKNAME: '',
        GROUP: '',
    });

    useEffect(() => {
        let interval;
        if (isActive) {
            interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(prev => prev - 1);
                } else {
                    clearInterval(interval);
                    setIsActive(false);
                    setNext(2);
                }
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [seconds, isActive]);

    const PwValidatorEvent = () =>{
        if (signUpFormData.PW === '') {
            setMsg({PW:'Password를 입력해주세요. (필수 항목)'});
            return false;
        }else if(signUpFormData.PW.length < 8){
            setMsg({PW:'최소 8자 이상이어야 합니다.'});
            return false;
        }else if(!/[A-Za-z]/.test(signUpFormData.PW)){
            setMsg({PW:'적어도 하나의 알파벳 문자가 포함되어야 합니다.'});
            return false;
        }else if(!/\d/.test(signUpFormData.PW)){
            setMsg({PW:'적어도 하나의 숫자(0-9)가 포함되어야 합니다.'});
            return false;
        }else if(!/[!@#$%^&*]/.test(signUpFormData.PW)){
            setMsg({PW:'적어도 하나의 특수 문자(!@#$%^&*)가 포함되어야 합니다.'});
            return false;
        }

        return true
    }

    const ConfirmPwValidatorEvent = () =>{
        if (signUpFormData.PW !== '' && signUpFormData.confirmPW === '') {
            setMsg({confirmPW:'Confirm Password 입력해주세요. (필수 항목)'});
            return false;
        } else if (signUpFormData.PW !== signUpFormData.confirmPW) {
            setMsg({confirmPW:'Password 일치하지 않습니다.'});
            return false;
        }

        return true
    }

    const isUserInfo = () => {
        if (signUpFormData.MEMBERNAME === '') {
            inputRef.current.MEMBERNAME.focus();
            setMsg({MEMBERNAME:'Name을 입력해주세요. (필수 항목)'});
            return false;
        }

        if(!PwValidatorEvent()){
            inputRef.current.PW.focus();
            return;
        }

        if(!ConfirmPwValidatorEvent()){
            inputRef.current.confirmPW.focus();
            return;
        }

        if (signUpFormData.NICKNAME === '') {
            inputRef.current.NICKNAME.focus();
            setMsg({NICKNAME:'nickname 입력해주세요.'});
            return false;
        }

        return true;
    }

    const checkSubmit = (e) =>{
        e.preventDefault();
        setMsg('');

        if(isUserInfo()){
          CreateAccount(signUpFormData['MEMBERNAME'], signUpFormData['NICKNAME'], signUpFormData['EMAIL'], signUpFormData['PW'])
            .then(res=>{
                const {result, msg} = res.data;
              if(result === 'Success'){
                  navigate('/');
                  alert('회원가입이 완료되었습니다.');
              }else{
                setMsg({common:`회원가입에 실패했습니다. ${msg}`});
              }
            })
            .catch(err=>{
                setMsg({common:`회원가입에 실패했습니다. ${err}`});
            });
        }else
          return;
    }

    switch (isNext) {
        case 1:
            return <CheckTernsOfService setNext={setNext}/>
        case 2:
            return(
                <SignUpSpace>
                    <ContentBox>
                        <div className='title'>Email Check</div>
                        <VerticalLabelInput
                            label='Email'
                            placeholder='ENUMETACORE@enu-tech.co.kr'
                            isRequired={true}
                            inputRef={(el) => (inputRef.current.EMAIL = el)}
                            value={VerifyData.EMAIL}
                            disabled={VerifyData.disabled}
                            onChangeEvent={(EMAIL) => {
                                setMsg("");
                                setVerifyData(prev => ({ ...prev, EMAIL }))
                            }}
                        />
                        
                        <CustomModalBottomBox>
                            <WarningMsg> {Msg?.EMAIL || ''} </WarningMsg>
                            <BtnSecondary 
                                label='Cancel' 
                                disabled={VerifyData.disabled} 
                                onClick={(e)=>{
                                    setNext(1); 
                                    setMsg('');
                                    setVerifyData({ EMAIL: '', Code: '', disabled: false });
                                }}/>
                            <BtnPrimary 
                                label='Next' 
                                disabled={VerifyData.disabled}
                                onClick={(e) => {
                                    setMsg('');
                                    if (VerifyData.EMAIL === '') {
                                        inputRef.current.EMAIL.focus();
                                        setMsg({EMAIL:'Email 입력해주세요.'});
                                        return false;
                                    } else if (!isEmail(VerifyData.EMAIL)) {
                                        inputRef.current.EMAIL.focus();
                                        setMsg({EMAIL:'잘못된 Email 형식입니다.'});
                                        return false;
                                    }
                            
                                    setVerifyData({...VerifyData, disabled: true});
                                    CheckEmail(VerifyData.EMAIL)
                                        .then(res=>{
                                            const {result, msg} = res.data;
                                            if(result === "Success"){
                                                alert(`"${VerifyData.EMAIL}" (으) 로 인증 코드가 전송되었습니다. `);
                                                setSeconds(300);
                                                setIsActive(true);
                                                setNext(3);
                                            }else{
                                                setMsg({EMAIL:msg});
                                            }
                                        }).catch(err=>{
                                            setMsg({EMAIL:`이메일 인증 오류: ${err}`});
                                        }).finally(()=>{
                                            setVerifyData({...VerifyData, disabled: false});
                                        })
                                }} 
                            />
                        </CustomModalBottomBox>
                    </ContentBox>
                </SignUpSpace>
            )
        case 3:
            return(
                <SignUpSpace>
                    <ContentBox>
                        <div className='title'>Email Verify</div>
                        <div id="email">
                            <VerticalLabelInput
                                label='Email'
                                placeholder='ENUMETACORE@enu-tech.co.kr'
                                value={VerifyData.EMAIL}
                                disabled={true}
                            />
                            <p className="timer">{String(minutes).padStart(2, '0')}:{String(remainingSeconds).padStart(2, '0')}</p>
                        </div>
                        <VerticalLabelInput
                            label='Code'
                            isRequired={true}
                            inputRef={(el) => (inputRef.current.Code = el)}
                            value={VerifyData.Code}
                            onChangeEvent={(Code) => {
                                setMsg("");
                                setVerifyData(prev => ({ ...prev, Code }))
                            }}
                        />

                        <CustomModalBottomBox>
                        <WarningMsg> {Msg?.Code || ''} </WarningMsg>
                            <BtnSecondary label={'Cancel'} onClick={(e)=>{
                                setIsActive(false); 
                                setNext(2);
                                setMsg('');
                                setVerifyData({ EMAIL: '', Code: '', disabled: false });
                            }} />
                            <BtnPrimary 
                                label='Next' 
                                onClick={(e) => {
                                    setMsg('');
                                    if(VerifyData.Code === ''){
                                        inputRef.current.Code.focus();
                                        setMsg({Code:'Code를 입력해주세요.'});
                                        return false;
                                    }
                            
                                    CheckValidationEmail( VerifyData.EMAIL, VerifyData.Code)
                                        .then(res=>{
                                            const {result, msg} = res.data;
                                            if(result === "Success"){
                                                setMsg("");
                                                setSignUpFormData({...signUpFormData, EMAIL:VerifyData.EMAIL});
                                                setIsActive(false);
                                                setNext(4);
                                            }else{
                                                setMsg({Code: msg});
                                            }
                                        })
                                        .catch(err=>{
                                            setMsg({Code:`이메일 인증 오류: ${err}`});
                                        });
                                }} />
                        </CustomModalBottomBox>
                    </ContentBox>
                </SignUpSpace>
            )
        case 4:
            return(
                <SignUpSpace>
                    <ContentBox>
                        <div className='title'>Welcome to ENUMETACORE<br />Let’s begin the adventure</div>
                        <VerticalLabelInput
                            label='Email'
                            value={signUpFormData.EMAIL}
                            disabled={true}
                        />
                        <VerticalLabelInput
                            label='Name'
                            isRequired={true}
                            placeholder='이엔유'
                            inputRef={(el) => (inputRef.current.MEMBERNAME = el)}
                            value={signUpFormData.MEMBERNAME}
                            errorMsg={Msg?.MEMBERNAME || ''}
                            onChangeEvent={(MEMBERNAME) => {
                                setMsg("");
                                setSignUpFormData(prev => ({ ...prev, MEMBERNAME }))
                            }}
                        />
                        <VerticalLabelInput
                            label='Password'
                            isRequired={true}
                            inputType='password'
                            placeholder='문자, 숫자 최소 1개 이상사용 (8자리 이상)'
                            inputRef={(el) => (inputRef.current.PW = el)}
                            value={signUpFormData.PW}
                            errorMsg={Msg?.PW || ''}
                            onChangeEvent={(PW) => {
                                setMsg("");
                                setSignUpFormData(prev => ({ ...prev, PW }))
                            }}
                            onBlurEvent={(pw)=>{
                                PwValidatorEvent();
                            }}
                        />
                        <VerticalLabelInput
                            label='Confirm Password'
                            isRequired={true}
                            inputType='password'
                            inputRef={(el) => (inputRef.current.confirmPW = el)}
                            value={signUpFormData.confirmPW}
                            errorMsg={Msg?.confirmPW || ''}
                            onChangeEvent={(confirmPW) => {
                                setMsg("");
                                setSignUpFormData(prev => ({ ...prev, confirmPW }))
                            }}
                        />
                        <VerticalLabelInput
                            label='Nickname'
                            isRequired={true}
                            inputRef={(el) => (inputRef.current.NICKNAME = el)}
                            value={signUpFormData.NICKNAME}
                            errorMsg={Msg?.NICKNAME || ''}
                            onChangeEvent={(NICKNAME) => {
                                setMsg("");
                                setSignUpFormData(prev => ({ ...prev, NICKNAME }))
                            }}
                        />
                        <VerticalLabelInput
                            label='Group'
                            value={signUpFormData.GROUP}
                            onChangeEvent={(GROUP) => {
                                setMsg("");
                                setSignUpFormData(prev => ({ ...prev, GROUP }))
                            }}
                        />
                        <MsgContent >
                            <WarningMsg >{Msg?.common}</WarningMsg>
                        </MsgContent>
            
                        <BtnPrimary label={'Sign Up'} onClick={checkSubmit} />
                    </ContentBox>
                </SignUpSpace>
            )
        default:
            return <CheckTernsOfService setNext={setNext}/>
    }
}

// 스타일 ------------------------------------
const SignUpSpace = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 72px);
`;

const ContentBox = styled.div`
    position: absolute;
    top: 46%;
    left: 50%;
    width: 360px;
    transform: translate(-50%, -50%);
    padding: 16px 24px;
    border-radius: 0.3rem;
    z-index:1;
    background-color: #fff;
    user-select: none;

    #email{
        display: flex;
        position: relative;
        width: 100%;
        gap: 6px;

        &>div{
            flex-grow: 1;
        }
    }

    &>.title{
        font-size: 1.25rem;
        text-align: center;
        color: ${({ theme }) => theme.base.font_color_Black};
        margin-bottom: 16px;
        font-weight: bold;
        line-height: 30px;
    }

    .timer{
        font-size: 1rem;
        color: ${({ theme }) => theme.base.font_color_DarkBlue};
        height: 38px;
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 4px;
        padding: 8px;
        box-sizing: border-box;
        margin-top: 20px;
    }

    &>button{
        display: flex;
        justify-content: center;
        margin-top: 4px;
        width: 100%;
        height: 48px;
    }
`;

const CustomModalBottomBox = styled(ModalBottomBox)`
    margin-top: 0!important;
`;

const MsgContent = styled.div`
    min-height: 24px;
`;