
import PropertiesContent from "components/atom/PropertiesContent";
import InputComponent from "components/atom/InputComponent";

export default function TableRowColInputList({ _data, _ChangeEvent, _disabled }) {
    const commonProps = {
        changeEvent: _ChangeEvent,
        disabled: _disabled,
    };
    const {
        row_count: NodeRowCount, col_count: NodeColCount,
        row_size: NodeRowSize, col_size: NodeColSize,
    } = _data;

    return (
        <PropertiesContent _Title={"Row & Column"}>
            <div>
                <InputComponent label='row-count' attributeName='row-count' valueType='number' labelWidth='24px' value={NodeRowCount} {...commonProps} />
            </div>
            <div>
                <InputComponent label='col-count' attributeName='col-count' valueType='number' labelWidth='24px' value={NodeColCount} {...commonProps} />
            </div>
            <div>
                <InputComponent label='row-size' attributeName='row-size' valueType='text' labelWidth='24px' value={NodeRowSize} {...commonProps} />
            </div>
            <div>
                <InputComponent label='col-size' attributeName='col-size' valueType='text' labelWidth='24px' value={NodeColSize} {...commonProps} />
            </div>

        </PropertiesContent>

    )
}