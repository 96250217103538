import { useReducer} from 'react';
import axios from 'axios';

function reducer(state, action){
    switch(action.type){
        case 'addAudio':{
            const {ptr, url, context, buffer, gainNode, moveTime, volume, interval} = action;
            return {...state, 
                [ptr] : {ptr, url, context, buffer, gainNode, moveTime, volume, interval}
            }
        }
        case 'changeAudio': {
            const { ptr, url, context, buffer, gainNode, moveTime, volume, interval } = action;
            if (state[ptr]) {
                state[ptr].url = url;
                state[ptr].context = context;
                state[ptr].buffer = buffer;
                state[ptr].gainNode = gainNode;
                state[ptr].moveTime = moveTime;
                state[ptr].volume = volume;
                state[ptr].interval = interval;
            }
            return state;
        }
        case 'deleteAudio':{
            const audio = state[action.ptr];
            if(audio){
                delete state[action.ptr]
            }
            return state;
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`); 
    }
}

export default function useAudio(enuSpace){
    const [AudioList, setAudioList] = useReducer(reducer, {});

    window.Re_LoadAudio = async (ptr, url) => {
        const audio = AudioList[ptr];
        if(audio !== undefined && audio.url === url) return;
        axios.get(url, {
            responseType: 'arraybuffer'
          })
          .then(async response => {
            const uint8Array = new Uint8Array(response.data);
            const arrayBuffer = uint8Array.buffer;
            const context = new (window.AudioContext || window.webkitAudioContext)();
    
            try {
                const buffer = await context.decodeAudioData(arrayBuffer);
                context.suspend();
                
                const moveTime = 0;
                const volume = 1;
                const interval = null;
    
                const gainNode = context.createGain();
                gainNode.gain.value = volume;
    
                if(audio === undefined){
                    setAudioList({type:'addAudio', ptr, url, context, buffer, gainNode, moveTime, volume, interval})
                } else {
                    setAudioList({type:'changeAudio', ptr, url, context, buffer, gainNode, moveTime, volume, interval})
                }
                enuSpace.SetAudioDuration(ptr, buffer.duration);
                enuSpace.SetAudioStatus(ptr, 1);
                
            } catch (error) {
                console.error('오디오 디코딩 오류:', error);
            }
            // var buf = Module._malloc(uint8.length*uint8.BYTES_PER_ELEMENT);
            // Module.HEAPU8.set(uint8, buf);
            // enuSpace.SetAudioData(ptr, buf, uint8.length*uint8.BYTES_PER_ELEMENT);
          })
          .catch(error => {
            console.error('Failed to load data. Status:', error.response.status);
          });
    }

    window.Re_PlayAudio = (ptr) => {
        const audio = AudioList[ptr];
        if(audio === undefined) return;
        if(audio.context.state === "running") return;
        if(audio.context.currentTime !== 0 && audio.context.state === "suspended"){
            audio.context.resume();
        }else{
            audio.context.resume();
            const source = audio.context.createBufferSource();
            source.buffer = audio.buffer;
            audio.gainNode.gain.value = audio.volume;
            source.connect(audio.gainNode);
            audio.gainNode.connect(audio.context.destination);
            source.start(0, audio.moveTime);
        }
        enuSpace.SetAudioCurrentTime(audio.ptr, audio.context.currentTime + audio.moveTime);
        audio.interval = setInterval(() => {
            const playTime = audio.context.currentTime + audio.moveTime;
            if(playTime > audio.buffer.duration){
                clearInterval(audio.interval);
                enuSpace.SetAudioCurrentTime(audio.ptr, 0);
                enuSpace.SetAudioStatus(ptr, 1);
                audio.moveTime = 0;
                audio.context.close();
                audio.context = new (window.AudioContext || window.webkitAudioContext)();
                audio.context.suspend();
                audio.gainNode = audio.context.createGain();
                audio.gainNode.gain.value = audio.volume;
            } else{
                enuSpace.SetAudioCurrentTime(audio.ptr, playTime);
            }
        }, 200);
    }

    window.Re_PauseAudio = (ptr) => {
        const audio = AudioList[ptr];
        if(audio === undefined) return;
        audio.context.suspend();
        clearInterval(audio.interval)
    }

    window.Re_StopAudio = (ptr) => {
        const audio = AudioList[ptr];
        if(audio === undefined) return;
        audio.moveTime = 0;
        audio.context.close();
        audio.context = new (window.AudioContext || window.webkitAudioContext)();
        audio.gainNode = audio.context.createGain();
        audio.gainNode.gain.value = audio.volume;

        audio.context.suspend();
        clearInterval(audio.interval)
        enuSpace.SetAudioCurrentTime(audio.ptr, 0);
    }

    window.Re_ControlAudio = (ptr, ratio) => {
        const audio = AudioList[ptr];
        if(audio === undefined) return;
        const state = audio.context.state;
        const moveTime = audio.buffer.duration * ratio;

        audio.moveTime = moveTime;
        audio.context.close();
        audio.context = new (window.AudioContext || window.webkitAudioContext)();
        audio.gainNode = audio.context.createGain();
        audio.gainNode.gain.value = audio.volume;

        const source = audio.context.createBufferSource();
        source.buffer = audio.buffer;
        source.connect(audio.gainNode);
        audio.gainNode.connect(audio.context.destination);

        if(state === "running"){
            source.start(0, moveTime);
            enuSpace.SetAudioCurrentTime(audio.ptr, moveTime);
        }else if(state === "suspended"){
            audio.context.suspend();
            enuSpace.SetAudioCurrentTime(audio.ptr, moveTime);
        }
    }

    window.Re_ControlVolume = (ptr, volume) => {
        const audio = AudioList[ptr];
        if(audio === undefined) return;
        audio.volume = volume;
        audio.gainNode.gain.value = audio.volume;
    }

    window.Re_DeleteAudio = (ptr) => {
        const audio = AudioList[ptr];
        if(audio === undefined) return;
        audio.context.close();
        clearInterval(audio.interval);
        setAudioList({type:'deleteAudio', ptr})
    }
}