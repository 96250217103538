import { useState, useEffect } from "react";
import useModalPopup from 'hooks/useModalPopup';
import {refreshAccessToken} from 'hooks/useMember';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function useWindowFun(enuSpace, _setIsLoadingPage){
    const {AddModal, UpdateModalData} = useModalPopup();
    const location = useLocation()
    const currentURL = location.pathname;
    const [cursorType, setCursorType] = useState('default');
    const ModalList = useSelector(state => state.modalPopup);

    const BASE_DATA = useSelector(state => state.projectData.BASE_DATA);

    useEffect(() => {
        return () => {
            localStorage.removeItem('projectId');
            localStorage.removeItem('publishId');

            if(window.g_enuSpace !== undefined){
                window.g_enuSpace.Finalization();
                window.g_enuSpace.CancelLoop();
            }
        };
    }, []);

    const SetGlobalValues = (_msg) => enuSpace.SetGlobalValues(_msg);

    const RefreshAccessToken = () => {
        refreshAccessToken()
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                if (typeof window.SessionLoginPopup === 'function') {
                    console.log("3번 ---@@@@@@")
                    window.SessionLoginPopup();
                }
            });
    }

    const ShowLink = (_path) => window.open(_path, 'DocumentViewer', `width=700,height=900,location=no,status=no,scrollbars=yes,menubar=no`);

    const EditModeShowMarkdown = (_path) => {
        localStorage.setItem('projectId', BASE_DATA.projectId);

        window.open(
            `https://${window.location.host}/MyRepository/DocumentViewer?path=${_path.replace(/\//g, '\\')}&extension=md&mode=read`, 
            'DocumentViewer', 
            `width=700,height=900,location=no,status=no,scrollbars=yes,menubar=no`);
    }

    const ShowDocumentModal = (path, pageState) =>{
        const arrPath = path.split('/');
        const extension = arrPath[arrPath.length-1].split('.').pop();
        const baseData = {userId: BASE_DATA.userId,projectId: BASE_DATA.projectId, currentURL};

        if(extension === 'pdf'){    //pdf 파일일 경우
            const findModalIndex = ModalList.findIndex(item => item.id === path);
              
            //모달이 없을 경우
            if(findModalIndex === -1){
                let PageNum = pageState;
                if (isNaN(Number(pageState))) {
                    PageNum = ['PREV', 'NEXT'].includes(pageState.toUpperCase()) ? 1 : pageState;
                }
                AddModal(path, arrPath[arrPath.length-1], 'PdfModal', {path, arrPath, pageState: PageNum, baseData});
            }else{
                const TargetModal = ModalList[findModalIndex];
                let PageNum = TargetModal.data.pageState;

                if (isNaN(Number(pageState))) {
                    if (pageState.toUpperCase() === 'PREV') {
                        PageNum -= 1;
                    } else if (pageState.toUpperCase() === 'NEXT') {
                        PageNum += 1;
                    }
                } else {
                    PageNum = pageState
                }
                UpdateModalData(TargetModal.id, {pageState: PageNum})
            }
        }else{
            window.AddAlertMsg({type:'error', msg:'지원하지 않은 파일 형식입니다.'});
        }
    }

    const RuntimeModeShowMarkdown = (_path) => {
        const RequestURL = currentURL.includes('Publish') ? 'Publish' : 'MyRepository';

        if(RequestURL === 'MyRepository'){
            localStorage.setItem('projectId', BASE_DATA.projectId);
            window.open(`https://${window.location.host}/${RequestURL}/DocumentViewer?path=${_path.replace(/\//g, '\\')}&mode=read`, 'DocumentViewer', `width=700,height=900,location=no,status=no,scrollbars=yes,menubar=no`);
        }else{
            const pathInfo = _path.replace(/\//g, '\\');
            const arrayPath = pathInfo.split('\\');
            AddModal(pathInfo, arrayPath[arrayPath.length-1], 'ShowDocument', {projectId: BASE_DATA.projectId, pathInfo, arrayPath});
        }
    }

    const LinkModeShowMarkdown = (_path) => {
        const pathInfo = _path.replace(/\//g, '\\');
        const arrayPath = pathInfo.split('\\');
        AddModal(pathInfo, arrayPath[arrayPath.length-1], 'ShowDocument', {projectId: BASE_DATA.projectId, pathInfo, arrayPath});
    }

    const GetNodeData = (resData) =>{
        if(resData['type'] === 'success'){
            const targetData = resData['data'][0];
            if(targetData['object_name'] === 'pg-editbox' ){
                AddModal('InputBox', `InputBox (${targetData['id']})`, 'InputBox', {targetData, ACTIVE_PICTURE_URL : targetData['url']});
            }
        }
    }

    //TODO: 임시
    window.Re_WheelScale = (_scaleValue) => { };
    // window.Re_GetNodeData = (nodeId) => { }  TODO: 240625 확인 필요
    window.Re_AddLog = (_logMsg) => {}

    window.Re_ShowWebpage = (_path) =>{
        AddModal(_path, _path, 'ShowWebpage', {url: _path});
    }

    window.Re_ShowWebpagePopup = (_path) =>{
        window.open(`${_path}`, "_blank", "popup=yes");
    }

    window.Re_ShowTextarea = (text, title) =>{
        const Title = title === '' ? 'Textarea' : title;
        AddModal('Textarea', Title, 'Textarea', {text});
    }

    window.SetClipboard = async (data) => {
        try {
            const jsonText = JSON.stringify({ inApp: true, type: 'text', data });
            await navigator.clipboard.writeText(jsonText);
        } catch (err) {
            throw new Error(`Error copying text to clipboard: ${err}`);
        }
    }

    window.GetClipboard = async () => {
        try {
            const clipboardItems = await navigator.clipboard.read();

            for (const clipboardItem of clipboardItems) {
                const imageTypes = clipboardItem.types.filter(type => type.startsWith('image/'))
                if (imageTypes.length !== 0) {
                    for (const imageType of imageTypes) {
                        const blob = await clipboardItem.getType(imageType);
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = function () {
                            enuSpace.Callback_GetClipboard(JSON.stringify({ inApp: false, type: 'image', data: reader.result }));
                        }
                    }
                }
                else if (clipboardItem.types.includes('text/plain')) {
                    // string
                    const text = await clipboardItem.getType('text/plain');
                    const loadedText = await text.text();
                    // inApp check
                    if (loadedText.includes('inApp') === true)
                        enuSpace.Callback_GetClipboard(loadedText);
                    else
                        enuSpace.Callback_GetClipboard(JSON.stringify({ inApp: false, type: 'text', data: loadedText }));
                }
            }
        } catch (err) {
            throw err;
        }
    };

    window.Re_ShowLoadingIcon = () => {_setIsLoadingPage(true); }
    window.Re_OffLoadingIcon = () => {_setIsLoadingPage(false); }
    
    return {
        SetGlobalValues, 
        RefreshAccessToken, 
        ShowLink, 
        EditModeShowMarkdown, 
        ShowDocumentModal,
        RuntimeModeShowMarkdown, 
        LinkModeShowMarkdown, 
        GetNodeData, 
        cursorType}
}

