
import { setBabylonData, deleteBabylonData } from "reducers/selectBabylonDataReducer";

import { useDispatch } from 'react-redux';

export default function useDataController() {
    const dispatch = useDispatch();

    const ClearData = () => {
        dispatch(deleteBabylonData());
    }

    const UploadData = (data) => {
        dispatch(setBabylonData(data));
    }

    const UploadDataByMesh = (Mesh) => {
        const data = Mesh.getPropertyMap();
        dispatch(setBabylonData(data));
    }

    return { 
        ClearData,
        UploadData,
        UploadDataByMesh
    };
}