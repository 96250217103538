import { useState, useEffect, useCallback } from 'react';

import { GroupName } from 'components/css/common';
import { scaleOpacityToPercentage} from 'components/Utility';

import styled from 'styled-components';
import ColorPicker from 'react-best-gradient-color-picker'

const PICKER_HEIGHT = 376;
export default function ColorPickerContent({ Id, label, rgbaArray, changeEvent, disabled, labelWidth = 56, isOpacity = true}) {
    const [isModal, setIsModal] = useState({ visibility: false});

    const [ColorValue, setColorValue] = useState('rgba(0,0,0,1)');
    const [ArrayColorData, setArrayColorData] = useState({ fill: '000', opacity: '1' });

    const hexToColor = (hex) => parseInt(hex, 16) / 255;
    const ColorTo255 = (color) => Math.round(color * 255);
    
    const RgbToHex = useCallback((R, G, B) =>{
        const r = ColorTo255(R).toString(16).padStart(2, "0");
        const g = ColorTo255(G).toString(16).padStart(2, "0");
        const b = ColorTo255(B).toString(16).padStart(2, "0");
        return `${r}${g}${b}`;
    }, []);
    
    useEffect(()=>{
        if(rgbaArray === undefined) return;

        const [R, G, B, A] = rgbaArray;
        setArrayColorData({ fill: RgbToHex(R,G,B), opacity: String(A)})
        setColorValue(`rgba(${ColorTo255(R)},${ColorTo255(G)},${ColorTo255(B)},${A})`);
    }, [rgbaArray, RgbToHex]);

    const KeyDownEvent = (e) => {
        if (e.key === 'Enter')
            e.target.blur();
        else if (e.ctrlKey && e.key === 'a') {
            e.preventDefault();
            e.target.select();
        }
    };

    const ShowColorPicker = (e) => {
        if (disabled) return;

        const {pageX, pageY} = e;
        const {innerWidth, innerHeight} = window;

        const AlignPos = {
            Y: innerHeight < PICKER_HEIGHT + pageY ? 'bottom' : pageY - 20,
            X: innerWidth - pageX + 40
        };
        setIsModal({ visibility: true, AlignPos });
    }

    const ChangeInputValue = (e, _targetKey = 'fill') => {
        let targetValue = e.target.value;
    
        if (_targetKey === 'fill') {
            const pattern = /[^0-9a-fA-F]/;
            targetValue = targetValue.replace(pattern, '');
        } else if (_targetKey === 'opacity') {
            targetValue = scaleOpacityToPercentage(targetValue) / 100;
        }

        setArrayColorData(prevData => ({ ...prevData, [_targetKey]: targetValue }));
    }

    const BlurEvent = (e) => ChangeValue(e);

    const ChangeValue = (e) =>{
        const {fill, opacity} = ArrayColorData;

        let fillValue = fill
        if (fillValue.length === 3) {
            fillValue = fillValue.split('').map(char => char + char).join('');
        }

        if (fillValue.length !== 6) {
            const [R, G, B, A] = rgbaArray;
            setArrayColorData({ fill: RgbToHex(R,G,B), opacity: String(A)})
            return;   
        }

        const R = hexToColor(fillValue.substring(0,2))
        const G = hexToColor(fillValue.substring(2,4))
        const B = hexToColor(fillValue.substring(4,6))

        changeEvent && changeEvent(Id, [R, G, B, Number(opacity)]);
    }

    const ChangeColorPicker = (e) =>{
        const [R, G, B, A] = e.replace('rgba(', '').replace(')', '').split(',');
        changeEvent && changeEvent(Id, [R / 255, G / 255, B / 255, A]);
    }

    return (
        <>
            <Content>
                <div>
                    <ColorChip onClick={ShowColorPicker} disabled={disabled}>
                        <span style={{ background: `#${ArrayColorData.fill}` }} />
                        <span style={{ background: `#${ArrayColorData.fill}`, opacity: ArrayColorData.opacity ?? 1 }} />
                    </ColorChip>
                    <GroupName width={labelWidth} htmlFor={Id}>{label}</GroupName>

                    <ColorInputBox
                        id={Id} 
                        name={Id} 
                        title={Id} 
                        type="text" 
                        value={ArrayColorData.fill} maxLength={6} 
                        disabled={disabled}
                        onChange={(e) => ChangeInputValue(e)} 
                        onKeyDown={KeyDownEvent}
                        onBlur={BlurEvent} />

                    {isOpacity &&
                        <OpacityInputBox
                            id={`${Id}-opacity`}
                            type="text" 
                            value={(ArrayColorData.opacity ?? 1) * 100} 
                            min="0" max="100"
                            disabled={disabled} 
                            onChange={(e) => {ChangeInputValue(e, 'opacity')}} 
                            onKeyDown={KeyDownEvent}
                            onBlur={BlurEvent} 
                        />
                    }
                </div>
            </Content>

            {isModal.visibility &&
                <ColorPickerModalContent
                    alignPos={isModal.AlignPos}
                    onClick={(e) => e.preventDefault()}
                >
                    <BgColorPicker onClick={(e) => setIsModal({ visibility: false})} />
                    <div className='ColorPicker'>
                        <ColorPicker
                            value={ColorValue}
                            onChange={ChangeColorPicker}
                            hidePresets={true}
                            hideAdvancedSliders={true}
                            hideColorGuide={true}
                            hideOpacity={!isOpacity}
                            hideControls={true}
                            width={270}
                            height={200}
                        />
                    </div>
                </ColorPickerModalContent>
            }
        </>
    )
}

// 스타일--------------------------------------------------------------
const Content = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &>div {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
    }

    .GradientType{
        font-weight: 500;
    }
`;

const ColorChip = styled.div`
    display: flex;
    width: 26px;
    height: 16px;
    border-radius: 2px;
    margin-left: 8px;
    border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
    border-radius: 2px;
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    background-image: 
        linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc),
        linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
    background-size: 8px 8px;
    background-position: 0 0, 4px 4px;
    
    &>span{
        width: 50%;
    }

    &>.GradientChip{
        width: 100%;
    }
`;

const ColorInputBox = styled.input`
    font-size: .9rem;
    height: 28px;
    box-sizing: border-box;
    border: 0 none;
    width: 56px;
    flex-grow: 1;
    border-bottom: 1px solid ${({ theme }) => theme.base.border_color_LightGray};

    &:hover{
        border-color: ${({ theme }) => theme.base.border_color_Blue};
    }
    &:focus{
        outline:none;
        border-bottom: 1px solid ${({ theme }) => theme.base.border_color_Blue};
    }
    &:selection{
        background-color: ${({ theme }) => theme.base.background_color_lightBlue};
        border: 0 none;
        border-bottom: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
    }
    &:disabled{
        border-color: transparent;
    }
`;

const ColorPickerModalContent = styled.div`
    position: fixed;
    right: ${props => `${props.alignPos.X}px;`};
    z-index: 9;
    ${props => props.alignPos.Y === 'bottom' ? 'bottom: 10px;' : `top: ${props.alignPos.Y}px;`}

    &>.ColorPicker{
        padding: 8px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
        z-index: 10;
    }
`;

const BgColorPicker = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
`;

const OpacityInputBox = styled(ColorInputBox)`
    width: 42px;
    margin-left: 6px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;