import { useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';

import EnuBox from "babylon/class/EnuBox";
import EnuContour from 'babylon/class/EnuContour';
import EnuCylinder from "babylon/class/EnuCylinder";
import EnuTextBox from "babylon/class/EnuTextBox";
import AssetTransformNode from "babylon/class/Asset"
import Material from "babylon/class/Material"
import EnuGUIRectangle from 'babylon/class/EnuGUIRectangle'
import ENU3DButton from 'babylon/class/ENU3DButton'
import {SUPPORT_OBJECT_TYPE} from "babylon/common/Enu3dEnum";
import { Axios } from 'components/Axios'

import { Color3 } from "@babylonjs/core"; 

export default function useProject3DLoader({ URL, EnuScene }) {
    const BASE_DATA = useSelector(state => state.projectData.BASE_DATA);

    const ImportObject = useCallback((objectInfo) => {
        // console.log("Importing: ", objectInfo)
        
        const {userId, projectId} = BASE_DATA;

        let object;
        let materialFlag = null;
        if (objectInfo.material === '') {
            materialFlag = true;
        } else {
            materialFlag = false;
        }
        switch (objectInfo.objectType) {      
            case SUPPORT_OBJECT_TYPE.MATERIAL:
                const r = objectInfo.diffuseColor[0]
                const g = objectInfo.diffuseColor[1]
                const b = objectInfo.diffuseColor[2]
                const alpha = objectInfo.alpha;
                
                object = new Material(EnuScene, '1', new Color3(r, g, b), alpha)
                object.id = objectInfo.id;
                object.name = objectInfo.name;

                break;
            
            case SUPPORT_OBJECT_TYPE.ADT:                
                break;    
            case SUPPORT_OBJECT_TYPE.GUI_RECT:                
                object = new EnuGUIRectangle(EnuScene)
                break;
            
            case SUPPORT_OBJECT_TYPE.GUI_3DMANAGER: 
                break;
        
            case SUPPORT_OBJECT_TYPE.GUI_3DBUTTON: 
                object = new ENU3DButton(EnuScene, objectInfo.name)
                object.billboardMode = objectInfo.billboardMode;
                break;
            
            case SUPPORT_OBJECT_TYPE.CONTOUR:
                const { x: subX, y: subY, z: subZ } = objectInfo.subdivision;
                //TODO: Everything into a single "option" parameter: subdivision, elevation, hiddenpoints...
                object = new EnuContour(EnuScene, "contour", subX, subY, subZ, materialFlag);
                object.minElevation = objectInfo.minElevation;
                object.maxElevation = objectInfo.maxElevation;
                object.colorElevation = objectInfo.colorElevation;
                object.hiddenPoints = objectInfo.hiddenPoints;
                break;

            case SUPPORT_OBJECT_TYPE.BOX:
                // TODO: Generalized Contructor(base) including params for positons
                object = new EnuBox(EnuScene, 'Box', materialFlag);
                object.width = objectInfo.width;
                object.height = objectInfo.height;
                object.depth = objectInfo.depth;
                object.faceColors = objectInfo.faceColors;                
                break;
            
            case SUPPORT_OBJECT_TYPE.CYLINDER:
                object = new EnuCylinder(EnuScene, 'cylinder', materialFlag);                
                object.height = objectInfo.height;
                object.diameterTop = objectInfo.diameterTop;
                object.diameterBottom = objectInfo.diameterBottom;
                object.faceColors = objectInfo.faceColors;
                
                break;
            case SUPPORT_OBJECT_TYPE.TEXTBOX:
                const options = {
                    text: objectInfo.text,
                    size: objectInfo.size,
                    resolution: objectInfo.resolution,
                    depth: objectInfo.depth,
                    updatable: true
                }
                object = new EnuTextBox(
                    EnuScene,
                    objectInfo.name,
                    options
                ); 
                break;
            
            case SUPPORT_OBJECT_TYPE.ASSETTRANSFORMNODE:
                const url = `Repository/${userId}/${projectId}/modeling/`
                object = new AssetTransformNode(EnuScene, url, objectInfo.filename);
                
                break;
            
            case SUPPORT_OBJECT_TYPE.CUSTOM:
                // object = new Custom(EnuScene, objectInfo.url, objectInfo.filename);
                // object.setParentNode(this.parentId);
                // const parent = EnuScene.getObjectByKey(objectInfo.parentId)                
                break;
            
            default:
                console.log(objectInfo)
                throw new Error(`Unexpected objectType: ${objectInfo.objectType}`);
        }
        const skipList = [
            SUPPORT_OBJECT_TYPE.CUSTOM,
            SUPPORT_OBJECT_TYPE.MATERIAL,
            SUPPORT_OBJECT_TYPE.ADT,
            SUPPORT_OBJECT_TYPE.GUI_RECT,
            SUPPORT_OBJECT_TYPE.GUI_3DBUTTON,
            SUPPORT_OBJECT_TYPE.GUI_3DMANAGER
        ];

        if (!skipList.includes(objectInfo.objectType)) {
            object.id = objectInfo.id;
            object.name = objectInfo.name;
            if (objectInfo.material !== ''){
                const material = EnuScene.getMaterialById(objectInfo.material)
                material.id = `material${object.uniqueId}`
                material.name = `material${object.uniqueId}`
                object.material = material
            }
            
            object.position = objectInfo.position
            object.scaling = objectInfo.scaling;
            object.rotation = objectInfo.rotation;
            object.visibility = objectInfo.visibility;

            Object.entries(objectInfo.event).forEach(([eventName, eventScript]) => {
                        EnuScene.addEventToScene(object, eventName, eventScript);
            });
        }
    }, [EnuScene, BASE_DATA]);

    const importSceneFromJSON = useCallback((jsonObject) => {
        const { materialMap, GUIMap, objectMap, lightMap, cameraMap } = jsonObject;
        Object.values(GUIMap).forEach((objectInfo) => ImportObject(objectInfo));
        Object.values(materialMap).forEach((objectInfo) => ImportObject(objectInfo));
        Object.values(objectMap).forEach((objectInfo) => ImportObject(objectInfo));
        Object.values(lightMap).forEach((lightInfo) => {
            // TODO: Add more cases for other light types later
            console.log(lightInfo);
        });
        Object.values(cameraMap).forEach((cameraInfo) => {
            // TODO: Add more cases for other camera types later
            // ex: FreeCamera, ArcRotateCamera, etc
            console.log(cameraInfo);
        });
    }, [ImportObject]);

    useEffect(() => {
        if (!EnuScene) return;
        Axios.get(URL).then((res) => importSceneFromJSON(res.data));
    }, [URL, EnuScene, importSceneFromJSON]);

    return null;
}