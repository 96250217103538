import PropertiesContent from "components/atom/PropertiesContent";
import {SelectContent} from "components/css/common";
import useModalPopup from 'hooks/useModalPopup';

import {MdAdd} from "react-icons/md";
import styled from "styled-components";

export default function TrendFocus({_data, _disabled, ...props}){
    const {AddModal} = useModalPopup();

    const ChangeSelect = (e) => {
        const JsonData = [{
            attributeName: 'focus',
            attributeValue: e.target.value
        }]

        props._ChangeEvent(JsonData);
    }

    const TrendAddData = (NodeId) =>AddModal(`TrendAddData_${NodeId}`, `Series Setup (${NodeId})`, "TrendAddData", _data);
    const TrendAddAxisY= (NodeId) =>AddModal(`TrendAddAxisY_${NodeId}`, `Axis Y Setup (${NodeId})`, "TrendAddAxisY", _data);
      
    return (
        <PropertiesContent _Title="Focus" _isTitle={false}>
            <div>
                <SelectContent height={32} value={_data['focus']} onChange={ChangeSelect} _disabled={_disabled}>
                    {
                        _data.components.split(';').map((_symbolName) => (
                            <option key={_symbolName} value={_symbolName}>{_symbolName}</option>
                        ))
                    }
                </SelectContent>
                <TrendMenu>
                    <li>
                        <div id="BtnTrend"><MdAdd /></div>
                        <div className="AddTrendMenuList">
                            <ul>
                                <li onClick={(e)=>TrendAddData(_data.id)}><span>Add Data</span></li>
                                <li onClick={(e)=>TrendAddAxisY(_data.id)}><span>Add Axis Y</span></li>
                            </ul>
                        </div>
                    </li>
                </TrendMenu>
            </div>
        </PropertiesContent>
    );
}

const TrendMenu = styled.ul`
    position: relative;
    margin-left: 8px;

    &>li{
        position: relative;
        height: 32px;
        width: 32px;

        #BtnTrend{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
            background-color: #fff;
            color: ${({theme}) => theme.base.font_color_DarkBlue};
            border: 1px solid ${({theme}) => theme.base.border_color_Blue};
            border-radius: 4px;
            box-sizing: border-box;
            user-select: none;
            
            &>svg{
                width: 24px;
                height: 24px;
            }

            &:hover{
                background-color: ${({theme}) => theme.base.background_color_LightBlue};
            }
        }

        &:hover{
            .AddTrendMenuList{
                display: block;
            }
        }

        .AddTrendMenuList{
            display: none;
            position: absolute;
            right: 0;
            width: 100px;
            padding-top: 8px;
            z-index: 9;

            &>ul{
                background-color: #fff;
                border-radius: 4px;
                padding: 8px;
                box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
                cursor: default;
                
                span{
                    color: ${({theme}) => theme.base.font_color_Black};
                    font-size: 0.9rem;
                    display: flex;
                    width: 100%;
                    padding: 6px;
                    box-sizing: border-box;
                }

                li {
                    cursor: pointer;

                    &:hover{
                        background-color :  ${({theme}) => theme.base.background_color_LightBlue};
                    }
                }
            }
        }
    }
`;