
import { useContext, useReducer } from "react";

import {BtnSecondary} from "components/atom/Button";
import PublishMenu from "components/molecule/PublishMenu";
import {PUBLISH_TYPE} from "components/common/EnuSpaceEnum";
import { TransientModalContext } from "context/TransientModalContext"
import { GetProjectPublishInfo, GetPublishMemberList} from 'api/RepositoryApi'

function reducer(state, action){
    switch(action.type){
        case 'Init':{
            const {mode, userList, activateViewPath, isMetaverse, useAvatar, enterNodeID, autoFit} = action; 
            return {mode, userList, activateViewPath, isMetaverse, useAvatar, enterNodeID, autoFit}
        }
        case 'ChangeMode':{
            return {...state, mode: action.value}
        }    
        case 'ChangeViewPath':{
            return {...state, activateViewPath: action.viewPath}
        }    
        case 'ChangeMetaverseMode':{
            const isMetaverse = action.isMetaverse;
            const useAvatar = isMetaverse ? state.useAvatar : false;
            const autoFit = isMetaverse ? state.autoFit : false;
            const enterNodeID = isMetaverse ? state.enterNodeID : '';
            return{ ...state, isMetaverse, useAvatar, autoFit, enterNodeID }
        }
        case 'ChangeUseAvatar':{
            const useAvatar = action.isMetaverse;
            const autoFit = useAvatar ? state.autoFit : false;
            return {...state, useAvatar, autoFit}
        }  
        case 'ChangeAutoFit':{
            return { ...state, autoFit: action.isAutoFit }
        }  
        case 'ChangeEnterId':{
            return { ...state, enterNodeID: action.enterNodeID }
        }  
        case 'AddUser':{
            const prevUserList = state.userList;
            const userEmail = action.userEmail;
            const userList = prevUserList.includes(userEmail) ? prevUserList : [...prevUserList, userEmail];
            return { ...state, userList };
        }    
        case 'DeleteUser':{
            const userList = state.userList.filter((_, idx) => idx !== action.index);
            return { ...state, userList };
        }    
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

const init = {
    mode: 0,
    userList: [],
    activateViewPath: '',
    isMetaverse: false,
    useAvatar: false,
    enterNodeID: '',
    autoFit: false,
}

export default function PublishMenuBtn({enuSpace, userId, projectId, treeData, saveEvent}){
    const {TransientModalInfo, setTransientModalInfo} = useContext(TransientModalContext);
    const [PublishInfo, setPublishInfo] = useReducer(reducer, init);

    // FIXME: 확인 필요
    // window.Re_UseMetaverseAvatar = (useAvatar) => { setPublish({...Publish, useAvatar})};

    return(
        <>
            <BtnSecondary
                label="Publish"
                type='sm'
                title='publish'
                onClick={(e) => {
                    const activateViewPath = enuSpace.GetActivateView();
                    const isMetaverse = enuSpace.GetMetaverseFlag();
                    const useAvatar = enuSpace.GetMetaverseAvatarFlag();
                    const enterNodeID = enuSpace.GetEnterNodeID();
                    const autoFit = enuSpace.GetMetaverseViewFixedFlag();

                    Promise.all([
                        GetProjectPublishInfo(userId, projectId),
                        GetPublishMemberList(userId, projectId)
                    ])
                        .then(([projectInfo, memberList]) => {
                            let mode = PUBLISH_TYPE.PUBLIC;
                            let userList = [];

                            const { result: resultProjectInfo, data: dataProjectInfo } = projectInfo.data;
                            const { result: resultMemberList, data: dataMemberList } = memberList.data;

                            if (resultProjectInfo === 'Success') {
                                mode = dataProjectInfo.PUBLISH_MODE;
                            }

                            if (resultMemberList === 'Success') {
                                userList = dataMemberList.map(item => item.email);
                            }

                            setPublishInfo({
                                type: 'Init', 
                                mode, 
                                userList, 
                                activateViewPath, 
                                isMetaverse, 
                                useAvatar, 
                                enterNodeID, 
                                autoFit
                            });
                            
                            setTransientModalInfo({ Id: 'PUBLISH_MENU_ID' })
                        })
                        .catch((errors) => {
                            window.AddAlertMsg({ type: 'error', text: errors });
                        });
                }}
            />

            {/* publish 설정 박스 */}
            {TransientModalInfo?.Id === 'PUBLISH_MENU_ID' &&
                <PublishMenu 
                    enuSpace={enuSpace} 
                    treeData={treeData} 
                    saveEvent={saveEvent} 
                    publishReducer={[PublishInfo, setPublishInfo]}
                />
            } 
        </>
    )
}