import { useEffect, useRef, useContext } from "react";
import { SceneContext } from "context/SceneContext";
import EnuScene from "babylon/class/EnuScene";

import { Engine, Scene } from "@babylonjs/core";

export default function SceneComponent({ id, antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, ...rest }){
    const reactCanvas = useRef(null);
    const {setEnuScene} = useContext(SceneContext);

    // set up basic engine and scene
    useEffect(() => {
        const { current: canvas } = reactCanvas;
        if (!canvas) return;

        // canvas.style.width = '100%';
        // canvas.style.height = '100%';

        const engine = new Engine(canvas, antialias, engineOptions, adaptToDeviceRatio);
        const scene = new Scene(engine, sceneOptions);
        if (scene.isReady()) {
            const enuScene = new EnuScene(scene, canvas)
            setEnuScene(enuScene);
            onSceneReady(enuScene, canvas);
        } else {
            scene.onReadyObservable.addOnce((scene) => onSceneReady(scene));
        }

        engine.runRenderLoop(() => {
            if (typeof onRender === "function") onRender(scene);
            scene.render();
        });

        const resize = () => {
            scene.getEngine().resize();
        };

        if (window) {
            window.addEventListener("resize", resize);
        }

        return () => {
            scene.getEngine().dispose();

            if (window) {
                window.removeEventListener("resize", resize);
            }
        };
    }, [setEnuScene, antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

    return <canvas id={id} ref={reactCanvas} {...rest} />;
};