import { useState, useEffect, useCallback} from 'react'

import {BtnGhost} from "components/atom/Button";
import PropertiesContentAdd from "components/atom/PropertiesContentBtn"
import PropertySheetRowContent from 'components/atom/PropertySheetRowContent';
import useModalPopup from 'hooks/useModalPopup';
import useProjectData from 'hooks/useProjectData';

import styled from "styled-components"
import { useSelector } from 'react-redux';

export default function VariableContent({enuSpace}){
    const ARRAY_ACTIVE_PICTURE_URL = useSelector(state => state.projectData.ARRAY_ACTIVE_PICTURE_URL);  //활성화 된 picture URL
    const NodeData = useSelector(state => state.selectNodeData)                                         //캠퍼스 클릭시 선택된 node 정보
    const {
        id: NodeId ,
        from: NodeFrom, 
        path: NodePath, 
        type: NodeType, 
        object_name: NodeObjectName,
        isMultiNode: NodeIsMultiNode,
    } = NodeData;
    const [CurrentSvgInfo, setCurrentSvgInfo] = useState([]);
    const [VariableInfoList, setVariableInfoList] = useState('');

    const {AddModal} = useModalPopup();
    const {SetTreeData}= useProjectData();

    const ReqVariableTree = useCallback(() => {
        const ARRAY_PATH = NodeFrom === 'ExplorerTree' ? NodePath : ARRAY_ACTIVE_PICTURE_URL;
        const folderName = ARRAY_PATH[0];

        switch (folderName){
            case 'global' :
                {
                    // picture\chan.svg  =>['picture', 'chan.svg']
                    const URL = `${folderName}\\${ARRAY_PATH[1]}`;
                    setCurrentSvgInfo({ URL, folderName, Type:'global'});
                    const targetID = ['struct', 'attribute'].includes(NodeType) ? `${NodeType}\\${NodePath[2]}` : NodeId;
                    enuSpace.GetVariableTree(URL, targetID, folderName, "", 'VariableContent');
                    break;
                }
            case 'picture' :
                {
                    // picture\chan.svg  =>['picture', 'chan.svg']
                    const URL = `${folderName}\\${ARRAY_PATH[1]}`;
                    setCurrentSvgInfo({ URL, folderName, Type:'picture'});
                    enuSpace.GetVariableTree(URL, NodeId, folderName, "", 'VariableContent');
                    break;
                }
            case 'library' :
                {
                    if(NodeType  === 'a.d') return;

                    // library\hmi\file.svg\SD => ['library', 'hmi', 'file.svg', 'SD']
                    const URL = `${folderName}\\${ARRAY_PATH[1]}\\${ARRAY_PATH[2]}`;
                    const Type = ARRAY_PATH[1];
                    const symbolName = `#${ARRAY_PATH[3]}`;
                    setCurrentSvgInfo({ URL, Type, symbolName, });
                    enuSpace.GetVariableTree(URL, NodeId, Type, symbolName, 'VariableContent');
                    break;
                }
            default:
                break;
        }
    } , [ enuSpace, NodeFrom, NodeId, NodePath, NodeType, ARRAY_ACTIVE_PICTURE_URL]);

    window.ReloadVariableTree = () => ReqVariableTree();

    useEffect(()=>{
        setVariableInfoList('');
        if(NodeIsMultiNode) return;
        if(['svg', 'symbol', 'use'].includes(NodeObjectName) || ['struct', 'attribute', 'symbol'].includes(NodeType))
            ReqVariableTree();
    }, [NodeObjectName, NodeType, NodeIsMultiNode, ReqVariableTree]);

    // Variable Name 기준으로 정렬 처리를 위한 함수
    const SortDataByVariableName = (data) =>{
        if(typeof(data) === 'string' || data.length === 0 ) return;
        
        data.forEach(item => {
            const [type, VariableName] = item.title.split(' ');
            item.type = type
            item.VariableName = VariableName

            if (item.child && item.child.length) {
                SortDataByVariableName(item.child);
            }
        });

        data.sort((a, b) => a.VariableName.localeCompare(b.VariableName));
    }

    window.Re_GetVariableInfoList = (_jsonVariableInfoList) =>{
        const {type, data} = _jsonVariableInfoList;
        if(type === 'success'){
            if(data[0] === null){
                setVariableInfoList('');
                return;
            }else if(NodeType === 'DB')    // interface DB 일 경우 표기하지 않는다.
                return;

            SortDataByVariableName(data);
            setVariableInfoList(data);
        }
    }

    window.Re_DeleteVariable = (_msg) => {
        const {type, data} = JSON.parse(_msg);
        if(type === 'success'){
            if(NodeFrom=== 'ExplorerTree'){
                SetTreeData(JSON.parse(enuSpace.GetProjectTree()));
            }
            ReqVariableTree();
        }else if(type === 'failed'){
            window.AddAlertMsg({type: 'error', msg: `${data}`})
        }
    }

    const ShowPopup = (e) =>{
        const svgName = ARRAY_ACTIVE_PICTURE_URL[ARRAY_ACTIVE_PICTURE_URL.length-1];
        const { URL: url, Type: type, symbolName } = CurrentSvgInfo;
        AddModal(url, `Variable (${svgName})`, 'AddVariableItem',
            {
                url,
                type,
                symbolName: symbolName ?? "",
                targetRowElementInfo: {
                    id: NodeId,
                    object_name: NodeId !== '' ? 'svg' : '',
                    from: 'Variable'
                }
            });
    }

    const DeleteVariable = (ValueL) =>{
        const {URL, Type, symbolName} = CurrentSvgInfo;
        if(window.confirm(`"${ValueL}" 을 삭제하시겠습니까?`)){
            //FIXME: attribute\\variable, struct\\type --- struct관련 정리 필요
            enuSpace.DeleteVariable(URL, Type, symbolName || "", `attribute\\${ValueL}`);
        }
    }

    const EditModeVariablePopup = (e, _type, _desc, attribute, originalValue) =>{
        const {symbolName} = CurrentSvgInfo;
        let TargetArrayURL, TargetArrayType;
        if(NodeFrom !== undefined && NodeFrom === 'ExplorerTree'){
            TargetArrayURL = NodePath;
            TargetArrayType = NodeType; 
        }else{
            TargetArrayURL = ARRAY_ACTIVE_PICTURE_URL;
            TargetArrayType = _type;
        }

        AddModal('EditVariableItem', 'Edit Variable', 'EditVariableItem', 
            {
                url: TargetArrayURL,
                type: TargetArrayURL[0] === 'library' ? TargetArrayURL[1] : TargetArrayURL[0],
                symbolName: symbolName !== undefined ? symbolName :"" ,
                attribute,
                originalValue,
                desc:_desc,
                targetType: TargetArrayType,
                useID: NodeId,
                targetRowElementInfo:{
                    id : NodeId,
                    object_name : NodeObjectName,
                    from : 'Variable'
                }
            });
    }

    const RenderPropertySheet = (Child, RowDepth, Type , Readonly=false) => {
        return Child.map((item, index) => {
            const {title, child: itemChild} = item;
            const isChild = (itemChild !== undefined && (itemChild.length !== 1 || itemChild[0].child !== null));
            return(
                <PropertySheetRowContent 
                    key={title+index} 
                    Type={Type} 
                    Depth={RowDepth} 
                    ValueL={title} 
                    ValueR={isChild ? null : itemChild?.[0]?.title}
                    Desc={itemChild?.[0]?.desc} 
                    Readonly={Readonly}
                    Delete={DeleteVariable} 
                    OnClick={EditModeVariablePopup}
                    >
                    {isChild ? RenderPropertySheet(itemChild, RowDepth + 1, 'struct') : null}
                </PropertySheetRowContent>
            )
        })
    }

    if(NodeObjectName === 'use'){
        return(
            <>
                {(VariableInfoList && VariableInfoList[0] !== null)  &&
                    <>
                        <PropertySheetRowContent ValueL="property" ValueR="value" Type='header' />
                        <div style={{'overflowY': 'overlay'}}>
                            {VariableInfoList[0]['child'] && RenderPropertySheet(VariableInfoList[0]['child'], 0, 'use', true) }
                        </div>
                    </>
                }

                {(VariableInfoList && VariableInfoList[1] !== null)  &&
                    <>
                        <PropertiesContentAdd _Title={'SetAttribute'}  />
                        <PropertySheetRowContent ValueL="property" ValueR="value" Type='header' />
                        <div style={{'overflowY': 'overlay'}}>
                            {(VariableInfoList[1] && VariableInfoList[1]['child'] )&& RenderPropertySheet(VariableInfoList[1]['child'], 0, 'use') }
                        </div>
                    </>
                }
            </>
        )
    }

    return(
        <VariableLayer>
            {(NodeObjectName === 'svg' || NodeObjectName === 'symbol' || (NodeObjectName === '' && NodeType === 'symbol')) && 
                <PropertiesContentAdd 
                    _Title={NodeId} 
                    _btn={
                        <BtnGhost label="Add" type='sm' onClick={ShowPopup}/>
                    }
                />
            }

            {VariableInfoList &&
                <PropertySheetRowContent ValueL="property" ValueR="value" Type='header' />
            }

            {(VariableInfoList && VariableInfoList[0] !== null) &&
                <div style={{ 'overflowY': 'overlay' }}>
                    {VariableInfoList[0]['child'] && RenderPropertySheet(VariableInfoList[0]['child'], 0, 'base')}
                </div>
            }

        </VariableLayer>
    )
}

const VariableLayer = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 40px);
    overflow: auto;
`