import {useEffect, useState } from 'react';

import {BtnGhost} from "components/atom/Button";
import {RadioComponent} from "components/atom/RadioContent";
import {EnuIconFolderClose} from "components/common/EnuSpaceIcon"
import {IMG_RESOURCE_TYPE} from "components/common/EnuSpaceEnum";
import useImageList from 'hooks/useImageList';
import {GetDescription} from 'api/Project';
import useModalPopup from 'hooks/useModalPopup';
import useProjectData from 'hooks/useProjectData'

import { useSelector } from 'react-redux';
import styled from "styled-components";

export default function ImageListContent({enuSpace}){
    const {AddModal} = useModalPopup();
    const { from: NodeFrom } = useSelector(state => state.selectNodeData); 
    const {DeleteNodeData, SetNodeData} = useProjectData();
    const {userId, projectId} = useSelector(state => state.projectData.BASE_DATA);
    const {ImageList, RefetchImageList, CurrentFolderPathArray} = useImageList();
    const [selectImage, setSelectImage] = useState('');
    const [templateImage, setTemplateImage] = useState({});
    const [ImageType, setImageType] = useState('myProject')

    useEffect(()=>{
        if(NodeFrom !== 'ImageListContent')
            setSelectImage('');
    }, [NodeFrom]);

    const SelectFolder = (_folderName) => {
        _folderName === '...' ? CurrentFolderPathArray.pop() : CurrentFolderPathArray.push(_folderName);
        RefetchImageList(CurrentFolderPathArray.join("/"));
    }

    useEffect(()=>{
        GetDescription(userId, projectId, 'temp')
            .then(res => {
                const { result, data } = res.data;
                if (result === "Success") {
                    const {templateInfo, templateImage} = JSON.parse(data);
                    setTemplateImage({templateInfo, templateImage})
                    setImageType('myProject')
                }
            })
    }, [userId, projectId]);

    const RenderFolderItem = (itemName, props) => {
        return (
            <ItemContent 
                {...props}
                onDoubleClick={(e) => SelectFolder(itemName)}
                onClick={(e) => {
                    setSelectImage('');
                    DeleteNodeData();
                }}
            >
                <EnuIconFolderClose className='icon_folder' />
                <span {...props}>{itemName}</span>
            </ItemContent>
        )
    }

    const RenderImgList = (menu) => menu?.map(({type: ItemType, title: ItemTitle}, indx)=>{
        const commonProps = { key: ItemTitle, 'data-name': ItemTitle, title: ItemTitle };
        const ImageSrc = `/${CurrentFolderPathArray.join('/')}/${ItemTitle}`

        const imageJson = {
            TYPE : "image",
            PATH : `/${CurrentFolderPathArray.join('/')}/${ItemTitle}`
        }

        switch (ItemType) {
            case IMG_RESOURCE_TYPE.FOLDER:
                return RenderFolderItem(ItemTitle, {...commonProps});
            case IMG_RESOURCE_TYPE.IMAGE:
                const isSelected = ItemTitle === selectImage;
                return (
                    <ItemContent 
                        {...commonProps} 
                        draggable="true"
                        className={isSelected ? 'selectImage' : ''}
                        onClick={async (e) => {
                            DeleteNodeData();
                            setSelectImage(isSelected ? '' : ItemTitle)
                            const imageDescKey = ImageSrc.replace('/resource/', '');
                            const res = await GetDescription(userId, projectId);
                            const { result, data, msg } = res.data;

                            if (result === "Success") {
                                const desc = JSON.parse(data)[imageDescKey]['Description'];
                                if (selectImage === '') {
                                    SetNodeData({ from: 'ImageListContent', imageDescKey, desc, });
                                }
                            } else
                                window.AddAlertMsg({ type: 'error', msg })
                        }}
                        onDragStart={(e) => {
                            e.dataTransfer.setData('text/plain', JSON.stringify({ ImageSrc:imageJson, type: 'image' }))
                        }}
                    >
                        <img 
                            draggable="false"
                            alt=""
                            src={`Repository/${userId}/${projectId}${ImageSrc}`}
                        />
                        <span {...commonProps}>{ItemTitle}</span>
                    </ItemContent>
                )
            default:
                return console.error('Invalid ItemType');
        }
    });

    window.ReloadImageTreeList = ()=>RefetchImageList();

    return(
        <aside style={{height: 'calc(100% - 16px)'}}>
            <ImageListHeader>
                <BtnGhost 
                    label="Edit Image Resource" 
                    onClick={(e) => AddModal('ImageModal', 'Image', 'ImageModal', {null: null,})}
                />
            </ImageListHeader>

            <ImageContent>
                {templateImage?.templateImage && 
                    <RadioComponent
                        attributeName='imageType'
                        dataList={{ myProject: 'my Project', template: 'template' }}
                        selectedValue={ImageType}
                        height='32px'
                        changeEvent={(attributeName, value) => {
                            setImageType(value)
                        }}
                    />
                }

                {(ImageType === 'template' && templateImage?.templateImage) && 
                    templateImage?.templateImage.map((imageInfo, index) => {
                        
                        const {TEMPLATE_SYSTEMID, VERSION} = templateImage.templateInfo;
                        const {TITLE, IMAGE_SYSTEMID} = imageInfo;
                        const extension = TITLE.split('.').pop();
                        const fileName = TITLE.split('.').slice(0, -1).join('.');
                        const commonProps = { key: TITLE, 'data-name': TITLE, title: TITLE };
                        const isSelected = TITLE === selectImage;
                        const ImageSrc = {
                            TYPE : "template",
                            VERSION,
                            TEMPLATE_SYSTEMID,
                            IMAGE : `${IMAGE_SYSTEMID}.${extension}`,
                            FILENAME : fileName
                        }
                        
                        return(
                            <ItemContent
                                {...commonProps}
                                draggable="true"
                                className={isSelected ? 'selectImage' : ''}
                                onClick={async (e) => {
                                    DeleteNodeData();
                                    setSelectImage(isSelected ? '' : TITLE)
                                    const res = await GetDescription(userId, projectId);
                                    const { result, data, msg } = res.data;

                                    if (result === "Success") {
                                        const desc = JSON.parse(data)[TITLE]['Description'];
                                        if (selectImage === '') {
                                            SetNodeData({ from: 'ImageListContent', TITLE, desc, });
                                        }
                                    } else
                                        window.AddAlertMsg({ type: 'error', msg })
                                }}
                                onDragStart={(e) => {
                                    e.dataTransfer.setData('text/plain', JSON.stringify({ ImageSrc, type: 'image' }))
                                }}
                            >
                                <img
                                    draggable="false"
                                    alt=""
                                    src={`Repository/Template/${TEMPLATE_SYSTEMID}/${VERSION}/Image/${IMAGE_SYSTEMID}.${extension}`}
                                />
                                <span {...commonProps}>{fileName}</span>
                            </ItemContent>
                        )
                    })
                }

                {(ImageType === 'myProject' && ImageList?.[0]?.type === IMG_RESOURCE_TYPE.FOLDER) &&
                    RenderFolderItem('...')
                }
                {(ImageType === 'myProject' && ImageList?.[0]) &&
                    RenderImgList(ImageList[0]['child'])
                }
            </ImageContent>
        </aside>
    )
}

//스타일---------------------------------------------
const ImageContent = styled.div`
    padding: 8px;
    border-top: 1px solid  ${({theme}) => theme.base.border_color_Gray};
    overflow: auto;
    height: calc(100% - 72px);
`;

const ImageListHeader = styled.div`
    font-size: .9rem;
    height: 32px;
    height: 38px;
    width: calc(100% - 32px);
    box-sizing: border-box;
    margin: 16px;

    &>button{
        width: 100%;
    }
`;

const ItemContent = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #fff;
    user-select: none;

    &.selectImage{
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
        background-color: ${({theme}) => theme.base.background_color_LightBlue};
        border-radius: 4px;
    }

    &:hover{
        border: 1px solid ${({theme}) => theme.base.border_color_Blue};
        border-radius: 4px;

        svg{
            display: block;
        }
    }

    img{
        width: 46px;
        height: 46px;
        box-sizing: border-box;
        object-fit: scale-down;
    }

    .icon_folder{
        width: 46px;
        height: 46px;
        padding: 8px;
        box-sizing: border-box;
    }

    span {
        font-size: .9rem;
        color: ${({theme}) => theme.base.font_color_Black};
        width: calc(100% - 46px);
        line-height: 1.1;
        padding-left: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;