import { useState, useRef, useEffect } from "react";

import PropertiesContent from "components/atom/PropertiesContent";
import BtnToggle from "components/atom/BtnToggle";
import TextAlignRadioContent from "components/atom/TextAlignRadioContent";
import SelectList from "components/atom/SelectList";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import FontFamily from 'components/common/FontFamily';
import {FontSizeIcon, DecoNoneIcon, DecoThroughIcon, DecoUnderlineIcon} from 'components/common/EnuSpaceIcon';
import { GroupName, ToggleRect } from 'components/css/common';

import styled from "styled-components";
import { MdFormatItalic } from "react-icons/md";

export default function TableCellInputList({ _data, _ChangeEvent, _disabled, ...props }) {
    const {
        font_family: NodeFontFamily,
        text: NodeText,
        font_style: NodeFontStyle,
        font_weight: NodeFontWeight,
        font_size: NodeFontSize,
        horizontal_align: NodeHorizontalAlign,
        vertical_align: NodeVerticalAlign,
        text_anchor: NodeTextAnchor,
        interface: NodeInterface,
    } = _data;

    const taRef = useRef();
    const [cursor, setCursor] = useState(0); 
    const [fontClass] = useState(new FontFamily());

    //�� �Է� �� Ŀ���� ��ġ
    useEffect(() => {
        if (taRef.current !== undefined)
            taRef.current.setSelectionRange(cursor, cursor);
    }, [cursor]);

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return (
        <PropertiesContent _Title="Text">
            <div>
                <SelectList
                    attributeName="font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <SelectList
                    attributeName="font-weight"
                    dataList={fontClass.GetFontWeight(NodeFontFamily)}
                    selectedValue={fontClass.CheckFontWeight(NodeFontFamily, NodeFontWeight)}
                    {...commonProps}
                />
                <InputComponent
                    label={<FontSizeIcon />}
                    attributeName="font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeFontSize}
                    {...commonProps}
                />
            </div>
            <div>
                <ColorComponent label="text" attributeName="text-color" isGradient={false} isVisibility={false} {...commonProps} />
            </div>
            <AlignDiv>
                <TextAlignRadioContent attributeName='horizontal-align' selectValue={NodeHorizontalAlign} {...commonProps} />
                <TextAlignRadioContent attributeName='vertical-align' alignType='vertical' selectValue={NodeVerticalAlign} {...commonProps} />
                <ToggleRect
                    disabled={_disabled}
                    onClick={(e) => {
                        if (_disabled) return;
                        const attributeValue = Array.isArray(NodeFontStyle) ? 'italic' : ['normal'].includes(NodeFontStyle) ? 'italic' : 'normal';
                        _ChangeEvent([{ attributeName: 'font-style', attributeValue }]);
                    }}
                >
                    <MdFormatItalic className={Array.isArray(NodeFontStyle) ? '' : ['normal'].includes(NodeFontStyle) ? '' : 'active'} />
                </ToggleRect>
            </AlignDiv>
            <div>
                <GroupName width={84}>anchor</GroupName>
                <SelectList
                    attributeName="text-anchor"
                    dataList={["start", "middle", "end"]}
                    selectedValue={NodeTextAnchor}
                    {...commonProps}
                />
            </div>
            <div style={{'justifyContent': 'space-between'}}>
                <GroupName  width={84}>interface</GroupName>
                <BtnToggle 
                    _toggleID="interface" 
                    _isToggleValue={NodeInterface} 
                    _disabled={Array.isArray(NodeInterface) ? true : _disabled}
                    _IsToggle={(attributeName, attributeValue)=> _ChangeEvent([{attributeName,attributeValue }])}
                />
            </div>
            <TextArea>
                <textarea
                    ref={taRef}
                    name="text"
                    value={Array.isArray(NodeText) ? 'Mixed' : NodeText === 'Input the Text.' ? '' : NodeText}
                    onClick={(e) => {
                        if (e.target.value === 'Mixed') e.target.select();
                    }}
                    onChange={(e) => {
                        _ChangeEvent([{ attributeName: 'text', attributeValue: e.target.value }])
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Tab') {
                            e.preventDefault();
                            const { value, selectionStart, selectionEnd } = e.target

                            const attributeValue = value.substring(0, selectionStart) + '\t' + value.substring(selectionEnd);
                            _ChangeEvent([{ attributeName: 'text', attributeValue }])
                            setCursor(selectionStart + 1);
                        }

                        if (e.nativeEvent.isComposing)
                            return;

                        if (e.key === 'Enter' && e.shiftKey) {
                            return;
                        } else if (e.key === 'Enter') {
                            e.preventDefault();
                            e.target.blur();
                        } else if (e.ctrlKey && e.key === 'a') {
                            e.preventDefault();
                            e.target.select();
                        }
                    }}
                    disabled={_disabled}
                />
            </TextArea>
        </PropertiesContent>
    )
}

const TextArea = styled.div`
    height: 72px;
    margin-top: 0;

    textarea{
        margin-left:8px;
        width: 100%;
        height: 58px;
        border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
        border-radius: 2px;
        overflow:auto;
        resize: none;
        line-height:20px;

        &:focus, &:hover {
            border: 1px solid ${({ theme }) => theme.base.border_color_Blue};
        }

        &:defined {
            border: 1px solid ${({ theme }) => theme.base.border_color_LightGray};
            outline: none;
        }
    }
`;

const AlignDiv = styled.div`
    margin-left: 8px;
    gap: 8px;
`;