import { useState, useEffect, useContext, useRef } from 'react';

import {BtnPrimary, BtnSecondary, BtnGhost} from "components/atom/Button";
import JavascriptCodeEditor from "babylon/components/atom/JavascriptCodeEditor";
import { SceneContext} from "context/SceneContext";
import {GroupName, ChartTitle, SelectContent, ModalBottomBox} from 'components/css/common';
import {SUPPORT_FUNCTION} from 'babylon/common/Enu3dEnum'
import useDataController from 'babylon/hooks/useDataController';
import useModalPopup from 'hooks/useModalPopup';

import styled from "styled-components";

export default function AddEvent({enuSpace, _Modal}){
    const {
        id: ModalId,
        data:{
            uniqueId: ModalUniqueId,
            mode: ModalDataMode,
            selectedData: ModalSelectedData,
            eventName: ModalEventName
        }
    } = _Modal;

    const {UploadDataByMesh} = useDataController();
    const {EnuScene} = useContext(SceneContext);
    const [SelectedEvent, setSelectedEvent] = useState('');
    const [TempScript, setTempScript] = useState('');
    const [OutputConsolLog, setOutputConsolLog] = useState('');
    const {UpdateModalData, DeleteModal} = useModalPopup();

    const IsAddMode = ModalDataMode === 'Add';

    const InitEventMap = (EventName) => {
        return {[`${EventName}`]: `function ${EventName}(scene, self){\n\t//TODO Add your javascript code here\n\t\n}`}
    }

    useEffect(()=>{
        if(ModalDataMode === 'Add'){
            const registeredEvents = ModalSelectedData.event;

            for (const EventName of Object.values(SUPPORT_FUNCTION)){
                if(registeredEvents[EventName] === undefined){
                    const initFunctionCode = InitEventMap(EventName);
                    setSelectedEvent(initFunctionCode)
                    setTempScript(Object.values(initFunctionCode)[0]);
                    break;
                }
            }
        }else {
            const initFunctionCode = {[ModalEventName]: ModalSelectedData.event[ModalEventName]};
            setSelectedEvent(initFunctionCode)
            setTempScript(Object.values(initFunctionCode)[0]);
        }
        setOutputConsolLog('');
    }, [ModalDataMode, ModalSelectedData, ModalEventName]);

    const AddEvent = (e, addFunction) => {
        const targetMesh = EnuScene.getObjectByKey(ModalUniqueId);
        const eventName = Object.keys(SelectedEvent)[0];
        

        EnuScene.addEventToScene(
            targetMesh,
            eventName,
            TempScript,
            ()=>{
                UploadDataByMesh(targetMesh)
                setOutputConsolLog('Success');

                addFunction && addFunction();
            }
        )
    }

    //크기 조절 관련 함수
    const EditBox = useRef();
    const OutputBox = useRef();
    const [isReSize, setIsReSize] = useState(false);
    const [initialPos, setInitialPos] = useState('');
    const [initialSize, setInitialSize] = useState('');

    const initial = (e) => {
        setInitialPos(e.clientY);
        setInitialSize({ EditBox: EditBox.current.offsetHeight, OutputBox: OutputBox.current.offsetHeight });
        setIsReSize(true);
        EditBox.current.style.userSelect = 'none';
        OutputBox.current.style.userSelect = 'none';
    }

    const resize = (e) => {
        if (isReSize) {
            const MaxHeight = OutputBox.current.parentNode.offsetHeight - 100;
            let EditHeight = parseInt(initialSize['EditBox']) + parseInt(e.clientY - initialPos);
            let OutputHeight = parseInt(initialSize['OutputBox']) - parseInt(e.clientY - initialPos);

            if (EditHeight < 108) {
                EditHeight = 108;
                OutputHeight = MaxHeight - 156;
            } else if (OutputHeight < 108) {
                OutputHeight = 108;
                EditHeight = MaxHeight - 156;
            }

            OutputBox.current.style.height = `${OutputHeight}px`;
            const editHeight = OutputHeight + 24 + 38 + 62;
            EditBox.current.style.height = `calc(100% - ${editHeight}px)`;
        }
    }

    const onDragEnd = (e) => {
        resize(e);
        setIsReSize(false);
        EditBox.current.style.userSelect = 'auto';
        OutputBox.current.style.userSelect = 'auto';
    }
    //  /크기 조절 관련 함수

    return(
        <>
            <ModalHeader>
                <div>
                    <div style={{ width: '180px', display: 'flex', alignItems: 'baseline' }}>
                        <GroupName width={52} htmlFor='ObjectId'>Name: </GroupName>
                        <EventId name="EventId" onChange={(e) => { }} title={ModalSelectedData.name || ''} value={ModalSelectedData.name || ''} />
                    </div>
                    <div style={{ width: '200px' }}>
                        <GroupName htmlFor='ObjectEventType'>Event: </GroupName>
                        <SelectContent
                            name="ObjectEventTypeSelect"
                            value={Object.keys(SelectedEvent)[0]}
                            title={Object.keys(SelectedEvent)[0]}
                            onChange={(e)=>{
                                setSelectedEvent(InitEventMap(e.target.value));
                                setTempScript(Object.values(InitEventMap(e.target.value))[0]);
                            }}
                            onFocus={(e) => document.activeElement.blur()}
                            width={144}
                            disabled={!IsAddMode}
                        >
                            {/* 이벤트 추가시 */}
                            {IsAddMode &&
                                Object.entries(SUPPORT_FUNCTION).map((EventInfo) => {
                                    const [, EventName] = EventInfo;
                                    const ActiveEvent = ModalSelectedData.event;

                                    if(ModalSelectedData === null || ActiveEvent[EventName] === undefined ) {
                                        return <option key={EventName} value={EventName}>{EventName}</option>
                                    }else 
                                        return null;
                                })
                            }

                            {/* 이벤트 수정시 */}
                            {!IsAddMode && SelectedEvent &&
                                <option key={Object.keys(SelectedEvent)[0]} value={Object.keys(SelectedEvent)[0]}>{Object.keys(SelectedEvent)[0]}</option>
                            }
                        </SelectContent>
                    </div>
                </div>
                <BtnGhost label="Execute" onClick={(e) => {
                    const scriptInside = TempScript.slice(TempScript.indexOf('{')+1, TempScript.lastIndexOf('}'));
                    
                    try {
                        // FIXME: 보안상 문제가 있음 -> 다른 바람직한 방법을 찾아야함
                        // eslint-disable-next-line
                        const tempCode = new Function('scene', 'self', scriptInside);
                        const target  = EnuScene.getObjectByKey(ModalUniqueId);
                        tempCode(EnuScene, target);
                    }catch(e){
                        setOutputConsolLog(e.message);
                        return;
                    }

                    setOutputConsolLog('Success')
                }} />
            </ModalHeader>

            <EditContent ref={EditBox}>
                <JavascriptCodeEditor _CodeData={Object.values(SelectedEvent)[0]} _tempScript={setTempScript}/>
            </EditContent>

            <OutputContent ref={OutputBox}>
                <OutputTitle draggable='true' onDragStart={initial} onDrag={resize} onDragEnd={onDragEnd}>Output</OutputTitle>
                <JavascriptCodeEditor _CodeData={OutputConsolLog} _isReadOnly={true}/>
            </OutputContent>

            <ModalBottomBox>
                <BtnSecondary label="Accept"
                    onClick={(e) => {
                        AddEvent(e);
                        UpdateModalData(ModalUniqueId, {
                            mode: 'Edit',
                            selectedData: {..._Modal.data, ...EnuScene.getObjectByKey(ModalUniqueId).getPropertyMap()},
                            eventName: Object.keys(SelectedEvent)[0],
                        })
                    }}
                />

                <BtnPrimary label="Compile"
                    onClick={(e) => {
                        AddEvent(e, DeleteModal(ModalId));
                    }}
                />
            </ModalBottomBox>
        </>
    )
}

//스타일--------------------------
const ModalHeader =  styled.div`
    display: flex;
    align-items: center;
    justify-content:space-between;
    cursor:default; 

    &>div{
        display: flex;
        align-items: center;
    }
`;

const EventId = styled.input`
    overflow: hidden;
    text-overflow: ellipsis;
    height: 38px;
    box-sizing: border-box;
    font-size: .9rem;
    border: 0 none;
    &:focus {
        outline: none;
    }
`;

const EditContent = styled.div`
    cursor:text; 
    height: calc(100% - 232px);
    min-height: 108px;

    &>div{
        height: 100%;
    }
`

const OutputContent = styled.div`
    min-height: 108px;

    &>span{
        margin-top: 0;
    }

    &>div{
        height: calc(100% - 28px);
    }
`
const OutputTitle = styled(ChartTitle)`
    width: 100%;

    &:hover{
        cursor: row-resize;
    }
`