import {useContext} from 'react';

import PropertiesInfo from "babylon/components/molecule/PropertiesInfo";
import Transformation from "babylon/components/molecule/Transformation"
import Edge from "babylon/components/molecule/Edge"
import Geometry from "babylon/components/molecule/Geometry"
import Display from "babylon/components/molecule/Display"
import Material from "babylon/components/molecule/Material"
import TextBoxInfo from "babylon/components/molecule/TextBoxInfo"
import {SUPPORT_OBJECT_TYPE} from "babylon/common/Enu3dEnum";
import { SelectMeshContext } from "context/SceneContext";

import styled from 'styled-components';
import ContourProperties from '../molecule/ContourProperties';
import ContourDataProperties from '../molecule/ContourData';

export default function PropertiesContent({selectBabylonData}) {
    const {
        id: MeshId, 
        name: MeshName,
        position: MeshPosition,
        edge: MeshEdge, 
        material: MeshMaterial,        
        objectType
    } = selectBabylonData;

    const {SelectedMesh} = useContext(SelectMeshContext);
 
    return (
        
        <PropertiesList>
            {  MeshId && MeshName &&
                <PropertiesInfo selectBabylonData={selectBabylonData} SelectedMesh={SelectedMesh}/>
            }

            <Geometry selectBabylonData={selectBabylonData} SelectedMesh={SelectedMesh} />
            <Display selectBabylonData={selectBabylonData} SelectedMesh={SelectedMesh} />
            { objectType === SUPPORT_OBJECT_TYPE.TEXTBOX && <TextBoxInfo selectBabylonData={selectBabylonData} SelectedMesh={SelectedMesh} />}
            
            { objectType === SUPPORT_OBJECT_TYPE.CONTOUR && <>
                <ContourProperties selectBabylonData={selectBabylonData} SelectedMesh={SelectedMesh}/>
                <ContourDataProperties selectBabylonData={selectBabylonData} SelectedMesh={SelectedMesh} />
            </>}

            {MeshMaterial && <Material selectBabylonData={selectBabylonData} SelectedMesh={SelectedMesh} />
}
            {/* {MeshPosition &&
                <Layout selectBabylonData={selectBabylonData} SelectedMesh={SelectedMesh}/>
            } */}

            {MeshPosition &&
                <Transformation selectBabylonData={selectBabylonData} SelectedMesh={SelectedMesh}/>
            }
            {MeshEdge && <Edge />}
        </PropertiesList>
    )
}

const PropertiesList = styled.div`
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
`;