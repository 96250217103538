import { AdvancedDynamicTexture} from "@babylonjs/gui/2D"
import { SUPPORT_OBJECT_TYPE } from "babylon/common/Enu3dEnum";

export default class EnuAdvancedDynamicTexture {
    constructor(EnuScene, name = "AdvancedDynamicTexture") {
        const object = AdvancedDynamicTexture.CreateFullscreenUI(name);
        this._originADT = object;

        this._name = object.name;
        // ADT: "AdvancedDynamicTexture"
        this.objectType = SUPPORT_OBJECT_TYPE.ADT;
        EnuScene.addGUIToMap("ADT", this);
    }

    set name(_name) {
        this._name = _name;
        this._originADT.name = _name;
    }
    get name() { return this._name; }

    set originADT(originADT) { this._originADT = originADT; }
    get originADT(){ return this._originADT; }

    getPropertyMap() {
        return {
            name: this.name,
            objectType: this.objectType,
        };
    }
}