import React from 'react';
import AppRoutes from 'AppRoutes';
import theme from 'components/css/theme';
import PrivateRoute from 'components/PrivateRoute';
import Layout from 'components/common/Layout';
import IframePage from 'components/external/IframePage ';

import { Route, Routes } from 'react-router-dom';
import store from 'store/store';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import 'App.css';

const App = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Layout>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, isCheckToken, ...rest } = route;
                            return <Route key={index} {...rest} element={isCheckToken ? <PrivateRoute component={element} /> : element} />;
                        })}

                        {/* iframe을 통한 접근만 허용*/}
                        <Route path="/iframePage" element={<IframePage />} />
                    </Routes>
                </Layout>
            </ThemeProvider>
        </Provider>
    );
}

export default App;

