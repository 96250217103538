
import React, { useState } from 'react';

import MouseRightClickMenuContent from "components/molecule/MouseRightClickMenuContent";
import {LoadingLayer} from "components/organism/LoadingPage";
import Hdf5Tree from "components/atom/Hdf5Tree";
import useRightMenu from "hooks/useRightMenu";

import useHdf5 from 'hooks/useHdf5';
import styled from 'styled-components';
import {  useSelector, shallowEqual } from 'react-redux';
import { MdRefresh, MdCopyAll } from "react-icons/md";

function Hdf5Content({ enuSpace, _visibility }) {
    const {Hdf5List, GetHDF5Hierachy, AddHDF5Data, GetUserDBList} = useHdf5('');
    const [isLoadingPage, setIsLoadingPage] = useState(false);

    const RightMenuInfo = useSelector(state => {
        if (state.rightMenu.type === 'Hdf5')
            return state.rightMenu;
        return null;
    }, shallowEqual);
    const {ShowRightMenu, CloseRightMenu} = useRightMenu();

    const MouseRightClick = (e) => {
        e.preventDefault();
        const {
            nodeName: TargetNodeName,
            dataset: {
                type: TargetType,
                filename: TargetFileName,
                path: TargetPath,
            }
        } = e.target;

        if (TargetNodeName !== 'DIV')
            return;
  
        let RightMenuData = {};
        if(TargetType === 'root'){
            RightMenuData = { type: 'root' };
        }else if (TargetType === 'DATASET') {
            const FileName = TargetFileName;
            let FilePath = TargetPath.substring(0, TargetPath.search(FileName)+FileName.length).replace(/\//g, '\\\\');
            let Path = TargetPath.substring(TargetPath.search(FileName), TargetPath.length);
            Path = Path.replace(FileName, '');

            const CopyData = `GetHDF5Json("${FilePath}", "${Path}")`;
            RightMenuData = { type: 'DATASET', CopyData };
        } else if (TargetType === 'hdf5') {
            RightMenuData = { type: 'hdf5file', path:TargetPath };
        }else{
            CloseRightMenu();
            return;
        }

        ShowRightMenu({ 'type': 'Hdf5', 'top': e.pageY, 'left': e.pageX, data: RightMenuData});
    }

    const CopyPath = (e) => {
        var copyText = RightMenuInfo['data']['CopyData'];
        navigator.clipboard.writeText(copyText);

        window.AddToastMsg(`Copy Success : ${copyText}`);
    }

    const ReloadData = (e) => {
        const TargetPath = RightMenuInfo['data']['path'];

        setIsLoadingPage(true);
        GetHDF5Hierachy(TargetPath.replace('hdf5/', ''))
            .then(res => {
                AddHDF5Data(TargetPath.split('/'), res.data);
            })
            .catch(err => {
                window.AddAlertMsg({type:'error', msg:err});
            }).finally(()=>{
                setIsLoadingPage(false);
            });
    }

    return (
        <Hdf5ContentDiv _visibility={_visibility}>
            <LoadingLayer marginTop={"30px"} isLoadingPage={isLoadingPage}/>
            <Hdf5Tree _data={Hdf5List} 
                _AddHDF5Data={AddHDF5Data} 
                _MouseRightClick={MouseRightClick} 
                _setIsLoadingPage={setIsLoadingPage} 
            /> {" "}

            {RightMenuInfo?.type === 'Hdf5' &&
                <MouseRightClickMenuContent _positionTop={RightMenuInfo.top} _positionLeft={RightMenuInfo.left}>
                    {RightMenuInfo.data?.type  === 'DATASET'    && <li onClick={CopyPath}><span><MdCopyAll style={{'color':'royalblue'}}/>copy</span> </li>}
                    {RightMenuInfo.data?.type  === 'hdf5file'   && <li onClick={ReloadData}><span><MdRefresh style={{'color':'royalblue'}}/>Reload</span> </li>}
                    {RightMenuInfo.data?.type  === 'root'       && <li onClick={GetUserDBList}><span><MdRefresh style={{'color':'royalblue'}}/>Reload</span> </li>}
                </MouseRightClickMenuContent>
            }
        </Hdf5ContentDiv>
    );
}

export default React.memo(Hdf5Content);

// 스타일-----------------------------------------------------
const Hdf5ContentDiv = styled.div`
    height: ${({ _visibility }) => _visibility ? '100%' : '0'};
    padding-left: ${({ _visibility }) => _visibility ? '8px' : '0'};
    display: ${({ _visibility }) => _visibility ? 'block' : 'none'};
`;