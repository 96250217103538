import { useState, useRef } from "react"

import Modal from 'components/modal_new/Modal'
import { BtnPrimary } from "components/atom/Button";
import { ModalBottomBox, BgPopup, WarningMsg } from 'components/css/common'
import { VerticalLabelInput } from "components/molecule/InputFieldLabel"
import { ARCHIVE_TYPE, PUBLISH_TYPE } from "components/common/EnuSpaceEnum";
import { RepositoryRadio} from "components/atom/RadioContent";
import { CreateArchiveProject, UpdateArchiveProject } from "api/ArchiveApi";

import { useNavigate } from 'react-router-dom'
import styled from "styled-components";

const ModalSize = {
    width: 170,
    height: 339,
}

// ArchiveType 은 Archive, Board 중 하나를 String으로 받는다.
export default function NewArchiveFormModal({ ArchiveType ,DataState, onReload }) {
    const [Msg, setMsg] = useState();
    const archiveRef = useRef(null);
    const [BaseData, setBaseData] = DataState;
    const navigate = useNavigate();
    const { userId, mode, archiveId, archiveName, description, publishType } = BaseData;
    const [CreateArchiveInfo, setCreateArchiveInfo] = useState({
        archiveName,
        description,
        publishType,
    });

    const newArchiveProject = (e) => {
        const { archiveName, description, publishType } = CreateArchiveInfo;

        if (!archiveName || archiveName === '') {
            archiveRef.current.focus();
            setMsg({archiveName:`${ArchiveType} Name을 입력해주세요.`});
            return;
        }

        setMsg('');

        if (mode === 'new') {
            CreateArchiveProject(
                userId, 
                archiveName, 
                description || '',
                publishType, 
                ARCHIVE_TYPE[ArchiveType.toUpperCase()]
                )
                .then((res) => {
                    const { result, data: ARCHIVE_SYSTEMID, msg } = res.data;
                    if (result === 'Success') {
                        setBaseData();
                        let urlParam = '';
                        if(ArchiveType === "Archive"){
                            urlParam = `archive=${ARCHIVE_SYSTEMID}`;
                        }else{
                            urlParam = `border=${ARCHIVE_SYSTEMID}&page=1`;
                        }
                        navigate(`/${ArchiveType}/Settings?${urlParam}`);
                    } else {
                        setMsg({common:msg});
                    }
                }).catch((err) => {
                    setMsg({common:err});
                });
        } else {
            // Edit 모드시
            UpdateArchiveProject(archiveId, archiveName, description, publishType)
                .then((res) => {
                    const { result, msg } = res.data;
                    if (result === 'Success') {
                        setBaseData();
                        onReload && onReload();
                    } else {
                        setMsg({common:msg});
                    }
                }).catch((err) => {
                    setMsg({common:err});
                });
        }
    };

    return (
        <>
            <BgPopup onClick={() => setBaseData()} />
            <Modal
                _x={document.getElementById('root').offsetWidth / 2 - ModalSize.width}
                _y={document.getElementById('root').offsetHeight / 2 - ModalSize.height}
                _enableResizing={false}
                _onClick={null}
                _closeModal={() => setBaseData()}
                _title={mode === 'new' ? `New ${ArchiveType}` : `${ArchiveType} Info Edit`}
            >
                <FlexContent>
                    {/* 탭 */}
                    <CustomRepositoryRadio
                        isUseKeyLabel={true}
                        attributeName='PublishType'
                        dataList={PUBLISH_TYPE}
                        titleList={{PUBLIC:'Public', PRIVATE:'Private'}}
                        selectedValue={CreateArchiveInfo.publishType}
                        changeEvent={(name, value) => setCreateArchiveInfo(perv => ({...perv, publishType: value}))}
                    />

                    <VerticalLabelInput
                        label={`${ArchiveType} Name`}
                        placeholder={`${ArchiveType} Name`}
                        isRequired={true}
                        inputRef={archiveRef}
                        value={CreateArchiveInfo.archiveName}
                        errorMsg={Msg?.archiveName || ''}
                        onChangeEvent={(archiveName) => {
                            setCreateArchiveInfo(prev => ({ ...prev, archiveName }))
                        }}
                    />
                    <VerticalLabelInput
                        label='description'
                        placeholder='description'
                        value={CreateArchiveInfo.description}
                        onChangeEvent={(description) => {
                            setCreateArchiveInfo(prev => ({ ...prev, description }))
                        }}
                    />
                </FlexContent>
                <ModalBottomBox marginTop={"0px!important"}>
                    <WarningMsg>{Msg?.common}</WarningMsg>
                    <BtnPrimary label="Ok" onClick={newArchiveProject} />
                </ModalBottomBox>
            </Modal>
        </>
    );
}

//스타일----------------------------------------------
const FlexContent = styled.div`
    &>div{
        margin-top: 0;
    }
`;

const CustomRepositoryRadio = styled(RepositoryRadio)`
    height: 36px;
    margin-bottom: 24px;
`;