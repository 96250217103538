import { useState, useEffect, useCallback, useContext } from "react";

import {BtnText} from "components/atom/Button";
import TreeRowContent from "babylon/components/atom/TreeRowContent";
import {TreeUlContent} from "components/css/common";
import { GetFileList } from "api/Project3D"; 
import { SceneContext } from "context/SceneContext";

import styled from "styled-components";
import { MdSearch, MdClose } from "react-icons/md";
import { useSelector } from 'react-redux';

export default function ProjectExplorerTree({SearchState}){
    const [Search, setSearch] = SearchState;
    const {projectName, projectId, userId} = useSelector(state => state.projectData.BASE_DATA);
    const [ObjectTree, setObjectTree] = useState([]);
    const [MeshTree, setMeshTree] = useState([]);
    const { EnuScene } = useContext(SceneContext);

    const GetObjectTreeData = useCallback(()=>{
        if(!userId) return;
        if(!projectId) return;

        GetFileList(userId, projectId)
            .then(res => {
                const { Result, Msg, Data } = res.data;
                if (Result === "Success") {
                    const treeData = Data[0]?.child;
                    setObjectTree(treeData)
                } else {
                    window.AddAlertMsg({ type: 'error', msg: Msg });
                }
            })
            .catch(err=>{
                window.AddAlertMsg({ type: 'error', msg: err })
            })
    }, [userId, projectId]);

    const GetMeshTreeData = useCallback(() => {
        if (EnuScene) {
            const meshTree = EnuScene.getTreeCopy();
            // const meshTree = EnuScene.tree;
            setMeshTree(meshTree);
        }
    }, [EnuScene]);

    useEffect(() => {
        if (EnuScene) {
            GetObjectTreeData();
            // GetMeshTreeData();
    }
    }, [EnuScene, GetObjectTreeData, GetMeshTreeData, MeshTree]); 


    const MouseRightClick = (e) =>{
        e.preventDefault();
    }

    const RenderTree = (menu) => {        
        return menu.map((item) => {  
            const { title, type, path, child } = item;

            if (['sqlite', 'style', 'sysdb', 'BackUp', 'History.log'].includes(title))
                return null;
            if (title.endsWith('.enup3d'))
                return null;

            const key = path !== undefined ? `${path}\\${title}` : title;

            return(
                <li key={key}>
                    <TreeRowContent 
                        id={title} 
                        title={title} 
                        type={type} 
                        path={path}
                    >
                        {child && <TreeUlContent>{(RenderTree(child))}</TreeUlContent>}
                    </TreeRowContent>
                </li>
            )
        })
    }

    return (
        <TreeContent onContextMenu={MouseRightClick} onClick={(e) => { e.preventDefault(); }}>
            <FilterContent>
                {Search.IsUse
                    ?
                    <div>
                        <MdSearch className='Icons SearchIcon' />
                        <input
                            className='SearchInput'
                            name='searchKeyword'
                            type='text'
                            placeholder='Keyword...'
                            value={Search.Keyword}
                            onChange={(e) => setSearch(perv => ({ ...perv, Keyword: e.target.value }))}
                        />
                        <MdClose className='Icons CloseIcon' onClick={(e => setSearch({ IsUse: false, Keyword: '' }))} />
                    </div>
                    :
                    <>
                        <div>
                            
                        </div>
                        <BtnText label={<MdSearch />} type='sm' onClick={() => setSearch(prev => ({ ...prev, IsUse: true }))} />
                    </>
                }
            </FilterContent>
            <div className='treeBoy'>
                <TreeRowContent 
                    title={projectName || "project"} 
                    type="folder"
                    onClickRow={(e) => {
                        e.stopPropagation();
                        window.resetSelectedMesh();
                    }}
                >
                    <TreeUlContent>
                        {/* {MeshTree?.length > 0 && RenderTree(MeshTree)} */}
                        {ObjectTree?.length > 0 && RenderTree(ObjectTree)}
                    </TreeUlContent>
                </TreeRowContent>
                
            </div>
        </TreeContent>
    );
}

const TreeContent = styled.div`
    height: 100%;
    overflow-y: auto;

    .treeBoy{
        padding-left: 8px;
    }
`

const FilterContent = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    gap: 4px;
    padding:0 8px;
    height: 40px;
    border-bottom: 1px solid ${({theme}) => theme.base.border_color_Gray};
    background-color: #FFF;
    z-index: 1;
    user-select: none;

    &>div{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;

        &>div{
            label{
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                height: 24px;
                width: 28px;
                font-size: .9rem;
                color: ${({theme}) => theme.base.font_color_DarkGray};
                border-radius: 4px;
                box-sizing: border-box;
                cursor: pointer;
            }

            input{
                display: none;

                &:hover + label{
                    color: ${({theme}) => theme.base.font_color_DarkBlue};
                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                }
                
                &:checked + label{
                    font-weight: bold;
                    color: ${({theme}) => theme.base.font_color_DarkBlue};
                    background-color: ${({theme}) => theme.base.background_color_LightBlue};
                    border: 1px solid ${({theme}) => theme.base.border_color_Blue};
                }
            }
        }
    }

    .Icons{
        padding: 4px;
        height: 24px;
        width: 24px;
        box-sizing: border-box;
    }

    .SearchIcon{
        color: ${({theme}) => theme.base.font_color_DarkBlue};
    }

    .CloseIcon{
        cursor: pointer;

        &:hover{
            color: ${({theme}) => theme.base.font_color_Red};
        }
    }

    .SearchInput{
        position: relative;
        flex-grow: 1;
        height: 100%;
        border: none;
        box-sizing: border-box;

        &:focus{
            outline: none;
        } 
    }
`;