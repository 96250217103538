import React, {useState} from 'react';

import CreateProject from './CreateProject';
import EditModePage from './EditModePage';
import RuntimeModePage from './RuntimeModePage';

import { Navigate } from 'react-router-dom';

export default function IframePage() {
    const [userInfo, setUserInfo] = useState({ userId: "",  userNickname: "", })
    const [projectInfo, setProjectInfo] = useState({projectId: "", projectName: "",})
    const [pageName, setPageName] = useState('')

    // iframe에서 접속했는지 확인
    const isInIframe = window !== window.parent
    if (!isInIframe)
        return <Navigate to="/" />

    window.addEventListener('message', event => {
            const {reqPage, authorization, refreshToken, userId, userNickname, projectId, projectName} = event.data;

            if(userId === undefined || userNickname === undefined)return;

            localStorage.setItem('Authorization', authorization); 
            localStorage.setItem('refreshtoken', refreshToken);

            setUserInfo({userId, userNickname});
            setProjectInfo({projectId, projectName});
            setPageName(reqPage);

            localStorage.setItem('systemId', userId);
            if(reqPage === 'EditMode'){
                localStorage.setItem('projectId', projectId);  
                localStorage.removeItem('publishId');
            }else if(reqPage === 'RuntimeMode'){
                localStorage.removeItem('projectId');
                localStorage.setItem('publishId', projectId);  
            }
    });

    switch (pageName) {
        case 'CreatePage':
            return (
                <CreateProject userId={userInfo.userId}/>
            )
        case 'EditMode':
            return (
                <EditModePage userId={userInfo.userId} projectId={projectInfo.projectId} projectName={projectInfo.projectName} />
            )
        case 'RuntimeMode':
            return (
                <RuntimeModePage userId={userInfo.userId} userNickname={userInfo.userNickname} projectId={projectInfo.projectId} projectName={projectInfo.projectName} />
            )
        default:
            return (
                <div>
                </div>
            )

    }
}