import { useState, useEffect, useRef } from 'react';

import {BtnPrimary, BtnSecondary} from "components/atom/Button";
import CodeEditor from "components/atom/CodeEditor";
import {ChartTitle, ModalBottomBox} from 'components/css/common';
import useModalPopup from 'hooks/useModalPopup';

import styled from "styled-components";

export default function LuaEdit({enuSpace, _Modal}){
    const {
        id: ModalId,
        data:{
            script: ModalDataScript,
            msg: ModalDataMsg,
            path: ModalDataPath,
        }
    } = _Modal;

    const [Script, setScript] = useState('');
    const [OutputConsolLog, setOutputConsolLog] = useState('');

    const {DeleteModal} = useModalPopup();
    const CloseModal = (e) =>DeleteModal(ModalId);

    useEffect(()=>{
        setScript(prv => prv || ModalDataScript);
    }, [ModalDataScript]);

    useEffect(()=>{
        setOutputConsolLog(ModalDataMsg || '');
    }, [ModalDataMsg]);

    //크기 조절 관련 함수
    const EditBox = useRef();
    const OutputBox = useRef();
    const [isReSize,   setIsReSize] = useState(false);
    const [initialPos,   setInitialPos] = useState('');
    const [initialSize, setInitialSize] = useState('');

    const initial = (e) => {
        setInitialPos(e.clientY);
        setInitialSize({EditBox:EditBox.current.offsetHeight, OutputBox:OutputBox.current.offsetHeight});
        setIsReSize(true);
        EditBox.current.style.userSelect = 'none';
        OutputBox.current.style.userSelect = 'none';
    }

    const resize = (e) => {
        if(isReSize){
            const MaxHeight = OutputBox.current.parentNode.offsetHeight - 100;
            let EditHeight = parseInt(initialSize['EditBox']) + parseInt(e.clientY - initialPos);
            let OutputHeight = parseInt(initialSize['OutputBox']) - parseInt(e.clientY - initialPos);

            if(EditHeight < 140){
                EditHeight= 140;
                OutputHeight = MaxHeight - 156;
            }else if(OutputHeight < 108){
                OutputHeight= 108;
                EditHeight = MaxHeight - 156;
            }

            OutputBox.current.style.height = `${OutputHeight}px`;
            const editHeight =OutputHeight + 24 + 62;
            EditBox.current.style.height = `calc(100% - ${editHeight}px)`;
        }
    }

    const onDragEnd = (e) => {
        resize(e);
        setIsReSize(false);
        EditBox.current.style.userSelect = 'auto';
        OutputBox.current.style.userSelect = 'auto';
    }

    const RunLua = (e) =>{
        enuSpace.RunGlobalLua(ModalDataPath, Script);
    }

    return(
        <>
            <EditContent ref={EditBox}>
                <CodeEditor _CodeData={Script} _tempScript={setScript}/>
            </EditContent>
            <OutputContent ref={OutputBox} width={10}>
                <OutputTitle draggable='true' onDragStart={initial} onDrag={resize} onDragEnd={onDragEnd}>Output</OutputTitle>
                <CodeEditor _CodeData={OutputConsolLog} _isReadOnly={true}/>
            </OutputContent>
            <ModalBottomBox>
                <BtnSecondary label="Cancel" onClick={CloseModal} />
                <BtnPrimary label="Run Lua" onClick={RunLua} />
            </ModalBottomBox>
        </>
    )
}

//스타일--------------------------

const EditContent = styled.div`
    cursor:text; 
    height: calc(100% - 186px);
    min-height: 108px;

    &>div{
        height: 100%;
    }
`

const OutputContent = styled.div`

    min-height: 108px;

    &>div{
        height: calc(100% - 28px);
    }
`
const OutputTitle = styled(ChartTitle)`
    width: 100%;

    &:hover{
        cursor: row-resize;
    }
`