import PropertiesContent from "components/atom/PropertiesContent";
import ColorPickerContent from "babylon/components/atom/ColorPickerContent";
// import LabelAndInput from "babylon/components/atom/LabelAndInput";

export default function Material({selectBabylonData, SelectedMesh}){
    const {
        faceColors,
        // material: linkedMaterial
    } = selectBabylonData;

    // const diffuseColor = linkedMaterial._diffuseColor;
    // console.log(diffuseColor)
    const ChangeColor = (keyValue, colorArray) =>{
        SelectedMesh[keyValue] = colorArray;
    }

    return(
        <PropertiesContent _Title="Material">
            <ColorPickerContent
                Id="faceColors"
                label="face"
                rgbaArray={faceColors}
                changeEvent={ChangeColor}
                labelWidth={70}
            />
            {/* {linkedMaterial && <ColorPickerContent
                Id="diffuseColor"
                label="diffuse"
                rgbArray={SelectedMesh}
                changeEvent={ChangeColor}
                labelWidth={70}
                isOpacity={false}
            />} */}
            {/* <LabelAndInput 
                id='faceUV'
                label='faceUV'
                value={''}
                labelWidth="108px"
                changeEvent={(e)=>{
                    // const targetValue = e.target.value;
                    // TODO: 변경에 따른 작업 필요
                    console.log("변경에따른 작업 필요")
                }}
            />
            <ColorPickerContent
                Id="diffuseColor"
                label="diffuse"
                labelWidth={70}
                isOpacity={false}
            />
            <ColorPickerContent
                Id="emissiveColor"
                label="emissive"
                labelWidth={70}
                isOpacity={false}
            />
            <ColorPickerContent
                Id="ambientColor"
                label="ambient"
                labelWidth={70}
                isOpacity={false}
            />
            <LabelAndInput 
                id='alpha'
                label='alpha'
                inputType='number'
                value={''}
                labelWidth="108px"
                changeEvent={(e)=>{
                    // const targetValue = e.target.value;
                    // TODO: 변경에 따른 작업 필요
                    console.log("변경에따른 작업 필요")
                }}
            /> */}
        </PropertiesContent>
    )
}