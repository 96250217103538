import PropertiesContent from "components/atom/PropertiesContent";
import LabelAndInput from "babylon/components/atom/LabelAndInput";
import useDataController from "babylon/hooks/useDataController";

export default function Geometry({ selectBabylonData, SelectedMesh }) {
    const {
        height,
        width,
        depth,
        diameterTop,
        diameterBottom,        
    } = selectBabylonData;

    const { UploadDataByMesh } = useDataController();

    if(!height && !width && !depth && !diameterTop && !diameterBottom ) return null;

    return (
        <PropertiesContent
            _Title="Geometry"
        >
            {width !== undefined && <LabelAndInput
                id='width'
                label='width(X)'
                inputType='number'
                value={width}
                labelWidth="30%"
                changeEvent={(e) => {
                    const targetValue = Number(e.target.value);
                    SelectedMesh.width = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}
            {height !== undefined && <LabelAndInput
                id='height'
                label='height(Y)'
                inputType='number'
                value={height}
                labelWidth="30%"
                changeEvent={(e) => {
                    const targetValue = Number(e.target.value);
                    SelectedMesh.height = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}
            {depth !== undefined && <LabelAndInput
                id='depth'
                label='depth(Z)'
                inputType='number'
                value={depth}
                labelWidth="30%"
                changeEvent={(e) => {
                    const targetValue = Number(e.target.value);
                    SelectedMesh.depth = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}

            {diameterTop !== undefined && <LabelAndInput
                id='diameterTop'
                label='diameterTop'
                inputType='number'
                value={diameterTop}
                labelWidth="50%"
                changeEvent={(e) => {
                    const targetValue = Number(e.target.value);
                    SelectedMesh.diameterTop = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}
            {diameterBottom !== undefined && <LabelAndInput
                id='diameterBottom'
                label='diameterBottom'
                inputType='number'
                value={diameterBottom}
                labelWidth="50%"
                changeEvent={(e) => {
                    const targetValue = Number(e.target.value);
                    SelectedMesh.diameterBottom = targetValue;
                    UploadDataByMesh(SelectedMesh);
                }}
            />}
            
        </PropertiesContent>
    )
}