import {useState, useEffect, useCallback} from "react"

import {RepositoryRadio} from 'components/atom/RadioContent'
import {BtnGhost} from "components/atom/Button";
import {DBCard} from 'components/molecule/ProjectCard'
import {SetTimeFormat ,displayHighestAuth} from 'components/Utility';
import { GetDeviceList as funGetDeviceList} from 'api/DeviceApi'
import { ContentSectionHeader} from 'components/css/common';
import NewDatabase from 'components/modal_new/NewDatabase'

import { useNavigate} from 'react-router-dom';
import { AllClearModalPopup } from 'reducers/modulePopupReducer';
import { useDispatch } from 'react-redux';
import styled from "styled-components"
import { MdModeEdit} from "react-icons/md";

export default function DB(){
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const systemId = localStorage.getItem('systemId');
    const email = localStorage.getItem('email');
    const [SelectTableType, setSelectTableType] = useState('My'); //My, Private, Public
    const [DeviceList, setDeviceList] = useState();

    const [isDBModalType, setIsDBModalType] = useState();

    /**
     * 디바이스 리스트를 가져온다.
     */
    const GetDeviceList = useCallback(async () => {
        try {
            const tableList = await funGetDeviceList(systemId, SelectTableType)
            setDeviceList(tableList);
        } catch (error) {
            window.AddAlertMsg({type:'error', msg:`DB 리스트를 가져오는데 실패하였습니다. 관리자에게 문의해주세요.`});
        }
    }, [systemId, SelectTableType]);

    useEffect(()=>{
        GetDeviceList();
    }, [GetDeviceList]);

    useEffect(() => {
        return () => dispatch(AllClearModalPopup());
    }, [dispatch]);

    return (
        <DevicePageContent>
            <ContentSectionHeader>
                <RepositoryRadio
                    attributeName='tableType'
                    isUseKeyLabel={true}
                    dataList={{ MY: 'My', PUBLIC: 'Public', PRIVATE: 'Private' }}
                    selectedValue={SelectTableType}
                    changeEvent={(name, listType) => setSelectTableType(listType)}
                />
            </ContentSectionHeader>

            <section className="projectListBody">
                {SelectTableType === 'My' &&
                    <NewProjectBox>
                        <BtnGhost
                            label="New DB"
                            onClick={(e) => setIsDBModalType({ type: 'create' })}
                        />
                    </NewProjectBox>
                }

                {DeviceList &&
                    DeviceList.map((Device) => {
                        const { DB_TABLE_NAME, TABLE_ID, CREATED_TIME, NICKNAME, READ, WRITE, EDIT, MANAGE, MASTER } = Device;
                        const ACCESS_LEVEL = displayHighestAuth({ READ, WRITE, EDIT, MANAGE, MASTER });
                        const IsEditBtn = ['Edit', 'Manage', 'Master'].includes(ACCESS_LEVEL);

                        return (
                            <DBCard
                                key={TABLE_ID}
                                title={DB_TABLE_NAME}
                                creator={NICKNAME}
                                publishStamp={SetTimeFormat(CREATED_TIME)}
                                accessLevel={ACCESS_LEVEL}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/DB/Tag`, { state: { Device, UserId: systemId, Email: email, Lever: ACCESS_LEVEL } });
                                }}
                            >
                                {IsEditBtn &&
                                    <BtnGhost
                                        label={<MdModeEdit />}
                                        type='sm'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsDBModalType({ type: 'edit', tableId: TABLE_ID, lever: ACCESS_LEVEL })
                                        }}
                                    />}
                            </DBCard>
                        )
                    })
                }
            </section>

            {isDBModalType && <NewDatabase type={isDBModalType.type} tableInfo={isDBModalType} reloadEvent={GetDeviceList} closeEvent={(e) => setIsDBModalType()} />}
        </DevicePageContent>
    );
}

// 스타일-------------------------------
const DevicePageContent = styled.div`
    user-select: none;

    &>.projectListBody{
        padding-bottom: 48px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 16px;
        margin-top: 16px;
    }

    @media screen and (min-width:1024px) {
        max-width: 1200px;
        margin: 0 auto;
    } 
    
    @media screen and (max-width:1090px) {
        margin: 0 16px;
    }
`;

const NewProjectBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 216px;
    border: 2px dashed ${({theme}) => theme.base.border_color_Gray};
    border-radius: 4px;
    padding: 8px;
    box-sizing: border-box;
    min-width: 240px;
    
    @media screen and (min-width:1024px) {
        width: calc(100% / 4 - 12px);
    } 
    
    @media screen and (max-width:1090px) {
        width: calc(100% / 3 - 11px);
    } 

    @media screen and (max-width:850px) {
        width: calc(100% / 2 - 8px);
    }

    @media screen and (max-width:560px) {
        width: calc(100%);
    }
`;