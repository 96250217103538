import { useState } from "react";

import FontFamily from 'components/common/FontFamily';
import PropertiesContent from "components/atom/PropertiesContent";
import {RadioComponent} from "components/atom/RadioContent";
import SelectList from "components/atom/SelectList";
import InputComponent from "components/atom/InputComponent";
import ColorComponent from "components/molecule/ColorComponent";
import {FontSizeIcon} from 'components/common/EnuSpaceIcon';
import { GroupName } from 'components/css/common';

import styled from "styled-components";
import { MdBorderLeft, MdBorderTop, MdBorderRight, MdBorderBottom } from "react-icons/md";

export default function ContourLegendList({_data, _ChangeEvent, _disabled, ...props}){
    const {
        legend_pos: NodeLegendPos,
        legend_width: NodeLegendWidth,
        legend_gap: NodeLegendGap,
        legend_font_family: NodeLegendFontFamily,
        legend_font_size: NodeLegendFontSize,
    } = _data;
    const [fontClass] = useState(new FontFamily());

    const ChangeSelect = (attributeName, attributeValue) => _ChangeEvent([{attributeName, attributeValue}]);

    const commonProps = {
        changeEvent : _ChangeEvent, 
        disabled: _disabled,
    };

    return(
        <PropertiesContent _Title="Legend">
            <div>
                <GroupName width={76}>position</GroupName>
                <RadioComponent
                    attributeName='legend-pos'
                    dataList={{
                        "left" : <MdBorderLeft />,
                        "top" : <MdBorderTop />,
                        "right" : <MdBorderRight />,
                        "bottom" : <MdBorderBottom />
                    }} 
                    selectedValue={NodeLegendPos}
                    disabled={_disabled}
                    changeEvent={ChangeSelect}
                />
            </div>
            <div>
                <InputComponent label='width' attributeName='legend-width' labelWidth='36px' valueType='number' value={NodeLegendWidth} {...commonProps}/>
                <InputComponent label='gap' attributeName='legend-gap' labelWidth='36px' valueType='number' value={NodeLegendGap} {...commonProps}/>
            </div>
            <div>
                <SelectList
                    attributeName="legend-font-family"
                    dataList={fontClass.GetFontFamilyList()}
                    selectedValue={NodeLegendFontFamily}
                    {...commonProps}
                />
            </div>
            <div>
                <ColorComponent label="font" attributeName="legend-font-color" isGradient={false} isVisibility={false} isOpacity={false} {...commonProps} />
                <CustomInputComponent
                    label={<FontSizeIcon />}
                    attributeName="legend-font-size"
                    labelWidth='24px'
                    valueType='number'
                    value={NodeLegendFontSize}
                    {...commonProps}
                />
            </div>  
        </PropertiesContent>
    )
}

const CustomInputComponent = styled(InputComponent)`
    width: 80px;
`;